import {
  CSSProperties,
  ChangeEvent,
  ClipboardEventHandler,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { DigitInputsWrapper, InputWrapper } from "./styles";

export interface IDigitsInputProps {
  style?: CSSProperties;
  totalDigits: number;
  onChangeDigits: (digits: string) => void;
}

function DigitsInput({
  onChangeDigits,
  totalDigits,
  style,
  ...others
}: IDigitsInputProps) {
  const refs = useRef<(HTMLInputElement | null)[]>(
    Array.from({ length: totalDigits })
  );
  const [digits, setDigits] = useState<string[]>(
    Array.from<string>({ length: totalDigits }).fill("")
  );

  useEffect(() => {
    onChangeDigits(digits.join(""));
  }, [digits, onChangeDigits]);

  const handleBackspace = useCallback((refIndex: number) => {
    const prevInputRef = refs.current?.[refIndex - 1];
    if (prevInputRef) {
      prevInputRef.focus();
    }
  }, []);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, refIndex: number) => {
      const currentInputRef = refs.current?.[refIndex];

      if (e.target.value) {
        const nextInputRef = refs.current?.[refIndex + 1];
        if (nextInputRef) {
          nextInputRef.focus();
        } else if (currentInputRef) {
          currentInputRef.blur();
        }
      }

      setDigits((prev) => {
        const newDigits = [...prev];
        newDigits[refIndex] = e.target.value.slice(-1);
        return newDigits;
      });
    },
    []
  );

  // Handle paste
  const onPasteCapture: ClipboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      e.preventDefault();

      const text = e.clipboardData.getData("Text") || "";
      const pastedDigits = text
        .slice(0, totalDigits)
        .split("")
        .filter(
          (v) =>
            v.trim().length &&
            v.toLowerCase() !== "e" &&
            !Number.isNaN(Number(v))
        );

      if (pastedDigits.length) {
        setDigits(pastedDigits);
        refs.current[pastedDigits.length]?.focus();
      }
    },
    [totalDigits]
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>, refIndex: number) => {
      // Dont allow "e"
      if (e.key.toLowerCase() === "e") {
        e.preventDefault();
        return;
      }

      // Backspace on an empty digit
      if (e.key === "Backspace") {
        if (digits[refIndex]) {
          const newDigits = [...digits];
          newDigits[refIndex] = "";
          setDigits(newDigits);
        } else {
          handleBackspace(refIndex);
        }
      }
    },
    [digits, handleBackspace]
  );

  return (
    <DigitInputsWrapper style={style}>
      {Array.from({ length: totalDigits }).map((_, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <InputWrapper key={idx}>
          <input
            inputMode="numeric"
            maxLength={1}
            placeholder="•"
            type="number"
            {...others}
            ref={(ref) => {
              if (refs.current) {
                refs.current[idx] = ref;
              }
            }}
            onPasteCapture={onPasteCapture}
            autoComplete="off"
            onChange={(e) => onChange(e, idx)}
            onKeyDown={(e) => onKeyDown(e, idx)}
            value={digits[idx]}
          />
        </InputWrapper>
      ))}
    </DigitInputsWrapper>
  );
}

export default DigitsInput;
