import styled from "styled-components";
import { COLORS } from "../../../../constants/design/colors";
import { SPACING } from "../../../../constants/design/spacing";

export const AirdropIconWrapper = styled.div`
  margin-left: ${SPACING.two}px;
  > svg {
    stroke: ${COLORS.highlight.one};
  }
  > svg > path {
    fill: ${COLORS.highlight.one};
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;
