import styled, { css } from "styled-components";
import { TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";
import { Button } from "../../Buttons/styles";

export const ConfirmCloseContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: ${SPACING.three}px;
`;

export const ButtonsContainer = styled.div`
  ${Button} {
    margin-top: ${SPACING.three}px;
    margin-right: 0;
  }
`;

export const Title = styled.span<{color?: string}>`
  ${FONT_CSS.header.seven}
  display: flex;
  align-items: center;

  svg {
    margin-left: ${SPACING.one}px;
  }

  ${({ color }) => {
    if (color) {
      return css`
        color: ${color};
        svg {
          stroke: ${color};
        }
      `;
    }
    return "";
  }}
`;

export const EmptyTextContent = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextContent = styled.div`
  ${FONT_CSS.body.three}
  color: ${TEXT_COLORS.two};
  padding: ${SPACING.three}px 0;
`;

export const Highlight = styled.span`
  color: ${TEXT_COLORS.one};
`;
