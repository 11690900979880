import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { GetFunding200Response } from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { publicApi } from "../../../services/api/apiFetcher";
import {
  pollingDelay,
  pollingInterval,
} from "../../../services/api/pollingInterval";

export const useFunding = (instrumentName?: string) => {
  const fetcher = publicApi();
  const [startsQuery, setStartsQuery] = useState(false);

  useEffect(() => {
    // Wait before querying
    const t = setTimeout(() => {
      setStartsQuery(true);
    }, pollingDelay[APIEndpointEnum.FUNDING]);
    return () => clearTimeout(t);
  }, []);

  return useSWR<GetFunding200Response, AxiosError>(
    instrumentName && startsQuery
      ? [APIEndpointEnum.FUNDING, instrumentName]
      : undefined,
    async () => (await (await fetcher.getFunding(instrumentName!))()).data,
    {
      refreshInterval: pollingInterval[APIEndpointEnum.FUNDING],
    }
  );
};
