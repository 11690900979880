import {
  IDecryptedAuthInfo,
  IEncryptedAuthInfo,
} from "../../interfaces/AuthInfo";
import { decrypt, encrypt } from "./encryption";

export enum DecryptAccountStatusEnum {
  INVALID_PASSWORD = "invalid_password",
  MISSING_IV_SALT = "missing_iv_salt",
  OK = "ok",
}

/**
 * Decrypts account
 * If ok, returns status OK and the decryptedInfo. Else the decryptedInfo is undefined
 * @param info
 * @param password
 * @returns
 */
export const decryptEncryptedAccountInfo = (
  info: IEncryptedAuthInfo,
  password: string
) => {
  if (!info.iv || !info.salt) {
    return {
      status: DecryptAccountStatusEnum.MISSING_IV_SALT,
      decryptedInfo: undefined,
    };
  }

  try {
    const decryptedString = decrypt(
      info.encryptedBundle,
      password,
      info.iv,
      info.salt
    );
    const parsed: IDecryptedAuthInfo = {
      ...(JSON.parse(decryptedString) as IDecryptedAuthInfo),
      // set encrypted to false
      encrypted: false,
    };

    // Check valid account
    if (parsed.account !== info.account) {
      return {
        status: DecryptAccountStatusEnum.INVALID_PASSWORD,
        decryptedInfo: undefined,
      };
    }

    return {
      status: DecryptAccountStatusEnum.OK,
      decryptedInfo: parsed,
    };
  } catch (error) {
    // Error return invalid password
    return {
      status: DecryptAccountStatusEnum.INVALID_PASSWORD,
      decryptedInfo: undefined,
    };
  }
};

export const encryptAccountInfo = (
  info: IDecryptedAuthInfo,
  password: string
) => {
  const encryptedString = encrypt(JSON.stringify(info), password);
  return encryptedString;
};
