import axios, { AxiosRequestConfig } from "axios";
import { APIEndpointEnum } from "../../enums/endpoint";
import {
  PrivateAPIApiFp,
  Configuration,
  PublicAPIApiFp,
} from "../../codegen-api";
import { API_URL } from "../../constants/api/api";

interface IFetcherProps extends AxiosRequestConfig {
  url: APIEndpointEnum | string;
  // API Key for endpoints that requires it
  apiKey?: string;
  apiSecret?: string;
}

// Returns a fetcher function.
// The URL will be appended by BASE_URL by default
export async function apiFetcher<T>({
  url,
  apiKey,
  apiSecret,
  ...configs
}: IFetcherProps) {
  const headers =
    apiKey && apiSecret
      ? {
          "AEVO-KEY": apiKey,
          "AEVO-SECRET": apiSecret,
        }
      : undefined;

  const { data, status, statusText } = await axios.request<T>({
    url: `${API_URL}/${url}`,
    headers,
    ...configs,
  });

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (status !== 200) {
    // TODO: - Make error clearer
    const error = new Error(statusText);
    throw error;
  }
  return data;
}

export function authApi(apiKey?: string, apiSecret?: string) {
  const config =
    apiKey && apiSecret
      ? new Configuration({
          apiKey: (key: string) => {
            switch (key) {
              case "AEVO-KEY":
                return apiKey;
              case "AEVO-SECRET":
                return apiSecret;
              default:
                throw new Error("No found key");
            }
          },
          basePath: API_URL,
        })
      : new Configuration({
          basePath: API_URL,
        });
  return PrivateAPIApiFp(config);
}

export function publicApi() {
  return PublicAPIApiFp(
    new Configuration({
      basePath: API_URL,
    })
  );
}
