import styled from "styled-components";
import { COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../constants/design/spacing";
import { ToggleContainer } from "../Toggle/style";

export const Container = styled.div<{ isOn: boolean }>`
  ${FONT_CSS.body.five}
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.highlight.one};
  background-color: ${COLORS.highlight.six};
  margin-top: ${SPACING.two}px;
  padding: ${SPACING.two}px;
  height: ${COMPONENTS.modalButton}px;
  border-radius: ${BORDER_RADIUS.five}px;

  > ${ToggleContainer} {
    background-color: ${({ isOn }) =>
      isOn ? COLORS.blue.six : COLORS.highlight.six};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: all 0.4s ease-in-out;
`;
