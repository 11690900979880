/* eslint-disable no-nested-ternary */
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PropsWithChildren, useCallback, useMemo } from "react";
import Decimal from "decimal.js";
import {
  GetAccount200ResponsePositionsInner,
  MarginTypeResponse,
  OrderTypeResponse,
  SideResponse,
} from "../../../../codegen-api";
import { GapWrapper, InputsWrapper } from "../../style";
import {
  IPerpsFormFieldValues,
  LeverageSelectInput,
  LimitPriceShortcutEnum,
  OrderSizeInput,
  PerpFormFieldKeyEnum,
  PriceInput,
  ReduceOnlyMaxSizeInput,
  TriggerPriceInput,
} from "./form";
import { FormValidatorKeysEnum } from "../../../../enums/form";
import { roundToStepSize } from "../../../../utils/format";
import { IContractPriceStep } from "../../../../utils/instruments";
import { IPerpsMarket } from "../../../../contexts/MarketInstrumentContext/useGetMarkets";
import { AssetResponse } from "../../../../utils/asset";
import { COLORS } from "../../../../constants/design/colors";

export interface IInputsProps {
  isMobileScreen?: boolean;
  form: UseFormReturn<IPerpsFormFieldValues, any, undefined>;
  perpInstrument?: IPerpsMarket;
  amount: string;
  price: string;
  leverage: string;
  markPrice?: string;
  orderDirection: SideResponse;
  orderType: OrderTypeResponse;
  portfolioMargin?: boolean;

  // If orderDirection is BUY, best price is best bid, else is best ask
  bestPrice?: string;
  isStop: boolean;
  tradeUseUSDCTerms: boolean;
  setTradeUseUSDCTerms?: (tradeUseUSDCTerms: boolean) => void;
  reduceOnly: boolean;
  reduceOnlyNotAllowed: boolean;
  currentPosition?: GetAccount200ResponsePositionsInner;
  contractPriceStep: IContractPriceStep;
  verifyNotEnoughBalance: (contractSize: string) => boolean;
  isLoading: boolean;
  customError?: string;
  minOrderValue?: string;
  maxOrderValue?: string;
  insufficientLiquidity: boolean;
  maxLeverage: number;
  calculateTotalValueWithSize: (amountStr: string, isUSDC?: boolean) => number;
  getRealContractSize: (amt: string, isUSD?: boolean) => number;
  updateLeverage: (
    orderSize: number,
    isUSDC: boolean,
    priceValue?: number
  ) => void;
  updateOrderSize: (
    leverageValue: number,
    disableLeverageUpdate?: boolean
  ) => void;
  updateReduceOnlyOrderSize: (size: number) => void;
  triggerPriceValidateFn:
    | {
        triggerPriceBelowMark: () => boolean;
        triggerPriceAboveMark: (v: string) => boolean;
      }
    | {
        triggerPriceBelowMark: (v: string) => boolean;
        triggerPriceAboveMark: () => boolean;
      };
  limitPriceValidateFn:
    | {
        limitPriceAboveTriggerPrice: (v: string) => boolean;
        limitPriceBelowTriggerPrice: () => boolean;
      }
    | {
        limitPriceAboveTriggerPrice: () => boolean;
        limitPriceBelowTriggerPrice: (v: string) => boolean;
      };
}

function Inputs({
  isMobileScreen,
  form,
  perpInstrument,
  amount,
  price,
  leverage,
  markPrice,
  bestPrice,
  orderDirection,
  orderType,
  isStop,
  tradeUseUSDCTerms,
  setTradeUseUSDCTerms,
  reduceOnly,
  reduceOnlyNotAllowed,
  currentPosition,
  contractPriceStep,
  verifyNotEnoughBalance,
  isLoading,
  customError,
  minOrderValue,
  maxOrderValue,
  maxLeverage,
  calculateTotalValueWithSize,
  getRealContractSize,
  updateLeverage,
  updateOrderSize,
  updateReduceOnlyOrderSize,
  insufficientLiquidity,
  triggerPriceValidateFn,
  limitPriceValidateFn,
  portfolioMargin,
}: PropsWithChildren<IInputsProps>) {
  const { t: formTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });

  const {
    register,
    setValue,
    formState: { errors },
  } = form;

  const onSelectLimitShortcut = useCallback(
    (shortcut: LimitPriceShortcutEnum) => {
      if (!bestPrice) {
        return;
      }

      let add = new Decimal(0);
      switch (shortcut) {
        case LimitPriceShortcutEnum.ONE_PERCENT:
          add = new Decimal(bestPrice).mul("0.01");
          break;
        case LimitPriceShortcutEnum.TWO_PERCENT:
          add = new Decimal(bestPrice).mul("0.02");
          break;
        case LimitPriceShortcutEnum.FIVE_PERCENT:
          add = new Decimal(bestPrice).mul("0.05");
          break;
        default:
          break;
      }

      // If buy, buy lower
      if (orderDirection === SideResponse.Buy) {
        add = add.neg();
      }

      setValue(
        PerpFormFieldKeyEnum.PRICE,
        new Decimal(bestPrice)
          .add(add)
          .toFixed(contractPriceStep.price_precision)
      );
    },
    [bestPrice, contractPriceStep.price_precision, orderDirection, setValue]
  );

  const isIsolated = useMemo(
    () =>
      !portfolioMargin &&
      currentPosition?.margin_type === MarginTypeResponse.Isolated,
    [currentPosition?.margin_type, portfolioMargin]
  );

  const selectedAsset = useMemo(() => {
    if (tradeUseUSDCTerms || isIsolated) {
      return "USDC";
    }

    return perpInstrument?.underlying_asset;
  }, [isIsolated, perpInstrument?.underlying_asset, tradeUseUSDCTerms]);

  return (
    <InputsWrapper isMobileScreen={isMobileScreen}>
      <GapWrapper isMobileScreen={isMobileScreen}>
        {isStop && (
          <TriggerPriceInput
            isMobileScreen={isMobileScreen}
            placeholder={
              markPrice
                ? Number(markPrice).toFixed(contractPriceStep.price_precision)
                : ""
            }
            markPrice={
              markPrice
                ? Number(markPrice).toFixed(contractPriceStep.price_precision)
                : undefined
            }
            minPrice={contractPriceStep.price_step}
            register={register(PerpFormFieldKeyEnum.TRIGGER_PRICE, {
              disabled: isLoading,
              required: true,
              validate: {
                ...triggerPriceValidateFn,
                [FormValidatorKeysEnum.moreThanZero]: (v) => parseFloat(v) > 0,
                [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
                  roundToStepSize(
                    Number(v),
                    contractPriceStep.price_step,
                    contractPriceStep.price_precision
                  ) >=
                  1 / 10 ** contractPriceStep.price_precision,
              },
              onBlur(event) {
                setValue(
                  PerpFormFieldKeyEnum.TRIGGER_PRICE,
                  event.target.value
                    ? roundToStepSize(
                        parseFloat(event.target.value),
                        contractPriceStep.price_step,
                        contractPriceStep.price_precision
                      ).toString()
                    : event.target.value
                );
              },
            })}
            errors={errors}
          />
        )}
        {orderType === OrderTypeResponse.Limit && (
          <PriceInput
            orderDirection={orderDirection}
            isMobileScreen={isMobileScreen}
            isStopOrder={isStop}
            placeholder={
              bestPrice
                ? Number(bestPrice).toFixed(contractPriceStep.price_precision)
                : ""
            }
            minPrice={contractPriceStep.price_step}
            register={register(PerpFormFieldKeyEnum.PRICE, {
              disabled: isLoading,
              required: true,
              validate: {
                ...limitPriceValidateFn,
                [FormValidatorKeysEnum.moreThanZero]: (v) => parseFloat(v) > 0,
                [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
                  roundToStepSize(
                    Number(v),
                    contractPriceStep.price_step,
                    contractPriceStep.price_precision
                  ) >=
                  1 / 10 ** contractPriceStep.price_precision,
              },
              onBlur(event) {
                const { value } = event.target;
                if (!value) {
                  return;
                }

                setValue(
                  PerpFormFieldKeyEnum.PRICE,
                  roundToStepSize(
                    parseFloat(value),
                    contractPriceStep.price_step,
                    contractPriceStep.price_precision
                  ).toString()
                );
              },
            })}
            onSelectLimitShortcut={onSelectLimitShortcut}
            errors={errors}
          />
        )}
        <OrderSizeInput
          isIsolated={isIsolated}
          isMobileScreen={isMobileScreen}
          customError={customError}
          minAmountSize={
            tradeUseUSDCTerms
              ? contractPriceStep.price_step
              : contractPriceStep.amount_step
          }
          minOrderValue={minOrderValue}
          maxOrderValue={maxOrderValue}
          existingPositionSize={currentPosition?.amount}
          underlyingAsset={perpInstrument?.underlying_asset as AssetResponse}
          selectedAsset={selectedAsset}
          onChangeAsset={(asset) => {
            const stepSize = asset
              ? contractPriceStep.amount_step
              : contractPriceStep.price_step;
            const precision = asset
              ? contractPriceStep.amount_precision
              : contractPriceStep.price_precision;
            let newAmount = 0;

            // change from underlying -> USDC
            // update amount to show TOTAL VALUE
            const noAsset = !asset;
            if (!tradeUseUSDCTerms && noAsset) {
              newAmount = roundToStepSize(
                calculateTotalValueWithSize(amount, false),
                stepSize,
                precision
              );
            } else if (tradeUseUSDCTerms && asset) {
              // change from USDC -> underlying
              // update amount to show CONTRACTS
              newAmount = roundToStepSize(
                getRealContractSize(amount, true),
                stepSize,
                precision
              );
            }

            if (newAmount) {
              setValue(PerpFormFieldKeyEnum.AMOUNT, String(newAmount));
              updateLeverage(
                Number(amount),
                !asset,
                price ? Number(price) : undefined
              );
            }
            setTradeUseUSDCTerms?.(!asset);
          }}
          register={register(PerpFormFieldKeyEnum.AMOUNT, {
            disabled: isLoading,
            required: true,
            validate: {
              [FormValidatorKeysEnum.moreThanZero]: (v) => parseFloat(v) > 0,
              [FormValidatorKeysEnum.notEnoughBalance]: verifyNotEnoughBalance,
              [FormValidatorKeysEnum.orderValueTooSmall]: (v) =>
                reduceOnly ||
                !minOrderValue ||
                calculateTotalValueWithSize(v) >= Number(minOrderValue),
              [FormValidatorKeysEnum.orderValueTooLarge]: (v) =>
                reduceOnly ||
                !maxOrderValue ||
                calculateTotalValueWithSize(v) <= Number(maxOrderValue),
              [FormValidatorKeysEnum.decimalsTooSmall]: (v) => {
                const stepSize = tradeUseUSDCTerms
                  ? contractPriceStep.price_step
                  : contractPriceStep.amount_step;
                const precision = tradeUseUSDCTerms
                  ? contractPriceStep.price_precision
                  : contractPriceStep.amount_precision;
                return (
                  roundToStepSize(Number(v), stepSize, precision) >=
                  1 / 10 ** contractPriceStep.amount_precision
                );
              },
              [FormValidatorKeysEnum.reduceOnlyOrderSizeValid]: (v) => {
                if (!reduceOnly) {
                  return true;
                }
                const size = getRealContractSize(v, tradeUseUSDCTerms);
                return currentPosition
                  ? size <= Number(currentPosition.amount)
                  : true;
              },
            },
            onChange(event) {
              const { value } = event.target;
              updateLeverage(
                Number(value),
                !!tradeUseUSDCTerms,
                price ? Number(price) : undefined
              );
            },
            onBlur(event) {
              const { value } = event.target;
              if (!value) {
                return;
              }

              const stepSize = tradeUseUSDCTerms
                ? contractPriceStep.price_step
                : contractPriceStep.amount_step;
              const precision = tradeUseUSDCTerms
                ? contractPriceStep.price_precision
                : contractPriceStep.amount_precision;
              setValue(
                PerpFormFieldKeyEnum.AMOUNT,
                roundToStepSize(Number(value), stepSize, precision).toString()
              );
              updateLeverage(
                Number(value),
                !!tradeUseUSDCTerms,
                price ? Number(price) : undefined
              );
            },
          })}
          style={{ paddingBottom: 0 }}
          errors={errors}
          warning={
            insufficientLiquidity && orderType === OrderTypeResponse.Market
              ? formTranslations("insufficient_market_liquidity")
              : undefined
          }
        />
        {!reduceOnly && (
          <LeverageSelectInput
            key={maxLeverage}
            leverage={Number(leverage)}
            maxLeverage={maxLeverage}
            onChangeLeverage={(value) => {
              setValue(PerpFormFieldKeyEnum.LEVERAGE, value.toFixed(2));
              updateOrderSize(value, true);
            }}
            leverageColor={
              orderDirection === SideResponse.Buy
                ? COLORS.positive.one
                : COLORS.negative.one
            }
            errors={errors}
          />
        )}
        {reduceOnly && (
          <ReduceOnlyMaxSizeInput
            key={currentPosition?.amount || 0}
            orderSize={getRealContractSize(amount, tradeUseUSDCTerms)}
            maxOrderSize={
              reduceOnlyNotAllowed ? 0 : Number(currentPosition?.amount || 0)
            }
            onChangeOrderSize={(value) => {
              updateReduceOnlyOrderSize(value);
            }}
            leverageColor={
              orderDirection === SideResponse.Buy
                ? COLORS.positive.one
                : COLORS.negative.one
            }
            errors={errors}
          />
        )}
      </GapWrapper>
    </InputsWrapper>
  );
}

export default Inputs;
