import styled from "styled-components";
import { LAYER_COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../constants/design/spacing";
import { GroupedCells, TableWrapper } from "../shared/Table/style";

export const OrdersTableWrapper = styled(TableWrapper)`
  height: 100%;
  overflow: auto !important;

  thead {
    position: sticky;
  }

  tbody {
    tr {
      cursor: pointer;
    }
  }

  th,
  td {
    &:last-child {
      padding-left: ${SPACING.two}px;
      padding-right: ${SPACING.two}px;
      position: sticky;
      right: 0;
      z-index: 1;
    }
  }

  th {
    td {
      background-color: transparent;
    }
  }

  tr {
    td:first-child {
      padding-right: 0;

      // This is to make sure the first col width fits content
      width: 0;
    }
  }

  tbody td {
    background: ${LAYER_COLORS.one};
  }
`;

export const OrderGroupedCells = styled(GroupedCells)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const CancelAllButton = styled.button`
  ${FONT_CSS.label.two}
  color: ${TEXT_COLORS.two};
  background-color: ${LAYER_COLORS.three};
  border-radius: ${BORDER_RADIUS.five}px;
  width: ${COMPONENTS.tableActionButtonsCellWidth}px;
  padding: ${SPACING.one}px;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${TEXT_COLORS.one};
    background-color: ${LAYER_COLORS.four};
  }
`;
