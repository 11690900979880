export const MIN_ORDER_VALUE = 0.1;

// perps max leverage map
export const DEFAULT_PERPS_MAX_LEVERAGE = 20;
const perpsMaxLeverageMap: { [key: string]: number } = {
  "FRIEND-PERP": 2,
  "SYN-PERP": 10,
  "BANANA-PERP": 2,
  "OX-PERP": 5,
  "BITCOIN-PERP": 2,
  "TAO-PERP": 5,
  "BLAST-PERP": 2,
  "SHFL-PERP": 2,
};

export const getPerpsMaxLeverage = (instrumentName?: string) =>
  instrumentName
    ? perpsMaxLeverageMap[instrumentName] || DEFAULT_PERPS_MAX_LEVERAGE
    : DEFAULT_PERPS_MAX_LEVERAGE;

export const FUNDING_RATE_PRECISION = 4;
export const FUNDING_RATE_PRICE_PRECISION = 6;

// If price impact is over 0.1 (10%), show warning
export const MAX_PRICE_IMPACT_WARNING = 0.1;

export const MARK_PRICE_COLLAR = 0.5;
export const INDEX_PRICE_COLLAR_OPTIONS = 0.0015;
export const INDEX_PRICE_COLLAR_PERPS = 0.005;
