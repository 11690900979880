import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Description,
  HeaderText,
  IntroContainer,
  SubText,
  TextContent,
  VideoContainer,
} from "./style";

import { TEXT_COLORS } from "../../../constants/design/colors";
import BackgroundVideoFX from "../../shared/BackgroundVideoFX/BackgroundVideoFX";
import ExternalLinkText from "../../ExternalLinkText";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import { EarnPageEnum } from ".";
import { useYieldVault } from "../../../hooks/api/yieldVault/useYieldVault";
import { LocalStorageKeyEnum } from "../../../enums/localStorage";

interface IIntroProps {
  setPageIndex?: (pageIndex: number) => void;
  isOnboard?: boolean;
}

function Intro({ setPageIndex, isOnboard = false }: IIntroProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "DepositWithdrawModal.EarnModal",
  });
  const { data: yieldVaultData } = useYieldVault();
  const vaultApyText = useMemo(
    () =>
      yieldVaultData?.apy
        ? `${Number(yieldVaultData.apy) >= 0 ? "+" : ""}${Number(
            yieldVaultData.apy
          ).toFixed(2)}%`
        : "---",
    [yieldVaultData]
  );
  const text = useMemo(() => <>{t("description")}</>, [t]);

  return (
    <IntroContainer isOnboard={isOnboard}>
      <VideoContainer>
        <BackgroundVideoFX />
        <HeaderText>{t("apy")}</HeaderText>
        <SubText>{vaultApyText}</SubText>
      </VideoContainer>
      <TextContent>
        <Description>{text}</Description>
        <ExternalLinkText
          href="https://docs.aevo.xyz/aevo-exchange/aeusd"
          target="_blank"
          rel="noreferrer"
          color={TEXT_COLORS.two}
        >
          {t("learn_more")}
        </ExternalLinkText>
      </TextContent>
      {!isOnboard && (
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          onClick={() => {
            setPageIndex?.(EarnPageEnum.DEPOSIT);
            window.localStorage.setItem(
              LocalStorageKeyEnum.SHOW_EARN_INTRO,
              "true"
            );
          }}
        >
          {t("next")}
        </Button>
      )}
    </IntroContainer>
  );
}

export default Intro;
