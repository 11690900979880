import styled, { css } from "styled-components";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { Button } from "../Buttons/styles";

export const Container = styled.div`
  min-height: ${COMPONENTS.footer.desktop}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${SPACING.three}px;
`;

export const BlockContainer = styled.a`
  ${FONT_CSS.body.five}
  text-decoration: none;
  cursor: pointer;
  color: ${TEXT_COLORS.one};

  &:hover {
    color: ${TEXT_COLORS.one};
  }

  span:first-child {
    ${FONT_CSS.label.two}
    color: ${TEXT_COLORS.three};
  }
`;

export const LeftContainer = styled.div`
  display: flex;
`;

export const RightContainer = styled.div`
  display: flex;

  a {
    ${FONT_CSS.label.two}
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TickerTapeContainer = styled.div`
  display: flex;
  flex: 1;
  margin-left: ${SPACING.three}px;
`;

export const TickerDropdownContainer = styled.div``;

export const MarqueeContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
`;

export const MarqueeInnerContainer = styled.div`
  position: relative;
  width: 1px;
  flex: 1;
  display: flex;
  align-items: center;
`;

export const Scrim = styled.div<{ direction: "left" | "right" }>`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: ${SPACING.three}px;

  ${({ direction }) => {
    switch (direction) {
      case "right":
        return css`
          right: 0;
          background: linear-gradient(
            90deg,
            transparent 0%,
            transparent 0.02%,
            ${COLORS.background.one} 94.41%
          );
        `;
      default:
        return css`
          left: 0;
          background: linear-gradient(
            270deg,
            transparent 0%,
            transparent 0.02%,
            ${COLORS.background.one} 94.41%
          );
        `;
    }
  }}
`;

export const Ticker = styled.button<{
  positiveChange?: boolean;
}>`
  display: flex;
  justify-content: center;
  ${FONT_CSS.data.four}
  color: ${TEXT_COLORS.one};
  margin-right: ${SPACING.three}px;

  span:not(:last-child) {
    margin-right: ${SPACING.one}px;
  }

  span:nth-child(1) {
    ${FONT_CSS.label.two}
    color: ${TEXT_COLORS.one};
  }

  span:nth-child(2) {
    color: ${TEXT_COLORS.two};
  }

  span:nth-child(3) {
    color: ${({ positiveChange }) =>
      positiveChange ? COLORS.positive.one : COLORS.negative.one};
  }

  &:hover {
    opacity: 0.6;
  }
`;

export const AddMarketsToWatchlistContainer = styled.button`
  display: flex;
  ${FONT_CSS.header.eight}
  color: ${TEXT_COLORS.three};
  margin-left: ${SPACING.three}px;
  align-items: center;

  > button {
    margin-left: ${SPACING.one}px;
  }
`;

export const SmallButton = styled(Button)`
  border-radius: ${SPACING.two}px;
  min-width: ${SPACING.four}px;
  min-height: ${SPACING.four}px;
  padding: 0;
`;
