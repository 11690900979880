import styled, { css } from "styled-components";
import { LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../../constants/design/spacing";

export const ViewDetailsContent = styled.div`
  > *:not(:last-child) {
    margin-bottom: ${SPACING.three}px;
  }
`;

export const ViewDetailsRow = styled.div<{ clickable?: boolean }>`
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  padding: 0 ${SPACING.two}px;

  span:first-child {
    ${FONT_CSS.label.one}
    color: ${TEXT_COLORS.three};
  }

  span:last-child {
    ${FONT_CSS.data.three}
    display: flex;
    align-items: center;
    color: ${TEXT_COLORS.one};
  }

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
      &:hover {
        span:last-child {
          transition: opacity 0.2s ease-in-out;
          opacity: 0.6;
        }
      }
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: ${SPACING.three}px;
`;

export const MarketHeaderContainer = styled.div`
  background-color: ${LAYER_COLORS.two};
  display: flex;
  flex: 1;
  text-transform: capitalize;
  align-items: center;
  padding: 0 ${SPACING.two}px;
  padding-right: ${SPACING.three}px;
  border-radius: ${BORDER_RADIUS.five}px;
  margin-right: ${SPACING.two}px;
`;

export const MarketHeaderContent = styled.div`
  ${FONT_CSS.body.four}
  display: flex;
  align-items: center;
`;

export const PriceInfo = styled.div`
  ${FONT_CSS.label.two}
  margin-top: ${SPACING.one}px;
  color: ${TEXT_COLORS.three};

  > span:not(:last-child) {
    margin-right: ${SPACING.two}px;
  }
`;

export const Separator = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 2px;
  margin: 0 ${SPACING.one}px;
  background-color: ${TEXT_COLORS.one};
`;
