import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../constants/design/colors";
import { PageEndpointEnum } from "../../enums/endpoint";
import { useMMP } from "../../hooks/api/mmp/useMMP";
import { BaseModal } from "../BaseModal";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import { MMPWrapper } from "./style";

function MMPTriggeredModal() {
  const navigate = useNavigate();
  const { triggeredMMPs } = useMMP();
  const [hasViewed, setHasViewed] = useState(false);
  const { t } = useTranslation("app", { keyPrefix: "MMPTriggeredModal" });

  const navigateToMMPSettings = useCallback(() => {
    setHasViewed(true);
    navigate(`${PageEndpointEnum.SETTINGS}/market-maker-protection`);
  }, [navigate]);

  const showModal = !hasViewed && !!triggeredMMPs?.length;

  const limitsBreachedText: JSX.Element[] = triggeredMMPs?.map(
    (mmp) => <strong key={mmp.asset}>{mmp.asset}</strong>
  ) || [];
  const limitsText = limitsBreachedText.length > 1
    ? limitsBreachedText.join(", ")
    : limitsBreachedText;

  return (
    <BaseModal show={showModal} title={t("title")} titleStyle={{ color: COLORS.negative.one }} style={{ width: 311 }} onHide={() => setHasViewed(true)}>
      <MMPWrapper>
        {t("mmp_triggered_desc_1")} {limitsText} {t("mmp_triggered_desc_2")}.
      </MMPWrapper>
      <Button buttonTheme={ButtonThemeEnum.NEUTRAL2} onClick={navigateToMMPSettings}>{t("go_to_settings")}</Button>
    </BaseModal>
  );
}

export default MMPTriggeredModal;
