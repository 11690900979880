import { useCallback } from "react";
import { OrderbookTypeFilterButton, OrderbookTypeFiltersContainer } from "./style";
import { OrderbookFilterEnum } from "./shared";

type IOrderbookTypeFilterProps = {
  isSelected?: boolean;
  filterType?: OrderbookFilterEnum;
  onSelected: (filterType?: OrderbookFilterEnum) => void;
}

function OrderbookTypeFilter({ isSelected, filterType, onSelected }: IOrderbookTypeFilterProps) {
  const onSelect = useCallback(() => {
    onSelected(filterType);
  }, [filterType, onSelected]);

  return (
    <OrderbookTypeFilterButton
      filterType={filterType}
      isSelected={isSelected}
      onClick={onSelect}
    >
      <div />
      <div />
      <div />
    </OrderbookTypeFilterButton>
  );
}

type IOrderbookTypeFiltersProps = {
  selectedFilterType?: OrderbookFilterEnum;
  onSelectFilterType: (filter?: OrderbookFilterEnum) => void;
}

function OrderbookTypeFilters({ selectedFilterType, onSelectFilterType }: IOrderbookTypeFiltersProps) {
  return (
    <OrderbookTypeFiltersContainer>
      <OrderbookTypeFilter
        isSelected={!selectedFilterType}
        onSelected={onSelectFilterType}
      />
      <OrderbookTypeFilter
        filterType={OrderbookFilterEnum.BIDS}
        isSelected={selectedFilterType === OrderbookFilterEnum.BIDS}
        onSelected={onSelectFilterType}
      />
      <OrderbookTypeFilter
        filterType={OrderbookFilterEnum.OFFERS}
        isSelected={selectedFilterType === OrderbookFilterEnum.OFFERS}
        onSelected={onSelectFilterType}
      />
    </OrderbookTypeFiltersContainer>
  );
}

export default OrderbookTypeFilters;
