import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalStorageKeyEnum } from "../../enums/localStorage";

export function ReferralRedirectPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [, setReferralCode] = useState<string>();

  useEffect(() => {
    const regex = /\/r\/([^/]+)$/; // Regex pattern to match "/r/" followed by the username
    const username = location.pathname.match(regex);

    if (username && username.length > 1) {
      const code = username[1];
      window.localStorage.setItem(LocalStorageKeyEnum.REFERRAL_CODE, code);
      setReferralCode(code);
    }

    navigate("/");
  }, [location.pathname, navigate]);

  useEffect(() => {
    const code = window.localStorage.getItem(LocalStorageKeyEnum.REFERRAL_CODE);
    if (code) setReferralCode(code);
  }, []);

  return <div />;
}
