import styled from "styled-components";
import { Popover } from "react-bootstrap";
import { BORDER_RADIUS, SPACING } from "../../../constants/design/spacing";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  ICON_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../../constants/design/fontWeight";

export const StakingBadgeTextWrapper = styled.div<{ color: string }>`
  display: flex;
  padding-left: ${SPACING.one}px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  background-color: ${({ color }) => color}26;
  color: ${({ color }) => color};
  ${FONT_CSS.label.two}
`;

export const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.two}px;
  ${FONT_CSS.body.five}
  color: ${TEXT_COLORS.two};
  width: 240px;
`;

export const PopoverContainer = styled(Popover)`
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -8px;
    height: 8px; // Same as the gap
  }

  .popover-arrow {
    display: none;
  }
  background: ${BACKGROUND_COLORS.tooltip};
  border: 1px solid ${BORDER_COLORS.two};
  border-radius: ${BORDER_RADIUS.five}px;
  padding: ${SPACING.three}px;
  max-width: 300px;
  z-index: 99999;
`;

export const Explanation = styled.span`
  ${FONT_CSS.body.five}
  color: ${TEXT_COLORS.two};

  strong {
    font-weight: ${FONT_WEIGHT.normal};
    color: ${TEXT_COLORS.one};
  }
`;

export const TooltipTitle = styled.div`
  ${FONT_CSS.header.eight}
  margin-bottom: ${SPACING.two}px;
`;

export const TooltipWrapper = styled.div<{ stroke?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  svg {
    stroke: ${({ stroke }) => stroke ?? ICON_COLORS.three};
  }
`;
