import styled from "styled-components";
import {
  COLORS,
  ICON_COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../../constants/design/colors";
import { FONT_CSS } from "../../../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../../../constants/design/spacing";
import { frostedGlassBg } from "../../style";

const qrcodeSize = 240;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const QRContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: ${qrcodeSize}px;
    height: ${qrcodeSize}px;
    border-radius: ${BORDER_RADIUS.five}px;
    padding: ${SPACING.two}px;
    background-color: ${COLORS.white.one};
  }
`;

export const HiddenOverlay = styled.div<{ isHidden?: boolean }>`
  ${frostedGlassBg("rgba(24, 24, 30, 0.96)", "rgba(24, 24, 30, 0.99)")}
  ${FONT_CSS.body.five}
  opacity: ${({ isHidden }) => (isHidden ? 1 : 0)};
  transition: all 0.2s ease-in-out;
  width: ${qrcodeSize + 1}px;
  height: ${qrcodeSize + 1}px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.negative.one};
  border-radius: ${BORDER_RADIUS.five}px;
`;

export const ShowHideButton = styled.button`
  ${FONT_CSS.body.five}
  color: ${TEXT_COLORS.two};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${SPACING.three}px;
  border-radius: ${BORDER_RADIUS.four}px;
  background-color: ${LAYER_COLORS.two};
  padding: ${SPACING.two}px;

  > svg {
    margin-left: ${SPACING.one}px;
    stroke: ${ICON_COLORS.two};
  }
`;
