import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Alert } from "../../assets/svg/alert.svg";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { WarningBanner } from "./style";
import useScreenSize from "../../hooks/screenSize/useScreenSize";

interface IVolatilityWarningBannerProps {
  // Key to be stored in localstorage for when dismissing warnings
  dismissKey?: string;
}

function VolatilityWarningBanner({
  dismissKey,
}: IVolatilityWarningBannerProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "pages.TradingPage.VolatilityWarningBanner",
  });
  const { isMobileScreen } = useScreenSize();
  // Only show if not already in local storage
  const [readKeys, setReadKeys] = useState<string[]>();

  // Load from local storage
  useEffect(() => {
    const readBannerKeysStr = localStorage.getItem(
      LocalStorageKeyEnum.VOLATILITY_WARNING_BANNER
    );
    const keys = readBannerKeysStr
      ? (JSON.parse(readBannerKeysStr) as string[])
      : [];
    setReadKeys(keys);
  }, []);

  const onDismiss = useCallback(() => {
    if (!dismissKey || !readKeys) {
      return;
    }
    const newKeys = Array.from(new Set([...readKeys, dismissKey]));
    localStorage.setItem(
      LocalStorageKeyEnum.VOLATILITY_WARNING_BANNER,
      JSON.stringify(newKeys)
    );
    setReadKeys(newKeys);
  }, [dismissKey, readKeys]);

  // Dont show if dimissed
  if (!readKeys || (dismissKey && readKeys.includes(dismissKey))) {
    return null;
  }

  return (
    <WarningBanner
      transition={{
        duration: 0.1,
        ease: "easeInOut",
      }}
      initial={{
        opacity: 0,
        height: 0,
      }}
      animate={{
        opacity: 1,
        height: "auto",
      }}
      exit={{
        opacity: 0,
        height: 0,
      }}
      style={{ borderRadius: isMobileScreen ? 8 : 0 }}
    >
      <button type="button" onClick={onDismiss}>
        <Close />
      </button>
      <Alert />
      {t("warning_message")}
    </WarningBanner>
  );
}

export default VolatilityWarningBanner;
