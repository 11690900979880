import { create } from "zustand";
import { GetAccount200ResponsePositionsInner } from "../../../../codegen-api";
import { mountZustandDevtool } from "../../../../utils/zustand";

interface IWebsocketState {
  positions: {
    [instrumentName: string]: GetAccount200ResponsePositionsInner;
  };

  // Mutations
  updatePosition: (
    wsDataPositions?: GetAccount200ResponsePositionsInner[]
  ) => void;

  updateFallbackPosition: (
    fallbackPositions?: GetAccount200ResponsePositionsInner[]
  ) => void;

  resetPositions: () => void;
}

function transformPositionsToMap(
  positions?: GetAccount200ResponsePositionsInner[]
) {
  const posMap = positions?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.instrument_name]: curr,
    }),
    {} as {
      [instrument: string]: GetAccount200ResponsePositionsInner;
    }
  );
  return posMap;
}

export const usePositionsStore = create<IWebsocketState>()((setState) => ({
  // DATA
  positions: {},
  updatePosition: (wsDataPositions) =>
    setState((state) => {
      const newPositions = { ...state.positions };
      wsDataPositions?.forEach((pos: GetAccount200ResponsePositionsInner) => {
        const size = Number(pos.amount);
        if (!size) {
          delete newPositions[pos.instrument_name];
        } else {
          newPositions[pos.instrument_name] = {
            ...pos,
            amount: String(Math.abs(size)),
          };
        }
      });

      return {
        positions: {
          ...newPositions,
        },
      };
    }),
  updateFallbackPosition: (fallbackPositions) =>
    setState((state) => {
      const prev = state.positions;

      // If previously empty, or no positions from account, just set positions.
      if (!prev || !fallbackPositions) {
        return {
          positions: {
            ...(transformPositionsToMap(fallbackPositions) || {}),
          },
        };
      }

      // If accountData.positions is different than positionsMap (new positions), just set map to accountData
      // We do this by checking the list of instrument names, eg "MANTA-PERP,TRB-PERP"
      const isDifferentInstruments =
        Object.keys(prev).sort().join(",") !==
        fallbackPositions.map((p) => p.instrument_name).join(",");
      if (isDifferentInstruments) {
        return {
          positions: {
            ...(transformPositionsToMap(fallbackPositions) || {}),
          },
        };
      }

      // Now we look for other notable props to update when positions from accounts api changes
      for (let i = 0; i < (fallbackPositions.length || 0); i += 1) {
        const p = fallbackPositions[i];
        const existingPositionFromMap = prev[p.instrument_name];

        // Check triggers
        const stopLossChanged =
          p.closePositionTriggers?.stop_loss?.order_id !==
          existingPositionFromMap.closePositionTriggers?.stop_loss?.order_id;
        const takeProfitChanged =
          p.closePositionTriggers?.take_profit?.order_id !==
          existingPositionFromMap.closePositionTriggers?.take_profit?.order_id;

        const partialStopLossChanged =
          p.partialPositionTriggers?.stop_loss?.length !==
          existingPositionFromMap.partialPositionTriggers?.stop_loss?.length;
        const partialTakeProfitChanged =
          p.partialPositionTriggers?.take_profit?.length !==
          existingPositionFromMap.partialPositionTriggers?.take_profit?.length;

        // Check isolated margin
        const isolatedMarginChanged =
          p.isolated_margin !== existingPositionFromMap.isolated_margin;

        if (
          stopLossChanged ||
          takeProfitChanged ||
          isolatedMarginChanged ||
          partialStopLossChanged ||
          partialTakeProfitChanged
        ) {
          return {
            positions: {
              ...(transformPositionsToMap(fallbackPositions) || {}),
            },
          };
        }
      }

      // No change
      return state;
    }),
  resetPositions: () =>
    setState(() => ({
      positions: {},
    })),
}));

mountZustandDevtool("usePositionsStore", usePositionsStore);
