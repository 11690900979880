import styled, { css } from "styled-components";
import {
  BORDER_COLORS,
  MODAL_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../constants/design/spacing";

export const ClearAllButton = styled.button<{ show?: boolean }>`
  position: absolute;
  top: ${SPACING.two}px;
  right: ${SPACING.twoHalf}px;
  width: 350px;
  background-color: ${MODAL_COLORS.one};
  border: 1px solid ${BORDER_COLORS.one};
  z-index: 99999;
  padding: ${SPACING.two}px;
  border-radius: ${BORDER_RADIUS.two}px;
  color: ${TEXT_COLORS.two};
  transition: 0.45s all ease-out;
  font-family: BananaGrotesk;
  ${FONT_CSS.header.seven}

  &:hover {
    cursor: pointer;
    color: ${TEXT_COLORS.one};
  }

  @media only screen and (max-width: 480px) {
    top: ${SPACING.two}px;
    left: ${SPACING.two}px;
    right: ${SPACING.two}px;
    width: auto;
    padding: ${SPACING.three}px;
  }

  ${({ show }) => {
    if (!show) {
      return css`
        opacity: 0;
        transform: translateX(100%);
        pointer-events: none;
      `;
    }
    return css``;
  }}
`;

export const ToastWrapper = styled.div``;

export const ToastHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: ${COMPONENTS.tabs}px;
  font-family: BananaGrotesk;
  color: ${TEXT_COLORS.one};

  > * {
    margin: auto 0;
    display: flex;
  }

  p {
    margin: auto;
  }

  img {
    margin-left: ${SPACING.twoHalf}px;
    margin-right: ${SPACING.two}px;
    width: 32px;
    height: 32px;
  }

  > div {
    align-items: center;
    &:first-child {
      margin: auto 0;
    }
  }
`;

export const ToastInfoWrapper = styled.div`
  padding-top: ${SPACING.three}px;
  border-top: 1px solid ${BORDER_COLORS.two};
  padding: ${SPACING.three}px;
  font-size: ${FONT_SIZE.one};

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto 0;

    &:not(:last-child) {
      margin-bottom: ${SPACING.two}px;
    }
  }
`;

export const CloseButton = styled.button`
  min-height: 100%;
  width: ${COMPONENTS.tabs}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${BORDER_COLORS.two};
  margin: 0;
`;

export const StatLabel = styled.span`
  color: ${TEXT_COLORS.two};
  font-size: ${FONT_SIZE.one};
`;

export const Header = styled.div`
  display: block;
  margin: auto 0;
  font-size: ${FONT_SIZE.two};
  padding: ${SPACING.two}px 0;
`;

export const SubheaderWrapper = styled.div`
  font-size: ${FONT_SIZE.one};
  color: ${TEXT_COLORS.two};
  text-transform: capitalize;
  padding-right: ${SPACING.two}px;
`;
