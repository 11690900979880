import { isAddress } from "ethers/lib/utils";
import mixpanel from "mixpanel-browser";
import { isPWA } from "./pwa";

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

export const initMixpanel = (debugMode?: boolean) => {
  mixpanel.init(mixpanelToken || "", {
    debug: !!debugMode,
    track_pageview: "url-with-path",
    persistence: "localStorage",
  });

  // SUPER PROPERTIES
  mixpanel.register({
    isPWA,
  });
};

// Call this the first time a user has been identified
export const idUser = (account: string) => {
  mixpanel.identify(account);

  // Set some defaults here if needed
  mixpanel.people.set({ isPWA });
};

// Call this after the user is logged out
export const stopIdUser = () => {
  const id = mixpanel.get_distinct_id();
  if (isAddress(id)) {
    mixpanel.reset();
  }
};

export const trackDownloadPWAClick = () => {
  mixpanel.track("Download PWA Clicked");
};
