import { motion } from "framer-motion";
import styled from "styled-components";
import { SideResponse } from "../../../codegen-api";
import {
  BORDER_COLORS,
  COLORS,
  LAYER_COLORS,
  PRIMARY_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../constants/design/spacing";
import { Button } from "../../Buttons/styles";

export const NotificationsWrapper = styled(motion.div)<{ $isMobile?: boolean }>`
  position: fixed;

  ${({ $isMobile }) =>
    $isMobile
      ? `
    top: ${SPACING.two}px;
    bottom: ${SPACING.two}px;
    right: ${SPACING.two}px;
    left: ${SPACING.two}px;
    `
      : `
    top: ${COMPONENTS.header.main}px;
    right: ${SPACING.two}px;
    bottom: ${COMPONENTS.footer.desktop}px;
    width: ${COMPONENTS.notificationPanel}px;
  `}
  z-index: 200;
  border-radius: ${BORDER_RADIUS.five}px;
  background-color: ${LAYER_COLORS.one};
  overflow-y: scroll;
  border: 1px solid ${BORDER_COLORS.two};
`;

export const NotificationsHeader = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: ${({ isMobile }) =>
    isMobile ? "flex-start" : "space-between"};
  border-bottom: 1px solid ${BORDER_COLORS.two};
  box-sizing: content-box;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${LAYER_COLORS.one};
  height: ${COMPONENTS.header.main}px;
  box-sizing: border-box;
  padding: ${SPACING.three}px;

  div {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: ${SPACING.one}px;
    }
  }

  p {
    ${FONT_CSS.header.seven}
    line-height: inherit;
    margin: 0;
  }

  ${Button} {
    padding: 0;
    min-width: ${COMPONENTS.header.buttons}px;
    min-height: ${COMPONENTS.header.buttons}px;
    position: absolute;
    top: ${SPACING.two}px;
    right: ${SPACING.two}px;
  }
`;

export const MarkAsRead = styled.div`
  > * {
    transition: 0.2s ease-in-out;
    font-size: ${FONT_SIZE.one};
    color: ${TEXT_COLORS.three};
    margin-left: ${SPACING.three}px;

    &:hover {
      color: ${TEXT_COLORS.one};
    }
  }
`;

export const NotificationWrapper = styled.div<{ isRead?: boolean }>`
  display: flex;
  padding: ${SPACING.three}px;
  background-color: ${({ isRead }) =>
    isRead ? "transparent" : LAYER_COLORS.two};
  transition: 0.1s ease-in-out;
  border-bottom: 1px solid ${BORDER_COLORS.two};

  &:hover {
    background-color: ${LAYER_COLORS.one};
  }
`;

export const IconWrapper = styled.div<{
  backgroundColor?: string;
  isRead?: boolean;
}>`
  width: fit-content;
  height: fit-content;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? "transparent"};
  border-radius: 50%;
  margin-right: ${SPACING.two}px;
  justify-content: center;
  display: grid;
  align-items: center;
  margin-top: ${SPACING.one}px;
  position: relative;
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;
  padding: ${SPACING.two}px;
`;

export const LogoIcon = styled(Icon)`
  padding: 0 !important;
  background: black;
  border-radius: 50%;
`;

export const SvgIconWrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: ${SPACING.two}px;

  svg {
    stroke: ${({ color }) => color};
  }
`;

export const Title = styled.div<{ color?: string }>`
  ${FONT_CSS.body.three}
  color: ${({ color }) => color ?? TEXT_COLORS.one};
  text-transform: capitalize;
  margin-bottom: ${SPACING.two}px;
`;

export const Content = styled.div<{ color?: string }>`
  color: ${({ color }) => color ?? TEXT_COLORS.one};
  margin-top: ${SPACING.one}px;
  margin-bottom: ${SPACING.three}px;
  ${FONT_CSS.body.five}
  display: flex;

  > div:not(:last-of-type) {
    margin-right: ${SPACING.one}px;
  }

  > strong {
    color: ${TEXT_COLORS.one};
  }
`;

export const Timestamp = styled.p`
  ${FONT_CSS.body.five}
  color: ${TEXT_COLORS.three};
  margin-top: ${SPACING.three}px;
  margin-bottom: 0;
`;

export const ReadIndicator = styled.span<{ color?: string }>`
  position: absolute;
  width: 6px;
  height: 6px;
  bottom: 2px;
  right: 2px;
  border-radius: 50%;
  background: ${({ color }) => color ?? COLORS.blue.one};
  transition: 0.2s all ease-in-out;
`;

export const PillContent = styled.div`
  display: inline-block;
  text-transform: capitalize;
  ${FONT_CSS.label.three}
`;

export const FillNotificationTitle = styled.div`
  display: inline-flex;

  > span {
    ${FONT_CSS.body.four}
  }
`;

export const SettlementNotificationTitle = styled.div`
  span {
    ${FONT_CSS.body.four}
  }
`;

export const SettlementType = styled.div<{ side?: SideResponse }>`
  ${FONT_CSS.body.five}
  color: ${({ side }) =>
    side === SideResponse.Sell ? COLORS.negative.one : COLORS.positive.one};
  margin-top: ${SPACING.one}px;
`;

export const FillNotificationBody = styled.div`
  display: flex;
  > span {
    ${FONT_CSS.data.four}

    &:not(:last-of-type) {
      margin-right: ${SPACING.one}px;
    }
  }
`;

export const SettlementNotificationBody = styled.div`
  display: flex;
  > span {
    ${FONT_CSS.data.four}
    color: ${TEXT_COLORS.three};

    strong {
      color: ${TEXT_COLORS.one};
    }

    &:not(:last-of-type) {
      margin-right: ${SPACING.one}px;
    }
  }
`;

export const StaticExternalLinkWrapper = styled.a`
  margin-left: ${SPACING.one}px;
  color: ${TEXT_COLORS.one};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${TEXT_COLORS.one};
  }
`;

export const EmptyContent = styled.div`
  padding: ${SPACING.five}px;
  text-align: center;
  border: none !important;
  color: ${TEXT_COLORS.three};
  ${FONT_CSS.label.one}
`;

export const TextWrapper = styled(IconWrapper)`
  background: ${PRIMARY_COLORS.one};
  min-width: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
`;
