/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import currency from "currency.js";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Alert } from "../../../assets/svg/alert.svg";
import {
  GetOrders200Response,
  InstrumentTypeResponse,
  SideResponse,
} from "../../../codegen-api";
import { COLORS } from "../../../constants/design/colors";
import { COMPONENTS } from "../../../constants/design/spacing";
import { MARK_PRICE_COLLAR } from "../../../constants/precision/form";
import { MarketContext } from "../../../contexts/MarketContext";
import { IPriceSize } from "../../../interfaces/Orderbook";
import { getAssetFromSymbol } from "../../../utils/instruments";
import {
  getCumulativeValue,
  isOrderPriceAllowed,
} from "../../../utils/orderbook";
import { truncateString } from "../../../utils/strings";
import { OrderbookFilterEnum } from "../../Perps/TradeOrderbookSection/shared";
import { LabelText, SublabelText } from "../../shared/Label/style";
import {
  RowWithSizeIndicator,
  TableHeaderWrapper,
} from "../../shared/Table/style";
import TooltipExplanation from "../../shared/Tooltip";
import {
  CellsWrapper,
  DetailBlock,
  DetailHeader,
  IVValue,
  PositionDetails,
  ProtectionBarrierRow,
  RowUpdater,
  TradeCol,
  TradeOrderBookWrapper,
} from "./style";

const headers = ["Price / IV", "Size", "Cumulative"] as const;
const tradeFormHeaders = ["Price / IV", "Size"] as const;
type IHeaderType = (typeof headers)[number];

type HeaderFlex = {
  [key in IHeaderType]: number;
};
const headerFlex: HeaderFlex = {
  "Price / IV": 2.5,
  Size: 3,
  Cumulative: 5,
};

interface ISpreadProps {
  price: string;
  size: string;
  cumulativeSize: number;
  sizeIsUSD: boolean;

  iv?: string;
  sizeDecimals: number;
  priceDecimals: number;
  accountSize: number;
  totalSize: number;
  color: string;
  textColor: string;
  type: "bid" | "ask";
  index: number;
  rowHeight?: string;
  isTradeForm?: boolean;
}

const maxCumulativeFillPercent = 0.5;

export function OrderbookRow({
  price,
  iv,
  size,
  cumulativeSize,
  sizeIsUSD,
  sizeDecimals,
  priceDecimals,
  accountSize,
  totalSize,
  color,
  textColor,
  type,
  index,
  rowHeight,
  isTradeForm,
}: ISpreadProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TicketDetails.TradeOrderbook",
  });
  const [isHovering, setHovering] = useState<boolean>(false);

  const cumulativeFill =
    totalSize && size
      ? (Number(cumulativeSize) / totalSize) * maxCumulativeFillPercent
      : 0;
  const accountSizeRelativeToSize =
    accountSize && size ? (accountSize / Number(size)) * cumulativeFill : 0;

  const fillTo = isTradeForm ? "right" : "left";

  return (
    <>
      {isHovering && accountSize > 0 && (
        <PositionDetails
          indexFromLastRow={index}
          positionType={type}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
        >
          <DetailHeader>
            <LabelText>
              {type === "bid" ? t("your_bid") : t("your_ask")}
            </LabelText>
          </DetailHeader>
          <DetailBlock>
            <SublabelText>{t("contracts_offered")}</SublabelText>
            <LabelText>
              {currency(accountSize, { precision: sizeDecimals }).format({
                symbol: "",
              })}
            </LabelText>
          </DetailBlock>
        </PositionDetails>
      )}
      <RowWithSizeIndicator
        highlightOnHover
        needsAnimation
        fillColor={color}
        fillPercent={Number(price) ? cumulativeFill * 100 : 0}
        fillTo={fillTo}
        onRowHover={(e) => setHovering(e)}
        style={{
          zIndex: 1,
          backgroundColor:
            // eslint-disable-next-line no-nested-ternary
            (Number(price) && accountSize) > 0
              ? type === "ask"
                ? COLORS.negative.six
                : COLORS.positive.six
              : undefined,
          height: rowHeight || COMPONENTS.tableRowSmall,
        }}
      >
        {accountSize > 0 && (
          <RowWithSizeIndicator
            fillColor={textColor}
            fillPercent={accountSizeRelativeToSize * 100}
            fillTo={fillTo}
            onRowHover={(e) => setHovering(e)}
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              width: "100%",
              opacity: 0.4,
              zIndex: -1,
            }}
          />
        )}
        <TradeCol style={{ color: textColor, flex: headerFlex["Price / IV"] }}>
          {Number(price) ? (
            <>
              <span>
                {currency(price, { precision: priceDecimals }).format()}
              </span>
              {iv !== undefined && (
                <IVValue>{(Number(iv || 0) * 100).toFixed(2)}%</IVValue>
              )}
            </>
          ) : null}
        </TradeCol>
        <TradeCol style={{ flex: headerFlex.Size }}>
          {Number(size) !== 0
            ? currency(size, {
                precision: sizeIsUSD ? 2 : sizeDecimals,
              }).format({
                symbol: "",
              })
            : ""}
        </TradeCol>
        {!isTradeForm && (
          <TradeCol style={{ flex: headerFlex.Cumulative }}>
            {Number(size) !== 0
              ? currency(cumulativeSize, {
                  precision: sizeIsUSD ? 2 : sizeDecimals,
                }).format({
                  symbol: "",
                })
              : ""}
          </TradeCol>
        )}
      </RowWithSizeIndicator>
    </>
  );
}

interface ITradeOrderbookProps {
  bids: IPriceSize[];
  asks: IPriceSize[];
  sizeDecimals: number;
  priceDecimals: number;
  accountOrders: GetOrders200Response[];
  instrumentId?: string;
  onRowClick: (price: string, size: string, side: SideResponse) => void;
  middleRow?: JSX.Element;
  showIV: boolean;
  filter?: OrderbookFilterEnum;
  markPrice?: number;
  indexPrice?: number;
  // % away from index price that a trade is allowed
  indexPriceCollar: number;
  headerRowHeight?: string;
  rowHeight?: string;
  showValueInUSD?: boolean;
  orderbookTicksize?: number | undefined;
  isTradeForm?: boolean;
  isOptionsTradeForm?: boolean;
}

function TradeOrderbook({
  bids,
  asks,
  sizeDecimals,
  priceDecimals,
  accountOrders,
  instrumentId,
  onRowClick,
  middleRow,
  showIV,
  markPrice,
  indexPrice,
  indexPriceCollar,
  headerRowHeight,
  rowHeight,
  showValueInUSD,
  filter,
  orderbookTicksize,
  isTradeForm,
  isOptionsTradeForm,
}: ITradeOrderbookProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TicketDetails.TradeOrderbook",
  });

  // Sort asks and bids ascending, from lowest price to highest price.
  // Make sure theres always a balanced no of rows on both sides
  const { market } = useContext(MarketContext);
  const asset = getAssetFromSymbol(instrumentId || "");
  const orderbookHeaders = isTradeForm ? tradeFormHeaders : headers;
  let sortedBids = bids;
  let sortedAsks = asks;

  // Adds back zero values if available
  if (bids.length > sortedBids.length) {
    const diff = bids.length - sortedBids.length;
    sortedBids = [...sortedBids, ...Array(diff).fill(["0", "0"])];
  }
  if (asks.length > sortedAsks.length) {
    const diff = asks.length - sortedAsks.length;
    sortedAsks = [...sortedAsks, ...Array(diff).fill(["0", "0"])];
  }

  // Limit the number of bids and asks to 3 each if isOptionsTradeForm is true
  if (isOptionsTradeForm) {
    if (bids.length < 3) {
      const diff = 3 - bids.length;
      sortedBids = [...sortedBids, ...Array(diff).fill(["0", "0"])];
    } else {
      sortedBids = sortedBids.slice(0, 3);
    }
    if (asks.length < 3) {
      const diff = 3 - bids.length;
      sortedAsks = [...sortedAsks, ...Array(diff).fill(["0", "0"])];
    } else {
      sortedAsks = sortedAsks.slice(0, 3);
    }
  }

  const protectionBarrierContent = useCallback(
    (type: "bid" | "ask") => {
      if (market.derivative === InstrumentTypeResponse.Option) {
        return (
          <TooltipExplanation
            title={t("order_protection_barrier")}
            explanation={
              <>
                {t("order_protection_barrier_explanation_1", {
                  type: type === "bid" ? "bids" : "offers",
                })}
                <br />
                <br />
                {type === "bid" ? (
                  <>
                    {t("order_protection_barrier_explanation_2")}
                    <br />
                    <strong>
                      {t("order_protection_barrier_explanation_3", {
                        markCollar: MARK_PRICE_COLLAR,
                        indexCollar: indexPriceCollar,
                      })}
                    </strong>
                  </>
                ) : (
                  <>
                    {t("order_protection_barrier_explanation_4")}
                    <br />
                    <strong>
                      {t("order_protection_barrier_explanation_5", {
                        markCollar: MARK_PRICE_COLLAR,
                        indexCollar: indexPriceCollar,
                      })}
                    </strong>
                  </>
                )}
              </>
            }
            renderContent={({ ref, ...triggerHandler }) => (
              <ProtectionBarrierRow
                type={type}
                ref={ref}
                {...triggerHandler}
                onClick={(e) => e.stopPropagation()}
              >
                {t("order_protection_barrier")} <Alert />
              </ProtectionBarrierRow>
            )}
          />
        );
      }

      return undefined;
    },
    [indexPriceCollar, market.derivative, t]
  );

  const getHeaderTitle = useCallback(
    (header: IHeaderType) => {
      switch (header) {
        case "Cumulative":
          return t("cumulative");
        case "Price / IV":
          return t("price_iv");
        case "Size":
          return t("size");
        default:
          return "";
      }
    },
    [t]
  );

  const askCells = () => {
    if (filter !== OrderbookFilterEnum.BIDS) {
      // Checks for the ask IV against mark's ask IV
      const protectionBarrierIndex = sortedAsks.findIndex(
        (ask) =>
          !!Number(ask[0]) &&
          !isOrderPriceAllowed(
            Number(ask[0] || 0),
            Number(markPrice || 0),
            Number(indexPrice || 0),
            MARK_PRICE_COLLAR,
            indexPriceCollar
          )
      );

      const rows = sortedAsks.map(([price, size, iv], index) => {
        const isNotLastRow =
          sortedAsks.length > 1 ? index !== sortedAsks.length - 1 : true;
        const hasBarrier = protectionBarrierIndex === index && isNotLastRow;

        // Sizes is price * contracts if show values in USD
        const realSize = showValueInUSD
          ? Number(size) * Number(price)
          : Number(size);
        const allSizes = sortedAsks.map((v) =>
          showValueInUSD ? Number(v[0]) * Number(v[1]) : Number(v[1])
        );
        const totalSize = allSizes.reduce((prev, s) => prev + Number(s), 0);
        const cumulative = getCumulativeValue(allSizes, index);

        const accountSize = (accountOrders || [])
          .filter((order) => {
            const priceUpperBound = Number(price) + (orderbookTicksize || 0);
            // If theres a tick size, we need to check if the order falls in the same level
            const orderWithinLevel = orderbookTicksize
              ? Number(order.price) >= Number(price) &&
                Number(order.price) < priceUpperBound
              : order.price === price;
            return order.instrument_name === instrumentId && orderWithinLevel;
          })
          .reduce((acc, current) => acc + Number(current.amount), 0);

        const key = Number(price) ? `${price}-${size}` : index;

        return (
          <RowUpdater
            key={key}
            onClick={() => onRowClick(price, size, SideResponse.Sell)}
            aria-hidden="true"
            style={{
              pointerEvents: Number(price) ? "auto" : "none",
            }}
          >
            <OrderbookRow
              index={Math.max(sortedAsks.length - index - 1, 0)}
              price={price}
              iv={showIV ? iv : undefined}
              size={String(realSize)}
              sizeIsUSD={!!showValueInUSD}
              sizeDecimals={sizeDecimals}
              priceDecimals={priceDecimals}
              accountSize={accountSize}
              totalSize={totalSize}
              cumulativeSize={cumulative}
              color={COLORS.negative.four}
              textColor={COLORS.negative.one}
              type="ask"
              rowHeight={rowHeight}
              isTradeForm={isTradeForm}
            />
            {hasBarrier && Number(size) > 0 && protectionBarrierContent("ask")}
          </RowUpdater>
        );
      });
      return rows.reverse();
    }
    return undefined;
  };

  const bidCells = () => {
    if (filter !== OrderbookFilterEnum.OFFERS) {
      const protectionBarrierIndex = sortedBids.findIndex(
        (bid) =>
          !!Number(bid[0]) &&
          !isOrderPriceAllowed(
            Number(bid[0] || 0),
            Number(markPrice || 0),
            Number(indexPrice || 0),
            MARK_PRICE_COLLAR,
            indexPriceCollar
          )
      );

      return sortedBids.map(([price, size, iv], index) => {
        const isNotLastRow =
          sortedBids.length - 1 > 1 ? index !== sortedBids.length - 1 : true;
        const hasBarrier = protectionBarrierIndex === index && isNotLastRow;

        // Sizes is price * contracts if show values in USD
        const realSize = showValueInUSD
          ? Number(size) * Number(price)
          : Number(size);
        const allSizes = sortedBids.map((v) =>
          showValueInUSD ? Number(v[0]) * Number(v[1]) : Number(v[1])
        );
        const totalSize = allSizes.reduce((prev, s) => prev + Number(s), 0);
        const cumulative = getCumulativeValue(allSizes, index);

        const accountSize = (accountOrders || [])
          .filter((order) => {
            const priceUpperBound = Number(price) + (orderbookTicksize || 0);
            // If theres a tick size, we need to check if the order falls in the same level
            const orderWithinLevel = orderbookTicksize
              ? Number(order.price) >= Number(price) &&
                Number(order.price) < priceUpperBound
              : order.price === price;
            return order.instrument_name === instrumentId && orderWithinLevel;
          })
          .reduce((acc, current) => acc + Number(current.amount), 0);

        const key = Number(price) ? `${price}-${size}` : index;

        return (
          <RowUpdater
            key={key}
            onClick={() => onRowClick(price, size, SideResponse.Buy)}
            aria-hidden="true"
            style={{
              pointerEvents: Number(price) ? "auto" : "none",
            }}
          >
            {hasBarrier && Number(size) > 0 && protectionBarrierContent("bid")}
            <OrderbookRow
              index={Math.max(sortedBids.length - index - 1, 0)}
              price={price}
              iv={showIV ? iv : undefined}
              size={String(realSize)}
              sizeIsUSD={!!showValueInUSD}
              sizeDecimals={sizeDecimals}
              priceDecimals={priceDecimals}
              accountSize={accountSize}
              totalSize={totalSize}
              cumulativeSize={cumulative}
              color={COLORS.positive.four}
              textColor={COLORS.positive.one}
              type="bid"
              rowHeight={rowHeight}
              isTradeForm={isTradeForm}
            />
          </RowUpdater>
        );
      });
    }
    return undefined;
  };

  return (
    <TradeOrderBookWrapper isTradeForm={isTradeForm}>
      <TableHeaderWrapper height={headerRowHeight}>
        <RowWithSizeIndicator>
          {orderbookHeaders.map((header) => {
            if (header === "Price / IV") {
              return (
                <TradeCol
                  key={header}
                  isHeader
                  style={{ flex: headerFlex[header] }}
                >
                  {showIV ? t("price_iv") : t("price")}
                </TradeCol>
              );
            }
            if (header === "Size" || header === "Cumulative") {
              return (
                <TradeCol
                  key={header}
                  isHeader
                  style={{ flex: headerFlex[header] }}
                >
                  {getHeaderTitle(header)} (
                  {showValueInUSD ? "USD" : truncateString(asset || "", 4)})
                </TradeCol>
              );
            }
            return (
              <TradeCol
                key={header}
                isHeader
                style={{ flex: headerFlex[header] }}
              >
                {getHeaderTitle(header)}
              </TradeCol>
            );
          })}
        </RowWithSizeIndicator>
      </TableHeaderWrapper>
      <CellsWrapper
        style={
          filter
            ? {
                justifyContent:
                  filter === OrderbookFilterEnum.BIDS
                    ? "flex-start"
                    : "flex-end",
              }
            : isOptionsTradeForm
            ? {
                justifyContent: "flex-start",
              }
            : {
                justifyContent: "center",
              }
        }
      >
        <div>
          {/* ASKS */}
          {askCells()}
          {/* MIDDLE ROW */}
          {!!middleRow && middleRow}
          {/* BIDS */}
          {bidCells()}
        </div>
      </CellsWrapper>
    </TradeOrderBookWrapper>
  );
}

export default TradeOrderbook;
