import currency from "currency.js";
import { isAddress } from "ethers/lib/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import infoIcon from "../../../assets/svg/info.svg";
import {
  GetAccount200Response,
  GetAccount200ResponseCollateralsInner,
} from "../../../codegen-api";
import { AllCollaterals } from "../../../constants/addresses";
import { COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";
import { ChainIdEnum } from "../../../enums/chain";
import useWallet, { ID_TO_CHAINS } from "../../../hooks/wallet/useWallet";
import {
  getAssetLogo,
  getAssetShortName,
  getCollateralAssets,
} from "../../../utils/asset/assets";
import {
  supportedChainId,
  supportedSigningChainIds,
} from "../../../utils/wallet/connectors";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import { InfoRow } from "../../ConnectWalletModal/Deposit/style";
import { Chevron } from "../../shared/Chevron/style";
import DropdownSimple from "../../shared/DropdownSimple";
import { Input } from "../../shared/Input";
import ModalOverlayInfo from "../../shared/ModalOverlayInfo";
import { OverlayInfoContent } from "../../shared/ModalOverlayInfo/style";
import {
  ButtonsContainer,
  DepositAssetDropdownItem,
  DepositAssetDropdownTitle,
  ErrorMessage,
  Footer,
  InputContainer,
  InputError,
  InputLabel,
  MaxButton,
} from "../style";
import { ITransferData } from "./model";

interface IInitialStateProps {
  accountData?: GetAccount200Response;
  defaultTransferData?: ITransferData;
  handleTransfer: (data: ITransferData) => void;

  asset: AllCollaterals;
  setAsset: (asset: AllCollaterals) => void;
}

type IFormFieldValues = {
  amount?: string;
  address?: string;
};

function InitialStateModal({
  accountData,
  handleTransfer,
  defaultTransferData,
  asset,
  setAsset,
}: IInitialStateProps) {
  const { chainId, setChainToSwitch } = useWallet();
  const { t } = useTranslation("app", {
    keyPrefix: "DepositWithdrawModal.TransferModal.InitialStateModal",
  });
  const { t: formError } = useTranslation("formErrors");
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    clearErrors,
    trigger,
  } = useForm<IFormFieldValues>({
    mode: "onChange",
    defaultValues: defaultTransferData,
  });

  const [assetDropdownOpen, setAssetDropdownOpen] = useState(false);

  const [showHelpInfo, setShowHelpInfo] = useState(false);
  const [depositWithdrawError, setDepositWithdrawError] = useState<
    string | undefined
  >(t("deposit_failed"));

  const collateralInfo: GetAccount200ResponseCollateralsInner | undefined =
    accountData?.collaterals?.find((c) => c.collateral_asset === asset);

  // Reset errors
  useEffect(() => {
    clearErrors();
    setDepositWithdrawError(undefined);
    setShowHelpInfo(false);
  }, [clearErrors]);

  const transferrableBalance = useMemo(() => {
    if (collateralInfo) {
      const { balance } = collateralInfo;
      const creditBalance = accountData?.credit
        ? Number(accountData.credit)
        : 0;
      const availableBalance = collateralInfo.available_balance;
      return (
        Math.min(Number(balance), Number(availableBalance)) - creditBalance
      );
    }
    return undefined;
  }, [accountData?.credit, collateralInfo]);

  const submitButtonContent = useMemo(() => {
    if (!chainId || !supportedSigningChainIds.includes(chainId)) {
      return (
        <Button
          fullWidth
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          type="button"
          onClick={() => setChainToSwitch(supportedChainId)}
        >
          {t("switch_to", {
            chain: ID_TO_CHAINS[supportedChainId as ChainIdEnum],
          })}
        </Button>
      );
    }

    return (
      <>
        <Button
          fullWidth
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          type={"submit"}
          disabled={!isValid}
        >
          {t("next")}
        </Button>
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          type={"button"}
          onClick={() => setShowHelpInfo(true)}
        >
          <img src={infoIcon} alt="info" />
        </Button>
      </>
    );
  }, [chainId, setChainToSwitch, isValid, t]);

  const onMaxClick = useCallback(() => {
    clearErrors("amount");
    setValue("amount", String(transferrableBalance || 0));
    trigger("amount");
  }, [clearErrors, setValue, transferrableBalance, trigger]);

  const onSubmit: SubmitHandler<IFormFieldValues> = useCallback(
    async (data) => {
      const { address, amount } = data;
      if (address && amount) {
        setDepositWithdrawError(undefined);
        handleTransfer({ address, amount });
      }
    },
    [handleTransfer]
  );

  return (
    <div>
      <ModalOverlayInfo
        show={showHelpInfo}
        onClose={() => setShowHelpInfo(false)}
        height="210px"
      >
        <OverlayInfoContent>
          <span>{t("transfers")}</span>
          <span>{t("transfers_desc")}</span>
        </OverlayInfoContent>
      </ModalOverlayInfo>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputContainer>
          <InputLabel>{t("recipient_address")}</InputLabel>
          <Input
            placeholder="0x56b...6405"
            type="text"
            {...register("address", {
              required: true,
              validate: {
                isAddress: (v) => isAddress(v || ""),
                transferToSelf: (v) => v !== accountData?.account,
              },
            })}
            error={!!errors.address?.type}
          />
          {errors.address?.type === "required" && (
            <InputError>{formError("address.required")}</InputError>
          )}
          {errors.address?.type === "isAddress" && (
            <InputError>{formError("address.isAddress")}</InputError>
          )}
          {errors.address?.type === "transferToSelf" && (
            <InputError>{formError("address.transferToSelf")}</InputError>
          )}
        </InputContainer>
        <InputContainer>
          <InputLabel>{t("amount")}</InputLabel>
          <Input
            placeholder="0.00"
            leftAccessory={
              <DropdownSimple<AllCollaterals>
                dropDirection={"down"}
                show={assetDropdownOpen}
                onToggle={setAssetDropdownOpen}
                toggleStyle={{
                  padding: 0,
                  paddingLeft: SPACING.two,
                  paddingRight: SPACING.two,
                }}
                title={
                  <DepositAssetDropdownTitle>
                    <img
                      src={getAssetLogo(asset)}
                      width={24}
                      height={24}
                      alt={asset}
                    />
                    <Chevron
                      size="small"
                      direction={assetDropdownOpen ? "up" : "down"}
                    />
                  </DepositAssetDropdownTitle>
                }
                items={getCollateralAssets(undefined, "transfer")}
                getItemLabel={(v) => (
                  <DepositAssetDropdownItem selected={v === asset}>
                    <img src={getAssetLogo(v)} width={24} height={24} alt={v} />
                    <span>{getAssetShortName(v)}</span>
                  </DepositAssetDropdownItem>
                )}
                selectedItem={asset}
                onSelectItem={(v) => {
                  setAsset(v);
                }}
              />
            }
            rightAccessory={
              <MaxButton onClick={onMaxClick} type="button">
                {t("max")}
              </MaxButton>
            }
            type="number"
            {...register("amount", {
              required: true,
              validate: {
                moreThanZero: (v) => parseFloat(v || "") > 0,
                lessThanEqualToTransferableBalance: (v) =>
                  parseFloat(v || "") <= Number(transferrableBalance),
              },
            })}
            error={!!errors.amount?.type}
          />
          {errors.amount?.type === "required" && (
            <InputError>{formError("amount.required")}</InputError>
          )}
          {errors.amount?.type === "moreThanZero" && (
            <InputError>{formError("amount.moreThanZero")}</InputError>
          )}
          {errors.amount?.type === "lessThanEqualToTransferableBalance" && (
            <InputError>
              {formError("amount.lessThanEqualToTransferableBalance")}
            </InputError>
          )}
        </InputContainer>
        <InfoRow>
          <span>{t("exchange_balance")}</span>
          <span>
            {collateralInfo
              ? currency(collateralInfo.balance).format({
                  symbol: "",
                })
              : "---"}
          </span>
        </InfoRow>
        <InfoRow>
          <span
            style={
              errors.amount?.type === "lessThanEqualToTransferableBalance"
                ? { color: COLORS.negative.one }
                : undefined
            }
          >
            {t("transferable_balance")}
          </span>
          <span
            style={
              errors.amount?.type === "lessThanEqualToTransferableBalance"
                ? { color: COLORS.negative.one }
                : undefined
            }
          >
            {transferrableBalance
              ? currency(transferrableBalance).format({ symbol: "" })
              : "---"}
          </span>
        </InfoRow>
        {depositWithdrawError && (
          <ErrorMessage>{depositWithdrawError}</ErrorMessage>
        )}
        <ButtonsContainer
          style={{
            paddingTop: SPACING.three,
          }}
        >
          {submitButtonContent}
        </ButtonsContainer>
        <Footer>{t("recipient_address_access_desc")}</Footer>
      </form>
    </div>
  );
}

export default InitialStateModal;
