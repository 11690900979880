import currency from "currency.js";
import { SPACING } from "../../../constants/design/spacing";
import {
  StopLossInput,
  TakeProfitInput,
} from "../../TradeForm/PerpsTradeForm/components/form";
import { Form } from "../../TradeForm/style";
import DropdownSimple from "../../shared/DropdownSimple";
import {
  Tpsl,
  ITPSLFormContentProps,
  NotificationTypeEnum,
  TpslType,
} from "./TPSLContent.types";
import TPSLNotificationText from "./TPSLNotificationText";

function TPSLFormContent({
  actualSlTriggerPrice,
  actualTpTriggerPrice,
  contractPriceStep,
  errors,
  handleSubmit,
  markPrice,
  onConfirmTPSLForPosition,
  pricePrecision,
  resetSlInput,
  resetTpInput,
  slDropdownOpen,
  slType,
  stopLossExpectedPnl,
  stopLossRegister,
  takeProfitExpectedPnl,
  takeProfitRegister,
  tpDropdownOpen,
  tpType,
  setTpDropdownOpen,
  setTpType,
  setSlDropdownOpen,
  setSlType,
}: ITPSLFormContentProps) {
  return (
    <Form
      style={{ padding: 0, marginBottom: SPACING.three }}
      onSubmit={handleSubmit(onConfirmTPSLForPosition)}
    >
      <TakeProfitInput
        key={tpType}
        placeholder={"0.00"}
        markPrice={currency(markPrice || 0, {
          precision: pricePrecision,
        }).format()}
        leftAccessory={
          <DropdownSimple<TpslType>
            dropDirection={"up"}
            show={tpDropdownOpen}
            onToggle={setTpDropdownOpen}
            title={tpType}
            items={Object.values(Tpsl)}
            selectedItem={tpType}
            onSelectItem={setTpType}
          />
        }
        minPrice={contractPriceStep}
        register={takeProfitRegister}
        resetInput={resetTpInput}
        errors={errors}
      />
      <TPSLNotificationText
        actualTriggerPrice={actualTpTriggerPrice}
        pricePrecision={pricePrecision}
        currencyExpectedPnl={takeProfitExpectedPnl}
        noticationType={NotificationTypeEnum.TP}
      />
      <div style={{ marginTop: SPACING.three }}>
        <StopLossInput
          key={slType}
          placeholder={"0.00"}
          markPrice={currency(markPrice || 0, {
            precision: pricePrecision,
          }).format()}
          leftAccessory={
            <DropdownSimple<TpslType>
              dropDirection={"up"}
              show={slDropdownOpen}
              onToggle={setSlDropdownOpen}
              title={slType}
              items={Object.values(Tpsl)}
              selectedItem={slType}
              onSelectItem={setSlType}
            />
          }
          minPrice={contractPriceStep}
          register={stopLossRegister}
          resetInput={resetSlInput}
          errors={errors}
        />
      </div>
      <TPSLNotificationText
        actualTriggerPrice={actualSlTriggerPrice}
        pricePrecision={pricePrecision}
        currencyExpectedPnl={stopLossExpectedPnl}
        noticationType={NotificationTypeEnum.SL}
      />
    </Form>
  );
}

export default TPSLFormContent;
