import * as Sentry from "@sentry/react";
import moment from "moment";
import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { publicApi } from "../../../services/api/apiFetcher";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";

/**
 * driftTime Measures the drift time between server and system clock.
 */
interface IClock {
  driftTime: number;
}

interface IClockTimestamp {
  /**
   * Returns the UNIX timestamp in seconds
   * @returns
   */
  getTimestamp: () => number;
}

// Get the current timestamp from server, and measure clockdrift
export const useClock = (): IClockTimestamp => {
  const fetcher = publicApi();

  // We use swrImmutable to call the clock API ONCE,
  // and then calculate the clock drift before storing it to cache.
  const swr = useSWRImmutable<IClock, Error>(
    [APIEndpointEnum.TIME],
    async () => {
      const time = (await (await fetcher.getTime())()).data;
      const currentTimestamp = moment().unix();
      const drift = Number(time.timestamp) - currentTimestamp;

      // log if more than 5 minutes of drift
      if (drift > 60 * 5) {
        Sentry.captureMessage("Clock Drift", {
          extra: {
            machineTimestamp: currentTimestamp,
            serverResponse: JSON.stringify(time),
          },
        });
      }

      return {
        driftTime: drift,
      };
    },
    {
      use: [useLoggerMiddleware],
    }
  );

  const getTimestamp = useCallback(() => {
    const drift = swr.data?.driftTime || 0;
    const currentTimestamp = moment().unix();
    return currentTimestamp + drift;
  }, [swr.data?.driftTime]);

  return {
    getTimestamp,
  };
};
