// Shared styles

import styled, { css, keyframes } from "styled-components";
import { BACKGROUND_COLORS, COLORS } from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { SPACING } from "../../constants/design/spacing";

export const ModalErrorMessage = styled.div`
  ${FONT_CSS.label.two}
  color: ${COLORS.negative.one};
  text-align: center;
  padding-top: ${SPACING.three}px;
  padding-bottom: ${SPACING.one}px;
`;

export const ModalWarningMessage = styled.div`
  ${FONT_CSS.label.two}
  color: ${COLORS.system.one};
  text-align: center;
  padding-top: ${SPACING.three}px;
  max-width: calc(311px - ${SPACING.three * 2}px);
`;

// keyframes
export const fadeIn = keyframes`
  to {
    opacity: 1;
  }
`;

export const flash = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

// shared CSS
export const frostedGlassBg = (
  bgColor?: string,
  fallbackBgColor?: string,
  blur?: number
) => css`
  -webkit-backdrop-filter: blur(${blur ?? 2}px);
  backdrop-filter: blur(${blur ?? 2}px);

  /* Only give frosted glass effect if supported. If not, use a darker bgcolor*/
  background-color: ${fallbackBgColor ?? BACKGROUND_COLORS.seven};
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: ${bgColor || "rgba(6, 6, 12, 0.16)"};
  }
`;

export const fadeInCSS = css`
  opacity: 0;
  animation: ${fadeIn} 0.1s ease-in forwards;
`;

export const flashingCSS = css`
  opacity: 0;
  animation: ${flash} 1.2s ease-in-out infinite;
`;
