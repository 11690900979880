export enum UptimeStatusEnum {
  PAUSED = 0,
  UNCHECKED = 1,
  UP = 2,
  SEEMS_DOWN = 8,
  DOWN = 9
}

export interface IUptimeMonitor {
  id: number;
  status: UptimeStatusEnum;
}
