import { useState, useEffect } from "react";
import moment from "moment";
import { CampaignDetails, CampaignEnum } from ".";

interface ICountdownDetails {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  isLive: boolean;
  isUpcoming: boolean; // New flag for upcoming campaigns
}

export const useCountdown = () => {
  const [countdownMapping, setCountdownMapping] = useState<{
    [key in CampaignEnum]: ICountdownDetails;
  }>({
    [CampaignEnum.WSB]: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      isLive: false,
      isUpcoming: false,
    },
  });

  useEffect(() => {
    const updateCountdowns = () => {
      const now = moment.utc();
      const updatedMapping = { ...countdownMapping };

      // Loop over each campaign and update its countdown
      Object.keys(CampaignDetails).forEach((campaignKey) => {
        const { startTime, endTime } =
          CampaignDetails[campaignKey as CampaignEnum];
        const competitionStartTime = moment.utc(startTime / 1000000); // Assuming startTime is in nanoseconds
        const competitionEndTime = moment.utc(endTime / 1000000); // Assuming endTime is in nanoseconds

        if (now.isBefore(competitionStartTime)) {
          // If current time is before startTime, countdown to startTime
          const duration = moment.duration(competitionStartTime.diff(now));
          updatedMapping[campaignKey as CampaignEnum] = {
            days: duration.days(),
            hours: duration.hours(),
            minutes: duration.minutes(),
            seconds: duration.seconds(),
            isLive: false, // Not live yet
            isUpcoming: true, // Campaign is upcoming
          };
        } else if (now.isBefore(competitionEndTime)) {
          // If current time is between startTime and endTime, countdown to endTime
          const duration = moment.duration(competitionEndTime.diff(now));
          updatedMapping[campaignKey as CampaignEnum] = {
            days: duration.days(),
            hours: duration.hours(),
            minutes: duration.minutes(),
            seconds: duration.seconds(),
            isLive: true, // Campaign is live
            isUpcoming: false, // Not upcoming anymore
          };
        } else {
          // If the duration is negative, set everything to 0 (campaign is over)
          updatedMapping[campaignKey as CampaignEnum] = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            isLive: false,
            isUpcoming: false, // Campaign is over
          };
        }
      });

      // Update the countdown mapping state
      setCountdownMapping(updatedMapping);
    };

    // Initial call
    updateCountdowns();

    // Set interval to update every second
    const intervalId = setInterval(updateCountdowns, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Return the countdown mapping
  return countdownMapping;
};
