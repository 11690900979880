import styled, { css } from "styled-components";
import { TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { SPACING } from "../../constants/design/spacing";

export const TextWrapper = styled.p<{
  color?: string;
  hoverColor?: string;
  disableHoverAnimation?: boolean;
  inline?: boolean;
}>`
  ${FONT_CSS.header.seven}
  display: ${({ inline }) => (inline ? "inline" : "flex")};
  align-items: center;
  justify-content: center;
  margin-bottom: 0;

  a {
    text-decoration: none;
    transition: all 0.4s ease-in-out;
    color: ${({ color }) => color ?? TEXT_COLORS.one} !important;
  }

  svg {
    stroke: ${({ color }) => color ?? TEXT_COLORS.one} !important;
    margin-left: ${SPACING.one}px;
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    a {
      ${({ hoverColor }) =>
        hoverColor &&
        css`
          color: ${hoverColor} !important;
        `}
    }
    svg {
      ${({ hoverColor }) =>
        hoverColor &&
        css`
          stroke: ${hoverColor} !important;
        `}
    }
    cursor: pointer;
  }

  ${({ disableHoverAnimation }) =>
    !disableHoverAnimation &&
    css`
      svg {
        transform: translate(1px, -1px);
      }
    `}
`;
