import { IPerpsMarket } from "../../contexts/MarketInstrumentContext/useGetMarkets";
import { useOrderbookWSS } from "../../hooks/wss/useOrderbookWSS";
import DepthChart from "../DepthFundingChart/DepthChart";

interface IDepthSectionProps {
  perpInstrument: IPerpsMarket;
}

function DepthSection({ perpInstrument }: IDepthSectionProps) {
  const orderbook = useOrderbookWSS(perpInstrument?.instrument_name);

  return (
    <DepthChart
      bids={orderbook?.bids || []}
      asks={orderbook?.asks || []}
      asset={perpInstrument.underlying_asset}
      instrumentType={perpInstrument.instrument_type}
    />
  );
}

export default DepthSection;
