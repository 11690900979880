import { Suspense, lazy } from "react";
import { IOptionsTradingHeaderProps } from "./OptionsTradingHeader";
import { TradingHeaderWrapper } from "../style";

const OptionsTradingHeader = lazy(
  () =>
    import(
      /* webpackChunkName: "OptionsTradingHeader" */
      /* webpackPrefetch: true */
      "./OptionsTradingHeader"
    )
);

function LazyOptionsTradingHeader({
  isMobileScreen,
}: IOptionsTradingHeaderProps) {
  return (
    <Suspense fallback={<TradingHeaderWrapper />}>
      <OptionsTradingHeader isMobileScreen={isMobileScreen} />
    </Suspense>
  );
}

export default LazyOptionsTradingHeader;
