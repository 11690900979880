import { Popover } from "react-bootstrap";
import styled from "styled-components";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../../constants/design/spacing";
import { FONT_WEIGHT } from "../../../constants/design/fontWeight";

export const PopoverContainer = styled(Popover)`
  .popover-arrow {
    display: none;
  }
  background: ${BACKGROUND_COLORS.tooltip};
  border: 1px solid ${BORDER_COLORS.two};
  border-radius: ${BORDER_RADIUS.five}px;
  padding: ${SPACING.three}px;
  max-width: 300px;
`;

export const PopoverContainerForTooltip = styled(Popover)`
  position: relative;
  z-index: 99999;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -8px;
    height: 8px; // Same as the gap
  }

  .popover-arrow {
    display: none;
  }
  background: ${BACKGROUND_COLORS.tooltip};
  border: 1px solid ${BORDER_COLORS.two};
  border-radius: ${BORDER_RADIUS.five}px;
  padding: ${SPACING.three}px;
  max-width: 300px;
`;

export const Title = styled.div`
  ${FONT_CSS.header.eight}
  margin-bottom: ${SPACING.two}px;
`;

export const Explanation = styled.span`
  ${FONT_CSS.body.five}
  color: ${TEXT_COLORS.two};

  strong {
    font-weight: ${FONT_WEIGHT.normal};
    color: ${TEXT_COLORS.one};
  }
`;
