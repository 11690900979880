import styled, { css } from "styled-components";
import {
  BORDER_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../constants/design/spacing";

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 100%;
`;

export const SectionContainer = styled.div<{
  padding?: boolean;
  hidden: boolean;
}>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => (padding ? `0 ${SPACING.three}px` : 0)};
  min-height: 100%;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;

export const PositionTradeContainer = styled.div`
  height: ${COMPONENTS.mobilePerpPositionBarHeight}px;
  padding: ${SPACING.three}px;
  display: flex;
  > div {
    flex: 1;

    &:first-child {
      margin-right: ${SPACING.two}px;
    }
    &:last-child {
      margin-left: ${SPACING.two}px;
    }
  }
`;

export const PositionContainer = styled.div`
  background-color: ${LAYER_COLORS.one};
  border-radius: ${BORDER_RADIUS.five}px;
  padding: ${SPACING.two}px;
  display: flex;
  align-items: center;
`;

export const PositionSize = styled.span`
  ${FONT_CSS.data.three}
  > span {
    &:last-child {
      ${FONT_CSS.label.three}
      color: ${TEXT_COLORS.two};
      margin-left: ${SPACING.one}px;
    }
  }
`;

export const PnLPercent = styled.span<{ pnl: number }>`
  color: ${({ pnl }) => (pnl >= 0 ? COLORS.positive.one : COLORS.negative.one)};
  ${FONT_CSS.data.four}
`;

export const Tab = styled.button<{ isSelected?: boolean }>`
  ${FONT_CSS.header.seven}
  color: ${({ isSelected }) =>
    isSelected ? TEXT_COLORS.one : TEXT_COLORS.three};
`;

export const Tabs = styled.div`
  display: flex;
  padding: ${SPACING.three}px;
  padding-top: 0;
  border-bottom: 1px solid ${BORDER_COLORS.one};
  ${Tab} {
    &:not(:last-child) {
      margin-right: ${SPACING.three}px;
    }
  }
`;

export const SegmentWrapper = styled.div`
  padding: ${SPACING.two}px;
  display: flex;
  width: 100%;
`;

export const TradeFormContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${SPACING.two}px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${SPACING.two}px;
  border-radius: ${BORDER_RADIUS.five}px;
  background: ${LAYER_COLORS.one};
`;

export const TradeFormTopContent = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr;
  gap: ${SPACING.two}px;
  width: 100%;
  flex: 1;
`;

export const OrderbookWrapper = styled.div`
  flex: 1;
`;

export const FormWrapper = styled.div`
  flex: 1;
`;

export const MobileChartTab = styled.div`
  padding: ${SPACING.two}px 0;
`;
