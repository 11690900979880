import Decimal from "decimal.js";
import currency from "currency.js";
import { CollateralAssetResponse } from "../codegen-api";
import { AllCollaterals } from "../constants/addresses";

/**
 * Sizes are formatted to 2 decimals places by default
 * @param size
 * @param decimals number of decimals
 */
export const formatSizePrecision = (
  size: number | string,
  decimals: number = 2
) => Number(size).toFixed(decimals);

export const roundToStepSize = (
  value: number | string,
  stepSize: number,
  precision: number = 2
) => {
  const roundedToNearest = new Decimal(value).toNearest(
    new Decimal(stepSize),
    Decimal.ROUND_DOWN
  );
  return roundedToNearest
    .toDecimalPlaces(precision, Decimal.ROUND_DOWN)
    .toNumber();
};

export const roundUpToPrecision = (value: number, precision: number = 2) =>
  new Decimal(value).toDecimalPlaces(precision, Decimal.ROUND_UP).toNumber();

export const getValidTotalValue = (
  price: number,
  totalValue: number,
  contractsStepSize: number,
  contractsPrecision?: number
) => {
  const contractSize = totalValue / price;
  const validContractSize = roundToStepSize(
    contractSize,
    contractsStepSize,
    contractsPrecision
  );
  const validTotalValue = Number(validContractSize * price).toFixed(2);
  return Number(validTotalValue);
};

/**
 *
 * @param n Number
 * @param alwaysShowDecimals Optional.
 * If true, always show decimals. If false, clip decimals if required.
 * eg. with n of 10.1000, value of false will return 10.1.
 * @returns
 */
export const formatAmount = (
  n: number,
  alwaysShowDecimals?: boolean
): string => {
  if (n < 1e3) {
    return `${currency(n, { separator: ",", symbol: "" }).format()}`;
  }
  if (n >= 1e3 && n < 1e6) {
    const v = alwaysShowDecimals
      ? (n / 1e3).toFixed(2)
      : parseFloat((n / 1e3).toFixed(2));
    return `${v}K`;
  }
  if (n >= 1e6 && n < 1e9) {
    const v = alwaysShowDecimals
      ? (n / 1e6).toFixed(2)
      : parseFloat((n / 1e6).toFixed(2));
    return `${v}M`;
  }
  if (n >= 1e9 && n < 1e12) {
    const v = alwaysShowDecimals
      ? (n / 1e9).toFixed(2)
      : parseFloat((n / 1e9).toFixed(2));
    return `${v}B`;
  }
  if (n >= 1e12) {
    const v = alwaysShowDecimals
      ? (n / 1e12).toFixed(2)
      : parseFloat((n / 1e12).toFixed(2));
    return `${v}T`;
  }

  return "";
};

export const formatCollateralDecimals = (
  amount: string | number,
  collateral?: AllCollaterals
) => {
  if (collateral === CollateralAssetResponse.Weth) {
    return currency(amount, { precision: 4 }).format({ symbol: " " });
  }
  return currency(amount, { precision: 2 }).format({ symbol: " " });
};
