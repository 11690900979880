import { SpinnerBar, SpinnerWrapper } from "./style";

interface ISpinnerProps {
  color?: string;
  isLoading?: boolean;
}

export function Spinner({
  color,
  isLoading = true,
}: ISpinnerProps) {
  return (
    <SpinnerWrapper color={color} isLoading={isLoading}>
      <SpinnerBar delay={0.3} />
      <SpinnerBar delay={0.5} />
      <SpinnerBar delay={0.7} />
    </SpinnerWrapper>
  );
}
