import { InstrumentTypeResponse } from "../codegen-api";
import { AssetResponse } from "../utils/asset";

export enum TradingRoutePathParamsEnum {
  DERIVATIVE = "derivative",
  ASSET = "asset",
  INSTRUMENT_NAME = "instrument",
}

export const validTradingRoutes = Object.values(TradingRoutePathParamsEnum).reduce((prev, param) => [
  ...prev,
  `${prev.slice(-1)}:${param}/`
], ["/"]);

export const getTradingRoutePath = (
  derivative: InstrumentTypeResponse,
  asset: AssetResponse,
  instrumentName?: string
) => {
  let newPath = `/${(derivative).toLowerCase()}/${(asset).toLowerCase()}`;
  if (instrumentName) {
    newPath += `/${instrumentName.toUpperCase()}`;
  }
  return newPath;
};
