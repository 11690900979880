import React, { useCallback, useState } from "react";
import { Placement } from "react-bootstrap/esm/types";
import { OverlayTrigger } from "react-bootstrap";
import { OverlayTriggerRenderProps } from "react-bootstrap/esm/OverlayTrigger";
import {
  Explanation,
  PopoverContainer,
  PopoverContainerForTooltip,
  Title,
} from "./style";

interface ITooltipExplanationProps {
  title?: string;
  placement?: Placement;
  explanation: React.ReactNode;
  learnMore?: boolean;
  offset?: number;
  renderContent: (
    props: OverlayTriggerRenderProps & React.HTMLAttributes<HTMLElement>
  ) => React.ReactNode;
}

function TooltipExplanation({
  title,
  placement = "bottom",
  explanation,
  learnMore,
  renderContent,
  offset,
}: ITooltipExplanationProps) {
  const [show, setShow] = useState(false);

  const onMouseEnter = useCallback(() => {
    setShow(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setShow(false);
  }, []);

  if (learnMore) {
    return (
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <OverlayTrigger
          placement={placement}
          show={show}
          overlay={(props) => (
            <PopoverContainerForTooltip
              onClick={(e) => e.stopPropagation()}
              {...props}
            >
              <Title>{title}</Title>
              <Explanation>{explanation}</Explanation>
            </PopoverContainerForTooltip>
          )}
          popperConfig={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, offset ?? 0],
                },
              },
            ],
          }}
        >
          {(props) =>
            renderContent({
              ...props,
            })
          }
        </OverlayTrigger>
      </div>
    );
  }
  return (
    <OverlayTrigger
      placement={placement}
      show={show}
      overlay={(props) => (
        <PopoverContainer
          {...props}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {title && <Title>{title}</Title>}
          <Explanation>{explanation}</Explanation>
        </PopoverContainer>
      )}
    >
      {(props) =>
        renderContent({
          ...props,
          onMouseEnter,
          onMouseLeave,
        })
      }
    </OverlayTrigger>
  );
}

export default TooltipExplanation;
