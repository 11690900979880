import {
  ChangeEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { SPACING } from "../../../constants/design/spacing";
import { AuthContext } from "../../../contexts/AuthContext";
import { shortenAddress } from "../../../utils/strings";
import { BaseModal, IBaseModalProps } from "../../BaseModal";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import { Input } from "../Input";
import ModalOverlayInfo from "../ModalOverlayInfo";
import {
  ConfirmationContainer,
  ConfirmationTitle,
  Container,
  Text,
} from "./style";
import { useToast } from "../../../hooks/toast";
import { ToastEnum, ToastStatusEnum } from "../../../utils/toast";

interface IEnterPasswordModalProps extends IBaseModalProps {
  deleteMode?: boolean;
}

function EnterPasswordModal({
  show,
  onHide,
  deleteMode,
  ...others
}: IEnterPasswordModalProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "shared.EnterPasswordModal",
  });

  const { addToast } = useToast();
  const { account, unlockAccount, deleteAuthInfo } = useContext(AuthContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>();

  const onPasswordChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setError(undefined);
      setPassword(e.target.value);
    },
    []
  );

  const onUnlockAccount = useCallback(() => {
    if (password) {
      const passwordValid = unlockAccount(password);
      if (passwordValid) {
        onHide?.();
      } else {
        setError(t("invalid_password"));
      }
    }
  }, [onHide, password, t, unlockAccount]);

  const onRemoveAccount = useCallback(() => {
    setShowConfirmation(true);
  }, []);

  const confirmDeleteAccount = useCallback(() => {
    deleteAuthInfo("all");
    onHide?.();
    addToast(
      {
        type: ToastEnum.SIMPLE,
        header: t("account_removed"),
        status: ToastStatusEnum.SUCCESS,
      },
      4000
    );
  }, [addToast, deleteAuthInfo, onHide, t]);

  useEffect(() => {
    if (!show) {
      setPassword("");
      setError(undefined);
      setShowConfirmation(false);
    }
  }, [show]);

  return (
    <BaseModal
      animateTitle
      noContentPadding
      title={deleteMode ? t("remove_account") : t("enter_trading_password")}
      show={show}
      onHide={onHide}
      {...others}
    >
      <ModalOverlayInfo
        hideCloseButton
        show={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        height="300px"
      >
        <ConfirmationContainer>
          <div>
            <ConfirmationTitle>
              {t("remove_account_confirmation_title")}
            </ConfirmationTitle>
            <Text>
              {t("remove_account_confirmation_desc_1")}{" "}
              <strong>{t("remove_account_confirmation_desc_2")}</strong>
            </Text>
          </div>
          <div>
            <Button
              buttonTheme={ButtonThemeEnum.NEGATIVE}
              onClick={confirmDeleteAccount}
              style={{ marginTop: SPACING.three }}
            >
              {t("remove_account_title")}
            </Button>
            <Button
              buttonTheme={ButtonThemeEnum.NEUTRAL2}
              onClick={() => setShowConfirmation(false)}
              style={{ marginTop: SPACING.three }}
            >
              {t("cancel")}
            </Button>
          </div>
        </ConfirmationContainer>
      </ModalOverlayInfo>

      <Container>
        <Text>
          {deleteMode
            ? t("remove_acount_desc", {
                account: shortenAddress(account || ""),
              })
            : t("account_encrypted_desc", {
                account: shortenAddress(account || ""),
              })}
        </Text>
        {!deleteMode && (
          <Input
            placeholder={t("password")}
            type="password"
            value={password}
            onChange={onPasswordChange}
            error={!!error}
            wrapperStyles={{ marginBottom: SPACING.one }}
          />
        )}
        {error && <span>{error}</span>}
        {deleteMode ? (
          <>
            <Button
              buttonTheme={ButtonThemeEnum.NEGATIVE}
              onClick={confirmDeleteAccount}
            >
              {t("remove_account_title")}
            </Button>
            <Button
              buttonTheme={ButtonThemeEnum.NEUTRAL2}
              onClick={onHide}
              style={{ marginTop: SPACING.three }}
            >
              {t("cancel")}
            </Button>
          </>
        ) : (
          <>
            <Button
              buttonTheme={ButtonThemeEnum.NEUTRAL2}
              onClick={onUnlockAccount}
              style={{ marginTop: SPACING.three }}
            >
              {t("unlock_trading")}
            </Button>
            <Button
              buttonTheme={ButtonThemeEnum.NEGATIVE}
              onClick={onRemoveAccount}
            >
              {t("remove_account_title")}
            </Button>
          </>
        )}
      </Container>
    </BaseModal>
  );
}

export default EnterPasswordModal;
