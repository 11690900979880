import { useTranslation } from "react-i18next";
import { forwardRef, useMemo } from "react";
import { ExplanationWrapper } from "../style";
import { TEXT_COLORS } from "../../../../constants/design/colors";
import ExternalLinkText from "../../../ExternalLinkText";
import { SPACING } from "../../../../constants/design/spacing";
import TooltipExplanation from "../../Tooltip";
import { AssetResponse } from "../../../../utils/asset";
import { AirdropIconWrapper } from "./style";
import { ReactComponent as AirdropIcon } from "../../../../assets/svg/airdrops.svg";

interface IIncentivizedAirdropsTooltipProps {
  asset: AssetResponse;
}

const IncentivizedAirdropsTooltip = forwardRef<
  HTMLDivElement,
  IIncentivizedAirdropsTooltipProps
>(({ asset }, ref) => {
  const { t } = useTranslation("app", {
    keyPrefix: "IncentivizedAirdropsTooltip",
  });

  const explanation = useMemo(
    () => (
      <ExplanationWrapper ref={ref}>
        <span>
          {t("explanation", {
            asset,
          })}
        </span>
        <ExternalLinkText
          href="https://docs.aevo.xyz/trading-and-staking-incentives/aevo-airdrops"
          target="_blank"
          rel="noreferrer"
          color={TEXT_COLORS.two}
          wrapperStyle={{
            fontSize: "12px",
            alignSelf: "flex-start",
            marginTop: SPACING.two,
          }}
        >
          {t("learn_more")}
        </ExternalLinkText>
      </ExplanationWrapper>
    ),
    [asset, t, ref]
  );

  return (
    <TooltipExplanation
      placement="bottom"
      explanation={explanation}
      renderContent={({ ref: triggerRef, ...triggerHandler }) => (
        <AirdropIconWrapper ref={triggerRef} {...triggerHandler}>
          <AirdropIcon />
        </AirdropIconWrapper>
      )}
      learnMore
    />
  );
});

export default IncentivizedAirdropsTooltip;
