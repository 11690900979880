import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetMarkets200Response, SideResponse } from "../../../codegen-api";
import { instrumentDefaultOrderbookTickSize } from "../../../constants/assets";
import { LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";
import usePersistentState from "../../../hooks/usePersistentState";
import { getContractPriceStep } from "../../../utils/instruments";
import { countDecimalPlaces } from "../../../utils/math";
import DropdownSimple from "../../shared/DropdownSimple";
import SegmentedControl from "../../shared/SegmentedControl";
import OrderbookSection from "./OrderbookSection";
import OrderbookTypeFilters from "./OrderbookTypeFilter";
import TradeHistorySection from "./TradeHistorySection";
import { OrderbookFilterEnum } from "./shared";
import {
  Container,
  FiltersContainer,
  MediumFilterContainer,
  Section,
  Title,
  TradeTab,
  TradeTabWrapper,
} from "./style";

enum OrderbookTradeHistoryType {
  ORDERBOOK = "orderbook",
  TRADES = "trade_history",
}

interface ITradeOrderbookSectionProps {
  onOrderbookRowClick: (
    price: string,
    size: string,
    side: SideResponse
  ) => void;
  perpInstrument?: GetMarkets200Response;
}

function TradeOrderbookSection({
  onOrderbookRowClick,
  perpInstrument,
}: ITradeOrderbookSectionProps) {
  // MEDIUM SCREEN ONLY
  const [orderbookTradeHistoryType, setOrderbookTradeHistoryType] = useState(
    OrderbookTradeHistoryType.ORDERBOOK
  );

  const {
    orderbookViewInUSD,
    setOrderbookViewInUSD,
    instrumentPreferences,
    setInstrumentPreferences,
  } = usePersistentState();
  const [orderbookFilter, setOrderbookFilter] = useState<OrderbookFilterEnum>();
  const { t } = useTranslation("app", {
    keyPrefix: "Perps.TradeOrderbookSection.TradeOrderbookSection",
  });
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [tickSizeDropdownOpen, setTickSizeDropdownOpen] = useState(false);

  const availableTickSizes = useMemo(() => {
    if (perpInstrument?.price_step !== undefined) {
      const priceStep = Number(perpInstrument.price_step);
      const decimals = countDecimalPlaces(priceStep);

      return [
        undefined,
        Number((priceStep * 10).toFixed(decimals)),
        Number((priceStep * 100).toFixed(decimals)),
        Number((priceStep * 1000).toFixed(decimals)),
      ];
    }
    return [];
  }, [perpInstrument?.price_step]);

  const [orderbookTickSize, setOrderbookTickSize] = useMemo(() => {
    let defaultTick: number | undefined =
      instrumentPreferences?.[perpInstrument?.instrument_name || ""]
        ?.defaultOrderbookTickSize ||
      instrumentDefaultOrderbookTickSize[perpInstrument?.instrument_name || ""];
    if (!availableTickSizes.includes(defaultTick)) {
      defaultTick = undefined;
    }
    return [
      defaultTick,
      (tickSize?: number) => {
        setInstrumentPreferences(
          {
            ...(instrumentPreferences?.[
              perpInstrument?.instrument_name || ""
            ] || {}),
            defaultOrderbookTickSize: tickSize,
          },
          perpInstrument?.instrument_name
        );
      },
    ];
  }, [
    availableTickSizes,
    instrumentPreferences,
    perpInstrument?.instrument_name,
    setInstrumentPreferences,
  ]);

  const toggleFilterDropdown = useCallback(() => {
    setFilterDropdownOpen((open) => !open);
  }, []);

  const toggleTickSizeDropdown = useCallback(() => {
    setTickSizeDropdownOpen((open) => !open);
  }, []);

  const filterComponent = useMemo(
    () => (
      <FiltersContainer>
        <OrderbookTypeFilters
          selectedFilterType={orderbookFilter}
          onSelectFilterType={setOrderbookFilter}
        />
        <div>
          <DropdownSimple<string>
            toggleStyle={{
              marginRight: SPACING.one,
            }}
            dropDirection={"down"}
            show={filterDropdownOpen}
            onToggle={toggleFilterDropdown}
            title={
              orderbookViewInUSD
                ? "USD"
                : perpInstrument?.underlying_asset || ""
            }
            items={[perpInstrument?.underlying_asset || "", "USD"]}
            selectedItem={
              orderbookViewInUSD
                ? "USD"
                : perpInstrument?.underlying_asset || ""
            }
            onSelectItem={(v) => setOrderbookViewInUSD(v === "USD")}
          />
          <DropdownSimple<string>
            toggleStyle={{
              marginRight: SPACING.one,
            }}
            dropDirection={"down"}
            show={tickSizeDropdownOpen}
            onToggle={toggleTickSizeDropdown}
            title={String(
              orderbookTickSize || perpInstrument?.price_step || ""
            )}
            items={availableTickSizes.map((tick) =>
              String(tick || perpInstrument?.price_step || "")
            )}
            selectedItem={String(
              orderbookTickSize || perpInstrument?.price_step || ""
            )}
            onSelectItem={(v) => setOrderbookTickSize(Number(v))}
          />
        </div>
      </FiltersContainer>
    ),
    [
      toggleFilterDropdown,
      orderbookViewInUSD,
      perpInstrument?.underlying_asset,
      perpInstrument?.price_step,
      filterDropdownOpen,
      orderbookFilter,
      setOrderbookViewInUSD,
      orderbookTickSize,
      availableTickSizes,
      tickSizeDropdownOpen,
      toggleTickSizeDropdown,
      setOrderbookTickSize,
    ]
  );

  const controls = useMemo(
    () =>
      Object.keys(OrderbookTradeHistoryType).map((k) => {
        const key = k as keyof typeof OrderbookTradeHistoryType;
        return {
          value: OrderbookTradeHistoryType[key],
          display: <TradeTab>{t(OrderbookTradeHistoryType[key])}</TradeTab>,
          textColor:
            orderbookTradeHistoryType === OrderbookTradeHistoryType[key]
              ? TEXT_COLORS.one
              : TEXT_COLORS.three,
        };
      }),
    [orderbookTradeHistoryType, t]
  );

  return (
    <Container>
      <Section>
        <Title>
          <TradeTabWrapper>
            <SegmentedControl
              segments={controls}
              value={orderbookTradeHistoryType}
              onSelect={(e) =>
                setOrderbookTradeHistoryType(e as OrderbookTradeHistoryType)
              }
              config={{
                widthType: "fullWidth",
                backgroundColor: LAYER_COLORS.two,
                activeBackgroundColor: LAYER_COLORS.three,
                borderRadius: "10px",
                button: {
                  fontSize: FONT_SIZE.one,
                },
              }}
            />
          </TradeTabWrapper>
        </Title>
        {orderbookTradeHistoryType === OrderbookTradeHistoryType.ORDERBOOK ? (
          <>
            <MediumFilterContainer>{filterComponent}</MediumFilterContainer>
            <OrderbookSection
              sizeDecimals={
                getContractPriceStep(perpInstrument).amount_precision
              }
              priceDecimals={
                getContractPriceStep(perpInstrument).price_precision
              }
              instrumentName={perpInstrument?.instrument_name}
              onOrderbookRowClick={onOrderbookRowClick}
              filter={orderbookFilter}
              showValueInUSD={orderbookViewInUSD}
              orderbookTickSize={orderbookTickSize}
            />
          </>
        ) : (
          <TradeHistorySection
            instrumentName={perpInstrument?.instrument_name}
            sizeDecimals={getContractPriceStep(perpInstrument).amount_precision}
            priceDecimals={getContractPriceStep(perpInstrument).price_precision}
          />
        )}
      </Section>
    </Container>
  );
}

export default TradeOrderbookSection;
