import { useTranslation } from "react-i18next";
import { MouseEventHandler, useMemo } from "react";
import { SwitchLabel, SwitchLine, ToggleContainer } from "./style";

interface IToggleProps {
  isOn: boolean;
  onToggle: MouseEventHandler<HTMLDivElement>;
  positiveText?: string;
  negativeText?: string;
}

function Toggle({ isOn, onToggle, positiveText, negativeText }: IToggleProps) {
  const { t } = useTranslation("app", { keyPrefix: "shared.Toggle" });

  const switchLabel = useMemo(() => {
    const onText = positiveText ? t(positiveText) : t("on");
    const offText = negativeText ? t(negativeText) : t("off");
    return isOn ? onText : offText;
  }, [isOn, negativeText, positiveText, t]);
  return (
    <ToggleContainer isOn={isOn} onClick={onToggle}>
      <div>
        <SwitchLine isOn={isOn} />
        <SwitchLabel isOn={isOn}>{switchLabel}</SwitchLabel>
      </div>
    </ToggleContainer>
  );
}

export default Toggle;
