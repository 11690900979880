import { useCallback, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { authApi } from "../../../services/api/apiFetcher";

export const useAccountLeverage = () => {
  const { apiKey, apiSecret } = useContext(AuthContext);
  const fetcher = useMemo(
    () => authApi(apiKey, apiSecret),
    [apiKey, apiSecret]
  );

  const updateAccountLeverage = useCallback(
    async (instrument: number, leverage: number) => {
      const { data } = await (
        await fetcher.postAccountLeverage({
          instrument,
          leverage,
        })
      )();

      return data;
    },
    [fetcher]
  );

  return {
    updateAccountLeverage,
  };
};
