import axios from "axios";

export async function testnetFaucet(
  address: string
): Promise<{ usdcTxHash?: string; ethTxHash?: string; error?: string }> {
  const data = await axios.post("https://testnet-faucet.aevo.xyz/api/faucet", {
    address,
  });

  return data.data;
}
