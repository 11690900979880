import axios from "axios";
import { useMemo } from "react";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_STYLE } from "../../constants/design/fontSize";
import { SPACING } from "../../constants/design/spacing";
import { LINKS } from "../../constants/links";
import { isProduction } from "../../utils/env";
import ExternalLinkText from "../ExternalLinkText";
import { IUptimeMonitor, UptimeStatusEnum } from "./model";
import { StatusContainer, StatusIndicator } from "./style";

const { REACT_APP_UPTIME_ROBOT_API_KEY } = process.env;

const PROD_UPTIME_MONITOR_ID = 792471758;
const TESTNET_UPTIME_MONITOR_ID = 792944756;

interface IUptimeMonitorProps {
  maintenanceTimestampSeconds: number;
}

function UptimeMonitor({ maintenanceTimestampSeconds }: IUptimeMonitorProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "UptimeMonitor.UptimeMonitor",
  });

  const maintenanceInProgress = Number(maintenanceTimestampSeconds)
    ? Number(maintenanceTimestampSeconds) * 1000 <= Date.now()
    : false;

  // Get status (once every minute)
  const uptimeMonitorsSwr = useSWR(
    ["Uptimemonitor"],
    async () => {
      try {
        const { data } = await axios.post(
          "https://api.uptimerobot.com/v2/getMonitors",
          { api_key: REACT_APP_UPTIME_ROBOT_API_KEY }
        );
        return (data.monitors as IUptimeMonitor[]) || [];
      } catch (error) {
        return undefined;
      }
    },
    {
      refreshInterval: 60000,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Retry after 1 min.
        setTimeout(() => revalidate({ retryCount }), 60000);
      },
    }
  );

  const uptimeStatus = useMemo(() => {
    const monitorId = isProduction()
      ? PROD_UPTIME_MONITOR_ID
      : TESTNET_UPTIME_MONITOR_ID;
    return uptimeMonitorsSwr.data?.find((v) => v.id === monitorId)?.status;
  }, [uptimeMonitorsSwr]);

  const statusDescription = useMemo(() => {
    if (maintenanceInProgress) {
      return t("maintenance_in_progress");
    }
    switch (uptimeStatus) {
      case UptimeStatusEnum.DOWN:
        return t("systems_outage");
      case UptimeStatusEnum.SEEMS_DOWN:
        return t("partial_systems_outage");
      default:
        return t("systems_operational");
    }
  }, [maintenanceInProgress, t, uptimeStatus]);

  return (
    <StatusContainer>
      <StatusIndicator
        maintenanceInProgress={maintenanceInProgress}
        status={uptimeStatus}
      />
      <ExternalLinkText
        onClick={() => window.open(LINKS.uptimeRobot)}
        color={maintenanceInProgress ? COLORS.highlight.one : TEXT_COLORS.three}
        hoverColor={
          maintenanceInProgress ? COLORS.highlight.one : TEXT_COLORS.one
        }
        wrapperStyle={{
          marginLeft: `${SPACING.two}px`,
          fontSize: FONT_STYLE.body.four.fontSize,
          lineHeight: FONT_STYLE.body.four.lineHeight,
        }}
      >
        {statusDescription}
      </ExternalLinkText>
    </StatusContainer>
  );
}

export default UptimeMonitor;
