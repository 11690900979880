import styled from "styled-components";
import { FONT_STYLE } from "../../constants/design/fontSize";
import { TEXT_COLORS } from "../../constants/design/colors";

export const InvalidContentWrapper = styled.div`
  > p {
    color: ${TEXT_COLORS.two};

    > span {
      color: ${TEXT_COLORS.one};
    }
  }

  ${FONT_STYLE.body.three};
`;
