import currency from "currency.js";
import moment from "moment";
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Copy } from "../../../assets/svg/copy.svg";
import {
  GetOrders200Response,
  InstrumentTypeResponse,
  OrderTypeResponse,
  SideResponse,
} from "../../../codegen-api";
import { COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";
import { MarketInstrumentContext } from "../../../contexts/MarketInstrumentContext";
import { nanosToSeconds } from "../../../utils/date";
import { formatSizePrecision } from "../../../utils/format";
import { getAssetFromSymbol } from "../../../utils/instruments";
import { getStopOrderName } from "../../../utils/order";
import { copyStringToClipboard, shortenString } from "../../../utils/strings";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import BaseDetailsModal from "./BaseDetailsModal";
import { ViewDetailsContent, ViewDetailsRow } from "./style";

interface IOrderDetailsModalProps {
  order?: GetOrders200Response;
  onHide?: () => void;
  onEditOrder: () => void;
  onRemoveOrder: () => void;
  style?: CSSProperties;
}

function OrderDetailsModal({
  order,
  onHide,
  onEditOrder,
  onRemoveOrder,
  style,
}: IOrderDetailsModalProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "shared.ViewDetailsModal.OrderDetailsModal",
  });
  const { t: translateAll } = useTranslation();
  const [showCopied, setShowCopied] = useState(false);

  const { activeOptionMarkets, activePerpMarkets, getMarketPrecision } =
    useContext(MarketInstrumentContext);
  const asset = useMemo(
    () => getAssetFromSymbol(order?.instrument_name || ""),
    [order?.instrument_name]
  );

  const instrument = useMemo(() => {
    if (asset && order) {
      const markets =
        order.instrument_type === InstrumentTypeResponse.Option
          ? activeOptionMarkets
          : activePerpMarkets;
      return markets?.find((m) => m.underlying_asset === asset);
    }
    return undefined;
  }, [activeOptionMarkets, activePerpMarkets, asset, order]);

  // Dismiss copied text after 1 second
  useEffect(() => {
    if (showCopied) {
      const timeout = setTimeout(() => {
        setShowCopied(!showCopied);
      }, 500);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [showCopied]);

  const onCopyOrderId = useCallback(() => {
    if (order) {
      copyStringToClipboard(order.order_id);
      setShowCopied(true);
    }
  }, [order]);

  return (
    <BaseDetailsModal
      instrument={
        order
          ? {
              instrument_name: order.instrument_name,
              expiry: order.expiry,
              strike: order.strike,
              markPrice: instrument?.mark_price || "0",
            }
          : undefined
      }
      show={!!order}
      onHide={onHide}
      style={style}
    >
      {order && (
        <ViewDetailsContent>
          <ViewDetailsRow>
            <span>{t("side")}</span>
            <span
              style={{
                color:
                  order.side === SideResponse.Buy
                    ? COLORS.positive.one
                    : COLORS.negative.one,
              }}
            >
              {order.side}
            </span>
          </ViewDetailsRow>
          <ViewDetailsRow>
            <span>{t("order_type")}</span>
            <span
              style={
                order.stop
                  ? {
                      color:
                        order.side === SideResponse.Buy
                          ? COLORS.positive.one
                          : COLORS.negative.one,
                    }
                  : undefined
              }
            >
              {order.stop
                ? getStopOrderName(translateAll, order.order_type, true)
                : order.order_type}
            </span>
          </ViewDetailsRow>
          {!order.option_type &&
            order.order_type === OrderTypeResponse.Market && (
              <ViewDetailsRow>
                <span>{t("reduce_only")}</span>
                <span>{order.reduce_only ? t("yes") : t("no")}</span>
              </ViewDetailsRow>
            )}
          <ViewDetailsRow>
            <span>{t("order_size")}</span>
            <span>
              {order.close_position
                ? t("close_position")
                : formatSizePrecision(
                    order.amount,
                    getMarketPrecision(asset, order.instrument_type)
                      .amount_precision
                  )}
            </span>
          </ViewDetailsRow>
          <ViewDetailsRow>
            <span>{t("filled")}</span>
            <span>
              {formatSizePrecision(
                order.filled,
                getMarketPrecision(asset, order.instrument_type)
                  .amount_precision
              )}
            </span>
          </ViewDetailsRow>
          {order.order_type === OrderTypeResponse.Limit && (
            <ViewDetailsRow>
              <span>{t("price")}</span>
              <span>
                {currency(order.price, {
                  precision: getMarketPrecision(asset, order.instrument_type)
                    .price_precision,
                }).format()}
              </span>
            </ViewDetailsRow>
          )}
          {!!order.trigger && (
            <ViewDetailsRow>
              <span>{t("trigger_price")}</span>
              <span>
                {currency(order.trigger, {
                  precision: getMarketPrecision(asset, order.instrument_type)
                    .price_precision,
                }).format()}
              </span>
            </ViewDetailsRow>
          )}
          {order.option_type && (
            <ViewDetailsRow>
              <span>{t("iv")}</span>
              <span>{order.iv ? `${Number(order.iv).toFixed(2)}%` : "-"}</span>
            </ViewDetailsRow>
          )}
          <ViewDetailsRow>
            <span>{t("initial_margin")}</span>
            <span>
              {order.initial_margin
                ? currency(order.initial_margin).format()
                : "-"}
            </span>
          </ViewDetailsRow>
          <ViewDetailsRow>
            <span>TIF</span>
            <span>{order.time_in_force ? order.time_in_force : "-"}</span>
          </ViewDetailsRow>
          <ViewDetailsRow>
            <span>{t("date_and_time")}</span>
            <span>
              {moment
                .unix(nanosToSeconds(order.created_timestamp!))
                .format("HH:mm:ss - DD MMM YY")}
            </span>
          </ViewDetailsRow>
          <ViewDetailsRow clickable onClick={onCopyOrderId}>
            <span>{t("order_id")}</span>
            <span>
              <Copy style={{ marginRight: SPACING.two }} />
              {showCopied
                ? t("copied_order_id")
                : shortenString(order.order_id, 5, 5)}
            </span>
          </ViewDetailsRow>

          {!order.close_position && (
            <Button
              fullWidth
              buttonTheme={ButtonThemeEnum.NEUTRAL2}
              onClick={onEditOrder}
            >
              {t("edit_order")}
            </Button>
          )}
          <Button
            fullWidth
            buttonTheme={ButtonThemeEnum.NEUTRAL2}
            onClick={onRemoveOrder}
          >
            {t("remove_order")}
          </Button>
        </ViewDetailsContent>
      )}
    </BaseDetailsModal>
  );
}

export default OrderDetailsModal;
