import { useTranslation } from "react-i18next";
import { useState, useCallback, useMemo, useEffect } from "react";

import { IClosePositionModal } from "../TPSLModalContent/TPSLContent.types";

import ConfirmationModal from "..";
import { BACKGROUND_COLORS, COLORS } from "../../../constants/design/colors";
import PartialTPSLModalContent from "./PartialTPSLModalContent";
import {
  PartialTPSLTabEnum,
  PartialTPSLTabType,
} from "./PartialTPSLModalContent.types";
import { useSFX } from "../../../hooks/useSFX";
import { useToast } from "../../../hooks/toast";
import { useOrder } from "../../../hooks/api/order/useOrder";
import { ToastEnum, ToastStatusEnum } from "../../../utils/toast";
import { partialPositionsDataHelpers } from "./PartialTPSLModalContent.helpers";
import { SPACING } from "../../../constants/design/spacing";
import { useGetAccount } from "../../../hooks/api/account/useGetAccount";

function PartialTPSLForPositionModal({
  position,
  onHide,
  show,
  ...modalProps
}: IClosePositionModal) {
  const positionTotalAmount = position?.amount;
  const side = position?.side;

  const { t } = useTranslation("app", {
    keyPrefix: "ConfirmationModal.TPSLForPositionModal",
  });
  const { t: tRemoveOrder } = useTranslation("app", {
    keyPrefix: "ConfirmationModal.RemoveOrderModal",
  });
  const { t: tApiError } = useTranslation("apiErrors");
  const { playSound } = useSFX();
  const { addToast } = useToast();
  const { removeOrder } = useOrder();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingOrderId, setLoadingOrderId] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>();
  const { mutate } = useGetAccount();

  const partialPositionsDataSet = useMemo(
    () => partialPositionsDataHelpers(position),
    [position]
  );

  const [currentTab, setCurrentTab] = useState<PartialTPSLTabType>(
    PartialTPSLTabEnum.All
  );
  const [partialPositionData, setPartialPositionData] = useState(
    partialPositionsDataSet.All || []
  );

  const onHideModal = useCallback(() => {
    onHide?.();
  }, [onHide]);

  const onSelectedTab = useCallback(
    (value: string) => {
      setCurrentTab(value as PartialTPSLTabType);
      setPartialPositionData(
        partialPositionsDataSet[
          value as keyof typeof partialPositionsDataSet
        ] || []
      );
    },
    [setPartialPositionData, partialPositionsDataSet]
  );

  const onCancelOrder = async (order: any) => {
    if (!order) {
      return;
    }

    try {
      setErrorMessage(undefined);
      setLoading(true);
      setLoadingOrderId(order.order_id);
      await removeOrder(order.order_id);
      mutate();
      playSound("order_cancelled");
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: tRemoveOrder("order_edited_header"),
          subheader: tRemoveOrder("order_edited_subheader"),
          status: ToastStatusEnum.SUCCESS,
        },
        4000
      );
    } catch (error: any) {
      setErrorMessage(
        tApiError(error.message) || tRemoveOrder("remove_order_error")
      );
    } finally {
      setLoading(false);
      setLoadingOrderId("");
    }
  };

  useEffect(() => {
    setPartialPositionData(partialPositionsDataSet[currentTab] || []);
  }, [partialPositionsDataSet, currentTab]);

  return (
    <ConfirmationModal
      title={t("confirmation_partial_modal_title")}
      primaryColor={COLORS.blue.one}
      style={{
        backgroundColor: BACKGROUND_COLORS.seven,
        width: 610,
        marginRight: SPACING.three,
        marginLeft: SPACING.three,
      }}
      confirmationWrapperStyle={{ display: "flex" }}
      isLoading={loading}
      onHide={onHideModal}
      show={show}
      error={errorMessage}
      {...modalProps}
      extras={
        <PartialTPSLModalContent
          currentTab={currentTab}
          onSelectTab={onSelectedTab}
          onCancelOrder={onCancelOrder}
          positionTotalAmount={positionTotalAmount}
          side={side!!}
          loadingOrderId={loadingOrderId}
          data={partialPositionData}
        />
      }
      statsFirst={false}
    />
  );
}

export default PartialTPSLForPositionModal;
