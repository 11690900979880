import React, { ReactElement, useState } from "react";
import { IntercomProvider } from "react-use-intercom";

interface IIntercomContextType {
  showIntercomSetupModal: boolean;
  setShowIntercomSetupModal: (show: boolean) => void;
}

interface IIntercomContextProviderProps {
  children: ReactElement;
}

export const IntercomContext = React.createContext<IIntercomContextType>({
  showIntercomSetupModal: false,
  setShowIntercomSetupModal: () => {},
});

export function IntercomContextProvider({
  children,
}: IIntercomContextProviderProps) {
  const [showIntercomSetupModal, setShowIntercomSetupModal] = useState(false);
  return (
    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID || ""}>
      <IntercomContext.Provider
        value={{ showIntercomSetupModal, setShowIntercomSetupModal }}
      >
        {children}
      </IntercomContext.Provider>
    </IntercomProvider>
  );
}
