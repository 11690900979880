import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { SideResponse } from "../../codegen-api";
import {
  BACKGROUND_COLORS,
  COLORS,
  FIELD_COLORS,
  ICON_COLORS,
  LAYER_COLORS,
  PRIMARY_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../constants/design/spacing";
import { glow } from "../Buttons/styles";
import { SelectButton } from "../shared/Select/style";
import { frostedGlassBg } from "../shared/style";
import { ReactComponent as Info } from "../../assets/svg/info.svg";

export const ContentWrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  ${({ isMobile }) =>
    isMobile
      ? css`
          height: 100%;
        `
      : css`
          position: absolute;
          top: 0;
          background-color: ${LAYER_COLORS.one};
        `};
  border: none;

  display: flex;
  flex-direction: column;
`;

export const FormContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Padding = styled.div<{ isMobileScreen?: boolean }>`
  ${({ isMobileScreen }) => !isMobileScreen && `padding: 0 ${SPACING.three}px;`}
`;

export const TradeForm = styled.form<{ isMobileScreen?: boolean }>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${({ isMobileScreen }) =>
    isMobileScreen &&
    css`
      gap: ${SPACING.two}px;
      width: 100%;
    `}
`;

export const Form = styled.form<{ isMobileScreen?: boolean }>`
  position: relative;
  overflow: hidden;
  ${({ isMobileScreen }) =>
    !isMobileScreen && `padding: ${SPACING.three}px ${SPACING.three}px 0;`}
`;

export const DetailsWrapper = styled.div<{ isMobileScreen?: boolean }>`
  position: relative;
  overflow: hidden;
  ${({ isMobileScreen }) =>
    isMobileScreen
      ? `padding-top: ${SPACING.one}px`
      : `padding: ${SPACING.three}px ${SPACING.three}px 0;`}
`;

export const StatsWrapper = styled.div<{ isMobileScreen?: boolean }>`
  position: relative;
  margin-top: auto;
  overflow: hidden;
  ${({ isMobileScreen }) =>
    isMobileScreen
      ? `padding-top: ${SPACING.one}px`
      : `padding: ${SPACING.three}px ${SPACING.three}px 0;`}
`;

export const TradeExecutedBar = styled(motion.div)<{ type: SideResponse }>`
  position: absolute;
  height: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ type }) =>
    type === SideResponse.Buy ? COLORS.positive.one : COLORS.negative.one};
  box-shadow: 4px 8px 40px 4px
    ${({ type }) =>
      type === SideResponse.Buy ? COLORS.positive.five : COLORS.negative.five};
`;

export const TransactionTypeWrapper = styled.div<{ isMobileScreen?: boolean }>`
  ${({ isMobileScreen }) =>
    isMobileScreen
      ? css`
          display: grid;
          grid-template-columns: 2fr 1fr;
          grid-gap: ${SPACING.two}px;
        `
      : css`
          margin-top: ${SPACING.three}px;
        `}

  ${SelectButton} {
    width: 50%;
    padding: ${SPACING.two}px;
    font-size: ${FONT_SIZE.three};
  }
`;

export const OrderTypeWrapper = styled.div<{ isMobileScreen?: boolean }>`
  margin-top: ${({ isMobileScreen }) =>
    isMobileScreen ? SPACING.two : SPACING.three}px;

  ${SelectButton} {
    width: 50%;
    border-radius: ${FONT_SIZE.two};
  }
`;

export const MarginTypeWrapper = styled.div<{ isMobileScreen?: boolean }>`
  margin-top: ${({ isMobileScreen }) =>
    isMobileScreen ? `${SPACING.two}px` : `${SPACING.three}px`};
`;

export const InfoRow = styled.div<{
  warningOrError?: "warning" | "error";
  marginBottom?: number;
  isMobileScreen?: boolean;
}>`
  &:not(:last-of-type) {
    margin-bottom: ${({ marginBottom, isMobileScreen }) =>
      marginBottom || isMobileScreen
        ? SPACING.two
        : SPACING.twoHalf}px !important;
  }

  > * {
    margin: auto 0 !important;
    color: ${({ warningOrError }) => {
      if (warningOrError === "warning") {
        return `${COLORS.system.one} !important`;
      }
      if (warningOrError === "error") {
        return `${COLORS.negative.one} !important`;
      }
      return undefined;
    }};
  }
`;

export const TradeInfoWrapper = styled.div<{ isMobileScreen?: boolean }>`
  margin-top: ${SPACING.three}px;

  > * {
    display: flex;
    justify-content: space-between;
  }

  p {
    margin: 0;
  }
`;

export const Title = styled.div`
  ${FONT_CSS.label.two}
  color: ${TEXT_COLORS.three};
  display: flex;
  align-items: center;
  margin: auto 0;
`;

export const Value = styled.div`
  ${FONT_CSS.data.three}
  color: ${TEXT_COLORS.one};
`;

export const PositionInfoWrapper = styled.div<{ isMobileScreen?: boolean }>`
  margin: ${({ isMobileScreen }) =>
    isMobileScreen ? `${SPACING.two}px 0 0 0` : `${SPACING.three}px 0`};
  padding: ${SPACING.two}px;
  background: ${LAYER_COLORS.two};
  border-radius: ${BORDER_RADIUS.two}px;

  > * {
    display: flex;
    justify-content: space-between;
  }

  p {
    margin: 0;
  }
`;

export const PositionChip = styled.span<{ type: SideResponse }>`
  ${FONT_CSS.label.three}
  padding: 2px ${SPACING.one}px;
  border-radius: ${BORDER_RADIUS.two}px;
  background-color: ${(props) =>
    props.type === SideResponse.Buy
      ? COLORS.positive.six
      : COLORS.negative.six};
  color: ${(props) =>
    props.type === SideResponse.Buy
      ? COLORS.positive.one
      : COLORS.negative.one}!important;
  margin-left: ${SPACING.two}px;
  text-transform: capitalize;
`;

export const InputWrapper = styled.div<{ isMobileScreen?: boolean }>`
  ${({ isMobileScreen }) => isMobileScreen && `margin-top: ${SPACING.one}px;`}
  > * {
    &:not(:last-child) {
      ${({ isMobileScreen }) => {
        if (isMobileScreen) {
          return css`
            margin-bottom: ${SPACING.one}px;
          `;
        }
        return css`
          margin-bottom: ${SPACING.two}px;
        `;
      }}

  > input {
    background: ${FIELD_COLORS.one};
  }
`;

export const Label = styled.span`
  text-transform: capitalize;
  display: inline-block;
  color: ${TEXT_COLORS.three};
  font-size: ${FONT_SIZE.one};
`;

export const WarningLabel = styled.span<{ type?: "error" | "warning" }>`
  display: inline-block;
  color: ${({ type }) =>
    type === "warning" ? COLORS.system.one : COLORS.negative.one};
  font-size: ${FONT_SIZE.one};
`;

export const SubmitWrapper = styled.div<{ isSticky?: boolean }>`
  ${({ isSticky }) =>
    isSticky
      ? css`
          position: sticky;
          bottom: 0;
          z-index: 3;
          ${frostedGlassBg("rgba(18, 18, 26, 0.36)", LAYER_COLORS.one, 8)}
        `
      : css``}
`;

export const SubmitButton = styled.button`
  justify-content: center;
  transition: 0.2s ease-in-out;
  display: flex;
  width: 100%;
  border-radius: ${BORDER_RADIUS.two}px;
  color: ${TEXT_COLORS.two};
  font-size: ${FONT_SIZE.two};
  padding: ${SPACING.three}px ${SPACING.two}px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const PlaceOrderButton = styled(SubmitButton)<{ side: SideResponse }>`
  background-color: ${({ disabled, side }) => {
    if (disabled) {
      return side === SideResponse.Buy
        ? COLORS.positive.five
        : COLORS.negative.five;
    }
    return side === SideResponse.Buy
      ? COLORS.positive.five
      : COLORS.negative.five;
  }};
  color: ${({ side }) =>
    side === SideResponse.Buy ? COLORS.positive.one : COLORS.negative.one};
  ${({ side, disabled }) =>
    disabled
      ? ""
      : glow(
          side === SideResponse.Buy ? COLORS.positive.one : COLORS.negative.one
        )};
`;

export const TotalFilledBarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TotalFillSize = styled.span`
  color: ${TEXT_COLORS.three};
  &:before {
    content: " ";
  }
`;

export const OrderbookTabWrapper = styled.div<{ isMobile?: boolean }>`
  position: relative;
  overflow: scroll;
  height: ${({ isMobile }) =>
    isMobile ? "100vh" : `${COMPONENTS.tradeTicketOrderbookHeight}px`};
`;

export const OrderbookEmptyContent = styled.div`
  display: flex;
  height: 100%;
  text-align: center;
`;

export const PlaceholderContent = styled.div`
  color: ${TEXT_COLORS.three};
  display: flex;
  min-height: 320px;
  align-items: center;
  justify-content: center;
  font-size: ${FONT_SIZE.two};
`;

export const DeselectReduceOnlyContainer = styled.div<{ show: boolean }>`
  background-color: ${LAYER_COLORS.two};
  margin-top: ${SPACING.two}px;
  padding: ${SPACING.two}px;
  border-radius: ${BORDER_RADIUS.two}px;

  div:nth-child(1) {
    ${FONT_CSS.label.two}
    color: ${COLORS.highlight.one};
  }

  div:nth-child(2) {
    ${FONT_CSS.label.three}
    color: ${TEXT_COLORS.two};
    strong {
      color: ${TEXT_COLORS.one};
    }
  }

  transition: all 0.2s ease-in-out;

  ${({ show }) =>
    show
      ? css`
          opacity: 1;
          height: 80px;
        `
      : css`
          opacity: 0;
          height: 0px;
          padding: 0;
          pointer-events: none;
        `}
`;

export const CheckboxContainer = styled.div`
  display: flex;
`;

export const InputsWrapper = styled.div<{ isMobileScreen?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ isMobileScreen }) =>
    isMobileScreen ? SPACING.two : SPACING.three}px;
`;

export const OrderTPSLWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${SPACING.two}px;
  gap: ${SPACING.two}px;
`;

export const GapWrapper = styled.div<{ isMobileScreen?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ isMobileScreen }) =>
    isMobileScreen ? SPACING.one : SPACING.two}px;
`;

export const BoostTitle = styled.div`
  display: flex;
`;

export const BoostValueWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: ${BORDER_RADIUS.three}px;
  background: ${PRIMARY_COLORS.three};
  border: 1px solid ${PRIMARY_COLORS.one};
  padding: ${SPACING.one}px;
  color: ${TEXT_COLORS.one};
  gap: ${SPACING.one}px;
`;

export const ExtraFarmBoostWrapper = styled.div`
  display: flex;
  width: 100%;
  background: ${PRIMARY_COLORS.four};
  border-radius: 8px;
  height: 48px;
  padding: 8px;
  gap: 8px;
  margin-top: ${SPACING.three}px;
  align-items: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  min-width: 32px;
  min-height: 32px;
  border-radius: 100px;
  background: ${PRIMARY_COLORS.one};
  align-items: center;
  justify-content: center;
  svg {
    stroke: ${TEXT_COLORS.one};
  }
`;

export const TextWrapper = styled.div`
  ${FONT_CSS.body.five}
  color: ${TEXT_COLORS.two};
`;

export const ReduceOnlyAndTIFContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TPSLContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TIFDropdownContainer = styled.div``;

export const TIFContainer = styled.div`
  display: flex;
  align-items: center;
  max-height: 16px;
  gap: ${SPACING.two}px;
  p {
    ${FONT_CSS.label.two}
    color: ${TEXT_COLORS.three};
    margin-bottom: 0;
  }
`;

export const DropdownTitle = styled.div`
  ${FONT_CSS.data.three}
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;
  color: ${TEXT_COLORS.two};
  white-space: normal;
  > span {
    text-align: center;
  }
`;

export const DropdownTitleItem = styled(DropdownTitle)<{
  selected: boolean;
}>`
  justify-content: left;
  padding-left: ${SPACING.two}px;
  height: ${SPACING.five}px;
  &:hover {
    color: ${TEXT_COLORS.one};
  }

  ${({ selected }) =>
    selected &&
    css`
      color: ${TEXT_COLORS.one};
    `}
`;

export const StyledInfo = styled(Info)<{ $reduceOnly?: boolean }>`
  stroke: ${({ $reduceOnly }) =>
    $reduceOnly ? `${COLORS.highlight.one} !important` : "inherit"};
`;

export const SubmitContentWrapper = styled.div<{ isMobileScreen?: boolean }>`
  position: relative;
  ${({ isMobileScreen }) =>
    !isMobileScreen &&
    css`
      padding: ${SPACING.three}px;
    `};
`;

export const PartialMaxButton = styled.button`
  ${FONT_CSS.label.three}
  background-color: ${LAYER_COLORS.four};
  color: ${TEXT_COLORS.two};
  border-radius: 8px;
  padding: ${SPACING.two}px ${SPACING.twoHalf}px;
`;

export const CheckboxesWrapper = styled.div<{ isMobileScreen?: boolean }>`
  margin-top: ${({ isMobileScreen }) =>
    isMobileScreen ? `${SPACING.two}px` : `${SPACING.three}px`};
  display: flex;
  flex-direction: column;
  gap: ${SPACING.two}px;
`;

export const MarginText = styled.div`
  ${FONT_CSS.data.three}
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${LAYER_COLORS.two};
  border-radius: ${BORDER_RADIUS.five}px;
`;

export const TradeFormContainer = styled.div<{ isMobileScreen?: boolean }>`
  display: flex;
  width: 100%;
  padding: ${SPACING.two}px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ isMobileScreen }) =>
    isMobileScreen ? SPACING.one : SPACING.two}px;
  border-radius: ${BORDER_RADIUS.five}px;
  background: ${LAYER_COLORS.one};
`;

export const TradeFormTopContent = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr;
  gap: ${SPACING.two}px;
  width: 100%;
  flex: 1;
`;

export const OrderbookWrapper = styled.div`
  flex: 1;
`;

export const FormWrapper = styled.div`
  flex: 1;
`;

export const SegmentWrapper = styled.div`
  padding: ${SPACING.two}px;
`;

export const SectionContainer = styled.div<{
  padding?: boolean;
  hidden: boolean;
}>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => (padding ? `0 ${SPACING.three}px` : 0)};
  min-height: 100%;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;

export const AbsoluteWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: ${SPACING.two}px;
  background: ${BACKGROUND_COLORS.one};
  z-index: 5;
`;

export const CloseButton = styled.button.attrs({
  type: "button",
})`
  background: ${LAYER_COLORS.one};
  border-radius: ${BORDER_RADIUS.five}px;
  display: grid;
  place-items: center;
  height: ${COMPONENTS.header.sub}px;
  min-width: ${COMPONENTS.header.sub}px;

  svg {
    width: 16px;
    height: 16px;
    stroke: ${ICON_COLORS.two};
  }
  &:hover {
    svg {
      stroke: ${ICON_COLORS.one} !important;
    }
  }
`;

export const MarketSelectionWrapper = styled.div`
  display: flex;
  gap: ${SPACING.two}px;
  margin-bottom: ${SPACING.two}px;
`;

export const DropdownItem = styled.div<{ isSelected?: boolean }>`
  padding: ${SPACING.two}px;
  font-size: ${FONT_SIZE.one};
  color: ${({ isSelected }) =>
    isSelected ? TEXT_COLORS.one : TEXT_COLORS.three};
  background-color: ${BACKGROUND_COLORS.four};

  &:hover {
    color: ${TEXT_COLORS.one};
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: ${SPACING.three}px;
  }

  display: flex;
  align-items: center;
`;
