import styled, { css } from "styled-components";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../constants/design/spacing";
import { Row } from "../../shared/Table/style";
import { frostedGlassBg } from "../../shared/style";

export const TradeOrderBookWrapper = styled.div<{ isTradeForm?: boolean }>`
  ${FONT_CSS.data.three}
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: flex-start;
  ${({ isTradeForm }) =>
    isTradeForm
      ? css`
          border-radius: ${BORDER_RADIUS.five}px;
          overflow: hidden;
          background: ${LAYER_COLORS.two};
        `
      : css``}
`;

export const CellsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MiddleRow = styled(Row)<{ isTradeForm?: boolean }>`
  ${FONT_CSS.data.three}
  justify-content: center;
  align-items: center;
  height: ${COMPONENTS.tableHeader}px;
  background-color: ${({ isTradeForm }) =>
    isTradeForm ? LAYER_COLORS.three : LAYER_COLORS.two};

  span {
    width: fit-content;
  }
`;

export const MidRow = styled(MiddleRow)<{
  isPositiveChange?: boolean;
  isTradeForm?: boolean;
}>`
  ${FONT_CSS.data.three}
  display: flex;
  align-items: center;
  height: ${({ isTradeForm }) =>
    isTradeForm
      ? COMPONENTS.perpsTableRowTradeForm
      : COMPONENTS.perpsOrderbookSpreadRowHeight}px;
  color: ${({ isPositiveChange }) => {
    if (isPositiveChange !== undefined) {
      return isPositiveChange ? COLORS.positive.one : COLORS.negative.one;
    }
    return TEXT_COLORS.one;
  }};

  svg {
    ${({ isPositiveChange }) =>
      isPositiveChange !== undefined
        ? css`
            stroke: ${isPositiveChange
              ? COLORS.positive.one
              : COLORS.negative.one};
            transform: ${isPositiveChange ? "rotate(180deg)" : "rotate(0deg)"};
          `
        : css`
            display: none;
          `}
  }
`;

export const MarkPriceIV = styled.span`
  ${FONT_CSS.data.four}
  color: ${TEXT_COLORS.three};
  margin-left: ${SPACING.three}px;
`;

export const RowUpdater = styled.div`
  cursor: pointer;
  position: relative;
`;

export const PositionDetails = styled.div<{
  positionType: "ask" | "bid";
  indexFromLastRow: number;
}>`
  position: absolute;
  left: ${SPACING.two}px;
  border: 1px solid ${BORDER_COLORS.one};
  z-index: 100 !important;
  bottom: 0;
  border-radius: ${BORDER_RADIUS.five}px;
  height: fit-content;
  width: fit-content;

  ${({ positionType, indexFromLastRow }) => {
    if (positionType === "ask") {
      return `transform: translateY(max(45%, calc(95% - ${indexFromLastRow}*21px - 8px)));`;
    }
    return `transform: translateY(min(45%, calc(${indexFromLastRow}*21px - 8px)));`;
  }}

  > * {
    padding: ${SPACING.two}px;
  }

  ${frostedGlassBg(BACKGROUND_COLORS.nine, BACKGROUND_COLORS.seven, 8)}
`;

export const DetailHeader = styled.div`
  padding: ${SPACING.two}px;
  border-bottom: 1px solid ${BORDER_COLORS.one};
  margin-bottom: ${SPACING.one}px;
  text-transform: capitalize;
`;

export const DetailBlock = styled.div`
  margin: ${SPACING.two}px auto;
  padding: 0 ${SPACING.two}px;
`;

export const TradeCol = styled.div<{ isHeader?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0px ${SPACING.one}px !important;
  ${({ isHeader }) =>
    isHeader
      ? css`
          ${FONT_CSS.body.five}
          color: ${TEXT_COLORS.three};
        `
      : css`
          ${FONT_CSS.data.three}
        `}

  > span {
    justify-content: flex-end;
  }
`;

export const IVValue = styled.span`
  ${FONT_CSS.data.four}
  color: ${TEXT_COLORS.one};
  text-align: right;
`;

export const ProtectionBarrierRow = styled.div<{ type: "bid" | "ask" }>`
  ${FONT_CSS.label.three}
  text-align: center;
  padding: ${SPACING.two}px;
  border-bottom: 1px solid ${BORDER_COLORS.one};
  cursor: help;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ type }) => {
    if (type === "bid") {
      return `
        color: ${COLORS.positive.one};
        background-color: ${COLORS.positive.six};

        > svg {
          stroke: ${COLORS.positive.one};
          margin-left: ${SPACING.two}px;
        }
      `;
    }

    return `
      color: ${COLORS.negative.one};
      background-color: ${COLORS.negative.six};

      > svg {
        stroke: ${COLORS.negative.one};
        margin-left: ${SPACING.two}px;
      }
    `;
  }}
`;
