import { useTranslation } from "react-i18next";
import { Padding } from "./style";
import { ChainIdEnum } from "../../enums/chain";
import SelectMultichainNetwork from "../SelectMultichainNetwork";
import { HeaderSubText } from "../shared/SharedModalStyles/styles";
import { InputContainer, InputLabel } from "./Deposit/style";

interface ISwitchNetworkProps {
  selectedNetwork: ChainIdEnum;
  onChangeSelectedNetwork: (chain: ChainIdEnum) => void;
}

function SwitchNetwork({
  selectedNetwork,
  onChangeSelectedNetwork,
}: ISwitchNetworkProps) {
  const { t } = useTranslation("app", { keyPrefix: "ConnectWalletModal.SwitchNetwork" });

  return (
    <>
      <HeaderSubText>{t("unsupported_network_desc")}</HeaderSubText>
      <Padding>
        <InputContainer>
          <InputLabel>{t("select_supported_network")}</InputLabel>
          <SelectMultichainNetwork
            selectedChain={selectedNetwork}
            onSelectChain={onChangeSelectedNetwork}
          />
        </InputContainer>
      </Padding>
    </>
  );
}
export default SwitchNetwork;
