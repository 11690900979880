import { TFunction } from "react-i18next";
import { ReactComponent as Globe } from "../../assets/svg/globe.svg";
import { LAYER_COLORS } from "../design/colors";
import { SPACING } from "../design/spacing";

export const STRATEGY_GENESIS_TIMESTAMP = 1721084400000000000;
export interface IStrategyDetails {
  title: string;
  subtitle?: string;
  strategy_id: string;
  logo: JSX.Element;
  view_type: "neutral" | "bullish" | "bearish";
  risk: "low" | "medium" | "high";
  strategy_type: "perpetual" | "options";
  description: string;
  risk_description: JSX.Element | string;
  fee_description?: JSX.Element | string;
}

export enum TestnetStrategyAddressEnum {
  ETH_BASIS_TRADE = "0xAc612bb9eF0207FaCEED3D3E615e91CB65c34cbE",
}

export enum MainnetStrategyAddressEnum {
  ETH_BASIS_TRADE = "0xd7d1580a09A1Bb4C466295432045E2702ae71C2F",
}

export const VALID_STRATEGY_IDS = ["aevo-basis-trade"];

function BasisTradeLogo() {
  return (
    <Globe
      style={{
        stroke: "white",
        height: 36,
        width: 36,
        padding: SPACING.one,
        background: LAYER_COLORS.three,
        borderRadius: "50%",
      }}
    />
  );
}

function basisTradeDetails(t: TFunction): IStrategyDetails {
  const translate = (key: string) =>
    t(`constants.strategies.aevo-basis-trade.${key}`, { ns: "app" });
  return {
    title: translate("title"),
    subtitle: translate("subtitle"),
    strategy_id: "aevo-basis-trade",
    logo: <BasisTradeLogo />,
    view_type: translate("view_type") as "neutral" | "bullish" | "bearish",
    risk: "low",
    strategy_type: translate("strategy_type") as "perpetual" | "options",
    description: translate("description"),
    risk_description: (
      <div>
        {translate("risk_description_1")}
        <ul>
          <li>{translate("risk_description_2")}</li>
          <li>{translate("risk_description_3")}</li>
          <li>{translate("risk_description_4")}</li>
        </ul>
      </div>
    ),
    fee_description: translate("fee_description"),
  };
}

export const STRATEGY_DETAILS: Record<
  string,
  (t: TFunction) => IStrategyDetails
> = {
  [TestnetStrategyAddressEnum.ETH_BASIS_TRADE]: (t: TFunction) =>
    basisTradeDetails(t),
  [MainnetStrategyAddressEnum.ETH_BASIS_TRADE]: (t: TFunction) =>
    basisTradeDetails(t),
};
