import { create } from "zustand";
import { IWSSMark, IWSSMarkPriceDataResponse } from "../../model/markPrice";
import { mountZustandDevtool } from "../../../../utils/zustand";

export interface ITickerToMark {
  // eg. "ETH:PERP": {}
  // "ETH:OPTION:{}"
  [markTicker: string]: IWSSMark;
}

interface IMarkPriceStoreState {
  markTickers: Set<string>;
  mark: ITickerToMark;
  
  // Mutations
  addMarkTickers: (tickers: string[]) => void;
  removeMarkTickers: (tickers: string[]) => void;
  updateMark: (data: IWSSMarkPriceDataResponse) => void;
}

export const useMarkPriceStore = create<IMarkPriceStoreState>()((setState) => ({
  markTickers: new Set(),
  addMarkTickers: (tickers: string[]) => setState((state) => {

    const newTickers = tickers.filter((t) => !state.markTickers.has(t))
    if (newTickers.length) {
      // Update if not already included
      const newIndexSet = new Set([
        ...Array.from(state.markTickers),
        ...newTickers
      ])
      return {
        markTickers: newIndexSet
      }
    }
    return state
  }),
  removeMarkTickers: (tickers: string[]) => setState((state) => {

    const existingTickers = Array.from(state.markTickers).filter((t) => tickers.includes(t))
    
    if (existingTickers.length) {
      // Update if not already included
      const newIndexSet = new Set([
        ...existingTickers,
      ])

      return {
        markTickers: newIndexSet
      }
    }
    return state
  }),

  // DATA
  mark: {},
  updateMark: (data: IWSSMarkPriceDataResponse) => setState((s) => {
    const prices = data.prices.reduce(
      (prevPrices, curr) => ({
        ...prevPrices,
        [curr.instrument_name]: curr,
      }),
      {} as ITickerToMark
    );

    return {
      mark: {
        ...s.mark,
        ...prices,
      }
    }
  })
}));

mountZustandDevtool("useMarkPriceStore", useMarkPriceStore)