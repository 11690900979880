import { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_STYLE, FONT_SIZE } from "../../../constants/design/fontSize";
import { PageEndpointEnum } from "../../../enums/endpoint";
import ExternalLinkText from "../../ExternalLinkText";
import { LinkText } from "../Header/style";
import {
  AnnouncementBanner,
  AnnouncementContent,
  AnnouncementHeader,
  BottomContent,
  CloseButton,
  Indicator,
  TopContent,
} from "./style";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import { IAirtableValues } from "../../../hooks/api/announcement/useAnnouncement";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";

export type AnnouncementType = "alert" | "feature";

interface IAnnouncementProp {
  announcement: IAirtableValues;
}

function AnnouncementCloseButton({ onClose }: any) {
  return (
    <CloseButton onClick={onClose}>
      <Close />
    </CloseButton>
  );
}

// use airtable for alerts
// use vercel env vars for features
function Announcement({ announcement }: IAnnouncementProp) {
  const navigate = useNavigate();

  const { isMobileScreen } = useScreenSize();
  const announcementActionTitle = announcement.actionTitle || "";
  const announcementKey = announcement.key || "";
  const announcementLink = announcement.link || "";
  const announcementTitle = announcement.title || "";
  const isAlert = announcement.announcementType === "alert";

  const [hideAnnouncement, setHideAnnouncement] = useState<boolean>(false);

  useEffect(() => {
    setHideAnnouncement(
      announcementKey === "" || Boolean(localStorage.getItem(announcementKey))
    );
  }, [announcementKey]);

  const setHideCallback = useCallback(() => {
    localStorage.setItem(announcementKey, "true");
    setHideAnnouncement(true);
  }, [announcementKey]);

  // change this callback when the announcement type changes
  const onAnnouncementActionClick = useCallback(() => {
    setHideCallback();
    navigate(PageEndpointEnum.TRADING);
  }, [navigate, setHideCallback]);

  const textColor = TEXT_COLORS.one;

  const banner = useMemo(
    () => (
      <AnnouncementBanner>
        <AnnouncementContent>
          <TopContent>
            <AnnouncementHeader isAlert={isAlert}>
              <Indicator isAlert={isAlert} />
              <div>{announcementTitle}</div>
            </AnnouncementHeader>
            <AnnouncementCloseButton onClose={() => setHideCallback()} />
          </TopContent>
          {announcementActionTitle && (
            <BottomContent>
              {announcementLink ? (
                <ExternalLinkText
                  color={textColor}
                  hoverColor={textColor}
                  wrapperStyle={{
                    textDecoration: "underline",
                    textDecorationColor: textColor,
                    lineHeight: FONT_STYLE.body.five.lineHeight,
                    fontSize: FONT_STYLE.body.five.fontSize,
                    justifyContent: "flex-start",
                  }}
                  href={announcementLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={onAnnouncementActionClick}
                  hideArrow
                >
                  {announcementActionTitle}
                </ExternalLinkText>
              ) : (
                <LinkText
                  style={{
                    color: textColor,
                    textDecoration: "underline",
                    fontSize: FONT_SIZE.one,
                  }}
                  onClick={onAnnouncementActionClick}
                >
                  {announcementActionTitle}
                </LinkText>
              )}
            </BottomContent>
          )}
        </AnnouncementContent>
      </AnnouncementBanner>
    ),
    [
      announcementActionTitle,
      announcementLink,
      announcementTitle,
      isAlert,
      onAnnouncementActionClick,
      setHideCallback,
      textColor,
    ]
  );

  if (!isAlert && isMobileScreen) {
    return null;
  }

  if (hideAnnouncement || !announcementTitle) {
    return null;
  }

  return banner;
}

export default Announcement;
