import { useTranslation } from "react-i18next";
import { TEXT_COLORS } from "../../constants/design/colors";
import { LINKS } from "../../constants/links";
import { BaseModal } from "../BaseModal";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import ExternalLinkText from "../ExternalLinkText";
import { OFACWrapper } from "./style";

interface IOFACModalProps {
  show: boolean;
  onHide: () => void;
  onContinue: () => void;
}

function OFACModal({ show, onHide, onContinue }: IOFACModalProps) {
  const { t } = useTranslation("app", { keyPrefix: "OFACModal" });

  return (
    <BaseModal
      id={"OFACModal"}
      noContentPadding
      onHide={onHide}
      show={show}
      title={t("terms_of_service")}
    >
      <OFACWrapper>
        <p>{t("ofac_desc_1")}:</p>
        <ul>
          <li>{t("ofac_desc_2")}</li>
          <li>{t("ofac_desc_3")}</li>
          <li>
            {t("ofac_desc_4")}{" "}
            <ExternalLinkText
              href={LINKS.terms}
              hideArrow
              inline
              target="_blank"
              rel="noreferrer"
              color={TEXT_COLORS.one}
            >
              {t("terms_of_service")}
            </ExternalLinkText>
          </li>
        </ul>
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          fullWidth
          onClick={onContinue}
        >
          {t("continue")}
        </Button>
      </OFACWrapper>
    </BaseModal>
  );
}

export default OFACModal;
