import styled from "styled-components";
import { TEXT_COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";

export const TradeColumn = styled.span<{ width: string; color?: string }>`
  padding: 0 ${SPACING.two}px;
  width: ${({ width }) => width} !important;
  color: ${({ color }) => color ?? TEXT_COLORS.one};
  justify-content: flex-end !important;
`;
