import styled from "styled-components";
import { TEXT_COLORS } from "../../constants/design/colors";
import { FONT_STYLE } from "../../constants/design/fontSize";
import { SPACING } from "../../constants/design/spacing";

export const CancelButton = styled.button`
  ${FONT_STYLE.body.five}
  color: ${TEXT_COLORS.two};
  transition: 0.2s ease-in-out;
  white-space: nowrap;
  margin-left: ${SPACING.two}px;

  &:hover:not(:disabled) {
    color: ${TEXT_COLORS.one};
  }

  &:disabled {
    opacity: 0.6;
  }
`;
