/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import currency from "currency.js";
import { AnimatePresence } from "framer-motion";
import moment from "moment";
import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { TFunction, useTranslation } from "react-i18next";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import logo from "../../../assets/logo/logo_white.svg";
import { arrowUpRight as Arrow } from "../../../assets/styledSvg/arrowUpRight";
import { ReactComponent as MMPLogo } from "../../../assets/svg/activity.svg";
import arrowDown from "../../../assets/svg/arrow-down.svg";
import payment from "../../../assets/svg/dollar.svg";
import { ReactComponent as EmailLogo } from "../../../assets/svg/email.svg";
import exclaimation from "../../../assets/svg/exclaimation.svg";
import checkmark from "../../../assets/svg/liquidation-checkmark.svg";
import pause from "../../../assets/svg/pause.svg";
import {
  CollateralAssetResponse,
  GetNotifications200Response,
  InstrumentType,
  NotificationTypeResponse,
  OrderTypeResponse,
  SideResponse,
} from "../../../codegen-api";
import {
  BACKGROUND_COLORS,
  COLORS,
  PRIMARY_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { COMPONENTS } from "../../../constants/design/spacing";
import { getRewardDetails } from "../../../constants/rewards";
import {
  IMarketPrecisionGetter,
  MarketInstrumentContext,
} from "../../../contexts/MarketInstrumentContext";
import { NotificationContext } from "../../../contexts/NotificationContext";
import {
  alertNotificationTypes,
  useNotifications,
} from "../../../hooks/api/notifications/useNotifications";
import { Pill } from "../../../pages/PortfolioPage/mobile/style";
import { IHasMobileComponentProps } from "../../../pages/shared";
import {
  getAssetLogo,
  getAssetShortName,
  getCollateralAssetByAddress,
  getCollateralPrecision,
} from "../../../utils/asset/assets";
import { getTimeAgo, nanosToSeconds } from "../../../utils/date";
import {
  formatCollateralDecimals,
  formatSizePrecision,
} from "../../../utils/format";
import { shortenAddress, startCase } from "../../../utils/strings";
import { Badge } from "../Badge";
import { Spinner } from "../Spinner";
import { SpinnerContainerWrapper } from "../Spinner/style";
import {
  Content,
  EmptyContent,
  FillNotificationBody,
  FillNotificationTitle,
  Icon,
  IconWrapper,
  LogoIcon,
  MarkAsRead,
  NotificationWrapper,
  NotificationsHeader,
  NotificationsWrapper,
  PillContent,
  ReadIndicator,
  SettlementNotificationBody,
  SettlementNotificationTitle,
  SettlementType,
  StaticExternalLinkWrapper,
  SvgIconWrapper,
  TextWrapper,
  Timestamp,
  Title,
} from "./style";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import { getRoundedBoost } from "../../../utils/math";

interface INotificationProps {
  notification: GetNotifications200Response;
  t: TFunction;
  translateAll: TFunction;
}

interface IMarketNotificationProps extends INotificationProps {
  getMarketPrecision: IMarketPrecisionGetter;
}

function DepositReceiveNotification({
  notification,
  t,
  translateAll,
}: INotificationProps) {
  const amount = notification.metadata?.amount || "0";
  const collateral = notification.metadata?.collateral
    ? getCollateralAssetByAddress(notification.metadata?.collateral)
    : undefined;
  const formattedCollateralAmount = `${formatCollateralDecimals(
    amount,
    collateral
  )} ${getAssetShortName(collateral)}`;

  let title: string | JSX.Element = t("deposit_complete");
  let subtitle: string | JSX.Element = t("deposit_complete_desc", {
    asset: formattedCollateralAmount,
  });

  const rewardsDetails = getRewardDetails({
    type: "notification",
    notificationOrTransaction: notification,
    t: translateAll,
  });

  if (notification.notification_type === NotificationTypeResponse.Receive) {
    title = t("transfer_received");

    subtitle = t("transfer_received_desc", {
      asset: formattedCollateralAmount,
      address: shortenAddress(notification.metadata?.account || ""),
    });

    if (rewardsDetails) {
      const { link, programName } = rewardsDetails;
      title = t("program_received", { programName });
      if (rewardsDetails.link && programName !== "Automatic Credit") {
        subtitle = (
          <span>
            {t("program_received_desc_1", {
              asset: formattedCollateralAmount,
              programName,
            })}
            <StaticExternalLinkWrapper
              href={link}
              target="_blank"
              rel="noreferrer"
            >
              {t("program_received_desc_2")} <Arrow />
            </StaticExternalLinkWrapper>
          </span>
        );
      } else if (notification.metadata?.label === "AUTOMATIC_CREDIT") {
        title = t("trading_credits_received");
        subtitle = (
          <span>
            {t("trading_credits_received_desc", {
              asset: formattedCollateralAmount,
            })}
          </span>
        );
      } else {
        subtitle = t("default_received_desc", {
          asset: formattedCollateralAmount,
          programName,
        });
      }
    }
  }

  return (
    <NotificationWrapper isRead={notification.is_read}>
      <IconWrapper
        backgroundColor={
          rewardsDetails ? COLORS.highlight.five : BACKGROUND_COLORS.two
        }
      >
        <Icon src={rewardsDetails ? payment : arrowDown} />
        {!notification.is_read && <ReadIndicator />}
      </IconWrapper>
      <div>
        <Title>{title}</Title>
        <Content color={TEXT_COLORS.two}>{subtitle}</Content>
        <Timestamp>
          {getTimeAgo(
            moment.unix(nanosToSeconds(notification.created_timestamp)),
            translateAll
          )}
        </Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function WithdrawalNotification({
  notification,
  t,
  translateAll,
}: INotificationProps) {
  const amount = notification.metadata?.amount || "0";
  const collateralAddress =
    notification.metadata?.collateral || notification.metadata?.l1Token;
  const collateral = collateralAddress
    ? getCollateralAssetByAddress(collateralAddress)
    : undefined;
  const formattedCollateralAmount = `${formatCollateralDecimals(
    amount,
    collateral
  )} ${getAssetShortName(collateral)}`;

  return (
    <NotificationWrapper isRead={notification.is_read}>
      <IconWrapper backgroundColor={BACKGROUND_COLORS.two}>
        <Icon src={arrowDown} style={{ transform: "rotate(180deg)" }} />
        {!notification.is_read && <ReadIndicator />}
      </IconWrapper>
      <div>
        <Title>{t("withdrawal_complete")}</Title>
        <Content color={TEXT_COLORS.two}>
          {t("withdrawal_complete_desc", { asset: formattedCollateralAmount })}
        </Content>
        <Timestamp>
          {getTimeAgo(
            moment.unix(nanosToSeconds(notification.created_timestamp)),
            translateAll
          )}
        </Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function DepositWithdrawStrategyNotification({
  notification,
  t,
  translateAll,
}: INotificationProps) {
  const label = notification?.metadata?.label;
  const amount = notification.metadata?.amount || "0";
  const collateralAddress =
    notification.metadata?.collateral || notification.metadata?.l1Token;
  const collateral = collateralAddress
    ? getCollateralAssetByAddress(collateralAddress)
    : undefined;
  const formattedCollateralAmount = `${formatCollateralDecimals(
    amount,
    collateral
  )} ${getAssetShortName(collateral)}`;
  return (
    <NotificationWrapper isRead={notification.is_read}>
      <IconWrapper backgroundColor={BACKGROUND_COLORS.two}>
        <Icon
          src={arrowDown}
          style={{
            transform: label === "MA_DEPOSIT" ? "" : "rotate(180deg)",
          }}
        />

        {!notification.is_read && <ReadIndicator />}
      </IconWrapper>
      <div>
        <Title>
          {label === "MA_DEPOSIT"
            ? t("strategy_deposit_completed")
            : t("strategy_withdrawal_completed")}
        </Title>
        <Content color={TEXT_COLORS.two}>
          <span style={{ color: TEXT_COLORS.one }}>
            {formattedCollateralAmount}&nbsp;
          </span>
          <span>
            {label === "MA_DEPOSIT"
              ? t("strategy_deposit_completed_desc")
              : t("strategy_withdrawal_completed_desc")}
          </span>
        </Content>
        <Timestamp>
          {getTimeAgo(
            moment.unix(nanosToSeconds(notification.created_timestamp)),
            translateAll
          )}
        </Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function MMPUpdatedOrUnfrozenNotification({
  notification,
  t,
  translateAll,
}: INotificationProps) {
  const isUnfrozenNotification =
    notification.notification_type === NotificationTypeResponse.MmpUnfrozen;
  return (
    <NotificationWrapper isRead={notification.is_read}>
      <IconWrapper
        backgroundColor={
          notification.metadata?.mmp_enabled || isUnfrozenNotification
            ? COLORS.highlight.five
            : COLORS.negative.five
        }
      >
        <SvgIconWrapper
          color={
            notification.metadata?.mmp_enabled || isUnfrozenNotification
              ? COLORS.highlight.one
              : COLORS.negative.one
          }
        >
          <MMPLogo />
        </SvgIconWrapper>
        {!notification.is_read && <ReadIndicator />}
      </IconWrapper>
      <div>
        <Title>
          {isUnfrozenNotification
            ? t("account_unfrozen")
            : notification.metadata?.mmp_enabled
            ? t("mmp_enabled")
            : t("mmp_disabled")}
        </Title>
        <Content color={TEXT_COLORS.two}>
          {isUnfrozenNotification
            ? t("account_unfrozen_desc")
            : notification.metadata?.mmp_enabled
            ? t("mmp_enabled_desc")
            : t("mmp_disabled_desc")}
        </Content>
        <Timestamp>
          {getTimeAgo(
            moment.unix(nanosToSeconds(notification.created_timestamp)),
            translateAll
          )}
        </Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function MMPTriggeredNotification({
  notification,
  t,
  translateAll,
}: INotificationProps) {
  const trigger = notification.metadata?.trigger;
  return (
    <NotificationWrapper
      isRead={notification.is_read}
      style={{
        backgroundColor: COLORS.negative.six,
      }}
    >
      <IconWrapper backgroundColor={COLORS.negative.five}>
        <Icon src={exclaimation} />
        {!notification.is_read && <ReadIndicator color={COLORS.negative.one} />}
      </IconWrapper>
      <div>
        <Title color={COLORS.negative.one}>{t("mmp_triggered")}</Title>
        <Content color={TEXT_COLORS.two}>
          <span>
            Your account has been frozen because{" "}
            <span style={{ color: TEXT_COLORS.one }}>
              {trigger ? `${startCase(trigger)} Limit` : "Limits"}
            </span>{" "}
            were breached.
          </span>
        </Content>
        <Timestamp>
          {getTimeAgo(
            moment.unix(nanosToSeconds(notification.created_timestamp)),
            translateAll
          )}
        </Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function LiquidationStartedNotification({
  notification,
  t,
  translateAll,
}: INotificationProps) {
  return (
    <NotificationWrapper isRead={notification.is_read}>
      <IconWrapper backgroundColor={COLORS.negative.five}>
        <Icon src={pause} />
        {!notification.is_read && <ReadIndicator color={COLORS.negative.one} />}
      </IconWrapper>
      <div>
        <Title color={COLORS.negative.one}>{t("liquidation_mode")}</Title>
        <Content>{t("liquidation_mode_desc")}</Content>
        <Timestamp>
          {getTimeAgo(
            moment.unix(nanosToSeconds(notification.created_timestamp)),
            translateAll
          )}
        </Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function LiquidationCompletedNotification({
  notification,
  t,
  translateAll,
}: INotificationProps) {
  return (
    <NotificationWrapper isRead={notification.is_read}>
      <IconWrapper backgroundColor={COLORS.negative.five}>
        <Icon src={checkmark} />
        {!notification.is_read && <ReadIndicator color={COLORS.negative.one} />}
      </IconWrapper>
      <div>
        <Title color={COLORS.negative.one}>{t("liquidations_completed")}</Title>
        <Content>
          {t("liquidations_completed_desc_1", {
            fee: currency(Number(notification?.metadata?.fees)).format({
              precision: 2,
            }),
          })}{" "}
          {Number(notification?.metadata?.position_count) > 1
            ? t("liquidations_completed_desc_2_single")
            : t("liquidations_completed_desc_2_plural", {
                count: Number(notification?.metadata?.position_count),
              })}
        </Content>
        <Timestamp>
          {getTimeAgo(
            moment.unix(nanosToSeconds(notification.created_timestamp)),
            translateAll
          )}
        </Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function FillNotification({
  notification,
  t,
  translateAll,
  getMarketPrecision,
}: IMarketNotificationProps) {
  const { metadata } = notification;

  const isCollateralConversion = useMemo(
    () =>
      (Object.values(CollateralAssetResponse) as string[]).includes(
        metadata?.asset || ""
      ),
    [metadata?.asset]
  );

  const fillTitle = useMemo(() => {
    if (metadata?.option_type) {
      return (
        <FillNotificationTitle>
          <span>{metadata?.option_type}&nbsp;-&nbsp;</span>
          <span>
            {metadata?.expiry
              ? moment
                  .unix(nanosToSeconds(metadata?.expiry))
                  .format("DD MMM YY")
              : ""}
            &nbsp;-&nbsp;
          </span>
          <span>{metadata?.strike}</span>
        </FillNotificationTitle>
      );
    }

    if (isCollateralConversion) {
      // Spot
      return (
        <FillNotificationTitle>
          <span>
            {getAssetShortName(metadata?.asset)} {t("spot")}
          </span>
        </FillNotificationTitle>
      );
    }

    return (
      <FillNotificationTitle>
        <span>{metadata?.asset} PERP</span>
      </FillNotificationTitle>
    );
  }, [isCollateralConversion, metadata, t]);

  const fillPill = useMemo(() => {
    if (isCollateralConversion) {
      return undefined;
    }

    if (metadata?.order_type === OrderTypeResponse.Market) {
      return t("market_order_filled");
    }
    return metadata?.side === SideResponse.Buy
      ? t("bid_filled")
      : t("ask_filled");
  }, [isCollateralConversion, metadata?.order_type, metadata?.side, t]);

  let { amount_precision, price_precision } = getMarketPrecision(
    metadata?.asset,
    metadata?.option_type ? InstrumentType.Option : InstrumentType.Perpetual
  );

  if (isCollateralConversion) {
    price_precision = 2;
    amount_precision =
      getCollateralPrecision(metadata?.asset as CollateralAssetResponse) || 2;
  }

  return (
    <NotificationWrapper isRead={notification.is_read}>
      <IconWrapper>
        <Icon src={getAssetLogo(metadata?.asset)} style={{ padding: 0 }} />
        {!notification.is_read && <ReadIndicator />}
      </IconWrapper>
      <div>
        <Title>
          {fillTitle}
          {fillPill && (
            <Pill
              isPositive={metadata?.side === SideResponse.Buy}
              borderRadius={"4px"}
            >
              <PillContent>{fillPill}</PillContent>
            </Pill>
          )}
        </Title>
        <FillNotificationBody>
          <span>
            {formatSizePrecision(
              Number(metadata?.amount || 0),
              amount_precision
            )}
          </span>
          <span style={{ color: TEXT_COLORS.three }}>
            {isCollateralConversion
              ? t("asset_at", { asset: getAssetShortName(metadata?.asset) })
              : t("contracts_at")}
          </span>
          <span>
            {currency(Number(metadata?.price || 0), {
              precision: price_precision,
            }).format()}
          </span>
        </FillNotificationBody>
        <Timestamp>
          {getTimeAgo(
            moment.unix(nanosToSeconds(notification.created_timestamp)),
            translateAll
          )}
        </Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function ReferralNotification({
  notification,
  t,
  translateAll,
}: INotificationProps) {
  return (
    <NotificationWrapper isRead={notification.is_read}>
      <IconWrapper backgroundColor={COLORS.neutral.one}>
        <LogoIcon src={logo} style={{ padding: "2px" }} />
        {!notification.is_read && <ReadIndicator color={COLORS.neutral.one} />}
      </IconWrapper>
      <div>
        <Title color={COLORS.neutral.one}>{t("new_trader_referred")}</Title>
        <Content color={TEXT_COLORS.two}>
          {t("new_trader_referred_desc", {
            commission: Number(notification.metadata?.commission) * 100,
          })}
        </Content>
        <Timestamp>
          {getTimeAgo(
            moment.unix(nanosToSeconds(notification.created_timestamp)),
            translateAll
          )}
        </Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function RefereeNotification({
  notification,
  t,
  translateAll,
}: INotificationProps) {
  return (
    <NotificationWrapper isRead={notification.is_read}>
      <IconWrapper backgroundColor={COLORS.neutral.one}>
        <LogoIcon src={logo} style={{ padding: "2px" }} />
        {!notification.is_read && <ReadIndicator color={COLORS.neutral.one} />}
      </IconWrapper>
      <div>
        <Title color={COLORS.neutral.one}>{t("trading_fee_discount")}</Title>
        <Content color={TEXT_COLORS.two}>
          {t("trading_fee_discount_desc", {
            commission: Number(notification.metadata?.commission) * 100,
          })}{" "}
          <div style={{ color: COLORS.blue.one, display: "contents" }}>
            &apos;{notification?.metadata?.username}&apos;
          </div>
          .
        </Content>
        <Timestamp>
          {getTimeAgo(
            moment.unix(nanosToSeconds(notification.created_timestamp)),
            translateAll
          )}
        </Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function SettlementNotification({
  notification,
  t,
  translateAll,
  getMarketPrecision,
}: IMarketNotificationProps) {
  const { metadata } = notification;

  const { price_precision, amount_precision } = getMarketPrecision(
    metadata?.asset,
    InstrumentType.Option
  );

  const pnlCurrency = currency(
    metadata?.side === SideResponse.Buy
      ? Number(metadata?.payout || 0)
      : Number(metadata?.payout || 0) + Number(metadata?.entry_price || 0),
    { precision: price_precision, pattern: "+!#" }
  );

  let inTheMoney = false;
  // Long, positive payout = itm, else otm
  // Short, negative payout = itm, else otm
  if (
    (metadata?.side === SideResponse.Buy && pnlCurrency.value > 0) ||
    (metadata?.side === SideResponse.Sell && pnlCurrency.value < 0)
  ) {
    inTheMoney = true;
  }

  const fillTitle = useMemo(
    () => (
      <SettlementNotificationTitle>
        <span>
          <span>{metadata?.option_type}&nbsp;-&nbsp;</span>
          <span>
            {metadata?.expiry
              ? moment
                  .unix(nanosToSeconds(metadata?.expiry))
                  .format("DD MMM YY")
              : ""}
            &nbsp;-&nbsp;
          </span>
          <span>{metadata?.strike}</span>
        </span>
        <SettlementType side={metadata?.side}>
          {metadata?.side === SideResponse.Sell
            ? t("short_settlement")
            : t("long_settlement")}
        </SettlementType>
      </SettlementNotificationTitle>
    ),
    [metadata, t]
  );

  return (
    <NotificationWrapper isRead={notification.is_read}>
      <IconWrapper>
        <Icon
          src={getAssetLogo(notification.metadata?.asset)}
          style={{ padding: 0 }}
        />
        {!notification.is_read && <ReadIndicator />}
      </IconWrapper>
      <div>
        <Title>{fillTitle}</Title>
        <SettlementNotificationBody>
          <span>
            <strong>
              {metadata?.amount
                ? Math.abs(Number(metadata?.amount)).toFixed(amount_precision)
                : "0"}{" "}
            </strong>
            {metadata?.side === SideResponse.Buy
              ? t("contracts_bought_desc")
              : t("contracts_sold_desc")}{" "}
            <strong>
              {inTheMoney ? t("in_the_money") : t("out_the_money")}{" "}
            </strong>
            {pnlCurrency.value >= 0
              ? t("realized_profit_desc")
              : t("realized_loss_desc")}{" "}
            <span
              style={{
                color:
                  pnlCurrency.value >= 0
                    ? COLORS.positive.one
                    : COLORS.negative.one,
              }}
            >
              {pnlCurrency.format()}
            </span>
          </span>
        </SettlementNotificationBody>
        <Timestamp>
          {getTimeAgo(
            moment.unix(nanosToSeconds(notification.created_timestamp)),
            translateAll
          )}
        </Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function EmailVerificationNotification({
  notification,
  t,
  translateAll,
}: INotificationProps) {
  return (
    <NotificationWrapper isRead={notification.is_read}>
      <IconWrapper backgroundColor={COLORS.highlight.five}>
        <SvgIconWrapper color={COLORS.highlight.one}>
          <EmailLogo />
        </SvgIconWrapper>
        {!notification.is_read && <ReadIndicator color={COLORS.neutral.one} />}
      </IconWrapper>
      <div>
        <Title color={COLORS.neutral.one}>{t("email_verified")}</Title>
        <Content color={TEXT_COLORS.two}>{t("email_verified_desc")}</Content>
        <Timestamp>
          {getTimeAgo(
            moment.unix(nanosToSeconds(notification.created_timestamp)),
            translateAll
          )}
        </Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function SendFinalizedNotification({
  notification,
  t,
  translateAll,
}: INotificationProps) {
  const amount = notification.metadata?.amount || "0";
  const collateral = CollateralAssetResponse.Usdc;
  const label = notification.metadata?.label;
  const formattedCollateralAmount = `${formatCollateralDecimals(
    amount,
    collateral
  )} ${getAssetShortName(collateral)}`;

  const title: string | JSX.Element =
    label === "YV_DEPOSIT"
      ? t("vault_deposit_complete")
      : t("vault_redemption_complete");
  const subtitle: string | JSX.Element =
    label === "YV_DEPOSIT"
      ? t("vault_deposit_complete_desc", {
          amount: formattedCollateralAmount,
        })
      : t("vault_redemption_complete_desc", {
          amount: formattedCollateralAmount,
        });

  return (
    <NotificationWrapper isRead={notification.is_read}>
      <IconWrapper backgroundColor={PRIMARY_COLORS.one}>
        <Icon
          src={arrowDown}
          style={{ transform: label === "YV_DEPOSIT" ? "" : "rotate(180deg)" }}
        />
        {!notification.is_read && <ReadIndicator />}
      </IconWrapper>
      <div>
        <Title>{title}</Title>
        <Content color={TEXT_COLORS.two}>{subtitle}</Content>
        <Timestamp>
          {getTimeAgo(
            moment.unix(nanosToSeconds(notification.created_timestamp)),
            translateAll
          )}
        </Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function FarmboostAppliedNotification({
  notification,
  t,
  translateAll,
}: INotificationProps) {
  const farmBoost = Number(
    Number(notification.metadata?.farm_boost || "0").toFixed(0)
  );
  const roundedFarmBoost = getRoundedBoost(farmBoost);
  const title: string = t("volume_boost_title", {
    roundedFarmBoost,
  });
  const subtitle: string | JSX.Element = (
    <span>
      {t("volume_boost_desc_1")}{" "}
      <span style={{ color: TEXT_COLORS.one }}>
        {roundedFarmBoost}x {t("volume_boost_desc_2")}
      </span>{" "}
      {t("volume_boost_desc_3")}
    </span>
  );

  return (
    <NotificationWrapper
      isRead={notification.is_read}
      style={{ background: PRIMARY_COLORS.three }}
    >
      <TextWrapper>{roundedFarmBoost}x</TextWrapper>
      <div>
        <Title>{title}</Title>
        <Content color={TEXT_COLORS.two}>{subtitle}</Content>
        <Timestamp>
          {getTimeAgo(
            moment.unix(nanosToSeconds(notification.created_timestamp)),
            translateAll
          )}
        </Timestamp>
      </div>
    </NotificationWrapper>
  );
}

interface INotificationPanelProps extends IHasMobileComponentProps {
  modalRef: MutableRefObject<any>;
  onClose?: () => void;
}

export function NotificationPanel({
  modalRef,
  isMobile,
  onClose,
}: INotificationPanelProps) {
  const {
    data: notificationsData,
    isValidating,
    unreadNotifications,
    markAsRead,
    mutate,
  } = useNotifications();
  const { getMarketPrecision } = useContext(MarketInstrumentContext);
  const { showPanel } = useContext(NotificationContext);
  const { t } = useTranslation("app", {
    keyPrefix: "shared.NotificationPanel",
  });
  const { t: translateAll } = useTranslation();
  const HIGH_LOAD_MODE = process.env.REACT_APP_HIGH_LOAD_MODE === "true";

  const notificationsBadgeColor = useMemo(() => {
    // Determine whether there's an unread alert event
    const hasUnreadAlerts = unreadNotifications.some((n) =>
      alertNotificationTypes.includes(n.notification_type)
    );

    if (hasUnreadAlerts) return [COLORS.negative.one, COLORS.negative.four];
    if (unreadNotifications.length > 0) {
      return [COLORS.blue.one, COLORS.blue.two];
    }

    return [TEXT_COLORS.one, BACKGROUND_COLORS.one];
  }, [unreadNotifications]);

  const isLoading = useMemo(
    () => !notificationsData && isValidating,
    [isValidating, notificationsData]
  );

  // Only reload notifications when showing panel
  useEffect(() => {
    if (showPanel) {
      mutate();
    }
  }, [mutate, showPanel]);

  useEffect(() => {
    if (showPanel && unreadNotifications.length > 0) {
      markAsRead();
    }
  }, [markAsRead, showPanel, unreadNotifications]);

  const notificationContent = useCallback(
    (notification: GetNotifications200Response) => {
      switch (notification.notification_type) {
        case NotificationTypeResponse.LiquidationStarted:
          return (
            <LiquidationStartedNotification
              notification={notification}
              t={t}
              translateAll={translateAll}
            />
          );
        case NotificationTypeResponse.LiquidationCompleted:
          return (
            <LiquidationCompletedNotification
              notification={notification}
              t={t}
              translateAll={translateAll}
            />
          );
        case NotificationTypeResponse.Fill:
          return (
            <FillNotification
              notification={notification}
              getMarketPrecision={getMarketPrecision}
              t={t}
              translateAll={translateAll}
            />
          );
        case NotificationTypeResponse.DepositFinalized:
        case NotificationTypeResponse.Receive:
          if (notification?.metadata?.label === "MA_WITHDRAW") {
            return (
              <DepositWithdrawStrategyNotification
                notification={notification}
                t={t}
                translateAll={translateAll}
              />
            );
          }
          return (
            <DepositReceiveNotification
              notification={notification}
              t={t}
              translateAll={translateAll}
            />
          );
        case NotificationTypeResponse.WithdrawalFinalized:
          return (
            <WithdrawalNotification
              notification={notification}
              t={t}
              translateAll={translateAll}
            />
          );
        case NotificationTypeResponse.MmpUpdated:
        case NotificationTypeResponse.MmpUnfrozen:
          return (
            <MMPUpdatedOrUnfrozenNotification
              notification={notification}
              t={t}
              translateAll={translateAll}
            />
          );
        case NotificationTypeResponse.MmpTriggered:
          return (
            <MMPTriggeredNotification
              notification={notification}
              t={t}
              translateAll={translateAll}
            />
          );
        case NotificationTypeResponse.Referral:
          return (
            <ReferralNotification
              notification={notification}
              t={t}
              translateAll={translateAll}
            />
          );
        case NotificationTypeResponse.Referee:
          return (
            <RefereeNotification
              notification={notification}
              t={t}
              translateAll={translateAll}
            />
          );
        case NotificationTypeResponse.Settled:
          return (
            <SettlementNotification
              notification={notification}
              getMarketPrecision={getMarketPrecision}
              t={t}
              translateAll={translateAll}
            />
          );
        case NotificationTypeResponse.EmailVerification:
          return (
            <EmailVerificationNotification
              notification={notification}
              t={t}
              translateAll={translateAll}
            />
          );
        case NotificationTypeResponse.SendFinalized: {
          if (notification?.metadata?.label === "MA_DEPOSIT") {
            return (
              <DepositWithdrawStrategyNotification
                notification={notification}
                t={t}
                translateAll={translateAll}
              />
            );
          }

          return (
            <SendFinalizedNotification
              notification={notification}
              t={t}
              translateAll={translateAll}
            />
          );
        }
        case NotificationTypeResponse.FarmBoostApplied:
          return (
            <FarmboostAppliedNotification
              notification={notification}
              t={t}
              translateAll={translateAll}
            />
          );
        default:
          return null;
      }
    },
    [getMarketPrecision, t, translateAll]
  );

  return (
    <AnimatePresence>
      {showPanel && (
        <NotificationsWrapper
          $isMobile={isMobile}
          ref={modalRef}
          transition={{
            duration: 0.2,
            ease: "easeInOut",
          }}
          initial={{
            opacity: 0,
            transform: `translateX(${COMPONENTS.notificationPanel}px)`,
          }}
          animate={{
            opacity: 1,
            transform: "translateX(0px)",
          }}
          exit={{
            opacity: 0,
            transform: `translateX(${COMPONENTS.notificationPanel}px)`,
          }}
        >
          <NotificationsHeader isMobile={isMobile}>
            <div>
              <p>{t("notifications")}</p>
              {unreadNotifications.length > 0 && (
                <Badge
                  color={notificationsBadgeColor[0]}
                  backgroundColor={notificationsBadgeColor[1]}
                >
                  {unreadNotifications.length}
                </Badge>
              )}
            </div>
            <MarkAsRead>
              <button
                type="button"
                disabled={unreadNotifications.length === 0}
                onClick={markAsRead}
              >
                {t("mark_all_as_read")}
              </button>
            </MarkAsRead>
            {isMobile && (
              <Button buttonTheme={ButtonThemeEnum.NEUTRAL2} onClick={onClose}>
                <Close />
              </Button>
            )}
          </NotificationsHeader>
          <div>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoading ? (
              <SpinnerContainerWrapper>
                <Spinner />
              </SpinnerContainerWrapper>
            ) : (notificationsData || []).length > 0 ? (
              (notificationsData || []).map(
                (notification: GetNotifications200Response) => (
                  <div
                    key={`${notification.created_timestamp}-${notification.notification_type}`}
                  >
                    {notificationContent(notification)}
                  </div>
                )
              )
            ) : (
              <EmptyContent>
                {HIGH_LOAD_MODE ? t("high_load") : t("no_notifications")}
              </EmptyContent>
            )}
          </div>
        </NotificationsWrapper>
      )}
    </AnimatePresence>
  );
}
