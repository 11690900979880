/* eslint-disable no-nested-ternary */
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import brave from "../../assets/svg/brave.svg";
import coinbaseWallet from "../../assets/svg/coinbaseWallet.svg";
import metamask from "../../assets/svg/metamask.svg";
import walletConnect from "../../assets/svg/walletConnect.svg";
import importWallet from "../../assets/svg/import-wallet.svg";
import { IBrowserWalletType } from "../../hooks/wallet/useWallet";
import { WALLET_NAMES, WalletEnum } from "../../utils/wallet/types";
import { Divider } from "../shared/Divider";
import { WalletButton } from "./style";
import { shortenAddress } from "../../utils/strings";
import TooltipExplanation from "../shared/Tooltip";
import { TooltipWrapper } from "../shared/Tooltips/style";
import { ReactComponent as Info } from "../../assets/svg/info.svg";
import okx from "../../assets/svg/assets/OKX.svg";

export type IConnectWalletType = WalletEnum | "manualImport";

interface IConnectWalletProps {
  selectedWallet?: IConnectWalletType;
  connectedWallet?: IConnectWalletType;
  setWallet: (wallet: IConnectWalletType) => void;
  isConnectingToWallet?: IConnectWalletType;
  importedWalletAddress?: string | null;
  hasBrowserWallet: boolean;
  isBraveWalletEnabled: boolean;
}

function ConnectWallet({
  selectedWallet,
  setWallet,
  isConnectingToWallet,
  connectedWallet,
  importedWalletAddress,
  hasBrowserWallet,
  isBraveWalletEnabled,
}: IConnectWalletProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "ConnectWalletModal.ConnectWallet",
  });

  // is brave browser
  const isBraveBrowser = useMemo(
    () =>
      typeof window !== "undefined" &&
      // Check for the 'brave' property in the navigator object
      (("brave" in navigator &&
        (navigator as Navigator & { brave?: unknown }).brave !== undefined) ||
        // Additional check for Brave's promise-based API
        (
          navigator as Navigator & {
            brave?: { isBrave?: () => Promise<boolean> };
          }
        )?.brave?.isBrave?.() !== undefined),
    []
  );

  const walletContent = useCallback(
    (wallet: WalletEnum) => {
      if (isConnectingToWallet === wallet) {
        return t("open_your_wallet_to_connect");
      }

      if (connectedWallet === wallet) {
        return t("connected");
      }

      return WALLET_NAMES[wallet];
    },
    [connectedWallet, isConnectingToWallet, t]
  );

  const browserWalletImg = useCallback((wallet: IBrowserWalletType) => {
    switch (wallet) {
      case WalletEnum.METAMASK:
        return <img src={metamask} alt="metamask" />;
      case WalletEnum.BRAVE:
        return <img src={brave} alt="brave" />;
      case WalletEnum.OKX:
        return <img src={okx} alt="okx" />;
      default:
        return <img alt="no-wallet" />;
    }
  }, []);

  return (
    <>
      {hasBrowserWallet && (
        <WalletButton
          onClick={() => setWallet(WalletEnum.METAMASK)}
          isActive={
            selectedWallet === WalletEnum.METAMASK ||
            connectedWallet === WalletEnum.METAMASK
          }
          disabled={connectedWallet && connectedWallet !== WalletEnum.METAMASK}
        >
          {browserWalletImg(WalletEnum.METAMASK)}
          <span>{walletContent(WalletEnum.METAMASK)}</span>
        </WalletButton>
      )}
      <WalletButton
        onClick={() => setWallet(WalletEnum.WALLETCONNECT)}
        isActive={
          selectedWallet === WalletEnum.WALLETCONNECT ||
          connectedWallet === WalletEnum.WALLETCONNECT
        }
        disabled={
          connectedWallet && connectedWallet !== WalletEnum.WALLETCONNECT
        }
      >
        <img src={walletConnect} alt="walletconnect" />
        <span>{walletContent(WalletEnum.WALLETCONNECT)}</span>
      </WalletButton>
      <WalletButton
        onClick={() => setWallet(WalletEnum.WALLETLINK)}
        isActive={
          selectedWallet === WalletEnum.WALLETLINK ||
          connectedWallet === WalletEnum.WALLETLINK
        }
        disabled={connectedWallet && connectedWallet !== WalletEnum.WALLETLINK}
      >
        <img src={coinbaseWallet} alt="coinbasewallet" />
        <span>{walletContent(WalletEnum.WALLETLINK)}</span>
        <TooltipExplanation
          explanation={t("coinbase_explanation")}
          renderContent={({ ref, ...triggerHandler }) => (
            <TooltipWrapper ref={ref} {...triggerHandler}>
              <Info />
            </TooltipWrapper>
          )}
        />
      </WalletButton>
      {hasBrowserWallet && (
        <WalletButton
          onClick={() => setWallet(WalletEnum.OKX)}
          isActive={
            selectedWallet === WalletEnum.OKX ||
            connectedWallet === WalletEnum.OKX
          }
          disabled={connectedWallet && connectedWallet !== WalletEnum.OKX}
        >
          {browserWalletImg(WalletEnum.OKX)}
          <span>{walletContent(WalletEnum.OKX)}</span>
        </WalletButton>
      )}

      {/* should check brave browser is use */}
      {hasBrowserWallet && isBraveBrowser && isBraveWalletEnabled && (
        <WalletButton
          onClick={() => setWallet(WalletEnum.BRAVE)}
          isActive={
            selectedWallet === WalletEnum.BRAVE ||
            connectedWallet === WalletEnum.BRAVE
          }
          disabled={connectedWallet && connectedWallet !== WalletEnum.BRAVE}
        >
          {browserWalletImg(WalletEnum.BRAVE)}
          <span>{walletContent(WalletEnum.BRAVE)}</span>
        </WalletButton>
      )}
      <WalletButton
        onClick={() => setWallet("manualImport")}
        isActive={
          selectedWallet === "manualImport" ||
          connectedWallet === "manualImport"
        }
        disabled={connectedWallet && connectedWallet !== "manualImport"}
      >
        <img src={importWallet} alt="metamask" />
        <span>
          {importedWalletAddress
            ? `${shortenAddress(importedWalletAddress)} (Imported)`
            : t("manual_import")}
        </span>
      </WalletButton>
      <Divider direction="horizontal" size="100%" />
    </>
  );
}

export default ConnectWallet;
