import useSWR from "swr";
import { GetEmissions200Response } from "../../../codegen-api";
import { publicApi } from "../../../services/api/apiFetcher";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";

export const useEmissions = () => {
  const fetcher = publicApi();

  const swr = useSWR<GetEmissions200Response[]>(
    [APIEndpointEnum.EMISSIONS],
    async () => {
      const { data } = await (await fetcher.getEmissions())();
      return data;
    },
    {
      use: [useLoggerMiddleware],
      revalidateOnFocus: false,
    }
  );

  return swr;
};
