/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type { Socket, SocketInterface } from "../Socket";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint32",
        name: "chainSlug_",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "hasher_",
        type: "address",
      },
      {
        internalType: "address",
        name: "capacitorFactory_",
        type: "address",
      },
      {
        internalType: "address",
        name: "owner_",
        type: "address",
      },
      {
        internalType: "string",
        name: "version_",
        type: "string",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "ErrInSourceValidation",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidCapacitorAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidConnection",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidPacketId",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidProof",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidTokenAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidTransmitter",
    type: "error",
  },
  {
    inputs: [],
    name: "LowGasLimit",
    type: "error",
  },
  {
    inputs: [],
    name: "MessageAlreadyExecuted",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
    ],
    name: "NoPermit",
    type: "error",
  },
  {
    inputs: [],
    name: "NotExecutor",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyNominee",
    type: "error",
  },
  {
    inputs: [],
    name: "OnlyOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "PacketNotProposed",
    type: "error",
  },
  {
    inputs: [],
    name: "PlugDisconnected",
    type: "error",
  },
  {
    inputs: [],
    name: "SwitchboardExists",
    type: "error",
  },
  {
    inputs: [],
    name: "UnequalArrayLengths",
    type: "error",
  },
  {
    inputs: [],
    name: "VerificationFailed",
    type: "error",
  },
  {
    inputs: [],
    name: "ZeroAddress",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "capacitorFactory",
        type: "address",
      },
    ],
    name: "CapacitorFactorySet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "executionManager",
        type: "address",
      },
    ],
    name: "ExecutionManagerSet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "msgId",
        type: "bytes32",
      },
    ],
    name: "ExecutionSuccess",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "hasher",
        type: "address",
      },
    ],
    name: "HasherSet",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint32",
        name: "localChainSlug",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "localPlug",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "dstChainSlug",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "dstPlug",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "msgId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "minMsgGasLimit",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "executionParams",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "transmissionParams",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "payload",
        type: "bytes",
      },
      {
        components: [
          {
            internalType: "uint128",
            name: "transmissionFees",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "executionFee",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "switchboardFees",
            type: "uint128",
          },
        ],
        indexed: false,
        internalType: "struct ISocket.Fees",
        name: "fees",
        type: "tuple",
      },
    ],
    name: "MessageOutbound",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "claimer",
        type: "address",
      },
    ],
    name: "OwnerClaimed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "nominee",
        type: "address",
      },
    ],
    name: "OwnerNominated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "transmitter",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "packetId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "proposalCount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "root",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "switchboard",
        type: "address",
      },
    ],
    name: "PacketProposed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "plug",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "siblingChainSlug",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "siblingPlug",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "inboundSwitchboard",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "outboundSwitchboard",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "capacitor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "decapacitor",
        type: "address",
      },
    ],
    name: "PlugConnected",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "grantee",
        type: "address",
      },
    ],
    name: "RoleGranted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "revokee",
        type: "address",
      },
    ],
    name: "RoleRevoked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "transmitter",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "packetId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "batchSize",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "root",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "Sealed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "switchboard",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "siblingChainSlug",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "siblingSwitchboard",
        type: "address",
      },
    ],
    name: "SiblingSwitchboardUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "switchboard",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "siblingChainSlug",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "capacitor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "decapacitor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "maxPacketLength",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "capacitorType",
        type: "uint256",
      },
    ],
    name: "SwitchboardAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "transmitManager",
        type: "address",
      },
    ],
    name: "TransmitManagerSet",
    type: "event",
  },
  {
    inputs: [],
    name: "capacitorFactory__",
    outputs: [
      {
        internalType: "contract ICapacitorFactory",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "capacitorToSlug",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    name: "capacitors__",
    outputs: [
      {
        internalType: "contract ICapacitor",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "chainSlug",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "claimOwner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "siblingChainSlug_",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "siblingPlug_",
        type: "address",
      },
      {
        internalType: "address",
        name: "inboundSwitchboard_",
        type: "address",
      },
      {
        internalType: "address",
        name: "outboundSwitchboard_",
        type: "address",
      },
    ],
    name: "connect",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    name: "decapacitors__",
    outputs: [
      {
        internalType: "contract IDecapacitor",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bytes32",
            name: "packetId",
            type: "bytes32",
          },
          {
            internalType: "uint256",
            name: "proposalCount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "executionGasLimit",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "decapacitorProof",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "signature",
            type: "bytes",
          },
        ],
        internalType: "struct ISocket.ExecutionDetails",
        name: "executionDetails_",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "msgId",
            type: "bytes32",
          },
          {
            internalType: "uint256",
            name: "executionFee",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "minMsgGasLimit",
            type: "uint256",
          },
          {
            internalType: "bytes32",
            name: "executionParams",
            type: "bytes32",
          },
          {
            internalType: "bytes",
            name: "payload",
            type: "bytes",
          },
        ],
        internalType: "struct ISocket.MessageDetails",
        name: "messageDetails_",
        type: "tuple",
      },
    ],
    name: "execute",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "executionManager__",
    outputs: [
      {
        internalType: "contract IExecutionManager",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "minMsgGasLimit_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "payloadSize_",
        type: "uint256",
      },
      {
        internalType: "bytes32",
        name: "executionParams_",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "transmissionParams_",
        type: "bytes32",
      },
      {
        internalType: "uint32",
        name: "siblingChainSlug_",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "plug_",
        type: "address",
      },
    ],
    name: "getMinFees",
    outputs: [
      {
        internalType: "uint256",
        name: "totalFees",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "plugAddress_",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "siblingChainSlug_",
        type: "uint32",
      },
    ],
    name: "getPlugConfig",
    outputs: [
      {
        internalType: "address",
        name: "siblingPlug",
        type: "address",
      },
      {
        internalType: "address",
        name: "inboundSwitchboard__",
        type: "address",
      },
      {
        internalType: "address",
        name: "outboundSwitchboard__",
        type: "address",
      },
      {
        internalType: "address",
        name: "capacitor__",
        type: "address",
      },
      {
        internalType: "address",
        name: "decapacitor__",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "globalMessageCount",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32[]",
        name: "roleNames_",
        type: "bytes32[]",
      },
      {
        internalType: "uint32[]",
        name: "slugs_",
        type: "uint32[]",
      },
      {
        internalType: "address[]",
        name: "grantees_",
        type: "address[]",
      },
    ],
    name: "grantBatchRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role_",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "grantee_",
        type: "address",
      },
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "roleName_",
        type: "bytes32",
      },
      {
        internalType: "uint32",
        name: "chainSlug_",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "grantee_",
        type: "address",
      },
    ],
    name: "grantRoleWithSlug",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role_",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "address_",
        type: "address",
      },
    ],
    name: "hasRole",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "roleName_",
        type: "bytes32",
      },
      {
        internalType: "uint32",
        name: "chainSlug_",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "address_",
        type: "address",
      },
    ],
    name: "hasRoleWithSlug",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "hasher__",
    outputs: [
      {
        internalType: "contract IHasher",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "packetId_",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "proposalCount_",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "switchboard_",
        type: "address",
      },
    ],
    name: "isPacketProposed",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    name: "messageExecuted",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "nominee_",
        type: "address",
      },
    ],
    name: "nominateOwner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "nominee",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "siblingChainSlug_",
        type: "uint32",
      },
      {
        internalType: "uint256",
        name: "minMsgGasLimit_",
        type: "uint256",
      },
      {
        internalType: "bytes32",
        name: "executionParams_",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "transmissionParams_",
        type: "bytes32",
      },
      {
        internalType: "bytes",
        name: "payload_",
        type: "bytes",
      },
    ],
    name: "outbound",
    outputs: [
      {
        internalType: "bytes32",
        name: "msgId",
        type: "bytes32",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "packetIdRoots",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    name: "proposalCount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "packetId_",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "root_",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "switchboard_",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "signature_",
        type: "bytes",
      },
    ],
    name: "proposeForSwitchboard",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "siblingChainSlug_",
        type: "uint32",
      },
      {
        internalType: "uint256",
        name: "maxPacketLength_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "capacitorType_",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "siblingSwitchboard_",
        type: "address",
      },
    ],
    name: "registerSwitchboardForSibling",
    outputs: [
      {
        internalType: "address",
        name: "capacitor",
        type: "address",
      },
      {
        internalType: "address",
        name: "decapacitor",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token_",
        type: "address",
      },
      {
        internalType: "address",
        name: "rescueTo_",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount_",
        type: "uint256",
      },
    ],
    name: "rescueFunds",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32[]",
        name: "roleNames_",
        type: "bytes32[]",
      },
      {
        internalType: "uint32[]",
        name: "slugs_",
        type: "uint32[]",
      },
      {
        internalType: "address[]",
        name: "grantees_",
        type: "address[]",
      },
    ],
    name: "revokeBatchRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role_",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "revokee_",
        type: "address",
      },
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "roleName_",
        type: "bytes32",
      },
      {
        internalType: "uint32",
        name: "chainSlug_",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "grantee_",
        type: "address",
      },
    ],
    name: "revokeRoleWithSlug",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "rootProposedAt",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "batchSize_",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "capacitorAddress_",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "signature_",
        type: "bytes",
      },
    ],
    name: "seal",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "capacitorFactory_",
        type: "address",
      },
    ],
    name: "setCapacitorFactory",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "executionManager_",
        type: "address",
      },
    ],
    name: "setExecutionManager",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "hasher_",
        type: "address",
      },
    ],
    name: "setHasher",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "transmitManager_",
        type: "address",
      },
    ],
    name: "setTransmitManager",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "transmitManager__",
    outputs: [
      {
        internalType: "contract ITransmitManager",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "siblingChainSlug_",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "siblingSwitchboard_",
        type: "address",
      },
    ],
    name: "useSiblingSwitchboard",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "version",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

export class Socket__factory {
  static readonly abi = _abi;
  static createInterface(): SocketInterface {
    return new utils.Interface(_abi) as SocketInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): Socket {
    return new Contract(address, _abi, signerOrProvider) as Socket;
  }
}
