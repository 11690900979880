import { PropsWithChildren } from "react";
import { CSSProperties } from "styled-components";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import { getOptionsTypeFromSymbol } from "../../../utils/instruments";
import { BaseModal } from "../../BaseModal";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import MarketHeader from "./MarketHeader";
import { TitleContainer } from "./style";

type IBaseDetailsModalProps = PropsWithChildren & {
  show: boolean;
  onHide?: () => void;
  style?: CSSProperties;

  instrument?: {
    instrument_name: string;
    expiry?: string;
    strike?: string;

    markPrice: string;
    markIncreasePercent?: number;
  };
}

function BaseDetailsModal({
  show,
  onHide,
  children,
  instrument,
  style,
}: IBaseDetailsModalProps) {
  return (
    <BaseModal
      hideCloseButton
      show={show}
      onHide={onHide}
      style={{
        ...style,
        minWidth: 311
      }}
    >
      {
        instrument && (
          <TitleContainer>
            <MarketHeader
              instrumentName={instrument.instrument_name}
              optionType={getOptionsTypeFromSymbol(instrument.instrument_name)}
              expiry={instrument.expiry}
              strike={instrument.strike}
              price={instrument.markPrice}
              priceIncreasePercent={instrument.markIncreasePercent}
            />
            <Button
              style={{
                height: "56px",
                width: "56px",
              }}
              buttonTheme={ButtonThemeEnum.NEUTRAL2}
              onClick={onHide}
            >
              <Close />
            </Button>
          </TitleContainer>
        )
      }
      {children}
    </BaseModal>
  );
}

export default BaseDetailsModal;
