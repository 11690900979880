import Bg from "../../../assets/videos/pending.mp4";

function BackgroundVideoFX() {
  return (
    <video
      autoPlay
      muted
      loop
      webkit-playsinline="true"
      playsInline
      controls={false}
    >
      <source src={Bg} type="video/mp4" />
    </video>
  );
}

export default BackgroundVideoFX;
