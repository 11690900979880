import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { CampaignDetails, CampaignEnum } from "../CampaignsPage";
import { PageEndpointEnum } from "../../enums/endpoint";

export function CampaignReferralRedirectPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [, setReferralCode] = useState<string>();

  const regex = /\/c\/([^/]+)\/([^/]+)$/;
  const matches = location.pathname.match(regex);

  useEffect(() => {
    // Regex pattern to match "/c/[campaign-key]/[username]"

    if (matches && matches.length > 2) {
      const campaignKey = matches[1];
      const username = matches[2];

      // Check if the campaign key is valid
      if (CampaignDetails[campaignKey as CampaignEnum]) {
        // Save the referral code in localStorage
        window.localStorage.setItem(
          LocalStorageKeyEnum.REFERRAL_CODE,
          username
        );
        window.localStorage.setItem(
          LocalStorageKeyEnum.CAMPAIGN_NAME,
          campaignKey
        );
        setReferralCode(username);

        // Navigate to the campaign page with the valid campaign key
        navigate(`${PageEndpointEnum.CAMPAIGNS}/${campaignKey}`);
      }
    }
  }, [location.pathname, matches, navigate]);
  return <div />;
}
