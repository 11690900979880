import { isProduction, isStaging } from "../../utils/env";

export const LINKS = {
  home: "https://aevo.xyz",
  docs: "https://docs.aevo.xyz",
  apidocs: "https://api-docs.aevo.xyz",
  twitter: "https://twitter.com/aevoxyz",
  discord: "https://discord.com/invite/aevo",
  github: "https://github.com/aevoxyz",
  blog: "https://mirror.xyz/aevo.eth",
  referrals: "https://app.aevo.xyz/referrals",
  dashboard:
    "https://aevo.metabaseapp.com/public/dashboard/81ee5b91-fbd2-41a5-90dd-d22771425f26",
  mediakit: "/assets/Aevo-MediaKit.zip",
  uptimeRobot: isProduction()
    ? "https://status.aevo.xyz/"
    : "https://status-testnet.aevo.xyz/",
  termsOfService: "https://discord.com/invite/aevo",
  openSea: "https://opensea.io/collection/exchange-pass/",
  otc: "https://otc.aevo.xyz",
  // eslint-disable-next-line no-nested-ternary
  app: isProduction()
    ? "https://app.aevo.xyz"
    : isStaging()
    ? "https://testnet.aevo.xyz"
    : "localhost:3000",
  ribbonApp: "https://app.ribbon.finance",
  telegram: "https://t.me/aevoupdates",
  terms:
    process.env.REACT_APP_LOCAL_DEV_ENV === "true"
      ? "http://localhost:3000/terms"
      : "https://app.aevo.xyz/terms",
} as const;

export const REWARD_LINKS = {
  week1: "https://aevo.mirror.xyz/5gksPetAa3x0FWLii4tcr_Aav0LHpZSq7fTJkAOtek0",
  week2: "https://aevo.mirror.xyz/Duu-Tz7ptRaQ6u1WY1NIXaSVkL6V9i7iAxKaEAAR3mA",
  week3: "https://aevo.mirror.xyz/eZUDjkk5RUAYjxdig-QTNDvY1WXo5GZJLEB_uztAY7M",
  week4: "https://aevo.mirror.xyz/pVEV6L-SlugqUazfnUEG-a7YHlMxsCwq6Gm65DjdejI",
  referralClaims:
    "https://docs.aevo.xyz/trading-and-staking-incentives/referrals",
};

export const getRewardsLinkForLabel = (label?: string) => {
  if (
    label?.toLowerCase() === "taker leaderboard rewards #1" ||
    label?.toLowerCase() === "taker incentive rewards #1"
  ) {
    return REWARD_LINKS.week2;
  }
  if (
    label?.toLowerCase() === "taker leaderboard rewards #2" ||
    label?.toLowerCase() === "taker incentive rewards #2"
  ) {
    return REWARD_LINKS.week3;
  }
  if (
    label?.toLowerCase() === "taker leaderboard rewards #3" ||
    label?.toLowerCase() === "taker incentive rewards #3"
  ) {
    return REWARD_LINKS.week3;
  }
  if (label?.toLowerCase() === "claim_referral_rewards") {
    return REWARD_LINKS.referralClaims;
  }
  return undefined;
};
