import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSortBy, useTable } from "react-table";
import { ContentContainer, SegmentedControlWrapper } from "../style";
import SegmentedControl from "../../shared/SegmentedControl";
import { LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import {
  IPartialTPSLModalContentProps,
  IPartialTPSLModalTableContentProps,
  PartialTPSLTabEnum,
} from "./PartialTPSLModalContent.types";
import { ITableColumn } from "../../../interfaces/Table/TableColumn";
import { DefaultHeaderForColumn } from "../../shared/Table/DefaultHeaderForColumn";
import { DefaultCellForColumn } from "../../shared/Table/DefaultCellForColumn";
import { PositionsTableWrapper, PositionTableRow } from "../../Positions/style";
import { TableHeaderCell } from "../../shared/Table/style";
import { getColumns, getTabTitle } from "./PartialTPSLModalContent.helpers";

function PartialTPSLModalContent({
  currentTab,
  onSelectTab,
  onCancelOrder,
  positionTotalAmount,
  side,
  loadingOrderId,
  data: partialPositionTableData,
}: IPartialTPSLModalContentProps) {
  const { t: tPositions } = useTranslation("app", { keyPrefix: "Positions" });
  const { t: tTooltip } = useTranslation("tooltips");
  const totalPositionAmount = positionTotalAmount
    ? parseFloat(positionTotalAmount)
    : 0;

  const columns: ITableColumn<IPartialTPSLModalTableContentProps>[] = useMemo(
    () =>
      getColumns(
        tPositions,
        tTooltip,
        onCancelOrder,
        loadingOrderId,
        totalPositionAmount,
        side
      ),
    [
      tPositions,
      tTooltip,
      onCancelOrder,
      loadingOrderId,
      totalPositionAmount,
      side,
    ]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<IPartialTPSLModalTableContentProps>(
      {
        columns,
        data:
          partialPositionTableData.sort(
            (a, b) => parseFloat(b.amount) - parseFloat(a.amount)
          ) || [],
        defaultColumn: {
          Header: DefaultHeaderForColumn,
          Cell: DefaultCellForColumn,
        },
        autoResetSortBy: false,
      } as any,
      useSortBy
    );

  return (
    <>
      <SegmentedControlWrapper>
        <SegmentedControl
          segments={Object.keys(PartialTPSLTabEnum).map((k) => ({
            display: getTabTitle(
              PartialTPSLTabEnum[k as keyof typeof PartialTPSLTabEnum],
              tPositions
            ),
            value: PartialTPSLTabEnum[
              k as keyof typeof PartialTPSLTabEnum
            ] as PartialTPSLTabEnum,
            textColor:
              PartialTPSLTabEnum[k as keyof typeof PartialTPSLTabEnum] ===
              currentTab
                ? TEXT_COLORS.one
                : TEXT_COLORS.three,
          }))}
          value={currentTab}
          onSelect={onSelectTab}
          config={{
            widthType: "fullWidth",
            backgroundColor: LAYER_COLORS.two,
            activeBackgroundColor: LAYER_COLORS.three,
            borderRadius: "10px",
            button: {
              fontSize: FONT_SIZE.one,
            },
          }}
        />
      </SegmentedControlWrapper>
      <ContentContainer key={String(currentTab)}>
        <PositionsTableWrapper>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                // eslint-disable-next-line react/jsx-key
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    // eslint-disable-next-line react/jsx-key
                    <TableHeaderCell
                      // We apply the sort properties to each column
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                    </TableHeaderCell>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <PositionTableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </PositionTableRow>
                  );
                })
              ) : (
                <PositionTableRow>
                  <td colSpan={columns.length} style={{ textAlign: "center" }}>
                    {tPositions("no_partial_position_data")}
                  </td>
                </PositionTableRow>
              )}
            </tbody>
          </table>
        </PositionsTableWrapper>
      </ContentContainer>
    </>
  );
}

export default PartialTPSLModalContent;
