import { isMobile } from "react-device-detect";
import { http, createConfig } from "wagmi";
import { type Chain } from "viem";
import {
  mainnet,
  sepolia,
  arbitrum,
  arbitrumSepolia,
  optimism,
  optimismSepolia,
  base,
  linea,
} from "wagmi/chains";
import {
  injected,
  metaMask,
  coinbaseWallet,
  walletConnect,
} from "wagmi/connectors";
import { ChainIdEnum } from "../enums/chain";

type ChainIdToBlockExplorerURL = {
  [chainId in ChainIdEnum]: string;
};

export const isSocketChain = (chain: ChainIdEnum) =>
  chain !== ChainIdEnum.ETH_MAINNET && chain !== ChainIdEnum.SEPOLIA_TESTNET;

export const CHAIN_EXPLORER_URLS: ChainIdToBlockExplorerURL = {
  [ChainIdEnum.NONE]: "",
  [ChainIdEnum.ETH_MAINNET]: "https://etherscan.io",
  [ChainIdEnum.SEPOLIA_TESTNET]: "https://sepolia.etherscan.io",
  [ChainIdEnum.LOCAL_TESTNET]: "",
  [ChainIdEnum.OPTIMISM]: "https://optimistic.etherscan.io",
  [ChainIdEnum.OPTIMISM_TESTNET]: "https://sepolia-optimism.etherscan.io",
  [ChainIdEnum.ARBITRUM]: "https://arbiscan.io",
  [ChainIdEnum.ARBITRUM_TESTNET]: "https://sepolia.arbiscan.io",
  [ChainIdEnum.ARBITRUM_LOCAL]: "",
  [ChainIdEnum.LINEA_MAINNET]: "https://lineascan.build",
  [ChainIdEnum.BASE]: "https://basescan.org",
};

const aevoL2ExplorerURL = "https://explorer.aevo.xyz";
const aevoL2TestnetExplorerURL = "https://explorer-testnet.aevo.xyz";

export const L2_CHAIN_EXPLORER_URLS: ChainIdToBlockExplorerURL = {
  [ChainIdEnum.NONE]: "",
  [ChainIdEnum.ETH_MAINNET]: aevoL2ExplorerURL,
  [ChainIdEnum.SEPOLIA_TESTNET]: aevoL2TestnetExplorerURL,
  [ChainIdEnum.LOCAL_TESTNET]: "",

  // Same
  [ChainIdEnum.OPTIMISM]: aevoL2ExplorerURL,
  [ChainIdEnum.OPTIMISM_TESTNET]: aevoL2TestnetExplorerURL,
  [ChainIdEnum.ARBITRUM]: aevoL2ExplorerURL,
  [ChainIdEnum.ARBITRUM_TESTNET]: aevoL2TestnetExplorerURL,
  [ChainIdEnum.ARBITRUM_LOCAL]: "",
  [ChainIdEnum.LINEA_MAINNET]: "",
  [ChainIdEnum.BASE]: aevoL2ExplorerURL,
};

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || "";

export const WC_PARAMS = {
  projectId,
  metadata: {
    name: "Aevo",
    description: "Aevo Interface",
    url: "https://app.aevo.xyz",
    icons: ["https://app.aevo.xyz/assets/logo/favicon-ios.png"],
  },
  qrModalOptions: {
    themeVariables: {
      "--wcm-font-family": '"Inter custom", sans-serif',
    },
  },
};

export const arbitrumLocal = {
  id: 464646,
  name: "Arbitrum Local",
  nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
  rpcUrls: {
    default: { http: ["http://127.0.0.1:8555"] },
  },
  blockExplorers: {
    default: {
      name: "Arbitrum Local",
      url: "http://127.0.0.1/",
    },
  },
} as const satisfies Chain;

export const localTestnet = {
  id: 31337,
  name: "Local Testnet",
  nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
  rpcUrls: {
    default: { http: ["http://127.0.0.1:8540"] },
  },
  blockExplorers: {
    default: {
      name: "",
      url: "http://127.0.0.1",
    },
  },
} as const satisfies Chain;

// @ts-ignore
export const config = createConfig({
  chains: [
    mainnet,
    sepolia,
    arbitrum,
    arbitrumSepolia,
    optimism,
    optimismSepolia,
    base,
    linea,
    arbitrumLocal,
    localTestnet,
  ],
  connectors: [
    injected(),
    walletConnect(WC_PARAMS),
    metaMask({
      extensionOnly: true,
    }),
    // Only use smart wallet on desktop
    ...(isMobile
      ? [
          coinbaseWallet({
            appName: "aevo",
            version: "3",
          }),
        ]
      : [
          coinbaseWallet({
            appName: "aevo",
            preference: "all",
          }),
        ]),
  ],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
    [arbitrum.id]: http(),
    [arbitrumSepolia.id]: http(),
    [optimism.id]: http(),
    [optimismSepolia.id]: http(),
    [base.id]: http(),
    [linea.id]: http(),
    [arbitrumLocal.id]: http(),
    [localTestnet.id]: http(),
  },
});
