import styled from "styled-components";
import { LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { Button } from "../../Buttons/styles";

export const OrderbookTradeHistoryArea = styled.div`
  transition: height 0.2s ease-in-out;
  grid-column: 2 / span 1;
  border-radius: 12px;
  overflow: hidden;
`;

export const ChartArea = styled.div`
  grid-column: 1 / span 1;
  display: flex;
  flex-direction: column;
  transition: height 0.2s ease-in-out;
  background: ${LAYER_COLORS.one};
  border-radius: 12px;
  overflow: hidden;
`;

export const ChartsContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ChartContainer = styled.div<{ hidden: boolean }>`
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  pointer-events: ${({ hidden }) => (hidden ? "none" : "auto")};
`;

export const Header = styled.div``;

export const ChartTypeToggle = styled.button<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? TEXT_COLORS.one : TEXT_COLORS.three)};
  font-size: ${FONT_SIZE.two};
  transition: 0.2s ease-in-out;
  margin-right: ${SPACING.three}px;

  &:hover {
    color: ${TEXT_COLORS.one};
  }
`;

export const ChartTabWrapper = styled.div`
  height: ${COMPONENTS.toggleTabs}px;
  width: fit-content;
  margin: ${SPACING.two}px;
  display: flex;
`;

export const ChartTab = styled.div`
  padding: ${SPACING.two}px ${SPACING.four}px;
`;

export const MarketDetailsButton = styled(Button)`
  ${FONT_CSS.body.five}
  color: ${TEXT_COLORS.two};
  padding: ${SPACING.two}px;
  min-height: 34px;
  margin-left: ${SPACING.two}px;
  border-radius: 10px;

  svg {
    margin-left: ${SPACING.one}px;
  }
`;
export const MarketDetailsHeader = styled.div`
  img {
    margin-right: ${SPACING.two}px;
  }
`;

export const MarketDetailsDescription = styled.div`
  ${FONT_CSS.body.three}
  color: ${TEXT_COLORS.two};
`;

export const MarketDetailsInfo = styled.div`
  margin-top: ${SPACING.three}px;

  > * {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
`;
