import { useEffect } from "react";

export enum KeydownHandlerKeyEnum {
  ESC = "Escape",
}

const useKeydownHandler = (
  key: KeydownHandlerKeyEnum | string,
  handler?: () => void
) => {
  useEffect(() => {
    if (!handler) {
      return () => {};
    }

    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === key) {
        handler();
      }
    };

    document.addEventListener("keydown", keyDownHandler, { passive: true });

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [handler, key]);
};

export default useKeydownHandler;
