import { useContext, useCallback, useMemo } from "react";
import { AxiosError } from "axios";
import useSWR from "swr";
import { ethers } from "ethers";
import { AuthContext } from "../../../contexts/AuthContext";
import { authApi } from "../../../services/api/apiFetcher";
import {
  GenericErrorResponse,
  GetQuotes200Response,
  PostQuotesPayload,
  PostQuotesPayloadLegsInner,
} from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";
import { useClock } from "../clock/useClock";
import { getDomainData } from "../../../utils/signing";
import { supportedChainId } from "../../../utils/wallet/connectors";
import { pollingInterval } from "../../../services/api/pollingInterval";

const quoteType = [
  { name: "block_id", type: "bytes32" },
  { name: "account", type: "address" },
  { name: "is_buy", type: "bool" },
  { name: "amount", type: "uint256" },
  { name: "salt", type: "uint256" },
  { name: "timestamp", type: "uint256" },
];

// Payload needed for acceptQuote
export interface ITakerQuotePayload {
  block_id: string;
  amount: string;
  is_buy: boolean;
  limit_price?: string;
  legs?: Array<PostQuotesPayloadLegsInner>;
}

// Final payload to post quote
export interface ITakerQuoteMessage extends ITakerQuotePayload {
  account: string;
  salt: string;
  timestamp: string;
  signature?: string;
}

export const useQuotes = (fetchQuotes: boolean = true) => {
  const { apiKey, apiSecret, signingKey, account } = useContext(AuthContext);
  const { getTimestamp } = useClock();

  const fetcher = useMemo(
    () => authApi(apiKey, apiSecret),
    [apiKey, apiSecret]
  );

  const swr = useSWR<GetQuotes200Response, AxiosError>(
    apiKey && fetchQuotes ? [APIEndpointEnum.QUOTES, apiKey] : undefined,
    async () => (await (await fetcher.getQuotes())()).data,
    {
      use: [useLoggerMiddleware],
      refreshInterval: pollingInterval[APIEndpointEnum.QUOTES],
    }
  );

  const acceptQuote = useCallback(
    async (payload: ITakerQuotePayload) => {
      if (!signingKey) {
        return undefined;
      }

      const salt = String(Math.floor(Math.random() * 1000000));
      const timestamp = String(getTimestamp());

      const quoteMessage: ITakerQuoteMessage = {
        ...payload,
        salt,
        timestamp,
        account: account as string,
      };

      const signer = new ethers.Wallet(signingKey);
      const domainData = getDomainData(supportedChainId);

      // eslint-disable-next-line no-underscore-dangle
      const quoteSignature = await signer._signTypedData(
        domainData,
        { Quote: quoteType },
        quoteMessage
      );

      const request: PostQuotesPayload = {
        ...quoteMessage,
        signature: quoteSignature,
        system_type: "WEB",
      };

      try {
        const response = (await (await fetcher.postQuotes(request))()).data;

        if (response) {
          await swr.mutate();
        }

        return response;
      } catch (error) {
        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        throw Error(
          genericResponseAxiosError.response?.data?.error ||
            "Error Accepting Quote"
        );
      }
    },
    [account, fetcher, getTimestamp, signingKey, swr]
  );

  const deleteQuote = useCallback(
    async (quoteId: string) => {
      try {
        const res = (await (await fetcher.deleteQuotesQuoteId(quoteId))()).data;
        swr.mutate();
        return res;
      } catch (error) {
        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        throw Error(
          genericResponseAxiosError.response?.data?.error ||
            "Error Deleting Quote"
        );
      }
    },
    [fetcher, swr]
  );

  return {
    ...swr,
    acceptQuote,
    deleteQuote,
  };
};
