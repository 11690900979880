import { AxiosError } from "axios";
import useSWR from "swr";
import { GetFundingHistory200Response } from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { publicApi } from "../../../services/api/apiFetcher";
import { TimePeriodEnum } from "../../../interfaces/TimePeriod";
import { getStartTimeSeconds, secondsToNanos } from "../../../utils/date";

export const useFundingHistory = (
  instrumentName?: string,
  limit?: number,
  disableQuery?: boolean,
  period: TimePeriodEnum = TimePeriodEnum.WEEKLY
) => {
  const fetcher = publicApi();

  return useSWR<GetFundingHistory200Response | undefined, AxiosError>(
    disableQuery
      ? undefined
      : [APIEndpointEnum.FUNDING_HISTORY, instrumentName, period, limit],
    async () => {
      if (!disableQuery) {
        const start = secondsToNanos(getStartTimeSeconds(period));

        return (
          await (
            await fetcher.getFundingHistory(
              instrumentName,
              start,
              undefined,
              limit
            )
          )()
        ).data;
      }

      return undefined;
    }
  );
};
