import styled, { css } from "styled-components";
import { BORDER_COLORS } from "../../../constants/design/colors";

export const DividerWrapper = styled.div<{
  direction: "vertical" | "horizontal";
  size: string;
}>`
  ${({ direction, size }) => {
    if (direction === "vertical") {
      return css`
        min-width: 1px;
        width: 1px;
        height: ${size};
      `;
    }
    return css`
      width: ${size};
      height: 1px;
      min-height: 1px;
    `;
  }}

  background-color: ${BORDER_COLORS.one};
`;
