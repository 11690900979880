import * as Sentry from "@sentry/react";
import currency from "currency.js";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow-down.svg";
import { SideResponse } from "../../../codegen-api";
import { COMPONENTS } from "../../../constants/design/spacing";
import { INDEX_PRICE_COLLAR_PERPS } from "../../../constants/precision/form";
import { useOrder } from "../../../hooks/api/order/useOrder";
import usePrevious from "../../../hooks/usePrevious";
import useTradesWSS from "../../../hooks/wss/useTradesWSS";
import { IPriceSize } from "../../../interfaces/Orderbook";
import { groupByTicketSize } from "../../../utils/orderbook";
import TradeOrderbook from "../../TicketDetails/TradeOrderBook";
import { MidRow } from "../../TicketDetails/TradeOrderBook/style";
import { OrderbookFilterEnum } from "./shared";
import { ScrollableContent, ScrollableWrapper } from "./style";
import { useOrderbookWSS } from "../../../hooks/wss/useOrderbookWSS";

interface ITradeSectionProps {
  onOrderbookRowClick: (
    price: string,
    amount: string,
    side: SideResponse
  ) => void;
  instrumentName?: string;
  sizeDecimals: number;
  priceDecimals: number;
  filter?: OrderbookFilterEnum;
  showValueInUSD: boolean;
  orderbookTickSize?: number;
  isTradeForm?: boolean;
}

let stopCaptureMessage = false;

function OrderbookSection({
  onOrderbookRowClick,
  instrumentName,
  sizeDecimals,
  priceDecimals,
  filter,
  showValueInUSD,
  orderbookTickSize,
  isTradeForm,
}: ITradeSectionProps) {
  const orderbookSectionContainer = useRef<HTMLDivElement>(null);
  const midRowRef = useRef<HTMLDivElement>(null);

  const { data: orderData } = useOrder();

  const orderbook = useOrderbookWSS(instrumentName);

  const { data: trades } = useTradesWSS(instrumentName, undefined, true, 1);

  const lastTradedPrice = useMemo(
    () => Number(trades?.[0]?.price || 0),
    [trades]
  );
  const prevlastTradedPrice = usePrevious(lastTradedPrice);

  const [isPositiveLastTradedChange, setIsPositiveMarkChange] =
    useState<boolean>();

  const containerHeight = orderbookSectionContainer.current?.clientHeight || 0;
  const rowHeight = isTradeForm
    ? COMPONENTS.perpsTableRowTradeForm
    : COMPONENTS.perpsTableRowSmall;
  const obHeightWithoutHeaders = containerHeight - rowHeight * 2;
  const maxRows =
    !filter && orderbookSectionContainer.current?.clientHeight
      ? Math.max(Math.floor(obHeightWithoutHeaders / 2 / rowHeight), 0)
      : undefined;
  const newRowHeight = maxRows
    ? obHeightWithoutHeaders / (maxRows * 2)
    : rowHeight;

  // Parse levels
  let bids: IPriceSize[] = (orderbook?.bids as IPriceSize[]) || [];
  let asks: IPriceSize[] = (orderbook?.asks as IPriceSize[]) || [];

  // Group by tick size
  bids = orderbookTickSize ? groupByTicketSize(bids, orderbookTickSize) : bids;
  asks = orderbookTickSize
    ? groupByTicketSize(asks, orderbookTickSize, true)
    : asks;

  // Slice max rows
  // If theres a max rows, make sure both side is the same after grouping
  if (maxRows) {
    bids = bids.slice(0, maxRows);
    asks = asks.slice(0, maxRows);

    const diff = bids.length - asks.length;
    if (diff > 0) {
      // Positive diff means bids > asks
      asks = [...asks, ...Array(Math.abs(diff)).fill(["0", "0"])];
    } else if (diff < 0) {
      bids = [...bids, ...Array(Math.abs(diff)).fill(["0", "0"])];
    }
  }

  // Best bid/asks should NEVER overlap.
  // Remove all bids thats > best ask
  // console.log(bids, asks, Number(bids[0]?.[0] || 0),Number(asks[0]?.[0] || 0) )
  if (
    Number(bids[0]?.[0] || 0) > Number(asks[0]?.[0] || 0) &&
    !stopCaptureMessage
  ) {
    bids = bids.filter(
      ([price]) => Number(price || 0) > Number(asks[0]?.[0] || 0)
    );
    console.log("BEST BID > BEST ASK", orderbook);
    Sentry.captureMessage("BEST BID > BEST ASK", {
      extra: {
        orderbook,
      },
    });
    stopCaptureMessage = true;
  }

  // Only update mark changes when its different
  useEffect(() => {
    const change = prevlastTradedPrice
      ? lastTradedPrice - prevlastTradedPrice
      : undefined;
    setIsPositiveMarkChange((prev) => {
      if (!change || change === 0) {
        return prev;
      }
      return change > 0;
    });
  }, [lastTradedPrice, prevlastTradedPrice]);

  // When instrument or filter changed, scroll mark price into view
  useEffect(() => {
    if (filter === OrderbookFilterEnum.BIDS) {
      orderbookSectionContainer.current?.scrollTo({ top: 0 });
    } else if (filter === OrderbookFilterEnum.OFFERS) {
      orderbookSectionContainer.current?.scrollTo({ top: 99999 });
    }
  }, [instrumentName, filter]);

  return (
    <ScrollableWrapper>
      <ScrollableContent
        ref={orderbookSectionContainer}
        style={{ overflow: filter ? "auto" : "hidden" }}
      >
        <TradeOrderbook
          indexPriceCollar={INDEX_PRICE_COLLAR_PERPS}
          headerRowHeight={`${rowHeight}px`}
          rowHeight={`${newRowHeight}px`}
          showIV={false}
          filter={filter}
          accountOrders={orderData || []}
          instrumentId={instrumentName}
          orderbookTicksize={orderbookTickSize}
          bids={bids}
          asks={asks}
          sizeDecimals={sizeDecimals}
          priceDecimals={priceDecimals}
          onRowClick={onOrderbookRowClick}
          showValueInUSD={showValueInUSD}
          isTradeForm={isTradeForm}
          middleRow={
            <MidRow
              ref={midRowRef}
              isPositiveChange={
                lastTradedPrice ? isPositiveLastTradedChange : undefined
              }
              isTradeForm={isTradeForm}
            >
              {lastTradedPrice
                ? currency(lastTradedPrice, {
                    precision: priceDecimals,
                  }).format()
                : "---"}
              {lastTradedPrice ? <ArrowDown /> : null}
            </MidRow>
          }
        />
      </ScrollableContent>
    </ScrollableWrapper>
  );
}

export default OrderbookSection;
