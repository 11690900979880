import { TableOptions } from "react-table";
import { ITableColumn } from "../../../interfaces/Table/TableColumn";
import { startCase } from "../../../utils/strings";
import SortIcon from "../SortIcon";
import TooltipExplanation from "../Tooltip";
import { Align } from "./style";

interface IDefaultHeaderForColumnProps extends TableOptions<{}> {
  column: ITableColumn<any>;
}

export function DefaultHeaderForColumn({
  column,
}: IDefaultHeaderForColumnProps) {
  const {
    id,
    isSorted,
    isSortedDesc,
    align,
    title,
    tooltip,
    canSort,
  } = column;

  const formattedTitle = startCase(title || id || "");

  if (tooltip) {
    return (
      <TooltipExplanation
        title={formattedTitle}
        explanation={tooltip}
        renderContent={({ ref, ...triggerHandler }) => (
          <Align ref={ref} align={align || "left"} {...triggerHandler}>
            {formattedTitle}
            {
              canSort
              && <SortIcon
                isSortedDesc={isSortedDesc}
                isSorted={isSorted}
              />
            }
          </Align>
        )}
      />
    );
  }

  return (
    <Align align={align || "left"}>
      {formattedTitle}
      {
        canSort
        && <SortIcon
          isSortedDesc={isSortedDesc}
          isSorted={isSorted}
        />
      }
    </Align>
  );
}
