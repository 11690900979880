export enum WebsocketChannelEnum {
  ORDER_BOOK = "orderbook-100ms",
  ORDER_BOOK_THROTTLED = "orderbook-500ms",
  ORDERS = "orders",
  TICKER = "ticker-500ms",
  MARK_PRICE = "markprice",
  RFQ = "rfqs",
  TRADES = "trades",
  INDEX = "index",
}

export interface IWebsocketAuth {
  timestamp: string;
  signature: string;
  key: string;
}

export type IWebsocketRequestOp = "subscribe" | "unsubscribe" | "ping" | "auth";

export interface IWebsocketRequest {
  op: IWebsocketRequestOp;
  data?: any;
  auth?: IWebsocketAuth;
  id?: number;
}

export interface IWebsocketResponse {
  id?: number;
  channel?: string;
  data?: any;
  error?: string;
}

export interface IWebsocketRateLimitedResponse extends IWebsocketResponse {
  data: {
    // Nanoseconds
    retry_after: string;
  };
}

export type ISubscribeIDMap = {
  [id: number]: string;
};
