import styled from "styled-components";
import { LAYER_COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { foregroundComponent } from "../../style";

export const ComponentTitle = styled.div<{ padding?: string }>`
  ${FONT_CSS.header.five}
  display: flex;
  align-items: center;
  padding: ${({ padding }) => padding ?? 0};
  max-height: 42px;
  margin-bottom: ${SPACING.three}px;
  color: ${TEXT_COLORS.one};
`;

export const ComponentSubtitle = styled(ComponentTitle)`
  ${FONT_CSS.header.seven};
`;

export const Content = styled.div`
  ${FONT_CSS.body.five}
  padding: ${SPACING.three}px;
  color: ${TEXT_COLORS.three};
`;

export const ContainerizedContent = styled.div`
  ${foregroundComponent}
  padding: ${SPACING.three}px;
  font-size: ${FONT_SIZE.oneHalf};
  margin: 0;
  height: ${COMPONENTS.overview.section}px;
  background: ${LAYER_COLORS.two};
`;
