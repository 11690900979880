import styled from "styled-components";
import { FONT_CSS } from "../../constants/design/fontSize";
import { SPACING } from "../../constants/design/spacing";
import { COLORS } from "../../constants/design/colors";

export const ErrorText = styled.div`
  ${FONT_CSS.label.three}
  margin-top: ${SPACING.one}px;
  color: ${COLORS.negative.one};
`;
