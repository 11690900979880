import { SideResponse } from "../../../../codegen-api";
import { COLORS } from "../../../../constants/design/colors";
import { RowWithSizeIndicator } from "../../../shared/Table/style";
import { Cell } from "../../style";

interface IBidAskSizeCellProps {
  totalVolume: number;
  volume: number;
  orderVolume: number;
  needsAnimation: boolean;
  side: SideResponse;
  align?: "left" | "center" | "right";
}

function BidAskSizeCell({
  totalVolume,
  volume,
  orderVolume,
  needsAnimation,
  side,
  align,
}: IBidAskSizeCellProps) {
  const bestVolumePercentage = (volume / totalVolume) * 100;
  const yourVolumePercentage = Math.min((orderVolume / volume), 1) * bestVolumePercentage;

  return (
    <RowWithSizeIndicator
      fillColor={side === SideResponse.Buy ? COLORS.positive.four : COLORS.negative.four}
      fillPercent={bestVolumePercentage}
      fillTo={side === SideResponse.Buy ? "right" : "left"}
      needsAnimation={needsAnimation}
    >
      {
        !!orderVolume && (
          <RowWithSizeIndicator
            fillColor={side === SideResponse.Buy ? COLORS.positive.two : COLORS.negative.two}
            fillPercent={yourVolumePercentage}
            fillTo={side === SideResponse.Buy ? "right" : "left"}
            needsAnimation={needsAnimation}
            // onRowHover={(e) => setHovering(e)}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              bottom: 0,
              width: "100%",
              zIndex: -1,
            }}
          />
        )
      }
      <Cell align={align}>
        {volume > 0 && volume.toFixed(2)}
      </Cell>
    </RowWithSizeIndicator>
  );
}

export default BidAskSizeCell;
