import currency from "currency.js";
import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { OptionTypeResponse, SideResponse } from "../../codegen-api";
import { MarketContext } from "../../contexts/MarketContext";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import SegmentedControl from "../shared/SegmentedControl";

import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { WIDTHS } from "../../constants/design/spacing";
import { useOrder } from "../../hooks/api/order/useOrder";
import useScreenSize from "../../hooks/screenSize/useScreenSize";
import useScroll from "../../hooks/useScroll";
import { useTickersStore } from "../../hooks/wss/public/store/useTickersStore";
import useIndexWSS from "../../hooks/wss/useIndexWSS";
import usePositionsWSS from "../../hooks/wss/usePositionsWSS";
import {
  getAssetFromSymbol,
  getContractPriceStep,
  getExpiryFromUnix,
} from "../../utils/instruments";
import { Divider } from "../shared/Divider";
import CurrentSpotSection from "./CurrentSpotSection";
import DateSelection from "./DateSelection";
import {
  IOptionVolume,
  ITableContentRowWithCallPut,
  ITickerWithInstrumentId,
  OptionsChainTableMode,
  TableContent,
  TableHeader,
} from "./OptionsTable";
import {
  OptionsChainContainer,
  OptionsTypeDateSelectionWrapper,
  OptionsTypeSelectWrapper,
  ScrollableContainer,
  ScrollableSection,
  TableContainer,
} from "./style";

interface IOptionsChainProps {
  onTrade: (
    orderType: OptionTypeResponse,
    expiry: number,
    strike: string
  ) => void;
  activeInstrumentId: string | undefined;
}

// Default placeholder ticker value used while loading
const defaultTickerValue: ITickerWithInstrumentId = {
  ask: {
    price: "",
    volume: "",
    iv: "",
    orderVolume: 0,
  },
  bid: {
    price: "",
    volume: "",
    iv: "",
    orderVolume: 0,
  },
  instrumentId: 0,
  instrumentName: "",
  position: 0,
  side: SideResponse.Buy,
  delta: "",
  open_interest: "0",
  markPrice: "",
  iv: "",
};

const placeholderRow: ITableContentRowWithCallPut = {
  strike: "",
  call: defaultTickerValue,
  put: defaultTickerValue,
  expiry: 0,
};

export function OptionsChain({
  onTrade,
  activeInstrumentId,
}: IOptionsChainProps) {
  const strikeWidth: number = 112;
  const { market } = useContext(MarketContext);
  const { index: indexData } = useIndexWSS(market.asset);
  const currentIndexPrice = indexData?.price;
  const {
    selectedValues,
    expiries,
    setExpiry,
    setOptionType,
    selectedOptionInstrument,
    marketsData,
  } = useContext(MarketInstrumentContext);

  const { expiry } = selectedValues;
  const { positions, positionsMap } = usePositionsWSS();
  const { data: orders } = useOrder();

  const marketsOfThisExpiry = useMemo(
    () => marketsData?.filter((v) => Number(v.expiry) === expiry) || [],
    [expiry, marketsData]
  );

  const putCalls = useMemo(
    () => ({
      calls: marketsOfThisExpiry.filter(
        (v) => v.option_type === OptionTypeResponse.Call
      ),
      puts: marketsOfThisExpiry.filter(
        (v) => v.option_type === OptionTypeResponse.Put
      ),
    }),
    [marketsOfThisExpiry]
  );

  // Websocket
  const addAssetDerivative = useTickersStore(
    (state) => state.addAssetDerivative
  );
  const removeAssetDerivative = useTickersStore(
    (state) => state.removeAssetDerivative
  );
  const instrumentTicker = useTickersStore(
    (state) => state.ticker[getExpiryFromUnix(expiry) || ""] || {}
  );

  const scrollableRef = useRef<HTMLDivElement>(null);
  const currentSpotRef = useRef<HTMLDivElement>(null);
  const lowerThanSpotRowRefs = useRef<HTMLDivElement[]>([]);
  const higherThanSpotRowRefs = useRef<HTMLDivElement[]>([]);
  const { scrollVerticalTo } = useScroll(scrollableRef.current);
  const [scrolledToInstrumentName, setScrolledToInstrumentName] =
    useState<string>();
  const { width } = useScreenSize();
  const { t } = useTranslation("app", {
    keyPrefix: "OptionsChain.OptionsChain",
  });

  // Used for filtering in screensize < isMediumScreen
  const defaultMobileOrderType = useMemo(
    () => selectedValues.orderType || "call",
    [selectedValues.orderType]
  );
  const optionsChainTableMode: OptionsChainTableMode = useMemo(
    () => (width < WIDTHS.SM ? defaultMobileOrderType : "both"),
    [defaultMobileOrderType, width]
  );

  const decoratedExpiries = useMemo(() => {
    const withPositions =
      positions
        .filter((pos) => !!pos.option && pos.asset === market.asset)
        .map((pos) => pos.option!.expiry) ?? [];
    const withOrders =
      orders
        ?.filter(
          (o) =>
            !!o.expiry && getAssetFromSymbol(o.instrument_name) === market.asset
        )
        .map((o) => o.expiry!) ?? [];
    return {
      withPositions,
      withOrders,
    };
  }, [market.asset, orders, positions]);

  // Calculate the total volume for calls and puts
  const totalCallsPutsVolume: IOptionVolume = useMemo(() => {
    const { puts, calls } = putCalls;
    const totalCallsVolume = calls.reduce(
      (prev, m) => {
        const ticker = instrumentTicker[m.instrument_name];
        return {
          bids: prev.bids + (Number(ticker?.bid?.amount) || 0),
          asks: prev.asks + (Number(ticker?.ask?.amount) || 0),
        };
      },
      {
        bids: 0,
        asks: 0,
      }
    );

    const totalPutsVolume = puts.reduce(
      (prev, m) => {
        const ticker = instrumentTicker[m.instrument_name];
        return {
          bids: prev.bids + (Number(ticker?.bid?.amount) || 0),
          asks: prev.asks + (Number(ticker?.ask?.amount) || 0),
        };
      },
      {
        bids: 0,
        asks: 0,
      }
    );
    return {
      calls: totalCallsVolume,
      puts: totalPutsVolume,
    };
  }, [instrumentTicker, putCalls]);

  // Returns rows, split into below strike price and above strike price
  const filteredRows = useMemo(() => {
    if (!currentIndexPrice) {
      return {
        lessThanCurrPriceRows: [
          placeholderRow,
          placeholderRow,
          placeholderRow,
          placeholderRow,
          placeholderRow,
        ],
        moreThanCurrPriceRows: [
          placeholderRow,
          placeholderRow,
          placeholderRow,
          placeholderRow,
          placeholderRow,
        ],
      };
    }

    const { puts, calls } = putCalls;
    const strikeToRow: Record<string, ITableContentRowWithCallPut> = {};

    calls.forEach((m) => {
      const ticker = instrumentTicker[m.instrument_name];
      if (m.strike && ticker) {
        const position = positionsMap[m.instrument_name];
        strikeToRow[m.strike] = {
          ...strikeToRow[m.strike],
          strike: m.strike,
          // Default value for put if doesnt already exist.
          put: strikeToRow[m.strike]?.put || defaultTickerValue,
          call: {
            delta: ticker.mark?.greeks?.delta || "0",
            open_interest: ticker.open_interest || "0",
            ask: {
              price: ticker.ask?.price || "0",
              volume: ticker.ask?.amount || "0",
              iv: ticker.ask?.greeks?.iv || "0",
              orderVolume: Number(
                orders?.find(
                  (o) =>
                    o.instrument_id === ticker.instrument_id &&
                    o.side === SideResponse.Sell
                )?.amount || 0
              ),
            },
            bid: {
              price: ticker.bid?.price || "0",
              volume: ticker.bid?.amount || "0",
              iv: ticker.bid?.greeks?.iv || "0",
              orderVolume: Number(
                orders?.find(
                  (o) =>
                    o.instrument_id === ticker.instrument_id &&
                    o.side === SideResponse.Buy
                )?.amount || 0
              ),
            },
            instrumentId: Number(m.instrument_id),
            instrumentName: m.instrument_name,
            position: Number(position?.amount || 0),
            side: position?.side || SideResponse.Buy,
            markPrice: ticker.mark?.price || "0",
            iv: ticker.mark?.greeks?.iv || "0",
          },
          expiry: expiry!,
        };
      }
    });
    puts.forEach((m) => {
      const ticker = instrumentTicker[m.instrument_name];
      const position = positionsMap[m.instrument_name];
      if (m.strike && ticker) {
        strikeToRow[m.strike] = {
          ...strikeToRow[m.strike],
          strike: m.strike,
          // Default value for call if doesnt already exist.
          call: strikeToRow[m.strike]?.call || defaultTickerValue,
          put: {
            delta: ticker.mark?.greeks?.delta || "0",
            open_interest: ticker.open_interest || "0",
            ask: {
              price: ticker.ask?.price || "0",
              volume: ticker.ask?.amount || "0",
              iv: ticker.ask?.greeks?.iv || "0",
              orderVolume: Number(
                orders?.find(
                  (o) =>
                    o.instrument_id === ticker.instrument_id &&
                    o.side === SideResponse.Sell
                )?.amount || 0
              ),
            },
            bid: {
              price: ticker.bid?.price || "0",
              volume: ticker.bid?.amount || "0",
              iv: ticker.bid?.greeks?.iv || "0",
              orderVolume: Number(
                orders?.find(
                  (o) =>
                    o.instrument_id === ticker.instrument_id &&
                    o.side === SideResponse.Buy
                )?.amount || 0
              ),
            },
            instrumentId: Number(m.instrument_id),
            instrumentName: m.instrument_name,
            position: Number(position?.amount || 0),
            side: position?.side || SideResponse.Buy,
            markPrice: ticker.mark?.price || "0",
            iv: ticker.mark?.greeks?.iv || "0",
          },
          expiry: expiry!,
        };
      }
    });

    const allRows = Object.values(strikeToRow);

    // Finally, split into 2 arrays, less than and more than current price
    let lessThanCurrPriceRows: ITableContentRowWithCallPut[] = [];
    let moreThanCurrPriceRows: ITableContentRowWithCallPut[] = [];

    if (allRows.length) {
      for (let i = 0; i < allRows.length; i += 1) {
        const row = allRows[i];
        if (currency(row.strike).value <= Number(currentIndexPrice)) {
          lessThanCurrPriceRows.push(row);
        } else {
          moreThanCurrPriceRows.push(row);
        }
      }
    } else {
      // Default values
      lessThanCurrPriceRows = [
        placeholderRow,
        placeholderRow,
        placeholderRow,
        placeholderRow,
        placeholderRow,
      ];
      moreThanCurrPriceRows = [
        placeholderRow,
        placeholderRow,
        placeholderRow,
        placeholderRow,
        placeholderRow,
      ];
    }

    return {
      lessThanCurrPriceRows,
      moreThanCurrPriceRows,
    };
  }, [
    putCalls,
    instrumentTicker,
    positionsMap,
    expiry,
    currentIndexPrice,
    orders,
  ]);

  useEffect(() => {
    if (expiry) {
      const channel = `${market.asset}:OPTION:${getExpiryFromUnix(
        expiry,
        true
      )}`;
      addAssetDerivative(channel);
      return () => {
        removeAssetDerivative(channel);
      };
    }
    return () => {};
  }, [addAssetDerivative, expiry, market.asset, removeAssetDerivative]);

  // ================================
  // ======== SCROLL JACKING ========
  // ================================
  // Whenever expiry changes, scrolls to strike (if no current instrument is selected)
  // also updates whenever no of rows changes
  useEffect(() => {
    if (
      expiry &&
      !selectedOptionInstrument?.instrument_name &&
      scrollableRef.current &&
      currentSpotRef.current
    ) {
      const halfScrollContainer = scrollableRef.current.offsetHeight / 2;
      const halfCurrentSpotHeight = currentSpotRef.current.offsetHeight / 2;
      scrollableRef.current.scrollTo({
        top:
          currentSpotRef.current.offsetTop -
          halfScrollContainer +
          halfCurrentSpotHeight,
        behavior: "smooth",
      });
    }
  }, [
    expiry,
    filteredRows.lessThanCurrPriceRows.length,
    filteredRows.moreThanCurrPriceRows.length,
    selectedOptionInstrument?.instrument_name,
  ]);

  // Whenever selected instrument changes, scrolls to it
  useEffect(() => {
    if (
      selectedOptionInstrument?.instrument_name &&
      selectedOptionInstrument.instrument_name !== scrolledToInstrumentName &&
      scrollableRef.current
    ) {
      // Find the ref
      let rows: ITableContentRowWithCallPut[] = [];
      let rowRefs: MutableRefObject<HTMLDivElement[]>;
      if (
        currentIndexPrice &&
        Number(selectedOptionInstrument.strike) > Number(currentIndexPrice)
      ) {
        rows = filteredRows.moreThanCurrPriceRows;
        rowRefs = higherThanSpotRowRefs;
      } else {
        rows = filteredRows.lessThanCurrPriceRows;
        rowRefs = lowerThanSpotRowRefs;
      }

      // Find index of the correct ref
      const index = rows.findIndex(
        (row) =>
          row.call.instrumentName ===
            selectedOptionInstrument.instrument_name ||
          row.put.instrumentName === selectedOptionInstrument.instrument_name
      );

      if (index && rowRefs.current[index]) {
        const rowElement = rowRefs.current[index];
        setScrolledToInstrumentName(selectedOptionInstrument.instrument_name);
        scrollVerticalTo(rowElement, true, true);
      }
    }
  }, [
    scrollVerticalTo,
    currentIndexPrice,
    filteredRows,
    selectedOptionInstrument,
    scrolledToInstrumentName,
  ]);

  const onSelectExpiry = useCallback(
    (expiryDate: number) => {
      setExpiry(expiryDate);
    },
    [setExpiry]
  );

  const onSelectOptionTypeFilterMobile = useCallback(
    (value: string) => {
      setOptionType(value as OptionTypeResponse);
    },
    [setOptionType]
  );

  return (
    <OptionsChainContainer>
      <OptionsTypeDateSelectionWrapper>
        {optionsChainTableMode !== "both" && (
          <OptionsTypeSelectWrapper>
            <SegmentedControl
              segments={[
                {
                  value: String(OptionTypeResponse.Call),
                  display: t("calls"),
                  textColor:
                    optionsChainTableMode === OptionTypeResponse.Call
                      ? COLORS.blue.one
                      : TEXT_COLORS.three,
                },
                {
                  value: String(OptionTypeResponse.Put),
                  display: t("puts"),
                  textColor:
                    optionsChainTableMode === OptionTypeResponse.Put
                      ? COLORS.blue.one
                      : TEXT_COLORS.three,
                },
              ]}
              value={optionsChainTableMode}
              onSelect={onSelectOptionTypeFilterMobile}
              config={{
                theme: "outline",
                color: COLORS.blue.one,
                widthType: "fullWidth",
                backgroundColor: LAYER_COLORS.two,
                borderRadius: "10px",
                button: {
                  fontSize: FONT_SIZE.one,
                },
              }}
            />
            <Divider direction="vertical" size="100%" />
          </OptionsTypeSelectWrapper>
        )}
        <DateSelection
          expiries={expiries}
          selectedExpiry={expiry}
          expiriesWithPosition={decoratedExpiries.withPositions}
          expiriesWithOrder={decoratedExpiries.withOrders}
          onSelectExpiry={onSelectExpiry}
        />
      </OptionsTypeDateSelectionWrapper>
      <TableHeader
        strikeWidth={strikeWidth}
        expiry={expiry}
        mode={optionsChainTableMode}
      />
      <TableContainer>
        <ScrollableContainer>
          <ScrollableSection ref={scrollableRef}>
            <TableContent
              rows={filteredRows.lessThanCurrPriceRows}
              rowRefs={lowerThanSpotRowRefs}
              strikeWidth={strikeWidth}
              totalCallsPutsSize={totalCallsPutsVolume}
              onTrade={onTrade}
              activeInstrumentId={activeInstrumentId}
              mode={optionsChainTableMode}
              decimals={
                getContractPriceStep(selectedOptionInstrument).amount_precision
              }
            />
            <CurrentSpotSection
              ref={currentSpotRef}
              asset={market.asset}
              price={Number(currentIndexPrice)}
              loading={!currentIndexPrice}
            />
            <TableContent
              rows={filteredRows.moreThanCurrPriceRows}
              rowRefs={higherThanSpotRowRefs}
              strikeWidth={strikeWidth}
              totalCallsPutsSize={totalCallsPutsVolume}
              onTrade={onTrade}
              activeInstrumentId={activeInstrumentId}
              mode={optionsChainTableMode}
              decimals={
                getContractPriceStep(selectedOptionInstrument).amount_precision
              }
            />
          </ScrollableSection>
        </ScrollableContainer>
      </TableContainer>
    </OptionsChainContainer>
  );
}
