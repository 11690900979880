import React from "react";

type SVGProps = React.SVGAttributes<SVGElement>;

export function StakingBadge({
  color,
  ...props
}: SVGProps & { color?: string }): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill={color || "white"}
        fillOpacity="0.08"
      />
      <path
        d="M12.1875 4.5L13.036 11.1515L19.6875 12L13.036 12.8485L12.1875 19.5L11.339 12.8485L4.6875 12L11.339 11.1515L12.1875 4.5Z"
        fill={color || "white"}
      />
    </svg>
  );
}
