import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "styled-components";
import SegmentedControl from "../../components/shared/SegmentedControl";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { LAYER_COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { TradingTab, TradingTabWrapper } from "./style";

export enum TradingPageTabEnum {
  OPTIONS_CHAIN = "options_chain",
  TRADE_HISTORY = "trade_history",
}

interface ITradingPageTabs {
  currentTab: TradingPageTabEnum;
  setCurrentTab: (tab: TradingPageTabEnum) => void;
  isMobileScreen?: boolean;
  style?: CSSProperties;
}

function TradingPageTabs({
  currentTab,
  setCurrentTab,
  isMobileScreen,
  style,
}: ITradingPageTabs) {
  const { t } = useTranslation("app", {
    keyPrefix: "pages.TradingPage.TradingPageTabs",
  });
  const controls = useMemo(
    () =>
      Object.keys(TradingPageTabEnum).map((k) => {
        const key = k as keyof typeof TradingPageTabEnum;
        return {
          value: TradingPageTabEnum[key],
          display: <TradingTab>{t(TradingPageTabEnum[key])}</TradingTab>,
          textColor:
            currentTab === TradingPageTabEnum[key]
              ? TEXT_COLORS.one
              : TEXT_COLORS.three,
        };
      }),
    [currentTab, t]
  );

  return (
    <TradingTabWrapper isMobileScreen={isMobileScreen} style={style}>
      <SegmentedControl
        segments={controls}
        value={currentTab}
        onSelect={(e) => setCurrentTab(e as TradingPageTabEnum)}
        config={{
          widthType: "fullWidth",
          backgroundColor: LAYER_COLORS.two,
          activeBackgroundColor: LAYER_COLORS.three,
          borderRadius: "10px",
          button: {
            fontSize: FONT_SIZE.one,
          },
        }}
      />
    </TradingTabWrapper>
  );
}

export default TradingPageTabs;
