import {
  AnimatePresence,
  HTMLMotionProps,
  motion,
  MotionStyle,
  Transition as MotionTransition,
} from "framer-motion";
import { PropsWithChildren, useMemo } from "react";

export enum TransitionTypeEnum {
  FADE = 0,
  EXPAND,
}

interface ITransitionProps {
  type?: TransitionTypeEnum;
  style?: MotionStyle;
}

const easeTransition: MotionTransition = {
  ease: "easeInOut",
  duration: 0.1,
};

const getAnimationConfig = (
  type: TransitionTypeEnum = TransitionTypeEnum.FADE
): HTMLMotionProps<"div"> => {
  if (type === TransitionTypeEnum.EXPAND) {
    const fadeConfig = getAnimationConfig(TransitionTypeEnum.FADE);
    return {
      initial: {
        ...Object(fadeConfig.initial),
        height: 0,
      },
      animate: {
        ...Object(fadeConfig.animate),
        height: "fit-content",
        transition: {
          type: "spring",
          bounce: 0.2,
          velocity: 1,
        },
      },
      exit: {
        ...Object(fadeConfig.exit),
        height: 0,
        transition: {
          type: "spring",
          bounce: 0.2,
          velocity: 1,
        },
      },
    };
  }

  // Returns fade by default
  return {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: easeTransition,
    },
    exit: {
      opacity: 0,
      transition: easeTransition,
    },
  };
};

function Transition({
  type,
  style,
  children,
}: PropsWithChildren<ITransitionProps>) {
  const config: HTMLMotionProps<"div"> = useMemo(
    () => getAnimationConfig(type),
    [type]
  );
  return (
    <AnimatePresence>
      {children && (
        <motion.div key={"transitionChildren"} style={style} {...config}>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Transition;
