import { IToast } from "../../../interfaces/Toast";
import { ToastEnum, ToastStatusEnum } from "../../../utils/toast";
import {
  Header,
  StatLabel,
  SubheaderWrapper,
  ToastHeaderWrapper,
  ToastInfoWrapper,
  ToastWrapper,
} from "./style";

import error from "../../../assets/svg/error.svg";
import success from "../../../assets/svg/success.svg";

interface ISingleToastProps {
  toast: IToast;
}

function SingleToast({ toast: toastParams }: ISingleToastProps) {
  return (
    <ToastWrapper>
      <ToastHeaderWrapper>
        <div>
          {/* eslint-disable-next-line no-nested-ternary */}
          {toastParams.icon ? (
            typeof toastParams.icon === "string" ? (
              <img src={toastParams.icon} alt={"Notification"} />
            ) : (
              toastParams.icon
            )
          ) : (
            <img
              src={
                toastParams.status === ToastStatusEnum.SUCCESS ? success : error
              }
              alt={"Notification"}
            />
          )}
          <Header>
            {toastParams.header}
            <SubheaderWrapper>{toastParams.subheader}</SubheaderWrapper>
          </Header>
        </div>
      </ToastHeaderWrapper>
      {toastParams.type === ToastEnum.INFO &&
        Boolean(toastParams.stats.length) && (
          <ToastInfoWrapper>
            {toastParams.stats.map((stat) => (
              <div key={`${stat.label}-${stat.value}`}>
                <StatLabel>{stat.label}</StatLabel>
                <span>{stat.value}</span>
              </div>
            ))}
          </ToastInfoWrapper>
        )}
    </ToastWrapper>
  );
}

export default SingleToast;
