import currency from "currency.js";
import { toJpeg } from "html-to-image";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import { ReactComponent as AevoLogo } from "../../assets/logo/logo_text_white.svg";
import { ReactComponent as LeftArrow } from "../../assets/svg/chevron-outline.svg";
import { ReactComponent as Download } from "../../assets/svg/download.svg";
import {
  GetAccount200ResponsePositionsInner,
  GetTradeHistory200ResponseTradeHistoryInner,
  SideResponse,
} from "../../codegen-api";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { LINKS } from "../../constants/links";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { getAssetLogo } from "../../utils/asset/assets";
import { BaseModal } from "../BaseModal";
import { CloseButton } from "../BaseModal/style";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import BackgroundVideoFX from "../shared/BackgroundVideoFX/BackgroundVideoFX";
import SegmentedControl from "../shared/SegmentedControl";
import { Spinner } from "../shared/Spinner";
import {
  EnlargedBottomCard,
  EnlargedPnLCardContainer,
  EnlargedPnLCardWrapper,
  EnlargedStatWrapper,
  EnlargedTitle,
  EnlargedTopCard,
  MiniAsset,
  MiniBottomCard,
  MiniCardWrapper,
  MiniStatWrapper,
  MiniText,
  MiniTitle,
  MiniTopCard,
  PnLCardWrapper,
  QRWrapper,
  ShareActionsRow,
  SharePreviewTitle,
} from "./style";
import { calculatePnlPercent } from "../../utils/math";

type IPnLCardProps = {
  show?: boolean;
  onHideModal?: () => void;
  onHidePnLCard: () => void;
  hideBackButton?: boolean;
} & (
  | {
      pnlType: "position";
      pnlData?: GetAccount200ResponsePositionsInner;
    }
  | {
      pnlType: "trade";
      pnlData?: GetTradeHistory200ResponseTradeHistoryInner;
    }
);

interface IMiniPnLCardProps {
  pnlFormat?: "%" | "$";
  pnlContent?: {
    text: string;
    color: string;
  };
  pnlType: "position" | "trade";
  pnlData?:
    | GetAccount200ResponsePositionsInner
    | GetTradeHistory200ResponseTradeHistoryInner;
}

interface IEnlargedPnLCardProps extends Omit<IMiniPnLCardProps, "pnlFormat"> {
  onDownload: boolean;
  setDownload: (onDownload: boolean) => void;
}

function StatContent({
  pnlType,
  pnlData,
}: {
  pnlType: "position" | "trade";
  pnlData?:
    | GetAccount200ResponsePositionsInner
    | GetTradeHistory200ResponseTradeHistoryInner;
}) {
  const { getMarketPrecision } = useContext(MarketInstrumentContext);
  const { t } = useTranslation("app", { keyPrefix: "PnLCardModal" });
  return (
    <>
      <div>
        <span>{t("position")}</span>
        {pnlType === "position" ? (
          <span
            style={{
              color:
                pnlData?.side === SideResponse.Buy
                  ? COLORS.positive.one
                  : COLORS.negative.one,
            }}
          >
            {pnlData?.side === SideResponse.Buy ? "Long" : "Short"}
          </span>
        ) : (
          <span
            style={{
              color:
                pnlData?.side === SideResponse.Sell
                  ? COLORS.positive.one
                  : COLORS.negative.one,
            }}
          >
            {pnlData?.side === SideResponse.Buy ? "Short" : "Long"}
          </span>
        )}
      </div>
      <div>
        <span>{t("entry_price")}</span>
        {pnlType === "position" ? (
          <span>
            {currency(pnlData?.avg_entry_price || 0, {
              precision: getMarketPrecision(
                pnlData?.asset,
                pnlData?.instrument_type
              ).price_precision,
            }).format()}
          </span>
        ) : (
          <span>
            {currency(pnlData?.avg_entry_price || 0, {
              precision: getMarketPrecision(
                pnlData?.asset,
                pnlData?.instrument_type
              ).price_precision,
            }).format()}
          </span>
        )}
      </div>
      {pnlType === "position" ? (
        <div>
          <span>{t("current_price")}</span>
          <span>
            {currency(pnlData?.mark_price || 0, {
              precision: getMarketPrecision(
                pnlData?.asset,
                pnlData?.instrument_type
              ).price_precision,
            }).format()}
          </span>
        </div>
      ) : (
        <div>
          <span>{t("exit_price")}</span>
          <span>
            {currency(
              (pnlData as GetTradeHistory200ResponseTradeHistoryInner)?.price ||
                0,
              {
                precision: getMarketPrecision(
                  pnlData?.asset,
                  pnlData?.instrument_type
                ).price_precision,
              }
            ).format()}
          </span>
        </div>
      )}
    </>
  );
}

function BottomCardContent({ username }: { username?: string }) {
  const QR_LINK = username ? `${LINKS.app}/r/${username}` : LINKS.app;

  return (
    <div>
      <div>
        <AevoLogo
          style={{
            width: "50%",
            height: "fit-content",
            marginBottom: SPACING.one,
          }}
        />
        <p style={{ color: TEXT_COLORS.two }}>APP.AEVO.XYZ</p>
        <p style={{ color: TEXT_COLORS.three }}>{username}</p>
      </div>
      <QRWrapper>
        <QRCode value={QR_LINK} />
      </QRWrapper>
    </div>
  );
}

function EnlargedPnLCard({
  pnlData,
  pnlType,
  pnlContent,
  onDownload,
  setDownload,
}: IEnlargedPnLCardProps) {
  const pnlRef = useRef<HTMLDivElement>(null);
  const { data: accountData } = useGetAccount();

  const exportPnLCard = useCallback(() => {
    if (pnlRef.current === null || !onDownload) {
      return;
    }

    toJpeg(pnlRef.current, {
      quality: 1,
      pixelRatio: 7.5,
      style: {
        opacity: "1",
        left: "0%",
        right: "0%",
        top: "0%",
        bottom: "0%",
      },
    })
      .then((dataUrl) => {
        const link = document.createElement("a");

        link.download = `${pnlData?.instrument_name}.jpeg`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      })
      .finally(() => {
        setDownload(false);
      });
  }, [onDownload, pnlData?.instrument_name, setDownload]);

  useEffect(() => {
    if (onDownload) {
      exportPnLCard();
    }
  }, [exportPnLCard, onDownload]);

  return (
    <EnlargedPnLCardWrapper ref={pnlRef}>
      <EnlargedTopCard>
        <img src={getAssetLogo(pnlData?.asset)} alt={pnlData?.asset} />
        <p>{pnlData?.instrument_name}</p>
        <EnlargedTitle color={pnlContent?.color}>
          {pnlContent?.text}
        </EnlargedTitle>
        <EnlargedStatWrapper>
          <StatContent pnlType={pnlType} pnlData={pnlData} />
        </EnlargedStatWrapper>
        <BackgroundVideoFX />
      </EnlargedTopCard>
      <EnlargedBottomCard>
        <BottomCardContent username={accountData?.username} />
      </EnlargedBottomCard>
    </EnlargedPnLCardWrapper>
  );
}

function MiniPnLCard({
  pnlData,
  pnlType,
  pnlContent,
  pnlFormat,
}: IMiniPnLCardProps) {
  const { data: accountData } = useGetAccount();

  return (
    <MiniCardWrapper>
      <MiniTopCard>
        <MiniAsset src={getAssetLogo(pnlData?.asset)} alt={pnlData?.asset} />
        <MiniTitle>{pnlData?.instrument_name}</MiniTitle>
        <MiniText
          color={pnlContent?.color}
          key={pnlFormat}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          {pnlContent?.text}
        </MiniText>
        <MiniStatWrapper>
          <StatContent pnlType={pnlType} pnlData={pnlData} />
        </MiniStatWrapper>
        <BackgroundVideoFX />
      </MiniTopCard>
      <MiniBottomCard>
        <BottomCardContent username={accountData?.username} />
      </MiniBottomCard>
    </MiniCardWrapper>
  );
}

export function PnLCardModal({
  pnlType,
  pnlData,
  onHideModal,
  show,
  onHidePnLCard,
  hideBackButton = false,
}: IPnLCardProps) {
  const [pnlFormat, setPnlFormat] = useState<"$" | "%">("$");
  const [onDownload, setDownload] = useState<boolean>(false);
  const { t } = useTranslation("app", { keyPrefix: "PnLCardModal" });

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        onHidePnLCard();
      }, 400);
    }
  }, [onHidePnLCard, show]);

  const pnlContent = useMemo(() => {
    if (!pnlData) return undefined;

    // For PnL denominated in $
    if (pnlFormat === "$") {
      if (pnlType === "position") {
        return {
          text: `${Number(pnlData?.unrealized_pnl) > 0 ? "+" : ""}${currency(
            pnlData?.unrealized_pnl
          ).format()}`,
          color:
            Number(pnlData?.unrealized_pnl) >= 0
              ? COLORS.positive.one
              : COLORS.negative.one,
        };
      }
      if (pnlType === "trade") {
        return {
          text: `${Number(pnlData?.pnl) > 0 ? "+" : ""}${currency(
            pnlData?.pnl || 0
          ).format()}`,
          color:
            Number(pnlData?.pnl) >= 0
              ? COLORS.positive.one
              : COLORS.negative.one,
        };
      }
    }

    // For PnL denominated in %
    if (pnlType === "position") {
      const totalValue =
        currency(pnlData?.avg_entry_price || 0).value * Number(pnlData?.amount);
      const roi = calculatePnlPercent(
        Number(pnlData.unrealized_pnl),
        totalValue,
        Number(pnlData.maintenance_margin)
      );

      return {
        text: `${Number(roi) >= 0 ? "+" : ""}${(Number(roi) * 100).toFixed(
          2
        )}%`,
        color: Number(roi) >= 0 ? COLORS.positive.one : COLORS.negative.one,
      };
    }

    if (pnlType === "trade") {
      let roi = 0;

      // If it's a long position
      // ROI = ((Exit Price - Entry Price) * Contract Amount - Fees) / (Entry Price * Contract Amount) * 100
      if (pnlData.side === SideResponse.Sell) {
        roi =
          ((Number(pnlData.price) - Number(pnlData.avg_entry_price)) *
            Number(pnlData.amount) -
            Number(pnlData.fees)) /
          (Number(pnlData.avg_entry_price) * Number(pnlData.amount));
      }

      // If it's a sell position
      // ROI = ((Entry Price - Exit Price) * Contract Amount - Fees) / (Entry Price * Contract Amount) * 100
      if (pnlData.side === SideResponse.Buy) {
        roi =
          ((Number(pnlData.avg_entry_price) - Number(pnlData.price)) *
            Number(pnlData.amount) -
            Number(pnlData.fees)) /
          (Number(pnlData.avg_entry_price) * Number(pnlData.amount));
      }

      return {
        text: `${Number(roi) >= 0 ? "+" : ""}${(Number(roi) * 100).toFixed(
          2
        )}%`,
        color: Number(roi) >= 0 ? COLORS.positive.one : COLORS.negative.one,
      };
    }

    return undefined;
  }, [pnlData, pnlFormat, pnlType]);

  return (
    <EnlargedPnLCardContainer>
      <EnlargedPnLCard
        onDownload={onDownload}
        setDownload={setDownload}
        pnlType={pnlType}
        pnlData={pnlData}
        pnlContent={pnlContent}
      />
      <BaseModal
        onHide={onHideModal}
        show={show}
        noContentPadding
        titleStyle={{ paddingLeft: 0 }}
        title={
          <SharePreviewTitle>
            {!hideBackButton && (
              <CloseButton onClick={onHidePnLCard}>
                <LeftArrow />
              </CloseButton>
            )}
            <span>{t("share_preview")}</span>
          </SharePreviewTitle>
        }
      >
        <PnLCardWrapper>
          <div>
            <SegmentedControl
              segments={[
                {
                  value: "$",
                  display: "$",
                  textColor:
                    pnlFormat === "$" ? COLORS.blue.one : TEXT_COLORS.two,
                },
                {
                  value: "%",
                  display: "%",
                  textColor:
                    pnlFormat === "%" ? COLORS.blue.one : TEXT_COLORS.two,
                },
              ]}
              value={pnlFormat}
              onSelect={(value) => setPnlFormat(value as "$" | "%")}
              config={{
                theme: "outline",
                color: COLORS.blue.one,
                backgroundColor: LAYER_COLORS.three,
                activeBackgroundColor: COLORS.blue.five,
                borderRadius: "10px",
                margin: `${SPACING.three}px auto`,
                widthType: "maxContent",
                button: {
                  px: 12,
                  py: 12,
                  fontSize: "14px",
                  height: 32,
                },
              }}
            />
            <MiniPnLCard
              pnlType={pnlType}
              pnlData={pnlData}
              pnlContent={pnlContent}
              pnlFormat={pnlFormat}
            />
          </div>
        </PnLCardWrapper>
        <ShareActionsRow>
          <Button
            fullWidth
            buttonTheme={ButtonThemeEnum.NEUTRAL2}
            disabled={onDownload}
            onClick={() => setDownload(true)}
          >
            {onDownload ? <Spinner /> : <Download />}
          </Button>
        </ShareActionsRow>
      </BaseModal>
    </EnlargedPnLCardContainer>
  );
}
