/* eslint-disable react/no-array-index-key */
import currency from "currency.js";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GetMarkets200Response,
  InstrumentTypeResponse,
  PostRfqsPayload,
  PostRfqsPayloadLegsInner,
} from "../../../../codegen-api";
import { BaseModal } from "../../../../components/BaseModal";
import { Button, ButtonThemeEnum } from "../../../../components/Buttons/styles";
import Tag from "../../../../components/ConfirmationModal/Tag";
import { Spinner } from "../../../../components/shared/Spinner";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../../../constants/design/colors";
import { SPACING } from "../../../../constants/design/spacing";
import { useRFQ } from "../../../../hooks/api/rfq/useRFQ";
import { useToast } from "../../../../hooks/toast";
import { useSFX } from "../../../../hooks/useSFX";
import { getAssetLogo } from "../../../../utils/asset/assets";
import { nanosToSeconds } from "../../../../utils/date";
import { ToastEnum, ToastStatusEnum } from "../../../../utils/toast";
import { AssetTag, Ratio } from "../RFQTable/style";
import { BlockDescWrapper, BlockPriceWrapper } from "./style";

interface IRFQModalProps {
  payload?: PostRfqsPayload;
  show: boolean;
  onHide: (resetForm?: boolean) => void;

  activeOptionMarkets?: GetMarkets200Response[];
  activePerpMarkets?: GetMarkets200Response[];
}

function CreateRFQModal({
  payload,
  show,
  onHide,
  activeOptionMarkets,
  activePerpMarkets,
}: IRFQModalProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { createRFQ } = useRFQ(undefined, false);
  const { addToast, addErrorToast } = useToast();
  const { t } = useTranslation("app", {
    keyPrefix: "pages.RFQPage.CreateRFQModal",
  });
  const { t: apiErrors } = useTranslation("apiErrors");
  const { playSound } = useSFX();

  const getMarketByLeg = useCallback(
    (leg: PostRfqsPayloadLegsInner) =>
      [...(activeOptionMarkets || []), ...(activePerpMarkets || [])].find(
        (m) => m.instrument_id === String(leg.instrument)
      ),
    [activeOptionMarkets, activePerpMarkets]
  );

  const generateLegTag = useCallback(
    (leg: PostRfqsPayloadLegsInner): Array<string | JSX.Element> => {
      const market = getMarketByLeg(leg);

      if (market) {
        if (market.instrument_type === InstrumentTypeResponse.Option) {
          return [
            <AssetTag key={leg.instrument}>
              <img
                src={getAssetLogo(market.underlying_asset)}
                alt={market.underlying_asset}
              />
              {market.underlying_asset}
            </AssetTag>,
            <span
              key={leg.instrument}
              style={{
                color: leg.is_buy ? COLORS.positive.one : COLORS.negative.one,
              }}
            >
              {leg.is_buy ? "Buy" : "Sell"}
            </span>,
            market.option_type as string,
            moment
              .unix(nanosToSeconds(Number(market.expiry)))
              .format("DD MMM YYYY"),
            currency(market.strike as string, { precision: 0 }).format(),
            <Ratio key={leg.instrument}>x{leg.ratio}</Ratio>,
          ];
        }
        return [
          <AssetTag key={leg.instrument}>
            <img
              src={getAssetLogo(market.underlying_asset)}
              alt={market.underlying_asset}
            />
            {market.underlying_asset}
          </AssetTag>,
          <span
            key={leg.instrument}
            style={{
              color: leg.is_buy ? COLORS.positive.one : COLORS.negative.one,
            }}
          >
            {leg.is_buy ? "Buy" : "Sell"}
          </span>,
          market.instrument_type,
          <Ratio key={leg.instrument}>x{leg.ratio}</Ratio>,
        ];
      }

      return [];
    },
    [getMarketByLeg]
  );

  const tags = useMemo(() => {
    if (payload?.legs && payload.legs.length > 0) {
      return payload.legs.map((leg) => generateLegTag(leg));
    }

    return undefined;
  }, [payload, generateLegTag]);

  const onSubmit = useCallback(async () => {
    try {
      if (payload) {
        setLoading(true);
        await createRFQ(payload);
        onHide(true);
        playSound("order_placed");
        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: `${payload.is_buy ? "Bid" : "Ask"} Offer Created`,
            subheader: "Offer created successfully",
            status: ToastStatusEnum.SUCCESS,
          },
          4000
        );
      }
    } catch (error: any) {
      addErrorToast(t("rfq_order"), apiErrors(error.message) || t("try_again"));
      onHide();
    } finally {
      setLoading(false);
    }
  }, [
    addErrorToast,
    addToast,
    apiErrors,
    createRFQ,
    onHide,
    payload,
    playSound,
    t,
  ]);

  if (!payload) {
    return null;
  }

  return (
    <BaseModal
      style={{
        width: "fit-content",
        minWidth: 311,
      }}
      title={"Create RFQ"}
      show={show}
      onHide={onHide}
    >
      <BlockDescWrapper style={{ marginBottom: SPACING.three }}>
        You are creating an offer for {payload?.legs?.length} instrument(s):
      </BlockDescWrapper>
      <div>
        {tags && (
          <div>
            {tags.map((texts, i) => (
              <div key={i}>
                <Tag
                  style={{
                    padding: SPACING.two,
                    marginTop: SPACING.three,
                    marginBottom: 0,
                    border: `1px solid ${BORDER_COLORS.one}`,
                  }}
                  color={TEXT_COLORS.two}
                  backgroundColor={BACKGROUND_COLORS.component}
                  texts={texts}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <BlockPriceWrapper>
        <span>Block Size:</span>
        <span>{Number(payload.amount) / 1000000}</span>
      </BlockPriceWrapper>
      <Button
        disabled={loading}
        type="submit"
        onClick={onSubmit}
        style={{ marginTop: SPACING.three }}
        fullWidth
        buttonTheme={ButtonThemeEnum.NEUTRAL2}
      >
        {loading ? <Spinner /> : "Create Offer"}
      </Button>
    </BaseModal>
  );
}

export default CreateRFQModal;
