import { AxiosError } from "axios";
import useSWR from "swr";
import { GenericErrorResponse, GetSettlementHistory200Response } from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { publicApi } from "../../../services/api/apiFetcher";
import { pollingInterval } from "../../../services/api/pollingInterval";
import { AssetResponse } from "../../../utils/asset";

export const useSettlementHistory = (asset?: AssetResponse, startTime?: number, endTime?: number, limit?: number) => {
  const fetcher = publicApi();

  const swr = useSWR<GetSettlementHistory200Response[], AxiosError<GenericErrorResponse>>(
    [APIEndpointEnum.SETTLEMENT_HISTORY, asset],
    async () => (await (await fetcher.getSettlementHistory(asset, startTime, endTime, limit))()).data,
    {
      refreshInterval: pollingInterval[APIEndpointEnum.SETTLEMENT_HISTORY],
    },
  );

  return swr;
};
