/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import currency from "currency.js";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSortBy, useTable } from "react-table";
import { GetSettlementHistory200Response } from "../../../codegen-api";
import { ITableColumn } from "../../../interfaces/Table/TableColumn";
import { getTimeAgo, nanosToSeconds } from "../../../utils/date";
import { Spinner } from "../../shared/Spinner";
import { SpinnerContainerWrapper } from "../../shared/Spinner/style";
import { DefaultCellForColumn } from "../../shared/Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../shared/Table/DefaultHeaderForColumn";
import MarketCell from "../Table/MarketCell";
import { EmptyContentContainer, PortfolioTableWrapper } from "../Table/style";

interface ITradeHistoryProps {
  settlementHistories?: GetSettlementHistory200Response[];
  loading?: boolean;
  isMobile?: boolean;
  height?: number;
}

function PortfolioSettlementHistory({
  settlementHistories,
  loading,
  isMobile,
}: ITradeHistoryProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "PortfolioSettings.PortfolioSettlementHistory",
  });
  const { t: translateAll } = useTranslation();
  const { t: tooltip } = useTranslation("tooltips");

  const modifiedSettlementHistory: GetSettlementHistory200Response[] = useMemo(
    () => settlementHistories || [],
    [settlementHistories]
  );

  const columns: ITableColumn<GetSettlementHistory200Response>[] = useMemo(
    () => [
      {
        title: t("asset"),
        accessor: "asset",
        tooltip: tooltip("asset"),
        Cell: ({ value }) => <MarketCell instrumentName={value} />,
      },
      {
        title: t("expiry"),
        accessor: "expiry",
        align: "left",
        tooltip: tooltip("expiry"),
        valueExtractor: (value) =>
          `${moment
            .unix(nanosToSeconds(value))
            .utc()
            .format("hhmm")} UTC - ${moment
            .unix(nanosToSeconds(value))
            .format("DD MMM YY")}`,
      },
      {
        title: t("settlement_price"),
        accessor: "settlement_price",
        align: "right",
        tooltip: tooltip("settlementPrice"),
        valueExtractor: (value) => currency(value).format(),
      },
      {
        title: t("settlement_time"),
        accessor: "settlement_timestamp",
        align: "right",
        tooltip: tooltip("settlementTime"),
        valueExtractor: (value) =>
          getTimeAgo(moment.unix(nanosToSeconds(value)), translateAll),
      },
    ],
    [t, tooltip, translateAll]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: modifiedSettlementHistory,
        defaultColumn: {
          Header: DefaultHeaderForColumn,
          Cell: DefaultCellForColumn,
        },
        autoResetSortBy: false,
      } as any,
      useSortBy
    );

  return (
    <PortfolioTableWrapper stickyHeader isMobile={isMobile}>
      {loading || !modifiedSettlementHistory.length ? (
        <div>
          {loading ? (
            <SpinnerContainerWrapper>
              <Spinner />
            </SpinnerContainerWrapper>
          ) : (
            <EmptyContentContainer>{t("no_history")}</EmptyContentContainer>
          )}
        </div>
      ) : (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  // eslint-disable-next-line react/jsx-key
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { ...rowProps } = row.getRowProps();
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...rowProps}>
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </PortfolioTableWrapper>
  );
}

export default PortfolioSettlementHistory;
