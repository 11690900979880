import { useMemo } from "react";
import useSWRImmutable from "swr/immutable";
import { GetIndexHistory200Response } from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { TimePeriodEnum } from "../../../interfaces/TimePeriod";
import { publicApi } from "../../../services/api/apiFetcher";
import { AssetResponse } from "../../../utils/asset";
import { getStartTimeSeconds, secondsToNanos } from "../../../utils/date";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";

/**
 * @param pair
 * @param period Optional. Defaults to daily
 * @param resolution Optional. Defaults to 60 * 30 (Every 30 mins)
 * @returns
 */
export const useGetPriceChart = (
  asset: AssetResponse,
  period: TimePeriodEnum = TimePeriodEnum.DAILY,
  resolution: number = 60 * 30
) => {
  const fetcher = publicApi();

  const swr = useSWRImmutable<GetIndexHistory200Response>(
    [APIEndpointEnum.PRICE_HISTORICAL, asset, period],
    async () => {
      const start = getStartTimeSeconds(period);
      const { data } = await (
        await fetcher.getIndexHistory(
          asset,
          resolution,
          secondsToNanos(start),
          undefined
        )
      )();

      return data;
    },
    {
      use: [useLoggerMiddleware],
    }
  );

  // CONVENIENCE VARIABLES
  const currentPrice = useMemo(() => {
    if (swr.data?.history?.length) {
      return Number(swr.data.history[0][1]);
    }
    return 0;
  }, [swr.data]);

  /**
   * Change percentage between the latest and oldest price for the given timeframe.
   */
  const changePercent = useMemo(() => {
    if (swr.data?.history?.length && currentPrice) {
      const oldestPrice = swr.data.history[swr.data.history.length - 1][1];
      return (+currentPrice - +oldestPrice) / +oldestPrice;
    }
    return undefined;
  }, [swr.data, currentPrice]);

  return {
    ...swr,
    changePercent,
    currentPrice,
  };
};
