import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { CSVLinkWrapper } from "./style";

interface IDownloadCSVButtonProps {
  data?: string[][];
  filename: string;
}

function DownloadCSVButton({ data, filename }: IDownloadCSVButtonProps) {
  const { t } = useTranslation("app", { keyPrefix: "PortfolioSettings.Table.DownloadCSVButton" });
  return (
    <CSVLinkWrapper>
      {
        data ? (
          <CSVLink
            data={data}
            filename={filename}
            target="_blank"
          >
            {t("download_desc")}
          </CSVLink>
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a aria-disabled>{t("download_desc")}</a>
        )
      }
    </CSVLinkWrapper>
  );
}

export default DownloadCSVButton;
