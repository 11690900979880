import { AnimatePresence } from "framer-motion";
import { CSSProperties, MouseEventHandler, PropsWithChildren } from "react";
import { LAYER_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";
import SegmentControl, { ISegmentType } from "../SegmentedControl";
import { BottomBarHeader, Container, ContentContainer } from "./style";

interface IBottomBarProps {
  style?: CSSProperties;
  segments: ISegmentType[];
  currentTab: any;
  rightAccessories?: JSX.Element | JSX.Element[];
  onSelect?: (value: string) => void;
  onHeaderClick?: MouseEventHandler<HTMLDivElement>;
}

function BottomBar({
  style,
  currentTab,
  segments,
  rightAccessories,
  onSelect,
  onHeaderClick,
  children,
}: PropsWithChildren<IBottomBarProps>) {
  return (
    <Container
      style={{
        transition: "0.2s all ease-in-out",
        ...style,
      }}
    >
      <BottomBarHeader canClick={!!onHeaderClick} onClick={onHeaderClick}>
        <SegmentControl
          onSelect={onSelect}
          segments={segments}
          value={currentTab}
          config={{
            backgroundColor: LAYER_COLORS.two,
            activeBackgroundColor: LAYER_COLORS.three,
            borderRadius: "10px",
            button: {
              fontSize: FONT_SIZE.one,
              px: SPACING.four,
              py: SPACING.two,
            },
          }}
        />
        {rightAccessories}
      </BottomBarHeader>
      <AnimatePresence exitBeforeEnter>
        <ContentContainer
          key={String(currentTab)}
          transition={{
            duration: 0.05,
            ease: "easeInOut",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {children}
        </ContentContainer>
      </AnimatePresence>
    </Container>
  );
}

export default BottomBar;
