import styled from "styled-components";
import { TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";

export const LabelText = styled.div<{ color?: string }>`
  ${FONT_CSS.data.three};
  color: ${({ color }) => color ?? TEXT_COLORS.one};
`;

export const SublabelText = styled.p<{ color?: string }>`
  ${FONT_CSS.label.three}
  margin: 0;
  margin-bottom: ${SPACING.one}px;
  color: ${({ color }) => color ?? TEXT_COLORS.three};
`;
