import styled, { css } from "styled-components";
import { LAYER_COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { TableWrapper } from "../shared/Table/style";

export const TradeHistoryTableWrapper = styled(TableWrapper)<{
  stickyHeader?: boolean;
}>`
  ${({ stickyHeader }) =>
    stickyHeader &&
    css`
      height: ${COMPONENTS.portfolioTableHeader}px;
      position: sticky;
      top: 0;
    `};

  height: 100%;
  overflow: auto !important;

  thead {
    position: sticky;
  }

  th,
  td {
    &:last-child {
      padding-left: ${SPACING.two}px;
      padding-right: ${SPACING.two}px;
      position: sticky;
      right: 0;
      z-index: 1;
    }
  }

  // Action buttons
  tbody td {
    background: ${LAYER_COLORS.one};
  }
`;

export const EmptyContent = styled.div`
  color: ${TEXT_COLORS.two};
  font-size: ${FONT_SIZE.two};
`;

export const Content = styled.div`
  padding: ${SPACING.three}px;
  color: ${TEXT_COLORS.three};
  font-size: ${FONT_SIZE.three};
`;

export const TimeAndDateCell = styled.span`
  color: ${TEXT_COLORS.two};
`;

export const ViewAllTradeHistoryCell = styled.td`
  padding: 0 ${SPACING.three}px !important;
  cursor: pointer;
  &:hover {
    background-color: transparent !important;
  }
`;
