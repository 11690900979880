import { CSSProperties, forwardRef, PropsWithChildren } from "react";
import { InputWrapper, SuffixWrapper } from "./styles";
// Should we separate inputs to string and number types?
// Reckon this would be better for different use cases
export interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  suffix?: JSX.Element;
  placeholder?: string;
  type?: string;
  leftAccessory?: JSX.Element;
  rightAccessory?: JSX.Element;
  wrapperStyles?: CSSProperties;
  inputStyles?: CSSProperties;
  error?: boolean;
}

export const Input = forwardRef<any, PropsWithChildren<IInputProps>>((
  {
    suffix,
    placeholder,
    type = "number",
    leftAccessory,
    rightAccessory,
    error,
    wrapperStyles,
    inputStyles,
    ...others
  },
  ref,
) => (
  <InputWrapper error={error} style={wrapperStyles}>
    {leftAccessory}
    <input
      step="any"
      placeholder={placeholder}
      type={type}
      {...others}
      ref={ref}
      autoComplete="off"
      onWheel={(e) => e.currentTarget.blur()}
      style={inputStyles}
    />
    <SuffixWrapper>{suffix}</SuffixWrapper>
    {rightAccessory}
  </InputWrapper>
));
