import Chart, { ChartData, ChartOptions } from "chart.js";
import currency from "currency.js";
import { WheelEvent, useCallback, useContext, useState } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { ReactComponent as MinusIcon } from "../../../assets/svg/minus.svg";
import { ReactComponent as PlusIcon } from "../../../assets/svg/plus.svg";
import { InstrumentTypeResponse } from "../../../codegen-api";
import {
  BORDER_COLORS,
  COLORS,
  MODAL_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_STYLE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";
import { MarketInstrumentContext } from "../../../contexts/MarketInstrumentContext";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";
import { AssetResponse } from "../../../utils/asset";
import { drawCircle, drawRoundRect } from "../../../utils/canvas";
import { getMidPrice } from "../../../utils/orderbook";
import {
  ChartContainer,
  Container,
  ContentContainer,
  HeaderContainer,
  LabelValue,
  ZoomControls,
} from "../style";

interface IDrawBidAskProps {
  price: number;
  size: number;
  totalCost: number;
  x: number;
  y: number;
}

/**
 * Array of 3 elements, price in USD, cumulative contracts size, and total costs, respectively  eg. ["2", "100", "200"]
 */
type IDepthData = string[][];
interface IDepthChartProps {
  bids: IDepthData;
  asks: IDepthData;
  asset: AssetResponse;
  instrumentType: InstrumentTypeResponse;
}

const MAX_ZOOM = 0.5;

// const mockBids = [
//   ["22.8", "3640"],
//   ["22.7", "18110"],
//   ["22.6", "37950"],
//   ["22.5", "44920"],
//   ["22.4", "50440"],
//   ["22.3", "54280"],
//   ["22.2", "57780"],
//   ["22.1", "58650"],
//   ["22.0", "63140"],
//   ["21.9", "64590"],
//   ["21.8", "66230"],
//   ["21.7", "67730"],
//   ["21.6", "70520"],
//   ["21.5", "72340"],
//   ["21.4", "76420"],
//   ["21.3", "77690"],
//   ["21.2", "78130"],
// ];

// const mockAsks = [
//   ["22.9", "12180"],
//   ["23", "34400"],
//   ["23.1", "42920"],
//   ["23.2", "53470"],
//   ["23.3", "63320"],
//   ["23.4", "75400"],
//   ["23.5", "86780"],
//   ["23.6", "97960"],
//   ["23.7", "110730"],
//   ["23.8", "135120"],
//   ["23.9", "143000"],
//   ["24.0", "168020"],
//   ["24.1", "169960"],
//   ["24.2", "175550"],
//   ["24.3", "182660"],
//   ["24.4", "186070"],
//   ["24.5", "196700"],
// ];

function DepthChart({ bids, asks, asset, instrumentType }: IDepthChartProps) {
  const [zoom, setZoom] = useState(0);
  const { getMarketPrecision } = useContext(MarketInstrumentContext);
  const { isMobileScreen } = useScreenSize();
  const { t } = useTranslation("app", { keyPrefix: "DepthChart" });

  const precision = useCallback(
    () => getMarketPrecision(asset, instrumentType),
    [asset, getMarketPrecision, instrumentType]
  );

  const [hoveredIndex, setHoveredIndex] = useState<
    | {
        bidIndex?: number;
        askIndex?: number;
      }
    | undefined
  >(undefined);

  /**
   * bids and asks are both sorted asc by price
   * if one side is empty, the maxLength should NOT be zero
   */
  const maxLength =
    !bids.length || !asks.length
      ? Math.max(bids.length, asks.length)
      : Math.floor(Math.min(bids.length, asks.length) * (1 - zoom));

  let sortedBids = bids.sort((a, b) => Number(a[0]) - Number(b[0]));

  // Cumulative size and total costs
  sortedBids = sortedBids
    .reverse()
    .reduce((arr, [price, size, totalCost], index) => {
      const prevSize =
        arr.length && index > 0 ? Number(arr[index - 1][1]) || 0 : 0;
      const currentSize = String(Number(size) + Number(prevSize));
      const currentTotalCosts =
        Number(totalCost) || Number(price) * Number(size);
      const prevTotalCost =
        arr.length && index > 0
          ? // If no prev total cost, calculate by multiplying price and size
            Number(arr[index - 1][2]) || 0
          : 0;
      const currentTotalCost = String(currentTotalCosts + prevTotalCost);
      return [...arr, [price, currentSize, currentTotalCost]];
    }, [] as IDepthData)
    .reverse();

  let sortedAsks = asks.sort((a, b) => Number(a[0]) - Number(b[0]));

  // Cumulative size and total costs
  sortedAsks = sortedAsks.reduce((arr, [price, size, totalCost], index) => {
    const prevSize =
      arr.length && index > 0 ? Number(arr[index - 1][1]) || 0 : 0;
    const currentSize = String(Number(size) + Number(prevSize));
    const currentTotalCosts = Number(totalCost) || Number(price) * Number(size);
    const prevTotalCost =
      arr.length && index > 0
        ? // If no prev total cost, calculate by multiplying price and size
          Number(arr[index - 1][2]) || 0
        : 0;
    const currentTotalCost = String(currentTotalCosts + prevTotalCost);
    return [...arr, [price, currentSize, currentTotalCost]];
  }, [] as IDepthData);

  sortedBids = sortedBids.slice(sortedBids.length - maxLength);
  sortedAsks = sortedAsks.slice(0, maxLength);

  const midPrice = getMidPrice(sortedBids, sortedAsks);

  const labels = [
    ...sortedBids.map((v) => Number(v[0])),
    midPrice,
    ...sortedAsks.map((v) => Number(v[0])),
  ];

  const bidsData: number[] = [
    ...sortedBids.map((v) => Number(v[1])),
    0,
    ...Array(sortedAsks.length).fill(null),
  ];
  const asksData: number[] = [
    ...Array(sortedBids.length).fill(null),
    0,
    ...sortedAsks.map((v) => Number(v[1])),
  ];
  const bidsTotalValueData: number[] = [
    ...sortedBids.map((v) => Number(v[2])),
    0,
    ...Array(sortedAsks.length).fill(null),
  ];
  const asksTotalValueData: number[] = [
    ...Array(sortedBids.length).fill(null),
    0,
    ...sortedAsks.map((v) => Number(v[2])),
  ];
  const hoveredData = Array(bidsData.length).fill(null);
  const hoveredTotalCostsData = Array(bidsData.length).fill(null);

  if (hoveredIndex?.bidIndex || hoveredIndex?.bidIndex === 0) {
    hoveredData[hoveredIndex.bidIndex] = bidsData[hoveredIndex.bidIndex];
    hoveredTotalCostsData[hoveredIndex.bidIndex] =
      bidsTotalValueData[hoveredIndex.bidIndex];
  }
  if (hoveredIndex?.askIndex || hoveredIndex?.askIndex === 0) {
    hoveredData[hoveredIndex.askIndex] = asksData[hoveredIndex.askIndex];
    hoveredTotalCostsData[hoveredIndex.askIndex] =
      asksTotalValueData[hoveredIndex.askIndex];
  }

  const smallestPrice = Number(sortedBids[0]?.[0]); // Math.min(...sortedBids.map((v) => Number(v[0])));
  const highestPrice = Number(sortedAsks[sortedAsks.length - 1]?.[0]); // Math.max(...sortedAsks.map((v) => Number(v[0])));
  const minX = smallestPrice;
  const maxX = highestPrice;

  const validBids = sortedBids.filter((v) => Number(v[0]) >= minX);
  const validAsks = sortedAsks.filter((v) => Number(v[0]) <= maxX);

  const volumes = [
    ...validBids.map((v) => Number(v[1])),
    ...validAsks.map((v) => Number(v[1])),
  ];

  const minY = Math.min(...volumes);
  const maxY = Math.max(...volumes);

  const y: Chart.TickOptions = {
    min: minY,
    max: maxY,
    maxTicksLimit: 5,
    maxRotation: 0,
    fontFamily: "BaseFont",
    fontSize: 13,
    callback(value) {
      return Number(value).toFixed(precision().amount_precision) || "";
    },
  };
  const x: Chart.TickOptions = {
    min: minX,
    max: maxX,
    maxTicksLimit: 5,
    maxRotation: 0,
    fontFamily: "BaseFont",
    fontSize: 13,
    callback(value) {
      return currency(value, {
        precision: precision().price_precision,
      }).format();
    },
  };

  const options: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    animation: { duration: 0 },
    legend: { display: false },
    layout: {
      padding: {
        right: SPACING.two,
      },
    },
    scales: {
      yAxes: [
        {
          display: true,
          position: "right",
          ticks: y,
          gridLines: {
            color: "transparent",
          },
        },
      ],
      xAxes: [
        {
          display: true,
          ticks: x,
          gridLines: {
            color: "transparent",
          },
        },
      ],
    },
    hover: { animationDuration: 0, intersect: false },
    onHover: (_: any, elements: any) => {
      if (elements && elements.length) {
        const { _index } = elements[0];
        const middleIndex = sortedBids.length;

        // Dont hover on middle point, its zero
        if (_index === middleIndex) {
          return;
        }

        let bidIndex = _index;
        // let askIndex = bidsData.length - _index - 1;
        let askIndex;
        if (_index > middleIndex) {
          bidIndex = undefined;
          // bidIndex = bidsData.length - _index - 1;
          askIndex = _index;
        }

        setHoveredIndex({
          bidIndex,
          askIndex,
        });
        return;
      }

      setHoveredIndex(undefined);
    },
  };

  const getData = (canvas: any): ChartData => {
    const ctx = canvas.getContext("2d");
    const green = ctx.createLinearGradient(0, 0, 0, 2000);
    green.addColorStop(0, COLORS.positive.three);
    green.addColorStop(0.3, "transparent");
    const red = ctx.createLinearGradient(0, 0, 0, 2000);
    red.addColorStop(0, COLORS.negative.three);
    red.addColorStop(0.3, "transparent");

    return {
      labels,
      datasets: [
        {
          label: "bids",
          data: bidsData,
          type: "line",
          pointRadius: 0,
          pointHoverRadius: 0,
          borderDash: undefined,
          borderWidth: 1,
          borderColor: COLORS.positive.one,
          backgroundColor: green,
          steppedLine: "after",
        },
        {
          label: "asks",
          data: asksData,
          type: "line",
          pointRadius: 0,
          pointHoverRadius: 0,
          borderDash: undefined,
          borderWidth: 1,
          borderColor: COLORS.negative.one,
          backgroundColor: red,
          steppedLine: "before",
        },
        {
          label: "hovered",
          data: hoveredData,
          type: "line",
          pointRadius: 0,
          pointHoverRadius: 0,
          borderDash: undefined,
        },
        {
          label: "hoveredTotalCostsData",
          data: hoveredTotalCostsData,
          type: "line",
          pointRadius: 0,
          pointHoverRadius: 0,
          borderDash: undefined,
        },
      ],
    };
  };

  const drawLabel = (
    chart: any,
    ctx: CanvasRenderingContext2D,
    drawX: number,
    drawY: number,
    values: {
      label: string;
      value: string;
    }[],
    side: "left" | "right"
  ) => {
    const valueHeight = 20;

    // Draw container
    const rectPadding = SPACING.three;
    const rectHeight =
      rectPadding * 2 + values.length * (valueHeight + SPACING.two);
    const rectWidth = 240;
    const rectX =
      side === "left" ? drawX - rectWidth - SPACING.two : drawX + SPACING.two;
    let rectY = drawY - rectHeight / 2;
    if (rectY < SPACING.two) {
      rectY = SPACING.two;
    } else if (rectY + rectHeight + SPACING.two > chart.chart.height) {
      rectY = chart.chart.height - SPACING.two - rectHeight;
    }

    ctx.save();
    ctx.fillStyle = MODAL_COLORS.one;
    ctx.strokeStyle = BORDER_COLORS.one;
    ctx.beginPath();
    drawRoundRect(
      ctx,
      rectX,
      rectY,
      rectWidth,
      rectHeight,
      {
        upperLeft: 8,
        upperRight: 8,
        lowerLeft: 8,
        lowerRight: 8,
      },
      true
    );
    ctx.restore();

    // Draw label : value
    let labelY = rectY + rectPadding;
    const labelX = rectX + rectPadding;
    values.forEach(({ label, value }) => {
      ctx.save();
      ctx.textBaseline = "top";

      ctx.font = `${FONT_STYLE.label.two.fontSize} BaseFont`;
      ctx.fillStyle = TEXT_COLORS.three;
      ctx.textAlign = "left";
      ctx.fillText(label, labelX, labelY);

      // const valueWidth = ctx.measureText(value).width;
      const valueX = rectX + rectWidth - rectPadding;
      ctx.font = `${FONT_STYLE.data.three.fontSize} BaseFont`;
      ctx.fillStyle = TEXT_COLORS.one;
      ctx.textAlign = "right";
      ctx.fillText(value, valueX, labelY);

      labelY += valueHeight + SPACING.three;

      ctx.restore();
    });
  };

  const drawPricePoint = (
    chart: any,
    bidAsk: IDrawBidAskProps,
    priceImpact: number,
    type: "bid" | "ask"
  ) => {
    // eslint-disable-next-line prefer-destructuring
    const ctx: CanvasRenderingContext2D = chart.chart.ctx;

    const { top, bottom, right, left } = chart.chartArea;

    // Draw bid+ask line
    ctx.save();
    ctx.setLineDash([5, 5]);
    ctx.lineWidth = 1;
    ctx.strokeStyle =
      type === "bid" ? COLORS.positive.two : COLORS.negative.two;
    ctx.globalCompositeOperation = "source-over";

    ctx.beginPath();
    ctx.moveTo(bidAsk.x, top);
    ctx.lineTo(bidAsk.x, bottom);
    ctx.moveTo(left, bidAsk.y);
    ctx.lineTo(right, bidAsk.y);
    ctx.stroke();

    ctx.restore();

    // Draw bid+ask dot
    const circleColorPrimary =
      type === "bid" ? COLORS.positive.one : COLORS.negative.one;
    const circleColorSecondary =
      type === "bid" ? COLORS.positive.three : COLORS.negative.three;

    drawCircle(
      ctx,
      bidAsk.x,
      bidAsk.y,
      2,
      circleColorPrimary,
      circleColorPrimary,
      0
    );

    drawCircle(
      ctx,
      bidAsk.x,
      bidAsk.y,
      4,
      circleColorSecondary,
      circleColorSecondary,
      0
    );

    // Draw price/volume
    ctx.save();
    ctx.textBaseline = "top";

    ctx.font = `${FONT_STYLE.data.three.fontSize} BaseFont`;
    ctx.fillStyle = circleColorPrimary;
    ctx.textAlign = "center";
    ctx.fillText(
      currency(bidAsk.price, {
        precision: precision().price_precision,
      }).format(),
      bidAsk.x,
      chart.chartArea.bottom + 12
    );

    ctx.textAlign = "left";
    ctx.fillText(
      bidAsk.size.toFixed(precision().amount_precision),
      chart.chartArea.right + 10,
      bidAsk.y - 6
    );

    ctx.restore();

    drawLabel(
      chart,
      ctx,
      bidAsk.x,
      bidAsk.y,
      [
        {
          label: t("price"),
          value: currency(bidAsk.price, {
            precision: precision().price_precision,
          }).format(),
        },
        {
          label: `${type === "bid" ? t("buy") : t("sell")} ${t(
            "size"
          )} (${asset})`,
          value: currency(bidAsk.size, { symbol: "" }).format(),
        },
        {
          label: t("total_cost"),
          value: currency(bidAsk.totalCost, {
            precision: precision().price_precision,
          }).format(),
        },
        {
          label: t("price_impact"),
          value: `${(priceImpact * 100).toFixed(2)}%`,
        },
      ],
      type === "bid" ? "right" : "left"
    );
  };

  const onToggleZoom = useCallback((zoomIn: boolean) => {
    setZoom((z) => {
      const newZoom = zoomIn ? z + 0.025 : z - 0.025;
      if (newZoom < 0) {
        return 0;
      }
      if (newZoom > MAX_ZOOM) {
        return MAX_ZOOM;
      }
      return newZoom;
    });
  }, []);

  const onWheel = useCallback(
    (e: WheelEvent<HTMLDivElement>) => {
      const { deltaY } = e;
      if (deltaY > 0) {
        onToggleZoom(true);
      } else if (deltaY < 0) {
        onToggleZoom(false);
      }
    },
    [onToggleZoom]
  );

  return (
    <Container>
      <HeaderContainer>
        <LabelValue>
          <span>{t("mid_market_price")}</span>
          <span>{currency(midPrice).format()}</span>
        </LabelValue>
        <ZoomControls>
          <button type="button" onClick={() => onToggleZoom(false)}>
            <MinusIcon />
          </button>
          <span>{(zoom * 100).toFixed(2)}%</span>
          <button type="button" onClick={() => onToggleZoom(true)}>
            <PlusIcon />
          </button>
        </ZoomControls>
      </HeaderContainer>
      <ContentContainer>
        <ChartContainer onWheel={onWheel} isMobileScreen={isMobileScreen}>
          <Line
            type="line"
            data={getData}
            options={options}
            plugins={[
              {
                afterDraw: (c: any) => {
                  // Draw lines when hovered
                  const hoveredDatasetIndex = c.chart.data.datasets.findIndex(
                    (dataset: any) => dataset.label === "hovered"
                  );
                  const hoveredDataset: (number | null)[] =
                    c.chart.data.datasets[hoveredDatasetIndex].data;

                  const bidsDatasetIndex = c.chart.data.datasets.findIndex(
                    (dataset: any) => dataset.label === "bids"
                  );
                  const bidsDataset: (number | null)[] =
                    c.chart.data.datasets[bidsDatasetIndex].data;

                  const [hoveredDataIndex] = hoveredDataset
                    .filter((v) => v !== null)
                    .map((v) => hoveredDataset.indexOf(v));

                  if (hoveredDataIndex !== undefined) {
                    const { labels: chartLabels } = c.chart.data;
                    const price = chartLabels[hoveredDataIndex];
                    const size = hoveredDataset[hoveredDataIndex];

                    const hoveredTotalCostsDatasetIndex =
                      c.chart.data.datasets.findIndex(
                        (dataset: any) =>
                          dataset.label === "hoveredTotalCostsData"
                      );
                    const hoveredTotalCostsDataset: (number | null)[] =
                      c.chart.data.datasets[hoveredTotalCostsDatasetIndex].data;
                    const totalCosts =
                      hoveredTotalCostsDataset[hoveredDataIndex];

                    // The first 0 in the bids dataset is the middle point (where bid and ask meets at 0)
                    const midIndex = bidsDataset.indexOf(0);

                    // Calculate price impact
                    const chartMidPrice = chartLabels[midIndex];
                    const priceImpact =
                      Math.abs(price - chartMidPrice) / chartMidPrice;

                    const type = hoveredDataIndex > midIndex ? "ask" : "bid";

                    const metadata =
                      c.chart.getDatasetMeta(hoveredDatasetIndex);
                    const { x: hoverX, y: hoverY } =
                      // eslint-disable-next-line no-underscore-dangle
                      metadata.data[hoveredDataIndex]._view;

                    drawPricePoint(
                      c,
                      {
                        price,
                        x: hoverX,
                        y: hoverY,
                        size: size || 0,
                        totalCost: totalCosts || 0,
                      },
                      priceImpact,
                      type
                    );
                  }
                },
              },
            ]}
          />
        </ChartContainer>
      </ContentContainer>
    </Container>
  );
}

export default DepthChart;
