import { useTranslation } from "react-i18next";
import { Container, TextContainer } from "./style";
import Toggle from "../Toggle";
import { useYieldVault } from "../../../hooks/api/yieldVault/useYieldVault";
import { COLORS } from "../../../constants/design/colors";
import ExternalLinkText from "../../ExternalLinkText";

interface IYieldVaultToggleProps {
  isOn: boolean;
  onToggle: () => void;
}

function YieldVaultToggle({ isOn, onToggle }: IYieldVaultToggleProps) {
  const { t } = useTranslation("app", { keyPrefix: "shared.Toggle" });
  const { data: yieldVaultData } = useYieldVault();

  return (
    <Container isOn={isOn}>
      <TextContainer>
        <span>
          {t("yield_description", {
            apy: yieldVaultData?.apy
              ? `${Number(yieldVaultData.apy).toFixed(2)}%`
              : "---",
          })}
        </span>
        <ExternalLinkText
          href="https://docs.aevo.xyz/aevo-exchange/aeusd"
          target="_blank"
          rel="noreferrer"
          color={COLORS.highlight.two}
          wrapperStyle={{
            fontSize: 10,
          }}
        >
          {t("learn_more")}
        </ExternalLinkText>
      </TextContainer>
      <Toggle
        isOn={isOn}
        onToggle={onToggle}
        positiveText="yes"
        negativeText="no"
      />
    </Container>
  );
}

export default YieldVaultToggle;
