import styled, { css } from "styled-components";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../constants/design/spacing";

export const OverlayInfo = styled.div<{ height?: string; noPadding?: boolean }>`
  background-color: ${LAYER_COLORS.two};
  opacity: 0.95;
  height: ${({ height }) => height || "360px"};
  border-radius: 10px;
  padding: ${({ noPadding }) => (noPadding ? 0 : SPACING.three)}px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.4s ease-in-out;
`;

export const TappableContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`;

export const Scrim = styled.div<{ show?: boolean }>`
  position: absolute;
  z-index: 2;
  background: ${COLORS.black.two};
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  transition: all 0.2s ease-in-out;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? "unset" : "none")};

  ${OverlayInfo} {
    ${({ show }) => {
      if (!show) {
        return css`
          transform: translateY(10%);
        `;
      }
      return "";
    }}
  }
`;

export const CloseContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${SPACING.three}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseInfoButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${LAYER_COLORS.three};
  color: ${TEXT_COLORS.one};
  border-radius: ${BORDER_RADIUS.five}px;
  padding: ${SPACING.two}px;
  min-width: ${COMPONENTS.modalButton}px;
  min-height: ${COMPONENTS.modalButton}px;

  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 0px 1px ${COLORS.white.one} inset;
  }
`;

export const OverlayInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${FONT_CSS.body.three}
  color: ${TEXT_COLORS.two};
  height: calc(100% - ${COMPONENTS.modalButton}px);
  width: 100%;
  overflow: scroll;

  > * {
    &:first-child {
      ${FONT_CSS.header.seven}
      color: ${TEXT_COLORS.one};
      margin-bottom: ${SPACING.three}px;
    }
  }
`;
