/* eslint-disable react/prop-types */
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import currency from "currency.js";
import { useTable, useSortBy } from "react-table";
import { useNavigate } from "react-router-dom";
import {
  LeaderboardTableRow,
  LeaderboardTableWrapper,
  LeaderboardHeader,
  BackButtonWrapper,
  LogoContainer,
  SOLVideoContainer,
  DescriptionContainer,
  Countdown,
  CountdownContainer,
  Step,
  StepBox,
  SignedUpWrapper,
  Textbox,
  StickyWrapper,
} from "./style";
import BackgroundVideoFX from "../../components/shared/BackgroundVideoFX/BackgroundVideoFX";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { useGetLeaderboard } from "../../hooks/api/leaderboard/useGetLeaderboard";
import { GetLeaderboard200ResponseLeaderboardDaily } from "../../codegen-api";
import { ITableColumn } from "../../interfaces/Table/TableColumn";
import { EmptyContentContainer } from "../../components/PortfolioSettings/Table/style";
import { SpinnerContainerWrapper } from "../../components/shared/Spinner/style";
import { DefaultCellForColumn } from "../../components/shared/Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../components/shared/Table/DefaultHeaderForColumn";
import { Align, TableHeaderCell } from "../../components/shared/Table/style";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import useScreenSize from "../../hooks/screenSize/useScreenSize";
import { Spinner } from "../../components/shared/Spinner";
import { getProfitTextColor } from "../../utils/strings";
import { useGetAccountStatistics } from "../../hooks/api/accountStatistics/useGetAccountStatistics";
import { AccountStateEnum, AuthContext } from "../../contexts/AuthContext";
import { Button, ButtonThemeEnum } from "../../components/Buttons/styles";
import { ReactComponent as LeftArrow } from "../../assets/svg/arrow-left.svg";
import { getAssetLogo } from "../../utils/asset/assets";
import { ReactComponent as Info } from "../../assets/svg/info.svg";
import TermsModal from "./TermsModal";
import { PageEndpointEnum } from "../../enums/endpoint";
import { useCountdown } from "./useCountdown";
import {
  CampaignEnum,
  ICampaignDetail,
  wsbEpoch1EndTime,
  wsbEpoch1StartTime,
  wsbEpoch2EndTime,
  wsbEpoch2StartTime,
} from ".";
import {
  Body,
  Data,
  FONT_SIZE,
  Header,
  Label,
} from "../../constants/design/fontSize";
import { FlexWrapper, SPACING } from "../../constants/design/spacing";
import { useSignUpForCampaign } from "../../hooks/api/leaderboard/useSignUpForCampaign";
import { ConnectWalletContext } from "../../contexts/ConnectWalletContext";
import { ReactComponent as Check } from "../../assets/svg/check.svg";
import SegmentControl from "../../components/shared/SegmentedControl";
import { Divider } from "../../components/shared/Divider";
import { useToast } from "../../hooks/toast";
import { ToastStatusEnum, ToastEnum } from "../../utils/toast";
import { LocalStorageKeyEnum } from "../../enums/localStorage";

enum LeaderboardFilterEnum {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  ALL_TIME = "all_time",
}

enum LeaderboardTypeEnum {
  PNL = "pnl",
  VOLUME = "perp_volume",
}

interface IWSBCampaignProps {
  campaignDetails: ICampaignDetail;
}

function WSBCampaign({ campaignDetails }: IWSBCampaignProps) {
  const { data: accountData, mutate: mutateAccount } = useGetAccount();
  const { isMobileScreen } = useScreenSize();
  const { t: campaignTranslations } = useTranslation("app", {
    keyPrefix: "pages.CampaignsPage",
  });
  const { t } = useTranslation("app", {
    keyPrefix: "pages.CampaignsPage.WSBCampaign",
  });
  const { setShowConnectModal } = useContext(ConnectWalletContext);

  const { campaignName, epoch } = campaignDetails;
  const localStorageCampaignName = window.localStorage.getItem(
    LocalStorageKeyEnum.CAMPAIGN_NAME
  );

  const [campaignToJoin, setCampaignToJoin] = useState<string | undefined>(
    localStorageCampaignName || undefined
  );
  const [selectedEpoch, setSelectedEpoch] = useState<number>(epoch);
  const [loading, setLoading] = useState<boolean>(false);
  const { addToast, addErrorToast } = useToast();
  const { data: e1PnlLeaderboard, isValidating: e1PnlLeaderboardLoading } =
    useGetLeaderboard({
      limit: 10,
      campaignName,
      startTime: wsbEpoch1StartTime,
      endTime: wsbEpoch1EndTime,
    });

  const { data: e2PnlLeaderboard, isValidating: e2PnlLeaderboardLoading } =
    useGetLeaderboard({
      limit: 10,
      campaignName,
      startTime: wsbEpoch2StartTime,
      endTime: wsbEpoch2EndTime,
    });

  const pnlLeaderboard =
    selectedEpoch === 1 ? e1PnlLeaderboard : e2PnlLeaderboard;
  const pnlLeaderboardLoading =
    selectedEpoch === 1 ? e1PnlLeaderboardLoading : e2PnlLeaderboardLoading;

  const {
    data: e1VolumeLeaderboard,
    isValidating: e1VolumeLeaderboardLoading,
  } = useGetLeaderboard({
    limit: 10,
    sort: "PerpVolume",
    campaignName,
    startTime: wsbEpoch1StartTime,
    endTime: wsbEpoch1EndTime,
  });

  const {
    data: e2VolumeLeaderboard,
    isValidating: e2VolumeLeaderboardLoading,
  } = useGetLeaderboard({
    limit: 10,
    sort: "PerpVolume",
    campaignName,
    startTime: wsbEpoch2StartTime,
    endTime: wsbEpoch2EndTime,
  });

  const volumeLeaderboard =
    selectedEpoch === 1 ? e1VolumeLeaderboard : e2VolumeLeaderboard;
  const volumeLeaderboardLoading =
    selectedEpoch === 1
      ? e1VolumeLeaderboardLoading
      : e2VolumeLeaderboardLoading;

  const {
    data: e1AccountStatistics,
    isValidating: e1AccountStatisticsLoading,
  } = useGetAccountStatistics({
    campaignName,
    startTime: wsbEpoch1StartTime,
    endTime: wsbEpoch1EndTime,
  });

  const {
    data: e2AccountStatistics,
    isValidating: e2AccountStatisticsLoading,
  } = useGetAccountStatistics({
    campaignName,
    startTime: wsbEpoch2StartTime,
    endTime: wsbEpoch2EndTime,
  });

  const accountStatistics =
    selectedEpoch === 1 ? e1AccountStatistics : e2AccountStatistics;
  const accountStatisticsLoading =
    selectedEpoch === 1
      ? e1AccountStatisticsLoading
      : e2AccountStatisticsLoading;

  const { accountApiKeyState } = useContext(AuthContext);
  const { signUpForCampaign } = useSignUpForCampaign();
  const navigate = useNavigate();

  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
  const [leaderboardType, setLeaderboardType] = useState<LeaderboardTypeEnum>(
    LeaderboardTypeEnum.VOLUME
  );
  const countdownMapping = useCountdown();
  const { days, hours, minutes, seconds, isLive, isUpcoming } =
    countdownMapping[CampaignEnum.WSB];

  const isYou = useCallback(
    (username: string) =>
      accountData?.username
        ? accountData.username.toLowerCase() === username.toLowerCase()
        : false,
    [accountData]
  );

  const accountAllTimeData = useMemo(() => {
    const accountStats = accountStatistics?.account_statistics;
    const allTimeData = accountStats?.all_time;
    if (allTimeData) {
      return allTimeData;
    }
    return undefined;
  }, [accountStatistics?.account_statistics]);

  const accountFilterData = useMemo(() => {
    const accountStats = accountStatistics?.account_statistics;
    const filteredData = accountStats?.[LeaderboardFilterEnum.ALL_TIME];
    if (filteredData) {
      return filteredData;
    }
    return undefined;
  }, [accountStatistics?.account_statistics]);

  const memoizedPnlLeaderboard = useMemo(() => {
    if (
      !pnlLeaderboard?.leaderboard ||
      (accountApiKeyState === AccountStateEnum.OK && !accountAllTimeData)
    ) {
      return [];
    }

    let filteredLeaderboard = (
      pnlLeaderboard?.leaderboard
        ? pnlLeaderboard?.leaderboard[LeaderboardFilterEnum.ALL_TIME]
        : []
    ) as GetLeaderboard200ResponseLeaderboardDaily[];

    const username = accountFilterData?.username;
    if (!username) {
      return filteredLeaderboard;
    }
    if (accountFilterData) {
      // Check if the username exists in filteredLeaderboard
      const userInLeaderboard = filteredLeaderboard.find(
        (entry) => entry.username === username
      );

      // if it doesnt exist add to top of table
      if (!userInLeaderboard) {
        filteredLeaderboard = [accountFilterData, ...filteredLeaderboard];
      }
    }
    return filteredLeaderboard;
  }, [
    accountAllTimeData,
    accountApiKeyState,
    accountFilterData,
    pnlLeaderboard?.leaderboard,
  ]);

  const memoizedVolumeLeaderboard = useMemo(() => {
    if (
      !volumeLeaderboard?.leaderboard ||
      (accountApiKeyState === AccountStateEnum.OK && !accountAllTimeData)
    )
      return [];

    let filteredLeaderboard = (
      volumeLeaderboard?.leaderboard
        ? volumeLeaderboard?.leaderboard[LeaderboardFilterEnum.ALL_TIME]
        : []
    ) as GetLeaderboard200ResponseLeaderboardDaily[];
    const username = accountFilterData?.username;
    if (!username) {
      return filteredLeaderboard;
    }
    if (accountFilterData) {
      // Check if the username exists in filteredLeaderboard
      const userInLeaderboard = filteredLeaderboard.find(
        (entry) => entry.username === username
      );

      // if it doesnt exist add to top of table
      if (!userInLeaderboard) {
        filteredLeaderboard = [accountFilterData, ...filteredLeaderboard];
      }
    }
    return filteredLeaderboard;
  }, [
    accountAllTimeData,
    accountApiKeyState,
    accountFilterData,
    volumeLeaderboard?.leaderboard,
  ]);

  const campaignSignUp = useCallback(async () => {
    try {
      setLoading(true);
      await signUpForCampaign(campaignName);

      mutateAccount();
      addToast(
        {
          header: t("success"),
          status: ToastStatusEnum.SUCCESS,
          type: ToastEnum.SIMPLE,
        },
        1000
      );
    } catch (error: any) {
      addErrorToast(t("error"), error.message);
    } finally {
      setLoading(false);
      setCampaignToJoin(undefined);
      window.localStorage.removeItem(LocalStorageKeyEnum.CAMPAIGN_NAME);
    }
  }, [
    addErrorToast,
    addToast,
    campaignName,
    mutateAccount,
    signUpForCampaign,
    t,
  ]);

  useEffect(() => {
    if (accountApiKeyState === AccountStateEnum.OK && campaignToJoin) {
      // wait 1 second so not ratelimited
      setCampaignToJoin(undefined);
      setTimeout(() => {
        campaignSignUp();
      }, 2000);
    }
  }, [accountApiKeyState, campaignSignUp, campaignToJoin, loading]);

  const columns: ITableColumn<GetLeaderboard200ResponseLeaderboardDaily>[] =
    useMemo(() => {
      const cols: ITableColumn<GetLeaderboard200ResponseLeaderboardDaily>[] = [
        {
          title: campaignTranslations("rank"),
          accessor: "ranking",
          disableSortBy: true,
          Cell: ({ value, row }: any) => (
            <Align
              align={"left"}
              style={{
                color: isYou(row.original.username || "")
                  ? COLORS.blue.one
                  : TEXT_COLORS.three,
              }}
            >
              {value}
            </Align>
          ),
        },
        {
          title: campaignTranslations("trader"),
          accessor: "username",
          disableSortBy: true,
          Cell: ({ value, row }: any) => (
            <Align
              align={"left"}
              style={{
                color: isYou(row.original.username || "")
                  ? COLORS.blue.one
                  : TEXT_COLORS.two,
              }}
            >
              {value}
              {isYou(value || "") ? ` (${campaignTranslations("you")})` : ""}
            </Align>
          ),
        },
      ];

      if (leaderboardType === LeaderboardTypeEnum.PNL) {
        cols.push({
          title: campaignTranslations("pnl"),
          accessor: "pnl",
          align: "right",
          Cell: ({ row, value }: any) => (
            <Align
              align={"right"}
              style={{
                color: isYou(row.original.username || "")
                  ? COLORS.blue.one
                  : getProfitTextColor(Number(value)),
              }}
            >
              {currency(value).format()}
            </Align>
          ),
        });
      } else {
        cols.push({
          title: campaignTranslations("perp_volume"),
          accessor: "perp_volume",
          align: "right",
          Cell: ({ value, row }: any) => (
            <Align
              align={"right"}
              style={{
                color: isYou(row.original.username || "")
                  ? COLORS.blue.one
                  : TEXT_COLORS.one,
              }}
            >
              {currency(value).format()}
            </Align>
          ),
        });
      }
      return cols;
    }, [campaignTranslations, isYou, leaderboardType]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data:
          leaderboardType === LeaderboardTypeEnum.PNL
            ? memoizedPnlLeaderboard
            : memoizedVolumeLeaderboard,
        defaultColumn: {
          Header: DefaultHeaderForColumn,
          Cell: DefaultCellForColumn,
        },
        autoResetSortBy: false,
      } as any,
      useSortBy
    );

  const actionButton = useMemo(() => {
    if (accountApiKeyState !== AccountStateEnum.OK) {
      return (
        <Button
          fullWidth
          buttonTheme={ButtonThemeEnum.PRIMARY}
          style={{ height: 48 }}
          onClick={() => {
            setCampaignToJoin(campaignName);
            setShowConnectModal(true);
          }}
        >
          {t("join")}
        </Button>
      );
    }
    if (!(accountData?.campaign_sign_ups || []).includes(campaignName)) {
      return (
        <Button
          fullWidth
          buttonTheme={ButtonThemeEnum.PRIMARY}
          style={{ height: 48 }}
          onClick={() => campaignSignUp()}
          disabled={loading}
        >
          {loading ? t("joining") : t("join")}
        </Button>
      );
    }
    return (
      <Button
        fullWidth
        buttonTheme={ButtonThemeEnum.PRIMARY}
        style={{ height: 48, pointerEvents: "none" }}
        onClick={() => {}}
      >
        <SignedUpWrapper gap={4}>
          <div>{t("joined")}</div>
          <Check color={TEXT_COLORS.one} />
        </SignedUpWrapper>
      </Button>
    );
  }, [
    accountApiKeyState,
    accountData?.campaign_sign_ups,
    campaignName,
    campaignSignUp,
    loading,
    setCampaignToJoin,
    setShowConnectModal,
    t,
  ]);

  return (
    <div>
      <TermsModal
        show={showTermsModal}
        onHide={() => setShowTermsModal(false)}
      />
      <BackButtonWrapper
        onClick={() => navigate(PageEndpointEnum.CAMPAIGNS)}
        isMobileScreen={isMobileScreen}
      >
        <LeftArrow />
        <div>{t("all_campaigns")}</div>
      </BackButtonWrapper>
      <LeaderboardHeader isMobileScreen={isMobileScreen}>
        <>
          <SOLVideoContainer
            isMobileScreen={isMobileScreen}
            style={{ marginBottom: 0 }}
          >
            <BackgroundVideoFX />
          </SOLVideoContainer>
          <DescriptionContainer>
            <LogoContainer size={120} borderWidth={4} isLive={isLive}>
              <img
                src={getAssetLogo("EIGEN")}
                width={114}
                height={114}
                alt={"EIGEN"}
              />
            </LogoContainer>
            <FlexWrapper gap={SPACING.three} alignItems="center">
              <CountdownContainer>
                {isLive || isUpcoming ? (
                  <>
                    <Label number="three" color={TEXT_COLORS.three}>
                      {isLive
                        ? `${t("end_of_epoch")} ${selectedEpoch}`
                        : t("time_till_campaign_starts")}
                    </Label>
                    <Countdown>
                      <Data number="two" color={TEXT_COLORS.one}>
                        {days}
                      </Data>
                      <Label number="three" color={TEXT_COLORS.three}>
                        {campaignTranslations(
                          days > 1 ? "days_short" : "day_short"
                        )}
                      </Label>
                    </Countdown>
                    <Countdown>
                      <Data number="two" color={TEXT_COLORS.one}>
                        {hours}
                      </Data>
                      <Label number="three" color={TEXT_COLORS.three}>
                        {campaignTranslations(
                          hours > 1 ? "hours_short" : "hour_short"
                        )}
                      </Label>
                    </Countdown>
                    <Countdown>
                      <Data number="two" color={TEXT_COLORS.one}>
                        {minutes}
                      </Data>
                      <Label number="three" color={TEXT_COLORS.three}>
                        {campaignTranslations(
                          minutes > 1 ? "minutes_short" : "minute_short"
                        )}
                      </Label>
                    </Countdown>
                    {!isMobileScreen && (
                      <Countdown>
                        <Data number="two" color={TEXT_COLORS.one}>
                          {seconds}
                        </Data>
                        <Label number="three" color={TEXT_COLORS.three}>
                          {campaignTranslations(
                            seconds > 1 ? "seconds_short" : "second_short"
                          )}
                        </Label>
                      </Countdown>
                    )}
                  </>
                ) : (
                  <Label number="three" color={COLORS.highlight.one}>
                    {campaignTranslations("campaign_over")}
                  </Label>
                )}
              </CountdownContainer>
              {!isMobileScreen && (
                <div style={{ width: 160 }}>{actionButton}</div>
              )}
            </FlexWrapper>
          </DescriptionContainer>
        </>
      </LeaderboardHeader>
      <FlexWrapper
        flexColumn
        gap={SPACING.five}
        style={{ padding: SPACING.three }}
      >
        <FlexWrapper flexColumn gap={SPACING.three}>
          <Header number="five" color={TEXT_COLORS.one}>
            {t("title")}
          </Header>
          <Body number="three" color={TEXT_COLORS.two}>
            <Textbox>
              <span>{t("description_1")} </span>
              <span style={{ color: TEXT_COLORS.one, margin: 0 }}>
                {t("description_2")}
              </span>
              <span> {t("description_3")}</span>
            </Textbox>
          </Body>
          <Header number="seven" color={TEXT_COLORS.one}>
            {t("volume_leaderboard")}
          </Header>
          <Body number="three" color={TEXT_COLORS.two}>
            <div>
              <Textbox>
                <span>{t("volume_description_1")} </span>
                <span style={{ color: TEXT_COLORS.one, margin: 0 }}>
                  {t("volume_description_2")}{" "}
                </span>
                <span>{t("volume_description_3")}</span>
              </Textbox>
            </div>
          </Body>
          <Body number="three" color={TEXT_COLORS.two}>
            <div>
              <span>{t("rewards_per_epoch", { amount: "7,000" })}</span>
              <ul style={{ paddingLeft: "20px" }}>
                <li>{t("first_place", { amount: "1,500" })}</li>
                <li>{t("second_place", { amount: "1,000" })}</li>
                <li>{t("third_place", { amount: "1,000" })}</li>
                <li>{t("fourth_place", { amount: "500" })}</li>
              </ul>
            </div>
          </Body>
          <Header number="seven" color={TEXT_COLORS.one}>
            {t("pnl_leaderboard")}
          </Header>
          <Body number="three" color={TEXT_COLORS.two}>
            <div>
              <Textbox>
                <span>{t("pnl_description_1")} </span>
                <span style={{ color: TEXT_COLORS.one, margin: 0 }}>
                  {t("pnl_description_2")}{" "}
                </span>
                <span>{t("pnl_description_3")}</span>
              </Textbox>
            </div>
          </Body>
          <Body number="three" color={TEXT_COLORS.two}>
            <div>
              <span>{t("rewards_per_epoch", { amount: "5,500" })}</span>
              <ul style={{ paddingLeft: "20px" }}>
                <li>{t("first_place", { amount: "1,100" })}</li>
                <li>{t("second_place", { amount: "900" })}</li>
                <li>{t("third_place", { amount: "700" })}</li>
                <li>{t("fourth_place", { amount: "400" })}</li>
              </ul>
            </div>
          </Body>
          <Header number="seven" color={TEXT_COLORS.one}>
            {t("key_dates")}
          </Header>
          <Body number="three" color={TEXT_COLORS.two}>
            <div>
              <span>{t("epoch")} 1</span>
              <ul style={{ paddingLeft: "20px" }}>
                <li>{t("epoch_1_start_date")}</li>
                <li>{t("epoch_1_end_date")}</li>
              </ul>
            </div>
          </Body>
          <Body number="three" color={TEXT_COLORS.two}>
            <div>
              <span>{t("epoch")} 2</span>
              <ul style={{ paddingLeft: "20px" }}>
                <li>{t("epoch_2_start_date")}</li>
                <li>{t("epoch_2_end_date")}</li>
              </ul>
            </div>
          </Body>
          <Button
            buttonTheme={ButtonThemeEnum.NEUTRAL1}
            style={{
              color: TEXT_COLORS.two,
              fontSize: "12px",
              maxHeight: "32px",
              minHeight: "32px",
              width: "fit-content",
              gap: "4px",
            }}
            onClick={() => setShowTermsModal(true)}
            fullWidth={false}
          >
            <div>{t("rules")}</div>
            <Info />
          </Button>
        </FlexWrapper>
        <FlexWrapper flexColumn gap={SPACING.three}>
          <Body number="two">{t("qualify")}</Body>
          <Step>
            <StepBox>1</StepBox>
            <Textbox>
              <span>{t("sign_up_step_1_1")} </span>
              <span style={{ color: TEXT_COLORS.one, margin: 0 }}>
                {t("sign_up_step_1_2")}{" "}
              </span>
              <span>{t("sign_up_step_1_3")}</span>
            </Textbox>
          </Step>
          <Step>
            <StepBox>2</StepBox>
            <Textbox>
              <span style={{ color: TEXT_COLORS.one, margin: 0 }}>
                {t("sign_up_step_2_1")}
              </span>
              <span> {t("sign_up_step_2_2")}</span>
            </Textbox>
          </Step>
        </FlexWrapper>
        <LeaderboardTableWrapper>
          <FlexWrapper flexColumn gap={SPACING.three}>
            <Body number="two">
              {campaignTranslations("campaign_leaderboard")}
            </Body>
            <FlexWrapper
              gap={SPACING.three}
              flexColumn={isMobileScreen}
              style={{ alignItems: isMobileScreen ? "flex-start" : "center" }}
            >
              <SegmentControl
                config={{
                  backgroundColor: COLORS.neutral.six,
                  activeBackgroundColor: COLORS.neutral.five,
                  borderRadius: "10px",
                  button: {
                    height: 32,
                    fontSize: FONT_SIZE.one,
                    px: SPACING.three,
                  },
                }}
                hasDropdown
                segments={[
                  {
                    value: "1",
                    display: `${t("epoch")} 1`,
                    textColor:
                      selectedEpoch === 1 ? TEXT_COLORS.one : TEXT_COLORS.three,
                  },
                  {
                    value: "2",
                    display: `${t("epoch")} 2`,
                    textColor:
                      selectedEpoch === 2 ? TEXT_COLORS.one : TEXT_COLORS.three,
                  },
                ]}
                value={String(selectedEpoch)}
                onSelect={(value) => setSelectedEpoch(Number(value))}
              />
              {!isMobileScreen && <Divider direction="vertical" size="16px" />}
              <SegmentControl
                config={{
                  backgroundColor: COLORS.neutral.six,
                  activeBackgroundColor: COLORS.neutral.five,
                  borderRadius: "10px",
                  button: {
                    height: 32,
                    fontSize: FONT_SIZE.one,
                    px: SPACING.three,
                  },
                }}
                hasDropdown
                segments={[
                  {
                    value: LeaderboardTypeEnum.VOLUME,
                    display: campaignTranslations(LeaderboardTypeEnum.VOLUME),
                    textColor:
                      leaderboardType === LeaderboardTypeEnum.VOLUME
                        ? TEXT_COLORS.one
                        : TEXT_COLORS.three,
                  },
                  {
                    value: LeaderboardTypeEnum.PNL,
                    display: campaignTranslations(LeaderboardTypeEnum.PNL),
                    textColor:
                      leaderboardType === LeaderboardTypeEnum.PNL
                        ? TEXT_COLORS.one
                        : TEXT_COLORS.three,
                  },
                ]}
                value={leaderboardType}
                onSelect={(value) =>
                  setLeaderboardType(value as LeaderboardTypeEnum)
                }
              />
            </FlexWrapper>
          </FlexWrapper>
          {
            // eslint-disable-next-line no-nested-ternary
            !pnlLeaderboard || !volumeLeaderboard ? (
              accountStatisticsLoading ||
              pnlLeaderboardLoading ||
              volumeLeaderboardLoading ? (
                <SpinnerContainerWrapper>
                  <Spinner />
                </SpinnerContainerWrapper>
              ) : (
                <EmptyContentContainer style={{ justifyContent: "flex-start" }}>
                  {campaignTranslations("no_trades")}
                </EmptyContentContainer>
              )
            ) : (
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        // eslint-disable-next-line react/jsx-key
                        <TableHeaderCell
                          // We apply the sort properties to each column
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                        </TableHeaderCell>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <LeaderboardTableRow {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          // eslint-disable-next-line react/jsx-key
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </LeaderboardTableRow>
                    );
                  })}
                </tbody>
              </table>
            )
          }
        </LeaderboardTableWrapper>
      </FlexWrapper>
      {isMobileScreen && <StickyWrapper>{actionButton}</StickyWrapper>}
    </div>
  );
}

export default WSBCampaign;
