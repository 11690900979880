import { useContext, useCallback, useMemo } from "react";
import { AxiosError } from "axios";
import useSWR from "swr";
import { AuthContext } from "../../../contexts/AuthContext";
import { authApi } from "../../../services/api/apiFetcher";
import {
  GenericErrorResponse,
  GetRfqs200Response,
  PostRfqsPayload,
} from "../../../codegen-api";
import { unwrapApiError } from "../../../utils/errors";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";
import { pollingInterval } from "../../../services/api/pollingInterval";

type RFQRole = "taker" | "maker" | undefined;

export const useRFQ = (role: RFQRole = undefined, polling: boolean = true) => {
  const { apiKey, apiSecret } = useContext(AuthContext);

  const fetcher = useMemo(
    () => authApi(apiKey, apiSecret),
    [apiKey, apiSecret]
  );

  const { mutate, ...swr } = useSWR<GetRfqs200Response, AxiosError>(
    apiKey ? [APIEndpointEnum.RFQS, apiKey, role] : undefined,
    async () => (await (await fetcher.getRfqs(role))()).data,
    {
      use: [useLoggerMiddleware],
      refreshInterval: polling
        ? pollingInterval[APIEndpointEnum.RFQS]
        : undefined,
    }
  );

  const deleteRFQ = useCallback(
    async (blockId: string) => {
      try {
        const res = (await (await fetcher.deleteRfqsBlockId(blockId))()).data;
        mutate();
        return res;
      } catch (error) {
        throw unwrapApiError(error);
      }
    },
    [fetcher, mutate]
  );

  const deleteRFQsByInstrumentId = useCallback(
    async (instrumentId: string) => {
      const data = await mutate();
      const rfqs = data?.blocks;
      const selectedRfqs = rfqs?.filter((rfq) => {
        const legs = rfq.legs || [];
        return (
          legs.length === 1 && String(legs[0].instrument_id) === instrumentId
        );
      });

      if (selectedRfqs && selectedRfqs.length > 0) {
        await Promise.all(selectedRfqs.map((r) => deleteRFQ(r.block_id)));
      }
    },
    [deleteRFQ, mutate]
  );

  const createRFQ = useCallback(
    async (payload: PostRfqsPayload) => {
      try {
        const res = (await (await fetcher.postRfqs(payload))()).data;
        mutate();
        return res;
      } catch (error) {
        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        throw Error(
          genericResponseAxiosError.response?.data?.error ||
            "Error Creating RFQ"
        );
      }
    },
    [fetcher, mutate]
  );

  const getRFQQuotesByBlockId = useCallback(
    async (blockId: string) => {
      try {
        const res = (await (await fetcher.getRfqsBlockIdQuotes(blockId))())
          .data;
        return res;
      } catch (error) {
        throw unwrapApiError(error);
      }
    },
    [fetcher]
  );

  return {
    mutate,
    ...swr,
    createRFQ,
    deleteRFQ,
    deleteRFQsByInstrumentId,
    getRFQQuotesByBlockId,
  };
};
