/* eslint-disable react/prop-types */
import currency from "currency.js";
import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useSortBy, useTable } from "react-table";
import { ReactComponent as Star } from "../../assets/svg/star.svg";
import { InstrumentTypeResponse } from "../../codegen-api";
import { COLORS, ICON_COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { ITableColumn } from "../../interfaces/Table/TableColumn";
import { AssetResponse } from "../../utils/asset";
import { getAssetLogo, getAssetShortName } from "../../utils/asset/assets";
import { DefaultCellForColumn } from "../shared/Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../shared/Table/DefaultHeaderForColumn";
import { Align } from "../shared/Table/style";
import {
  AssetCellContainer,
  ChangeTag,
  MarketRow,
  MarketTableBody,
  MarketsTableCol,
  MarketsTableTable,
  MarketsTableWrapper,
  PriceCell,
  WatchlistButton,
} from "./style";
import IncentivizedAirdropsTooltip from "../shared/Tooltips/IncentivizedAirdropsTooltip";
import { FUNDING_RATE_PRECISION } from "../../constants/precision/form";
import { useGetAirdropIncentivizedAssets } from "../../hooks/api/farm/useGetAirdropIncentivizedAssets";

export type IMarketData = {
  id: string;
  asset: AssetResponse;
  dailyVolume: number;
  isWatchlist: boolean;
  isCurrentMarket: boolean;

  // Number of decimal places, optional.
  pricePrecision?: number;
};

export type IOptionMarketData = IMarketData & {
  putCallRatio: number;
  oi: number;
};

export type IPerpetualMarketData = IMarketData & {
  // eg. BANANA-USD
  market: string;
  price: string;
  dailyChange: number;
  isPrelaunch: boolean;
  fundingRate?: number;
};

type IMarketsTableProps = {
  isMobile?: boolean;
  highlightedIndex?: number;
} & (
  | {
      type: typeof InstrumentTypeResponse.Option;
      marketsData: IOptionMarketData[];
      onRowClick: (row: IOptionMarketData) => void;
      onWatchlist: (row: IOptionMarketData) => void;
    }
  | {
      type: typeof InstrumentTypeResponse.Perpetual;
      marketsData: IPerpetualMarketData[];
      onRowClick: (row: IPerpetualMarketData) => void;
      onWatchlist: (row: IPerpetualMarketData) => void;
    }
);

interface IAssetCellProps {
  asset: AssetResponse;
  isPrelaunch?: boolean;
}

const AssetCell = forwardRef<
  HTMLDivElement,
  PropsWithChildren<IAssetCellProps>
>(({ asset, isPrelaunch, children }, ref) => {
  const { isPrelaunchWithAirdrop } = useGetAirdropIncentivizedAssets();
  return (
    <AssetCellContainer>
      <img src={getAssetLogo(asset)} width={24} height={24} alt={asset} />
      {children || <span>{getAssetShortName(asset)}</span>}
      {isPrelaunchWithAirdrop(asset) && isPrelaunch && (
        <IncentivizedAirdropsTooltip asset={asset} ref={ref} />
      )}
    </AssetCellContainer>
  );
});

const chartDimensions = {
  width: 40,
  height: 40,
};

const MarketsTable = forwardRef<HTMLDivElement, IMarketsTableProps>(
  (
    { type, marketsData, onRowClick, onWatchlist, highlightedIndex, isMobile },
    baseRef
  ) => {
    const { t } = useTranslation("app", {
      keyPrefix: "CommandModal.MarketsTable",
    });
    const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]);

    // change this when we add/remove rows
    const columnWidths = isMobile
      ? ["60%", "25%", "15%"]
      : ["40%", "20%", "20%", "20%"];

    const columns = useMemo(() => {
      if (type === InstrumentTypeResponse.Perpetual) {
        const mobileCol: ITableColumn<IPerpetualMarketData>[] = [
          {
            title: t("market"),
            accessor: "market",
            Cell: ({ value, row }) => (
              <AssetCell
                asset={row.original.asset}
                isPrelaunch={row.original.isPrelaunch}
                ref={baseRef}
              >
                {value}
              </AssetCell>
            ),
          },
          {
            title: t("price"),
            accessor: "dailyChange",
            id: "price",
            align: "right",
            Cell: ({ row }) => {
              const { price, dailyChange } = row.original;
              return (
                <Align align="right">
                  <PriceCell>
                    <div>
                      {currency(price, {
                        precision: Math.max(
                          row.original.pricePrecision || 2,
                          2
                        ),
                      }).format()}
                    </div>
                    {isMobile && (
                      <div>
                        <ChangeTag value={dailyChange}>
                          {(dailyChange * 100).toFixed(2)}%
                        </ChangeTag>
                      </div>
                    )}
                  </PriceCell>
                </Align>
              );
            },
            sortType: "basic",
          },
        ];

        const col: ITableColumn<IPerpetualMarketData>[] = [
          ...mobileCol,
          {
            title: t("funding_rate"),
            accessor: "fundingRate",
            align: "right",
            sortType: "basic",
            Cell: ({ value }) => (
              <Align align="right">
                {Number(value) ? (
                  <ChangeTag value={Number(value || 0)}>
                    {Number(value) > 0 ? "+" : ""}
                    {`${(Number(value || 0) * 100).toFixed(
                      FUNDING_RATE_PRECISION
                    )}%`}
                  </ChangeTag>
                ) : (
                  <ChangeTag value={0}>0.00%</ChangeTag>
                )}
              </Align>
            ),
          },
          {
            title: t("daily_change"),
            accessor: "dailyChange",
            id: "dailyChange",
            align: "right",
            sortType: "basic",
            Cell: ({ value }) => (
              <Align align="right">
                <ChangeTag value={value}>
                  {value > 0 ? "+" : ""}
                  {(value * 100).toFixed(2)}%
                </ChangeTag>
              </Align>
            ),
          },
          {
            title: t("daily_volume"),
            accessor: "dailyVolume",
            align: "right",
            Cell: ({ value }) => (
              <Align align="right">
                {value ? currency(value).format() : "-"}
              </Align>
            ),
          },
        ];

        const watchlistCol: ITableColumn<IPerpetualMarketData> = {
          title: " ",
          accessor: "id",
          Header: () => <div />,
          Cell: ({ row }) => (
            <Align
              align="right"
              style={{
                marginRight: SPACING.two,
              }}
            >
              <WatchlistButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onWatchlist(row.original);
                }}
              >
                <Star
                  fill={
                    row.original.isWatchlist
                      ? COLORS.highlight.one
                      : ICON_COLORS.three
                  }
                />
              </WatchlistButton>
            </Align>
          ),
        };

        return isMobile ? [...mobileCol, watchlistCol] : [...col, watchlistCol];
      }

      const mobileCol: ITableColumn<IOptionMarketData>[] = [
        {
          title: t("underlying_asset"),
          accessor: "asset",
          Cell: ({ row }) => <AssetCell asset={row.original.asset} />,
        },
        {
          title: t("oi"),
          accessor: "oi",
          align: "right",
          Cell: ({ value }) => (
            <Align align="right">{currency(value).format()}</Align>
          ),
        },
      ];

      const col: ITableColumn<IOptionMarketData>[] = [
        mobileCol[0],
        {
          title: t("put_call_ratio"),
          accessor: "putCallRatio",
          align: "right",
          Cell: ({ value }) => <Align align="right">{value.toFixed(2)}</Align>,
        },
        mobileCol[1],
        {
          title: t("daily_volume"),
          accessor: "dailyVolume",
          align: "right",
          Cell: ({ value }) => (
            <Align align="right">
              {value ? currency(value).format() : "-"}
            </Align>
          ),
        },
      ];

      const watchlistCol: ITableColumn<IOptionMarketData> = {
        title: " ",
        accessor: "id",
        Header: () => <div />,
        Cell: ({ row }) => (
          <Align
            align="right"
            style={{
              marginRight: SPACING.two,
            }}
          >
            <WatchlistButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onWatchlist(row.original);
              }}
            >
              <Star
                fill={
                  row.original.isWatchlist
                    ? COLORS.highlight.one
                    : ICON_COLORS.three
                }
              />
            </WatchlistButton>
          </Align>
        ),
      };

      return isMobile ? [...mobileCol, watchlistCol] : [...col, watchlistCol];
    }, [baseRef, isMobile, onWatchlist, t, type]);

    const assignRef = useCallback(
      (ref: HTMLTableRowElement | null, index: number) => {
        // eslint-disable-next-line no-param-reassign
        rowRefs.current[index] = ref;
      },
      [rowRefs]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data: marketsData,
          defaultColumn: {
            Header: DefaultHeaderForColumn,
            Cell: DefaultCellForColumn,
          },
          autoResetSortBy: false,
        } as any,
        useSortBy
      );

    // Scroll to selected row
    useEffect(() => {
      if (highlightedIndex !== undefined) {
        const ref = rowRefs.current[highlightedIndex];
        if (ref) {
          ref.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }, [highlightedIndex]);

    return (
      <MarketsTableWrapper>
        <MarketsTableTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, headerIndex: number) => (
                  // <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  // eslint-disable-next-line react/jsx-key
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ width: columnWidths[headerIndex] || "auto" }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <MarketTableBody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              const { ...rowProps } = row.getRowProps();
              return (
                // eslint-disable-next-line react/jsx-key
                <MarketRow
                  {...rowProps}
                  ref={(ref) => assignRef(ref, index)}
                  isSelected={
                    isMobile
                      ? false
                      : (row.original as IMarketData).isCurrentMarket ||
                        index === highlightedIndex
                  }
                  onClick={() => onRowClick(row.original as any)}
                >
                  {row.cells.map((cell, rowIndex) => (
                    // eslint-disable-next-line react/jsx-key
                    <MarketsTableCol
                      {...cell.getCellProps()}
                      style={{
                        width: columnWidths[rowIndex] || "auto",
                        ...(cell.column.id === "indexHistory"
                          ? { width: chartDimensions.width }
                          : undefined),
                      }}
                    >
                      {cell.render("Cell")}
                    </MarketsTableCol>
                  ))}
                </MarketRow>
              );
            })}
          </MarketTableBody>
        </MarketsTableTable>
      </MarketsTableWrapper>
    );
  }
);

export default MarketsTable;
