import styled from "styled-components";
import { COLORS } from "../../../../constants/design/colors";
import { FONT_CSS } from "../../../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../../../constants/design/spacing";

export const Container = styled.div`
  ${FONT_CSS.body.three}
  background-color: ${COLORS.highlight.six};
  color: ${COLORS.highlight.one};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;

  > div {
    width: 6px;
    height: 6px;
    border-radius: ${BORDER_RADIUS.two}px;
    background-color: ${COLORS.highlight.one};
    margin-right: ${SPACING.two}px;
  }
`;
