import { useCallback, useEffect, useState } from "react";

/**
 * Rate limit hook
 * When increaseCount is called consecutively, and reaches maxCount, rateLimited becomes `true`.
 * After rateLimitSeconds, rateLimited becomes `false` again.
 * "consecutive" is determined by how often increaseCount is called per duration (duration is durationBeforeRateLimited)
 * @param maxCount
 * @param durationBeforeRateLimited
 * @param rateLimitSeconds
 */
const useRateLimit = (
  maxCount: number,
  durationBeforeRateLimited: number,
  rateLimitSeconds: number
) => {
  const [consecutiveCount, setConsecutiveCount] = useState(0);
  const [rateLimited, setRateLimited] = useState(false);

  // Reset rate limited after rateLimitSeconds
  useEffect(() => {
    if (rateLimited) {
      const timeout = setTimeout(() => {
        setRateLimited(false);
      }, rateLimitSeconds * 1000);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [rateLimitSeconds, rateLimited]);

  // Always reset consecutive count every duration
  useEffect(() => {
    const int = setInterval(() => {
      setConsecutiveCount(0);
    }, durationBeforeRateLimited * 1000);
    return () => clearInterval(int);
  }, [durationBeforeRateLimited]);

  // When breaches, set rate limited
  useEffect(() => {
    if (consecutiveCount >= maxCount) {
      setConsecutiveCount(0);
      setRateLimited(true);
    }
  }, [consecutiveCount, maxCount]);

  const increaseCount = useCallback((count?: number) => {
    setConsecutiveCount((prev) => prev + (count || 1));
  }, []);

  return {
    increaseCount,
    rateLimited
  };
};

export default useRateLimit;
