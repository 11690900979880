import styled from "styled-components";
import { FONT_STYLE } from "../../constants/design/fontSize";
import { TEXT_COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";

export const MMPWrapper = styled.div`
  ${FONT_STYLE.body.three};
  color: ${TEXT_COLORS.two};
  margin-bottom: ${SPACING.three}px;

  > strong {
    color: ${TEXT_COLORS.one};
  }
`;
