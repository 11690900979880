import { AxiosError } from "axios";
import { useCallback, useContext, useMemo } from "react";
import useSWR from "swr";
import {
  GetMmp200Response,
  PostMmpPayload
} from "../../../codegen-api";
import { AuthContext } from "../../../contexts/AuthContext";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { authApi } from "../../../services/api/apiFetcher";
import { pollingInterval } from "../../../services/api/pollingInterval";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";
import { unwrapApiError } from "../../../utils/errors";
import { AssetResponse } from "../../../utils/asset";

export const mmpEnabledAssets = ["ETH", "BTC"] as const;
export type IMMPEnabledAsset = typeof mmpEnabledAssets[number];

export const useMMP = () => {
  // Get API Key
  const { apiKey, apiSecret } = useContext(AuthContext);
  const fetcher = authApi(apiKey, apiSecret);

  const swr = useSWR<GetMmp200Response[], AxiosError>(
    // If apiKey is not available, the request will not fetch
    apiKey ? [APIEndpointEnum.MMP, apiKey] : undefined,
    async () => {
      const resp = mmpEnabledAssets.map(async (a) => ({
        ...(await (await fetcher.getMmp(a))()).data,
        asset: a,
      }));
      return Promise.all(resp);
    },
    {
      use: [useLoggerMiddleware],
      refreshInterval: pollingInterval[APIEndpointEnum.MMP],
    }
  );

  const disableMMP = useCallback(
    async (asset: IMMPEnabledAsset) => {
      try {
        const res = (
          await (
            await fetcher.postMmp({
              interval: 0,
              frozen: 0,
              amount_limit: "1",
              delta_limit: 0,
              asset,
            })
          )()
        ).data;
        await swr.mutate();
        return res;
      } catch (error) {
        throw unwrapApiError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher, swr.mutate]
  );

  const updateAndEnableMMP = useCallback(
    async (payload: PostMmpPayload) => {
      try {
        const res = (await (await fetcher.postMmp(payload))()).data;
        await swr.mutate();
        return res;
      } catch (error) {
        throw unwrapApiError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher, swr.mutate]
  );

  const resetMMP = useCallback(
    async (asset: IMMPEnabledAsset) => {
      try {
        const res = (await (await fetcher.postResetMmp({ asset }))()).data;
        return res;
      } catch (error) {
        throw unwrapApiError(error);
      }
    },
    [fetcher]
  );

  const getMmpData = useCallback(
    (asset: IMMPEnabledAsset) => swr.data?.find((v) => v.asset === asset),
    [swr.data]
  );

  const triggeredMMPs = useMemo(
    () => swr.data?.filter((v) => v.triggered),
    [swr.data]
  );

  const limitsBreached = useMemo(() => {
    const limits: {
      [asset in AssetResponse]?: string[];
    } = {};

    triggeredMMPs?.forEach((triggeredMMP) => {
      if (
        Number(triggeredMMP?.amount_change) >= Number(triggeredMMP?.amount_limit)
      ) {
        limits[triggeredMMP.asset] = limits[triggeredMMP.asset] || [];
        limits[triggeredMMP.asset]?.push("Amount Limits");
      }
      if (
        Number(triggeredMMP?.delta_change) >= Number(triggeredMMP?.delta_limit)
      ) {
        limits[triggeredMMP.asset] = limits[triggeredMMP.asset] || [];
        limits[triggeredMMP.asset]?.push("Delta Limits");
      }
    });

    return limits;
  }, [triggeredMMPs]);

  return {
    ...swr,
    updateAndEnableMMP,
    disableMMP,
    resetMMP,
    triggeredMMPs,
    limitsBreached,
    getMmpData,
  };
};
