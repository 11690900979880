import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useOrderbookStore } from "./public/store/useOrderbookStore";

export const useOrderbookWSS = (instrumentName?: string) => {
  const [addOrderbookInstruments, removeOrderbookInstruments] =
    useOrderbookStore(
      useShallow((state) => [
        state.addOrderbookInstruments,
        state.removeOrderbookInstruments,
      ])
    );

  const orderbook = useOrderbookStore((state) =>
    instrumentName ? state.orderbook[instrumentName] : undefined
  );

  useEffect(() => {
    if (instrumentName) {
      addOrderbookInstruments(instrumentName);
      return () => {
        removeOrderbookInstruments(instrumentName);
      };
    }
    return () => {};
  }, [addOrderbookInstruments, instrumentName, removeOrderbookInstruments]);

  return orderbook;
};
