import styled from "styled-components";
import { FONT_CSS } from "../../constants/design/fontSize";
import { TEXT_COLORS } from "../../constants/design/colors";

export const Message = styled.div`
  ${FONT_CSS.body.three}
  width: 280px;
  color: ${TEXT_COLORS.two};

  > a {
    color: ${TEXT_COLORS.one};
  }
`;
