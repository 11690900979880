import { getAssetFromSymbol } from "../../../utils/instruments";
import { getAssetLogo } from "../../../utils/asset/assets";
import { MarketIconContainer } from "./style";

interface IMarketIconProps {
  instrumentName?: string;
}
function MarketIcon({ instrumentName }: IMarketIconProps) {
  const asset = getAssetFromSymbol(instrumentName || "");
  const icon = getAssetLogo(asset);
  return (
    <MarketIconContainer>
      {icon && <img src={icon} alt={asset} />}
    </MarketIconContainer>
  );
}

export default MarketIcon;
