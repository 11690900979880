import styled, { css } from "styled-components";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { foregroundComponent } from "../../../style";
import { OrderbookFilterEnum } from "./shared";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Section = styled.div`
  ${foregroundComponent}
  display: flex;
  flex-direction: column;
  flex: 1;

  &:not(:last-child) {
    margin-bottom: ${SPACING.three}px;
  }
`;

export const Title = styled.div`
  display: flex;
  padding: ${SPACING.two}px;
  height: ${COMPONENTS.secondaryTabs}px;
  align-items: center;
  justify-content: space-between;

  span:first-child {
    ${FONT_CSS.header.eight}
    margin: 0 ${SPACING.two}px;
  }
`;

export const MediumFilterContainer = styled.div`
  padding: ${SPACING.one}px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const TitleFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FilterComponentHeader = styled.div`
  padding-top: ${SPACING.two}px;
`;

export const DropdownItem = styled.div<{ selected: boolean }>`
  ${FONT_CSS.header.eight}
  padding: ${SPACING.two}px ${SPACING.two}px;
  color: ${({ selected }) => (selected ? TEXT_COLORS.one : TEXT_COLORS.two)};

  svg {
    margin-left: ${SPACING.two}px;
  }
`;

export const ScrollableWrapper = styled.div`
  position: relative;
  flex: 1;
`;

export const ScrollableContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const OrderbookTypeFiltersContainer = styled.div`
  display: flex;

  > * {
    margin-left: ${SPACING.one}px;
  }
`;

export const OrderbookTypeFilterButton = styled.button<{
  isSelected?: boolean;
  filterType?: OrderbookFilterEnum;
}>`
  width: 24px;
  height: 24px;
  background-color: ${LAYER_COLORS.two};
  border-radius: 4px;
  padding: 6px;
  transition: all 0.2s ease-in-out;
  border: 1px solid
    ${({ isSelected }) => (isSelected ? COLORS.neutral.one : "transparent")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    background-color: ${COLORS.neutral.three};
    height: 2px;
    border-radius: 2px;
    width: 100%;
  }

  ${({ filterType }) => {
    switch (filterType) {
      case OrderbookFilterEnum.BIDS:
        return css`
          div:nth-child(1) {
          }
          div:nth-child(2) {
          }
          div:nth-child(3) {
            background-color: ${COLORS.positive.one};
          }
        `;
      case OrderbookFilterEnum.OFFERS:
        return css`
          div:nth-child(1) {
            background-color: ${COLORS.negative.one};
          }
          div:nth-child(2) {
          }
          div:nth-child(3) {
          }
        `;
      default:
        return css`
          div:nth-child(1) {
            background-color: ${COLORS.negative.one};
          }
          div:nth-child(3) {
            background-color: ${COLORS.positive.one};
          }
        `;
    }
  }}
`;

export const TradeTabWrapper = styled.div`
  height: ${COMPONENTS.toggleTabs}px;
  width: 100%;
`;

export const TradeTab = styled.div`
  padding: ${SPACING.two}px ${SPACING.four}px;
`;
