import { motion } from "framer-motion";
import styled from "styled-components";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  MODAL_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { BORDER_RADIUS, SPACING } from "../../constants/design/spacing";
import { CloseButton } from "../BaseModal/style";
import { FONT_SIZE } from "../../constants/design/fontSize";

export const SharePreviewTitle = styled.div`
  display: grid;
  grid-template-columns: 56px 199px;
  width: calc(100% - 56px - 199px);

  span {
    text-align: center;
    align-self: center;
    grid-column: 2 / span 1;
  }

  ${CloseButton} {
    border-left: none;
    border-right: 1px solid ${BORDER_COLORS.one};
    grid-column: 1 / span 1;

    > svg {
      transform: rotate(180deg);
    }
  }
`;

export const ShareActionsRow = styled.div`
  border-top: 1px solid ${BORDER_COLORS.one};
  display: flex;
  width: 100%;
  margin: auto 0;
  justify-content: center;

  > button {
    width: 100%;
    margin: ${SPACING.three}px;
  }
`;

export const PnLCardWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    margin: auto;
    height: fit-content;
    background: ${MODAL_COLORS.one};
    overflow: hidden;
    width: 100%;
    justify-content: center;
  }
`;

export const MiniCardWrapper = styled.div`
  transform-origin: center center;
  margin: auto;
  margin-bottom: ${SPACING.three}px;
  width: 260px;
`;

export const MiniTopCard = styled.div`
  padding: ${SPACING.twoHalf}px;
  background-color: ${BACKGROUND_COLORS.one};
  border-radius: ${BORDER_RADIUS.five}px;
  border: 1px solid ${BORDER_COLORS.one};
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  > * {
    z-index: 100;
  }

  > video {
    opacity: 0.25;
    z-index: 10;
    position: absolute;
    left: -45%;
    top: 0;
    bottom: 0;
    height: 100%;
  }
`;

export const MiniBottomCard = styled.div`
  padding: ${SPACING.three}px;
  background-color: ${BACKGROUND_COLORS.one};
  border-radius: ${BORDER_RADIUS.five}px;
  border: 1px solid ${BORDER_COLORS.one};
  margin-top: -1px;

  > div {
    display: flex;
    justify-content: space-between;

    > * {
      margin: auto 0;
    }

    p {
      font-size: 10px;
      color: ${TEXT_COLORS.three};
      margin: 0;
    }
  }

  > svg {
    margin-top: ${SPACING.three}px;
    width 100%;
    height: fit-content;
  }
`;

export const QRWrapper = styled.div`
  background: white;
  padding: 2px;
  border-radius: 3px;

  svg {
    width: 48px;
    height: 48px;
  }
`;

export const MiniAsset = styled.img`
  text-align: center;
  margin: auto;
  width: 48px;
  height: 48px;
`;

export const MiniTitle = styled.div`
  text-align: center;
  margin-top: ${SPACING.twoHalf}px;
  font-size: 14px;
`;

export const MiniText = styled(motion.div)<{ color?: string }>`
  color: ${({ color }) => color ?? TEXT_COLORS.one};
  text-align: center;
  margin-top: ${SPACING.two}px;
  font-size: 28px;
`;

export const MiniStatWrapper = styled.div`
  margin-top: ${SPACING.three}px;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: space-between;
    padding: ${SPACING.two}px 0;
    font-size: 12px;
    text-transform: capitalize;

    span:first-of-type {
      color: ${TEXT_COLORS.two};
    }
  }
`;

export const EnlargedPnLCardWrapper = styled.div`
  background: ${MODAL_COLORS.one};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  margin: auto 0;
  padding: 40px 125px;
  height: 500px;
  width: 500px;
  position: absolute;

  > video {
    z-index: 10;
  }

  > div {
    width: 250px;
  }
`;

export const EnlargedTitle = styled(motion.div)<{ color?: string }>`
  color: ${({ color }) => color ?? TEXT_COLORS.one};
  text-align: center;
  font-size: 28px;
`;

export const EnlargedStatWrapper = styled.div`
  margin-top: ${SPACING.three}px;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: space-between;
    padding: ${SPACING.two}px 0;
    text-transform: capitalize;
    font-size: ${FONT_SIZE.one};

    span:first-of-type {
      color: ${TEXT_COLORS.two};
    }
  }
`;

export const EnlargedTopCard = styled.div`
  padding: ${SPACING.twoHalf}px;
  background-color: ${BACKGROUND_COLORS.one};
  border-radius: ${BORDER_RADIUS.five}px;
  border: 1px solid ${BORDER_COLORS.one};
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  margin: auto !important;
  margin-bottom: 0 !important;
  position: relative;
  overflow: hidden;

  > video {
    opacity: 0.25;
    z-index: 10;
    position: absolute;
    left: -50%;
    top: 0;
    bottom: 0;
    height: 100%;
  }

  > img {
    width: 48px;
    height: 48px;
    margin: auto;
  }

  > p {
    margin-top: ${SPACING.two}px;
    margin-bottom: ${SPACING.one}px;
    text-align: center;
  }
`;

export const EnlargedBottomCard = styled.div`
  padding: ${SPACING.three}px;
  background-color: ${BACKGROUND_COLORS.one};
  border-radius: ${BORDER_RADIUS.five}px;
  border: 1px solid ${BORDER_COLORS.one};
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  margin: auto !important;
  margin-top: -1px !important;

  > div {
    display: flex;
    justify-content: space-between;

    > * {
      margin: auto 0;
    }

    p {
      font-size: 8px;
      color: ${TEXT_COLORS.three};
      margin: 0;
    }
  }

  > svg {
    margin-top: ${SPACING.three}px;
    width 100%;
    height: fit-content;
  }
`;

export const EnlargedPnLCardContainer = styled.div`
  position: relative;
  overflow: hidden;
`;
