import { useEffect, useState } from "react";
import { Id, Slide, ToastContainer, toast } from "react-toastify";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import { BORDER_COLORS, MODAL_COLORS } from "../../../constants/design/colors";
import { ClearAllButton, CloseButton } from "./style";

function ToastCloseButton({ closeToast }: any) {
  return (
    <CloseButton onClick={closeToast}>
      <Close />
    </CloseButton>
  );
}

export function Toast() {
  const [displayedToastsIds, setDisplayedToastsIds] = useState(new Set<Id>());

  // Hack to quickly check if any toasts is active,
  // and automatically hides clear all button if NONE of the toasts are active (but still animating)
  useEffect(() => {
    if (displayedToastsIds.size > 0) {
      const int = setInterval(() => {
        const anyActive = Array.from(displayedToastsIds).some((i) =>
          toast.isActive(i)
        );
        if (!anyActive) {
          setDisplayedToastsIds(new Set());
        }
      }, 200);
      return () => clearInterval(int);
    }
    return () => {};
  }, [displayedToastsIds]);

  useEffect(() => {
    toast.onChange((t) => {
      if (t.status === "added") {
        setDisplayedToastsIds((prev) => new Set([...Array.from(prev), t.id]));
      } else if (t.status === "removed") {
        setDisplayedToastsIds((prev) => {
          const dupSet = new Set([...Array.from(prev)]);
          dupSet.delete(t.id);
          return dupSet;
        });
      }
    });
  }, []);

  const onClearAll = () => {
    toast.dismiss();
    toast.clearWaitingQueue();
    setDisplayedToastsIds(new Set());
  };

  return (
    <>
      <ClearAllButton
        onClick={onClearAll}
        show={Boolean(displayedToastsIds.size)}
      >
        Clear All
      </ClearAllButton>
      <ToastContainer
        stacked
        draggable
        newestOnTop
        limit={4}
        autoClose={5000}
        transition={Slide}
        bodyStyle={{
          padding: 0,
        }}
        toastStyle={{
          backgroundColor: MODAL_COLORS.one,
          border: `1px solid ${BORDER_COLORS.one}`,
          padding: 0,
        }}
        closeButton={ToastCloseButton}
      />
    </>
  );
}
