import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import currency from "currency.js";
import { useTranslation } from "react-i18next";
import { Campaign, LeaderboardWrapper, LogoContainer } from "./style";
import useScreenSize from "../../hooks/screenSize/useScreenSize";
import {
  Body,
  Data,
  FONT_SIZE,
  Header,
  Label,
} from "../../constants/design/fontSize";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FlexWrapper, SPACING } from "../../constants/design/spacing";
import SegmentControl from "../../components/shared/SegmentedControl";
import { getAssetLogo } from "../../utils/asset/assets";
import { CampaignDetails, CampaignEnum, wsbCurrentEpoch } from ".";
import { PageEndpointEnum } from "../../enums/endpoint";
import { useCountdown } from "./useCountdown";
import useIndexWSS from "../../hooks/wss/useIndexWSS";

export enum FilterEnum {
  ALL = "all",
  LIVE = "live",
  OLD = "old",
}

function MainPage() {
  const { isMobileScreen } = useScreenSize();

  const { t } = useTranslation("app", { keyPrefix: "pages.CampaignsPage" });

  const { index } = useIndexWSS("EIGEN");

  const [activeFilter, setActiveFilter] = useState<FilterEnum>(FilterEnum.ALL);

  const navigate = useNavigate();

  const handleCampaignClick = (campaign: CampaignEnum) => {
    navigate(`${PageEndpointEnum.CAMPAIGNS}/${campaign}`);
  };

  const countdownMapping = useCountdown();
  const { days, hours, minutes, seconds, isLive, isUpcoming } =
    countdownMapping[CampaignEnum.WSB];

  const createCountdownText = (unit: number, label: string) =>
    `${unit} ${t(label)} ${t(isUpcoming ? "till_start" : "left")}`;

  // Determine countdown text based on campaign status
  let countdownText = t("campaign_over"); // Default message if no other case applies

  if (isUpcoming || isLive) {
    if (days > 0) {
      countdownText = createCountdownText(days, days > 1 ? "days" : "day");
    } else if (hours > 0) {
      countdownText = createCountdownText(hours, hours > 1 ? "hours" : "hour");
    } else if (minutes > 0) {
      countdownText = createCountdownText(
        minutes,
        minutes > 1 ? "minutes" : "minute"
      );
    } else if (seconds > 0) {
      countdownText = createCountdownText(
        seconds,
        seconds > 1 ? "seconds" : "second"
      );
    }
  }

  // update this when more coampaigns are done
  const rewardsPaidOut = useMemo(
    () =>
      index?.price
        ? Number(index.price) * CampaignDetails[CampaignEnum.WSB].rewards
        : "- - -",
    [index?.price]
  );

  const showCampaign = useCallback(
    (campaign: CampaignEnum) => {
      switch (activeFilter) {
        case FilterEnum.ALL:
          return true;
        case FilterEnum.LIVE:
          return countdownMapping[campaign].isLive;
        case FilterEnum.OLD:
          return !countdownMapping[campaign].isLive;
        default:
          return true;
      }
    },
    [activeFilter, countdownMapping]
  );
  return (
    <LeaderboardWrapper isMobileScreen={isMobileScreen}>
      <FlexWrapper
        flexColumn
        gap={SPACING.three}
        style={{ padding: isMobileScreen ? SPACING.three : 0 }}
      >
        <Header number="four">{t("aevo_campaigns")}</Header>
        <Body number="three" color={TEXT_COLORS.two}>
          {t("campaign_desc")}
        </Body>
        <FlexWrapper flexColumn gap={SPACING.two}>
          <Label number="three" color={TEXT_COLORS.three}>
            {t("total_rewards")}
          </Label>
          <Data number="one">{currency(rewardsPaidOut).format()}</Data>
        </FlexWrapper>
        <FlexWrapper flexColumn gap={SPACING.three}>
          <SegmentControl
            config={{
              widthType: "fullWidth",
              backgroundColor: COLORS.neutral.six,
              activeBackgroundColor: COLORS.neutral.five,
              borderRadius: "10px",
              button: {
                height: 32,
                fontSize: FONT_SIZE.one,
                px: SPACING.three,
              },
            }}
            hasDropdown
            segments={[
              {
                value: FilterEnum.ALL,
                display: t(FilterEnum.ALL),
                textColor:
                  activeFilter === FilterEnum.ALL
                    ? TEXT_COLORS.one
                    : TEXT_COLORS.three,
              },
              {
                value: FilterEnum.LIVE,
                display: t(FilterEnum.LIVE),
                textColor:
                  activeFilter === FilterEnum.LIVE
                    ? TEXT_COLORS.one
                    : TEXT_COLORS.three,
              },
              {
                value: FilterEnum.OLD,
                display: t(FilterEnum.OLD),
                textColor:
                  activeFilter === FilterEnum.OLD
                    ? TEXT_COLORS.one
                    : TEXT_COLORS.three,
              },
            ]}
            value={activeFilter}
            onSelect={(value) => setActiveFilter(value as FilterEnum)}
          />
        </FlexWrapper>
        {showCampaign(CampaignEnum.WSB) && (
          <Campaign onClick={() => handleCampaignClick(CampaignEnum.WSB)}>
            <FlexWrapper gap={SPACING.two}>
              <LogoContainer size={40} borderWidth={2} isLive={isLive}>
                <img
                  src={getAssetLogo("EIGEN")}
                  width={40}
                  height={40}
                  alt={"EIGEN"}
                />
              </LogoContainer>
              <FlexWrapper flexColumn gap={SPACING.one} alignItems="flex-start">
                <Header number="seven" color={TEXT_COLORS.one}>
                  {isMobileScreen
                    ? t("wsb_title_short", {
                        epoch: wsbCurrentEpoch,
                      })
                    : t("wsb_title", {
                        epoch: wsbCurrentEpoch,
                      })}
                </Header>
                <Label number="two" color={TEXT_COLORS.three}>
                  {countdownText}
                </Label>
              </FlexWrapper>
            </FlexWrapper>
            <FlexWrapper flexColumn gap={SPACING.one} alignItems="flex-end">
              <Header number="seven" color={TEXT_COLORS.one}>
                {index && index.price
                  ? currency(
                      CampaignDetails[CampaignEnum.WSB].rewards *
                        Number(index.price),
                      {
                        precision: 0,
                      }
                    ).format()
                  : "- - -"}
              </Header>
              <Label number="two" color={TEXT_COLORS.three}>
                {currency(CampaignDetails[CampaignEnum.WSB].rewards, {
                  symbol: "",
                  precision: 0,
                }).format()}{" "}
                EIGEN
              </Label>
            </FlexWrapper>
          </Campaign>
        )}
      </FlexWrapper>
    </LeaderboardWrapper>
  );
}

export default MainPage;
