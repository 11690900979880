import styled from "styled-components";
import { ReactComponent as ChevronIcon } from "../../../assets/svg/chevron-outline.svg";

interface IChevronProps {
  direction: "up" | "down" | "left" | "right";
  size?: "small" | "large";
}

export const Chevron = styled(ChevronIcon)<IChevronProps>`
  opacity: 0.48;
  width: ${({ size }) =>
    size === "small" ? "12px" : size === "large" ? "20px" : "16px"};
  height: ${({ size }) =>
    size === "small" ? "12px" : size === "large" ? "20px" : "16px"};
  transition: 0.2s all ease-out;
  transform: ${({ direction }) => {
    if (direction === "down") {
      return "rotate(90deg);";
    }
    if (direction === "left") {
      return "rotate(180deg);";
    }
    if (direction === "up") {
      return "rotate(270deg);";
    }

    return "rotate(0deg);";
  }};
`;
