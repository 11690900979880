// This hook is solely responsible for the management of toasts rendered on a global level
// A toast is added to a queue with varying intervals to be removed
// We hash each toast and clear it from the queue based on its hash
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SingleToast from "../../components/shared/Toast/SingleToast";
import { IToast } from "../../interfaces/Toast";
import { ToastEnum, ToastStatusEnum } from "../../utils/toast";
import { useSFX } from "../useSFX";

export function useToast() {
  // Attach intervals and remove based on toast hash after timeout
  const { t } = useTranslation();
  const { playSound } = useSFX();

  const addToast = useCallback(
    (toastParam: IToast, interval?: number | undefined) => {
      toast(<SingleToast toast={toastParam} />, { autoClose: interval });
    },
    []
  );

  const addToasts = useCallback(
    (newToasts: IToast[], interval?: number | undefined) => {
      newToasts.forEach((to) => {
        addToast(to, interval);
      });
    },
    [addToast]
  );

  /**
   * Given an error code, displays the related error message in an error toast
   * @param errorCode Error code returned from API. Should be one of src/i18n/en/errors.json. If doesnt exist, show error code as is
   */
  const addErrorToast = useCallback(
    (header: string | JSX.Element, errorCode: string) => {
      const error = t(`apiErrors:${errorCode}`, {
        defaultValue: errorCode,
      });

      playSound("error");

      addToast(
        {
          type: ToastEnum.SIMPLE,
          header,
          subheader: error,
          status: ToastStatusEnum.ERROR,
        },
        10000
      );
    },
    [addToast, playSound, t]
  );

  return {
    addToast,
    addToasts,
    addErrorToast,
  };
}
