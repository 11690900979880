import styled from "styled-components";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { FONT_CSS } from "../../constants/design/fontSize";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-height: calc(${COMPONENTS.mobileTabBar}px + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
`;

export const Tab = styled.button<{
  isSelected?: boolean;
  isAevoLogo?: boolean;
}>`
  ${FONT_CSS.body.six}
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${({ isSelected }) =>
    isSelected ? COLORS.highlight.one : TEXT_COLORS.two};

  > svg {
    width: 20px;
    height: 20px;
    stroke: ${({ isSelected, isAevoLogo }) =>
      isAevoLogo
        ? "none"
        : isSelected
        ? COLORS.highlight.one
        : TEXT_COLORS.two};
    margin-bottom: ${SPACING.one}px;
  }
  > svg > path {
    ${({ isSelected, isAevoLogo }) =>
      isAevoLogo &&
      `fill: ${isSelected ? COLORS.highlight.one : TEXT_COLORS.two};`}
`;
