import axios, { AxiosError } from "axios";
import { useMemo } from "react";
import useSWRImmutable from "swr/immutable";
import { getDatafeedURLForPerpAsset } from "../../../constants/api/api";

export interface IOHLCResponse {
  o: number[];
  h: number[];
  l: number[];
  c: number[];
  // unix seconds eg. 1694852700
  t: number[];

  // status
  s: "ok" | string;
}

export const useOHLC = (
  // eg. ETH-PERP
  resolution: string,
  from: number,
  to: number,
  instrumentName?: string,
  underlyingAsset?: string
) => {
  const datafeedURL = useMemo(
    () =>
      underlyingAsset
        ? `${getDatafeedURLForPerpAsset(underlyingAsset)}/history`
        : undefined,
    [underlyingAsset]
  );

  const swr = useSWRImmutable<IOHLCResponse | undefined, AxiosError>(
    instrumentName && datafeedURL
      ? [datafeedURL, resolution, from, to, instrumentName]
      : undefined,
    async () => {
      if (!datafeedURL) return undefined;
      const { data } = await axios.get<IOHLCResponse>(datafeedURL, {
        params: {
          resolution,
          from,
          to,
          symbol: instrumentName,
        },
      });
      if (data.s !== "ok") {
        return undefined;
      }
      return data as IOHLCResponse;
    },
    {}
  );

  return swr;
};
