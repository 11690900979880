import { AnimatePresence } from "framer-motion";
import { ModalErrorMessage } from "../../shared/style";
import { ContentContainer } from "../style";
import { ITPSLFormContentProps } from "./TPSLContent.types";
import TPSLFormContent from "./TPSLFormContent";

function TPSLContent({
  actualSlTriggerPrice,
  actualTpTriggerPrice,
  contractPriceStep,
  errorMessage,
  errors,
  handleSubmit,
  markPrice,
  onConfirmTPSLForPosition,
  position,
  pricePrecision,
  resetSlInput,
  resetTpInput,
  slDropdownOpen,
  slType,
  stopLossExpectedPnl,
  stopLossRegister,
  takeProfitExpectedPnl,
  takeProfitRegister,
  tpDropdownOpen,
  tpType,
  setTpDropdownOpen,
  setTpType,
  setSlDropdownOpen,
  setSlType,
}: ITPSLFormContentProps) {
  if (!position) return null;

  return (
    <AnimatePresence exitBeforeEnter>
      <ContentContainer
        transition={{
          duration: 0.05,
          ease: "easeInOut",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <TPSLFormContent
          actualSlTriggerPrice={actualSlTriggerPrice}
          actualTpTriggerPrice={actualTpTriggerPrice}
          contractPriceStep={contractPriceStep}
          errorMessage={errorMessage}
          errors={errors}
          handleSubmit={handleSubmit}
          markPrice={markPrice}
          onConfirmTPSLForPosition={onConfirmTPSLForPosition}
          position={position}
          pricePrecision={pricePrecision}
          resetSlInput={resetSlInput}
          resetTpInput={resetTpInput}
          slDropdownOpen={slDropdownOpen}
          slType={slType}
          stopLossExpectedPnl={stopLossExpectedPnl}
          stopLossRegister={stopLossRegister}
          takeProfitExpectedPnl={takeProfitExpectedPnl}
          takeProfitRegister={takeProfitRegister}
          tpDropdownOpen={tpDropdownOpen}
          tpType={tpType}
          setTpDropdownOpen={setTpDropdownOpen}
          setTpType={setTpType}
          setSlDropdownOpen={setSlDropdownOpen}
          setSlType={setSlType}
        />
        {errorMessage && <ModalErrorMessage>{errorMessage}</ModalErrorMessage>}
      </ContentContainer>
    </AnimatePresence>
  );
}

export default TPSLContent;
