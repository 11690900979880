import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Language, onChangeLanguage } from "../../i18n/config";

export type SFXSettings =
  | "disable_all"
  | "order_placed"
  | "order_filled"
  | "order_cancelled"
  | "error"
  | "points"
  | "yv_deposit"
  | "infographic_transition"
  | "positive_game_sound"
  | "social_media_like";

export type OptionsChainSettings =
  | "disable_all"
  | "position"
  | "bid_iv"
  | "ask_iv"
  | "delta"
  | "mark_price";

export type NotificationsSettings = "fills";

export type LanguageSettings = "en" | "cn" | "ru";

export enum SettingsEnum {
  OPTIONS_CHAIN = "OPTIONS_CHAIN_SETTINGS",
  SFX = "SFX_SETTINGS",
  LANGUAGE = "LANGUAGE_SETTINGS",
  NOTIFICATIONS = "NOTIFICATIONS_SETTINGS",
}

type SettingsEnumToTitle = {
  [key in SettingsEnum]: string;
};

export const settingsEnumTitle: SettingsEnumToTitle = {
  [SettingsEnum.OPTIONS_CHAIN]: "options_chain",
  [SettingsEnum.SFX]: "sfx",
  [SettingsEnum.LANGUAGE]: "language",
  [SettingsEnum.NOTIFICATIONS]: "notifications",
};

interface ISettingsContextType {
  sfx: Set<SFXSettings>;
  addSfx: (key: SFXSettings) => void;
  removeSfx: (key: SFXSettings) => void;

  optionsChain: Set<OptionsChainSettings>;
  addOptionsChain: (key: OptionsChainSettings) => void;
  removeOptionsChain: (key: OptionsChainSettings) => void;

  notifications: Set<NotificationsSettings>;
  addNotifications: (key: NotificationsSettings) => void;
  removeNotifications: (key: NotificationsSettings) => void;

  language: Language;
  changeLanguage: (language: Language) => void;
}

interface ISettingsContextProviderProps {
  children: ReactElement;
}

const defaultSettings: ISettingsContextType = {
  sfx: new Set<SFXSettings>([
    "order_placed",
    "order_filled",
    "order_cancelled",
    "yv_deposit",
    "points",
    "error",
    "infographic_transition",
    "positive_game_sound",
    "social_media_like",
  ]),
  addSfx: () => {},
  removeSfx: () => {},

  optionsChain: new Set<OptionsChainSettings>([
    "position",
    "bid_iv",
    "ask_iv",
    "delta",
    "mark_price",
  ]),
  addOptionsChain: () => {},
  removeOptionsChain: () => {},

  notifications: new Set<NotificationsSettings>(["fills"]),
  addNotifications: () => {},
  removeNotifications: () => {},

  language: "en",
  changeLanguage: () => {},
};

const getSettingsLocalstorageVersionKey = (setting: SettingsEnum) =>
  `${setting}-version`;

// Bump this version to clear respective settings in local storage
const currentSettingsVersions: {
  [key in SettingsEnum]?: string;
} = {
  [SettingsEnum.SFX]: "1.1",
};

export const SettingsContext =
  createContext<ISettingsContextType>(defaultSettings);

export function SettingsContextProvider({
  children,
}: ISettingsContextProviderProps) {
  const [sfx, setSfx] = useState<Set<SFXSettings>>(defaultSettings.sfx);
  const [optionsChain, setOptionsChain] = useState<Set<OptionsChainSettings>>(
    defaultSettings.optionsChain
  );
  const [notifications, setNotifications] = useState<
    Set<NotificationsSettings>
  >(defaultSettings.notifications);
  const [language, setLanguage] = useState<Language>("en");

  const updateLocalStorage = useCallback(
    (setting: SettingsEnum) => {
      if (setting === SettingsEnum.OPTIONS_CHAIN) {
        localStorage.setItem(
          SettingsEnum.OPTIONS_CHAIN,
          JSON.stringify(Array.from(optionsChain))
        );
      }

      if (setting === SettingsEnum.SFX) {
        localStorage.setItem(SettingsEnum.SFX, JSON.stringify(Array.from(sfx)));
      }

      if (setting === SettingsEnum.NOTIFICATIONS) {
        localStorage.setItem(
          SettingsEnum.NOTIFICATIONS,
          JSON.stringify(Array.from(notifications))
        );
      }

      if (setting === SettingsEnum.LANGUAGE) {
        localStorage.setItem(SettingsEnum.LANGUAGE, language);
        onChangeLanguage(language);
      }
    },
    [optionsChain, sfx, notifications, language]
  );

  // Initialization hook
  useEffect(() => {
    const optSettings = localStorage.getItem(SettingsEnum.OPTIONS_CHAIN);
    const sfxSettings = localStorage.getItem(SettingsEnum.SFX);
    const languageSetting = localStorage.getItem(SettingsEnum.LANGUAGE);
    const notificationsSetting = localStorage.getItem(
      SettingsEnum.NOTIFICATIONS
    );

    // Delete outdated local storage verions
    Object.values(SettingsEnum).forEach((v) => {
      const versionKey = getSettingsLocalstorageVersionKey(v);
      const previousVersion = localStorage.getItem(versionKey);
      const currentSettingsVersion = currentSettingsVersions[v];
      if (
        currentSettingsVersion &&
        currentSettingsVersions[v] !== previousVersion
      ) {
        localStorage.removeItem(v);

        // Update version
        localStorage.setItem(versionKey, currentSettingsVersion);
      }
    });

    if (optSettings) {
      const parsedOptSettings = new Set(
        JSON.parse(optSettings) as OptionsChainSettings[]
      );
      setOptionsChain(parsedOptSettings);
    } else {
      // Check if settings were all intentionally set as disabled
      if (optionsChain.has("disable_all")) {
        setOptionsChain(new Set<OptionsChainSettings>([]));
      } else {
        // If the key doesn't exist, re-populate settings
        setOptionsChain(
          new Set<OptionsChainSettings>(defaultSettings.optionsChain)
        );
      }

      updateLocalStorage(SettingsEnum.OPTIONS_CHAIN);
    }

    if (sfxSettings) {
      const parsedSfxSettings = new Set(
        JSON.parse(sfxSettings) as SFXSettings[]
      );
      setSfx(parsedSfxSettings);
    } else {
      // Check if settings were all intentionally set as disabled
      if (optionsChain.has("disable_all")) {
        setSfx(new Set<SFXSettings>([]));
      } else {
        // If the key doesn't exist, re-populate settings
        setSfx(new Set<SFXSettings>(defaultSettings.sfx));
      }

      updateLocalStorage(SettingsEnum.SFX);
    }

    if (languageSetting) {
      setLanguage(languageSetting as Language);
      onChangeLanguage(languageSetting as Language);
    } else {
      updateLocalStorage(SettingsEnum.LANGUAGE);
    }

    if (notificationsSetting) {
      const parsedNotificationsSetting = new Set(
        JSON.parse(notificationsSetting) as NotificationsSettings[]
      );
      setNotifications(parsedNotificationsSetting);
    } else {
      setNotifications(
        new Set<NotificationsSettings>(defaultSettings.notifications)
      );
      updateLocalStorage(SettingsEnum.NOTIFICATIONS);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOptionsChain = useCallback(
    (code: OptionsChainSettings) => {
      optionsChain.add(code);
      // Remove disable_all if there are other settings added
      if (optionsChain.size > 1 && optionsChain.has("disable_all"))
        optionsChain.delete("disable_all");
      setOptionsChain(new Set(optionsChain));
      updateLocalStorage(SettingsEnum.OPTIONS_CHAIN);
    },
    [optionsChain, updateLocalStorage]
  );

  const removeOptionsChain = useCallback(
    (code: OptionsChainSettings) => {
      optionsChain.delete(code);
      // Add disable_all if all settings are intentionally disabled
      if (optionsChain.size === 0) optionsChain.add("disable_all");
      setOptionsChain(new Set(optionsChain));
      updateLocalStorage(SettingsEnum.OPTIONS_CHAIN);
    },
    [optionsChain, updateLocalStorage]
  );

  const addNotifications = useCallback(
    (code: NotificationsSettings) => {
      notifications.add(code);
      setNotifications(new Set(notifications));
      updateLocalStorage(SettingsEnum.NOTIFICATIONS);
    },
    [notifications, updateLocalStorage]
  );

  const removeNotifications = useCallback(
    (code: NotificationsSettings) => {
      notifications.delete(code);
      setNotifications(new Set(notifications));
      updateLocalStorage(SettingsEnum.NOTIFICATIONS);
    },
    [notifications, updateLocalStorage]
  );

  const addSfx = useCallback(
    (code: SFXSettings) => {
      sfx.add(code);
      // Remove disable_all if there are other settings added
      if (sfx.size > 1 && sfx.has("disable_all")) sfx.delete("disable_all");
      setSfx(new Set(sfx));
      updateLocalStorage(SettingsEnum.SFX);
    },
    [sfx, updateLocalStorage]
  );

  const removeSfx = useCallback(
    (code: SFXSettings) => {
      sfx.delete(code);
      // Add disable_all if all settings are intentionally disabled
      if (sfx.size === 0) sfx.add("disable_all");
      setSfx(new Set(sfx));
      updateLocalStorage(SettingsEnum.SFX);
    },
    [sfx, updateLocalStorage]
  );

  const changeLanguage = useCallback((l: Language) => {
    setLanguage(l);
    localStorage.setItem(SettingsEnum.LANGUAGE, l);
    onChangeLanguage(l);
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        sfx,
        addSfx,
        removeSfx,
        optionsChain,
        addOptionsChain,
        removeOptionsChain,
        notifications,
        addNotifications,
        removeNotifications,
        language,
        changeLanguage,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}
