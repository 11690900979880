import { TableOptions } from "react-table";
import { ITableColumn } from "../../../interfaces/Table/TableColumn";
import { Align } from "./style";

interface IDefaultCellForColumnProps extends TableOptions<{}> {
  column: ITableColumn<any>;
  cell: {
    value: any;
  };
}

export function DefaultCellForColumn({
  column,
  cell,
}: IDefaultCellForColumnProps) {
  const {
    align,
    valueExtractor,
  } = column;

  return (
    <Align align={align || "left"}>
      {valueExtractor ? valueExtractor(cell.value) : cell.value}
    </Align>
  );
}
