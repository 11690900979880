import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { ModalErrorMessage } from "../../shared/style";
import SegmentedControl from "../../shared/SegmentedControl";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { ContentContainer, SegmentedControlWrapper } from "../style";
import {
  IPartialTPSLContentProps,
  NotificationTypeEnum,
} from "./TPSLContent.types";
import TPSLPartialFormContent from "./TPSLPartialFormContent";

function TPSLPartialContent({
  actualSlTriggerPrice,
  actualTpTriggerPrice,
  contractPriceStep,
  minAmountSize,
  errorMessage,
  errors,
  handleSubmit,
  markPrice,
  onConfirmTPSLForPosition,
  position,
  pricePrecision,
  amountPrecision,
  resetSlInput,
  resetTpInput,
  slDropdownOpen,
  slType,
  stopLossExpectedPnl,
  stopLossRegister,
  takeProfitExpectedPnl,
  takeProfitRegister,
  tpDropdownOpen,
  tpType,
  setTpDropdownOpen,
  setTpType,
  setSlDropdownOpen,
  setSlType,
  partialTakeProfitPositionAmountRegister,
  partialStopLossPositionAmountRegister,
  positionTakeProfitSliderAmount,
  positionStopLossSliderAmount,
  setMaxPartialTPValue,
  setMaxPartialSLValue,
  onChangePartialTakeProfitSliderValue,
  onChangePartialStopLossSliderValue,
  currentTab,
  onSelectTab,
}: IPartialTPSLContentProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "Positions",
  });

  const getTabTitle = useCallback(
    (tab: NotificationTypeEnum) => {
      switch (tab) {
        case NotificationTypeEnum.TP:
          return `${t("take_profit")}`;
        case NotificationTypeEnum.SL:
          return `${t("stop_loss")}`;
        default:
          return "";
      }
    },
    [t]
  );

  if (!position) return null;

  return (
    <>
      <SegmentedControlWrapper>
        <SegmentedControl
          segments={Object.keys(NotificationTypeEnum).map((k) => ({
            display: getTabTitle(
              NotificationTypeEnum[k as keyof typeof NotificationTypeEnum]
            ),
            value: NotificationTypeEnum[
              k as keyof typeof NotificationTypeEnum
            ] as NotificationTypeEnum,
            textColor:
              NotificationTypeEnum[k as keyof typeof NotificationTypeEnum] ===
              currentTab
                ? TEXT_COLORS.one
                : TEXT_COLORS.three,
          }))}
          value={currentTab}
          onSelect={onSelectTab}
          config={{
            widthType: "fullWidth",
            backgroundColor: LAYER_COLORS.two,
            activeBackgroundColor: LAYER_COLORS.three,
            borderRadius: "10px",
            button: {
              fontSize: FONT_SIZE.one,
            },
          }}
        />
      </SegmentedControlWrapper>
      <ContentContainer key={String(currentTab)}>
        <TPSLPartialFormContent
          actualSlTriggerPrice={actualSlTriggerPrice}
          actualTpTriggerPrice={actualTpTriggerPrice}
          contractPriceStep={contractPriceStep}
          minAmountSize={minAmountSize}
          errorMessage={errorMessage}
          errors={errors}
          handleSubmit={handleSubmit}
          markPrice={markPrice}
          onConfirmTPSLForPosition={onConfirmTPSLForPosition}
          position={position}
          pricePrecision={pricePrecision}
          amountPrecision={amountPrecision}
          resetSlInput={resetSlInput}
          resetTpInput={resetTpInput}
          slDropdownOpen={slDropdownOpen}
          slType={slType}
          stopLossExpectedPnl={stopLossExpectedPnl}
          stopLossRegister={stopLossRegister}
          takeProfitExpectedPnl={takeProfitExpectedPnl}
          takeProfitRegister={takeProfitRegister}
          tpDropdownOpen={tpDropdownOpen}
          tpType={tpType}
          setTpDropdownOpen={setTpDropdownOpen}
          setTpType={setTpType}
          setSlDropdownOpen={setSlDropdownOpen}
          setSlType={setSlType}
          partialTakeProfitPositionAmountRegister={
            partialTakeProfitPositionAmountRegister
          }
          partialStopLossPositionAmountRegister={
            partialStopLossPositionAmountRegister
          }
          positionTakeProfitSliderAmount={positionTakeProfitSliderAmount}
          positionStopLossSliderAmount={positionStopLossSliderAmount}
          onChangePartialTakeProfitSliderValue={
            onChangePartialTakeProfitSliderValue
          }
          onChangePartialStopLossSliderValue={
            onChangePartialStopLossSliderValue
          }
          setMaxPartialTPValue={setMaxPartialTPValue}
          setMaxPartialSLValue={setMaxPartialSLValue}
          currentTab={currentTab}
        />
        {errorMessage && <ModalErrorMessage>{errorMessage}</ModalErrorMessage>}
      </ContentContainer>
    </>
  );
}

export default TPSLPartialContent;
