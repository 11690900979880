import currency from "currency.js";
import moment from "moment";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InstrumentType, OptionTypeResponse } from "../../../codegen-api";
import { nanosToSeconds } from "../../../utils/date";
import MarketIcon from "../../PortfolioSettings/Table/MarketIcon";
import { MarketHeaderContainer, MarketHeaderContent, PriceInfo, Separator } from "./style";
import { COLORS } from "../../../constants/design/colors";
import { getAssetFromSymbol } from "../../../utils/instruments";
import { MarketInstrumentContext } from "../../../contexts/MarketInstrumentContext";

interface IMarketCellProps {
  instrumentName?: string;
  optionType?: OptionTypeResponse;
  // Nanos
  expiry?: string;
  strike?: string;

  price: string;
  // 0 to 1
  priceIncreasePercent?: number;
}

function MarketHeader({
  instrumentName,
  optionType,
  expiry,
  strike,
  price,
  priceIncreasePercent
}: IMarketCellProps) {
  const { t } = useTranslation("app", { keyPrefix: "shared.ViewDetailsModal.MarketHeader" });
  const { getMarketPrecision } = useContext(MarketInstrumentContext);
  const isOption = useMemo(
    () => optionType !== undefined && expiry !== undefined && strike !== undefined,
    [expiry, optionType, strike]
  );

  const content = useMemo(() => {
    if (isOption) {
      return (
        <MarketHeaderContent>
          {optionType}
          <Separator />
          {moment.unix(nanosToSeconds(expiry!)).format("DD MMM YY")}
          <Separator />
          {currency(strike!, { precision: 0 }).format()}
        </MarketHeaderContent>
      );
    }
    return (
      <MarketHeaderContent>
        {instrumentName}
      </MarketHeaderContent>
    );
  }, [expiry, instrumentName, isOption, optionType, strike]);

  const subContent = useMemo(() => {
    if (isOption) {
      return (
        <PriceInfo>
          <span>{t("mark_price")}</span>
          <span>{currency(price).format()}</span>
        </PriceInfo>
      );
    }

    return (
      <PriceInfo>
        {/* eslint-disable-next-line no-nested-ternary */}
        <span>{currency(price, { precision: getMarketPrecision(getAssetFromSymbol(instrumentName!), isOption ? InstrumentType.Option : InstrumentType.Perpetual).price_precision }).format()}</span>
        {
          priceIncreasePercent && (
            <span style={{
              color: priceIncreasePercent >= 0
                ? COLORS.positive.one
                : COLORS.negative.one
            }}
            >
              {(priceIncreasePercent * 100).toFixed(2)}%
            </span>
          )
        }
      </PriceInfo>
    );
  }, [isOption, price, getMarketPrecision, instrumentName, priceIncreasePercent, t]);

  return (
    <MarketHeaderContainer>
      <MarketIcon instrumentName={instrumentName} />
      <div>
        {content}
        {subContent}
      </div>
    </MarketHeaderContainer>
  );
}

export default MarketHeader;
