import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OrderTypeResponse } from "../../../../codegen-api";
import SegmentedControl, {
  ISegmentedControlConfig,
} from "../../../shared/SegmentedControl";
import { TEXT_COLORS, LAYER_COLORS } from "../../../../constants/design/colors";
import { FONT_SIZE } from "../../../../constants/design/fontSize";

interface IOrderTypeSegmentedControlProps {
  orderType: OrderTypeResponse;
  setOrderType: (orderType: OrderTypeResponse) => void;
}

function OrderTypeSegmentedControl({
  orderType,
  setOrderType,
}: IOrderTypeSegmentedControlProps) {
  const { t: commonFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.Common",
  });

  const limitMarketSegmentProps = useMemo(() => {
    const config: ISegmentedControlConfig = {
      widthType: "fullWidth",
      backgroundColor: LAYER_COLORS.two,
      activeBackgroundColor: LAYER_COLORS.three,
      borderRadius: "10px",
      button: {
        height: 32,
        fontSize: FONT_SIZE.one,
      },
    };
    return {
      config,
      segments: [
        {
          value: OrderTypeResponse.Market,
          display: commonFormTranslations("market"),
          textColor:
            orderType === OrderTypeResponse.Market
              ? TEXT_COLORS.one
              : TEXT_COLORS.three,
        },
        {
          value: OrderTypeResponse.Limit,
          display: commonFormTranslations("limit"),
          textColor:
            orderType === OrderTypeResponse.Limit
              ? TEXT_COLORS.one
              : TEXT_COLORS.three,
        },
      ],
    };
  }, [orderType, commonFormTranslations]);

  return (
    <SegmentedControl
      segments={limitMarketSegmentProps.segments}
      value={String(orderType)}
      onSelect={(v) => setOrderType(v as OrderTypeResponse)}
      config={limitMarketSegmentProps.config}
    />
  );
}

export default OrderTypeSegmentedControl;
