import axios from "axios";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { ReactComponent as Help } from "../../assets/svg/help.svg";
import { LAYER_COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { ChainIdEnum } from "../../enums/chain";
import { useIntercomHook } from "../../hooks/intercom";
import { L2_CHAIN_EXPLORER_URLS } from "../../utils/chain";
import { isProduction } from "../../utils/env";
import ExternalLinkText from "../ExternalLinkText";
import UptimeMonitor from "../UptimeMonitor";
import CurrentTimeUTC from "../UptimeMonitor/CurrentTimeUTC";
import MaintenanceSchedule from "../UptimeMonitor/MaintenanceSchedule";
import { Divider } from "../shared/Divider";
import {
  BlockContainer,
  Container,
  LeftContainer,
  RightContainer,
} from "./style";
import FooterTickerTape from "./FooterTickerTape";
import { LINKS } from "../../constants/links";

const BLOCK_EXPLORER_URL = isProduction()
  ? L2_CHAIN_EXPLORER_URLS[ChainIdEnum.ETH_MAINNET]
  : L2_CHAIN_EXPLORER_URLS[ChainIdEnum.SEPOLIA_TESTNET];

const MAINTENANCE_TIMESTAMP =
  process.env.REACT_APP_MAINTENANCE_TIMESTAMP_SECONDS;

function Footer() {
  const { t } = useTranslation("app", { keyPrefix: "Footer" });
  const { handleIntercomButtonClicked } = useIntercomHook();

  const maintenanceInProgress = Number(MAINTENANCE_TIMESTAMP)
    ? Number(MAINTENANCE_TIMESTAMP) * 1000 <= Date.now()
    : false;

  // Get block (once every 30s)
  const { data: blockNumber } = useSWR(
    ["BlockNumber"],
    async () => {
      try {
        const { data } = await axios.get(
          `${BLOCK_EXPLORER_URL}/api?module=block&action=eth_block_number`
        );
        return data.result ? parseInt(data.result, 16) : 0;
      } catch (error) {
        // Ignore error fetching block
        return undefined;
      }
    },
    {
      refreshInterval: 30000,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Retry after 30s.
        setTimeout(() => revalidate({ retryCount }), 30000);
      },
    }
  );

  return (
    <Container>
      <LeftContainer>
        <UptimeMonitor
          maintenanceTimestampSeconds={Number(MAINTENANCE_TIMESTAMP)}
        />
        <Divider
          direction="vertical"
          size="16px"
          style={{
            backgroundColor: LAYER_COLORS.four,
            margin: `0 ${SPACING.three}px`,
          }}
        />
        {Boolean(Number(MAINTENANCE_TIMESTAMP) && !maintenanceInProgress) && (
          <>
            <MaintenanceSchedule
              maintenanceTimestampSeconds={Number(MAINTENANCE_TIMESTAMP)}
            />
            <Divider
              direction="vertical"
              size="16px"
              style={{
                backgroundColor: LAYER_COLORS.four,
                margin: `0 ${SPACING.three}px`,
              }}
            />
          </>
        )}
        <CurrentTimeUTC />
      </LeftContainer>
      <FooterTickerTape />
      <RightContainer>
        <ExternalLinkText
          href={LINKS.terms}
          target="_blank"
          rel="noreferrer"
          disableHoverAnimation
          color={TEXT_COLORS.three}
          hoverColor={TEXT_COLORS.one}
          hideArrow
        >
          {t("terms")}
        </ExternalLinkText>
        <Divider
          direction="vertical"
          size="16px"
          style={{
            backgroundColor: LAYER_COLORS.four,
            margin: `0 ${SPACING.three}px`,
          }}
        />
        <ExternalLinkText
          disableHoverAnimation
          color={TEXT_COLORS.three}
          hoverColor={TEXT_COLORS.one}
          role="button"
          hideArrow
          onClick={handleIntercomButtonClicked}
        >
          <Help
            style={{
              marginRight: SPACING.one,
            }}
          />
          {t("help_and_support")}
        </ExternalLinkText>
        <Divider
          direction="vertical"
          size="16px"
          style={{
            backgroundColor: LAYER_COLORS.four,
            margin: `0 ${SPACING.three}px`,
          }}
        />
        <BlockContainer
          href={BLOCK_EXPLORER_URL}
          target="_blank"
          rel="noreferrer"
        >
          <span>{t("aevo_block")}:</span>
          &nbsp;
          <span>{!blockNumber ? "---" : blockNumber}</span>
        </BlockContainer>
      </RightContainer>
    </Container>
  );
}

export default Footer;
