import currency from "currency.js";
import { useTranslation } from "react-i18next";
import { TextWrapper } from "../style";
import { TEXT_COLORS } from "../../../constants/design/colors";
import { getProfitTextColor } from "../../../utils/strings";
import {
  NotificationTypeEnum,
  ITPSLNotificationTextProps,
} from "./TPSLContent.types";

function TPSLNotificationText({
  actualTriggerPrice,
  pricePrecision,
  currencyExpectedPnl,
  noticationType,
}: ITPSLNotificationTextProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "ConfirmationModal.TPSLForPositionModal",
  });
  const stopLossText =
    noticationType === NotificationTypeEnum.SL ? t("sl") : t("tp");

  return (
    <TextWrapper>
      {stopLossText}{" "}
      {t("desc_1", {
        order: t("market_order"),
      })}{" "}
      <p style={{ color: TEXT_COLORS.one }}>{t("mark_price")}</p> {t("reaches")}{" "}
      <p style={{ color: TEXT_COLORS.one }}>
        {actualTriggerPrice
          ? currency(actualTriggerPrice, {
              precision: pricePrecision,
            }).format()
          : "- -"}
      </p>{" "}
      {t("desc_2")}{" "}
      <p style={{ color: getProfitTextColor(currencyExpectedPnl || 0) }}>
        {currency(currencyExpectedPnl || "0").format()}
      </p>
    </TextWrapper>
  );
}

export default TPSLNotificationText;
