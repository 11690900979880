import { AnchorHTMLAttributes, CSSProperties, PropsWithChildren } from "react";
import { TextWrapper } from "./styles";
import { arrowUpRight as Arrow } from "../../assets/styledSvg/arrowUpRight";

interface IExternalLinkTextProps
  extends PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>> {
  hideArrow?: boolean;
  wrapperStyle?: CSSProperties;
  color?: string;
  hoverColor?: string;
  disableHoverAnimation?: boolean;
  inline?: boolean;
}

function ExternalLinkText({
  children,
  hideArrow,
  wrapperStyle,
  color,
  hoverColor,
  disableHoverAnimation,
  inline,
  ...others
}: IExternalLinkTextProps) {
  return (
    <TextWrapper
      inline={inline}
      disableHoverAnimation={disableHoverAnimation}
      style={wrapperStyle}
      color={color}
      hoverColor={hoverColor}
    >
      <a {...others}>
        {children}
        {!hideArrow && <Arrow color={color} />}
      </a>
    </TextWrapper>
  );
}

export default ExternalLinkText;
