import currency from "currency.js";
import { forwardRef } from "react";
import { MarkPriceIV, MiddleRow } from "./style";

interface IMarkRowProps {
  markPrice?: string;
  markIV?: string;
  isTradeForm?: boolean;
}

export const MarkRow = forwardRef<any, IMarkRowProps>(
  ({ markPrice, markIV, isTradeForm }, ref) => (
    <MiddleRow ref={ref} isTradeForm={isTradeForm}>
      <span>
        {markPrice || markIV ? (
          <>
            <span>{currency(markPrice || 0).format()}</span>
            {markIV && (
              <MarkPriceIV>{(Number(markIV) * 100).toFixed(2)}%</MarkPriceIV>
            )}
          </>
        ) : (
          "---"
        )}
      </span>
    </MiddleRow>
  )
);
