/* eslint-disable no-nested-ternary */
import { AnimationControls } from "framer-motion";
import { PropsWithChildren, RefObject, useLayoutEffect, useState } from "react";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as Alert } from "../../../assets/svg/alert.svg";
import {
  GetAccount200Response,
  GetAccount200ResponseFeeStructuresInner,
  GetAccount200ResponsePositionsInner,
  OrderTypeResponse,
  SideResponse,
  Stop,
  TimeInForce,
} from "../../../codegen-api";
import { COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";
import { IPerpsMarket } from "../../../contexts/MarketInstrumentContext/useGetMarkets";
import { FormValidatorKeysEnum } from "../../../enums/form";
import { IPositionInfo, ITradeInfo } from "../../../interfaces/TradeInfo";
import { AssetResponse } from "../../../utils/asset";
import { roundToStepSize } from "../../../utils/format";
import { IContractPriceStep } from "../../../utils/instruments";
import { getStopOrderName } from "../../../utils/order";
import { Divider } from "../../shared/Divider";
import FeeInfo from "../OptionsTradeForm/components/FeeInfo";
import { ReduceOnlyInput, TIFSelector, TPSLToggle } from "../form";
import {
  CheckboxesWrapper,
  ContentWrapper,
  DeselectReduceOnlyContainer,
  DetailsWrapper,
  FormContent,
  InfoRow,
  MarginTypeWrapper,
  OrderTPSLWrapper,
  OrderTypeWrapper,
  Padding,
  PositionChip,
  PositionInfoWrapper,
  ReduceOnlyAndTIFContainer,
  TPSLContainer,
  Title,
  TradeForm,
  TradeInfoWrapper,
  TransactionTypeWrapper,
  Value,
} from "../style";
import PerformanceDetails from "./components/PerformanceDetails";
import {
  IPerpsFormFieldValues,
  MarginSelector,
  OrderSLTriggerPriceInput,
  OrderTPTriggerPriceInput,
  PerpFormFieldKeyEnum,
} from "./components/form";
import { MarketOrderDisabledContainer } from "./style";
import OrderTypeSegmentedControl from "./components/OrderTypeSegmentedControl";
import OrderSideSegmentedControl from "./components/OrderSideSegmentedControl";
import Inputs from "./components/Inputs";
import SubmitContent from "./components/SubmitContent";

export type IPerpsFormDefaultValues = {
  amount?: string;
  price?: string;
  side?: SideResponse;
};

export interface IPerpsDesktopComponentProps {
  modalRef: RefObject<HTMLDivElement>;
  form: UseFormReturn<IPerpsFormFieldValues, any, undefined>;
  perpInstrument?: IPerpsMarket;
  submitOrder: SubmitHandler<IPerpsFormFieldValues>;
  amount: string;
  price: string;
  leverage: string;
  markPrice?: string;
  bestPrice?: string;
  orderDirection: SideResponse;
  setOrderDirection: (orderDirection: SideResponse) => void;
  orderType: OrderTypeResponse;
  setOrderType: (type: OrderTypeResponse) => void;
  isStop: boolean;
  setIsStop: (isStop: boolean) => void;
  tradeUseUSDCTerms: boolean;
  setTradeUseUSDCTerms?: (tradeUseUSDCTerms: boolean) => void;
  tpslForOrder: boolean;
  setTpslForOrder: (tpsl: boolean) => void;
  tifType: TimeInForce;
  setTifType: (tif: TimeInForce) => void;
  tifDropdown: boolean;
  setTifDropdown: (value: boolean) => void;
  dropdownValue: string;
  reduceOnlyNotAllowed: boolean;
  reduceOnly: boolean;
  currentPosition?: GetAccount200ResponsePositionsInner;
  contractPriceStep: IContractPriceStep;
  verifyNotEnoughBalance: (contractSize: string) => boolean;
  isLoading: boolean;
  setReduceOnly: (reduceOnly: boolean) => void;
  tradeInfo: ITradeInfo[];
  feeStructure?: GetAccount200ResponseFeeStructuresInner;
  positionInfo: IPositionInfo[];
  animControls: AnimationControls;
  bottomTradeInfo?: ITradeInfo[];
  customError?: string;
  minOrderValue?: string;
  maxOrderValue?: string;
  insufficientLiquidity: boolean;
  maxLeverage: number;
  realContractSize: number;
  replacePlaceOrderButton?: JSX.Element;
  accountData: GetAccount200Response | undefined;
  calculateTotalValueWithSize: (amountStr: string, isUSDC?: boolean) => number;
  getRealContractSize: (amt: string, isUSD?: boolean) => number;
  updateLeverage: (
    orderSize: number,
    isUSDC: boolean,
    priceValue?: number
  ) => void;
  updateOrderSize: (
    leverageValue: number,
    disableLeverageUpdate?: boolean
  ) => void;
  updateReduceOnlyOrderSize: (size: number) => void;
  triggerPriceValidateFn:
    | {
        triggerPriceBelowMark: () => boolean;
        triggerPriceAboveMark: (v: string) => boolean;
      }
    | {
        triggerPriceBelowMark: (v: string) => boolean;
        triggerPriceAboveMark: () => boolean;
      };
  limitPriceValidateFn:
    | {
        limitPriceAboveTriggerPrice: (v: string) => boolean;
        limitPriceBelowTriggerPrice: () => boolean;
      }
    | {
        limitPriceAboveTriggerPrice: () => boolean;
        limitPriceBelowTriggerPrice: (v: string) => boolean;
      };
  triggerOrderStopValidateFn: (stop: Stop) =>
    | {
        triggerPriceBelowMark: () => boolean;
        triggerPriceAboveMark: (v: string) => boolean;
      }
    | {
        triggerPriceBelowMark: (v: string) => boolean;
        triggerPriceAboveMark: () => boolean;
      };
}

function DesktopComponent({
  modalRef,
  form,
  perpInstrument,
  submitOrder,
  amount,
  price,
  leverage,
  markPrice,
  bestPrice,
  orderDirection,
  setOrderDirection,
  orderType,
  setOrderType,
  isStop,
  setIsStop,
  tradeUseUSDCTerms,
  setTradeUseUSDCTerms,
  tifType,
  setTifType,
  tpslForOrder,
  setTpslForOrder,
  tifDropdown,
  setTifDropdown,
  dropdownValue,
  reduceOnlyNotAllowed,
  reduceOnly,
  currentPosition,
  contractPriceStep,
  verifyNotEnoughBalance,
  isLoading,
  setReduceOnly,
  tradeInfo,
  feeStructure,
  positionInfo,
  animControls,
  bottomTradeInfo,
  customError,
  minOrderValue,
  maxOrderValue,
  maxLeverage,
  replacePlaceOrderButton,
  accountData,
  realContractSize,
  calculateTotalValueWithSize,
  getRealContractSize,
  updateLeverage,
  updateOrderSize,
  updateReduceOnlyOrderSize,
  insufficientLiquidity,
  triggerPriceValidateFn,
  limitPriceValidateFn,
  triggerOrderStopValidateFn,
}: PropsWithChildren<IPerpsDesktopComponentProps>) {
  const { t } = useTranslation();
  const { t: formTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const { t: commonFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.Common",
  });
  const { t: perpTradeFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.PerpsTradeForm",
  });

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = form;

  const [isSticky, setIsSticky] = useState(false);

  // To set orderform sticky when height reaches certain threshold
  useLayoutEffect(() => {
    const handleResize = () => {
      if (modalRef.current) {
        const currentHeight = modalRef.current.clientHeight;
        setIsSticky(currentHeight < 900);
      }
    };

    // Perform an initial check after 2 seconds.
    const timeoutId = setTimeout(handleResize, 2000);

    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", handleResize);
    };
  }, [modalRef]);

  return (
    <ContentWrapper ref={modalRef} style={{ bottom: 0 }}>
      <TradeForm onSubmit={handleSubmit(submitOrder)}>
        <FormContent>
          <Padding>
            <TransactionTypeWrapper>
              <OrderSideSegmentedControl
                orderDirection={orderDirection}
                setOrderDirection={setOrderDirection}
              />
            </TransactionTypeWrapper>
            <OrderTypeWrapper>
              <OrderTypeSegmentedControl
                dropdownValue={dropdownValue}
                orderType={orderType}
                setOrderType={setOrderType}
                isStop={isStop}
                setIsStop={setIsStop}
              />
            </OrderTypeWrapper>
            <MarginTypeWrapper>
              <MarginSelector
                instrument={perpInstrument}
                form={form}
                side={orderDirection}
              />
            </MarginTypeWrapper>
          </Padding>
          {!!perpInstrument?.pre_launch &&
          orderType === OrderTypeResponse.Market ? (
            <MarketOrderDisabledContainer>
              <span>
                {perpTradeFormTranslations("prelaunch_market_order_disabled_1")}
              </span>
              <span>
                {perpTradeFormTranslations("prelaunch_market_order_disabled_2")}
              </span>
            </MarketOrderDisabledContainer>
          ) : (
            <DetailsWrapper>
              <Inputs
                form={form}
                perpInstrument={perpInstrument}
                amount={amount}
                price={price}
                leverage={leverage}
                markPrice={markPrice}
                bestPrice={bestPrice}
                orderDirection={orderDirection}
                orderType={orderType}
                isStop={isStop}
                tradeUseUSDCTerms={tradeUseUSDCTerms}
                setTradeUseUSDCTerms={setTradeUseUSDCTerms}
                reduceOnly={reduceOnly}
                reduceOnlyNotAllowed={reduceOnlyNotAllowed}
                currentPosition={currentPosition}
                contractPriceStep={contractPriceStep}
                verifyNotEnoughBalance={verifyNotEnoughBalance}
                isLoading={isLoading}
                customError={customError}
                minOrderValue={minOrderValue}
                maxOrderValue={maxOrderValue}
                maxLeverage={maxLeverage}
                calculateTotalValueWithSize={calculateTotalValueWithSize}
                getRealContractSize={getRealContractSize}
                updateLeverage={updateLeverage}
                updateOrderSize={updateOrderSize}
                updateReduceOnlyOrderSize={updateReduceOnlyOrderSize}
                insufficientLiquidity={insufficientLiquidity}
                triggerPriceValidateFn={triggerPriceValidateFn}
                limitPriceValidateFn={limitPriceValidateFn}
                portfolioMargin={accountData?.portfolio}
              />
              <DeselectReduceOnlyContainer
                show={reduceOnlyNotAllowed && reduceOnly}
              >
                <div>{commonFormTranslations("deselect_reduce_only")}</div>
                <div>
                  {commonFormTranslations("reduce_only_error_desc_1", {
                    orderDirection: commonFormTranslations(
                      orderDirection.toLowerCase()
                    ),
                    orderType: getStopOrderName(t, orderType, true, isStop),
                  })}
                  <strong>
                    {currentPosition
                      ? currentPosition?.side === SideResponse.Buy
                        ? commonFormTranslations(
                            "reduce_only_error_desc_2_open_position_long"
                          )
                        : commonFormTranslations(
                            "reduce_only_error_desc_2_open_position_short"
                          )
                      : commonFormTranslations(
                          "reduce_only_error_desc_2_no_position"
                        )}
                  </strong>
                  {commonFormTranslations("reduce_only_error_desc_3_and")}
                  <strong>
                    {commonFormTranslations(
                      "reduce_only_error_desc_4_reduce_only"
                    )}
                  </strong>
                  {commonFormTranslations("reduce_only_error_desc_5")}
                </div>
              </DeselectReduceOnlyContainer>
              <CheckboxesWrapper>
                <ReduceOnlyAndTIFContainer>
                  <ReduceOnlyInput
                    isOn={reduceOnly}
                    onToggle={() => {
                      setReduceOnly(!reduceOnly);
                      // cannot be both reduce only and have tp/sl for order
                      setTpslForOrder(false);
                    }}
                  />
                  {orderType === OrderTypeResponse.Limit && (
                    <TIFSelector
                      tifDropdown={tifDropdown}
                      setTifDropdown={setTifDropdown}
                      reduceOnly={reduceOnly}
                      tifType={tifType}
                      setTIFType={setTifType}
                    />
                  )}
                </ReduceOnlyAndTIFContainer>
                {!isStop && (
                  <TPSLContainer>
                    <TPSLToggle
                      isOn={tpslForOrder}
                      onToggle={() => {
                        setTpslForOrder(!tpslForOrder);
                        // cannot be both reduce only and have tp/sl for order
                        setReduceOnly(false);
                      }}
                    />
                  </TPSLContainer>
                )}
              </CheckboxesWrapper>
              {tpslForOrder && !isStop && (
                <OrderTPSLWrapper>
                  <OrderTPTriggerPriceInput
                    placeholder={perpTradeFormTranslations("take_profit")}
                    markPrice={
                      markPrice
                        ? Number(markPrice).toFixed(
                            contractPriceStep.price_precision
                          )
                        : undefined
                    }
                    minPrice={contractPriceStep.price_step}
                    register={register(
                      PerpFormFieldKeyEnum.ORDER_TP_TRIGGER_PRICE,
                      {
                        disabled: isLoading,
                        validate: {
                          ...triggerOrderStopValidateFn(Stop.TakeProfit),
                          [FormValidatorKeysEnum.moreThanZero]: (v) =>
                            !v || parseFloat(v) > 0,
                          [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
                            !v ||
                            roundToStepSize(
                              Number(v),
                              contractPriceStep.price_step,
                              contractPriceStep.price_precision
                            ) >=
                              1 / 10 ** contractPriceStep.price_precision,
                        },
                        onBlur(event) {
                          setValue(
                            PerpFormFieldKeyEnum.ORDER_TP_TRIGGER_PRICE,
                            event.target.value
                              ? roundToStepSize(
                                  parseFloat(event.target.value),
                                  contractPriceStep.price_step,
                                  contractPriceStep.price_precision
                                ).toString()
                              : event.target.value
                          );
                        },
                      }
                    )}
                    errors={errors}
                    resetInput={() =>
                      setValue(PerpFormFieldKeyEnum.ORDER_TP_TRIGGER_PRICE, "")
                    }
                  />
                  <OrderSLTriggerPriceInput
                    placeholder={perpTradeFormTranslations("stop_loss")}
                    markPrice={
                      markPrice
                        ? Number(markPrice).toFixed(
                            contractPriceStep.price_precision
                          )
                        : undefined
                    }
                    minPrice={contractPriceStep.price_step}
                    register={register(
                      PerpFormFieldKeyEnum.ORDER_SL_TRIGGER_PRICE,
                      {
                        disabled: isLoading,
                        validate: {
                          ...triggerOrderStopValidateFn(Stop.StopLoss),
                          [FormValidatorKeysEnum.moreThanZero]: (v) =>
                            !v || parseFloat(v) > 0,
                          [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
                            !v ||
                            roundToStepSize(
                              Number(v),
                              contractPriceStep.price_step,
                              contractPriceStep.price_precision
                            ) >=
                              1 / 10 ** contractPriceStep.price_precision,
                        },
                        onBlur(event) {
                          setValue(
                            PerpFormFieldKeyEnum.ORDER_SL_TRIGGER_PRICE,
                            event.target.value
                              ? roundToStepSize(
                                  parseFloat(event.target.value),
                                  contractPriceStep.price_step,
                                  contractPriceStep.price_precision
                                ).toString()
                              : event.target.value
                          );
                        },
                      }
                    )}
                    errors={errors}
                    resetInput={() =>
                      setValue(PerpFormFieldKeyEnum.ORDER_SL_TRIGGER_PRICE, "")
                    }
                  />
                </OrderTPSLWrapper>
              )}
              <TradeInfoWrapper>
                {tradeInfo.map((info) => (
                  <InfoRow
                    key={`${info.title}-${info.value}}`}
                    warningOrError={info.warningOrError}
                  >
                    <Title>
                      {info.title}
                      {Boolean(info.warningOrError && info.showErrorIcon) && (
                        <Alert
                          style={{
                            stroke:
                              info.warningOrError === "warning"
                                ? COLORS.system.one
                                : COLORS.negative.one,
                            marginLeft: `${SPACING.one}px`,
                            marginTop: `-${SPACING.one / 2}px`,
                          }}
                        />
                      )}
                    </Title>
                    <Value>{info.value}</Value>
                  </InfoRow>
                ))}
                <FeeInfo
                  asset={perpInstrument?.underlying_asset as AssetResponse}
                  size={realContractSize}
                  feeStructure={Number(feeStructure?.taker_fee || 0)}
                />
              </TradeInfoWrapper>
              <PositionInfoWrapper>
                {positionInfo.map((info) => (
                  <InfoRow
                    key={`${info.title}-${info.value}}`}
                    warningOrError={info.warningOrError}
                  >
                    <Title>
                      {info.title}
                      {Boolean(info.warningOrError && info.showErrorIcon) && (
                        <Alert
                          style={{
                            stroke:
                              info.warningOrError === "warning"
                                ? COLORS.system.one
                                : COLORS.negative.one,
                            marginLeft: `${SPACING.one}px`,
                            marginTop: `-${SPACING.one / 2}px`,
                          }}
                        />
                      )}
                      {info.side && (
                        <PositionChip type={info.side}>
                          {info.side === SideResponse.Buy
                            ? formTranslations("long")
                            : formTranslations("short")}
                        </PositionChip>
                      )}
                    </Title>
                    <Value>{info.value}</Value>
                  </InfoRow>
                ))}
              </PositionInfoWrapper>
              {bottomTradeInfo && (
                <TradeInfoWrapper style={{ marginTop: SPACING.three }}>
                  {bottomTradeInfo.map((info) => (
                    <InfoRow
                      key={info.key ?? `${info.title}-${info.value}`}
                      warningOrError={info.warningOrError}
                      marginBottom={SPACING.two}
                    >
                      <Title>{info.title}</Title>
                      <Value>{info.value}</Value>
                    </InfoRow>
                  ))}
                </TradeInfoWrapper>
              )}
            </DetailsWrapper>
          )}
        </FormContent>
        <SubmitContent
          perpInstrument={perpInstrument}
          orderType={orderType}
          orderDirection={orderDirection}
          reduceOnlyNotAllowed={reduceOnlyNotAllowed}
          reduceOnly={reduceOnly}
          isLoading={isLoading}
          animControls={animControls}
          customError={customError}
          replacePlaceOrderButton={replacePlaceOrderButton}
          accountData={accountData}
          isSticky={isSticky}
        />
        {!isSticky && (
          <>
            <Divider direction="horizontal" size="100%" />
            <PerformanceDetails perpInstrument={perpInstrument} />
          </>
        )}
      </TradeForm>
    </ContentWrapper>
  );
}

export default DesktopComponent;
