import { PropsWithChildren } from "react";
import { CloseContainer, CloseInfoButton, OverlayInfo, Scrim, TappableContent } from "./style";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";

interface IModalOverlayInfoProps {
  show?: boolean;
  onClose?: () => void;
  height?: string;
  hideCloseButton?: boolean;
  noPadding?: boolean;
}

function ModalOverlayInfo({
  show,
  onClose,
  height,
  hideCloseButton,
  noPadding,
  children,
}: PropsWithChildren<IModalOverlayInfoProps>) {
  return (
    <Scrim show={show}>
      <TappableContent onClick={onClose} />
      <OverlayInfo noPadding={noPadding} height={height}>
        {children}
        {
          !hideCloseButton && (
            <CloseContainer>
              <CloseInfoButton onClick={onClose}>
                <Close />
              </CloseInfoButton>
            </CloseContainer>
          )
        }
      </OverlayInfo>
    </Scrim>
  );
}

export default ModalOverlayInfo;
