import { InstrumentTypeResponse } from "../codegen-api";

type DerivativeEnumDesc = {
  [key in InstrumentTypeResponse]: string
}

export const derivativeDescription: DerivativeEnumDesc = {
  [InstrumentTypeResponse.Option]: "options",
  [InstrumentTypeResponse.Perpetual]: "perpetuals",
  [InstrumentTypeResponse.Spot]: "spot",
};
