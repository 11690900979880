import currency from "currency.js";
import { AnimatePresence } from "framer-motion";
import moment from "moment";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { COMPONENTS } from "../../constants/design/spacing";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import usePrevious from "../../hooks/usePrevious";
import { nanosToSeconds, shorthandTimeLeftToExpiry } from "../../utils/date";
import { Chevron } from "../shared/Chevron/style";
import StrikeRow from "./StrikeRow";
import {
  CloseButton,
  Container,
  ExpiryTag,
  FilterContent,
  FilterRow,
  FilterSelections,
  Header,
  SelectStrikeWarning,
  Tab,
  Title,
} from "./style";
import { OptionTypeResponse } from "../../codegen-api";

export enum FilterByEnum {
  Type = "option_type",
  Expiry = "expiry",
  Strike = "strike_price",
}

interface IOptionFilterProps {
  isMobileScreen?: boolean;
}

function OptionFilter({ isMobileScreen }: IOptionFilterProps) {
  const {
    selectedValues,
    orderTypes,
    setOptionType,
    expiries,
    setExpiry,
    strikes,
    setStrike,
  } = useContext(MarketInstrumentContext);
  const [filteringBy, setFilteringBy] = useState<FilterByEnum>();
  const prevFilteringBy = usePrevious(filteringBy);
  const { t } = useTranslation("app", { keyPrefix: "OptionFilter" });
  const { t: translateAll } = useTranslation();
  const onToggleFilter = useCallback(
    (filter: FilterByEnum) => {
      if (filteringBy === filter) {
        setFilteringBy(undefined);
      } else {
        setFilteringBy(filter);
      }
    },
    [filteringBy]
  );

  const optionTypeText = useCallback(
    (type: OptionTypeResponse) => {
      switch (type) {
        case "call":
          return t("call_option");
        case "put":
          return t("put_option");
        default:
          return undefined;
      }
    },
    [t]
  );

  const filterSelections = useMemo(() => {
    if (filteringBy === FilterByEnum.Type) {
      return orderTypes.map((type) => (
        <FilterRow
          key={type}
          selected={selectedValues.orderType === type}
          onClick={() => {
            setOptionType(type);
            setFilteringBy(undefined);
          }}
          type="button"
        >
          {optionTypeText(type)}
        </FilterRow>
      ));
    }
    if (filteringBy === FilterByEnum.Expiry) {
      return expiries.map((expiry) => (
        <FilterRow
          key={expiry}
          selected={selectedValues.expiry === expiry}
          onClick={() => {
            setExpiry(expiry);
            setFilteringBy(undefined);
          }}
          type="button"
        >
          <span>
            {moment.unix(nanosToSeconds(expiry)).format("DD MMM YYYY")}
          </span>
          <ExpiryTag>
            {shorthandTimeLeftToExpiry(translateAll, nanosToSeconds(expiry))}
          </ExpiryTag>
        </FilterRow>
      ));
    }
    if (filteringBy === FilterByEnum.Strike) {
      return (
        <StrikeRow
          strikes={strikes}
          selectedStrike={selectedValues.strike}
          onRowClick={(strike) => {
            setStrike(strike);
            setFilteringBy(undefined);
          }}
        />
      );
    }
    return null;
  }, [
    expiries,
    filteringBy,
    orderTypes,
    selectedValues,
    setExpiry,
    setOptionType,
    setStrike,
    strikes,
    optionTypeText,
    translateAll,
  ]);

  return (
    <Container isMobileScreen={isMobileScreen}>
      {!selectedValues.strike && (
        <SelectStrikeWarning>
          {t("select_strike_price_desc")}
        </SelectStrikeWarning>
      )}
      <Header>
        <Tab
          isActive={
            filteringBy === FilterByEnum.Type || !selectedValues.orderType
          }
          flashing={!selectedValues.orderType}
          onClick={() => onToggleFilter(FilterByEnum.Type)}
          type="button"
        >
          {t(selectedValues.orderType || "type")}
          {!isMobileScreen && (
            <Chevron
              size="small"
              direction={filteringBy === FilterByEnum.Type ? "up" : "down"}
            />
          )}
        </Tab>
        <Tab
          isActive={
            filteringBy === FilterByEnum.Expiry || !selectedValues.expiry
          }
          flashing={!selectedValues.expiry}
          onClick={() => onToggleFilter(FilterByEnum.Expiry)}
          type="button"
        >
          {selectedValues.expiry
            ? moment
                .unix(nanosToSeconds(selectedValues.expiry!))
                .format("DD MMM YY")
            : t("expiry")}
          {!isMobileScreen && (
            <Chevron
              size="small"
              direction={filteringBy === FilterByEnum.Expiry ? "up" : "down"}
            />
          )}
        </Tab>
        <Tab
          isActive={
            filteringBy === FilterByEnum.Strike || !selectedValues.strike
          }
          flashing={!selectedValues.strike}
          onClick={() => onToggleFilter(FilterByEnum.Strike)}
          type="button"
        >
          {selectedValues.strike
            ? currency(selectedValues.strike, { precision: 0 }).format()
            : t("strike")}
          {!isMobileScreen && (
            <Chevron
              size="small"
              direction={filteringBy === FilterByEnum.Strike ? "up" : "down"}
            />
          )}
        </Tab>
      </Header>
      <AnimatePresence>
        {Boolean(filteringBy) && (
          <FilterContent
            transition={{
              duration: 0.1,
              ease: "easeOut",
            }}
            initial={{ height: 0 }}
            animate={{ height: COMPONENTS.tradeModalFilterHeight }}
            exit={{ height: 0 }}
          >
            <Title>
              {t("select")} {t((filteringBy || prevFilteringBy) as string)}
            </Title>
            <FilterSelections>{filterSelections}</FilterSelections>
            <CloseButton onClick={() => setFilteringBy(undefined)}>
              {t("close")}
            </CloseButton>
          </FilterContent>
        )}
      </AnimatePresence>
    </Container>
  );
}

export default OptionFilter;
