import styled, { css } from "styled-components";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";

export const InputContainer = styled.div`
  margin: ${SPACING.three}px 0;
`;

export const InputLabel = styled.span`
  ${FONT_CSS.label.three}
  color: ${TEXT_COLORS.three};
`;

export const MaxButton = styled.button`
  ${FONT_CSS.label.three}
  background-color: ${LAYER_COLORS.four};
  color: ${TEXT_COLORS.two};
  border-radius: 8px;
  padding: ${SPACING.two}px ${SPACING.twoHalf}px;
`;

export const InputError = styled.span`
  color: ${COLORS.negative.one};
  font-size: ${FONT_SIZE.two};
`;

export const InfoRow = styled.div<{ error?: boolean }>`
  ${FONT_CSS.data.four}
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${SPACING.two}px 0;

  span:first-child {
    ${FONT_CSS.label.two}
    color: ${TEXT_COLORS.three};
  }

  ${({ error }) =>
    error &&
    css`
      span {
        color: ${COLORS.negative.one} !important;
      }
    `}
`;

export const HeaderText = styled.p`
  font-size: ${FONT_SIZE};
  margin-bottom: ${SPACING.two}px;
`;
