export interface IRoundRectCornerRadius {
  upperLeft: number;
  upperRight: number;
  lowerLeft: number;
  lowerRight: number;
}

/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Object} radius All corner radii. Defaults to 0,0,0,0;
 * @param {Boolean} fill Whether to fill the rectangle. Defaults to false.
 * @param {Boolean} stroke Whether to stroke the rectangle. Defaults to true.
 */
export const drawRoundRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: IRoundRectCornerRadius,
  fill: boolean,
  stroke: boolean = true,
) => {
  ctx.save();
  const cornerRadius: IRoundRectCornerRadius = {
    upperLeft: 0,
    upperRight: 0,
    lowerLeft: 0,
    lowerRight: 0,
  };
  if (typeof radius === "object") {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const side in radius) {
      // @ts-ignore
      cornerRadius[side] = radius[side];
    }
  }

  ctx.beginPath();
  ctx.moveTo(x + cornerRadius.upperLeft, y);
  ctx.lineTo(x + width - cornerRadius.upperRight, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + cornerRadius.upperRight);
  ctx.lineTo(x + width, y + height - cornerRadius.lowerRight);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - cornerRadius.lowerRight,
    y + height,
  );
  ctx.lineTo(x + cornerRadius.lowerLeft, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - cornerRadius.lowerLeft);
  ctx.lineTo(x, y + cornerRadius.upperLeft);
  ctx.quadraticCurveTo(x, y, x + cornerRadius.upperLeft, y);
  ctx.closePath();
  if (stroke) {
    ctx.stroke();
  }
  if (fill) {
    ctx.fill();
  }
  ctx.restore();
};

export const drawCircle = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: number,
  fill: string,
  stroke: string,
  strokeWidth: number,
) => {
  ctx.save();
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
  if (fill) {
    ctx.fillStyle = fill;
    ctx.fill();
  }
  if (stroke) {
    ctx.lineWidth = strokeWidth;
    ctx.strokeStyle = stroke;
    ctx.stroke();
  }
  ctx.restore();
};
