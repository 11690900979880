import { AxiosError } from "axios";
import useSWRImmutable from "swr/immutable";
import { GetCheckRestricted200Response } from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { publicApi } from "../../../services/api/apiFetcher";

// Check if the current ip is geoblocked.
export const useGeoblock = () => {
  const fetcher = publicApi();
  const swr = useSWRImmutable<GetCheckRestricted200Response, AxiosError>(
    [APIEndpointEnum.GEOBLOCK],
    async () => (await (await fetcher.getCheckRestricted())()).data
  );

  return {
    ...swr,
  };
};
