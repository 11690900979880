import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { InstrumentTypeResponse } from "../../../codegen-api";
import { Select } from "../../shared/Select";

interface IPortfolioMarketFilterProps {
  title: string;
  instrumentType?: InstrumentTypeResponse;
  onSetFilter: (type?: InstrumentTypeResponse) => void;
}

const EXCLUDED_INSTRUMENT_TYPES: InstrumentTypeResponse[] = [InstrumentTypeResponse.Spot];

export function PortfolioMarketFilter({ title, instrumentType, onSetFilter }: IPortfolioMarketFilterProps) {
  const { t } = useTranslation("app", { keyPrefix: "PortfolioSettings.PortfolioMarketFilter" });
  const filters: (InstrumentTypeResponse | undefined)[] = [
    undefined,
    ...Object.values(InstrumentTypeResponse).filter((i) => !EXCLUDED_INSTRUMENT_TYPES.includes(i))
  ];

  const filterName = useCallback((filter?: InstrumentTypeResponse) => {
    switch (filter) {
      case InstrumentTypeResponse.Option:
        return t("option");
      case InstrumentTypeResponse.Perpetual:
        return t("perpetual");
      default:
        return undefined;
    }
  }, [t]);

  return (
    <Select
      isRound
      options={filters.map((filter) => {
        const name = filterName(filter);
        return {
          label: name || `${t("all")} ${title}`,
          isActive: filter === instrumentType,
          onClick: () => onSetFilter(filter),
        };
      })}
    />
  );
}
