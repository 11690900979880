/* eslint-disable no-nested-ternary */
import { AnimationControls } from "framer-motion";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import {
  GetAccount200Response,
  OrderTypeResponse,
  SideResponse,
} from "../../../../codegen-api";
import { COLORS } from "../../../../constants/design/colors";
import { IPerpsMarket } from "../../../../contexts/MarketInstrumentContext/useGetMarkets";
import { Spinner } from "../../../shared/Spinner";
import {
  PlaceOrderButton,
  SubmitContentWrapper,
  SubmitWrapper,
  TradeExecutedBar,
} from "../../style";

export interface IMobileComponentProps {
  isMobileScreen?: boolean;
  perpInstrument?: IPerpsMarket;
  orderType: OrderTypeResponse;
  orderDirection: SideResponse;
  reduceOnlyNotAllowed: boolean;
  reduceOnly: boolean;
  isLoading: boolean;
  animControls: AnimationControls;
  customError?: string;
  replacePlaceOrderButton?: JSX.Element;
  accountData: GetAccount200Response | undefined;
  isSticky?: boolean;
}

function SubmitContent({
  isMobileScreen,
  perpInstrument,
  orderType,
  orderDirection,
  reduceOnlyNotAllowed,
  reduceOnly,
  isLoading,
  animControls,
  customError,
  replacePlaceOrderButton,
  accountData,
  isSticky,
}: PropsWithChildren<IMobileComponentProps>) {
  const { t: commonFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.Common",
  });

  return (
    <SubmitWrapper isSticky={isSticky}>
      <SubmitContentWrapper isMobileScreen={isMobileScreen}>
        {replacePlaceOrderButton || (
          <PlaceOrderButton
            disabled={
              !!customError ||
              accountData?.in_liquidation ||
              isLoading ||
              (reduceOnlyNotAllowed && reduceOnly) ||
              (!!perpInstrument?.pre_launch &&
                orderType === OrderTypeResponse.Market)
            }
            type={"submit"}
            side={orderDirection}
          >
            {!isLoading ? (
              commonFormTranslations("place_order", {
                side: commonFormTranslations(orderDirection.toLowerCase()),
              })
            ) : (
              <Spinner
                color={
                  orderDirection === SideResponse.Buy
                    ? COLORS.positive.one
                    : COLORS.negative.one
                }
              />
            )}
          </PlaceOrderButton>
        )}
        <TradeExecutedBar
          type={orderDirection}
          animate={animControls}
          initial={{
            opacity: 0,
            scale: 0,
          }}
        />
      </SubmitContentWrapper>
    </SubmitWrapper>
  );
}

export default SubmitContent;
