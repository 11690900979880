import styled from "styled-components";
import {
  COLORS,
  FIELD_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../../constants/design/spacing";

export const InputWrapper = styled.div<{ width?: string; error?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${FIELD_COLORS.one};
  border-radius: ${BORDER_RADIUS.five}px;
  width: ${(props) => props.width ?? "100%"};
  padding: ${SPACING.two}px;
  height: ${SPACING.six}px;

  border: ${({ error }) =>
    error ? `1px solid ${COLORS.negative.one}` : "none"};

  input {
    ${FONT_CSS.data.two}
    color: ${TEXT_COLORS.one};
    background-color: transparent;
    border: none;
    padding-left: 8px;
    // This fixes the input overflowing the container
    flex: 1;
    width: 0;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${TEXT_COLORS.four};
      opacity: 1; /* Firefox */
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }

    &:disabled {
      color: ${TEXT_COLORS.two};
      cursor: not-allowed;
    }
  }
`;

export const SuffixWrapper = styled.div`
  position: absolute;
  right: 0;
`;

export const DigitInputsWrapper = styled.div`
  display: flex;

  > div:not(:last-child) {
    margin-right: ${SPACING.two}px;
  }

  input {
    padding: 0;
    text-align: center;
  }
`;
