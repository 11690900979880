import { AssetResponse, assets } from "../../utils/asset";

type AssetResponseToEnabled = {
  [key in AssetResponse]?: boolean;
};

export const optionsEnabledAssets: AssetResponseToEnabled = {
  ETH: true,
  BTC: true,
};

export const disabledAssets: AssetResponse[] = (() => {
  if (process.env.REACT_APP_DISABLED_ASSETS) {
    try {
      return JSON.parse(
        process.env.REACT_APP_DISABLED_ASSETS
      ) as AssetResponse[];
    } catch (error) {
      console.log("Error parsing disabled assets", error);
    }
  }
  return [];
})();

export const allAssets: AssetResponse[] = Object.keys(assets).filter(
  (a) => !disabledAssets.includes(a)
);

type IInstrumentToDefaultTickSize = {
  [instrumentName: string]: number;
};
export const instrumentDefaultOrderbookTickSize: IInstrumentToDefaultTickSize =
  {
    "ETH-PERP": 0.1,
    "BTC-PERP": 1,
    "SOL-PERP": 0.1,
  };
