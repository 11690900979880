import { useTranslation } from "react-i18next";
import { BaseModal } from "../BaseModal";
import {
  InfoRow,
  MarketDetailsDescription,
  MarketDetailsHeader,
  MarketDetailsInfo,
  Title,
  Value,
} from "./style";
import { getAssetLogo } from "../../utils/asset/assets";
import { getAssetInfo } from "../../utils/asset";
import { COLORS } from "../../constants/design/colors";
import { FUNDING_RATE_PRECISION } from "../../constants/precision/form";
import { GetStatistics200Response } from "../../codegen-api";
import { IPerpsMarket } from "../../contexts/MarketInstrumentContext/useGetMarkets";

interface IMarketDetailsModalProps {
  show?: boolean;
  onHide?: () => void;
  statsData?: GetStatistics200Response;
  perpInstrument?: IPerpsMarket;
}

function MarketDetailsModal({
  show,
  onHide,
  statsData,
  perpInstrument,
}: IMarketDetailsModalProps) {
  const { t } = useTranslation("app", { keyPrefix: "MarketDetailsModal" });
  return (
    <BaseModal
      style={{ width: 311 }}
      show={show}
      title={
        <MarketDetailsHeader>
          <img
            src={getAssetLogo(perpInstrument?.underlying_asset)}
            alt={perpInstrument?.underlying_asset}
            style={{
              width: 32,
              height: 32,
            }}
          />
          {perpInstrument?.instrument_name}
        </MarketDetailsHeader>
      }
      onHide={onHide}
    >
      <MarketDetailsDescription>
        {getAssetInfo(perpInstrument?.underlying_asset).description}
        <MarketDetailsInfo>
          <InfoRow>
            <Title>{t("min_contract_size")}</Title>
            <Value>
              {perpInstrument?.quote_asset
                ? `${perpInstrument.amount_step} ${perpInstrument?.underlying_asset}`
                : "---"}
            </Value>
          </InfoRow>
          <InfoRow>
            <Title>{t("min_notional_value")}</Title>
            <Value>
              {perpInstrument?.quote_asset
                ? `10 ${perpInstrument?.quote_asset}`
                : "---"}
            </Value>
          </InfoRow>
          <InfoRow>
            <Title>{t("tick_size")}</Title>
            <Value>{perpInstrument?.price_step}</Value>
          </InfoRow>
          <InfoRow>
            <Title>{t("settlement_asset")}</Title>
            <Value>{perpInstrument?.quote_asset ?? "---"}</Value>
          </InfoRow>
          <InfoRow>
            <Title>{t("avg_1h_funding")}</Title>
            {!statsData ? (
              <Value>-</Value>
            ) : (
              <Value
                style={{
                  color:
                    Number(statsData.funding_daily_avg) >= 0
                      ? COLORS.positive.one
                      : COLORS.negative.one,
                }}
              >
                {Number(statsData.funding_daily_avg) >= 0 ? "+" : ""}
                {(Number(statsData.funding_daily_avg) * 100).toFixed(
                  FUNDING_RATE_PRECISION
                )}
                %
              </Value>
            )}
          </InfoRow>
        </MarketDetailsInfo>
      </MarketDetailsDescription>
    </BaseModal>
  );
}

export default MarketDetailsModal;
