import styled from "styled-components";
import {
  BACKGROUND_COLORS,
  COLORS,
  ICON_COLORS,
  LAYER_COLORS,
} from "../../../../constants/design/colors";
import { FONT_CSS } from "../../../../constants/design/fontSize";
import { SPACING } from "../../../../constants/design/spacing";
import { Button } from "../../../Buttons/styles";

export const QRCodeScannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  text-align: center;
  padding: ${SPACING.five}px;
  background: ${BACKGROUND_COLORS.one};

  width: 100%;
  flex: 1;

  section {
    div {
      background-color: red;
    }

    div:first-child {
      display: none;
    }
  }

  > div:first-child {
    display: flex;
    flex-direction: column;

    span {
      ${FONT_CSS.body.five}
    }

    span:first-child {
      ${FONT_CSS.header.six}
      margin-bottom: ${SPACING.two}px;
    }
  }

  ${Button} {
    z-index: 1;
    svg {
      stroke: ${ICON_COLORS.one};
    }
  }
`;

export const CenteredContainer = styled.div`
  position: absolute;
  left: ${SPACING.five}px;
  right: ${SPACING.five}px;
  top: ${SPACING.five}px;
  bottom: ${SPACING.five}px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    ${FONT_CSS.label.one}
    margin-top: ${SPACING.two}px;
    color: ${COLORS.negative.one};
  }
`;

export const ScannerFrame = styled.div`
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: ${SPACING.three}px;
  background-color: ${LAYER_COLORS.four};
`;
