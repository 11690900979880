import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../../constants/design/spacing";
import { frostedGlassBg } from "../../../components/shared/style";

export const MobilePortfolioRow = styled(motion.div)<{
  $noPadding?: boolean;
  isNested?: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: ${({ $noPadding }) => ($noPadding ? 0 : SPACING.three)}px;
  background: ${LAYER_COLORS.two};
  border-radius: ${BORDER_RADIUS.five}px;
  margin-top: ${SPACING.two}px;
  ${({ isNested }) =>
    isNested &&
    css`
      padding: ${SPACING.three}px;
      background: ${LAYER_COLORS.three};
    `}
`;

export const Header = styled.div`
  display: flex;
  > * {
    margin: auto 0;
  }

  p {
    ${FONT_CSS.header.seven}
    margin-left: ${SPACING.three}px;
    text-transform: capitalize;
  }
`;

export const Footer = styled.div`
  margin-top: ${SPACING.three}px;
`;

export const Stat = styled.div`
  flex: 1;
  &:not(:last-of-type) {
    margin-right: ${SPACING.five}px;
  }
`;

export const StatValue = styled.p<{ color?: string }>`
  ${FONT_CSS.data.three}
  color: ${(props) => props.color ?? TEXT_COLORS.two};
  margin: 0;
  min-width: 60px;
  text-transform: capitalize;
`;

export const Stats = styled.div`
  display: flex;

  &:not(:first-child) {
    margin-top: ${SPACING.three}px;
  }
`;

export const Actions = styled.div`
  display: flex;
  margin-top: ${SPACING.three}px;
  height: fit-content;
  gap: ${SPACING.two}px;

  > * {
    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const Pill = styled.span<{ isPositive: boolean; borderRadius?: string }>`
  ${FONT_CSS.data.three}
  border-radius: ${({ borderRadius }) => borderRadius ?? "8px"};
  padding: ${SPACING.one}px ${SPACING.two}px;
  margin-left: ${SPACING.two}px;

  ${({ isPositive }) => `
  background-color: ${isPositive ? COLORS.positive.five : COLORS.negative.five};
  color: ${isPositive ? COLORS.positive.one : COLORS.negative.one};
  `};
`;

export const Separator = styled.span`
  margin: auto ${SPACING.one}px;
`;

export const PositionContent = styled.div`
  display: flex;
  align-items: center;

  ${FONT_CSS.header.eight}
  text-transform: capitalize;
`;

export const PortfolioFilterContainer = styled.div`
  padding: ${SPACING.three}px;
  background-color: ${COLORS.background.one};
  overflow-x: scroll;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 3;
  ${frostedGlassBg()}
`;

export const SettlementInProgress = styled.div`
  ${FONT_CSS.body.six}
  color: ${COLORS.highlight.one};
  margin-top: ${SPACING.one}px;
`;

export const MobileWrapper = styled.div<{ noPadding?: boolean }>``;
