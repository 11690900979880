import moment from "moment";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PortfolioSettlementHistory from "../../components/PortfolioSettings/PortfolioSettlementHistory";
import DownloadCSVButton from "../../components/PortfolioSettings/Table/DownloadCSVButton";
import {
  Header,
  SpaceBetweenInnerHeader,
} from "../../components/PortfolioSettings/Table/style";
import { Select } from "../../components/shared/Select";
import { TableContainerWrapper } from "../../components/shared/Table/style";
import { allAssets, optionsEnabledAssets } from "../../constants/assets";
import { useSettlementHistory } from "../../hooks/api/settlementHistory/useSettlementHistory";
import { nanosToSeconds } from "../../utils/date";
import { AssetResponse } from "../../utils/asset";
import { IHasMobileComponentProps } from "../shared";
import { ComponentTitle } from "../../components/PortfolioSettings/style";
import Pagination from "../../components/shared/Pagination";
import { PortfolioPaginationInlineStyle } from "../../components/shared/Pagination/style";
import { PaginationPerPageOptionsEnum } from "../../enums/pagination";

function SettlementHistoryTab({ isMobile }: IHasMobileComponentProps) {
  const [assetFilter, setAssetFilter] = useState<AssetResponse>();
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(
    PaginationPerPageOptionsEnum.TWENTY_FIVE
  );
  const { data, isValidating } = useSettlementHistory(assetFilter);
  const { t } = useTranslation("app", {
    keyPrefix: "pages.SettlementHistoryTab",
  });

  const settlementHistory = useMemo(
    () =>
      (data || []).slice(
        currentPage * perPage,
        currentPage * perPage + perPage
      ),
    [currentPage, perPage, data]
  );

  const csvData = useMemo(() => {
    if (data && data.length) {
      const headers = [
        t("asset"),
        t("expiry"),
        t("settlement_price"),
        t("date_time"),
      ];
      const rows =
        data.map(
          (v) => [
            v.asset,
            v.expiry,
            v.settlement_price,
            moment
              .unix(nanosToSeconds(v.settlement_timestamp))
              .utc()
              .toISOString(),
          ],
          []
        ) || [];

      return [headers, ...rows];
    }
    return undefined;
  }, [data, t]);

  return (
    <TableContainerWrapper $isMobile={isMobile}>
      <Header isMobile={isMobile}>
        {!isMobile && (
          <ComponentTitle>{t("settlement_history")}</ComponentTitle>
        )}
        <SpaceBetweenInnerHeader>
          <Select
            isRound
            options={[
              undefined,
              ...allAssets.filter((a) => optionsEnabledAssets[a]),
            ].map((asset) => {
              if (asset) {
                return {
                  label: asset,
                  isActive: asset === assetFilter,
                  onClick: () => setAssetFilter(asset),
                };
              }
              return {
                label: t("all_options"),
                isActive: !assetFilter,
                onClick: () => setAssetFilter(undefined),
              };
            })}
          />
          <DownloadCSVButton
            data={csvData}
            filename={`${t("aevo_settlement_history")} ${moment().format(
              "DD_MMM_YYYY_HH_mm_ss"
            )}`}
          />
        </SpaceBetweenInnerHeader>
      </Header>
      <PortfolioSettlementHistory
        settlementHistories={settlementHistory}
        loading={!settlementHistory && isValidating}
        isMobile={isMobile}
      />
      <Pagination
        isMobile={isMobile}
        containerStyle={PortfolioPaginationInlineStyle}
        currentPage={currentPage}
        totalPages={data?.length ? Math.ceil(Number(data.length) / perPage) : 0}
        onPrevPage={() => setCurrentPage(currentPage - 1)}
        onNextPage={() => setCurrentPage(currentPage + 1)}
        itemCountConfig={{
          totalItems: data?.length || 0,
          perPage,
          perPageDropdownOptions: Object.values(
            PaginationPerPageOptionsEnum
          ).filter(Number) as number[],
          onPerPageDropdownSelect: (v) => {
            setCurrentPage(0);
            setPerPage(v);
          },
        }}
      />
    </TableContainerWrapper>
  );
}

export default SettlementHistoryTab;
