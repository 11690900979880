import currency from "currency.js";
import moment from "moment";
import { TFunction } from "react-i18next";
import { useState } from "react";
import {
  InstrumentTypeResponse,
  MarginTypeResponse,
  SideResponse,
} from "../../../../codegen-api";
import { IModifiedPosition } from "../../../../components/Positions/model";
import RowActionButton from "../../../../components/shared/RowActionButton";
import {
  TPSLCell,
  TPSLContainer,
} from "../../../../components/shared/Table/style";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../../constants/design/colors";
import { SPACING } from "../../../../constants/design/spacing";
import { getAssetLogo } from "../../../../utils/asset/assets";
import { nanosToSeconds } from "../../../../utils/date";
import { Label } from "../../../PortfolioOverviewTab/style";
import {
  Actions,
  Footer,
  Header,
  MobilePortfolioRow,
  Pill,
  PositionContent,
  Separator,
  SettlementInProgress,
  Stat,
  StatValue,
  Stats,
} from "../style";
import { Chevron } from "../../../../components/shared/Chevron/style";
import { startCase } from "../../../../utils/strings";
import { Divider } from "../../../../components/shared/Divider";

const pnlColor = (pnl: string) => {
  if (pnl && Number(pnl) > 0) {
    return COLORS.positive.one;
  }
  if (pnl && Number(pnl) < 0) {
    return COLORS.negative.one;
  }
  return TEXT_COLORS.one;
};

export enum OnModifyPositionTypeEnum {
  TPSL_EDIT,
  PARTIAL_TPSL_DETAIL,
  PARTIAL_TPSL_ADD,
  EDIT,
  CLOSE,
  VIEW_DETAIL,
  SHARE,
}

interface IMobilePositionRowProps {
  position: IModifiedPosition;
  t: TFunction;
  noAnimation?: boolean;
  noLogo?: boolean;
  noPadding?: boolean;
  delay?: number;
  inLiquidation?: boolean;

  onModifyPosition: (
    type: OnModifyPositionTypeEnum,
    position: IModifiedPosition
  ) => void;

  isNested?: boolean;
}

function MobilePositionRow({
  position,
  noAnimation,
  noLogo,
  noPadding,
  delay,
  t,
  inLiquidation,
  onModifyPosition,
  isNested,
}: IMobilePositionRowProps) {
  const isPartialPosition =
    (position.partialPositionTriggers?.take_profit?.length ?? 0) > 0 ||
    (position.partialPositionTriggers?.stop_loss?.length ?? 0) > 0;

  const [assetExpanded, setAssetExpanded] = useState(false);

  const buttonBgColor = isNested ? LAYER_COLORS.four : undefined;

  const animProps = {
    transition: noAnimation
      ? {}
      : {
          delay,
          duration: 0.5,
          ease: "easeInOut",
        },
    initial: noAnimation
      ? {}
      : {
          transform: "translateY(-8px)",
          opacity: 0,
        },
    animate: noAnimation
      ? {}
      : {
          transform: "translateY(0px)",
          opacity: 1,
        },
    exit: noAnimation
      ? {}
      : {
          opacity: 0,
        },
  };

  // Render nested
  if (position.nestedOptionsData) {
    const assetPositions = Object.values(position.nestedOptionsData)
      .map(({ positions }) => positions)
      .flat();
    return (
      <MobilePortfolioRow
        {...animProps}
        $noPadding={noPadding}
        key={position.instrument_id}
      >
        <Header
          style={{ cursor: "pointer" }}
          onClick={() => setAssetExpanded((ex) => !ex)}
        >
          <img
            src={getAssetLogo(position.asset)}
            alt={position.asset}
            width={32}
            height={32}
            style={{ marginRight: SPACING.two }}
          />
          <PositionContent>
            <div>
              <div>
                {position.asset} {t("options")}
              </div>
            </div>
          </PositionContent>
          <Chevron
            style={{ marginLeft: "auto" }}
            direction={assetExpanded ? "up" : "down"}
          />
        </Header>
        <div
          style={{
            height: assetExpanded
              ? (296 + 1 + SPACING.three) * assetPositions.length -
                SPACING.three // no. of rows * rowHeight + divider
              : 0,
            transition: "all 0.15s ease-in-out",
            overflow: "hidden",
          }}
        >
          {assetPositions.map((p, i) => (
            <>
              <MobilePositionRow
                key={p.instrument_id}
                noAnimation
                noLogo
                noPadding
                position={p}
                t={t}
                inLiquidation={inLiquidation}
                onModifyPosition={onModifyPosition}
                isNested
              />
              {i < assetPositions.length - 1 && (
                <Divider
                  direction="horizontal"
                  size="100%"
                  style={{
                    marginTop: SPACING.three,
                  }}
                />
              )}
            </>
          ))}
        </div>
      </MobilePortfolioRow>
    );
  }

  return (
    <MobilePortfolioRow
      {...animProps}
      $noPadding={noPadding}
      key={position.instrument_id}
      isNested={isNested}
    >
      <Header>
        {!noLogo && (
          <img
            src={getAssetLogo(position.asset)}
            alt={position.asset}
            width={32}
            height={32}
            style={{ marginRight: SPACING.two }}
          />
        )}
        <PositionContent>
          <div>
            <div>
              {position.option ? (
                <>
                  {position.asset}
                  <Separator>{"⸱"}</Separator>
                  {startCase(position.option.option_type)}
                  <Separator>{"⸱"}</Separator>
                  {moment
                    .unix(nanosToSeconds(position.option.expiry))
                    .format("DD MMM YY")}
                  <Separator>{"⸱"}</Separator>
                  {currency(position.option.strike, {
                    precision: 0,
                  }).format()}
                </>
              ) : (
                position.instrument_name
              )}
            </div>
            {position.option &&
              moment
                .unix(nanosToSeconds(position.option.expiry))
                .isSameOrBefore(moment()) && (
                <SettlementInProgress>
                  {t("settlement_in_progress")}
                </SettlementInProgress>
              )}
          </div>
          <Pill
            borderRadius="8px"
            isPositive={position.side === SideResponse.Buy}
          >
            {position.side === SideResponse.Buy ? t("long") : t("short")}
          </Pill>
        </PositionContent>
      </Header>
      <Footer>
        <Stats>
          <Stat>
            <Label>{t("size")}</Label>
            <StatValue>
              {Number(position.amount).toFixed(position.amountPrecision)}
            </StatValue>
          </Stat>
          <Stat>
            <Label>{t("position_value")}</Label>
            <StatValue>
              {currency(Number(position.amount) * Number(position.mark_price), {
                precision: position.pricePrecision,
              }).format()}
            </StatValue>
          </Stat>
        </Stats>
        <Stats>
          <Stat>
            <Label>{t("pnl")}</Label>
            {position.unrealized_pnl ? (
              <StatValue color={pnlColor(position.unrealized_pnl)}>
                {currency(position.unrealized_pnl, {
                  precision: 2,
                }).format()}
              </StatValue>
            ) : (
              <StatValue color={TEXT_COLORS.one}>-</StatValue>
            )}
          </Stat>
          <Stat>
            <Label>
              {t("margin")}{" "}
              {position.margin_type === MarginTypeResponse.Isolated ? (
                <>
                  -{" "}
                  <span style={{ color: COLORS.blue.one }}>
                    {t("isolated")}
                  </span>
                </>
              ) : null}
            </Label>
            <StatValue>
              {currency(
                position.margin_type === MarginTypeResponse.Isolated
                  ? position.isolated_margin || 0
                  : position.maintenance_margin || 0,
                {
                  precision: position.pricePrecision,
                }
              ).format()}
            </StatValue>
          </Stat>
        </Stats>
        <Stats>
          <Stat>
            <Label>{t("tpsl_for_position")}</Label>
            <StatValue>
              <TPSLContainer>
                <TPSLCell>
                  <span>
                    {position.closePositionTriggers?.take_profit?.trigger ? (
                      currency(
                        position.closePositionTriggers?.take_profit?.trigger ||
                          0,
                        {
                          precision: position.pricePrecision,
                        }
                      ).format()
                    ) : (
                      <div style={{ width: SPACING.four }}>{"-"}</div>
                    )}
                  </span>
                  <span>
                    {position.closePositionTriggers?.stop_loss?.trigger ? (
                      currency(
                        position.closePositionTriggers?.stop_loss?.trigger || 0,
                        {
                          precision: position.pricePrecision,
                        }
                      ).format()
                    ) : (
                      <div style={{ width: SPACING.four }}>{"-"}</div>
                    )}
                  </span>
                </TPSLCell>
                <RowActionButton
                  variant="edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    onModifyPosition(
                      OnModifyPositionTypeEnum.TPSL_EDIT,
                      position
                    );
                  }}
                  disabled={
                    position.instrument_type !==
                    InstrumentTypeResponse.Perpetual
                  }
                  style={{ marginLeft: SPACING.two }}
                  svgBgColor={buttonBgColor}
                />
              </TPSLContainer>
            </StatValue>
          </Stat>
          <Stat>
            <Label>{t("partial_tpsl_for_position")}</Label>
            <StatValue>
              <TPSLContainer>
                <RowActionButton
                  variant="partial"
                  text={"Detail"}
                  onClick={(e) => {
                    e.stopPropagation();
                    onModifyPosition(
                      OnModifyPositionTypeEnum.PARTIAL_TPSL_DETAIL,
                      position
                    );
                  }}
                  disabled={
                    position.instrument_type !==
                      InstrumentTypeResponse.Perpetual || !isPartialPosition
                  }
                  svgBgColor={buttonBgColor}
                />
                <RowActionButton
                  variant={"plus"}
                  onClick={(e) => {
                    e.stopPropagation();
                    onModifyPosition(
                      OnModifyPositionTypeEnum.PARTIAL_TPSL_ADD,
                      position
                    );
                  }}
                  disabled={
                    position.instrument_type !==
                    InstrumentTypeResponse.Perpetual
                  }
                  style={{ marginLeft: SPACING.two }}
                  svgBgColor={buttonBgColor}
                />
              </TPSLContainer>
            </StatValue>
          </Stat>
        </Stats>
      </Footer>
      <Actions>
        <RowActionButton
          variant={"edit"}
          disabled={!position.isolated_margin}
          onClick={(e) => {
            e.stopPropagation();
            onModifyPosition(OnModifyPositionTypeEnum.EDIT, position);
          }}
          svgBgColor={buttonBgColor}
        />
        {!inLiquidation && (
          <RowActionButton
            variant={"close"}
            onClick={(e) => {
              e.stopPropagation();
              onModifyPosition(OnModifyPositionTypeEnum.CLOSE, position);
            }}
            svgBgColor={buttonBgColor}
          />
        )}
        <RowActionButton
          variant={"details"}
          onClick={(e) => {
            e.stopPropagation();
            onModifyPosition(OnModifyPositionTypeEnum.VIEW_DETAIL, position);
          }}
          svgBgColor={buttonBgColor}
        />
        <RowActionButton
          variant="share"
          svgColor={
            Number(position.unrealized_pnl) >= 0
              ? COLORS.positive.one
              : COLORS.negative.one
          }
          onClick={(e) => {
            e.stopPropagation();
            onModifyPosition(OnModifyPositionTypeEnum.SHARE, position);
          }}
          svgBgColor={buttonBgColor}
        />
      </Actions>
    </MobilePortfolioRow>
  );
}

export default MobilePositionRow;
