import currency from "currency.js";
import { CSSProperties, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { PageEndpointEnum } from "../../../enums/endpoint";
import { useGetAccount } from "../../../hooks/api/account/useGetAccount";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";
import {
  initialMarginUtilization,
  maintenanceMarginUtilization,
} from "../../../utils/math";
import FillMeter from "../../FillMeter";
import {
  BalanceLink,
  ExchangeBalanceContainer,
  MarginUtilizationContainer,
  MobileUtilizationWrapper,
  TextValues,
} from "./style";

interface IBalanceMarginUtilizationProps {
  containerStyle?: CSSProperties;
}

function BalanceMarginUtilization({
  containerStyle,
}: IBalanceMarginUtilizationProps) {
  const { data: accountData } = useGetAccount();
  const { isMobileScreen } = useScreenSize();
  const { t } = useTranslation("app", {
    keyPrefix: "shared.BalanceMarginUtilization",
  });

  const equityString = useMemo(() => {
    if (accountData?.equity) {
      return currency(accountData.equity).format();
    }
    return "---";
  }, [accountData]);

  const maintenanceUtilization = useMemo(() => {
    if (!accountData) {
      return undefined;
    }

    const equity = accountData?.equity ? Number(accountData.equity) : 0;
    const maintenanceMargin = accountData?.maintenance_margin
      ? Number(accountData.maintenance_margin)
      : 0;

    return maintenanceMarginUtilization(equity, maintenanceMargin);
  }, [accountData]);

  const initialUtilization = useMemo(() => {
    if (!accountData) {
      return undefined;
    }

    const equity = accountData?.equity ? Number(accountData.equity) : 0;
    const initialMargin = accountData?.initial_margin
      ? Number(accountData.initial_margin)
      : 0;
    const maintenanceMargin = accountData?.maintenance_margin
      ? Number(accountData.maintenance_margin)
      : 0;

    return initialMarginUtilization(equity, initialMargin, maintenanceMargin);
  }, [accountData]);

  const utilizationColor = useCallback((percent: number) => {
    if (percent >= 50 && percent < 80) {
      return COLORS.system.one;
    }

    if (percent >= 80) {
      return COLORS.negative.one;
    }

    return COLORS.blue.one;
  }, []);

  return (
    <BalanceLink style={containerStyle} to={PageEndpointEnum.PORTFOLIO}>
      <ExchangeBalanceContainer isMobileScreen={isMobileScreen}>
        <TextValues>
          <span>{t("equity")}</span>
          <span>{equityString}</span>
        </TextValues>
      </ExchangeBalanceContainer>
      {!isMobileScreen ? (
        <>
          <MarginUtilizationContainer>
            <TextValues>
              <span>{t("im_utilization")}</span>
              {initialUtilization === undefined ? (
                "---"
              ) : (
                <FillMeter
                  layoutReversed
                  decimals={2}
                  fill={String(initialUtilization)}
                  textColor={utilizationColor(initialUtilization)}
                  barColor={utilizationColor(initialUtilization)}
                />
              )}
            </TextValues>
          </MarginUtilizationContainer>
          <MarginUtilizationContainer>
            <TextValues style={{ marginRight: 0 }}>
              <span>{t("mm_utilization")}</span>
              {maintenanceUtilization === undefined ? (
                "---"
              ) : (
                <FillMeter
                  layoutReversed
                  decimals={2}
                  fill={String(maintenanceUtilization)}
                  textColor={utilizationColor(maintenanceUtilization)}
                  barColor={utilizationColor(maintenanceUtilization)}
                />
              )}
            </TextValues>
          </MarginUtilizationContainer>
        </>
      ) : (
        <MobileUtilizationWrapper isMobileScreen>
          <MarginUtilizationContainer isMobileScreen>
            <TextValues>
              <span>{t("im")}</span>
              {initialUtilization === undefined ? (
                "---"
              ) : (
                <FillMeter
                  layoutReversed
                  decimals={2}
                  fill={String(initialUtilization)}
                  textColor={TEXT_COLORS.one}
                  barColor={utilizationColor(initialUtilization)}
                />
              )}
            </TextValues>
          </MarginUtilizationContainer>
          <MarginUtilizationContainer isMobileScreen>
            <TextValues>
              <span>{t("mm")}</span>
              {maintenanceUtilization === undefined ? (
                "---"
              ) : (
                <FillMeter
                  layoutReversed
                  decimals={2}
                  fill={String(maintenanceUtilization)}
                  textColor={TEXT_COLORS.one}
                  barColor={utilizationColor(maintenanceUtilization)}
                />
              )}
            </TextValues>
          </MarginUtilizationContainer>
        </MobileUtilizationWrapper>
      )}
    </BalanceLink>
  );
}

export default BalanceMarginUtilization;
