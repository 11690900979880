import React, { ReactElement, useState } from "react";

interface IConnectWalletContextType {
  yieldVaultToggle: boolean;
  setYieldVaultToggle: (yes: boolean) => void;
  showConnectModal: boolean;
  setShowConnectModal: (show: boolean) => void;
  hideOnConnectWallet: boolean;
  setHideOnConnectWallet: (hide: boolean) => void;
}

interface IConnectWalletContextProviderProps {
  children: ReactElement;
}

export const ConnectWalletContext =
  React.createContext<IConnectWalletContextType>({
    yieldVaultToggle: false,
    setYieldVaultToggle: () => {},
    showConnectModal: false,
    setShowConnectModal: () => {},
    hideOnConnectWallet: false,
    setHideOnConnectWallet: () => {},
  });

export function ConnectWalletContextProvider({
  children,
}: IConnectWalletContextProviderProps) {
  const [yieldVaultToggle, setYieldVaultToggle] = useState(false);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [hideOnConnectWallet, setHideOnConnectWallet] = useState(false);

  return (
    <ConnectWalletContext.Provider
      value={{
        yieldVaultToggle,
        setYieldVaultToggle,
        showConnectModal,
        setShowConnectModal,
        hideOnConnectWallet,
        setHideOnConnectWallet,
      }}
    >
      {children}
    </ConnectWalletContext.Provider>
  );
}
