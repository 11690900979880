import { motion } from "framer-motion";
import styled from "styled-components";
import { BORDER_COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";

export const OrderbookWrapper = styled(motion.div)`
  border-top: 1px solid ${BORDER_COLORS.one};
`;

export const OrderbookSelect = styled.div`
  height: ${COMPONENTS.tabs}px;
  display: flex;
  align-items: center;
  padding: 0 ${SPACING.two}px;
  border-top: 1px solid ${BORDER_COLORS.one};
  border-bottom: 1px solid ${BORDER_COLORS.one};
  overflow-x: scroll;
`;

export const ChartContainer = styled.div`
  height: 264px;
  margin: 0 -15px;
  width: calc(100% + 18px);
`;

export const PayoffDataContainer = styled.div`
  padding: ${SPACING.four}px ${SPACING.three}px;
`;

export const PayoffDataRow = styled.div<{
  valueColor?: string;
}>`
  ${FONT_CSS.label.two}
  display: flex;
  justify-content: space-between;
  color: ${TEXT_COLORS.three};

  &:not(:last-of-type) {
    padding-bottom: ${SPACING.three}px;
  }

  span:last-child {
    ${FONT_CSS.data.four}
    color: ${({ valueColor }) => valueColor || TEXT_COLORS.one};
  }
`;

export const TicketGreeksWrapper = styled.div`
  padding: ${SPACING.one}px ${SPACING.three}px;
  padding-bottom: ${SPACING.three}px;

  > *:not(:last-child) {
    margin-bottom: ${SPACING.three}px;
  }
`;
