import { Web3Provider } from "@ethersproject/providers";
import { useMemo } from "react";
import type { Client } from "viem";
import { useClient, useConnectorClient, useAccount } from "wagmi";
import { ChainIdEnum } from "../../enums/chain";

// file copied from https://github.com/Uniswap/interface/blob/main/apps/web/src/hooks/useEthersProvider.ts

const providers = new WeakMap<Client, Web3Provider>();

function clientToProvider(client?: any, chainId?: number) {
  if (!client) {
    return undefined;
  }
  const { chain, transport } = client;

  const ensAddress = chain ? chain.contracts?.ensRegistry?.address : undefined;
  const network =
    chain && ensAddress
      ? {
          chainId: chain.id,
          name: chain.name,
          ensAddress,
        }
      : chainId
      ? { chainId, name: "Unsupported" }
      : undefined;
  if (!network) {
    return undefined;
  }

  if (providers?.has(client)) {
    return providers.get(client);
  }
  const provider = new Web3Provider(transport, network);
  providers.set(client, provider);
  return provider;
}

/** Hook to convert a viem Client to an ethers.js Provider with a default disconnected Network fallback. */
export function useEthersProvider({ chainId }: { chainId?: ChainIdEnum } = {}) {
  const account = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const disconnectedClient = useClient({ chainId });
  return useMemo(
    () =>
      clientToProvider(
        account.chainId !== chainId
          ? disconnectedClient
          : client ?? disconnectedClient,
        chainId
      ),
    [account.chainId, chainId, client, disconnectedClient]
  );
}

/** Hook to convert a connected viem Client to an ethers.js Provider. */
export function useEthersWeb3Provider({
  chainId,
}: { chainId?: ChainIdEnum } = {}) {
  const { data: client } = useConnectorClient({ chainId });
  return useMemo(() => clientToProvider(client, chainId), [chainId, client]);
}
