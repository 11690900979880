import { AxiosError } from "axios";
import currency from "currency.js";
import moment from "moment";
import { useCallback, useContext, useMemo } from "react";
import useSWR from "swr";
import {
  GenericErrorResponse,
  GetAccount200Response,
} from "../../../codegen-api";
import { AuthContext } from "../../../contexts/AuthContext";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { authApi } from "../../../services/api/apiFetcher";
import { pollingInterval } from "../../../services/api/pollingInterval";
import { nanosToSeconds } from "../../../utils/date";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";

// Get the current account
export const useGetAccount = () => {
  // Get API Key
  const { apiKey, apiSecret, deleteAuthInfo } = useContext(AuthContext);
  const fetcher = authApi(apiKey, apiSecret);

  const swr = useSWR<GetAccount200Response, Error>(
    // If apiKey is not available, the request will not fetch
    apiKey ? [APIEndpointEnum.ACCOUNT, apiKey] : undefined,
    async () => {
      const accountData = (await (await fetcher.getAccount())()).data;
      if (accountData?.signing_keys?.length === 0) {
        deleteAuthInfo("signing");
      }
      return {
        ...accountData,
        positions: (accountData.positions || []).sort((a, b) => {
          if (a.option && b.option) {
            if (a.option.expiry === b.option.expiry) {
              // if expiry is the same, sort by num of contracts
              // largest to smallest
              return Number(b.amount) - Number(a.amount);
            }
            return (
              moment.unix(nanosToSeconds(a.option.expiry)).toDate().getTime() -
              moment.unix(nanosToSeconds(b.option.expiry)).toDate().getTime()
            );
          }
          return a.instrument_name.localeCompare(b.instrument_name);
        }),
      };
    },
    {
      use: [useLoggerMiddleware],
      refreshInterval: pollingInterval[APIEndpointEnum.ACCOUNT],
      onError: (e) => {
        const error = e as AxiosError;
        const isUnauthorized =
          error.response?.status === 401 ||
          error.response?.data?.trim?.() === "INVALID_TOKEN";
        if (isUnauthorized) {
          // Delete auth info, clear account data
          deleteAuthInfo("api");
          swr.mutate(undefined, { revalidate: false });
        }
      },
    }
  );

  const showOnboarding = useMemo(() => {
    if (swr.data) {
      return (
        currency(swr.data.available_balance).value === 0 &&
        currency(swr.data.equity).value === 0
      );
    }
    return undefined;
  }, [swr.data]);

  const removeApiKey = useCallback(
    async (key: string) => {
      try {
        await (
          await fetcher.deleteApiKey({ api_key: key })
        )();
        swr.mutate();
      } catch (error) {
        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        throw Error(
          genericResponseAxiosError.response?.data?.error ||
            "Error removing API key"
        );
      }
    },
    [fetcher, swr]
  );

  const removeSigningKey = useCallback(
    async (key: string) => {
      try {
        await (
          await fetcher.deleteSigningKey({ signing_key: key })
        )();
        const mutatedData = swr.data
          ? {
              ...swr.data,
              signing_keys: (swr.data.signing_keys || []).filter(
                (k) => k.signing_key !== key
              ),
            }
          : undefined;
        swr.mutate(mutatedData, {
          revalidate: false,
        });
      } catch (error) {
        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        throw Error(
          genericResponseAxiosError.response?.data?.error ||
            "Error removing API key"
        );
      }
    },
    [fetcher, swr]
  );

  const addApiKey = useCallback(
    async (name: string, read_only: boolean = false) => {
      try {
        await (
          await fetcher.postApiKey({ name, read_only })
        )();
        swr.mutate();
      } catch (error) {
        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        throw Error(
          genericResponseAxiosError.response?.data?.error ||
            "Error adding API key"
        );
      }
    },
    [fetcher, swr]
  );

  const updateIsolatedMargin = useCallback(
    async (instrumentId: string, margin: string) => {
      try {
        await (
          await fetcher.postAccountUpdateMargin({
            instrument: Number(instrumentId),
            isolated_margin: margin,
          })
        )();
        swr.mutate();
      } catch (error) {
        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        throw Error(
          genericResponseAxiosError.response?.data?.error ||
            "Error adding API key"
        );
      }
    },
    [fetcher, swr]
  );

  return {
    ...swr,
    removeApiKey,
    removeSigningKey,
    addApiKey,
    updateIsolatedMargin,
    showOnboarding,
  };
};
