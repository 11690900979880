import { motion } from "framer-motion";
import styled from "styled-components";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { frostedGlassBg } from "../shared/style";

export const MobileFooterWrapper = styled(motion.div)`
  overflow: hidden;
  position: sticky;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  height: ${COMPONENTS.footer.mobileNav}px;
  display: flex;
  justify-content: center;
  padding: ${SPACING.two}px;

  ${frostedGlassBg(undefined, undefined, 40)}

  > button {
    min-height: 0;
    flex: 1;

    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;
