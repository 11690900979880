import { useCallback, useEffect, useRef, useState } from "react";
import { OrderTypeResponse, SideResponse } from "../../codegen-api";
import { LocalStorageKeyEnum } from "../../enums/localStorage";

// For any state that needs to be persisted between refreshes

export enum StateKeyEnum {
  SELECTED_MARKET_ONLY = "showSelectedMarketOnly",
  ORDERBOOK_VIEW_IN_USD = "orderbookViewInUSD",
  SHOW_BOTTOM_TRAY = "showBottomTray",
  PREFERRED_MARKET_FILTER = "preferredMarketFilter",
  PREFERRED_TICKER_TYPE = "preferredTickerType",

  // Trade form uses underlying asset as the order size.
  // if unset, default is "USDC"
  TRADE_USE_USDC_TERMS = "tradeUseUSDCTerms",

  // Form
  FORM_DEFAULTS = "formDefaults",

  // Instrument Preferences
  INSTRUMENT_PREFERENCES = "instrumentPreferences",
}

type StorageType = {
  [key in StateKeyEnum]: any;
};

type FormDefaultsType = {
  side?: SideResponse;
  orderType?: OrderTypeResponse;
};

// All fields should be optional
type IInstrumentPreference = {
  defaultOrderbookTickSize?: number;
};

type InstrumentPreferencesType = {
  [instrumentName: string]: IInstrumentPreference;
};

const setPersistentState = (key: StateKeyEnum, value?: any) => {
  const storage = localStorage.getItem(LocalStorageKeyEnum.PERSISTENT_STATE);

  if (storage) {
    const obj = JSON.parse(storage) as StorageType;
    if (value) {
      obj[key] = value;
    } else {
      delete obj[key];
    }
    localStorage.setItem(
      LocalStorageKeyEnum.PERSISTENT_STATE,
      JSON.stringify(obj)
    );
  } else if (value) {
    localStorage.setItem(
      LocalStorageKeyEnum.PERSISTENT_STATE,
      JSON.stringify({
        [key]: value,
      })
    );
  }
};

const usePersistentState = () => {
  const defaultState = useRef(
    localStorage.getItem(LocalStorageKeyEnum.PERSISTENT_STATE)
      ? (JSON.parse(
          localStorage.getItem(LocalStorageKeyEnum.PERSISTENT_STATE)!
        ) as StorageType)
      : undefined
  );

  const [showOnlyCurrentMarket, setShowOnlyCurrentMarket] = useState(
    Boolean(defaultState.current?.[StateKeyEnum.SELECTED_MARKET_ONLY])
  );
  const [orderbookViewInUSD, setOrderbookViewInUSD] = useState(
    Boolean(defaultState.current?.[StateKeyEnum.ORDERBOOK_VIEW_IN_USD])
  );
  const [preferredMarketFilter, setPreferredMarketFilter] = useState(
    defaultState.current?.[StateKeyEnum.PREFERRED_MARKET_FILTER]?.toString()
  );
  const [showBottomTray, setShowBottomTray] = useState(
    Boolean(defaultState.current?.[StateKeyEnum.SHOW_BOTTOM_TRAY])
  );
  const [tradeUseUSDCTerms, setTradeUseUSDCTerms] = useState(
    Boolean(defaultState.current?.[StateKeyEnum.TRADE_USE_USDC_TERMS])
  );
  const [preferredTickerType, setPreferredTickerType] = useState(
    defaultState.current?.[StateKeyEnum.PREFERRED_TICKER_TYPE]?.toString()
  );
  const [formDefaults, _setFormDefaults] = useState(
    defaultState.current?.[StateKeyEnum.FORM_DEFAULTS] as
      | FormDefaultsType
      | undefined
  );
  const [instrumentPreferences, _setInstrumentPreferences] = useState(
    defaultState.current?.[StateKeyEnum.INSTRUMENT_PREFERENCES] as
      | InstrumentPreferencesType
      | undefined
  );

  // Store to local storage whenever its updated
  useEffect(() => {
    setPersistentState(
      StateKeyEnum.SELECTED_MARKET_ONLY,
      showOnlyCurrentMarket ? 1 : 0
    );
  }, [showOnlyCurrentMarket]);

  useEffect(() => {
    setPersistentState(
      StateKeyEnum.ORDERBOOK_VIEW_IN_USD,
      orderbookViewInUSD ? 1 : 0
    );
  }, [orderbookViewInUSD]);

  useEffect(() => {
    setPersistentState(
      StateKeyEnum.PREFERRED_MARKET_FILTER,
      preferredMarketFilter
    );
  }, [preferredMarketFilter]);

  useEffect(() => {
    setPersistentState(StateKeyEnum.PREFERRED_TICKER_TYPE, preferredTickerType);
  }, [preferredTickerType]);

  useEffect(() => {
    setPersistentState(StateKeyEnum.SHOW_BOTTOM_TRAY, showBottomTray ? 1 : 0);
  }, [showBottomTray]);

  useEffect(() => {
    setPersistentState(
      StateKeyEnum.TRADE_USE_USDC_TERMS,
      tradeUseUSDCTerms ? 1 : 0
    );
  }, [tradeUseUSDCTerms]);

  useEffect(() => {
    setPersistentState(StateKeyEnum.FORM_DEFAULTS, formDefaults);
  }, [formDefaults]);

  useEffect(() => {
    setPersistentState(
      StateKeyEnum.INSTRUMENT_PREFERENCES,
      instrumentPreferences
    );
  }, [instrumentPreferences]);

  const setFormDefaults = useCallback((d: Partial<FormDefaultsType>) => {
    _setFormDefaults((prev) => ({
      ...prev,
      ...d,
    }));
  }, []);

  const setInstrumentPreferences = useCallback(
    (p: IInstrumentPreference, instrumentName?: string) => {
      if (instrumentName) {
        _setInstrumentPreferences((prev) => ({
          ...prev,
          [instrumentName]: {
            ...prev?.[instrumentName],
            ...p,
          },
        }));
      }
    },
    []
  );

  return {
    showOnlyCurrentMarket,
    setShowOnlyCurrentMarket,
    orderbookViewInUSD,
    setOrderbookViewInUSD,
    preferredMarketFilter,
    setPreferredMarketFilter,
    preferredTickerType,
    setPreferredTickerType,
    showBottomTray,
    setShowBottomTray,
    tradeUseUSDCTerms,
    setTradeUseUSDCTerms,
    formDefaults,
    setFormDefaults,
    instrumentPreferences,
    setInstrumentPreferences,
  };
};

export default usePersistentState;
