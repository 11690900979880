import { create } from "zustand";
import { IWSSTicker, IWSSTickerDataResponse } from "../../model/ticker";
import { mountZustandDevtool } from "../../../../utils/zustand";
import { getExpiryFromSymbol } from "../../../../utils/instruments";

export type InstrumentToExpiryToTicker = {
  [expiry: string]: {
    [instrumentName: string]: IWSSTicker;
  };
};


interface ITickersStoreState {
  assetDerivatives: Set<string>;
  ticker: InstrumentToExpiryToTicker;
  
  // Mutations
  addAssetDerivative: (assetDerivative: string) => void;
  removeAssetDerivative: (assetDerivative: string) => void;
  updateTicker: (data: IWSSTickerDataResponse) => void;
}

export const useTickersStore = create<ITickersStoreState>()((setState) => ({
  assetDerivatives: new Set(),
  addAssetDerivative: (ticker: string) => setState((state) => {
    if (!Array.from(state.assetDerivatives).some((t) => ticker === t)) {
      // Update if not already included
      const newIndexSet = new Set([
        ...Array.from(state.assetDerivatives),
        ticker
      ])
      return {
        assetDerivatives: newIndexSet
      }
    }
    return state
  }),
  removeAssetDerivative: (ticker: string) => setState((state) => {
    if (Array.from(state.assetDerivatives).some((t) => ticker === t)) {
      // Update if not already included
      const newIndexSet = new Set([
        ...Array.from(state.assetDerivatives),
      ].filter((t) => ticker !== t))

      return {
        assetDerivatives: newIndexSet
      }
    }
    return state
  }),

  // DATA
  ticker: {},
  updateTicker: (data: IWSSTickerDataResponse) => setState((s) => {
    const tickers = data.tickers.reduce(
      (prevTickers, curr) => {
        const exp = getExpiryFromSymbol(curr.instrument_name)
        if (exp) {
          return {
            ...prevTickers,
            [exp]: {
              ...prevTickers[exp],
              [curr.instrument_name]: curr
            },
          }
        }
        return prevTickers
      },
      s.ticker
    );

    return {
      ticker: tickers
    }
  })
}));

mountZustandDevtool("useTickersStore", useTickersStore)
