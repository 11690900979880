import { ChipWrapper } from "./style";

interface IChipProps {
  theme: "green" | "blue" | "red";
  value: string;
}

export function Chip({ theme, value }: IChipProps) {
  return <ChipWrapper theme={theme}>{value}</ChipWrapper>;
}
