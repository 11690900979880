import React from "react";

type SVGProps = React.SVGAttributes<SVGElement>;

export const arrowUpRight = ({
  color,
  ...props
}: SVGProps & { color?: string }): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    stroke={color || "white"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.66675 11.3333L11.3334 4.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66675 4.66667H11.3334V11.3333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
