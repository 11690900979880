import { useTranslation } from "react-i18next";
import { MarginTypeResponse, SideResponse } from "../../../../codegen-api";
import { BaseModal } from "../../../BaseModal";
import { MarginIcon } from "./form";
import { MarginDesc, MarginTitle } from "../style";

interface IMarginModalProps {
  show?: boolean;
  onHide?: () => void;
  marginType: MarginTypeResponse;
}

export function MarginModal({ marginType, show, onHide }: IMarginModalProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.MarginModal",
  });

  return (
    <BaseModal
      style={{
        width: 310,
      }}
      show={show}
      onHide={onHide}
      title={
        <MarginTitle>
          <MarginIcon
            marginType={marginType}
            isSelected={false}
            side={SideResponse.Buy}
          />
          <span>
            {marginType === MarginTypeResponse.Isolated
              ? t("isolated_margin")
              : t("cross_margin")}
          </span>
        </MarginTitle>
      }
    >
      <MarginDesc>
        {marginType === MarginTypeResponse.Isolated
          ? t("isolated_margin_desc")
          : t("cross_margin_desc")}
      </MarginDesc>
    </BaseModal>
  );
}
