export function chunk<T>(items: T[], perChunk: number) {
  if (perChunk <= 0) {
    return [];
  }
  return Array.from({ length: Math.ceil(items.length / perChunk) }, (v, i) =>
    items.slice(i * perChunk, i * perChunk + perChunk)
  );
}

/**
 * Given an array and a filterFn, splits the array into a tuple of 2 different arrays.
 * @param items Array
 * @param filterFn Filter fn
 * @returns [arrayOfMatchingItems, arrayOfNonMatchingItems]
 */
export function splitArrayByFilter<T>(
  items: T[],
  filterFn: (v: T, i?: number, array?: T[]) => boolean
) {
  return items.reduce(
    (result, item) => {
      if (filterFn(item)) {
        result[0].push(item);
      } else {
        result[1].push(item);
      }
      return result;
    },
    [[], []] as [T[], T[]]
  );
}
