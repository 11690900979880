import { useMemo, useRef } from "react";
import {
  ChartData,
  ChartMetaInfo,
  IExternalSaveLoadAdapter,
  StudyTemplateMetaInfo,
} from "../../charting_library/charting_library";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { jsonParse, jsonStringify } from "../../utils/strings";

export type IChartInfo = (ChartData & ChartMetaInfo) & {
  timestamp?: number;
  content?: string;
};

const defaultChartState = {
  charts: [] as IChartInfo[],
  studyTemplates: [] as StudyTemplateMetaInfo[],
  drawingTemplates: [] as {
    name: string;
    content: string;
  }[],
  chartTemplates: [] as {
    name: string;
    content: string;
  }[],
} as const;

const storeChartState = (obj: any) => {
  localStorage.setItem(LocalStorageKeyEnum.TV_CHART_STATE, jsonStringify(obj));
};

const getChartState = (): typeof defaultChartState | undefined => {
  const str = localStorage.getItem(LocalStorageKeyEnum.TV_CHART_STATE);
  if (str) {
    return jsonParse(str);
  }
  return undefined;
};

const useSaveloadAdapter = () => {
  const chartState = useRef(getChartState() || defaultChartState);

  const adapter: IExternalSaveLoadAdapter = useMemo(
    () => ({
      getAllCharts() {
        return Promise.resolve(chartState.current.charts);
      },

      removeChart(id) {
        for (let i = 0; i < chartState.current.charts.length; i += 1) {
          if (chartState.current.charts[i].id === id) {
            chartState.current.charts.splice(i, 1);
            storeChartState(chartState.current);
            return Promise.resolve();
          }
        }
        return Promise.reject();
      },

      saveChart(chartData) {
        if (!chartData.id) {
          // eslint-disable-next-line no-param-reassign
          chartData.id = Math.random().toString();
        } else {
          this.removeChart(chartData.id);
        }

        chartState.current.charts.push(chartData as IChartInfo);
        storeChartState(chartState.current);
        return Promise.resolve(chartData.id);
      },

      getChartContent(id) {
        for (let i = 0; i < chartState.current.charts.length; i += 1) {
          if (chartState.current.charts[i].id === id) {
            return Promise.resolve(chartState.current.charts[i].content);
          }
        }

        console.error("error");

        return Promise.reject();
      },

      removeStudyTemplate(studyTemplateData) {
        for (let i = 0; i < chartState.current.studyTemplates.length; i += 1) {
          if (
            chartState.current.studyTemplates[i].name === studyTemplateData.name
          ) {
            chartState.current.studyTemplates.splice(i, 1);
            storeChartState(chartState.current);
            return Promise.resolve();
          }
        }

        return Promise.reject();
      },

      getStudyTemplateContent(studyTemplateData) {
        for (let i = 0; i < chartState.current.studyTemplates.length; i += 1) {
          if (
            chartState.current.studyTemplates[i].name === studyTemplateData.name
          ) {
            // TODO: - WTF??
            return Promise.resolve(
              chartState.current.studyTemplates[i] as any as string
            );
          }
        }

        console.error("st: error");

        return Promise.reject();
      },

      saveStudyTemplate(studyTemplateData) {
        for (let i = 0; i < chartState.current.studyTemplates.length; i += 1) {
          if (
            chartState.current.studyTemplates[i].name === studyTemplateData.name
          ) {
            chartState.current.studyTemplates.splice(i, 1);
            break;
          }
        }

        chartState.current.studyTemplates.push(studyTemplateData);
        storeChartState(chartState.current);
        return Promise.resolve();
      },

      getAllStudyTemplates() {
        return Promise.resolve(chartState.current.studyTemplates);
      },

      removeDrawingTemplate(toolName, templateName) {
        for (
          let i = 0;
          i < chartState.current.drawingTemplates.length;
          i += 1
        ) {
          if (chartState.current.drawingTemplates[i].name === templateName) {
            chartState.current.drawingTemplates.splice(i, 1);
            storeChartState(chartState.current);
            return Promise.resolve();
          }
        }

        return Promise.reject();
      },

      loadDrawingTemplate(toolName, templateName) {
        for (
          let i = 0;
          i < chartState.current.drawingTemplates.length;
          i += 1
        ) {
          if (chartState.current.drawingTemplates[i].name === templateName) {
            return Promise.resolve(
              chartState.current.drawingTemplates[i].content
            );
          }
        }

        console.error("drawing: error");

        return Promise.reject();
      },

      saveDrawingTemplate(toolName, templateName, content) {
        for (
          let i = 0;
          i < chartState.current.drawingTemplates.length;
          i += 1
        ) {
          if (chartState.current.drawingTemplates[i].name === templateName) {
            chartState.current.drawingTemplates.splice(i, 1);
            break;
          }
        }

        chartState.current.drawingTemplates.push({
          name: templateName,
          content,
        });
        storeChartState(chartState.current);
        return Promise.resolve();
      },

      getDrawingTemplates() {
        return Promise.resolve(
          chartState.current.drawingTemplates.map((template) => template.name)
        );
      },
    }),
    []
  );

  return {
    adapter,
  };
};

export default useSaveloadAdapter;
