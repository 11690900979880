import moment from "moment";
import { InstrumentTypeResponse } from "../codegen-api";
import { nanosToSeconds } from "./date";
import { getOptionsTypeFromSymbol } from "./instruments";
import { startCase } from "./strings";

export enum ToastEnum {
  SIMPLE,
  INFO,
  WIDGET,
}

export enum ToastStatusEnum {
  ERROR,
  SUCCESS,
}

/**
 *
 * @param instrumentName eg. "ETH-19AUG22-2200-C"
 * @param expiry Expiry in UNIX (seconds)
 * @param strike
 * @returns
 */
export const getToastTitleForInstrument = (
  derivative: InstrumentTypeResponse,
  instrumentName: string,
  expiry?: number,
  strike?: string | number
) => {
  const exp = expiry
    ? moment.unix(nanosToSeconds(expiry)).format("DD MMM")
    : undefined;
  if (derivative === InstrumentTypeResponse.Option && exp) {
    return `${startCase(
      getOptionsTypeFromSymbol(instrumentName) || ""
    )} - ${exp} - ${strike}
    `;
  }
  return instrumentName;
};
