import useSWR from "swr";
import { AxiosError } from "axios";
import { publicApi } from "../../../services/api/apiFetcher";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";
import { GetInstrumentInstrumentName200Response } from "../../../codegen-api";
import { pollingInterval } from "../../../services/api/pollingInterval";

export const useGetOptionData = (instrumentName?: string) => {
  const fetcher = publicApi();

  return useSWR<GetInstrumentInstrumentName200Response, AxiosError>(
    instrumentName ? [APIEndpointEnum.INSTRUMENT, instrumentName] : undefined,
    async () => (await (await fetcher.getInstrumentInstrumentName(instrumentName!))()).data,
    {
      use: [useLoggerMiddleware],
      refreshInterval: pollingInterval[APIEndpointEnum.INSTRUMENT],
    },
  );
};
