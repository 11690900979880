import { AssetResponse } from "../../../utils/asset";
import { WebsocketChannelEnum } from "../model/shared";

export const getTradesChannel = (instrument: string) =>
  `${WebsocketChannelEnum.TRADES}:${instrument}`;
export const getIndexChannel = (asset: AssetResponse) =>
  `${WebsocketChannelEnum.INDEX}:${asset}`;

export const getMarkPriceChannel = (ticker: string) =>
  `${WebsocketChannelEnum.MARK_PRICE}:${ticker}`;
export const getTickerChannel = (assetDerivative: string) =>
  `${WebsocketChannelEnum.TICKER}:${assetDerivative}`;

export const fillsChannel = "fills:ext";
export const positionsChannel = "positions";
