import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NotificationsSettings,
  OptionsChainSettings,
  SettingsContext,
  SettingsEnum,
  settingsEnumTitle,
  SFXSettings,
} from "../../contexts/SettingsContext";
import { SettingsAudioPlayer } from "../SettingsAudioPlayer";
import { BaseModal, IBaseModalProps } from "../BaseModal";
import Toggle from "../shared/Toggle";
import {
  LanguageButton,
  LanguageButtonWrapper,
  SelectScrim,
  SettingHeader,
  SettingRow,
  SettingsTitle,
  SFXTitle,
} from "./style";
import { ButtonThemeEnum } from "../Buttons/styles";
import { Language } from "../../i18n/config";
import { Select } from "../shared/Select";

interface ISetting {
  title: string;
  setting: OptionsChainSettings | SFXSettings | NotificationsSettings;
  isOn: boolean;
  onClick: () => void;
}

interface ISettingsModalProps extends IBaseModalProps {
  showOptionsChainSettings?: boolean;
}

interface ILanguage {
  key: string;
  value: Language;
}

const languages: ILanguage[] = [
  {
    key: "English",
    value: "en",
  },
  {
    key: "中文",
    value: "cn",
  },
  {
    key: "русский",
    value: "ru",
  },
  {
    key: "한국인",
    value: "kr",
  },
  {
    key: "Tiếng Việt",
    value: "vn",
  },
  {
    key: "Bahasa Indonesia",
    value: "id",
  },
];

export function SettingsModal({
  show,
  onHide,
  showOptionsChainSettings,
}: ISettingsModalProps) {
  const { t } = useTranslation("app", { keyPrefix: "SettingsModal" });

  const NOTIFICATIONS_LIST: Array<{
    key: string;
    value: NotificationsSettings;
  }> = useMemo(() => [{ key: t("fills"), value: "fills" }], [t]);

  const OPTIONS_CHAIN_LIST: Array<{
    key: string;
    value: OptionsChainSettings;
  }> = useMemo(
    () => [
      { key: t("your_position"), value: "position" },
      { key: t("bid_iv"), value: "bid_iv" },
      { key: t("ask_iv"), value: "ask_iv" },
      { key: t("delta"), value: "delta" },
      { key: t("mark_price"), value: "mark_price" },
    ],
    [t]
  );

  const SFX_LIST: Array<{ key: string; value: SFXSettings }> = useMemo(
    () => [
      { key: t("order_placed"), value: "order_placed" },
      { key: t("order_filled"), value: "order_filled" },
      { key: t("order_cancelled"), value: "order_cancelled" },
      { key: t("errors"), value: "error" },
    ],
    [t]
  );

  const {
    optionsChain,
    addOptionsChain,
    removeOptionsChain,
    notifications,
    addNotifications,
    removeNotifications,
    sfx,
    addSfx,
    removeSfx,
    language,
    changeLanguage,
  } = useContext(SettingsContext);

  const settingOptions = useMemo(() => {
    if (showOptionsChainSettings) return Object.values(SettingsEnum);
    const settings = Object.values(SettingsEnum).filter(
      (v) => v !== SettingsEnum.OPTIONS_CHAIN
    ) as SettingsEnum[];
    return settings;
  }, [showOptionsChainSettings]);

  const [selectedSetting, setSetting] = useState<SettingsEnum>(
    settingOptions[0]
  );

  const optionsChainSettings: ISetting[] = useMemo(
    () =>
      OPTIONS_CHAIN_LIST.map((o) => ({
        title: o.key,
        setting: o.value,
        isOn: optionsChain.has(o.value),
        onClick: () => {
          if (optionsChain.has(o.value)) {
            removeOptionsChain(o.value);
          } else {
            addOptionsChain(o.value);
          }
        },
      })),
    [OPTIONS_CHAIN_LIST, optionsChain, removeOptionsChain, addOptionsChain]
  );

  const sfxSettings: ISetting[] = useMemo(
    () =>
      SFX_LIST.map((o) => ({
        title: o.key,
        setting: o.value,
        isOn: sfx.has(o.value),
        onClick: () => {
          if (sfx.has(o.value)) {
            removeSfx(o.value);
          } else {
            addSfx(o.value);
          }
        },
      })),
    [SFX_LIST, sfx, removeSfx, addSfx]
  );

  const notificationsSettings: ISetting[] = useMemo(
    () =>
      NOTIFICATIONS_LIST.map((o) => ({
        title: o.key,
        setting: o.value,
        isOn: notifications.has(o.value),
        onClick: () => {
          if (notifications.has(o.value)) {
            removeNotifications(o.value);
          } else {
            addNotifications(o.value);
          }
        },
      })),
    [NOTIFICATIONS_LIST, addNotifications, notifications, removeNotifications]
  );

  const settingsTitle = useMemo(() => {
    if (selectedSetting === SettingsEnum.OPTIONS_CHAIN) {
      return t("options_chain_desc");
    }

    if (selectedSetting === SettingsEnum.SFX) {
      return t("sfx_desc");
    }

    if (selectedSetting === SettingsEnum.LANGUAGE) {
      return t("language_desc");
    }

    if (selectedSetting === SettingsEnum.NOTIFICATIONS) {
      return t("notifications_desc");
    }

    return undefined;
  }, [selectedSetting, t]);

  const settingsList = useMemo(() => {
    switch (selectedSetting) {
      case SettingsEnum.OPTIONS_CHAIN:
        return optionsChainSettings;
      case SettingsEnum.SFX:
        return sfxSettings;
      case SettingsEnum.NOTIFICATIONS:
        return notificationsSettings;
      default:
        return [];
    }
  }, [
    notificationsSettings,
    optionsChainSettings,
    selectedSetting,
    sfxSettings,
  ]);

  return (
    <BaseModal
      title={t(settingsEnumTitle[selectedSetting])}
      show={show}
      onHide={onHide}
      style={{
        width: 342,
      }}
    >
      <SelectScrim>
        <Select
          isRound
          options={settingOptions.map((opt) => ({
            value: opt,
            label: t(settingsEnumTitle[opt]),
            isActive: opt === selectedSetting,
            onClick: () => setSetting(opt),
          }))}
        />
      </SelectScrim>
      <SettingHeader>{settingsTitle}</SettingHeader>
      {selectedSetting === SettingsEnum.LANGUAGE ? (
        <LanguageButtonWrapper>
          {languages.map((lang: ILanguage) => (
            <LanguageButton
              key={lang.key}
              isActive={lang.value === language}
              onClick={() => changeLanguage(lang.value)}
              fullWidth
              buttonTheme={
                language === lang.value
                  ? ButtonThemeEnum.HIGHLIGHT
                  : ButtonThemeEnum.NEUTRAL2
              }
            >
              {lang.key}
            </LanguageButton>
          ))}
        </LanguageButtonWrapper>
      ) : null}
      {selectedSetting === SettingsEnum.OPTIONS_CHAIN ||
      selectedSetting === SettingsEnum.SFX ||
      selectedSetting === SettingsEnum.NOTIFICATIONS
        ? settingsList.map((o, i) => (
            <SettingRow
              key={o.setting}
              transition={{
                duration: 0.4,
                delay: (i + 1) * 0.05,
                ease: "easeInOut",
              }}
              initial={{
                opacity: 0,
                transform: "translateY(8px)",
              }}
              animate={{
                opacity: 1,
                transform: "translateY(0px)",
              }}
            >
              {selectedSetting === SettingsEnum.SFX ? (
                <SFXTitle $isOn={o.isOn}>
                  <SettingsAudioPlayer
                    sfx={o.setting as SFXSettings}
                    isOn={o.isOn}
                  />
                  <p>{o.title}</p>
                </SFXTitle>
              ) : (
                <SettingsTitle $isOn={o.isOn}>
                  <p>{o.title}</p>
                </SettingsTitle>
              )}
              <Toggle isOn={o.isOn} onToggle={o.onClick} />
            </SettingRow>
          ))
        : null}
    </BaseModal>
  );
}
