import {
  ChangeEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { SPACING } from "../../constants/design/spacing";
import { AuthContext } from "../../contexts/AuthContext";
import { IEncryptedAuthInfo } from "../../interfaces/AuthInfo";
import {
  DecryptAccountStatusEnum,
  decryptEncryptedAccountInfo,
} from "../../utils/encryption/accountPasswordEncryption";
import { BaseModal, IBaseModalProps } from "../BaseModal";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import { Input } from "../shared/Input";
import { HeaderSubText } from "../shared/SharedModalStyles/styles";
import { ErrorText } from "./style";

interface IManualImportModalProps extends IBaseModalProps {
  onImportSuccess: () => void;
}

function ManualImportModal({
  onImportSuccess,
  ...modalProps
}: IManualImportModalProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "ManualImportModal",
  });

  const { manualImportAccount } = useContext(AuthContext);

  const [passwordInput, setPasswordInput] = useState<string>("");
  const [encryptedAuthInfo, setEncryptedAuthInfo] =
    useState<IEncryptedAuthInfo>();
  const [error, setError] = useState<string>();

  const onPasswordChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setPasswordInput?.(e.target.value);
      setError(undefined);
    },
    []
  );

  const onUploadJSON: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.target.files) {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = (progressEvent) => {
          if (progressEvent.target?.result) {
            try {
              const data = JSON.parse(
                progressEvent.target.result as string
              ) as IEncryptedAuthInfo;
              if (data.account) {
                setEncryptedAuthInfo(data);
              } else {
                throw Error();
              }
            } catch (fileErr) {
              setError(t("file_corrupted_error"));
            }
          } else {
            setError(t("file_error"));
          }
        };
      }
    },
    [t]
  );

  const onImport = useCallback(() => {
    // 1. Decrypt with password
    // 2. Throw error if fail
    // 3. If success, import into AuthContext
    if (passwordInput && encryptedAuthInfo) {
      try {
        const { status } = decryptEncryptedAccountInfo(
          encryptedAuthInfo,
          passwordInput
        );
        if (status !== DecryptAccountStatusEnum.OK) {
          throw Error();
        }
        // OK, import account
        manualImportAccount(encryptedAuthInfo, passwordInput);
        modalProps.onHide?.();
        onImportSuccess();
      } catch (e) {
        setError(t("invalid_password"));
      }
    }
  }, [
    encryptedAuthInfo,
    manualImportAccount,
    modalProps,
    onImportSuccess,
    passwordInput,
    t,
  ]);

  // RESET STATE ON HIDE
  useEffect(() => {
    if (!modalProps.show) {
      setPasswordInput("");
      setError(undefined);
    }
  }, [modalProps.show]);

  return (
    <BaseModal
      style={{
        width: 310,
      }}
      title={t("import_account")}
      {...modalProps}
    >
      <HeaderSubText
        style={{
          padding: 0,
        }}
      >
        {t("import_account_desc")}
      </HeaderSubText>
      <Input
        placeholder={t("password")}
        type="file"
        onChange={onUploadJSON}
        wrapperStyles={{
          marginTop: SPACING.three,
          marginBottom: SPACING.two,
        }}
      />
      <Input
        placeholder={t("password")}
        type="password"
        value={passwordInput}
        onChange={onPasswordChange}
        wrapperStyles={{
          marginTop: SPACING.three,
          marginBottom: SPACING.two,
        }}
      />
      {error && <ErrorText>{error}</ErrorText>}
      <Button
        onClick={onImport}
        buttonTheme={ButtonThemeEnum.NEUTRAL2}
        disabled={!encryptedAuthInfo || !passwordInput}
        style={{
          marginTop: SPACING.three,
        }}
      >
        {t("import")}
      </Button>
    </BaseModal>
  );
}
export default ManualImportModal;
