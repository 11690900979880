import { motion } from "framer-motion";
import styled from "styled-components";
import {
  BACKGROUND_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../constants/design/fontWeight";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../constants/design/spacing";
import { flashingCSS, frostedGlassBg } from "../shared/style";

export const Container = styled.div<{
  isMobileScreen?: boolean;
}>`
  ${({ isMobileScreen }) =>
    `margin: ${isMobileScreen ? SPACING.two : SPACING.three}px 0 0;`}
`;

export const Tab = styled.button<{
  isActive?: boolean;
  flashing?: boolean;
}>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${SPACING.two}px 0;
  text-transform: capitalize;
  ${FONT_CSS.body.five}
  color: ${({ isActive }) => (isActive ? COLORS.blue.one : TEXT_COLORS.one)};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.64)};

  svg {
    margin-left: ${SPACING.one}px;
    stroke: ${({ isActive }) => (isActive ? COLORS.blue.one : TEXT_COLORS.one)};
  }

  ${({ flashing }) => {
    if (flashing) {
      return flashingCSS;
    }
    return "";
  }}
`;

export const Header = styled.div`
  background-color: ${BACKGROUND_COLORS.eight};
  border-radius: ${SPACING.two}px;
  display: flex;

  ${Tab} {
    &:not(:last-child) {
      border-right: 1px solid ${COLORS.white.two};
    }
  }
`;

export const FilterContent = styled(motion.div)`
  ${FONT_CSS.header.seven}
  position: relative;
  margin-top: ${SPACING.three}px;
  background-color: ${BACKGROUND_COLORS.eight};
  border-radius: ${SPACING.two}px;
  display: flex;
  flex-direction: column;
  height: ${COMPONENTS.tradeModalFilterHeight}px;
  overflow: hidden;
`;

export const CloseButton = styled.div`
  color: ${TEXT_COLORS.two};
  margin: ${SPACING.three}px;
  padding: ${SPACING.two}px;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  ${frostedGlassBg(BACKGROUND_COLORS.four, BACKGROUND_COLORS.twelve)};

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

export const Title = styled.div`
  padding: ${SPACING.three}px;
  font-weight: ${FONT_WEIGHT.medium};
  border-bottom: 1px solid ${COLORS.white.five};
`;

export const FilterSelections = styled.div`
  // Extra padding bottom leave space for cancel button
  margin-bottom: ${SPACING.three}px;
  padding-bottom: ${SPACING.six}px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const ExpiryTag = styled.div`
  border-radius: 4px;
  margin-left: ${SPACING.two}px;
  padding: 2px 4px;
`;

export const FilterRow = styled.button<{
  selected?: boolean;
}>`
  ${FONT_CSS.data.three}
  color: ${({ selected }) => (selected ? COLORS.blue.one : TEXT_COLORS.two)};
  padding: ${SPACING.twoHalf}px ${SPACING.two}px;
  margin: 0 ${SPACING.two}px;
  text-align: left;
  border-radius: ${BORDER_RADIUS.five}px;
  text-transform: capitalize;
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ selected }) => (selected ? COLORS.blue.one : TEXT_COLORS.one)};
    background-color: ${({ selected }) =>
      selected ? COLORS.blue.four : BACKGROUND_COLORS.four};
  }

  ${ExpiryTag} {
    background-color: ${({ selected }) =>
      selected ? COLORS.blue.five : BACKGROUND_COLORS.four};
  }
`;

export const CurrentSpotRow = styled.div`
  ${FONT_CSS.data.three}
  background-color: ${BACKGROUND_COLORS.four};
  padding: ${SPACING.two}px;
  text-align: center;
  color: ${TEXT_COLORS.three};
`;

export const CurrentPrice = styled.span`
  color: ${TEXT_COLORS.one};
  padding: ${SPACING.two}px;
`;

export const ChangePercent = styled.span`
  color: ${COLORS.positive.one};
  font-size: ${FONT_SIZE.one};
`;

export const SelectStrikeWarning = styled.span`
  text-align: center;
  display: inline-block;
  width: 100%;
  color: ${COLORS.blue.one};
  font-size: ${FONT_SIZE.one};
  font-weight: ${FONT_WEIGHT.medium};
  padding-bottom: ${SPACING.twoHalf}px;
`;
