import styled, { css } from "styled-components";
import {
  COLORS,
  ICON_COLORS,
  LAYER_COLORS,
  PRIMARY_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../constants/design/spacing";

/**
 * CSS for components that glows on hover
 * @param color Color, hex or rgba()
 * @returns css
 */
export const glow = (color: string) => css`
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 0px 1px ${color} inset;
  }
`;

export const ConnectWalletButton = styled.button.attrs<{
  fullWidth?: boolean;
  isError?: boolean;
  disableHoverAnimation?: boolean;
}>({
  type: "button",
})<{
  fullWidth?: boolean;
  isError?: boolean;
  disableHoverAnimation?: boolean;
}>`
  border-radius: ${BORDER_RADIUS.five}px;
  color: ${COLORS.highlight.one};
  padding: ${SPACING.twoHalf}px ${SPACING.three}px;
  white-space: nowrap;
  ${FONT_CSS.body.three}
  width: ${({ fullWidth }) => (fullWidth ? "100%" : undefined)};
  ${({ disableHoverAnimation }) =>
    !disableHoverAnimation && glow(COLORS.highlight.one)}

  ${({ isError, disableHoverAnimation }) => {
    if (isError) {
      return css`
        color: ${COLORS.negative.one};
        background-color: ${COLORS.negative.five};
        ${!disableHoverAnimation ? glow(COLORS.negative.one) : ""}
      `;
    }
    return css`
      background-color: ${COLORS.highlight.six};
    `;
  }}

    &:disabled {
    opacity: 0.48;

    &:hover {
      box-shadow: none;
    }
  }
`;

export enum ButtonThemeEnum {
  PRIMARY = "PRIMARY",
  NEUTRAL1 = "NEUTRAL1",
  NEUTRAL2 = "NEUTRAL2",
  NEUTRAL3 = "NEUTRAL3",
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  HIGHLIGHT = "HIGHLIGHT",
  SYSTEM = "SYSTEM",
}

type ButtonTypeToColor = {
  [key in ButtonThemeEnum]: string;
};

export const buttonBgColor: ButtonTypeToColor = {
  [ButtonThemeEnum.PRIMARY]: PRIMARY_COLORS.two,
  [ButtonThemeEnum.NEUTRAL1]: LAYER_COLORS.one,
  [ButtonThemeEnum.NEUTRAL2]: LAYER_COLORS.two,
  [ButtonThemeEnum.NEUTRAL3]: LAYER_COLORS.three,
  [ButtonThemeEnum.POSITIVE]: COLORS.positive.five,
  [ButtonThemeEnum.NEGATIVE]: COLORS.negative.five,
  [ButtonThemeEnum.HIGHLIGHT]: COLORS.highlight.five,
  [ButtonThemeEnum.SYSTEM]: COLORS.system.five,
};

const buttonHoverBorderColor: ButtonTypeToColor = {
  [ButtonThemeEnum.PRIMARY]: PRIMARY_COLORS.one,
  [ButtonThemeEnum.NEUTRAL1]: COLORS.neutral.one,
  [ButtonThemeEnum.NEUTRAL2]: COLORS.neutral.one,
  [ButtonThemeEnum.NEUTRAL3]: COLORS.neutral.one,
  [ButtonThemeEnum.POSITIVE]: COLORS.positive.one,
  [ButtonThemeEnum.NEGATIVE]: COLORS.negative.one,
  [ButtonThemeEnum.HIGHLIGHT]: COLORS.highlight.one,
  [ButtonThemeEnum.SYSTEM]: COLORS.system.one,
};

export const buttonTextColor: ButtonTypeToColor = {
  [ButtonThemeEnum.PRIMARY]: TEXT_COLORS.one,
  [ButtonThemeEnum.NEUTRAL1]: TEXT_COLORS.one,
  [ButtonThemeEnum.NEUTRAL2]: TEXT_COLORS.one,
  [ButtonThemeEnum.NEUTRAL3]: TEXT_COLORS.one,
  [ButtonThemeEnum.POSITIVE]: COLORS.positive.one,
  [ButtonThemeEnum.NEGATIVE]: COLORS.negative.one,
  [ButtonThemeEnum.HIGHLIGHT]: COLORS.highlight.one,
  [ButtonThemeEnum.SYSTEM]: COLORS.system.one,
};

const buttonTextDisabledColor: ButtonTypeToColor = {
  [ButtonThemeEnum.PRIMARY]: TEXT_COLORS.two,
  [ButtonThemeEnum.NEUTRAL1]: TEXT_COLORS.two,
  [ButtonThemeEnum.NEUTRAL2]: TEXT_COLORS.two,
  [ButtonThemeEnum.NEUTRAL3]: TEXT_COLORS.two,
  [ButtonThemeEnum.POSITIVE]: COLORS.positive.two,
  [ButtonThemeEnum.NEGATIVE]: COLORS.negative.two,
  [ButtonThemeEnum.HIGHLIGHT]: COLORS.highlight.two,
  [ButtonThemeEnum.SYSTEM]: COLORS.system.two,
};

export interface IButtonComponentProps {
  buttonTheme: ButtonThemeEnum;
  svgIsFill?: boolean;
  fullWidth?: boolean;
}

export const Button = styled.button.attrs<
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ type }) => ({
  type: type || "button",
}))<IButtonComponentProps>`
  ${FONT_CSS.body.four}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ buttonTheme }) => buttonBgColor[buttonTheme]};
  color: ${({ buttonTheme }) => buttonTextColor[buttonTheme]};
  border-radius: ${BORDER_RADIUS.five}px;
  padding: ${SPACING.three}px;
  min-width: ${COMPONENTS.modalButton}px;
  min-height: ${COMPONENTS.modalButton}px;
  transition: all 0.2s ease-in-out;

  svg {
    stroke: ${ICON_COLORS.two};
  }

  &:hover {
    box-shadow: 0px 0px 0px 1px
      ${({ buttonTheme }) => buttonHoverBorderColor[buttonTheme]} inset;
  }

  &:disabled {
    color: ${({ buttonTheme }) => buttonTextDisabledColor[buttonTheme]};

    &:hover {
      box-shadow: none;
    }
  }
`;
