/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { useCallback, useContext, useMemo, useState } from "react";
import { useTable } from "react-table";
import moment from "moment";
import currency from "currency.js";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import {
  Align,
  TableContainerWrapper,
} from "../../../../components/shared/Table/style";
import { ITableColumn } from "../../../../interfaces/Table/TableColumn";
import { ButtonThemeEnum } from "../../../../components/Buttons/styles";
import {
  GetRfqs200ResponseBlocksInner,
  GetRfqs200ResponseBlocksInnerLegsInner,
  InstrumentTypeResponse,
  SideResponse,
} from "../../../../codegen-api";
import { DefaultCellForColumn } from "../../../../components/shared/Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../../../components/shared/Table/DefaultHeaderForColumn";
import Tag from "../../../../components/ConfirmationModal/Tag";
import {
  BACKGROUND_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../../../constants/design/colors";
import {
  AssetTag,
  BlockId,
  BlockRow,
  ChevronWrapper,
  OfferButton,
  RFQTableWrapper,
  Ratio,
  TagWrapper,
} from "./style";
import { getTimeAgo, nanosToSeconds } from "../../../../utils/date";
import { getAssetLogo } from "../../../../utils/asset/assets";
import { Chevron } from "../../../../components/shared/Chevron/style";
import { TradeCol } from "../../../../components/TicketDetails/TradeOrderBook/style";
import RequestAcceptRFQModal from "../modals/RequestAcceptRFQModal";
import { useRFQ } from "../../../../hooks/api/rfq/useRFQ";
import { useSFX } from "../../../../hooks/useSFX";
import { useToast } from "../../../../hooks/toast";
import { ToastEnum, ToastStatusEnum } from "../../../../utils/toast";
import { EmptyContent } from "../../../../components/TradeHistory/style";
import { SPACING } from "../../../../constants/design/spacing";
import { useQuotes } from "../../../../hooks/api/rfq/useQuotes";
import { MarketInstrumentContext } from "../../../../contexts/MarketInstrumentContext";

function RFQTable() {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [selectedBlock, setBlock] = useState<GetRfqs200ResponseBlocksInner>();
  const [selectedSide, setSide] = useState<SideResponse>();
  const [showRFQModal, setShowRFQModal] = useState<boolean>(false);
  const { data: rfqData, deleteRFQ, mutate: mutateRFQs } = useRFQ();
  const { data: quotesData, deleteQuote, acceptQuote } = useQuotes();
  const { t: translateAll } = useTranslation();
  const { t } = useTranslation("app", { keyPrefix: "pages.RFQPage.RFQTable" });
  const { t: apiErrors } = useTranslation("apiErrors");
  const { playSound } = useSFX();
  const { addToast, addErrorToast } = useToast();
  const { getMarketPrecision } = useContext(MarketInstrumentContext);

  const handleRowClick = (index: number) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((i) => i !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  const quoteByBlock = useCallback(
    (blockId: string, side: SideResponse) => {
      if (!quotesData?.quotes) return false;

      const quote = (quotesData.quotes || []).find(
        (q) =>
          q.block_id === blockId &&
          (q.is_buy ? SideResponse.Buy : SideResponse.Sell) === side
      );
      return quote;
    },
    [quotesData]
  );

  const memoizedBlocks = useMemo(() => {
    // sort by role === taker first
    const sortedBlocks = (rfqData?.blocks || [])
      .sort((a, b) => Number(b.created_timestamp) - Number(a.created_timestamp))
      .sort((a) => {
        if (a.role === "taker") return -1;
        return 0;
      });
    return sortedBlocks;
  }, [rfqData]);

  const generateLegTag = useCallback(
    (
      leg: GetRfqs200ResponseBlocksInnerLegsInner
    ): Array<string | JSX.Element> => {
      if (leg.instrument_type === InstrumentTypeResponse.Option) {
        return [
          <AssetTag key={leg.instrument_id}>
            <img src={getAssetLogo(leg.asset)} alt={leg.asset} />
            {leg.asset}
          </AssetTag>,
          <span
            key={leg.instrument_id}
            style={{
              color:
                leg.side === "buy" ? COLORS.positive.one : COLORS.negative.one,
            }}
          >
            {leg.side}
          </span>,
          leg.option_type as string,
          moment.unix(nanosToSeconds(Number(leg.expiry))).format("DD MMM YYYY"),
          currency(leg.strike as string, { precision: 0 }).format(),
          <Ratio key={leg.instrument_id}>x{leg.ratio}</Ratio>,
        ];
      }
      return [
        <AssetTag key={leg.instrument_id}>
          <img src={getAssetLogo(leg.asset)} alt={leg.asset} />
          {leg.asset}
        </AssetTag>,
        <span
          key={leg.instrument_id}
          style={{
            color:
              leg.side === "buy" ? COLORS.positive.one : COLORS.negative.one,
          }}
        >
          {leg.side}
        </span>,
        leg.instrument_type,
        <Ratio key={leg.instrument_id}>x{leg.ratio}</Ratio>,
      ];
    },
    []
  );

  const onOffer = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      block: GetRfqs200ResponseBlocksInner,
      side: SideResponse
    ) => {
      e.stopPropagation();
      setBlock(block);
      setSide(side);
      setShowRFQModal(true);
    },
    []
  );

  const onCancelBlock = useCallback(
    async (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      block: GetRfqs200ResponseBlocksInner
    ) => {
      e.stopPropagation();
      try {
        await deleteRFQ(block.block_id);
        playSound("order_cancelled");
        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "RFQ Cancelled",
            subheader: "RFQ cancelled successfully",
            status: ToastStatusEnum.SUCCESS,
          },
          4000
        );
      } catch (error: any) {
        addErrorToast(
          t("cancel_rfq_order"),
          apiErrors(error.message) || t("try_again")
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addErrorToast, addToast, apiErrors, playSound, t]
  );

  const onCancelQuote = useCallback(
    async (quoteId: string) => {
      const quote = (quotesData?.quotes || []).find(
        (q) => q.quote_id === quoteId
      );
      try {
        await deleteQuote(quoteId);
        playSound("order_cancelled");
        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: `${quote?.is_buy ? "Bid" : "Ask"} Cancelled`,
            subheader: "Quote cancelled successfully",
            status: ToastStatusEnum.SUCCESS,
          },
          4000
        );
      } catch (error: any) {
        addErrorToast(
          t("cancel_quote_order"),
          apiErrors(error.message) || t("try_again")
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addErrorToast, addToast, apiErrors, playSound, t]
  );

  const onHideModal = useCallback(() => {
    setShowRFQModal(false);
  }, []);

  const getHighestPrecision = useCallback(
    (legs: GetRfqs200ResponseBlocksInnerLegsInner[]) => {
      let highestPrecision = 2;
      legs.forEach((leg) => {
        const precision = getMarketPrecision(leg.asset, leg.instrument_type);
        if (precision.price_precision > highestPrecision)
          highestPrecision = precision.price_precision;
      });

      return highestPrecision;
    },
    [getMarketPrecision]
  );

  const columns: ITableColumn<GetRfqs200ResponseBlocksInner>[] = useMemo(
    () => [
      {
        title: t("id"),
        id: "block_id",
        accessor: "block_id",
        align: "center",
        Cell: ({ value, row }) => (
          <Align align="center">
            <BlockId $isTaker={row.original.role === "taker"}>
              {value.slice(-6).toUpperCase()}
            </BlockId>
          </Align>
        ),
      },
      {
        title: t("legs"),
        id: "legs",
        accessor: "legs",
        Cell: ({ value, row }) => {
          const isExpanded =
            expandedRows.includes(row.index) || (value || []).length === 1;
          const tags = useMemo(() => {
            if (value && value.length > 0) {
              const tag: Array<Array<string | JSX.Element>> = [
                generateLegTag(value[0]),
              ];

              if (!isExpanded && value.length > 1) {
                tag.push([`+${value.length - 1} more`]);
              } else if (isExpanded) {
                value.forEach((leg, i) => {
                  if (i !== 0) tag.push(generateLegTag(leg));
                });
              }

              return tag.map((texts, i) => (
                <Tag
                  key={i}
                  color={TEXT_COLORS.two}
                  backgroundColor={BACKGROUND_COLORS.component}
                  texts={texts}
                />
              ));
            }

            return undefined;
          }, [isExpanded, value]);

          return (
            <TagWrapper isExpanded={isExpanded}>
              {(value || []).length > 1 ? (
                <ChevronWrapper>
                  <Chevron direction={isExpanded ? "up" : "down"} />
                </ChevronWrapper>
              ) : null}
              {tags}
            </TagWrapper>
          );
        },
      },
      {
        title: t("block_size"),
        id: "block_size",
        accessor: "amount",
        align: "left",
        Cell: ({ value }) => <Align align="left">{value}</Align>,
      },
      {
        title: " ",
        id: "sell_cta",
        accessor: "orderbook",
        align: "center",
        Cell: ({ value, row }) => {
          const quote = quoteByBlock(row.original.block_id, SideResponse.Buy);
          return (
            <Align align="center">
              {row.original.role === "taker" ? (
                <OfferButton
                  onClick={(e) => onOffer(e, row.original, SideResponse.Buy)}
                  buttonTheme={ButtonThemeEnum.HIGHLIGHT}
                  disabled={(value?.bids || []).length === 0}
                >
                  Accept
                </OfferButton>
              ) : quote ? (
                <OfferButton
                  onClick={() => onCancelQuote(quote.quote_id)}
                  buttonTheme={ButtonThemeEnum.SYSTEM}
                >
                  Cancel Bid
                </OfferButton>
              ) : (
                <OfferButton
                  onClick={(e) => onOffer(e, row.original, SideResponse.Buy)}
                  buttonTheme={ButtonThemeEnum.POSITIVE}
                >
                  Bid
                </OfferButton>
              )}
            </Align>
          );
        },
      },
      {
        title: t("best_bid"),
        id: "best_bid",
        accessor: "orderbook",
        align: "right",
        Cell: ({ value, row }) => {
          const quote = quoteByBlock(row.original.block_id, SideResponse.Buy);
          const isTaker = row.original.role === "taker";

          // If this is your own quote, show
          if (quote) {
            return (
              <Align align="right">
                <TradeCol>
                  <span style={{ color: COLORS.positive.one }}>
                    {currency(quote.limit_price || 0).format()}
                  </span>
                  <span>{quote.amount}</span>
                </TradeCol>
              </Align>
            );
          }
          if (!isTaker) {
            return (
              <Align align="right">
                <TradeCol>
                  <span style={{ color: COLORS.positive.one }}>$•••</span>
                </TradeCol>
              </Align>
            );
          }
          return (
            <Align align="right">
              <TradeCol>
                <span style={{ color: COLORS.positive.one }}>
                  {(value?.bids || []).length > 0
                    ? currency(value?.bids?.[0][0] || 0).format()
                    : "-"}
                </span>
                <span>
                  {(value?.bids || []).length > 0 ? value?.bids?.[0][1] : "-"}
                </span>
              </TradeCol>
            </Align>
          );
        },
      },
      {
        title: t("mark_price"),
        id: "mark_price",
        accessor: "mark_price",
        align: "right",
        Cell: ({ value, row }) => {
          const highestPrecision = getHighestPrecision(row.original.legs || []);

          return (
            <Align align="right">
              {currency(value, { precision: highestPrecision }).format()}
            </Align>
          );
        },
      },
      {
        title: t("best_ask"),
        id: "best_ask",
        accessor: "orderbook",
        align: "right",
        Cell: ({ value, row }) => {
          const quote = quoteByBlock(row.original.block_id, SideResponse.Sell);
          const isTaker = row.original.role === "taker";

          // If this is your own quote, show
          if (quote) {
            return (
              <Align align="right">
                <TradeCol>
                  <span style={{ color: COLORS.negative.one }}>
                    {currency(quote.limit_price || 0).format()}
                  </span>
                  <span>{quote.amount}</span>
                </TradeCol>
              </Align>
            );
          }
          if (!isTaker) {
            return (
              <Align align="right">
                <TradeCol>
                  <span style={{ color: COLORS.negative.one }}>$•••</span>
                </TradeCol>
              </Align>
            );
          }
          return (
            <Align align="right">
              <TradeCol>
                <span style={{ color: COLORS.negative.one }}>
                  {(value?.asks || []).length > 0
                    ? currency(value?.asks?.[0][0] || 0).format()
                    : "-"}
                </span>
                <span>
                  {(value?.asks || []).length > 0 ? value?.asks?.[0][1] : "-"}
                </span>
              </TradeCol>
            </Align>
          );
        },
      },
      {
        title: " ",
        id: "buy_cta",
        accessor: "orderbook",
        align: "center",
        Cell: ({ value, row }) => {
          const quote = quoteByBlock(row.original.block_id, SideResponse.Sell);
          return (
            <Align align="center">
              {row.original.role === "taker" ? (
                <OfferButton
                  onClick={(e) => onOffer(e, row.original, SideResponse.Sell)}
                  buttonTheme={ButtonThemeEnum.HIGHLIGHT}
                  disabled={(value?.asks || []).length === 0}
                >
                  Accept
                </OfferButton>
              ) : quote ? (
                <OfferButton
                  onClick={() => onCancelQuote(quote.quote_id)}
                  buttonTheme={ButtonThemeEnum.SYSTEM}
                >
                  Cancel Ask
                </OfferButton>
              ) : (
                <OfferButton
                  onClick={(e) => onOffer(e, row.original, SideResponse.Sell)}
                  buttonTheme={ButtonThemeEnum.NEGATIVE}
                >
                  Ask
                </OfferButton>
              )}
            </Align>
          );
        },
      },
      {
        title: " ",
        id: "cancel_cta",
        accessor: "role",
        align: "center",
        Cell: ({ value, row }: any) => (
          <Align align="center">
            {value === "taker" ? (
              <OfferButton
                onClick={(e) => onCancelBlock(e, row.original)}
                disabled={value !== "taker"}
                buttonTheme={ButtonThemeEnum.SYSTEM}
              >
                Cancel RFQ
              </OfferButton>
            ) : null}
          </Align>
        ),
      },
      {
        title: t("date_time"),
        id: "date_time",
        accessor: "created_timestamp",
        align: "right",
        Cell: ({ value }) => (
          <Align align="right">
            {getTimeAgo(moment.unix(nanosToSeconds(value)), translateAll)}
          </Align>
        ),
      },
    ],
    [
      t,
      expandedRows,
      generateLegTag,
      quoteByBlock,
      onOffer,
      onCancelQuote,
      getHighestPrecision,
      onCancelBlock,
      translateAll,
    ]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: memoizedBlocks,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
    });

  return (
    <div>
      <RequestAcceptRFQModal
        block={selectedBlock}
        side={selectedSide}
        show={showRFQModal}
        onHide={onHideModal}
        acceptQuote={acceptQuote}
        mutateRFQs={mutateRFQs}
      />
      <TableContainerWrapper>
        <AnimatePresence>
          <RFQTableWrapper>
            {memoizedBlocks.length > 0 ? (
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        // eslint-disable-next-line react/jsx-key
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    const { ...rowProps } = row.getRowProps();
                    const isTaker = row.original.role === "taker";
                    const isExpanded = expandedRows.includes(row.index);

                    return (
                      // eslint-disable-next-line react/jsx-key
                      <BlockRow
                        {...rowProps}
                        $isTaker={isTaker}
                        onClick={() =>
                          (row.original.legs || []).length > 1
                            ? handleRowClick(row.index)
                            : null
                        }
                      >
                        {row.cells.map((cell) => (
                          // eslint-disable-next-line react/jsx-key
                          <td
                            style={{
                              verticalAlign: isExpanded ? "top" : "middle",
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </BlockRow>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <EmptyContent style={{ padding: SPACING.five }}>
                There are no RFQ listings
              </EmptyContent>
            )}
          </RFQTableWrapper>
        </AnimatePresence>
      </TableContainerWrapper>
    </div>
  );
}
export default RFQTable;
