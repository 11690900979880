import styled from "styled-components";
import { TEXT_COLORS } from "../../constants/design/colors";
import { FONT_STYLE } from "../../constants/design/fontSize";

export const LiquidationWrapper = styled.div`
  p,
  li {
    color: ${TEXT_COLORS.two};
    ${FONT_STYLE.body.three};
  }

  span {
    color: ${TEXT_COLORS.one};
  }
`;
