import { useCallback, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { authApi } from "../../../services/api/apiFetcher";
import { MarginTypeResponse } from "../../../codegen-api";

export const useMarginType = () => {
  const { apiKey, apiSecret } = useContext(AuthContext);
  const fetcher = useMemo(
    () => authApi(apiKey, apiSecret),
    [apiKey, apiSecret]
  );

  const updateMarginType = useCallback(
    async (instrument: number, margin_type: MarginTypeResponse) => {
      const { data } = await (
        await fetcher.postAccountMarginType({
          instrument,
          margin_type,
        })
      )();

      return data;
    },
    [fetcher]
  );

  return {
    updateMarginType,
  };
};
