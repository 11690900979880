import currency from "currency.js";
import {
  CSSProperties,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import {
  GetAccount200ResponsePositionsInner,
  SideResponse,
} from "../../codegen-api";
import { COLORS, LAYER_COLORS } from "../../constants/design/colors";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { useToast } from "../../hooks/toast";
import { formatSizePrecision } from "../../utils/format";
import { calculatePnlPercent } from "../../utils/math";
import { ToastEnum, ToastStatusEnum } from "../../utils/toast";
import {
  ViewDetailsContent,
  ViewDetailsRow,
} from "../PortfolioSettings/Table/style";
import BaseDetailsModal from "../shared/ViewDetailsModal/BaseDetailsModal";
import { IBaseModalProps } from "../BaseModal";
import SegmentedControl from "../shared/SegmentedControl";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../constants/design/spacing";
import { Label, WarningLabel } from "../TradeForm/style";
import { Input } from "../shared/Input";
import { ButtonThemeEnum } from "../Buttons/styles";
import { FooterContainer, SubmitButton } from "./style";
import { MaxButton } from "../DepositWithdrawModal/style";
import { Spinner } from "../shared/Spinner";

interface IAdjustIsolatedMarginModalProps extends IBaseModalProps {
  position?: GetAccount200ResponsePositionsInner;
  onHide?: () => void;
  style?: CSSProperties;
}

function AdjustIsolatedMarginModal({
  position,
  onHide,
  style,
}: IAdjustIsolatedMarginModalProps) {
  const { updateIsolatedMargin } = useGetAccount();
  const { addToast, addErrorToast } = useToast();
  const { t } = useTranslation("app", {
    keyPrefix: "shared.ViewDetailsModal.AdjustIsolatedMarginModal",
  });
  const { t: apiErrors } = useTranslation("apiErrors");
  const { getMarketPrecision } = useContext(MarketInstrumentContext);

  const { data: accountData } = useGetAccount();
  const [error, setError] = useState<string>();
  const [marginError, setMarginError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [isAddMargin, setIsAddMargin] = useState(true);
  const [marginAmount, setMarginAmount] = useState<number | undefined>(
    undefined
  );

  const onHideModal = useCallback(() => {
    onHide?.();
    setIsAddMargin(true);
    setMarginAmount(undefined);
    setMarginError(undefined);
  }, [onHide]);

  const onUpdateMargin = useCallback(async () => {
    if (!position || !marginAmount || marginAmount <= 0) {
      return;
    }

    try {
      const updatedMargin = String(
        Number(isAddMargin ? marginAmount : -marginAmount) * 1000000
      );

      setError(undefined);
      setLoading(true);
      await updateIsolatedMargin(position.instrument_id, updatedMargin);
      onHideModal();
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: `Margin ${isAddMargin ? "Added" : "Removed"}`,
          subheader: "Your position margin was successfully adjusted",
          status: ToastStatusEnum.SUCCESS,
        },
        4000
      );
    } catch (e: any) {
      addErrorToast(t("error_adjust_margin"), apiErrors(e));
      setError(apiErrors(e.message) || t("error_adjust_margin"));
    } finally {
      setLoading(false);
    }
  }, [
    addErrorToast,
    addToast,
    apiErrors,
    isAddMargin,
    marginAmount,
    onHideModal,
    position,
    t,
    updateIsolatedMargin,
  ]);

  const profitPercent = useMemo(() => {
    if (!position) {
      return undefined;
    }
    const totalEntryValue =
      Number(position.avg_entry_price) * Number(position.amount);
    return calculatePnlPercent(
      Number(position.unrealized_pnl),
      totalEntryValue,
      Number(position.isolated_margin)
    );
  }, [position]);

  const accountLeverage = useMemo(
    () =>
      accountData?.leverages?.find(
        (lev) => lev.instrument_id === position?.instrument_id
      )?.leverage,
    [accountData?.leverages, position?.instrument_id]
  );

  const marginBalance = useMemo(() => {
    if (!position) {
      return 0;
    }

    return (
      Number(accountData?.equity) -
      Number(position.isolated_margin) -
      Number(position.maintenance_margin) -
      Number(accountData?.initial_margin)
    );
  }, [accountData?.equity, accountData?.initial_margin, position]);

  const onMaxClick = useCallback(() => {
    // on add margin, max margin is account equity - isolated margin - maintenance margin - initial margin
    // on remove margin, max margin is isolated margin - maintenance margin
    if (!position) {
      return;
    }

    const maxMargin = isAddMargin
      ? marginBalance
      : Number(position.isolated_margin) - Number(position.maintenance_margin);

    setMarginAmount(Number(maxMargin.toFixed(2)));
  }, [isAddMargin, marginBalance, position]);

  useEffect(() => {
    if (isAddMargin) {
      if (Number(marginAmount) > marginBalance) {
        setMarginError(t("insufficient_margin_balance"));
      } else {
        setMarginError(undefined);
      }
    } else if (
      Number(marginAmount) >
      Number(position?.isolated_margin) - Number(position?.maintenance_margin)
    ) {
      setMarginError(
        t("maintenance_margin_must_be_more_than", {
          margin: currency(position?.maintenance_margin || 0).format(),
        })
      );
    } else {
      setMarginError(undefined);
    }
  }, [
    isAddMargin,
    marginAmount,
    marginBalance,
    position?.isolated_margin,
    position?.maintenance_margin,
    t,
  ]);

  return (
    <BaseDetailsModal
      instrument={
        position
          ? {
              instrument_name: position.instrument_name,
              expiry: position.option?.expiry,
              strike: position.option?.strike,
              markPrice: String(position.mark_price || 0),
            }
          : undefined
      }
      show={!!position}
      onHide={onHideModal}
      style={style}
    >
      <SegmentedControl
        segments={[
          {
            value: "1",
            display: t("add_margin"),
          },
          {
            value: "0",
            display: t("remove_margin"),
          },
        ]}
        value={isAddMargin ? "1" : "0"}
        onSelect={(v) => {
          setIsAddMargin(Boolean(Number(v)));
          setMarginAmount(0);
        }}
        config={{
          widthType: "fullWidth",
          backgroundColor: LAYER_COLORS.two,
          activeBackgroundColor: LAYER_COLORS.three,
          borderRadius: `${BORDER_RADIUS.three}px`,
          button: {
            fontSize: FONT_SIZE.one,
            py: SPACING.two,
          },
        }}
      />
      <Label style={{ marginTop: SPACING.three }}>{t("margin_amount")}</Label>
      <Input
        error={Boolean(marginError)}
        placeholder="0"
        type="number"
        value={marginAmount}
        onChange={(e) => setMarginAmount(Number(e.target.value))}
        rightAccessory={
          <MaxButton onClick={onMaxClick} type="button">
            {t("max")}
          </MaxButton>
        }
      />
      {marginError ? <WarningLabel>{marginError}</WarningLabel> : null}
      {position && (
        <ViewDetailsContent style={{ marginTop: SPACING.three }}>
          <ViewDetailsRow>
            <span>{t("unrealized_pnl")}</span>
            <span
              style={{
                color:
                  Number(position.unrealized_pnl) >= 0
                    ? COLORS.positive.one
                    : COLORS.negative.one,
              }}
            >
              {Number(position.unrealized_pnl) >= 0 ? "+" : ""}
              {position.unrealized_pnl
                ? currency(position.unrealized_pnl).format()
                : "-"}
            </span>
          </ViewDetailsRow>
          <ViewDetailsRow>
            <span>{t("roi")}</span>
            <span
              style={{
                color:
                  Number(profitPercent) >= 0
                    ? COLORS.positive.one
                    : COLORS.negative.one,
              }}
            >
              {Number(profitPercent) >= 0 ? "+" : ""}
              {(Number(profitPercent) * 100).toFixed(2)}%
            </span>
          </ViewDetailsRow>
          <ViewDetailsRow>
            <span>{t("contracts")}</span>
            <span>{formatSizePrecision(position.amount)}</span>
          </ViewDetailsRow>
          <ViewDetailsRow>
            <span>{t("side")}</span>
            <span
              style={{
                color:
                  position.side === SideResponse.Buy
                    ? COLORS.positive.one
                    : COLORS.negative.one,
              }}
            >
              {position.side === SideResponse.Buy ? t("long") : t("short")}
            </span>
          </ViewDetailsRow>
          <ViewDetailsRow>
            <span>{t("avg_entry_price")}</span>
            <span>
              {currency(position.avg_entry_price || 0, {
                precision: getMarketPrecision(
                  position.asset,
                  position.instrument_type
                ).price_precision,
              }).format()}
            </span>
          </ViewDetailsRow>
          <ViewDetailsRow>
            <span>{t("isolated_margin")}</span>
            <span>{currency(Number(position.isolated_margin)).format()}</span>
          </ViewDetailsRow>
          {!position.option ? (
            <ViewDetailsRow
              style={{
                textTransform: "none",
              }}
            >
              <span>{t("leverage")}</span>
              <span>{accountLeverage}x</span>
            </ViewDetailsRow>
          ) : null}
          {position.liquidation_price ? (
            <ViewDetailsRow>
              <span>{t("liquidation_price")}</span>
              <span>
                {currency(position.liquidation_price, {
                  precision: getMarketPrecision(
                    position.asset,
                    position.instrument_type
                  ).price_precision,
                }).format()}
              </span>
            </ViewDetailsRow>
          ) : null}
          <FooterContainer>
            <ViewDetailsRow>
              <span>{t("margin_balance")}</span>
              <span
                style={
                  marginError
                    ? { color: isAddMargin ? COLORS.negative.one : undefined }
                    : undefined
                }
              >
                {currency(marginBalance).format()}
              </span>
            </ViewDetailsRow>
          </FooterContainer>
        </ViewDetailsContent>
      )}
      <SubmitButton
        buttonTheme={ButtonThemeEnum.NEUTRAL2}
        disabled={
          !marginAmount || marginAmount <= 0 || loading || Boolean(marginError)
        }
        onClick={onUpdateMargin}
      >
        {loading ? (
          <Spinner />
        ) : isAddMargin ? (
          t("add_margin")
        ) : (
          t("remove_margin")
        )}
      </SubmitButton>
    </BaseDetailsModal>
  );
}

export default AdjustIsolatedMarginModal;
