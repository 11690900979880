/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import currency from "currency.js";
import moment from "moment";
import { memo, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import {
  GetTradeHistory200ResponseTradeHistoryInner,
  InstrumentTypeResponse,
  SideResponse,
  TradeTypeResponse,
} from "../../codegen-api";
import { COLORS } from "../../constants/design/colors";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import { PageEndpointEnum } from "../../enums/endpoint";
import { ITableColumn } from "../../interfaces/Table/TableColumn";
import { getTimeAgo, nanosToSeconds } from "../../utils/date";
import { formatSizePrecision } from "../../utils/format";
import {
  getContractPriceStep,
  getOptionsTypeFromSymbol,
} from "../../utils/instruments";
import { PnL } from "../FundingHistory/style";
import MarketCell from "../PortfolioSettings/Table/MarketCell";
import RowActionButton from "../shared/RowActionButton";
import { Spinner } from "../shared/Spinner";
import { SpinnerContainerWrapper } from "../shared/Spinner/style";
import { DefaultCellForColumn } from "../shared/Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../shared/Table/DefaultHeaderForColumn";
import { Align, Direction, TradeType } from "../shared/Table/style";
import TradeDetailsModal from "../shared/ViewDetailsModal/TradeDetailsModal";
import {
  Content,
  TradeHistoryTableWrapper,
  ViewAllTradeHistoryCell,
} from "./style";

interface ITradeHistoryProps {
  histories?: GetTradeHistory200ResponseTradeHistoryInner[];
  loading?: boolean;
  selectedMarketType?: InstrumentTypeResponse;
  isStickyHeader?: boolean;
  emptyContent?: JSX.Element;
}

// A modified version of Order that includes calculated values
interface IModifiedTradeHistory
  extends GetTradeHistory200ResponseTradeHistoryInner {
  amountPrecision: number;
  pricePrecision: number;
}

function TradeHistory({
  histories,
  loading,
  selectedMarketType,
  emptyContent,
  isStickyHeader = false,
}: ITradeHistoryProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeHistory.TradeHistory",
  });
  const { t: translateAll } = useTranslation();
  const { t: tooltips } = useTranslation("tooltips");
  const { activeOptionMarkets, activePerpMarkets } = useContext(
    MarketInstrumentContext
  );
  const navigate = useNavigate();

  const viewAllTradeHistory = useCallback(() => {
    navigate(`${PageEndpointEnum.PORTFOLIO}/trade-history`);
  }, [navigate]);

  // Weird behaviour when using useSortBy hook
  // that happens when state changes happens on parent
  // https://github.com/TanStack/table/issues/2369
  const memoizedHistories: IModifiedTradeHistory[] = useMemo(
    () =>
      (histories || []).map((h) => {
        const markets =
          h.instrument_type === InstrumentTypeResponse.Option
            ? activeOptionMarkets
            : activePerpMarkets;
        const instrument = markets?.find((m) => m.underlying_asset === h.asset);
        return {
          ...h,
          amountPrecision: getContractPriceStep(instrument).amount_precision,
          pricePrecision: getContractPriceStep(instrument).price_precision,
        };
      }),
    [histories, activeOptionMarkets, activePerpMarkets]
  );

  const [viewDetailsForHistory, setViewDetailsForHistory] =
    useState<IModifiedTradeHistory>();

  const columns: ITableColumn<IModifiedTradeHistory>[] = useMemo(() => {
    const ivCols: ITableColumn<IModifiedTradeHistory>[] = [
      {
        title: t("iv"),
        accessor: "iv",
        align: "right",
        tooltip: tooltips("iv"),
        valueExtractor: (value) =>
          value ? `${(Number(value) * 100).toFixed(2)}%` : "-",
      },
    ];
    const cols: ITableColumn<IModifiedTradeHistory>[] = [
      {
        title: t("market"),
        accessor: "instrument_name",
        tooltip: tooltips("market"),
        Cell: ({ row }) => {
          const { expiry, strike, instrument_name } = row.original;
          const optionType = getOptionsTypeFromSymbol(instrument_name);
          return (
            <MarketCell
              instrumentName={instrument_name}
              optionType={optionType}
              expiry={expiry}
              strike={strike}
            />
          );
        },
      },
      {
        title: t("side"),
        accessor: "side",
        tooltip: tooltips("side"),
        Cell: ({ value }) => (
          <Direction side={value}>
            {value === SideResponse.Buy ? t("buy") : t("sell")}
          </Direction>
        ),
      },
      {
        title: t("type"),
        accessor: "trade_type",
        tooltip: tooltips("tradeType"),
        Cell: ({ value }) => (
          <TradeType tradeType={value}>{value || "-"}</TradeType>
        ),
      },
      {
        title: t("size"),
        accessor: "amount",
        align: "right",
        tooltip: tooltips("positionSize"),
        Cell: ({ value, row }) => (
          <Align align="right">
            {formatSizePrecision(value, row.original.amountPrecision)}
          </Align>
        ),
      },
      {
        title: t("price"),
        accessor: "price",
        align: "right",
        tooltip: tooltips("avgEntryPrice"),
        Cell: ({ value, row }) => (
          <Align align="right">
            {value
              ? currency(value, {
                  precision: row.original.pricePrecision,
                }).format()
              : "-"}
          </Align>
        ),
      },
      {
        title: t("order_type"),
        accessor: "order_type",
        tooltip: tooltips("orderType"),
        valueExtractor: (value) => t(value),
      },
      ...(selectedMarketType === InstrumentTypeResponse.Option ? ivCols : []),
      {
        title: t("realized_pnl"),
        accessor: "pnl",
        align: "right",
        tooltip: tooltips("realizedPnl"),
        Cell: ({ row, value }) => {
          const { fees } = row.original;
          const difference = Math.abs(Number(value)) - Math.abs(Number(fees));
          return difference !== 0 && value ? (
            <Align align="right">
              <PnL
                color={
                  Number(value) >= 0 ? COLORS.positive.one : COLORS.negative.one
                }
              >
                {Number(value) > 0
                  ? `${
                      Number(value) > 0.01
                        ? `+${currency(Number(value)).format()}`
                        : "<+$0.01"
                    }`
                  : `${
                      Number(value) < -0.01
                        ? currency(Number(value)).format()
                        : "<-$0.01"
                    }`}
              </PnL>
            </Align>
          ) : (
            <Align align="right">-</Align>
          );
        },
      },
      {
        title: t("fees"),
        accessor: "fees",
        tooltip: tooltips("fee"),
        align: "right",
        Cell: ({ row }) => {
          const { fees, trade_type } = row.original;
          if (Number(fees) > 0 || trade_type === TradeTypeResponse.Funding) {
            return (
              <Align align="right">
                {Number(fees) > 0.01
                  ? `${currency(Number(fees)).format()}`
                  : "<$0.01"}
              </Align>
            );
          }

          return <Align align="right">-</Align>;
        },
      },
      {
        title: t("time"),
        accessor: "created_timestamp",
        tooltip: tooltips("tradeTimestamp"),
        valueExtractor: (value) =>
          getTimeAgo(moment.unix(nanosToSeconds(value)), translateAll),
      },
      {
        title: " ",
        accessor: "trade_id",
        Header: () => <div />,
        Cell: ({ row }) => (
          <Align align="right">
            <RowActionButton
              variant={"details"}
              onClick={(e) => {
                e.stopPropagation();
                setViewDetailsForHistory(row.original);
              }}
            />
          </Align>
        ),
      },
    ];
    return cols;
  }, [selectedMarketType, t, tooltips, translateAll]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: memoizedHistories,
        defaultColumn: {
          Header: DefaultHeaderForColumn,
          Cell: DefaultCellForColumn,
        },
        autoResetSortBy: false,
      } as any,
      useSortBy
    );

  if (emptyContent && !memoizedHistories.length) {
    return emptyContent;
  }

  return (
    <TradeHistoryTableWrapper stickyHeader={isStickyHeader}>
      <TradeDetailsModal
        trade={viewDetailsForHistory}
        onHide={() => setViewDetailsForHistory(undefined)}
      />
      {loading || !memoizedHistories.length ? (
        <div>
          {loading ? (
            <SpinnerContainerWrapper>
              <Spinner />
            </SpinnerContainerWrapper>
          ) : (
            <Content>{t("no_trade_history")}</Content>
          )}
        </div>
      ) : (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  // eslint-disable-next-line react/jsx-key
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { ...rowProps } = row.getRowProps();
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...rowProps}>
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
            <tr role="row">
              <ViewAllTradeHistoryCell
                role="cell"
                onClick={viewAllTradeHistory}
              >
                {t("view_all_trade_history")}
              </ViewAllTradeHistoryCell>
            </tr>
          </tbody>
        </table>
      )}
    </TradeHistoryTableWrapper>
  );
}

export default memo(TradeHistory);
