import { useCallback, useContext } from "react";
import { authApi } from "../../../services/api/apiFetcher";
import { AuthContext } from "../../../contexts/AuthContext";
import { unwrapApiError } from "../../../utils/errors";

export const useGetSocketFees = () => {
  const { apiKey, apiSecret } = useContext(AuthContext);
  const fetcher = authApi(apiKey, apiSecret);

  const getSocketFees = useCallback(
    async (chainId: number, socketConnector: string, socketAddress: string) => {
      try {
        const res = (
          await (
            await fetcher.getSocketFees(chainId, socketConnector, socketAddress)
          )()
        ).data;
        return res.socket_fees;
      } catch (error) {
        throw unwrapApiError(error);
      }
    },
    [fetcher]
  );

  return {
    getSocketFees,
  };
};
