import { useMemo } from "react";
import { COMPONENTS } from "../../../constants/design/spacing";
import useTradesWSS from "../../../hooks/wss/useTradesWSS";
import InstrumentTradeHistory from "../../InstrumentTradeHistory";
import { ScrollableContent, ScrollableWrapper } from "./style";

interface ITradeHistoryProps {
  instrumentName?: string;
  sizeDecimals: number;
  priceDecimals: number;
}

function TradeHistorySection({
  instrumentName,
  sizeDecimals,
  priceDecimals,
}: ITradeHistoryProps) {
  const { data: histories, loading: tradeHistoryValidating } = useTradesWSS(
    instrumentName,
    undefined,
    true
  );

  const memoizedHistories = useMemo(
    () =>
      histories.filter((h) =>
        h.instrument_name.includes(String(instrumentName))
      ),
    [histories, instrumentName]
  );

  return (
    <ScrollableWrapper>
      <ScrollableContent>
        <InstrumentTradeHistory
          sizeDecimals={sizeDecimals}
          priceDecimals={priceDecimals}
          rowHeight={`${COMPONENTS.perpsTableRowSmall}px`}
          trades={memoizedHistories}
          loading={tradeHistoryValidating}
        />
      </ScrollableContent>
    </ScrollableWrapper>
  );
}

export default TradeHistorySection;
