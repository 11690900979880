/* eslint-disable react/no-array-index-key */
import { CSSProperties } from "react";
import { Separator } from "../PortfolioSettings/Table/style";
import { TagContainer, TagContent } from "./style";

interface ITagProps {
  texts: (string | JSX.Element)[];
  color: string;
  backgroundColor: string;
  style?: CSSProperties;
}

// Separator
function Tag({
  texts,
  color,
  backgroundColor,
  style
}: ITagProps) {
  return (
    <TagContainer color={color} bgColor={backgroundColor} style={style}>
      {
        texts.map((text, index) => (
          <TagContent key={`${String(text)}-${index}`}>
            <span>{text}</span>
            {
              index !== texts.length - 1 && (
                <Separator style={{ backgroundColor: color }} />
              )
            }
          </TagContent>
        ))
      }
    </TagContainer>
  );
}

export default Tag;
