import { useCallback, useState } from "react";

// Scroll utilities
const useScroll = (scrollableContainer?: HTMLDivElement | null) => {
  // Prevent scrolling to same element again
  const [prevScrollToElement, setPrevScrollToElement] = useState<HTMLElement>();

  // Scrolls to the element, centered on the scrollableContainer
  // if force is true, scrolls regardless if its the same element
  const scrollVerticalTo = useCallback((
    element?: HTMLElement | null,
    animate?: boolean,
    force?: boolean,
  ) => {
    if (
      !scrollableContainer
      || !element
      || (prevScrollToElement === element && !force)
    ) {
      return;
    }

    scrollableContainer.scrollTo(
      {
        top: element.offsetTop
          - (scrollableContainer.offsetHeight / 2)
          - scrollableContainer.offsetTop
          - (element.offsetHeight / 2),
        behavior: animate ? "smooth" : "auto",
      },
    );
    setPrevScrollToElement(element);
  }, [prevScrollToElement, scrollableContainer]);

  return {
    scrollVerticalTo,
  };
};

export default useScroll;
