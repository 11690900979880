import moment from "moment";
import { useMemo } from "react";
import currency from "currency.js";
import { useTranslation } from "react-i18next";
import {
  GetInstrumentInstrumentNameTradeHistory200ResponseTradeHistoryInner,
  SideResponse,
} from "../../codegen-api";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { COMPONENTS } from "../../constants/design/spacing";
import { nanosToSeconds } from "../../utils/date";
import { Spinner } from "../shared/Spinner";
import { SpinnerContainerWrapper } from "../shared/Spinner/style";
import {
  RowWithSizeIndicator,
  TableHeaderWrapper,
} from "../shared/Table/style";
import {
  RowUpdater,
  TradeOrderBookWrapper,
} from "../TicketDetails/TradeOrderBook/style";
import { TradeColumn } from "./style";

interface IHeader {
  title: string;
  width: string;
}

const emptyRow: Partial<GetInstrumentInstrumentNameTradeHistory200ResponseTradeHistoryInner> =
  {
    amount: undefined,
    price: undefined,
    side: undefined,
    created_timestamp: undefined,
  };

const defaultHistoryRows: Partial<GetInstrumentInstrumentNameTradeHistory200ResponseTradeHistoryInner>[] =
  [emptyRow, emptyRow, emptyRow, emptyRow];

interface ISpreadProps {
  totalSize: number;
  color: string;
  textColor: string;
  timestamp: number | null;
  price: string | null;
  size: string | null;
  sizeDecimals: number;
  priceDecimals: number;
  rowHeight?: string;
  headers: Array<IHeader>;
}
function TradeRow({
  price,
  size,
  totalSize,
  timestamp,
  color,
  textColor,
  sizeDecimals,
  priceDecimals,
  rowHeight,
  headers,
}: ISpreadProps) {
  return (
    <RowWithSizeIndicator
      showBorderBottom
      highlightOnHover
      fillColor={color}
      fillPercent={(Number(size) / totalSize) * 100}
      fillTo={"right"}
      style={{
        height: rowHeight || COMPONENTS.tableRowSmall,
      }}
    >
      <TradeColumn
        width={headers[0].width}
        color={size ? textColor : TEXT_COLORS.one}
      >
        {price ? currency(price, { precision: priceDecimals }).format() : "-"}
      </TradeColumn>
      <TradeColumn width={headers[1].width}>
        {size
          ? currency(size, { precision: sizeDecimals }).format({ symbol: "" })
          : "-"}
      </TradeColumn>
      <TradeColumn color={TEXT_COLORS.two} width={headers[2].width}>
        {timestamp ? moment.unix(timestamp).format("HH:mm:ss") : "-"}
      </TradeColumn>
    </RowWithSizeIndicator>
  );
}

interface IInstrumentTradeHistoryProps {
  sizeDecimals: number;
  priceDecimals: number;
  trades?: GetInstrumentInstrumentNameTradeHistory200ResponseTradeHistoryInner[];
  loading?: boolean;
  rowHeight?: string;
}

function InstrumentTradeHistory({
  trades,
  loading,
  rowHeight,
  sizeDecimals,
  priceDecimals,
}: IInstrumentTradeHistoryProps) {
  const { t } = useTranslation("app", { keyPrefix: "InstrumentTradeHistory" });
  const headers = useMemo(
    () => [
      {
        title: t("price"),
        width: "40%",
      },
      {
        title: t("size"),
        width: "30%",
      },
      {
        title: t("time"),
        width: "30%",
      },
    ],
    [t]
  );

  const total = useMemo(
    () => ({
      value: (trades || []).map((trade) => Number(trade.amount)),
      size: (trades || []).reduce(
        (prev, curr) => prev + Number(curr.amount),
        0
      ),
    }),
    [trades]
  );

  return (
    <TradeOrderBookWrapper>
      {!trades && loading ? (
        <SpinnerContainerWrapper height={100}>
          <Spinner />
        </SpinnerContainerWrapper>
      ) : (
        <>
          <TableHeaderWrapper>
            <RowWithSizeIndicator>
              {headers.map((header) => (
                <TradeColumn key={header.title} width={header.width}>
                  {header.title}
                </TradeColumn>
              ))}
            </RowWithSizeIndicator>
          </TableHeaderWrapper>
          <div>
            {(trades?.length ? trades : defaultHistoryRows).map(
              ({ amount, price, side, created_timestamp, trade_id }, index) => (
                <RowUpdater
                  // eslint-disable-next-line react/no-array-index-key
                  key={
                    trade_id
                      ? `${trade_id}-${index}`
                      : `${created_timestamp}-${index}`
                  }
                >
                  <TradeRow
                    rowHeight={rowHeight}
                    priceDecimals={priceDecimals}
                    sizeDecimals={sizeDecimals}
                    price={price || ""}
                    size={amount || ""}
                    totalSize={total.size}
                    timestamp={nanosToSeconds(created_timestamp || 0)}
                    color={
                      side === SideResponse.Buy
                        ? COLORS.positive.four
                        : COLORS.negative.four
                    }
                    textColor={
                      side === SideResponse.Buy
                        ? COLORS.positive.one
                        : COLORS.negative.one
                    }
                    headers={headers}
                  />
                </RowUpdater>
              )
            )}
          </div>
        </>
      )}
    </TradeOrderBookWrapper>
  );
}

export default InstrumentTradeHistory;
