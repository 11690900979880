import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IosShare } from "../../../../assets/svg/ios-share.svg";
import { ReactComponent as PlusSquare } from "../../../../assets/svg/plus-square.svg";
import { ReactComponent as Phone } from "../../../../assets/svg/smartphone.svg";
import { SPACING } from "../../../../constants/design/spacing";
import {
  AccountStateEnum,
  AuthContext,
} from "../../../../contexts/AuthContext";
import { PageEndpointEnum } from "../../../../enums/endpoint";
import { useToast } from "../../../../hooks/toast";
import { pwaURLPrefix, usePWAFlow } from "../../../../hooks/usePWAFlow";
import { DecryptAccountStatusEnum } from "../../../../utils/encryption/accountPasswordEncryption";
import { isPWA } from "../../../../utils/pwa";
import { shortenAddress } from "../../../../utils/strings";
import { ToastEnum, ToastStatusEnum } from "../../../../utils/toast";
import { BaseModal } from "../../../BaseModal";
import { Button, ButtonThemeEnum } from "../../../Buttons/styles";
import DigitsInput from "../../Input/DigitsInput";
import { IContentTitle } from "../model";
import {
  Content,
  ErrorText,
  StepConnector,
  StepContent,
  StepNumber,
  Title,
} from "../style";
import QRCodeScannerModal from "./QRCodeScannerModal";

interface IMobilePWAModalProps {
  show?: boolean;
  onHide?: () => void;
}

function MobilePWAModal({ show, onHide }: IMobilePWAModalProps) {
  const { pwaInfo, decryptData, setProcessedPWA } = usePWAFlow();
  const { accountApiKeyState, manualSyncAccount, account, disconnectAccount } =
    useContext(AuthContext);

  const { addToast } = useToast();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");

  const { t } = useTranslation("app", {
    keyPrefix: "Header.PWAFlowModal.MobilePWAModal",
  });

  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [pwaInfo]);

  useEffect(() => {
    setError(false);
  }, [password]);

  const onRedirect = useCallback(
    (hide?: boolean) => {
      if (hide) {
        onHide?.();
      }
      navigate(PageEndpointEnum.TRADING);
    },
    [navigate, onHide]
  );

  const onReceiveURL = useCallback(
    (url: string) => {
      setProcessedPWA(false);
      const data = url.split(pwaURLPrefix)[1];
      const path = `${pwaURLPrefix}${data}`;
      navigate(path);
    },
    [navigate, setProcessedPWA]
  );

  const onCompleteSync = useCallback(() => {
    const d = decryptData(password);
    const { decryptedInfo } = d || {};
    if (!d || d.status !== DecryptAccountStatusEnum.OK || !decryptedInfo) {
      setError(true);
      return;
    }

    addToast(
      {
        header: t("sync_success"),
        status: ToastStatusEnum.SUCCESS,
        type: ToastEnum.SIMPLE,
      },
      4000
    );
    manualSyncAccount({
      ...decryptedInfo,
      passwordProtected: false,
    });
    setProcessedPWA(true);
    onRedirect(true);
  }, [
    addToast,
    decryptData,
    manualSyncAccount,
    onRedirect,
    password,
    setProcessedPWA,
    t,
  ]);

  const contentTitle: IContentTitle | null = useMemo(() => {
    if (isPWA) {
      if (accountApiKeyState === AccountStateEnum.OK) {
        // Already synced, lets ask user to disconnect first
        return {
          title: (
            <Title>
              <Phone />
              {t("sync_with_desktop")}
            </Title>
          ),
          content: (
            <Content>
              {t("device_already_synced", {
                account: shortenAddress(account || ""),
              })}
              <Button
                fullWidth
                buttonTheme={ButtonThemeEnum.NEGATIVE}
                onClick={disconnectAccount}
              >
                {t("disconnect_and_sync")}
              </Button>
              <Button
                fullWidth
                buttonTheme={ButtonThemeEnum.NEUTRAL3}
                onClick={() => onRedirect(true)}
              >
                {t("cancel")}
              </Button>
            </Content>
          ),
        };
      }
      return {
        title: (
          <Title>
            <Phone />
            {t("sync_with_desktop")}
          </Title>
        ),
        content: (
          <Content>
            {t("sync_with_desktop_description")}
            <DigitsInput
              totalDigits={6}
              onChangeDigits={setPassword}
              style={{
                marginTop: SPACING.two,
              }}
            />
            {error && <ErrorText>{t("invalid_password")}</ErrorText>}
            <Button
              fullWidth
              buttonTheme={ButtonThemeEnum.PRIMARY}
              disabled={password.length !== 6}
              onClick={onCompleteSync}
            >
              {t("complete_sync")}
            </Button>
          </Content>
        ),
      };
    }
    return {
      title: (
        <Title>
          <Phone />
          {t("download_pwa")}
        </Title>
      ),
      content: (
        <>
          <Content>{t("looks_like_safari_browser")}</Content>
          <Content
            center
            style={{
              marginTop: SPACING.three,
            }}
          >
            <StepNumber>1</StepNumber>
            <StepContent>{t("tap_share_button")}</StepContent>
            <Button buttonTheme={ButtonThemeEnum.HIGHLIGHT}>
              <IosShare />
            </Button>
            <StepConnector />
            <StepNumber>2</StepNumber>
            <StepContent>{t("tap_add_home_screen")}</StepContent>
            <Button buttonTheme={ButtonThemeEnum.HIGHLIGHT}>
              <PlusSquare />
            </Button>
          </Content>
        </>
      ),
    };
  }, [
    account,
    accountApiKeyState,
    disconnectAccount,
    error,
    onCompleteSync,
    password.length,
    t,
    onRedirect,
  ]);

  if (isPWA && !pwaInfo) {
    return (
      <QRCodeScannerModal
        onHide={onHide}
        show={show}
        onReceiveURL={onReceiveURL}
      />
    );
  }

  return (
    <BaseModal
      onHide={onRedirect}
      show={show}
      style={{
        width: 310,
      }}
      title={contentTitle.title}
    >
      {contentTitle.content}
    </BaseModal>
  );
}

export default MobilePWAModal;
