import { CSSProperties } from "react";
import { SPACING } from "../../constants/design/spacing";
import FillBar from "./FillBar";
import { FillMeterWrapper, FillPercentage } from "./style";

interface IFillMeter {
  // Number between 0 - 100
  fill: string;
  barColor?: string;
  textColor?: string;
  layoutReversed?: boolean;
  decimals?: number;
  barStyle?: CSSProperties;
  showText?: boolean;
}

function FillMeter({
  fill,
  barColor,
  textColor,
  layoutReversed,
  decimals,
  barStyle,
  showText = true,
}: IFillMeter) {
  const percentage = Number(fill);
  return (
    <FillMeterWrapper textColor={textColor} layoutReversed={layoutReversed}>
      <FillBar
        fillColor={barColor}
        percent={percentage}
        style={{
          marginRight: layoutReversed ? 0 : SPACING.two,
          marginLeft: layoutReversed && showText ? SPACING.two : 0,
          ...barStyle,
        }}
      />
      {showText ? <FillPercentage>
        {percentage.toFixed(decimals ?? 1)}%
      </FillPercentage> : null}
    </FillMeterWrapper>
  );
}

export default FillMeter;
