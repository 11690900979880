import { ChartTypeEnum } from ".";
import { GetOrders200Response } from "../../../codegen-api";
import { IPerpsMarket } from "../../../contexts/MarketInstrumentContext/useGetMarkets";
import { useOrder } from "../../../hooks/api/order/useOrder";
import usePositionsWSS from "../../../hooks/wss/usePositionsWSS";
import { getContractPriceStep } from "../../../utils/instruments";
import TradingViewChart from "../../TradingViewChart";

interface IPerpsChartProps {
  currentChartType: ChartTypeEnum;
  perpInstrument: IPerpsMarket;
  onCancelOrder: (order: GetOrders200Response) => void;
}

function PerpsChart({
  currentChartType,
  perpInstrument,
  onCancelOrder,
}: IPerpsChartProps) {
  const { positionsMap } = usePositionsWSS(perpInstrument.instrument_name);
  const { data: orderData } = useOrder();

  return (
    <TradingViewChart
      hidden={currentChartType !== ChartTypeEnum.PRICE}
      amountDecimals={getContractPriceStep(perpInstrument).amount_precision}
      symbol={perpInstrument?.instrument_name}
      position={positionsMap[perpInstrument.instrument_name]}
      orders={orderData?.filter(
        (o) => o.instrument_id === perpInstrument.instrument_id
      )}
      onCancelOrder={onCancelOrder}
    />
  );
}

export default PerpsChart;
