import { useTranslation } from "react-i18next";
import { OrderTypeResponse } from "../../../../codegen-api";
import SegmentedControl from "../../../shared/SegmentedControl";
import { TEXT_COLORS, LAYER_COLORS } from "../../../../constants/design/colors";
import { FONT_SIZE } from "../../../../constants/design/fontSize";
import { SPACING } from "../../../../constants/design/spacing";
import { getStopOrderName } from "../../../../utils/order";

interface IOrderTypeSegmentedControlProps {
  dropdownValue: string;
  orderType: OrderTypeResponse;
  setOrderType: (type: OrderTypeResponse) => void;
  isStop: boolean;
  setIsStop: (isStop: boolean) => void;
}

function OrderTypeSegmentedControl({
  dropdownValue,
  isStop,
  setIsStop,
  orderType,
  setOrderType,
}: IOrderTypeSegmentedControlProps) {
  const { t } = useTranslation();
  const { t: commonFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.Common",
  });

  return (
    <SegmentedControl
      config={{
        widthType: "fullWidth",
        backgroundColor: LAYER_COLORS.two,
        activeBackgroundColor: LAYER_COLORS.three,
        borderRadius: "10px",
        button: {
          height: 32,
          fontSize: FONT_SIZE.one,
          px: SPACING.two,
        },
      }}
      hasDropdown
      segments={[
        {
          value: OrderTypeResponse.Market,
          display: commonFormTranslations("market"),
          textColor:
            !isStop && orderType === OrderTypeResponse.Market
              ? TEXT_COLORS.one
              : TEXT_COLORS.three,
        },
        {
          value: OrderTypeResponse.Limit,
          display: commonFormTranslations("limit"),
          textColor:
            !isStop && orderType === OrderTypeResponse.Limit
              ? TEXT_COLORS.one
              : TEXT_COLORS.three,
        },
        {
          type: "dropdown",
          value: dropdownValue,
          display: isStop ? getStopOrderName(t, orderType, true, true) : "Stop",
          textColor: isStop ? TEXT_COLORS.one : TEXT_COLORS.three,
          items: [
            {
              title: getStopOrderName(t, OrderTypeResponse.Market, true, true),
              value: `Stop-${OrderTypeResponse.Market}`,
              onSelectItem: () => {
                setOrderType(OrderTypeResponse.Market);
                setIsStop(true);
              },
            },
            {
              title: getStopOrderName(t, OrderTypeResponse.Limit, true, true),
              value: `Stop-${OrderTypeResponse.Limit}`,
              onSelectItem: () => {
                setOrderType(OrderTypeResponse.Limit);
                setIsStop(true);
              },
            },
          ],
        },
      ]}
      value={isStop ? dropdownValue : orderType}
      onSelect={(value) => {
        setIsStop(false);
        setOrderType(value as OrderTypeResponse);
      }}
    />
  );
}

export default OrderTypeSegmentedControl;
