import currency from "currency.js";
import moment from "moment";
import { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FundingHistory from "../../components/FundingHistory";
import DownloadCSVButton from "../../components/PortfolioSettings/Table/DownloadCSVButton";
import {
  Header,
  SpaceBetweenInnerHeader,
} from "../../components/PortfolioSettings/Table/style";
import Pagination from "../../components/shared/Pagination";
import { Select } from "../../components/shared/Select";
import { TableContainerWrapper } from "../../components/shared/Table/style";
import { SPACING } from "../../constants/design/spacing";
import { FUNDING_RATE_PRICE_PRECISION } from "../../constants/precision/form";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import { PaginationPerPageOptionsEnum } from "../../enums/pagination";
import { useFundingHistory } from "../../hooks/api/funding/useFundingHistory";
import { nanosToSeconds } from "../../utils/date";
import { PortfolioPaginationInlineStyle } from "../../components/shared/Pagination/style";
import { IHasMobileComponentProps } from "../shared";
import { ComponentTitle } from "../../components/PortfolioSettings/style";

export interface IModifiedFundingHistory {
  market: string;
  created_timestamp: string;
  funding_rate: string;
  mark_price: string;
}

export function FundingHistoryTab({ isMobile }: IHasMobileComponentProps) {
  const [instrumentNameFilter, setInstrumentNameFilter] = useState<string>();
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(
    PaginationPerPageOptionsEnum.TWENTY_FIVE
  );
  const HIGH_LOAD_MODE = process.env.REACT_APP_HIGH_LOAD_MODE === "true";
  const { data, isLoading } = useFundingHistory(
    instrumentNameFilter,
    undefined,
    HIGH_LOAD_MODE
  );
  const { activePerpMarkets } = useContext(MarketInstrumentContext);
  const { t } = useTranslation("app", { keyPrefix: "pages.FundingHistoryTab" });
  const headerRef = useRef<HTMLDivElement>(null);
  const perps = activePerpMarkets?.map((p) => p.instrument_name) || [];

  const fundingHistory = useMemo(
    () =>
      (data?.funding_history || [])
        .map(
          (funding_history) =>
            ({
              market: funding_history[0],
              created_timestamp: funding_history[1],
              funding_rate: funding_history[2],
              mark_price: funding_history[3],
            } as IModifiedFundingHistory)
        )
        .slice(currentPage * perPage, currentPage * perPage + perPage),
    [currentPage, data?.funding_history, perPage]
  );

  const csvData = useMemo(() => {
    if (fundingHistory && fundingHistory.length) {
      const headers = [t("market"), t("funding_rate"), t("date_time")];
      const rows =
        fundingHistory.map(
          (v) => [
            v.market,
            `${currency(Number(v.funding_rate), {
              precision: FUNDING_RATE_PRICE_PRECISION,
            }).format({ symbol: "" })}%`,
            moment
              .unix(nanosToSeconds(v.created_timestamp))
              .utc()
              .toISOString(),
          ],
          []
        ) || [];

      return [headers, ...rows];
    }
    return undefined;
  }, [fundingHistory, t]);

  return (
    <TableContainerWrapper $isMobile={isMobile}>
      <Header isMobile={isMobile} ref={headerRef}>
        {!isMobile && <ComponentTitle>{t("funding_history")}</ComponentTitle>}
        <SpaceBetweenInnerHeader>
          <Select
            isRound
            wrapperStyle={{
              flex: 1,
              marginRight: SPACING.four,
              overflowX: "scroll",
            }}
            options={[undefined, ...perps].map((instrumentName) => {
              if (instrumentName) {
                return {
                  label: instrumentName,
                  isActive: instrumentName === instrumentNameFilter,
                  onClick: () => setInstrumentNameFilter(instrumentName),
                };
              }
              return {
                label: t("all_perps"),
                isActive: !instrumentNameFilter,
                onClick: () => setInstrumentNameFilter(undefined),
              };
            })}
          />
          <DownloadCSVButton
            data={csvData}
            filename={`${t("aevo_public_funding_history")} ${moment().format(
              "DD_MMM_YYYY_HH_mm_ss"
            )}`}
          />
        </SpaceBetweenInnerHeader>
      </Header>
      <FundingHistory
        isMobile={isMobile}
        histories={fundingHistory}
        loading={isLoading}
      />
      <Pagination
        isMobile={isMobile}
        containerStyle={PortfolioPaginationInlineStyle}
        currentPage={currentPage}
        totalPages={
          data?.funding_history?.length
            ? Math.ceil(Number(data.funding_history?.length) / perPage)
            : 0
        }
        onPrevPage={() => setCurrentPage(currentPage - 1)}
        onNextPage={() => setCurrentPage(currentPage + 1)}
        itemCountConfig={{
          totalItems: data?.funding_history?.length || 0,
          perPage,
          perPageDropdownOptions: Object.values(
            PaginationPerPageOptionsEnum
          ).filter(Number) as number[],
          onPerPageDropdownSelect: (v) => {
            setCurrentPage(0);
            setPerPage(v);
          },
        }}
      />
    </TableContainerWrapper>
  );
}
