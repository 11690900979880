import { useEffect, useMemo, useRef } from "react";
import { useShallow } from "zustand/react/shallow";
import { InstrumentTypeResponse } from "../../codegen-api";
import { AssetResponse } from "../../utils/asset";
import { IWSSMark } from "./model/markPrice";
import {
  ITickerToMark,
  useMarkPriceStore,
} from "./public/store/useMarkPriceStore";

export type InstrumentToMark = ITickerToMark;

export type IMarkPriceAssetDerivative = {
  asset: AssetResponse;
  derivative: InstrumentTypeResponse;
};
export type IMultiMarkPriceAssetDerivative = {
  [instrumentName: string]: IMarkPriceAssetDerivative;
};

/**
 * @param info mark price info
 * @param delayedMemoMs optional. Milliseconds to delay the mark price update
 * @returns
 */
export const useMarkPriceWSS = (
  info?: {
    instrumentName: string;
    asset: AssetResponse;
    derivative: InstrumentTypeResponse;
  },
  delayedMemoMs = 0
) => {
  const lastUpdateTime = useRef(0);
  const lastMark = useRef<IWSSMark | undefined>({} as IWSSMark);

  const mark = useMarkPriceStore((state) => {
    if (Date.now() < lastUpdateTime.current + delayedMemoMs) {
      return lastMark.current;
    }
    const m = info ? state.mark[info.instrumentName] : undefined;
    lastMark.current = m;
    lastUpdateTime.current = Date.now();
    return m;
  });
  const addTickers = useMarkPriceStore((state) => state.addMarkTickers);

  useEffect(() => {
    if (!info) {
      return;
    }
    const markTickers = [`${info.asset}:${info.derivative}`];
    addTickers(markTickers);
  }, [addTickers, info]);

  return mark;
};

/**
 *
 * @param ticker key value of instrumentName to asset+derivative
 * @param delayedMemoMs optional. Milliseconds to delay the mark price update
 * @returns
 */
export const useMarkPricesWSS = (
  ticker: IMultiMarkPriceAssetDerivative,
  delayedMemoMs = 0
) => {
  // This is the only difference between this and the above.
  // Reason we need a separate hook is so that
  const tickerKeys = useMemo(() => Object.keys(ticker), [ticker]);
  const lastUpdateTime = useRef(0);
  const lastMark = useRef({} as ITickerToMark);

  const addTickers = useMarkPriceStore((state) => state.addMarkTickers);

  const mark = useMarkPriceStore(
    useShallow((state) => {
      if (Date.now() < lastUpdateTime.current + delayedMemoMs) {
        return lastMark.current;
      }
      const m = tickerKeys.reduce(
        (acc, symb) => ({
          ...acc,
          [symb]: state.mark[symb],
        }),
        {} as ITickerToMark
      );
      lastMark.current = m;
      lastUpdateTime.current = Date.now();
      return m;
    })
  );

  useEffect(() => {
    if (Object.values(ticker).length) {
      const markTickers = Object.values(ticker).map(
        (t) => `${t.asset}:${t.derivative}`
      );
      addTickers(markTickers);
    }
    return () => {};
  }, [addTickers, ticker]);

  return {
    instrumentMark: mark,
  };
};
