import { AssetResponse } from "../../../utils/asset";
import { getExpiryFromSymbol } from "../../../utils/instruments";
import { WebsocketChannelEnum } from "../model/shared";

export const getOrderbookChannel = (instrument: string) => {
  const isOptions = Boolean(getExpiryFromSymbol(instrument))
  return `${
    !isOptions
      ? WebsocketChannelEnum.ORDER_BOOK_THROTTLED
      : WebsocketChannelEnum.ORDER_BOOK
  }:${instrument}`;
}

export const getTradesChannel = (instrument: string) => `${WebsocketChannelEnum.TRADES}:${instrument}`;
export const getIndexChannel = (asset: AssetResponse) => `${WebsocketChannelEnum.INDEX}:${asset}`

export const getMarkPriceChannel = (ticker: string) =>`${WebsocketChannelEnum.MARK_PRICE}:${ticker}`;
export const getTickerChannel = (assetDerivative: string) => `${WebsocketChannelEnum.TICKER}:${assetDerivative}`;