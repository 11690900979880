import { useCallback, useEffect, useMemo, useState } from "react";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { usePWAFlow } from "../usePWAFlow";
import { isPWA } from "../../utils/pwa";

const useTerms = () => {
  const [termsAccepted, setTermsAccepted] = useState<boolean>(true);
  const { pwaInfo } = usePWAFlow();

  // Initial check to see whether terms have already been accepted
  useEffect(() => {
    const ofac = window.localStorage.getItem(LocalStorageKeyEnum.OFAC);
    setTermsAccepted(Boolean(ofac));
  }, []);

  const setTerms = useCallback(() => {
    window.localStorage.setItem(LocalStorageKeyEnum.OFAC, "true");
    setTermsAccepted(true);
  }, [setTermsAccepted]);

  const showTerms = useMemo(
    () => (isPWA ? !pwaInfo && !termsAccepted : !termsAccepted),
    [pwaInfo, termsAccepted]
  );

  return {
    setTerms,
    showTerms,
  };
};

export default useTerms;
