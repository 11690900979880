/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { ethers } from "ethers";
import currency from "currency.js";
import { useForm, useWatch } from "react-hook-form";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import {
  GetRfqs200ResponseBlocksInner,
  GetRfqs200ResponseBlocksInnerLegsInner,
  InstrumentTypeResponse,
  PostQuotes200Response,
  SideResponse,
} from "../../../../codegen-api";
import { BaseModal } from "../../../../components/BaseModal";
import Tag from "../../../../components/ConfirmationModal/Tag";
import {
  TEXT_COLORS,
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
} from "../../../../constants/design/colors";
import { AssetTag, Ratio } from "../RFQTable/style";
import { getAssetLogo } from "../../../../utils/asset/assets";
import { nanosToSeconds } from "../../../../utils/date";
import { Button, ButtonThemeEnum } from "../../../../components/Buttons/styles";
import { Input } from "../../../../components/shared/Input";
import { BlockDescWrapper, BlockPriceWrapper } from "./style";
import { SPACING } from "../../../../constants/design/spacing";
import { ToastEnum, ToastStatusEnum } from "../../../../utils/toast";
import { useSFX } from "../../../../hooks/useSFX";
import { useToast } from "../../../../hooks/toast";
import { Spinner } from "../../../../components/shared/Spinner";
import { ITakerQuotePayload } from "../../../../hooks/api/rfq/useQuotes";

interface IRFQModalProps {
  block?: GetRfqs200ResponseBlocksInner;
  side?: SideResponse;
  show: boolean;
  onHide: () => void;
  acceptQuote: (
    payload: ITakerQuotePayload
  ) => Promise<PostQuotes200Response | undefined>;
  mutateRFQs: () => void;
}

function RequestAcceptRFQModal({
  block,
  side,
  show,
  onHide,
  acceptQuote,
  mutateRFQs,
}: IRFQModalProps) {
  const { playSound } = useSFX();
  const { addToast, addErrorToast } = useToast();
  const { t: apiErrors } = useTranslation("apiErrors");
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    control,
    trigger,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      legs: (block?.legs || []).map((leg) => ({
        ratio: leg.ratio,
        instrument: leg.instrument_id,
        side: leg.side,
        price: undefined as number | undefined,
      })),
    },
  });

  useEffect(() => {
    if (!show) {
      reset();
    } else {
      setValue(
        "legs",
        (block?.legs || []).map((leg) => ({
          ratio: leg.ratio,
          instrument: leg.instrument_id,
          side: leg.side,
          price: undefined as number | undefined,
        }))
      );
    }
  }, [block?.legs, reset, setValue, show]);

  const legs = useWatch({ control, name: "legs" });

  const blockPrice = useMemo(() => {
    // If there are no legs, return 0
    if (!legs || legs.length === 0) {
      return 0;
    }

    // For each leg, depending on the side, calculate the price * ratio
    // If it's sell, deduct the price from the acc
    // If it's buy, add the price to the acc
    const amount = legs.reduce((acc, leg) => {
      if (leg.price && leg.price > 0) {
        if (leg.side === "sell") {
          return acc - leg.price * Number(leg.ratio);
        }
        return acc + leg.price * Number(leg.ratio);
      }

      return acc;
    }, 0);
    return amount;
  }, [legs]);

  const isTaker = useMemo(() => block?.role === "taker", [block?.role]);

  const bestOffer = useMemo(() => {
    if (isTaker && block?.orderbook && side) {
      return side === SideResponse.Buy
        ? block.orderbook?.bids?.[0][0]
        : block.orderbook?.asks?.[0][0];
    }
    return undefined;
  }, [isTaker, block, side]);

  const bestOfferQuantity = useMemo(() => {
    if (isTaker && block?.orderbook && side) {
      if (block.full_size) {
        return block.amount;
      }

      return side === SideResponse.Buy
        ? block.orderbook?.bids?.[0][1]
        : block.orderbook?.asks?.[0][1];
    }
    return undefined;
  }, [isTaker, block, side]);

  const invertedSide = useCallback((s: SideResponse) => {
    if (s === SideResponse.Buy) {
      return SideResponse.Sell;
    }
    return SideResponse.Buy;
  }, []);

  const tagSide = useCallback(
    (legSide: SideResponse) => {
      // Inverse the bid if it's a taker
      if (isTaker && side) {
        if (side === SideResponse.Buy) {
          return invertedSide(legSide);
        }

        return legSide;
      }

      // Inverse the ask if it's a maker
      return side === SideResponse.Sell ? invertedSide(legSide) : legSide;
    },
    [isTaker, invertedSide, side]
  );

  const generateLegTag = useCallback(
    (
      leg: GetRfqs200ResponseBlocksInnerLegsInner
    ): Array<string | JSX.Element> => {
      if (leg.instrument_type === InstrumentTypeResponse.Option) {
        return [
          <AssetTag key={leg.instrument_id}>
            <img src={getAssetLogo(leg.asset)} alt={leg.asset} />
            {leg.asset}
          </AssetTag>,
          <span
            key={leg.instrument_id}
            style={{
              color:
                tagSide(leg.side) === SideResponse.Buy
                  ? COLORS.positive.one
                  : COLORS.negative.one,
            }}
          >
            {tagSide(leg.side)}
          </span>,
          leg.option_type as string,
          moment.unix(nanosToSeconds(Number(leg.expiry))).format("DD MMM YYYY"),
          currency(leg.strike as string, { precision: 0 }).format(),
          <Ratio key={leg.instrument_id}>x{leg.ratio}</Ratio>,
        ];
      }
      return [
        <AssetTag key={leg.instrument_id}>
          <img src={getAssetLogo(leg.asset)} alt={leg.asset} />
          {leg.asset}
        </AssetTag>,
        <span
          key={leg.instrument_id}
          style={{
            color:
              tagSide(leg.side) === SideResponse.Buy
                ? COLORS.positive.one
                : COLORS.negative.one,
          }}
        >
          {tagSide(leg.side)}
        </span>,
        leg.instrument_type,
        <Ratio key={leg.instrument_id}>x{leg.ratio}</Ratio>,
      ];
    },
    [tagSide]
  );

  const tags = useMemo(() => {
    if (block?.legs && block.legs.length > 0) {
      return block.legs.map((leg) => generateLegTag(leg));
    }

    return undefined;
  }, [block, generateLegTag]);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      if (block?.block_id) {
        const payload: ITakerQuotePayload = {
          block_id: block.block_id || "",
          is_buy: isTaker
            ? side !== SideResponse.Buy
            : side === SideResponse.Buy,
          limit_price: isTaker
            ? String(ethers.utils.parseUnits(bestOffer || "0", 6))
            : undefined,
          amount: `${
            isTaker
              ? ethers.utils.parseUnits(bestOfferQuantity || "0", 6)
              : ethers.utils.parseUnits(block.amount, 6)
          }`,
          legs: isTaker
            ? []
            : legs.map((leg) => ({
                instrument: Number(leg.instrument),
                price: ethers.utils.parseUnits(String(leg.price), 6).toString(),
              })),
        };

        const response = await acceptQuote(payload);

        if (response) {
          mutateRFQs();
          playSound("order_placed");
          reset();
          addToast(
            {
              type: ToastEnum.SIMPLE,
              header: "RFQ Created",
              subheader: "RFQ created successfully",
              status: ToastStatusEnum.SUCCESS,
            },
            4000
          );
        }
      }
    } catch (error: any) {
      addErrorToast(
        "Create RFQ failed",
        apiErrors(error.message) || t("try_again")
      );
    }

    setLoading(false);
    onHide?.();
  }, [
    acceptQuote,
    addErrorToast,
    addToast,
    apiErrors,
    bestOffer,
    bestOfferQuantity,
    block?.amount,
    block?.block_id,
    isTaker,
    legs,
    mutateRFQs,
    onHide,
    playSound,
    reset,
    side,
  ]);

  const offerButtonLabel = useMemo(() => {
    if (isTaker) {
      if (side === SideResponse.Buy) {
        return SideResponse.Sell;
      }
      return SideResponse.Buy;
    }
    if (side === SideResponse.Sell) {
      return "Ask";
    }
    return "Bid";
  }, [isTaker, side]);

  if (!block || !side) {
    return null;
  }

  return (
    <BaseModal
      style={{
        width: "fit-content",
        minWidth: 311,
      }}
      title={`${isTaker ? "Accept" : "Make"} Offer - ${block.block_id
        .slice(-6)
        .toUpperCase()}`}
      show={show}
      onHide={onHide}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <BlockDescWrapper>
          {block?.legs?.length} instrument(s):
        </BlockDescWrapper>
        <div>
          {tags && (
            <div>
              {tags.map((texts, i) => (
                <div key={i}>
                  <Tag
                    style={{
                      padding: SPACING.two,
                      marginTop: SPACING.three,
                      marginBottom: SPACING.two,
                      border: `1px solid ${BORDER_COLORS.one}`,
                    }}
                    color={TEXT_COLORS.two}
                    backgroundColor={BACKGROUND_COLORS.component}
                    texts={texts}
                  />
                  {!isTaker ? (
                    <Input
                      {...register(`legs.${i}.price`, {
                        onChange: (e) => {
                          const leg = legs[i];
                          leg.price = Number(e.target.value);
                          setValue(`legs.${i}`, leg);
                          trigger(`legs.${i}`);
                        },
                        required: true,
                        validate: {
                          moreThanZero: (v) => v !== undefined && v >= 0,
                        },
                      })}
                      placeholder="0"
                      type="number"
                      error={Boolean(errors.legs?.[i]?.price)}
                    />
                  ) : null}
                </div>
              ))}
            </div>
          )}
        </div>
        <BlockPriceWrapper>
          <span>Block Price:</span>
          <span>
            {currency(
              // eslint-disable-next-line no-nested-ternary
              isTaker ? bestOffer || 0 : blockPrice
            ).format()}
          </span>
        </BlockPriceWrapper>
        <Button
          disabled={isTaker ? false : !isValid}
          fullWidth
          type="submit"
          buttonTheme={
            (isTaker ? invertedSide(side) : side) === SideResponse.Buy
              ? ButtonThemeEnum.POSITIVE
              : ButtonThemeEnum.NEGATIVE
          }
        >
          {isLoading ? (
            <Spinner
              color={
                (isTaker ? invertedSide(side) : side) === SideResponse.Buy
                  ? COLORS.positive.one
                  : COLORS.negative.one
              }
            />
          ) : (
            offerButtonLabel
          )}
        </Button>
      </form>
    </BaseModal>
  );
}

export default RequestAcceptRFQModal;
