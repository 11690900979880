/* eslint-disable react/no-array-index-key */
import { CSSProperties, useMemo, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { BaseModal, IBaseModalProps } from "../BaseModal";
import { Button, ButtonThemeEnum, buttonTextColor } from "../Buttons/styles";
import { Spinner } from "../shared/Spinner";
import { ModalErrorMessage, ModalWarningMessage } from "../shared/style";
import Tag from "./Tag";
import { ButtonsContainer, StatContainer } from "./style";
import { SideResponse } from "../../codegen-api";
import { PositionChip } from "../TradeForm/style";

export interface IConfirmationModalStat {
  title: string;
  data: string | number | JSX.Element;
  side?: SideResponse;
}

export function Stat({ title, data, side }: IConfirmationModalStat) {
  const { t } = useTranslation("app", {
    keyPrefix: "ConfirmationModal.EditOrderModal",
  });
  return (
    <StatContainer>
      <span>
        {title}{" "}
        {side && (
          <PositionChip type={side}>
            {side === SideResponse.Buy ? t("long") : t("short")}
          </PositionChip>
        )}
      </span>
      <span>{data}</span>
    </StatContainer>
  );
}

export interface IConfirmationButton {
  title?: string | JSX.Element;
  overrideComponent?: JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  theme?: ButtonThemeEnum;
  style?: CSSProperties;
}

interface IConfirmationModalProps extends IBaseModalProps {
  minWidth?: string;
  // Optional error message to be displayed at the bottom
  error?: string;
  warning?: string;
  tag?: {
    texts: string[];
    color: string;
    backgroundColor: string;
  };
  stats?: IConfirmationModalStat[];
  extras?: ReactNode;
  isLoading?: boolean;
  confirmationButtons?: IConfirmationButton[];
  confirmationWrapperStyle?: CSSProperties;
  statsFirst?: boolean;
}
function ConfirmationModal({
  confirmationButtons = [],
  confirmationWrapperStyle,
  minWidth,
  tag,
  stats,
  extras,
  onHide,
  isLoading,
  error,
  warning,
  statsFirst = true,
  ...modalProps
}: IConfirmationModalProps) {
  const buttons = useMemo(() => {
    if (confirmationButtons.length === 0) {
      return null;
    }

    return (
      <ButtonsContainer style={confirmationWrapperStyle ?? {}}>
        {confirmationButtons.map((b, i) => {
          if (b.overrideComponent) {
            return (
              <div key={`${b.title}-${i}`} style={b.style}>
                {b.overrideComponent}
              </div>
            );
          }

          return (
            <Button
              key={`${b.title}-${i}`}
              fullWidth
              style={b.style}
              buttonTheme={b.theme || ButtonThemeEnum.NEUTRAL2}
              onClick={b.onClick}
              disabled={b.disabled || isLoading}
            >
              {isLoading ? (
                <Spinner
                  color={buttonTextColor[b.theme || ButtonThemeEnum.HIGHLIGHT]}
                />
              ) : (
                b.title
              )}
            </Button>
          );
        })}
      </ButtonsContainer>
    );
  }, [confirmationButtons, confirmationWrapperStyle, isLoading]);

  const content = useMemo(() => {
    const statsComponent = stats ? (
      <div>
        {stats.map((stat) => (
          <Stat
            key={stat.title}
            title={stat.title}
            data={stat.data}
            side={stat.side}
          />
        ))}
      </div>
    ) : undefined;
    if (statsFirst) {
      return (
        <div>
          {statsComponent}
          {extras}
        </div>
      );
    }
    return (
      <div>
        {extras}
        {statsComponent}
      </div>
    );
  }, [extras, stats, statsFirst]);

  return (
    <BaseModal
      {...modalProps}
      style={{
        ...(modalProps.style || {}),
        minWidth: minWidth || "311px",
      }}
      onHide={onHide}
    >
      {tag && (
        <Tag
          texts={tag.texts}
          color={tag.color}
          backgroundColor={tag.backgroundColor}
        />
      )}
      {content}
      {buttons}
      {error && <ModalErrorMessage>{error}</ModalErrorMessage>}
      {warning && <ModalWarningMessage>{warning}</ModalWarningMessage>}
    </BaseModal>
  );
}

export default ConfirmationModal;
