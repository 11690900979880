/* eslint-disable no-nested-ternary */
import { FieldErrors, UseFormReturn } from "react-hook-form";
import { PropsWithChildren } from "react";
import {
  GetAccount200ResponsePositionsInner,
  OrderTypeResponse,
} from "../../../../codegen-api";
import { GapWrapper, InputsWrapper } from "../../style";
import {
  IOptionsFormFieldValues,
  OptionFormFieldKeyEnum,
  PriceInput,
  SizeInput,
} from "./form";
import { FormValidatorKeysEnum } from "../../../../enums/form";
import { roundToStepSize } from "../../../../utils/format";
import { IContractPriceStep } from "../../../../utils/instruments";

export interface IInputsProps {
  orderType: OrderTypeResponse;
  amount: never;
  reduceOnlyDisabled: boolean;
  reduceOnly: boolean;
  isLoading: boolean;
  maxOrderSizeBeforeOBProtectionTriggered: number;
  insufficientMarketLiquidityWarning?: string;
  errors: FieldErrors<IOptionsFormFieldValues>;
  contractPriceStep: IContractPriceStep;
  optionsForm: UseFormReturn<IOptionsFormFieldValues, any, undefined>;
  verifyNotEnoughBalance: (contractSize: string) => boolean;
  verifyOrderValueTooSmall: (contractSize: string) => boolean;
  currentPosition?: GetAccount200ResponsePositionsInner;
  isMobileScreen?: boolean;
}

function Inputs({
  orderType,
  amount,
  reduceOnlyDisabled,
  reduceOnly,
  isLoading,
  maxOrderSizeBeforeOBProtectionTriggered,
  insufficientMarketLiquidityWarning,
  errors,
  contractPriceStep,
  optionsForm,
  verifyNotEnoughBalance,
  verifyOrderValueTooSmall,
  currentPosition,
  isMobileScreen,
}: PropsWithChildren<IInputsProps>) {
  const { register, setValue } = optionsForm;
  return (
    <InputsWrapper isMobileScreen={isMobileScreen}>
      <GapWrapper isMobileScreen={isMobileScreen}>
        {
          // Only shows price input if limit order.
          orderType === OrderTypeResponse.Limit && (
            <PriceInput
              isMobileScreen={isMobileScreen}
              minPrice={contractPriceStep.price_step}
              register={register(OptionFormFieldKeyEnum.PRICE, {
                disabled: isLoading,
                required: true,
                validate: {
                  [FormValidatorKeysEnum.moreThanZero]: (v) =>
                    parseFloat(v) > 0,
                  [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
                    roundToStepSize(
                      v,
                      contractPriceStep.price_step,
                      contractPriceStep.price_precision
                    ) >=
                    1 / 10 ** contractPriceStep.price_precision,
                },
                onBlur(event) {
                  setValue(
                    OptionFormFieldKeyEnum.PRICE,
                    roundToStepSize(
                      parseFloat(event.target.value),
                      contractPriceStep.price_step,
                      contractPriceStep.price_precision
                    ).toString()
                  );
                },
              })}
              errors={errors}
            />
          )
        }
        <SizeInput
          isMobileScreen={isMobileScreen}
          minAmountSize={contractPriceStep.amount_step || 0}
          register={register("amount", {
            disabled: isLoading,
            required: true,
            validate: {
              [FormValidatorKeysEnum.moreThanZero]: (v) => parseFloat(v) > 0,
              [FormValidatorKeysEnum.notEnoughBalance]: (v) =>
                verifyNotEnoughBalance(v),
              [FormValidatorKeysEnum.orderValueTooSmall]: (v) =>
                verifyOrderValueTooSmall(v),
              [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
                roundToStepSize(
                  v,
                  contractPriceStep.amount_step,
                  contractPriceStep.amount_precision
                ) >=
                1 / 10 ** contractPriceStep.amount_precision,
              [FormValidatorKeysEnum.reduceOnlyOrderSizeValid]: (v) => {
                if (!reduceOnly || reduceOnlyDisabled) {
                  return true;
                }
                return currentPosition
                  ? Number(v || 0) <= Number(currentPosition.amount)
                  : true;
              },
            },
            onBlur(event) {
              setValue(
                OptionFormFieldKeyEnum.AMOUNT,
                roundToStepSize(
                  parseFloat(event.target.value),
                  contractPriceStep.amount_step,
                  contractPriceStep.amount_precision
                ).toString()
              );
            },
          })}
          style={{ paddingBottom: 0 }}
          errors={errors}
          warning={
            Number(amount) <= maxOrderSizeBeforeOBProtectionTriggered
              ? insufficientMarketLiquidityWarning
              : undefined
          }
        />
      </GapWrapper>
    </InputsWrapper>
  );
}

export default Inputs;
