import { AxiosError } from "axios";
import { useContext } from "react";
import useSWRImmutable from "swr/immutable";
import { GetBalanceHistory200Response } from "../../../codegen-api";
import { AuthContext } from "../../../contexts/AuthContext";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { authApi } from "../../../services/api/apiFetcher";

/**
 * Immutable swr hook that only gets called once on startup, and every subsequent time when apiKey is changed.
 */
export const useAnalytics = () => {
  const { apiKey, apiSecret } = useContext(AuthContext);
  const fetcher = authApi(apiKey, apiSecret);

  return useSWRImmutable<GetBalanceHistory200Response, AxiosError>(
    apiKey ? [APIEndpointEnum.ANALYTICS, apiKey] : undefined,
    async () => (await (await fetcher.getAnalytics())()).data
  );
};
