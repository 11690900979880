import moment from "moment";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../constants/design/colors";
import { FONT_STYLE } from "../../constants/design/fontSize";
import { LINKS } from "../../constants/links";
import ExternalLinkText from "../ExternalLinkText";

interface IMaintenanceScheduleProps {
  maintenanceTimestampSeconds: number;
}

function MaintenanceSchedule({ maintenanceTimestampSeconds }: IMaintenanceScheduleProps) {
  const { t } = useTranslation("app", { keyPrefix: "UptimeMonitor.MaintenanceSchedule" });

  const maintenanceTime = Number(maintenanceTimestampSeconds)
    ? moment.unix(Number(maintenanceTimestampSeconds)).utc()
    : undefined;

  if (maintenanceTime && maintenanceTime.isValid()) {
    return (
      <ExternalLinkText
        onClick={() => window.open(LINKS.uptimeRobot)}
        color={COLORS.highlight.one}
        hoverColor={COLORS.highlight.one}
        wrapperStyle={{
          fontSize: FONT_STYLE.label.two.fontSize,
          lineHeight: FONT_STYLE.label.two.lineHeight,
        }}
      >
        {t("scheduled_at", {
          time: maintenanceTime.format("HH:mm"),
          date: maintenanceTime.format("DD MMM YYYY")
        })}
      </ExternalLinkText>
    );
  }
  return null;
}

export default MaintenanceSchedule;
