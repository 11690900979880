import { Row } from "react-bootstrap";
import styled from "styled-components";
import { LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../../constants/design/spacing";

export const GreeksContent = styled(Row)`
  ${FONT_CSS.data.three}
  display: flex;
  align-items: center;
  margin: 0;
`;

export const Greek = styled.div`
  background-color: ${LAYER_COLORS.three};
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: ${BORDER_RADIUS.five}px;
  display: flex;
  vertical-align: middle;
  color: ${TEXT_COLORS.two};
  justify-content: center;
  margin: auto 0;
  margin-right: ${SPACING.three}px;

  img {
    margin: auto;
  }
`;

export const GreekWrapper = styled.div<{ isTradeForm?: boolean }>`
  display: flex;
  ${({ isTradeForm }) =>
    isTradeForm
      ? `margin-top: ${SPACING.two}px`
      : `margin: ${SPACING.two}px 0`};

  p {
    margin-bottom: 0;
  }
`;

export const GreeksContentMobile = styled.div`
  ${FONT_CSS.data.three}
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  align-items: center;
  padding: ${SPACING.two}px;
  padding-top: 0;
  margin: 0;
`;
