import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { TableWrapper } from "../../../../components/shared/Table/style";
import { COMPONENTS, SPACING } from "../../../../constants/design/spacing";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../../../constants/design/colors";
import { TagContainer } from "../../../../components/ConfirmationModal/style";
import { Button } from "../../../../components/Buttons/styles";
import { flashingCSS } from "../../../../components/shared/style";

const BLOCK_ROW_HEIGHT = 60;

export const RFQTableWrapper = styled(TableWrapper)`
  padding: 0 ${SPACING.three}px;
  width: calc(100vw - ${COMPONENTS.portfolioSideTab}px);

  table {
    border-collapse: separate;
    border-spacing: 0 8px;
  }

  thead {
    th {
      background: none;
    }
  }

  tbody {
    border: 1px solid ${BORDER_COLORS.one};
    vertical-align: unset !important;

    tr {
      td {
        margin: 0 auto !important;
        padding: 0 !important;
        height: ${BLOCK_ROW_HEIGHT}px !important;
        width: fit-content !important;
        background: none !important;

        > * {
          min-height: ${BLOCK_ROW_HEIGHT}px !important;
          padding: ${SPACING.two}px ${SPACING.three}px;
        }

        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
`;

export const BlockRow = styled(motion.tr)<{ $isTaker?: boolean }>`
  cursor: pointer;
  background: ${BACKGROUND_COLORS.four} !important;

  td {
    padding: ${SPACING.two}px !important;
  }
`;

export const TagWrapper = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  cursor: pointer;
  margin-right: ${BLOCK_ROW_HEIGHT}px !important;
  position: relative;
  width: 370px;
  padding-left: 0 !important;

  ${TagContainer} {
    border: 1px solid ${BORDER_COLORS.two};
    height: 44px !important;

    &:not(:last-of-type) {
      margin-right: ${SPACING.two}px;
    }
  }

  ${({ isExpanded }) =>
    isExpanded
      ? css`
          flex-direction: column;
          ${TagContainer} {
            margin-bottom: ${SPACING.two}px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        `
      : css`
          flex-direction: row;

          ${TagContainer} {
            margin-bottom: 0;
          }
        `};
`;

export const AssetTag = styled.div`
  > img {
    margin-right: ${SPACING.two}px;
    height: 24px;
    width: 24px;
  }
`;

export const ChevronWrapper = styled.div`
  position: absolute;
  top: calc(${BLOCK_ROW_HEIGHT / 2}px - 8px);
  right: calc(-${BLOCK_ROW_HEIGHT / 2}px - 8px);
  height: 16px;
  transition: 0.2s ease-in-out;
`;

export const OfferButton = styled(Button)`
  min-height: unset;
  padding: ${SPACING.two}px ${SPACING.three}px;
  border-radius: 20px;
  min-width: 100px;
`;

export const BlockId = styled.div<{ $isTaker: boolean }>`
  color: ${TEXT_COLORS.two};

  ${({ $isTaker }) =>
    $isTaker
      ? css`
          display: flex;
          color: ${COLORS.blue.one};

          &::before {
            width: 4px;
            height: 4px;
            background: ${COLORS.blue.one};
            content: "";
            margin: auto;
            margin-right: ${SPACING.one}px;
            border-radius: 50%;
            ${flashingCSS}
          }
        `
      : undefined}
`;

export const Ratio = styled.span`
  text-transform: lowercase !important;
`;
