import { useEffect } from "react";
import { AssetResponse } from "../../utils/asset";
import { useIndexPriceStore } from "./public/store/useIndexPriceStore";

const useIndexWSS = (asset?: AssetResponse) => {
  const index = useIndexPriceStore((state) =>
    asset ? state.index[asset] : undefined
  );

  const addIndexAsset = useIndexPriceStore((state) => state.addIndexAsset);
  const removeIndexAsset = useIndexPriceStore(
    (state) => state.removeIndexAsset
  );

  useEffect(() => {
    if (asset) {
      addIndexAsset(asset);
      return () => {
        removeIndexAsset(asset);
      };
    }
    return () => {};
  }, [addIndexAsset, asset, removeIndexAsset]);

  return {
    index,
  };
};

export default useIndexWSS;
