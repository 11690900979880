import currency from "currency.js";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { ExplanationWrapper, Stat, StatNumber, StatTitle } from "../style";
import TooltipExplanation from "../../Tooltip";
import { TooltipWrapper } from "../../Header/AccountModal/style";
import { ICON_COLORS, TEXT_COLORS } from "../../../../constants/design/colors";
import { ReactComponent as Info } from "../../../../assets/svg/info.svg";
import ExternalLinkText from "../../../ExternalLinkText";
import { SPACING } from "../../../../constants/design/spacing";
import { BoostValueWrapper } from "../../../TradeForm/style";

interface ITradingRewardsTooltipProp {
  farmBoostAfterTrade: number;
  boostIncreasePercentage?: string;
  boostedVolume: number;
  isPrelaunch?: boolean;
}

function TradeFormTradingTooltip({
  farmBoostAfterTrade,
  boostIncreasePercentage,
  boostedVolume,
  isPrelaunch,
}: ITradingRewardsTooltipProp) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeFormTradingTooltip",
  });

  const explanation = useMemo(
    () => (
      <ExplanationWrapper>
        <span>
          {isPrelaunch ? t("prelaunch_explanation") : t("explanation")}
        </span>
        <Stat style={{ marginTop: SPACING.two }}>
          <StatTitle>{t("volume")}</StatTitle>
          <StatNumber>
            {boostedVolume
              ? currency(boostedVolume / farmBoostAfterTrade).format()
              : "-"}
          </StatNumber>
        </Stat>
        <Stat>
          <StatTitle>{t("volume_boost")}</StatTitle>
          <StatNumber>
            {`${currency(farmBoostAfterTrade, {
              symbol: "",
            }).format()}x `}
            {boostIncreasePercentage && (
              <BoostValueWrapper>{`+${Number(boostIncreasePercentage).toFixed(
                2
              )}%`}</BoostValueWrapper>
            )}
          </StatNumber>
        </Stat>
        <Stat>
          <StatTitle>{t("boosted_volume")}</StatTitle>
          <StatNumber>
            {boostedVolume ? currency(boostedVolume).format() : "-"}
          </StatNumber>
        </Stat>
        <ExternalLinkText
          href="https://docs.aevo.xyz/trading-and-staking-incentives/earning-usdaevo/trade-on-aevo"
          target="_blank"
          rel="noreferrer"
          color={TEXT_COLORS.two}
          wrapperStyle={{
            fontSize: "12px",
            alignSelf: "flex-start",
            marginTop: SPACING.two,
          }}
        >
          {t("learn_more")}
        </ExternalLinkText>
      </ExplanationWrapper>
    ),
    [
      boostIncreasePercentage,
      boostedVolume,
      farmBoostAfterTrade,
      isPrelaunch,
      t,
    ]
  );

  return (
    <TooltipExplanation
      title={t("title")}
      placement="top"
      explanation={explanation}
      renderContent={({ ref, ...triggerHandler }) => (
        <TooltipWrapper stroke={ICON_COLORS.two} ref={ref} {...triggerHandler}>
          <Info />
        </TooltipWrapper>
      )}
      learnMore
    />
  );
}

export default TradeFormTradingTooltip;
