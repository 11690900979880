import styled, { CSSProperties, css } from "styled-components";
import { motion } from "framer-motion";
import { COMPONENTS, SPACING } from "../../../../constants/design/spacing";
import { TableWrapper } from "../../../../components/shared/Table/style";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../../../constants/design/colors";
import { FONT_STYLE } from "../../../../constants/design/fontSize";

export const EditorRow = styled(motion.tr)<{ $hasError?: boolean }>`
  background: none !important;

  > td {
    padding: ${SPACING.two}px !important;
    ${({ $hasError }) =>
      $hasError
        ? css`
            background-color: ${COLORS.negative.five} !important;
          `
        : null})}
  }

`;

const BUTTON_HEIGHT = 48;

export const StrategyTableWrapper = styled(TableWrapper)`
  table {
    border-collapse: separate;
    border-spacing: 0 8px;
  }

  thead {
    position: sticky;
    top: 0;

    th {
      background: none;
    }
  }

  td {
    height: fit-content !important;
  }

  tbody {
    border: 1px solid ${BORDER_COLORS.two};
    tr {
      background-color: ${BACKGROUND_COLORS.four} !important;
      td {
        input {
          width: 60px;
        }

        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }

  * {
    overflow: visible;
  }

  .dropdown > button {
    height: ${BUTTON_HEIGHT}px;
    padding: 0 ${SPACING.two}px;
  }

  .dropdown-menu.show {
    display: grid;
    max-height: calc(${BUTTON_HEIGHT}px * 5);
    overflow: scroll;

    > button {
      height: ${BUTTON_HEIGHT}px;
      padding: 0 ${SPACING.two}px;

      &:hover {
        background-color: ${BACKGROUND_COLORS.four};
      }
    }
  }
`;

export const FooterButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${SPACING.two}px 0;

  > *:not(:last-child) {
    margin-right: ${SPACING.two}px;
  }
`;

export const RFQWrapper = styled.div`
  height: calc(
    100vh - ${COMPONENTS.header.main}px - ${COMPONENTS.footer.desktop}px
  );
  display: grid;
`;

export const AssetTag = styled.div`
  display: flex;
  height: ${BUTTON_HEIGHT}px;

  > * {
    margin: auto 0;
  }
  > img {
    margin-right: ${SPACING.two}px;
    height: 24px;
    width: 24px;
  }
`;

export const AmountWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${SPACING.two}px 0;
  padding-top: 0;

  > span {
    margin: auto 0;
    margin-right: ${SPACING.two}px;
    color: ${TEXT_COLORS.two};
    ${FONT_STYLE.label.one};
  }

  > div {
    width: 200px;
  }
`;

export const dropdownToggleStyles: CSSProperties = {
  textTransform: "capitalize",
  background: BACKGROUND_COLORS.four,
};

export const EmptyContent = styled.div`
  padding-left: ${SPACING.two}px;
`;
