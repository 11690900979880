import { TFunction } from "react-i18next";
import {
  GetNotifications200Response,
  GetTransactionHistory200ResponseTransactionHistoryInner,
  GetTransactionHistory200ResponseTransactionHistoryInnerTransferDetails,
} from "../../codegen-api";
import { isProduction } from "../../utils/env";
import { getRewardsLinkForLabel } from "../links";
import { IStrategyDetails, STRATEGY_DETAILS } from "../strategies";

export enum RewardsIncentiveTypeEnum {
  DEPOSIT_INCENTIVE = "DEPOSIT_INCENTIVE",
}

export type IRewardsIncentiveSenderAddresses = {
  [key in RewardsIncentiveTypeEnum]: string;
};

export const INCENTIVE_SENDER_ADDRESSES: IRewardsIncentiveSenderAddresses = {
  DEPOSIT_INCENTIVE: isProduction()
    ? "0x4dA2888822CCe922Bc891D8c75b3F4Bd62c641FE"
    : "0x70997970C51812dc3A010C7d01b50e0d17dc79C8",
} as const;

export const getRewardsTypeFromSenderAddress = (address?: string) => {
  if (!address) {
    return undefined;
  }
  const type = Object.keys(INCENTIVE_SENDER_ADDRESSES).find(
    (k) =>
      INCENTIVE_SENDER_ADDRESSES[
        k as RewardsIncentiveTypeEnum
      ].toLowerCase() === address.toLowerCase()
  );

  if (type) {
    return type as RewardsIncentiveTypeEnum;
  }
  return undefined;
};

export interface IRewardDetails {
  programName: string;
  link?: string;
  strategy?: IStrategyDetails;
}

export type IGetRewardDetailsInfo =
  | {
      type: "notification";
      t: TFunction;
      notificationOrTransaction?: GetNotifications200Response;
    }
  | {
      type: "transaction";
      t: TFunction;
      notificationOrTransaction?: GetTransactionHistory200ResponseTransactionHistoryInner;
    };

export const getRewardDetails = ({
  type,
  notificationOrTransaction,
  t,
}: IGetRewardDetailsInfo): IRewardDetails | undefined => {
  if (!notificationOrTransaction) {
    return undefined;
  }

  let account: string | undefined;
  let label: string | undefined;
  let transfer_details:
    | GetTransactionHistory200ResponseTransactionHistoryInnerTransferDetails
    | undefined;

  // Unwrap rewards info
  if (type === "notification") {
    const notification = notificationOrTransaction;
    account = notification.metadata?.account;
    label = notification.metadata?.label;
    transfer_details = notification.metadata?.transfer_details;
  } else {
    const transaction = notificationOrTransaction;
    account = transaction?.counter_party;
    label = transaction?.label;
    transfer_details = transaction?.transfer_details;
  }

  const isCurrentReward = Boolean(transfer_details);
  const legacyRewardType = getRewardsTypeFromSenderAddress(account);
  const isLegacyReward = Boolean(
    !isCurrentReward &&
      (!!legacyRewardType || label) &&
      !(label === "YV_DEPOSIT" || label === "YV_WITHDRAW")
  );

  if (isLegacyReward) {
    if (label) {
      if (label === "MA_DEPOSIT") {
        const strategy_address = (
          notificationOrTransaction as GetTransactionHistory200ResponseTransactionHistoryInner
        ).counter_party;
        const strategy = STRATEGY_DETAILS[strategy_address!](t);
        return {
          programName: label,
          link: undefined,
          strategy,
        };
      }
      if (label === "MA_WITHDRAW") {
        const strategy_address = (
          notificationOrTransaction as GetTransactionHistory200ResponseTransactionHistoryInner
        ).counter_party;
        const strategy = STRATEGY_DETAILS[strategy_address!](t);
        return {
          programName: label,
          link: undefined,
          strategy,
        };
      }
      return {
        programName:
          label === "CLAIM_REFERRAL_REWARDS" ? "Referral Rewards" : label,
        link: getRewardsLinkForLabel(label),
        strategy: undefined,
      };
    }
    switch (legacyRewardType) {
      case RewardsIncentiveTypeEnum.DEPOSIT_INCENTIVE:
        return {
          programName: "Deposit Campaign Incentive",
          link: undefined,
          strategy: undefined,
        };
      default:
        break;
    }
  }

  if (transfer_details) {
    return {
      programName: transfer_details.description,
      link: transfer_details.link,
      strategy: undefined,
    };
  }

  return undefined;
};
