import { useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import MainPage from "./MainPage";
import WSBCampaign from "./WSBCampaign";
import useScreenSize from "../../hooks/screenSize/useScreenSize";
import { LeaderboardWrapper } from "./style";
import { secondsToNanos } from "../../utils/date";

export enum CampaignEnum {
  WSB = "wsb-eigen",
}

export interface ICampaignDetail {
  startTime: number;
  endTime: number;
  title: string;
  campaignName: string;
  rewards: number;
  epoch: number;
}

export type CampaignDetailsType = {
  [key in CampaignEnum]: ICampaignDetail;
};

export const wsbEpoch1StartTime = secondsToNanos(1727856000); // 2nd October
export const wsbEpoch1EndTime = secondsToNanos(1729065600); // 16th October
export const wsbEpoch2StartTime = secondsToNanos(1729065600); // 16th October
export const wsbEpoch2EndTime = secondsToNanos(1730275200); // 30th October

const currentTime = Date.now() * 1e6; // Convert current time to nanoseconds

const wsbStartTime =
  currentTime >= wsbEpoch1EndTime ? wsbEpoch2StartTime : wsbEpoch1StartTime;

const wsbEndTime =
  currentTime >= wsbEpoch1EndTime ? wsbEpoch2EndTime : wsbEpoch1EndTime;

export const wsbCurrentEpoch = currentTime >= wsbEpoch1EndTime ? 2 : 1;
export const CampaignDetails: CampaignDetailsType = {
  [CampaignEnum.WSB]: {
    startTime: wsbStartTime,
    endTime: wsbEndTime,
    title: "WSB Campaign",
    campaignName: CampaignEnum.WSB,
    rewards: 25000,
    epoch: wsbCurrentEpoch,
  },
};

type CampaignComponentType = {
  [key in CampaignEnum]: JSX.Element;
};

const CampaignComponent: CampaignComponentType = {
  [CampaignEnum.WSB]: (
    <WSBCampaign campaignDetails={CampaignDetails[CampaignEnum.WSB]} />
  ),
};

function CampaignsPage() {
  const { isMobileScreen } = useScreenSize();

  const location = useLocation();

  // Get active page from URL
  // Extract the active campaign from the URL path
  const activePage = useMemo(() => {
    const splitPath = location.pathname.split("/campaign/")[1];
    return splitPath || undefined; // Set activePage to undefined if no campaign is found
  }, [location]);

  const pages = useMemo(() => {
    // Add the main leaderboard page
    const pageList = [
      {
        title: "Main Page",
        path: "*",
        redirect: "campaign",
        element: <MainPage />,
        isActive: !activePage,
      },
    ];

    Object.keys(CampaignDetails).forEach((campaignKey) => {
      const campaign = CampaignDetails[campaignKey as CampaignEnum];
      pageList.push({
        title: campaign.title,
        path: `/${campaignKey}`,
        redirect: `campaign/${campaignKey}`,
        element: CampaignComponent[campaignKey as CampaignEnum],
        isActive: activePage === campaignKey,
      });
    });

    return pageList;
  }, [activePage]);

  return (
    <LeaderboardWrapper isMobileScreen={isMobileScreen}>
      <Routes>
        {pages.map((page) => (
          <Route key={page.path} path={page.path} element={page.element} />
        ))}
        <Route path="*" element={<Navigate to="/campaign" />} />
      </Routes>
    </LeaderboardWrapper>
  );
}

export default CampaignsPage;
