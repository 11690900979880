/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type { SocketVault, SocketVaultInterface } from "../SocketVault";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "token_",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "AmountOutsideLimit",
    type: "error",
  },
  {
    inputs: [],
    name: "ConnectorUnavailable",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            internalType: "bool",
            name: "isLock",
            type: "bool",
          },
          {
            internalType: "address",
            name: "connector",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "maxLimit",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "ratePerSecond",
            type: "uint256",
          },
        ],
        indexed: false,
        internalType: "struct Vault.UpdateLimitParams[]",
        name: "updates",
        type: "tuple[]",
      },
    ],
    name: "LimitParamsUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferStarted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "connector",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "receiver",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "unlockedAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "pendingAmount",
        type: "uint256",
      },
    ],
    name: "PendingTokensTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "connector",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "depositor",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "receiver",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "depositAmount",
        type: "uint256",
      },
    ],
    name: "TokensDeposited",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "connector",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "receiver",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "pendingAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalPendingAmount",
        type: "uint256",
      },
    ],
    name: "TokensPending",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "connector",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "receiver",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "unlockedAmount",
        type: "uint256",
      },
    ],
    name: "TokensUnlocked",
    type: "event",
  },
  {
    inputs: [],
    name: "acceptOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "connectorPendingUnlocks",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver_",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "msgGasLimit_",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "connector_",
        type: "address",
      },
    ],
    name: "depositToAppChain",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "connector_",
        type: "address",
      },
    ],
    name: "getCurrentLockLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "connector_",
        type: "address",
      },
    ],
    name: "getCurrentUnlockLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "connector_",
        type: "address",
      },
    ],
    name: "getLockLimitParams",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "lastUpdateTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "ratePerSecond",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxLimit",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lastUpdateLimit",
            type: "uint256",
          },
        ],
        internalType: "struct Gauge.LimitParams",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "connector_",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "msgGasLimit_",
        type: "uint256",
      },
    ],
    name: "getMinFees",
    outputs: [
      {
        internalType: "uint256",
        name: "totalFees",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "connector_",
        type: "address",
      },
    ],
    name: "getUnlockLimitParams",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "lastUpdateTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "ratePerSecond",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxLimit",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lastUpdateLimit",
            type: "uint256",
          },
        ],
        internalType: "struct Gauge.LimitParams",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pendingOwner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "pendingUnlocks",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "payload_",
        type: "bytes",
      },
    ],
    name: "receiveInbound",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "token__",
    outputs: [
      {
        internalType: "contract ERC20",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver_",
        type: "address",
      },
      {
        internalType: "address",
        name: "connector_",
        type: "address",
      },
    ],
    name: "unlockPendingFor",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bool",
            name: "isLock",
            type: "bool",
          },
          {
            internalType: "address",
            name: "connector",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "maxLimit",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "ratePerSecond",
            type: "uint256",
          },
        ],
        internalType: "struct Vault.UpdateLimitParams[]",
        name: "updates_",
        type: "tuple[]",
      },
    ],
    name: "updateLimitParams",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class SocketVault__factory {
  static readonly abi = _abi;
  static createInterface(): SocketVaultInterface {
    return new utils.Interface(_abi) as SocketVaultInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): SocketVault {
    return new Contract(address, _abi, signerOrProvider) as SocketVault;
  }
}
