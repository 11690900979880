/* eslint-disable no-continue */
import { useCallback, useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useShallow } from "zustand/react/shallow"
import { WSS_URL } from "../../../constants/api/wss";
import { AssetResponse } from "../../../utils/asset";
import { nanosToMillis } from "../../../utils/date";
import { jsonParse, jsonStringify } from "../../../utils/strings";
import { IWSSOrderbookRequest } from "../model/orderbook";
import { ISubscribeIDMap, IWebsocketRateLimitedResponse, IWebsocketResponse } from "../model/shared";
import { IWSSTickerRequest } from "../model/ticker";
import { getIndexChannel, getOrderbookChannel, getTickerChannel, getTradesChannel } from "./channels";
import { useIndexPriceStore } from "./store/useIndexPriceStore";
import { useOrderbookStore } from "./store/useOrderbookStore";
import { useTickersStore } from "./store/useTickersStore";
import { useTradesStore } from "./store/useTradesStore";
import useTabVisibility from "../../useTabVisibility";

/**
 * 2 different zustand states
 * 1 for tracking subscribes
 * 1 for tracking data
 * 
 * to sub to a channel, we simply call the action "subscribe" which updates zustand state,
 * which then triggers this component to do the actual websocket subscribe
 * 
 * to receive data, just retrieve from zustand data states.
 */

const flushMessagesIntervalMs = 250;

export function PublicWebsocket() {
  const isTabVisible = useTabVisibility();

  // ORDERBOOK
  const [subToOrderbookInstruments, orderbookInstrumentSubscribeCount, updateOrderbookData] = useOrderbookStore(useShallow((state) => [state.orderbookInstruments, state.orderbookInstrumentSubscribeCount, state.updateOrderbookData]))
  const subbedOrderbookInstruments = useRef<Set<string>>(new Set());

  // TRADES
  const [subToTradesInstruments, updateTradesData] = useTradesStore(useShallow((state) => [state.tradeInstruments, state.updateTrades]))
  const subbedTradesInstruments = useRef<Set<string>>(new Set());

  // INDEX PRICE
  const [subToIndexAssets, updateIndex] = useIndexPriceStore(useShallow((state) => [state.indexAssets, state.updateIndex]))
  const subbedIndexAssets = useRef<Set<AssetResponse>>(new Set());

  // TICKERS
  const [subToTickerAssetDerivatives, updateTicker] = useTickersStore(useShallow((state) => [state.assetDerivatives, state.updateTicker]))
  const subbedTickerAssetDerivatives = useRef<Set<string>>(new Set());

  const [messages, setMessages] = useState<MessageEvent<any>[] | null>(null);

  const messageEvents = useRef(new Set<MessageEvent<any>>());

  const { sendMessage, readyState } = useWebSocket(WSS_URL, {
    shouldReconnect: () => true,
    reconnectAttempts: 50,
    // Attempt to reconnect every 2 seconds
    reconnectInterval: 2000,
    retryOnError: true,
    share: false,
    onMessage: (msg: any) => {
      if (isTabVisible) {
        messageEvents.current.add(msg);
      }
    },
    // Never update lastMessage, since we're processing onMessage ourselves
    filter: () => false,
  });

  useEffect(() => {
    const flushMessages = () => {
      const msgs = Array.from(messageEvents.current.values());
      if (msgs.length) {
        messageEvents.current.clear();
        setMessages(msgs);
      }
    };
    const timer = setInterval(flushMessages, flushMessagesIntervalMs);

    return () => {
      clearInterval(timer);
      flushMessages();
    };
  }, []);


  // An incremental id will be assigned to each subscribes
  // Using ref because we never want these to trigger any side effects
  const id = useRef(0);
  const subbedIdMap = useRef<ISubscribeIDMap>({});

  // Resub whenever readyState opens
  useEffect(() => {
    if (readyState === ReadyState.OPEN && isTabVisible) {
      const subDatas = [...Object.values(subbedIdMap.current)];
      // Resub all the existing subs
      console.log("WS Reconnected! Resubbing to: ", subDatas);
      subbedIdMap.current = {};

      for (let i = 0; i < subDatas.length; i += 1) {
        const data = jsonParse(subDatas[i]);
        id.current += 1;

        // Subscribe
        const subMessage: IWSSTickerRequest = {
          op: "subscribe",
          data,
          id: id.current,
        };
        sendMessage(jsonStringify(subMessage));

        subbedIdMap.current = {
          ...subbedIdMap.current,
          [id.current]: jsonStringify(data),
        };
      }
    }
  }, [isTabVisible, readyState, sendMessage]);

  const triggerUnsubscribe = useCallback((data: string[]) => {
    if (!data.length) {
      return;
    }

    const existingId = Object.keys(subbedIdMap.current).find((key) =>
      subbedIdMap.current[Number(key)].includes(jsonStringify(data))
    );
    const idNum = Number(existingId);
    if (idNum) {
      const unsubMessage: IWSSOrderbookRequest = {
        op: "unsubscribe",
        data: jsonParse(subbedIdMap.current[idNum]),
      };
      sendMessage(jsonStringify(unsubMessage));
      delete subbedIdMap.current[idNum];
    }
  }, [sendMessage])

  const triggerSubscribe = useCallback((data: string[]) => {
    if (!data.length) {
      return;
    }

    // Check if any existing channels exists
    // If not subbed, increment id and SUB
    const exists = Object.values(subbedIdMap.current).some(
      (v) => v === jsonStringify(data)
    );
    if (!exists && readyState === ReadyState.OPEN) {
      id.current += 1;

      // Subscribe
      const subMessage: IWSSTickerRequest = {
        op: "subscribe",
        data,
        id: id.current,
      };
      sendMessage(jsonStringify(subMessage));

      subbedIdMap.current = {
        ...subbedIdMap.current,
        [id.current]: jsonStringify(data),
      };
    }
  },
    [readyState, sendMessage]
  );

  // Automatic subbed to orderbook instruments
  useEffect(() => {
    if (readyState !== ReadyState.OPEN) {
      return;
    }

    // If subToOrderbookInstruments exist, but local subbedOrderbookInstruments don't,
    const subscribeToInstruments = Array
      .from(subToOrderbookInstruments)
      .filter((i) => !subbedOrderbookInstruments.current.has(i))

    // If local subbedOrderbookInstruments exist, but subToOrderbookInstruments don't,
    const unsubscribeFromInstruments = Array
      .from(subbedOrderbookInstruments.current)
      // Only unsubscribe when count reaches 0. Refer to useOrderbookStore.ts
      .filter((i) => !subToOrderbookInstruments.has(i) && !orderbookInstrumentSubscribeCount[i])

    // Sub
    for (let i = 0; i < subscribeToInstruments.length; i += 1) {
      const instrument = subscribeToInstruments[i];
      subbedOrderbookInstruments.current.add(instrument)
      const data = [getOrderbookChannel(instrument)];
      triggerSubscribe(data);
    }

    // Unsub
    for (let i = 0; i < unsubscribeFromInstruments.length; i += 1) {
      const instrument = unsubscribeFromInstruments[i];
      subbedOrderbookInstruments.current.delete(instrument)

      const data = [getOrderbookChannel(instrument)];
      triggerUnsubscribe(data);

      updateOrderbookData(instrument, undefined)
    }
  }, [
    orderbookInstrumentSubscribeCount, 
    readyState, 
    subToOrderbookInstruments, 
    triggerSubscribe, 
    triggerUnsubscribe, 
    updateOrderbookData
  ]);

  // Automatic subbed to trade instruments
  useEffect(() => {
    if (readyState !== ReadyState.OPEN) {
      return;
    }

    // If subToTradesInstruments exist, but local subbedTradesInstruments don't,
    const subscribeToInstruments = Array
      .from(subToTradesInstruments)
      .filter((i) => !subbedTradesInstruments.current.has(i))

    // If local subbedTradesInstruments exist, but subToTradesInstruments don't,
    const unsubscribeFromInstruments = Array
      .from(subbedTradesInstruments.current)
      .filter((i) => !subToTradesInstruments.has(i))

    // Sub
    for (let i = 0; i < subscribeToInstruments.length; i += 1) {
      const instrument = subscribeToInstruments[i];
      subbedTradesInstruments.current.add(instrument)

      const data = [getTradesChannel(instrument)];
      triggerSubscribe(data);
    }

    // Unsub
    for (let i = 0; i < unsubscribeFromInstruments.length; i += 1) {
      const instrument = unsubscribeFromInstruments[i];
      subbedTradesInstruments.current.delete(instrument)

      const data = [getTradesChannel(instrument)];
      triggerUnsubscribe(data);

      updateTradesData(instrument, undefined)
    }
  }, [readyState, subToTradesInstruments, triggerSubscribe, triggerUnsubscribe, updateTradesData]);

  // Automatic subbed to index prices
  useEffect(() => {
    if (readyState !== ReadyState.OPEN) {
      return;
    }

    // If subToIndexAssets exist, but local subbedIndexAssets don't,
    const subscribeToIndexAssets = Array
      .from(subToIndexAssets)
      .filter((i) => !subbedIndexAssets.current.has(i))

    // If local subbedIndexAssets exist, but subToIndexAssets don't,
    const unsubscribeFromIndexAssets = Array
      .from(subbedIndexAssets.current)
      .filter((i) => !subToIndexAssets.has(i))

    // Sub
    for (let i = 0; i < subscribeToIndexAssets.length; i += 1) {
      const asset = subscribeToIndexAssets[i];
      subbedIndexAssets.current.add(asset)

      const data = [getIndexChannel(asset)];
      triggerSubscribe(data);
    }

    // Unsub
    for (let i = 0; i < unsubscribeFromIndexAssets.length; i += 1) {
      const asset = unsubscribeFromIndexAssets[i];
      subbedIndexAssets.current.delete(asset)

      const data = [getIndexChannel(asset)];
      triggerUnsubscribe(data);
    }
  }, [readyState, subToIndexAssets, triggerSubscribe, triggerUnsubscribe]);

  // Automatic subbed to tickers
  useEffect(() => {
    if (readyState !== ReadyState.OPEN) {
      return;
    }

    // If subToMarkTickers exist, but local subbedMarkTickers don't,
    const subscribeToTickerAssetDerivatives = Array
      .from(subToTickerAssetDerivatives)
      .filter((i) => !subbedTickerAssetDerivatives.current.has(i))

    // If local subbedTickerAssetDerivatives exist, but subToTickerAssetDerivatives don't,
    const unsubscribeFromMarkAssets = Array
      .from(subbedTickerAssetDerivatives.current)
      .filter((i) => !subToTickerAssetDerivatives.has(i))

    // Sub
    for (let i = 0; i < subscribeToTickerAssetDerivatives.length; i += 1) {
      const assetDerivative = subscribeToTickerAssetDerivatives[i];
      subbedTickerAssetDerivatives.current.add(assetDerivative)

      const data = [getTickerChannel(assetDerivative)];
      triggerSubscribe(data);
    }

    // Unsub
    for (let i = 0; i < unsubscribeFromMarkAssets.length; i += 1) {
      const assetDerivative = unsubscribeFromMarkAssets[i];
      subbedTickerAssetDerivatives.current.delete(assetDerivative)

      const data = [getTickerChannel(assetDerivative)];
      triggerUnsubscribe(data);
    }
  }, [readyState, subToTickerAssetDerivatives, triggerSubscribe, triggerUnsubscribe]);

  // If any messages is a rate limit exceeded, retry after x seconds
  useEffect(() => {
    if (!messages) {
      return
    }

    for (let i = 0; i < messages.length; i += 1) {
      const lastMessage = messages[i];

      const {
        data,
        error,
        id: resubId,
      }: IWebsocketRateLimitedResponse = jsonParse(lastMessage.data);
      if (error === "RATE_LIMIT_EXCEEDED" && resubId) {
        const subMsg = subbedIdMap.current[resubId];
        // If existing sub id exists
        if (subMsg) {
          const retryMs = nanosToMillis(data.retry_after);

          // If failed, we delete subbed id map
          delete subbedIdMap.current[resubId];

          // And then resub again after a duration
          setTimeout(() => {
            console.log("RETRYING", subMsg);
            triggerSubscribe(jsonParse(subMsg));
          }, retryMs);
        }
      }
    }
  }, [messages, triggerSubscribe]);

  // Handle messages
  useEffect(() => {
    if (!messages) {
      return
    }

    for (let idx = 0; idx < messages.length; idx += 1) {
      const lastMessage = messages[idx];

      const { data, channel }: IWebsocketResponse = jsonParse(
        lastMessage.data
      );

        // Handle OB
        const instrMatchingObChannel = Array
          .from(subbedOrderbookInstruments.current)
          .find((i) => getOrderbookChannel(i) === channel)

        if (data && instrMatchingObChannel) {
          updateOrderbookData(instrMatchingObChannel, data)
          continue
        }

        // Handle Trades
        const instrMatchingTradesChannel = Array
          .from(subbedTradesInstruments.current)
          .find((i) => getTradesChannel(i) === channel)

        if (data && instrMatchingTradesChannel) {
          updateTradesData(instrMatchingTradesChannel, data)
          continue
        }

        // Handle Index
        const assetMatchingIndexChannel = Array
          .from(subbedIndexAssets.current)
          .find((i) => getIndexChannel(i) === channel)
        if (data && assetMatchingIndexChannel) {
          updateIndex(assetMatchingIndexChannel, data)
          continue
        }

          // Handle Ticker
          const tickerMatchingTickerChannel = Array
          .from(subbedTickerAssetDerivatives.current)
          .find((i) => getTickerChannel(i) === channel)
        if (data && tickerMatchingTickerChannel) {
          updateTicker(data)
          continue
        }
      console.log("UNHANDLED DATA:", data, channel)
    }
  }, [messages, updateIndex, updateOrderbookData, updateTradesData, updateTicker, isTabVisible]);


  return null
}