import { TFunction } from "react-i18next";
import { ITableColumn } from "../../../interfaces/Table/TableColumn";
import { formatSizePrecision } from "../../../utils/format";
import { Align } from "../../shared/Table/style";
import {
  IPartialTPSLModalTableContentProps,
  PartialTPSLTabEnum,
  TPSLEnum,
} from "./PartialTPSLModalContent.types";
import {
  GetAccount200ResponsePositionsInner,
  GetOrders200Response,
  SideResponse,
} from "../../../codegen-api";
import { COLORS } from "../../../constants/design/colors";
import RowActionButton from "../../shared/RowActionButton";
import { SpinnerContainerWrapper } from "../../shared/Spinner/style";
import { Spinner } from "../../shared/Spinner";

export const getColumns = (
  tPositions: TFunction<"app", "Positions">,
  tTooltip: TFunction<"tooltips">,
  onCancelOrder: (order: any) => void,
  loadingOrderId: string,
  positionTotalAmount: number,
  side: SideResponse
) => {
  const columns: ITableColumn<IPartialTPSLModalTableContentProps>[] = [
    {
      title: tPositions("side"),
      accessor: "side",
      tooltip: tTooltip("side"),
      Cell: () => (
        <Align
          align="left"
          style={{
            color:
              side === SideResponse.Buy
                ? COLORS.negative.one
                : COLORS.positive.one,
          }}
        >
          {side === SideResponse.Buy ? SideResponse.Sell : SideResponse.Buy}
        </Align>
      ),
    },
    {
      title: tPositions("direction"),
      accessor: "stop",
      tooltip: tTooltip("side"),
      Cell: ({ value }: any) => (
        <Align
          align="left"
          style={{
            color:
              value === TPSLEnum.STOP_LOSS
                ? COLORS.negative.one
                : COLORS.positive.one,
          }}
        >
          {value === TPSLEnum.STOP_LOSS
            ? tPositions("stop_loss")
            : tPositions("take_profit")}
        </Align>
      ),
    },
    {
      title: tPositions("order_type"),
      accessor: "order_type",
      tooltip: tTooltip("orderType"),
    },
    {
      title: tPositions("amount"),
      accessor: "amount",
      align: "left",
      tooltip: tTooltip("partialPositionAmount"),
      Cell: ({ value, row }: any) => {
        const percentage = (value / positionTotalAmount) * 100;
        return (
          <Align align="left">
            {formatSizePrecision(value, row.original.amountPrecision)} /{" "}
            {formatSizePrecision(
              positionTotalAmount,
              row.original.amountPrecision
            )}{" "}
            ({percentage.toFixed(2)}%)
          </Align>
        );
      },
    },
    {
      title: tPositions("trigger_price"),
      accessor: "trigger",
      align: "right",
      tooltip: tTooltip("stopTriggerPrice"),
      Cell: ({ value }: any) => (
        <Align align="right">{Number(value).toFixed(2)}</Align>
      ),
    },
    {
      id: "cancelOrders",
      Header: "",
      Cell: ({ row }: any) =>
        loadingOrderId === row.original.order_id ? (
          <SpinnerContainerWrapper>
            <Spinner />
          </SpinnerContainerWrapper>
        ) : (
          <RowActionButton
            variant={"close"}
            onClick={(e) => {
              e.stopPropagation();
              onCancelOrder(row.original as GetOrders200Response);
            }}
          />
        ),
    },
  ];

  return columns;
};

export const getTabTitle = (
  tab: PartialTPSLTabEnum,
  translation: TFunction<"app", "Positions">
) => {
  switch (tab) {
    case PartialTPSLTabEnum.All:
      return translation("all");
    case PartialTPSLTabEnum.TakeProfit:
      return translation("take_profit");
    case PartialTPSLTabEnum.StopLoss:
      return translation("stop_loss");
    default:
      return "";
  }
};

/**
 * Helper function to extract partial positions data from a given position.
 *
 * @param {GetAccount200ResponsePositionsInner | undefined} position - The position from which to extract partial positions data. If undefined, default values are returned.
 * @returns {Object} An object containing arrays of all, stop loss, and take profit partial positions. If the position is undefined, all arrays are empty.
 *
 * @property {Array} All - An array containing all partial positions (both stop loss and take profit).
 * @property {Array} StopLoss - An array containing stop loss partial positions.
 * @property {Array} TakeProfit - An array containing take profit partial positions.
 */
export const partialPositionsDataHelpers = (
  position: GetAccount200ResponsePositionsInner | undefined
) => {
  if (!position) {
    // Return default values when position is undefined or null
    return {
      All: [],
      StopLoss: [],
      TakeProfit: [],
    };
  }

  return {
    All: [
      ...(position?.partialPositionTriggers?.stop_loss ?? []),
      ...(position?.partialPositionTriggers?.take_profit ?? []),
    ],
    StopLoss: position?.partialPositionTriggers?.stop_loss ?? [],
    TakeProfit: position?.partialPositionTriggers?.take_profit ?? [],
  };
};
