import { useContext } from "react";
import useSWR from "swr";
import { GetAccountAccumulatedFundings200Response } from "../../../codegen-api";
import { AuthContext } from "../../../contexts/AuthContext";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { authApi } from "../../../services/api/apiFetcher";
import { pollingInterval } from "../../../services/api/pollingInterval";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";

// Get the current account's positions' accumulated fundings
export const useGetAccumulatedFundings = () => {
  // Get API Key
  const { apiKey, apiSecret } = useContext(AuthContext);
  const fetcher = authApi(apiKey, apiSecret);

  const swr = useSWR<GetAccountAccumulatedFundings200Response, Error>(
    // If apiKey is not available, the request will not fetch
    apiKey ? [APIEndpointEnum.ACCUMULATED_FUNDINGS, apiKey] : undefined,
    async () => {
      const accumulatedFundingData = (
        await (
          await fetcher.getAccountAccumulatedFundings()
        )()
      ).data;
      return {
        ...accumulatedFundingData,
      };
    },
    {
      use: [useLoggerMiddleware],
      refreshInterval: pollingInterval[APIEndpointEnum.ACCUMULATED_FUNDINGS],
    }
  );

  return {
    ...swr,
  };
};
