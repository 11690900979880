import { useCallback, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { authApi } from "../../../services/api/apiFetcher";

export const useSignUpForCampaign = () => {
  const { apiKey, apiSecret } = useContext(AuthContext);
  const fetcher = useMemo(
    () => authApi(apiKey, apiSecret),
    [apiKey, apiSecret]
  );

  const signUpForCampaign = useCallback(
    async (campaign_name: string) => {
      const { data } = await (
        await fetcher.postCampaignSignUp({
          campaign_name,
        })
      )();

      return data;
    },
    [fetcher]
  );

  return {
    signUpForCampaign,
  };
};
