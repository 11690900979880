import { motion } from "framer-motion";
import styled from "styled-components";
import { TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s ease-in-out;
`;

export const PendingVideo = styled.video`
  width: 180%;
  height: 280px;
`;

export const ViewTransactionLabel = styled.a`
  padding: ${SPACING.four}px;
  width: 100%;
  font-size: ${FONT_SIZE.two};
  opacity: 0.48;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${TEXT_COLORS.one};
  text-decoration: none;
  cursor: pointer;

  svg {
    margin-left: ${SPACING.one}px;
  }

  &:hover {
    color: ${TEXT_COLORS.one};
  }
`;
