import { motion } from "framer-motion";
import styled from "styled-components";
import { BACKGROUND_COLORS } from "../../constants/design/colors";

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${BACKGROUND_COLORS.five};
  z-index: 999;
`;
