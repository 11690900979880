import { IDetectedBarcode, Scanner } from "@yudiel/react-qr-scanner";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "../../../../assets/svg/close.svg";
import { LINKS } from "../../../../constants/links";
import { pwaURLPrefix } from "../../../../hooks/usePWAFlow";
import { BaseModal } from "../../../BaseModal";
import { Button, ButtonThemeEnum } from "../../../Buttons/styles";
import {
  CenteredContainer,
  QRCodeScannerContainer,
  ScannerFrame,
} from "./style";

interface IQRCodeScannerProps {
  onHide?: () => void;
  show?: boolean;
  onReceiveURL: (url: string) => void;
}

function QRCodeScannerModal({
  onHide,
  show,
  onReceiveURL,
}: IQRCodeScannerProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "Header.PWAFlowModal.MobilePWAModal.QRCodeScannerModal",
  });
  const [invalidQR, setInvalidQR] = useState(false);

  const handleScan = useCallback(
    async (scanData?: IDetectedBarcode[]) => {
      const data = scanData?.[0]?.rawValue;
      if (data) {
        // ONLY ALLOW if the URL matches the PWA URL
        const mustMatchURL = `${LINKS.app}${pwaURLPrefix}`;
        if (data.startsWith(mustMatchURL)) {
          // Do something
          setInvalidQR(false);
          onReceiveURL(data);
        } else {
          setInvalidQR(true);
        }
      }
    },
    [onReceiveURL]
  );

  return (
    <BaseModal
      isFullscreen
      hideCloseButton
      noContentPadding
      onHide={onHide}
      show={show}
    >
      <QRCodeScannerContainer>
        <div>
          <span>{t("scan_qr_code")}</span>
          <span>{t("scan_qr_code_desc", { url: LINKS.app })}</span>
        </div>
        <CenteredContainer>
          <ScannerFrame>
            <Scanner
              formats={["qr_code", "rm_qr_code", "micro_qr_code"]}
              onScan={handleScan}
              paused={!show}
              components={{
                audio: false,
                finder: false,
              }}
            />
          </ScannerFrame>
          <p>{invalidQR ? t("invalid_qr") : ""}</p>
        </CenteredContainer>
        <Button buttonTheme={ButtonThemeEnum.NEUTRAL1} onClick={onHide}>
          <Close />
        </Button>
      </QRCodeScannerContainer>
    </BaseModal>
  );
}

export default QRCodeScannerModal;
