import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../constants/design/colors";
import { AuthContext } from "../../contexts/AuthContext";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { BaseModal } from "../BaseModal";
import { LiquidationWrapper } from "./style";

function LiquidationModal() {
  const hasViewedRef = useRef<boolean>(false);
  const { account } = useContext(AuthContext);
  const { data: accountData } = useGetAccount();
  const [hasViewedModal, setHasViewed] = useState<boolean>(false);
  const { t } = useTranslation("app", { keyPrefix: "LiquidationModal" });

  useEffect(() => {
    if (hasViewedModal) {
      hasViewedRef.current = true;
    }
  }, [accountData, hasViewedModal]);

  if (!account || !accountData?.in_liquidation) return null;

  return (
    <BaseModal
      show={!hasViewedRef.current}
      title={t("title")}
      titleStyle={{ color: COLORS.negative.one }}
      style={{ width: 311 }}
      onHide={() => setHasViewed(true)}
    >
      <LiquidationWrapper>
        <p>
          <span>{t("important")}:</span> {t("liquidation_desc_1")}:
        </p>
        <ul>
          <li>{t("liquidation_bullet_1")}</li>
          <li>{t("liquidation_bullet_2")}</li>
          <li>{t("liquidation_bullet_3")}</li>
          <li>
            {t("liquidation_bullet_4_1")}{" "}
            <span>{t("liquidation_bullet_4_2")}</span>{" "}
            {t("liquidation_bullet_4_3")}
          </li>
        </ul>
        <p>{t("liquidation_desc_2")}</p>
      </LiquidationWrapper>
    </BaseModal>
  );
}

export default LiquidationModal;
