/* eslint-disable no-unreachable */
import { Web3Provider } from "@ethersproject/providers";
import { AxiosError } from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Suspense, lazy, useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { WagmiProvider } from "wagmi";
import "react-toastify/dist/ReactToastify.css";
import { SWRConfig } from "swr";
import { config } from "./utils/chain";
import { AppContainer } from "./App.style";
import CommandModal from "./components/CommandModal";
import InvalidReferralModal from "./components/InvalidReferralModal";
import LiquidationModal from "./components/LiquidationModal";
import LoadingScreen from "./components/LoadingScreen";
import MMPTriggeredModal from "./components/MMPTriggeredModal";
import { Toast } from "./components/shared/Toast";
import { AuthContext, AuthContextProvider } from "./contexts/AuthContext";
import { ConnectWalletContextProvider } from "./contexts/ConnectWalletContext";
import {
  IntercomContext,
  IntercomContextProvider,
} from "./contexts/IntercomContext";
import { MarketContextProvider } from "./contexts/MarketContext";
import { NotificationContextProvider } from "./contexts/NotificationContext";
import { SettingsContextProvider } from "./contexts/SettingsContext";
import { Web3ContextProvider } from "./contexts/Web3Context";
import { useAnalytics } from "./hooks/api/analytics/useAnalytics";
import useScreenSize from "./hooks/screenSize/useScreenSize";
import { PublicWebsocket } from "./hooks/wss/public/PublicWebsocket";
import { AuthenticatedWebsocket } from "./hooks/wss/authenticated/AuthenticatedWebsocket";
import { MarkPriceWebsocket } from "./hooks/wss/public/MarkPriceWebsocket";
import AevoRoutes from "./routes";
import "./toast-overrides.css";
import { nanosToMillis } from "./utils/date";
import { Announcements } from "./components/shared/Announcements";

declare module "wagmi" {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Register {
    config: typeof config;
  }
}

const LazyIntercomSetupModal = lazy(
  () =>
    import(
      /* webpackChunkName: "IntercomSetupModal" */
      /* webpackPrefetch: true */
      "./components/IntercomSetupModal"
    )
);

export function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider, "any");
  library.pollingInterval = 15000;
  return library;
}

const queryClient = new QueryClient();

function Root() {
  useAnalytics();

  const { account } = useContext(AuthContext);
  const { showIntercomSetupModal } = useContext(IntercomContext);
  const { height } = useScreenSize();
  return (
    <AppContainer style={{ height }}>
      {/* WEBSOCKET INSTANCES */}
      <PublicWebsocket />
      <AuthenticatedWebsocket />
      <MarkPriceWebsocket />

      <Toast />
      <BrowserRouter>
        <Announcements />
        {account && (
          <div>
            <InvalidReferralModal />
            <LiquidationModal />
            <MMPTriggeredModal />
          </div>
        )}
        {showIntercomSetupModal && (
          <Suspense>
            <LazyIntercomSetupModal />
          </Suspense>
        )}
        <CommandModal />
        <AevoRoutes />
        <LoadingScreen autoHideAfterMs={100} />
      </BrowserRouter>
    </AppContainer>
  );
}

function App() {
  return (
    <SettingsContextProvider>
      <ConnectWalletContextProvider>
        <IntercomContextProvider>
          <NotificationContextProvider>
            <MarketContextProvider>
              <Web3ContextProvider>
                <WagmiProvider config={config}>
                  <QueryClientProvider client={queryClient}>
                    <AuthContextProvider>
                      <SWRConfig
                        value={{
                          shouldRetryOnError: true,
                          onErrorRetry: (
                            err,
                            _,
                            __,
                            revalidate,
                            { retryCount }
                          ) => {
                            const axiosErr = err as AxiosError | undefined;
                            if (axiosErr?.response?.status === 429) {
                              // Retry mechanism on rate limited
                              const retryAfterNanos: string | undefined =
                                axiosErr?.response?.data?.retry_after;
                              const retryAfterMillis = retryAfterNanos
                                ? nanosToMillis(retryAfterNanos)
                                : 1000;
                              setTimeout(
                                () => revalidate({ retryCount }),
                                retryAfterMillis
                              );
                            }
                          },
                          onError: (error) => {
                            if (error.status !== 429 && error.status !== 401) {
                              // do nothing
                            }
                          },
                        }}
                      >
                        <Root />
                      </SWRConfig>
                    </AuthContextProvider>
                  </QueryClientProvider>
                </WagmiProvider>
              </Web3ContextProvider>
            </MarketContextProvider>
          </NotificationContextProvider>
        </IntercomContextProvider>
      </ConnectWalletContextProvider>
    </SettingsContextProvider>
  );
}

export default App;
