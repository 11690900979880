import styled from "styled-components";
import { SPACING } from "../../../constants/design/spacing";
import { ICON_COLORS, TEXT_COLORS } from "../../../constants/design/colors";

import { FONT_CSS } from "../../../constants/design/fontSize";

export const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.two}px;
  ${FONT_CSS.body.five}
  color: ${TEXT_COLORS.two};
  width: 240px;
`;

export const Stat = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StatTitle = styled.div`
  color: ${TEXT_COLORS.three};
`;

export const StatNumber = styled.div`
  display: flex;
  gap: ${SPACING.one}px;
  color: ${TEXT_COLORS.one};
  align-items: center;
  justify-content: center;
`;

export const TooltipWrapper = styled.div<{ stroke?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  svg {
    stroke: ${({ stroke }) => stroke ?? ICON_COLORS.three};
  }
`;
