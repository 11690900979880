import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ConnectWalletContext } from "../../contexts/ConnectWalletContext";

export function EarnRedirectPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setYieldVaultToggle, setShowConnectModal } =
    useContext(ConnectWalletContext);

  useEffect(() => {
    navigate("/portfolio");
    setYieldVaultToggle(true);
    setShowConnectModal(true);
  }, [location.pathname, navigate, setShowConnectModal, setYieldVaultToggle]);

  return <div />;
}
