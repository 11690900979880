import { create } from "zustand";
import { GetInstrumentInstrumentNameTradeHistory200ResponseTradeHistoryInner } from "../../../../codegen-api";
import { mountZustandDevtool } from "../../../../utils/zustand";

interface IInstrumentToTrades {
  [instrument: string]: GetInstrumentInstrumentNameTradeHistory200ResponseTradeHistoryInner[];
}

interface IPublicWebsocketState {
  tradeInstruments: Set<string>;
  trades: IInstrumentToTrades;
  
  // Mutations
  addTradeInstruments: (instrument: string) => void;
  removeTradeInstruments: (instrument: string) => void;
  updateTrades: (instrument: string, data?: GetInstrumentInstrumentNameTradeHistory200ResponseTradeHistoryInner) => void;
}

const MAX_TRADES_PER_INSTRUMENT = 100;

export const useTradesStore = create<IPublicWebsocketState>()((setState) => ({
  tradeInstruments: new Set(),
  addTradeInstruments: (instrument: string) => setState((state) => {
    if (!state.tradeInstruments.has(instrument)) {
      // Update if not already included
      const newTradeSet = new Set([
        ...Array.from(state.tradeInstruments),
        instrument
      ])
      return {
        tradeInstruments: newTradeSet
      }
    }
    return state
  }),
  removeTradeInstruments: (instrument: string) => setState((state) => {
    if (state.tradeInstruments.has(instrument)) {
      // Update if not already included
      const newTradeSet = new Set([
        ...Array.from(state.tradeInstruments),
      ].filter((i) => i !== instrument))

      return {
        tradeInstruments: newTradeSet
      }
    }
    return state
  }),

  // DATA
  trades: {},
  updateTrades: (instrument: string, data?: GetInstrumentInstrumentNameTradeHistory200ResponseTradeHistoryInner) => setState((state) => {
    if (!data) {
      const newTrades = {
        ...state.trades
      }
      delete newTrades[instrument]
      return {
        trades: newTrades
      }
    }
    return {
      trades: {
        ...state.trades,
        [instrument]: [
          data,
          ...(state.trades?.[data.instrument_name] || [])
        ].slice(0, MAX_TRADES_PER_INSTRUMENT)
      }
    }
  })
}));

mountZustandDevtool("useTradesStore", useTradesStore)
