// Modal that can be triggered from anywhere to show markets
import Fuse from "fuse.js";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/search.svg";
import {
  GetMarketsSummary200Response,
  InstrumentTypeResponse,
} from "../../codegen-api";
import { optionsEnabledAssets } from "../../constants/assets";
import { ICON_COLORS, LAYER_COLORS } from "../../constants/design/colors";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { MarketContext } from "../../contexts/MarketContext";
import { PageEndpointEnum } from "../../enums/endpoint";
import { useGetMarketSummary } from "../../hooks/api/marketSummary/useGetMarketSummary";
import useScreenSize from "../../hooks/screenSize/useScreenSize";
import usePersistentState from "../../hooks/usePersistentState";
import useWatchlist from "../../hooks/useWatchlist";
import { AssetResponse } from "../../utils/asset";
import { getContractPriceStepPrecision } from "../../utils/instruments";
import { BaseModal } from "../BaseModal";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import { ClearButton } from "../MarketSelectionButton/style";
import { Input } from "../shared/Input";
import { Select } from "../shared/Select";
import CommandFooter from "./CommandFooter";
import MarketsTable, {
  IMarketData,
  IOptionMarketData,
  IPerpetualMarketData,
} from "./MarketsTable";
import {
  CommandContent,
  CommandWrapper,
  HeaderContainer,
  InputContainer,
  MarketFilterContainer,
  ModalContentWrapper,
  Section,
  Title,
} from "./style";
import { useGetMarkets } from "../../contexts/MarketInstrumentContext/useGetMarkets";

enum MarketFiltersEnum {
  ALL_MARKETS = "all_markets",
  WATCHLIST = "watchlist",
  PRELAUNCH = "prelaunch",
  PERPS = "perps",
  OPTIONS = "options",
}

type ISectionData = {
  name: string;
  instrumentType: InstrumentTypeResponse;
  isPrelaunch: boolean;
} & (
  | {
      instrumentType: typeof InstrumentTypeResponse.Option;
      data: IOptionMarketData[];
    }
  | {
      instrumentType: typeof InstrumentTypeResponse.Perpetual;
      data: IPerpetualMarketData[];
    }
);

const sortWatchlist = (a: IMarketData, b: IMarketData) => {
  if (a.isWatchlist && !b.isWatchlist) {
    return -1;
  }
  if (!a.isWatchlist && b.isWatchlist) {
    return 1;
  }
  return 0;
};

// millis
const swrUpdateMillis = 5000;

function CommandModal() {
  const { t } = useTranslation("app", { keyPrefix: "CommandModal" });
  const navigate = useNavigate();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { isMobileScreen } = useScreenSize();
  const { market, setMarket, showCommandModal, setShowCommandModal } =
    useContext(MarketContext);
  const { watchlist, updateWatchlist } = useWatchlist();
  const { preferredMarketFilter: marketFilter, setPreferredMarketFilter } =
    usePersistentState();

  const { data: marketsSummary, mutate: mutateMarketSummary } =
    useGetMarketSummary();

  // TEMPFIX: fallback use unique assets from /markets
  const { data: marketsData } = useGetMarkets(
    undefined,
    InstrumentTypeResponse.Perpetual
  );

  const uniqueAssets = useMemo(
    () =>
      Array.from(
        new Set(marketsData?.map((s) => s.underlying_asset) || [])
      ).sort(),
    [marketsData]
  );

  // Fallback when markets summary is slow
  const marketsSummaryData = useMemo(() => {
    if (!marketsSummary) {
      const fallbackSummaries: GetMarketsSummary200Response = {
        summaries: uniqueAssets.map((a) => {
          const mkt = marketsData?.find((d) => a === d.underlying_asset);
          return {
            asset: a,
            index_price: mkt?.index_price || "",
            index_daily_change: "",
            option_info: {
              mark_price: mkt?.mark_price || "",
              mark_daily_change: "",
              price_step: mkt?.price_step || "",
              amount_step: mkt?.amount_step || "",
            },
            funding_rate: "",
            perpetual_info: {
              mark_price: mkt?.mark_price || "",
              mark_daily_change: "",
              price_step: mkt?.price_step || "",
              amount_step: mkt?.amount_step || "",
              mark_price_24h_ago: "",
              funding_rate: "0",
            },
          };
        }),
      };
      return fallbackSummaries;
    }
    return marketsSummary;
  }, [marketsData, marketsSummary, uniqueAssets]);

  const [marketsSummaryLastUpdatedMillis, setMarketsSummaryLastUpdatedMillis] =
    useState(Date.now());

  const [searchText, setSearchText] = useState("");
  const [highlightedMarketIndex, setHighlightedMarketIndex] = useState<{
    sectionIndex: number;
    dataIndex: number;
  }>({
    sectionIndex: 0,
    dataIndex: 0,
  });

  const searchResults = useMemo(() => {
    if (searchText) {
      const fuse = new Fuse(marketsSummaryData?.summaries || [], {
        keys: ["asset"],
        shouldSort: true,
        threshold: 0.5,
      });
      const search = fuse.search(searchText);
      return search.map((v) => v.item);
    }
    return marketsSummaryData?.summaries || [];
  }, [marketsSummaryData?.summaries, searchText]);

  const sectionsData: ISectionData[] = useMemo(() => {
    const optionsData: IOptionMarketData[] = [...searchResults]
      .filter((d) => !!optionsEnabledAssets[d.asset])
      .map((d) => {
        const putOI = Number(d.option_info?.open_interest?.puts);
        const callOI = Number(d.option_info?.open_interest?.calls);
        const putCallRatio = putOI && callOI ? putOI / callOI : 0;

        const openInterestUSD = d.option_info?.open_interest
          ? Number(d.index_price) * Number(d.option_info.open_interest.total)
          : undefined;

        return {
          id: `OPTION-${d.asset}`,
          asset: d.asset,
          putCallRatio,
          oi: openInterestUSD || 0,
          dailyVolume: Number(d.option_info?.daily_volume || 0),
          isWatchlist: watchlist.some(
            (w) =>
              w.asset === d.asset &&
              w.derivative === InstrumentTypeResponse.Option
          ),
          isCurrentMarket:
            market.asset === d.asset &&
            market.derivative === InstrumentTypeResponse.Option,
          pricePrecision: d.option_info
            ? getContractPriceStepPrecision(
                d.option_info?.amount_step,
                d.option_info?.price_step
              ).price_precision
            : undefined,
          indexHistory: [],
        };
      })
      .filter((d) =>
        marketFilter === MarketFiltersEnum.WATCHLIST ? d.isWatchlist : true
      )
      .sort(sortWatchlist);

    const allResults = [...searchResults]
      .map((d) => {
        const change = Number(d.perpetual_info?.mark_daily_change || 0);
        const price24hAgo = Number(d.perpetual_info?.mark_price_24h_ago || 0);
        const dailyChange = price24hAgo ? change / price24hAgo : 0;

        return {
          id: `PERPETUAL-${d.asset}`,
          asset: d.asset,
          market: `${d.asset}-USD`,
          price: d.perpetual_info?.mark_price || "0",
          dailyChange,
          fundingRate: Number(d.perpetual_info?.funding_rate || 0),
          dailyVolume: Number(d.perpetual_info?.daily_volume || 0),
          isWatchlist: watchlist.some(
            (w) =>
              w.asset === d.asset &&
              w.derivative === InstrumentTypeResponse.Perpetual
          ),
          isPrelaunch: !!d.perpetual_info?.pre_launch,
          isCurrentMarket:
            market.asset === d.asset &&
            market.derivative === InstrumentTypeResponse.Perpetual,
          pricePrecision: d.perpetual_info
            ? getContractPriceStepPrecision(
                d.perpetual_info?.amount_step,
                d.perpetual_info?.price_step
              ).price_precision
            : undefined,
        };
      })
      .filter((d) =>
        marketFilter === MarketFiltersEnum.WATCHLIST ? d.isWatchlist : true
      )
      .sort(sortWatchlist);
    const prelaunch = allResults.filter((d) => d.isPrelaunch);
    const perps = allResults.filter((d) => !d.isPrelaunch);

    const sections: ISectionData[] = [];

    if (
      prelaunch.length &&
      marketFilter !== MarketFiltersEnum.OPTIONS &&
      marketFilter !== MarketFiltersEnum.PERPS
    ) {
      sections.push({
        name: t("prelaunch"), // "Pre-Launch Tokens",
        instrumentType: InstrumentTypeResponse.Perpetual,
        isPrelaunch: true,
        data: prelaunch,
      });
    }

    if (
      perps.length &&
      marketFilter !== MarketFiltersEnum.OPTIONS &&
      marketFilter !== MarketFiltersEnum.PRELAUNCH
    ) {
      sections.push({
        name: t("perps"), // "Perpetual Futures",
        instrumentType: InstrumentTypeResponse.Perpetual,
        isPrelaunch: false,
        data: perps,
      });
    }

    if (
      optionsData.length &&
      marketFilter !== MarketFiltersEnum.PRELAUNCH &&
      marketFilter !== MarketFiltersEnum.PERPS
    ) {
      sections.push({
        name: t("options"), // "Options",
        instrumentType: InstrumentTypeResponse.Option,
        isPrelaunch: false,
        data: optionsData,
      });
    }
    return sections;
  }, [
    market.asset,
    market.derivative,
    marketFilter,
    searchResults,
    t,
    watchlist,
  ]);

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      // Handle CMD+K
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setShowCommandModal((o) => !o);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [setShowCommandModal]);

  const setMarketFilter = useCallback(
    (filter: MarketFiltersEnum) => {
      setPreferredMarketFilter(filter);
    },
    [setPreferredMarketFilter]
  );

  const onSearchChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setSearchText(value);
  }, []);

  const onRowClick = useCallback(
    (
      asset: AssetResponse,
      derivative: InstrumentTypeResponse,
      event?: React.MouseEvent<HTMLTableRowElement>
    ) => {
      if (event) {
        event.preventDefault();
      }
      setShowCommandModal(false);
      setMarket({
        asset,
        derivative,
      });
      navigate(PageEndpointEnum.TRADING);
    },
    [navigate, setMarket, setShowCommandModal]
  );

  // Handle navigation while the modal is open
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (showCommandModal && (e.key === "ArrowUp" || e.key === "ArrowDown")) {
        if (!highlightedMarketIndex) {
          setHighlightedMarketIndex({
            sectionIndex: 0,
            dataIndex: 0,
          });
          return;
        }

        const [sectionIndex, dataIndex] = [
          highlightedMarketIndex.sectionIndex,
          highlightedMarketIndex.dataIndex,
        ];

        // Handle UP, Decrement data
        if (e.key === "ArrowUp" && showCommandModal) {
          e.preventDefault();
          const canPrevDataIndex = dataIndex - 1 >= 0;
          const canPrevSectionIndex = sectionIndex - 1 >= 0;

          if (canPrevDataIndex) {
            setHighlightedMarketIndex({
              sectionIndex,
              dataIndex: dataIndex - 1,
            });
          } else if (!canPrevDataIndex && canPrevSectionIndex) {
            setHighlightedMarketIndex({
              sectionIndex: sectionIndex - 1,
              dataIndex: sectionsData[sectionIndex - 1].data.length - 1,
            });
          }
        } else if (e.key === "ArrowDown" && showCommandModal) {
          e.preventDefault();
          // Handle DOWN, Increment data
          const canNextDataIndex =
            dataIndex + 1 < sectionsData[sectionIndex].data.length;
          const canNextSectionIndex = sectionIndex + 1 < sectionsData.length;

          if (canNextDataIndex) {
            setHighlightedMarketIndex({
              sectionIndex,
              dataIndex: dataIndex + 1,
            });
          } else if (!canNextDataIndex && canNextSectionIndex) {
            setHighlightedMarketIndex({
              sectionIndex: sectionIndex + 1,
              dataIndex: 0,
            });
          }
        }
      }

      // Handle enter
      if (showCommandModal && e.key === "Enter" && highlightedMarketIndex) {
        const [sectionIndex, dataIndex] = [
          highlightedMarketIndex.sectionIndex,
          highlightedMarketIndex.dataIndex,
        ];
        const { data, instrumentType } = sectionsData[sectionIndex];
        const { asset } = data[dataIndex];
        onRowClick(asset, instrumentType);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [highlightedMarketIndex, onRowClick, showCommandModal, sectionsData]);

  // Reset modal states when closed
  useEffect(() => {
    if (!showCommandModal) {
      setSearchText("");
      setHighlightedMarketIndex({
        sectionIndex: 0,
        dataIndex: 0,
      });
    }
  }, [showCommandModal]);

  // Reload data when modal is opened, and data is stale
  // This prevents spamming open/close modal from hitting the apis repeatedly
  useEffect(() => {
    if (showCommandModal) {
      const now = Date.now();
      const nextUpdateTime = marketsSummaryLastUpdatedMillis + swrUpdateMillis;
      if (now > nextUpdateTime) {
        mutateMarketSummary();
        setMarketsSummaryLastUpdatedMillis(now);
      }
    }
  }, [marketsSummaryLastUpdatedMillis, mutateMarketSummary, showCommandModal]);

  const onWatchlistClick = useCallback(
    (asset: AssetResponse, derivative: InstrumentTypeResponse) => {
      const exist = watchlist.some(
        (w) => w.asset === asset && w.derivative === derivative
      );
      if (exist) {
        const newWatchlist = [...watchlist].filter(
          (w) => w.asset !== asset || w.derivative !== derivative
        );
        updateWatchlist(newWatchlist);
      } else {
        updateWatchlist([...watchlist, { asset, derivative }]);
      }
    },
    [updateWatchlist, watchlist]
  );

  const modalContent = useMemo(
    () => (
      <ModalContentWrapper isMobile={isMobileScreen}>
        <HeaderContainer>
          <InputContainer>
            <Input
              autoFocus={!isMobileScreen}
              type="text"
              placeholder={t("search_markets")}
              wrapperStyles={{
                backgroundColor: LAYER_COLORS.two,
                padding: `0 ${SPACING.three}px`,
              }}
              inputStyles={{
                fontSize: FONT_SIZE.two,
              }}
              leftAccessory={<SearchIcon stroke={ICON_COLORS.three} />}
              rightAccessory={
                searchText ? (
                  <ClearButton onClick={() => setSearchText("")}>
                    <CloseIcon />
                  </ClearButton>
                ) : undefined
              }
              value={searchText}
              onChange={onSearchChange}
            />
            <Button
              buttonTheme={ButtonThemeEnum.NEUTRAL2}
              onClick={() => setShowCommandModal(false)}
            >
              <CloseIcon />
            </Button>
          </InputContainer>
          <MarketFilterContainer>
            <Select
              isRound
              buttonSpacing={isMobileScreen ? SPACING.two : SPACING.three}
              options={Object.keys(MarketFiltersEnum).map((k) => {
                const f =
                  MarketFiltersEnum[k as keyof typeof MarketFiltersEnum];
                return {
                  label: t(f),
                  isActive: marketFilter === f,
                  onClick: () => setMarketFilter(f),
                };
              })}
            />
          </MarketFilterContainer>
        </HeaderContainer>
        <CommandWrapper>
          <CommandContent
            style={{
              paddingBottom: isMobileScreen ? 0 : COMPONENTS.cmdKModalFooter,
            }}
          >
            {sectionsData.map((s, sIndex) => (
              <Section key={s.name}>
                <Title>{s.name}</Title>
                <MarketsTable
                  isMobile={isMobileScreen}
                  type={s.instrumentType as any}
                  marketsData={s.data as any}
                  highlightedIndex={
                    highlightedMarketIndex?.sectionIndex === sIndex
                      ? highlightedMarketIndex?.dataIndex
                      : undefined
                  }
                  onRowClick={(row: IMarketData) =>
                    onRowClick(row.asset, s.instrumentType)
                  }
                  onWatchlist={(row: IMarketData) =>
                    onWatchlistClick(row.asset, s.instrumentType)
                  }
                  ref={tooltipRef}
                />
              </Section>
            ))}
            {/* NO RESULTS */}
            {!sectionsData.length && (
              <Section>
                <Title noResults>{t("no_search_results")}</Title>
              </Section>
            )}
          </CommandContent>
        </CommandWrapper>

        {!isMobileScreen && <CommandFooter />}
      </ModalContentWrapper>
    ),
    [
      highlightedMarketIndex,
      isMobileScreen,
      marketFilter,
      onRowClick,
      onSearchChange,
      onWatchlistClick,
      searchText,
      sectionsData,
      setMarketFilter,
      t,
      setShowCommandModal,
    ]
  );

  const style = isMobileScreen
    ? {
        width: `calc(100% - ${SPACING.three * 2}px)`,
      }
    : {
        width: 720,
      };

  return (
    <BaseModal
      hideCloseButton
      noContentPadding
      show={showCommandModal}
      onHide={() => setShowCommandModal(false)}
      excludeRefs={[tooltipRef]}
      style={style}
    >
      {modalContent}
    </BaseModal>
  );
}

export default CommandModal;
