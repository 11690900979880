import { useTranslation } from "react-i18next";
import { Container } from "./style";

const MAINTENANCE_TIMESTAMP = process.env.REACT_APP_MAINTENANCE_TIMESTAMP_SECONDS;

function MobileMaintenanceInProgress() {
  const { t } = useTranslation("app", { keyPrefix: "Header.MobileMaintenanceInProgress" });
  const maintenanceInProgress = Number(MAINTENANCE_TIMESTAMP)
    ? Number(MAINTENANCE_TIMESTAMP) * 1000 <= Date.now()
    : false;

  return maintenanceInProgress
    ? (
      <Container>
        <div />
        {t("maintenance_desc")}
      </Container>
    )
    : null;
}

export default MobileMaintenanceInProgress;
