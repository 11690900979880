import { SortContainer } from "./styles";
import { ReactComponent as SortArrow } from "../../../assets/svg/sort-triangle.svg";

interface ISortIconProps {
  isSortedDesc?: boolean;
  isSorted?: boolean;
}

function SortIcon(props: ISortIconProps) {
  return (
    <SortContainer {...props}>
      <SortArrow />
      <SortArrow />
    </SortContainer>
  );
}

export default SortIcon;
