import styled, { css } from "styled-components";
import { BORDER_COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentContainer = styled.div`
  position: relative;
  flex: 1;
`;

export const ChartContainer = styled.div<{ isMobileScreen?: boolean }>`
  ${({ isMobileScreen }) =>
    isMobileScreen
      ? css`
          height: ${COMPONENTS.mobileCustomChart}px;
        `
      : css`
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-width: 1px 0px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.06);
  border-color: ${BORDER_COLORS.one};
  height: 40px;
  padding: 0 ${SPACING.two}px;
`;

export const LabelValue = styled.div`
  ${FONT_CSS.label.one};

  span:first-child {
    color: ${TEXT_COLORS.three};
    margin-right: ${SPACING.two}px;
  }
`;

export const ZoomControls = styled.div`
  ${FONT_CSS.body.four}
  color: ${TEXT_COLORS.three};
  display: flex;
  align-items: center;
  justify-content: center;

  > button {
    ${FONT_CSS.body.four}
    color: ${TEXT_COLORS.three};
    padding: ${SPACING.two}px;
    svg {
      stroke: ${TEXT_COLORS.three};
    }
  }
`;

export const NoDataContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${FONT_CSS.label.two}
  color: ${TEXT_COLORS.three};
`;
