import { AxiosError } from "axios";
import useSWRImmutable from "swr/immutable";
import { GetYieldVault200Response } from "../../../codegen-api";
import { publicApi } from "../../../services/api/apiFetcher";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";

export const useYieldVault = () => {
  const publicFetcher = publicApi();

  const swr = useSWRImmutable<GetYieldVault200Response | undefined, AxiosError>(
    [APIEndpointEnum.YIELD_VAULT],
    async () => (await (await publicFetcher.getYieldVault())()).data,
    {
      use: [useLoggerMiddleware],
    }
  );

  return {
    ...swr,
  };
};
