import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronRight } from "../../../assets/svg/chevron-outline.svg";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import { ButtonsContainer, InputLabel } from "../style";
import { ITransferData } from "./model";
import { AddressConfirmationContainer, BackButton } from "./style";

interface IInitialStateProps {
  defaultTransferData: ITransferData;
  handleNext: (data: ITransferData) => void;
  handleBack: () => void;
}

function ConfirmAddressModal({
  defaultTransferData,
  handleNext,
  handleBack,
}: IInitialStateProps) {
  const { t } = useTranslation("app", { keyPrefix: "DepositWithdrawModal.TransferModal.ConfirmAddressModal" });

  return (
    <div>
      <AddressConfirmationContainer>
        {t("confirmation_desc")}
        <div>
          <InputLabel>{t("recipient_address")}</InputLabel>
          <p>{defaultTransferData.address}</p>
        </div>
      </AddressConfirmationContainer>
      <ButtonsContainer>
        <BackButton buttonTheme={ButtonThemeEnum.NEUTRAL2} onClick={handleBack}>
          <ChevronRight />
        </BackButton>
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          onClick={() => handleNext(defaultTransferData)}
          style={{ flex: 1 }}
        >
          {t("next")}
        </Button>
      </ButtonsContainer>
    </div>
  );
}

export default ConfirmAddressModal;
