import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CurrentTimeContainer } from "./style";

function CurrentTimeUTC() {
  const { t } = useTranslation("app", { keyPrefix: "UptimeMonitor.CurrentTimeUTC" });
  const [currentTime, setCurrentTime] = useState(moment().utc().format("hh:mm:ss"));

  useEffect(() => {
    // Update time every 1 second
    const int = setInterval(() => {
      setCurrentTime(moment().utc().format("HH:mm:ss"));
    }, 1000);
    return () => clearInterval(int);
  }, []);

  return (
    <CurrentTimeContainer>
      <span>{currentTime}</span>
      <span>{t("utc")}</span>
    </CurrentTimeContainer>
  );
}

export default CurrentTimeUTC;
