import { AxiosError } from "axios";
import { useCallback, useContext, useMemo } from "react";
import useSWRImmutable from "swr/immutable";
import {
  GenericErrorResponse,
  GetNotifications200Response,
  NotificationTypeResponse,
} from "../../../codegen-api";
import { AuthContext } from "../../../contexts/AuthContext";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { authApi } from "../../../services/api/apiFetcher";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";

export const supportedNotificationTypes: NotificationTypeResponse[] = [
  NotificationTypeResponse.Fill,
  NotificationTypeResponse.LiquidationStarted,
  NotificationTypeResponse.LiquidationCompleted,
  NotificationTypeResponse.DepositFinalized,
  NotificationTypeResponse.WithdrawalFinalized,
  NotificationTypeResponse.MmpUpdated,
  NotificationTypeResponse.MmpTriggered,
  NotificationTypeResponse.MmpUnfrozen,
  NotificationTypeResponse.Receive,
  NotificationTypeResponse.Settled,
  NotificationTypeResponse.EmailVerification,
  NotificationTypeResponse.SendFinalized,
  NotificationTypeResponse.FarmBoostApplied,
];

// Notification types that are considered "alerts"
export const alertNotificationTypes: NotificationTypeResponse[] = [
  NotificationTypeResponse.MmpTriggered,
  NotificationTypeResponse.LiquidationStarted,
  NotificationTypeResponse.LiquidationCompleted,
];

export const useNotifications = () => {
  const { apiKey, apiSecret } = useContext(AuthContext);
  const fetcher = useMemo(
    () => authApi(apiKey, apiSecret),
    [apiKey, apiSecret]
  );
  const HIGH_LOAD_MODE = process.env.REACT_APP_HIGH_LOAD_MODE === "true";

  const swr = useSWRImmutable<GetNotifications200Response[], AxiosError>(
    !HIGH_LOAD_MODE && apiKey
      ? [APIEndpointEnum.NOTIFICATIONS, apiKey]
      : undefined,
    async () => {
      try {
        if (!HIGH_LOAD_MODE) {
          return (await (await fetcher.getNotifications(undefined, 100))())
            .data;
        }

        return [];
      } catch (error) {
        return [];
      }
    },
    {
      use: [useLoggerMiddleware],
      revalidateOnMount: false,
    }
  );

  const markAsRead = useCallback(async () => {
    try {
      if (swr.data) {
        const updatedNotifs = swr.data.map((n) => ({
          ...n,
          is_read: true,
        }));

        swr.mutate(updatedNotifs, { revalidate: false });
      }

      await (
        await fetcher.postMarkAsRead()
      )();
    } catch (error) {
      const genericResponseAxiosError =
        error as AxiosError<GenericErrorResponse>;
      throw Error(
        genericResponseAxiosError.response?.data?.error ||
          "Error Posting Mark As Read"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher, swr.mutate, swr.data]);

  const unreadNotifications: GetNotifications200Response[] = useMemo(
    () => {
      let data: GetNotifications200Response[] = [];
      if (swr.data) data = swr.data as GetNotifications200Response[];
      return data.filter(
        (n: GetNotifications200Response) =>
          !n.is_read && supportedNotificationTypes.includes(n.notification_type)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [swr.data]
  );

  return {
    ...swr,
    markAsRead,
    unreadNotifications,
  };
};
