import styled, { css } from "styled-components";
import {
  BORDER_RADIUS,
  COMPONENTS,
  FlexWrapper,
  SPACING,
} from "../../constants/design/spacing";
import {
  BACKGROUND_COLORS,
  COLORS,
  LAYER_COLORS,
  PRIMARY_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import {
  FONT_CSS,
  FONT_SIZE,
  FONT_STYLE,
} from "../../constants/design/fontSize";
import { RewardReferralTableWrapper } from "../ReferralsTab/style";
import { ISelectProps, Select } from "../../components/shared/Select";

export const LeaderboardWrapper = styled.div<{ isMobileScreen?: boolean }>`
  margin: 0 ${SPACING.five}px;
  margin-top: ${SPACING.five}px;
  height: calc(100vh - ${COMPONENTS.header.main + COMPONENTS.footer.desktop}px);
  overflow-y: scroll;
  ${({ isMobileScreen }) =>
    isMobileScreen
      ? css`
          padding: 0px;
          margin: 0px;
        `
      : css`
          > * {
            height: fit-content;
            width: 800px;
            max-width: 100%;
            margin: auto;
          }
        `}
`;

export function OptionsSelect(props: ISelectProps) {
  return (
    <div style={{ overflow: "scroll" }}>
      <Select
        isRound
        wrapperStyle={{
          display: "flex",
          justifyContent: "space-evenly",
          height: `${COMPONENTS.chart.dateFilter}px`,
          alignItems: "center",
          // eslint-disable-next-line react/destructuring-assignment
          ...props.wrapperStyle,
        }}
        buttonSpacing={SPACING.three}
        buttonHighlightColor={TEXT_COLORS.one}
        {...props}
      />
    </div>
  );
}

export const LeaderboardHeader = styled.div<{ isMobileScreen?: boolean }>`
  > *:not(:last-child) {
    ${({ isMobileScreen }) =>
      isMobileScreen
        ? css`
            margin-bottom: ${SPACING.three}px;
          `
        : css`
            margin-bottom: ${SPACING.five}px;
          `}
  }
`;

export const VideoContainer = styled.div<{ isMobileScreen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 12px;
  overflow: hidden;
  background: ${LAYER_COLORS.four};

  height: 160px;

  video {
    opacity: 0.12;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ isMobileScreen }) =>
    isMobileScreen
      ? css`
          height: 96px;
          border-radius: 0px;
        `
      : css`
          height: 160px;
          margin-bottom: ${SPACING.five}px;
        `}
`;

export const SOLVideoContainer = styled.div<{ isMobileScreen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 12px;
  overflow: hidden;
  background: ${LAYER_COLORS.four};

  height: 160px;

  video {
    opacity: 0.12;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ isMobileScreen }) =>
    isMobileScreen
      ? css`
          border-radius: 0px;
        `
      : css``}
`;

export const LeaderboardTableWrapper = styled(RewardReferralTableWrapper)`
  overflow-y: scroll;

  tr {
    cursor: default !important;
  }

  th {
        padding: 0 ${SPACING.two}px !important;

        &:first-of-type {
            padding-left: 0 !important;
        }
    }
  }
`;

export const LeaderboardTableRow = styled.tr`
  height: ${COMPONENTS.portfolioRewardsTableRow}px !important;

  td {
    text-transform: none !important;
    padding: 0 ${SPACING.two}px !important;
    background: transparent !important;
  }

  &:hover {
    * {
      transition: 0.2s ease-in-out all !important;
    }
  }
`;

export const YourRanking = styled.div`
  color: ${TEXT_COLORS.one};
  background-color: ${PRIMARY_COLORS.one};
  border-radius: 12px;
  width: fit-content;
  padding: 0 ${SPACING.one}px;
  margin-top: ${SPACING.two}px;
  font-size: ${FONT_SIZE.xs};
`;

export const DropdownTitle = styled.div`
  padding: ${SPACING.one}px;
  font-size: ${FONT_SIZE.one};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DropdownItem = styled.div<{ isSelected?: boolean }>`
  padding: ${SPACING.two}px;
  font-size: ${FONT_SIZE.one};
  color: ${({ isSelected }) =>
    isSelected ? TEXT_COLORS.one : TEXT_COLORS.three};
  background-color: ${BACKGROUND_COLORS.four};

  &:hover {
    color: ${TEXT_COLORS.one};
  }

  white-space: nowrap;
`;

export const BackButtonWrapper = styled.button<{ isMobileScreen?: boolean }>`
  display: flex;
  gap: ${SPACING.one}px;
  align-items: center;
  color: ${TEXT_COLORS.three};
  ${FONT_CSS.body.five}
  margin-top: ${SPACING.two}px;
  margin-bottom: ${SPACING.two}px;
  ${({ isMobileScreen }) =>
    isMobileScreen && `padding-left: ${SPACING.three}px;`}
`;

export const LogoContainer = styled.div<{
  size?: number;
  borderWidth?: number;
  isLive?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: ${({ borderWidth, isLive }) => `${borderWidth || 4}px solid
    ${isLive ? COLORS.positive.one : "transparent"};`}
  width: ${({ size }) => size || 120}px;
  height: ${({ size }) => size || 120}px;
  background: ${BACKGROUND_COLORS.one};
  z-index: 2;
  > svg {
    width: ${({ size }) => size || 120}px;
    height: ${({ size }) => size || 120}px;
  }
`;

export const DescriptionContainer = styled.div<{ isMobileScreen?: boolean }>`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: -60px;
  padding: 0 ${SPACING.three}px;
`;

export const Countdown = styled.div`
  width: 40px;
  height: 48px;
  background: ${LAYER_COLORS.one};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CountdownContainer = styled.div`
  display: flex;
  gap: ${SPACING.two}px;
  align-items: center;
  height: 48px;
`;

export const Step = styled.div`
  ${FONT_STYLE.body.three}
  color: ${TEXT_COLORS.two};
  display: flex;
  gap: ${SPACING.two}px;
  height: 56px;
  border-radius: ${BORDER_RADIUS.five}px;
  background: ${LAYER_COLORS.one};
  align-items: center;
  justify-content: flex-start;
  padding: ${SPACING.two}px;
`;

export const StepBox = styled.div`
  min-width: 40px;
  min-height: 40px;
  border-radius: ${BORDER_RADIUS.five}px;
  background: ${LAYER_COLORS.two};
  display: flex;
  justify-content: center;
  align-items: center;
  ${FONT_STYLE.label.two}
  color: ${COLORS.positive.one};
  border-radius: 12px;
  border: 1px solid ${COLORS.positive.one};
  background: ${COLORS.positive.four};
`;

export const Campaign = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  border-radius: ${BORDER_RADIUS.five}px;
  background: ${LAYER_COLORS.one};
  padding: 0 ${SPACING.three}px 0 ${SPACING.two}px;

  &:hover {
    background: ${LAYER_COLORS.two};
  }
`;

export const SignedUpWrapper = styled(FlexWrapper)`
  align-items: center;
  > svg {
    stroke: ${TEXT_COLORS.one};
  }
`;

export const Textbox = styled.div`
  display: inline;
`;

export const StickyWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background: ${BACKGROUND_COLORS.one};
  padding: 0 ${SPACING.three}px;
  width: 100%;
  z-index: 2;
`;
