import { mountStoreDevtool } from "simple-zustand-devtools";
import { StoreApi, UseBoundStore } from "zustand";
import { isDevelopment, isProductionTest, isStaging } from "./env";

// Call this function with the zustand store to add it to debug mode
export function mountZustandDevtool<
  T extends object = Record<string | number | symbol, any>
>(name: string, store: UseBoundStore<StoreApi<T>>) {
  if (
    isDevelopment() ||
    isProductionTest() ||
    isStaging() ||
    process.env.NODE_ENV !== "production"
  ) {
    mountStoreDevtool(name, store);
  }
}
