import { memo } from "react";
import RowActionButton from "../shared/RowActionButton";
import { RowActionWrapper } from "./style";
import {
  GetAccount200ResponsePositionsInner,
  MarginTypeResponse,
} from "../../codegen-api";

interface IActionButtonsCellProps {
  shareColor: string;
  inLiquidation: boolean;
  position: GetAccount200ResponsePositionsInner;
  onClosePositionWithId: (instrumentId: string) => void;
  setViewDetailsForPositionId: (instrumentId: string) => void;
  setShareModalPositionId: (instrumentId: string) => void;
  setAdjustingMarginPositionId: (instrumentId: string) => void;
}

function ActionButtonsCell({
  inLiquidation,
  shareColor,
  position,
  onClosePositionWithId,
  setViewDetailsForPositionId,
  setShareModalPositionId,
  setAdjustingMarginPositionId,
}: IActionButtonsCellProps) {
  return (
    <RowActionWrapper>
      {position.margin_type === MarginTypeResponse.Isolated && (
        <RowActionButton
          variant={"edit"}
          disabled={!position.isolated_margin}
          onClick={(e) => {
            e.stopPropagation();
            setAdjustingMarginPositionId(position.instrument_id);
          }}
        />
      )}
      {!inLiquidation && (
        <RowActionButton
          variant={"close"}
          onClick={() => {
            onClosePositionWithId(position.instrument_id);
          }}
        />
      )}
      <RowActionButton
        variant={"details"}
        onClick={() => {
          setViewDetailsForPositionId(position.instrument_id);
        }}
      />
      <RowActionButton
        variant="share"
        svgColor={shareColor}
        onClick={() => {
          setShareModalPositionId(position.instrument_id);
        }}
      />
    </RowActionWrapper>
  );
}

export default memo(ActionButtonsCell);
