/* eslint-disable consistent-return */
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

const useElementSize = (
  ref: MutableRefObject<HTMLElement | null>,
  config = { mutationObserver: true }
) => {
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const [scrollWidth, setScrollWidth] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [maxScrollLeft, setMaxScrollLeft] = useState<number>(0);
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [maxScrollTop, setMaxScrollTop] = useState<number>(0);
  // horizontal overflowing only
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

  const updateScrollValues = useCallback(() => {
    const element = ref.current;
    if (!element) return;

    setHeight(element.clientHeight);
    setWidth(element.clientWidth);
    setScrollHeight(element.scrollHeight);
    setScrollWidth(element.scrollWidth);
    setScrollLeft(element.scrollLeft);
    setScrollTop(element.scrollTop);
    setMaxScrollLeft(element.scrollWidth - element.clientWidth);
    setMaxScrollTop(element.scrollHeight - element.clientHeight);
    setIsOverflowing(element.scrollWidth > element.clientWidth);
    // updates should be on ref.current not ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  useEffect(() => {
    updateScrollValues();
  }, [updateScrollValues]);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    updateScrollValues();

    const resizeObserver = new ResizeObserver(updateScrollValues);
    resizeObserver.observe(element);

    if (config.mutationObserver) {
      const mutationObserver = new MutationObserver(updateScrollValues);
      mutationObserver.observe(element, {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true,
      });

      return () => {
        mutationObserver.disconnect();
        resizeObserver.disconnect();
      };
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, updateScrollValues, config.mutationObserver]);

  useEffect(() => {
    const resizeCallback = () => {
      updateScrollValues();
    };

    window.addEventListener("resize", resizeCallback);

    return () => window.removeEventListener("resize", resizeCallback);
  }, [updateScrollValues]);

  useEffect(() => {
    const handleScroll = () => {
      updateScrollValues();
    };

    const element = ref.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
  }, [ref, updateScrollValues]);

  const onScroll = useCallback(
    (side: "left" | "right" | "top" | "bottom") => {
      if (ref.current) {
        if (side === "left" || side === "right") {
          const newScrollLeft = side === "left" ? 0 : maxScrollLeft;

          ref.current.scrollTo({
            left: newScrollLeft,
            behavior: "smooth",
          });
        } else {
          const newScrollTop = side === "top" ? 0 : maxScrollTop;

          ref.current.scrollTo({
            top: newScrollTop,
            behavior: "smooth",
          });
        }
      }

      updateScrollValues();
    },
    [maxScrollLeft, maxScrollTop, ref, updateScrollValues]
  );

  const scrollLeftDisabled = useMemo(() => scrollLeft <= 1, [scrollLeft]);
  const scrollRightDisabled = useMemo(
    () => scrollLeft >= maxScrollLeft - 1,
    [maxScrollLeft, scrollLeft]
  );
  const scrollTopDisabled = useMemo(() => scrollTop <= 1, [scrollTop]);
  const scrollBottomDisabled = useMemo(
    () => scrollTop >= maxScrollTop - 1,
    [maxScrollTop, scrollTop]
  );

  const rangeButtonsDisabled = useMemo(
    () => scrollLeft <= 1 && scrollLeft >= maxScrollLeft - 1,
    [maxScrollLeft, scrollLeft]
  );

  return {
    height,
    width,
    scrollHeight,
    scrollWidth,
    scrollLeft,
    scrollTop,
    isOverflowing,
    onScroll,
    scrollLeftDisabled,
    scrollRightDisabled,
    scrollTopDisabled,
    scrollBottomDisabled,
    rangeButtonsDisabled,
  };
};

export default useElementSize;
