import { BigNumber } from "ethers";

export const EIP2612_TYPE = [
  { name: "owner", type: "address" },
  { name: "spender", type: "address" },
  { name: "value", type: "uint256" },
  { name: "nonce", type: "uint256" },
  { name: "deadline", type: "uint256" },
];

/**
 * Validates the estGas promise, returns a default gas if error, else returns the estimated gas + 20% buffer
 * @param estGas estimated gas promise
 * @param defaultGas default gas for when estimation failed
 * @returns gas
 */
export const validateEstimatedGas = async (estGas: Promise<BigNumber>, defaultGas: BigNumber) => {
  try {
    const g = await estGas;
    // adds a 20% buffer
    const extraBuffer = Number((g.toNumber() * 0.2).toFixed(0));
    return g.add(BigNumber.from(extraBuffer));
  } catch (error) {
    // If error estimating gas, returns default gas
    return defaultGas;
  }
};
