import { motion } from "framer-motion";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation } from "react-router-dom";
import { ReactComponent as RFQsLogo } from "../../../assets/svg/orders.svg";
import { ReactComponent as CreateLogo } from "../../../assets/svg/plus.svg";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { PageEndpointEnum } from "../../../enums/endpoint";
import { IPage } from "../../shared";
import {
  ContentsWrapper,
  InnerContentsWrapper,
  MobilePageWrapper,
  MobileTabActionsWrapper,
  MobileTabLinkWrapper,
} from "../../style";
import { CreateRFQ } from "../components/CreateRFQ";
import RFQTable from "../components/RFQTable";

function MobileRFQPage() {
  const location = useLocation();
  const activePage = useMemo(
    () => location.pathname.split(`${PageEndpointEnum.RFQ}/`)[1],
    [location]
  );
  const { t } = useTranslation("app", { keyPrefix: "pages.RFQPage.RFQPage" });

  const pages = useMemo(() => {
    const initPages: IPage[] = [
      {
        title: t("rfqs"),
        path: "/",
        redirect: "/rfq",
        Logo: RFQsLogo,
        element: <RFQTable />,
        isActive: !activePage,
      },
      {
        title: t("create_rfq"),
        path: "/create",
        redirect: "create",
        Logo: CreateLogo,
        element: <CreateRFQ />,
        isActive: activePage === "create",
      },
    ];

    return initPages;
  }, [activePage, t]);

  return (
    <MobilePageWrapper>
      <MobileTabActionsWrapper>
        <div>
          {pages.map((page, i) => (
            <motion.div
              key={page.title}
              transition={{
                delay: i * 0.1,
                duration: 0.5,
                ease: "easeInOut",
              }}
              initial={{
                transform: "translateY(-8px)",
                opacity: 0,
              }}
              animate={{
                transform: "translateY(0px)",
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <MobileTabLinkWrapper
                to={page.redirect}
                $isActive={page.isActive}
              >
                {page.title}
              </MobileTabLinkWrapper>
            </motion.div>
          ))}
        </div>
      </MobileTabActionsWrapper>
      <ContentsWrapper isMobileScreen>
        <InnerContentsWrapper
          isMobileScreen
          style={{
            paddingTop: COMPONENTS.portfolioNavigation,
            paddingLeft: SPACING.three,
            paddingRight: SPACING.three,
          }}
        >
          <Routes>
            {pages.map((page) => (
              <Route key={page.path} path={page.path} element={page.element} />
            ))}
          </Routes>
        </InnerContentsWrapper>
      </ContentsWrapper>
    </MobilePageWrapper>
  );
}

export default MobileRFQPage;
