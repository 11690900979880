import { CSSProperties } from "react";
import { Bar, BarInner } from "./style";

interface IFillBarProps {
  percent: number;
  fillColor?: string;
  style?: CSSProperties;
}

function FillBar({
  fillColor,
  percent,
  style
}: IFillBarProps) {
  return (
    <Bar style={style}>
      <BarInner
        fillColor={fillColor}
        percent={percent}
      />
    </Bar>
  );
}

export default FillBar;
