import { create } from "zustand";
import { GetTradeHistory200ResponseTradeHistoryInner } from "../../../../codegen-api";
import { mountZustandDevtool } from "../../../../utils/zustand";

interface IWebsocketState {
  fills: GetTradeHistory200ResponseTradeHistoryInner[];

  // Mutations
  resetFills: () => void;
  updateFill: (data: GetTradeHistory200ResponseTradeHistoryInner) => void;
}

const MAX_FILLS = 100;

export const useFillsStore = create<IWebsocketState>()((setState) => ({
  // DATA
  fills: [],
  resetFills: () => setState(() => ({ fills: [] })),
  updateFill: (data: GetTradeHistory200ResponseTradeHistoryInner) =>
    setState((state) => ({
      fills: [data, ...state.fills].slice(0, MAX_FILLS),
    })),
}));

mountZustandDevtool("useFillsStore", useFillsStore);
