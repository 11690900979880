import { useTranslation } from "react-i18next";
import { LINKS } from "../../constants/links";
import { BaseModal } from "../BaseModal";
import { Message } from "./style";

interface IGeoblockModalProps {
  show?: boolean;
  onHide?: () => void;
}

function GeoblockModal({ show, onHide }: IGeoblockModalProps) {
  const { t } = useTranslation("app", { keyPrefix: "GeoblockModal" });
  return (
    <BaseModal show={show} onHide={onHide} title={t("title")}>
      <Message>
        {t("geoblock_desc")}{" "}
        <a href={LINKS.termsOfService} target="_blank" rel="noreferrer">
          {t("terms_of_service")}
        </a>
        .
      </Message>
    </BaseModal>
  );
}

export default GeoblockModal;
