import { useFillsStore } from "./authenticated/store/useFillsStore";

const useFillsWSS = () => {
  const fills = useFillsStore((state) => state.fills);
  return {
    trades: fills,
  };
};

export default useFillsWSS;
