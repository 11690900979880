import Chart, { ChartData, ChartOptions } from "chart.js";
import moment from "moment";
import { useCallback, useMemo, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import {
  BORDER_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_STYLE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";
import { FUNDING_RATE_PRECISION } from "../../../constants/precision/form";
import { useFunding } from "../../../hooks/api/funding/useFunding";
import { useFundingHistory } from "../../../hooks/api/funding/useFundingHistory";
import { drawCircle } from "../../../utils/canvas";
import { nanosToSeconds } from "../../../utils/date";
import { Select } from "../../shared/Select";
import {
  ChartContainer,
  Container,
  ContentContainer,
  HeaderContainer,
  LabelValue,
  NoDataContainer,
} from "../style";

interface IFundingChartProps {
  instrument: string;
}

function FundingChart({ instrument }: IFundingChartProps) {
  const fundingChartWrapperRef = useRef<HTMLDivElement>(null);
  const { t: translate } = useTranslation("app", { keyPrefix: "FundingChart" });
  const HIGH_LOAD_MODE = process.env.REACT_APP_HIGH_LOAD_MODE === "true";
  const { data: fundingRate } = useFunding(instrument);
  const { data: fd } = useFundingHistory(instrument, 50, HIGH_LOAD_MODE);

  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(
    undefined
  );
  const [fundingRateFilter, setFundingRateFilter] = useState<
    "1h" | "8h" | "annualized"
  >("1h");

  const calculateAnnualizedHourlyFunding = useCallback(
    (hourlyFunding: number) => hourlyFunding * 24 * 365,
    []
  );

  // Picking only time and funding, since funding_history returns [instrument_name, time, funding, mark]
  // this will return [time, funding]
  const fundingData = useMemo(() => {
    const d = (
      fd?.funding_history?.map((v) => [String(nanosToSeconds(v[1])), v[2]]) ||
      []
    ).reverse();
    // Add current funding rate
    if (fundingRate?.funding_rate) {
      d.push([String(Date.now() / 1000), fundingRate.funding_rate]);
    }

    if (fundingRateFilter === "8h") {
      return d.map((v) => [v[0], String(Number(v[1]) * 8)]);
    }
    if (fundingRateFilter === "annualized") {
      return d.map((v) => [
        v[0],
        String(calculateAnnualizedHourlyFunding(Number(v[1]))),
      ]);
    }
    return d;
  }, [
    calculateAnnualizedHourlyFunding,
    fd?.funding_history,
    fundingRate?.funding_rate,
    fundingRateFilter,
  ]);

  const currentFundingLabel = useMemo(() => {
    const hourlyFunding = Number(fundingRate?.funding_rate || 0);

    if (fundingRateFilter === "8h") {
      // Get last 8 hours of the funding rate
      const eightHoursFunding = hourlyFunding * 8;
      return (
        <LabelValue>
          <span>{translate("8h_funding_rate")}</span>
          <span
            style={{
              color:
                eightHoursFunding < 0
                  ? COLORS.negative.one
                  : COLORS.positive.one,
            }}
          >
            {(eightHoursFunding * 100).toFixed(FUNDING_RATE_PRECISION)}%
          </span>
        </LabelValue>
      );
    }
    if (fundingRateFilter === "annualized") {
      const annualizedFunding = calculateAnnualizedHourlyFunding(hourlyFunding);
      return (
        <LabelValue>
          <span>{translate("annualized_rate")}</span>
          <span
            style={{
              color:
                hourlyFunding < 0 ? COLORS.negative.one : COLORS.positive.one,
            }}
          >
            {(annualizedFunding * 100).toFixed(FUNDING_RATE_PRECISION)}%
          </span>
        </LabelValue>
      );
    }
    return (
      <LabelValue>
        <span>{translate("1h_funding_rate")}</span>
        <span
          style={{
            color:
              hourlyFunding < 0 ? COLORS.negative.one : COLORS.positive.one,
          }}
        >
          {(hourlyFunding * 100).toFixed(FUNDING_RATE_PRECISION)}%
        </span>
      </LabelValue>
    );
  }, [
    calculateAnnualizedHourlyFunding,
    fundingRate?.funding_rate,
    fundingRateFilter,
    translate,
  ]);

  const labelsAndDatas = useMemo(() => {
    const labels = fundingData.map((v) => v[0]);
    const data: number[] = fundingData.map((v) => Number(v[1]));
    const hoveredData = Array(data.length).fill(null);

    if (hoveredIndex || hoveredIndex === 0) {
      hoveredData[hoveredIndex] = data[hoveredIndex];
    }

    return {
      // labels is an array of TIME
      labels,
      // data is an array of FUNDING
      data,
      // hovered data is an array of TIME
      hoveredData,
    };
  }, [fundingData, hoveredIndex]);

  const ticks: {
    x: Chart.TickOptions;
    y: Chart.TickOptions;
  } = useMemo(() => {
    const minX = Number(fundingData[0]?.[0]);
    const maxX = Number(fundingData[fundingData.length - 1]?.[0]);

    const fundingRates = fundingData.map((f) => Number(f[1]));
    const minY = Math.min(...fundingRates);
    const maxY = Math.max(...fundingRates);

    return {
      y: {
        // Multiply 10% to give extra margin on top and bottom
        min: minY - Math.abs(minY * 1.1),
        max: maxY + Math.abs(maxY * 1.1),
        maxTicksLimit: 5,
        maxRotation: 0,
        fontFamily: "BaseFont",
        fontSize: 13,
        callback(value) {
          return `${(Number(value) * 100).toFixed(FUNDING_RATE_PRECISION)}%`;
        },
      },
      x: {
        min: minX,
        max: maxX,
        maxTicksLimit: 5,
        maxRotation: 0,
        fontFamily: "BaseFont",
        fontSize: 13,
        callback(value) {
          return moment.unix(Number(value)).format("DD MMM YY");
        },
      },
    };
  }, [fundingData]);

  const options = useMemo(
    (): ChartOptions => ({
      maintainAspectRatio: false,
      responsive: true,
      animation: { duration: 0 },
      legend: { display: false },
      layout: {
        padding: {
          right: SPACING.two,
        },
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        yAxes: [
          {
            display: true,
            position: "right",
            ticks: ticks.y,
            gridLines: {
              color: "transparent",
              zeroLineColor: BORDER_COLORS.two,
            },
          },
        ],
        xAxes: [
          {
            display: true,
            ticks: ticks.x,
            gridLines: {
              color: "transparent",
              zeroLineColor: BORDER_COLORS.two,
            },
          },
        ],
      },
      hover: { animationDuration: 0, intersect: false },
      onHover: (_: any, elements: any) => {
        if (elements && elements.length) {
          const { _index } = elements[0];
          setHoveredIndex(_index);
          return;
        }

        setHoveredIndex(undefined);
      },
    }),
    [ticks.x, ticks.y]
  );

  const drawPricePoint = useCallback(
    (
      chart: any,
      x: number,
      y: number,
      time: string,
      funding: number | null
    ) => {
      // eslint-disable-next-line prefer-destructuring
      const ctx: CanvasRenderingContext2D = chart.chart.ctx;

      const { top, bottom, left, right } = chart.chartArea;

      // Draw vert line
      ctx.save();
      ctx.setLineDash([5, 5]);
      ctx.lineWidth = 1;
      ctx.strokeStyle = COLORS.white.one;
      ctx.globalCompositeOperation = "source-over";

      ctx.beginPath();
      ctx.moveTo(x, top);
      ctx.lineTo(x, bottom);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(left, y);
      ctx.lineTo(right, y);
      ctx.stroke();

      ctx.restore();

      // Draw price/volume
      ctx.save();
      ctx.textBaseline = "top";

      ctx.font = `${FONT_STYLE.data.three.fontSize} BaseFont`;
      ctx.fillStyle = TEXT_COLORS.one;
      ctx.textAlign = "center";
      ctx.fillText(
        moment.unix(Number(time)).format("DD MMM YY' h:mm A"),
        x,
        bottom + 12
      );

      if (funding !== null) {
        ctx.textAlign = "left";
        ctx.fillStyle = funding < 0 ? COLORS.negative.one : COLORS.positive.one;
        ctx.fillText(
          `${funding >= 0 ? "+" : ""}${(funding * 100).toFixed(
            FUNDING_RATE_PRECISION
          )}%`,
          right + 10,
          y - 6
        );
      }
      ctx.restore();

      drawCircle(ctx, x, y, 2, COLORS.neutral.one, COLORS.neutral.one, 0);

      drawCircle(ctx, x, y, 4, COLORS.neutral.two, COLORS.neutral.two, 0);
    },
    []
  );

  const getData = useCallback(
    (canvas: any): ChartData => {
      let x;
      return {
        labels: labelsAndDatas.labels,
        datasets: [
          {
            label: "funding",
            data: labelsAndDatas.data,
            type: "line",
            pointRadius: 0,
            pointHoverRadius: 0,
            borderDash: undefined,
            borderWidth: 1,
            borderColor: COLORS.neutral.one,
            backgroundColor: "transparent",
            lineTension: 0,
            fill: "start",
          },
          {
            label: "hovered",
            data: labelsAndDatas.hoveredData,
            type: "line",
            pointRadius: 0,
            pointHoverRadius: 0,
            borderDash: undefined,
          },
        ],
      };
    },
    [labelsAndDatas]
  );

  return (
    <Container>
      <HeaderContainer>
        {currentFundingLabel}
        <Select
          isRound
          buttonSpacing={SPACING.one}
          buttonHighlightColor={TEXT_COLORS.one}
          options={[
            {
              label: translate("1h_rate"),
              isActive: fundingRateFilter === "1h",
              onClick: () => setFundingRateFilter("1h"),
            },
            {
              label: translate("8h_rate"),
              isActive: fundingRateFilter === "8h",
              onClick: () => setFundingRateFilter("8h"),
            },
            {
              label: translate("annualized"),
              isActive: fundingRateFilter === "annualized",
              onClick: () => setFundingRateFilter("annualized"),
            },
          ]}
        />
      </HeaderContainer>
      <ContentContainer>
        {!fundingData?.length ? (
          <NoDataContainer>{translate("no_data")}</NoDataContainer>
        ) : (
          <ChartContainer ref={fundingChartWrapperRef}>
            <Line
              type="line"
              data={getData}
              options={options}
              plugins={[
                {
                  afterDraw: (c: any) => {
                    // Draw lines when hovered
                    const hoveredDatasetIndex = c.chart.data.datasets.findIndex(
                      (dataset: any) => dataset.label === "hovered"
                    );
                    const hoveredDataset: (number | null)[] =
                      c.chart.data.datasets[hoveredDatasetIndex].data;

                    const [hoveredDataIndex] = hoveredDataset
                      .filter((v) => v !== null)
                      .map((v) => hoveredDataset.indexOf(v));

                    if (hoveredDataIndex !== undefined) {
                      const { labels } = c.chart.data;
                      const time = labels[hoveredDataIndex];
                      const funding = hoveredDataset[hoveredDataIndex];
                      const metadata =
                        c.chart.getDatasetMeta(hoveredDatasetIndex);
                      // eslint-disable-next-line no-underscore-dangle
                      const { x, y } = metadata.data[hoveredDataIndex]._view;
                      drawPricePoint(c, x, y, time, funding);
                    }
                  },
                },
              ]}
            />
          </ChartContainer>
        )}
      </ContentContainer>
    </Container>
  );
}

export default FundingChart;
