import styled from "styled-components";
import { BORDER_RADIUS, SPACING } from "../../../../constants/design/spacing";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  TEXT_COLORS,
} from "../../../../constants/design/colors";
import { FONT_STYLE } from "../../../../constants/design/fontSize";

export const BlockDescWrapper = styled.div`
  ${FONT_STYLE.header.seven}
  color: ${TEXT_COLORS.two};
`;

export const BlockPriceWrapper = styled.div`
  padding: ${SPACING.three}px;
  background: ${BACKGROUND_COLORS.six};
  margin: ${SPACING.three}px 0;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: ${BORDER_RADIUS.two}px;

  ${FONT_STYLE.label.one}

  > span:first-of-type {
    color: ${TEXT_COLORS.two};
  }
`;

export const PriceWrapper = styled.div`
  ${FONT_STYLE.label.one};
  margin-top: ${SPACING.two}px;
  padding-left: ${SPACING.two}px;
`;
