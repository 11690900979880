import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Container } from "./style";
import { ReactComponent as AevoLogo } from "../../assets/logo/logo_text_white.svg";

interface ILoadingScreenProps {
  autoHideAfterMs?: number;
}

function LoadingScreen({ autoHideAfterMs }: ILoadingScreenProps) {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (autoHideAfterMs) {
      const timeout = setTimeout(() => {
        setHidden(true);
      }, autoHideAfterMs);
      return () => clearTimeout(timeout);
    }
    return () => { };
  }, [autoHideAfterMs]);

  return (
    <AnimatePresence>
      {
        hidden
          ? null
          : (
            <Container
              transition={{
                duration: 0.1,
                ease: "easeInOut",
              }}
              initial={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <AevoLogo />
            </Container>
          )
      }
    </AnimatePresence>
  );
}

export default LoadingScreen;
