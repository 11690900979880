import { AxiosError } from "axios";
import useSWR from "swr";
import { GetInstrumentInstrumentNameTradeHistory200ResponseTradeHistoryInner } from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { TimePeriodEnum } from "../../../interfaces/TimePeriod";
import { publicApi } from "../../../services/api/apiFetcher";
import {
  getStartTimeSeconds,
  nanosToSeconds,
  secondsToNanos,
} from "../../../utils/date";

export const useInstrumentTradeHistory = (
  instrumentName?: string,
  period: TimePeriodEnum = TimePeriodEnum.WEEKLY
) => {
  const fetcher = publicApi();
  return useSWR<
    GetInstrumentInstrumentNameTradeHistory200ResponseTradeHistoryInner[],
    AxiosError
  >(
    instrumentName
      ? [APIEndpointEnum.INSTRUMENT_TRADES, instrumentName, period]
      : undefined,
    async () => {
      const start = secondsToNanos(getStartTimeSeconds(period));
      return (
        await (
          await fetcher.getInstrumentInstrumentNameTradeHistory(
            instrumentName!,
            start
          )
        )()
      ).data.trade_history!.sort(
        (a, b) =>
          (nanosToSeconds(b?.created_timestamp) || 0) -
          (nanosToSeconds(a?.created_timestamp) || 0)
      );
    }
  );
};
