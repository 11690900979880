import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AccountStateEnum, AuthContext } from "../../contexts/AuthContext";
import { ConnectWalletContext } from "../../contexts/ConnectWalletContext";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import {
  ReferralErrorCodesEnum,
  useCheckReferral,
} from "../../hooks/api/referrals/useCheckReferral";
import { ModalKeyEnum } from "../../portal";
import { BaseModal } from "../BaseModal";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import { InvalidContentWrapper } from "./style";

function InvalidReferralModal() {
  const { setShowConnectModal } = useContext(ConnectWalletContext);
  const { accountApiKeyState, account } = useContext(AuthContext);
  const { showInvalidRefModal, setShowInvalidRefModal } =
    useContext(AuthContext);
  const { data: checkReferralData } = useCheckReferral();
  const { t } = useTranslation("app", { keyPrefix: "InvalidReferralModal" });
  const referralCode = window.localStorage.getItem(
    LocalStorageKeyEnum.REFERRAL_CODE
  );

  useEffect(() => {
    if (!checkReferralData) {
      return;
    }
    if (!checkReferralData.is_referrable && referralCode) {
      setShowInvalidRefModal(true);
      return;
    }

    setShowConnectModal(
      Boolean(
        checkReferralData?.is_referrable &&
          account &&
          accountApiKeyState !== AccountStateEnum.OK
      )
    );
  }, [
    account,
    accountApiKeyState,
    checkReferralData,
    referralCode,
    setShowConnectModal,
    setShowInvalidRefModal,
  ]);

  const title = useMemo(() => {
    if (
      checkReferralData?.code &&
      checkReferralData?.code === ReferralErrorCodesEnum.INVALID_REFERRAL_CODE
    ) {
      return t("invalid_ref_code");
    }

    return t("referral_inapplicable");
  }, [checkReferralData?.code, t]);

  const content = useMemo(() => {
    if (
      checkReferralData?.code &&
      checkReferralData?.code === ReferralErrorCodesEnum.INVALID_REFERRAL_CODE
    ) {
      return <p>{t("invalid_ref_code_desc")}</p>;
    }

    if (
      checkReferralData?.code &&
      checkReferralData?.code ===
        ReferralErrorCodesEnum.REFERRER_SAME_AS_REFEREE
    ) {
      return <p>{t("own_ref_code_desc")}</p>;
    }

    return (
      <p>
        {t("welcome_back_desc_1")} <span>{t("welcome_back_desc_2")}</span>,{" "}
        {t("welcome_back_desc_3")} <span>{t("welcome_back_desc_4")}</span>{" "}
        {t("welcome_back_desc_5")}
      </p>
    );
  }, [checkReferralData?.code, t]);

  const onClick = useCallback(() => {
    window.localStorage.removeItem(LocalStorageKeyEnum.REFERRAL_CODE);
    setShowInvalidRefModal(false);
  }, [setShowInvalidRefModal]);

  return (
    <BaseModal
      id={ModalKeyEnum.INVALID_REF}
      show={showInvalidRefModal}
      onHide={onClick}
      style={{ width: 311 }}
      title={title}
    >
      <InvalidContentWrapper>{content}</InvalidContentWrapper>
      <Button buttonTheme={ButtonThemeEnum.NEUTRAL2} onClick={onClick}>
        {t("continue")}
      </Button>
    </BaseModal>
  );
}

export default InvalidReferralModal;
