import styled from "styled-components";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../constants/design/spacing";
import { UptimeStatusEnum } from "./model";

export const StatusContainer = styled.div`
  ${FONT_CSS.label.two}
  display: flex;
  align-items: center;
  color: ${TEXT_COLORS.three};
`;

export const StatusIndicator = styled.div<{
  maintenanceInProgress: boolean;
  status?: UptimeStatusEnum;
}>`
  width: 6px;
  height: 6px;
  border-radius: ${BORDER_RADIUS.two}px;
  background-color: ${({ maintenanceInProgress, status }) => {
    if (maintenanceInProgress) {
      return COLORS.highlight.one;
    }
    switch (status) {
      case UptimeStatusEnum.DOWN:
        return COLORS.negative.one;
      case UptimeStatusEnum.UP:
        return COLORS.positive.one;
      case UptimeStatusEnum.SEEMS_DOWN:
        return COLORS.system.one;
      default:
        return COLORS.positive.one;
    }
  }};
`;

export const CurrentTimeContainer = styled.div`
  ${FONT_CSS.data.four}
  > span:last-child {
    ${FONT_CSS.label.two}
    margin-left: ${SPACING.one}px;
    color: ${TEXT_COLORS.three};
  }
`;
