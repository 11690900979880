import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CommandIcon } from "../../assets/svg/command.svg";
import { InstrumentTypeResponse } from "../../codegen-api";
import { MarketContext } from "../../contexts/MarketContext";
import { derivativeDescription } from "../../enums/derivative";
import { getAssetLogo, getAssetName } from "../../utils/asset/assets";
import { Chevron } from "../shared/Chevron/style";
import {
  CommandKeyChevronContainer,
  HeaderText,
  MarketsDropdownWrapper,
  SubText,
} from "./style";

interface IActiveAsset {
  logo: string | undefined;
  title: string | undefined;
  isPrelaunch: boolean;
}

function MarketSelectionButton({
  isMobile,
  isTradeForm,
}: {
  isMobile?: boolean;
  isTradeForm?: boolean;
}) {
  const { market, showCommandModal, setShowCommandModal } =
    useContext(MarketContext);
  const { t } = useTranslation("app", { keyPrefix: "MarketSelectionDropdown" });

  const isMacLike = /(Mac)/i.test(navigator.userAgent);

  const activeAsset: IActiveAsset = useMemo(
    () => ({
      logo: getAssetLogo(market.asset),
      title:
        market.derivative === InstrumentTypeResponse.Perpetual
          ? `${market.asset}-USD`
          : getAssetName(market.asset),
      isPrelaunch: false,
    }),
    [market]
  );

  const toggleShowCommandModal = useCallback(() => {
    setShowCommandModal((v) => !v);
  }, [setShowCommandModal]);

  return (
    <MarketsDropdownWrapper
      isTradeForm={isTradeForm}
      onClick={toggleShowCommandModal}
    >
      <div>
        <img
          src={activeAsset?.logo}
          alt={activeAsset?.logo}
          width={32}
          height={32}
        />
        <div>
          <HeaderText>{activeAsset?.title}</HeaderText>
          <SubText>{t(derivativeDescription[market.derivative])}</SubText>
        </div>
      </div>
      <CommandKeyChevronContainer>
        {!isMobile && (
          <>
            <div>{isMacLike ? <CommandIcon /> : <span>CTRL</span>}</div>
            <div>K</div>
          </>
        )}
        {!isTradeForm && (
          <Chevron
            style={
              isMobile
                ? {
                    opacity: 1,
                  }
                : undefined
            }
            direction={showCommandModal ? "up" : "down"}
          />
        )}
      </CommandKeyChevronContainer>
    </MarketsDropdownWrapper>
  );
}

export default MarketSelectionButton;
