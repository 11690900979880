import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InstrumentTypeResponse, SideResponse } from "../../../codegen-api";
import { LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { COMPONENTS } from "../../../constants/design/spacing";
import { IPerpsMarket } from "../../../contexts/MarketInstrumentContext/useGetMarkets";
import { getContractPriceStep } from "../../../utils/instruments";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import MobileFooter from "../../MobileFooter";
import PerpsTradeForm, {
  IPerpsFormDefaultValues,
} from "../../TradeForm/PerpsTradeForm";
import MobileTradingBottomBar from "../../TradingBottomBar/MobileTradingBottomBar";
import TradingViewChart from "../../TradingViewChart";
import SegmentedControl from "../../shared/SegmentedControl";
import DepthSection from "../DepthSection";
import { MarketDetailsButton } from "../PerpsOrderbookAndChart/style";
import OrderbookSection from "../TradeOrderbookSection/OrderbookSection";
import TradeHistorySection from "../TradeOrderbookSection/TradeHistorySection";
import {
  Container,
  MobileChartTab,
  SectionContainer,
  SegmentWrapper,
} from "./styles";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import MarketDetailsModal from "../../MarketDetailsModal";
import { useGetStatistics } from "../../../hooks/api/statistics/useGetStatistics";
import { AssetResponse } from "../../../utils/asset";

interface IMobilePerpsSectionProps {
  perpInstrument?: IPerpsMarket;
  showTradeForm: boolean;
  setShowTradeForm: (show: boolean) => void;
}

enum PerpTabEnum {
  PRICE = "price",
  DEPTH = "depth",
  ORDERBOOK = "orderbook",
  HISTORY = "history",
}

function MobilePerpsSection({
  perpInstrument,
  showTradeForm,
  setShowTradeForm,
}: IMobilePerpsSectionProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "Perps.MobilePerpsSection.MobilePerpsSection",
  });

  const [selectedTab, setSelectedTab] = useState<PerpTabEnum>(
    PerpTabEnum.PRICE
  );
  const [perpDefaultValues, setPerpDefaultValues] =
    useState<IPerpsFormDefaultValues>();

  const [showMarketDetails, setShowMarketDetails] = useState(false);

  const { data: statsData } = useGetStatistics(
    perpInstrument?.underlying_asset as AssetResponse,
    InstrumentTypeResponse.Perpetual
  );

  const onClickMarketDetails = useCallback(() => {
    setShowMarketDetails(true);
  }, []);
  const onPerpOrderbookRowClick = useCallback(
    (price: string) => {
      setShowTradeForm(true);
      setPerpDefaultValues({ price });
    },
    [setShowTradeForm]
  );

  const chartControls = useMemo(
    () =>
      Object.keys(PerpTabEnum).map((k) => {
        const key = k as keyof typeof PerpTabEnum;
        return {
          value: PerpTabEnum[key],
          display: <MobileChartTab>{t(PerpTabEnum[key])}</MobileChartTab>,
          textColor:
            selectedTab === PerpTabEnum[key]
              ? TEXT_COLORS.one
              : TEXT_COLORS.three,
        };
      }),
    [selectedTab, t]
  );

  const onToggleForm = useCallback(
    (side: SideResponse) => {
      setPerpDefaultValues({ side });
      setShowTradeForm(true);
    },
    [setShowTradeForm]
  );

  return (
    <Container>
      {showTradeForm && (
        <PerpsTradeForm
          mobileMode
          defaultValues={perpDefaultValues}
          perpInstrument={perpInstrument}
          onOrderbookRowClick={onPerpOrderbookRowClick}
          showTradeForm={showTradeForm}
          setShowTradeForm={setShowTradeForm}
        />
      )}
      {perpInstrument && showMarketDetails && (
        <MarketDetailsModal
          show={showMarketDetails}
          onHide={() => setShowMarketDetails(false)}
          statsData={statsData}
          perpInstrument={perpInstrument}
        />
      )}
      <div
        style={{
          background: LAYER_COLORS.one,
          height: "auto",
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        <SegmentWrapper>
          <div style={{ display: "flex", flex: "1" }}>
            <div style={{ width: "100%" }}>
              <SegmentedControl
                segments={chartControls}
                value={selectedTab}
                onSelect={(e) => setSelectedTab(e as PerpTabEnum)}
                config={{
                  widthType: "overflow",
                  backgroundColor: LAYER_COLORS.two,
                  activeBackgroundColor: LAYER_COLORS.three,
                  borderRadius: "10px",
                  button: {
                    fontSize: FONT_SIZE.one,
                  },
                }}
              />
            </div>
          </div>
          <MarketDetailsButton
            buttonTheme={ButtonThemeEnum.NEUTRAL2}
            onClick={onClickMarketDetails}
          >
            {t("market_details")}
            <Info />
          </MarketDetailsButton>
        </SegmentWrapper>

        {perpInstrument && (
          <>
            {selectedTab === PerpTabEnum.PRICE && (
              <SectionContainer hidden={false}>
                <TradingViewChart
                  isMobileScreen
                  key={String(
                    getContractPriceStep(perpInstrument).price_precision
                  )}
                  symbol={perpInstrument.instrument_name}
                  amountDecimals={
                    getContractPriceStep(perpInstrument).amount_precision
                  }
                />
              </SectionContainer>
            )}
            {selectedTab === PerpTabEnum.DEPTH && (
              <SectionContainer hidden={false}>
                <DepthSection perpInstrument={perpInstrument} />
              </SectionContainer>
            )}
            {selectedTab === PerpTabEnum.ORDERBOOK && (
              <SectionContainer
                hidden={false}
                style={{ height: COMPONENTS.mobileTvChart }}
              >
                <OrderbookSection
                  sizeDecimals={
                    getContractPriceStep(perpInstrument).amount_precision
                  }
                  priceDecimals={
                    getContractPriceStep(perpInstrument).price_precision
                  }
                  onOrderbookRowClick={onPerpOrderbookRowClick}
                  instrumentName={perpInstrument.instrument_name}
                  showValueInUSD={false}
                  orderbookTickSize={undefined}
                  isTradeForm={showTradeForm}
                />
              </SectionContainer>
            )}
            {selectedTab === PerpTabEnum.HISTORY && (
              <SectionContainer
                style={{ height: COMPONENTS.mobileTvChart }}
                hidden={false}
              >
                <TradeHistorySection
                  instrumentName={perpInstrument.instrument_name}
                  sizeDecimals={
                    getContractPriceStep(perpInstrument).amount_precision
                  }
                  priceDecimals={
                    getContractPriceStep(perpInstrument).price_precision
                  }
                />
              </SectionContainer>
            )}
          </>
        )}
      </div>
      <MobileTradingBottomBar />
      <MobileFooter>
        <Button
          style={{ borderRadius: 8 }}
          onClick={() => onToggleForm(SideResponse.Buy)}
          buttonTheme={ButtonThemeEnum.POSITIVE}
        >
          {t("buy")}
        </Button>
        <Button
          style={{ borderRadius: 8 }}
          onClick={() => onToggleForm(SideResponse.Sell)}
          buttonTheme={ButtonThemeEnum.NEGATIVE}
        >
          {t("sell")}
        </Button>
      </MobileFooter>
    </Container>
  );
}

export default MobilePerpsSection;
