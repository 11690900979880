import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { usePositionsStore } from "./authenticated/store/usePositionsStore";

const usePositionsWSS = (instrumentName?: string) => {
  const positions = usePositionsStore(
    useShallow((state) =>
      instrumentName
        ? { [instrumentName]: state.positions[instrumentName] }
        : state.positions
    )
  );

  const response = useMemo(
    () => ({
      positionsMap: positions,
      positions: Object.values(positions || {}).sort(
        (a, b) => Number(a.instrument_id) - Number(b.instrument_id)
      ),
      loading: false,
    }),
    [positions]
  );

  return response;
};

export default usePositionsWSS;
