/* eslint-disable no-nested-ternary */
import currency from "currency.js";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FieldValues, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ConfirmationModal, {
  IConfirmationButton,
  IConfirmationModalStat,
} from "..";
import {
  GetAccount200ResponsePositionsInnerPartialPositionTriggers,
  InstrumentTypeResponse,
  SideResponse,
  Stop,
} from "../../../codegen-api";
import { BACKGROUND_COLORS, COLORS } from "../../../constants/design/colors";
import { MarketInstrumentContext } from "../../../contexts/MarketInstrumentContext";
import { FormValidatorKeysEnum } from "../../../enums/form";
import { useGetAccount } from "../../../hooks/api/account/useGetAccount";
import { useOrder } from "../../../hooks/api/order/useOrder";
import { useToast } from "../../../hooks/toast";
import { useSFX } from "../../../hooks/useSFX";
import { useMarkPriceWSS } from "../../../hooks/wss/useMarkPriceWSS";
import { roundToStepSize } from "../../../utils/format";
import {
  getAssetFromSymbol,
  getContractPriceStep,
} from "../../../utils/instruments";
import { calculateExitPriceFromRoi, calculatePnl } from "../../../utils/math";
import { ToastEnum, ToastStatusEnum } from "../../../utils/toast";
import { AccountStateEnum, AuthContext } from "../../../contexts/AuthContext";
import { ButtonThemeEnum } from "../../Buttons/styles";
import { ConnectWalletContext } from "../../../contexts/ConnectWalletContext";
import TPSLContent from "./TPSLContent";
import {
  Tpsl,
  TpslType,
  IClosePositionModal,
  OrderTypeEnum,
  ModalTypeEnum,
  NotificationTypeEnum,
  NotificationType,
} from "./TPSLContent.types";
import TPSLPartialContent from "./TPSLPartialContent";

/**
 * The modal responsible for creating and cancelling tpsl for position orders
 */
function TPSLForPositionModal({
  position,
  onHide,
  show,
  modalType,
  ...modalProps
}: IClosePositionModal) {
  const { t: apiError } = useTranslation("apiErrors");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const defaultTpslType = Tpsl.Price;

  const [tpType, setTpType] = useState<TpslType>(defaultTpslType);
  const [slType, setSlType] = useState<TpslType>(defaultTpslType);
  const [tpDropdownOpen, setTpDropdownOpen] = useState<boolean>(false);
  const [slDropdownOpen, setSlDropdownOpen] = useState<boolean>(false);

  const [partialTpType, setPartialTpType] = useState<TpslType>(defaultTpslType);
  const [partialSlType, setPartialSlType] = useState<TpslType>(defaultTpslType);
  const [partialTpDropdownOpen, setPartialTpDropdownOpen] =
    useState<boolean>(false);
  const [partialSlDropdownOpen, setPartialSlDropdownOpen] =
    useState<boolean>(false);

  const { accountSigningKeyState } = useContext(AuthContext);
  const [currentTab, setCurrentTab] = useState<NotificationType>(
    NotificationTypeEnum.TP
  );

  const mark = useMarkPriceWSS(
    position
      ? {
          instrumentName: position.instrument_name,
          asset: position.asset,
          derivative: position.option
            ? InstrumentTypeResponse.Option
            : InstrumentTypeResponse.Perpetual,
        }
      : undefined
  );
  const markPrice = mark?.mark_price || position?.mark_price;

  const { t } = useTranslation("app", {
    keyPrefix: "ConfirmationModal.TPSLForPositionModal",
  });
  const { addToast } = useToast();
  const { createTPSLOrder, createTPSLOrders } = useOrder();
  const { mutate } = useGetAccount();
  const { playSound } = useSFX();
  const { getMarketPrecision } = useContext(MarketInstrumentContext);

  const pricePrecision = getMarketPrecision(
    position?.asset,
    position?.instrument_type
  ).price_precision;

  const amountPrecision = getMarketPrecision(
    position?.asset,
    position?.instrument_type
  ).amount_precision;

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    clearErrors,
    trigger,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const tpTriggerPriceName = "tpTriggerPrice";
  const slTriggerPriceName = "slTriggerPrice";

  // partial position inputs
  const partialTPTriggerPriceName = "partialTPTriggerPrice";
  const partialSLTriggerPriceName = "partialSLTriggerPrice";
  const partialTakeProfitPositionAmount = "partialTakeProfitPositionAmount";
  const partialStopLossPositionAmount = "partialStopLossPositionAmount";

  const tpTriggerPrice = useWatch({ control, name: tpTriggerPriceName });
  const slTriggerPrice = useWatch({ control, name: slTriggerPriceName });

  const partialTPTriggerPrice = useWatch({
    control,
    name: partialTPTriggerPriceName,
  });
  const partialSLTriggerPrice = useWatch({
    control,
    name: partialSLTriggerPriceName,
  });

  // partial position component for partial positions
  const partialPositionTakeProfitAmount = useWatch<
    FieldValues,
    "partialTakeProfitPositionAmount"
  >({
    control,
    name: partialTakeProfitPositionAmount,
  });

  const partialPositionStopLossAmount = useWatch<
    FieldValues,
    "partialStopLossPositionAmount"
  >({
    control,
    name: partialStopLossPositionAmount,
  });

  // slider component for partial positions
  const positionTakeProfitSliderAmount = useWatch<
    FieldValues,
    "partialTakeProfitPositionAmount"
  >({
    control,
    name: partialTakeProfitPositionAmount,
  });

  const positionStopLossSliderAmount = useWatch<
    FieldValues,
    "partialStopLossPositionAmount"
  >({
    control,
    name: partialStopLossPositionAmount,
  });

  const triggerPriceFromRoi = useCallback(
    (roi: number) => {
      if (!position) {
        return undefined;
      }
      return calculateExitPriceFromRoi(
        Number(position?.avg_entry_price),
        roi / 100,
        Number(position?.maintenance_margin),
        position?.side!,
        Number(position?.amount)
      );
    },
    [position]
  );

  const [actualTpTriggerPrice, actualSlTriggerPrice] = useMemo(() => {
    const tpToUse =
      tpType === Tpsl.Price
        ? tpTriggerPrice
        : triggerPriceFromRoi(tpTriggerPrice);
    const slToUse =
      slType === Tpsl.Price
        ? slTriggerPrice
        : triggerPriceFromRoi(slTriggerPrice);
    return [Math.max(0, tpToUse), Math.max(0, slToUse)];
  }, [slTriggerPrice, slType, tpTriggerPrice, tpType, triggerPriceFromRoi]);

  const [actualPartialTpTriggerPrice, actualPartialSlTriggerPrice] =
    useMemo(() => {
      const tpToUse =
        partialTpType === Tpsl.Price
          ? partialTPTriggerPrice
          : triggerPriceFromRoi(partialTPTriggerPrice);
      const slToUse =
        partialSlType === Tpsl.Price
          ? partialSLTriggerPrice
          : triggerPriceFromRoi(partialSLTriggerPrice);
      return [Math.max(0, tpToUse), Math.max(0, slToUse)];
    }, [
      partialSLTriggerPrice,
      partialSlType,
      partialTPTriggerPrice,
      partialTpType,
      triggerPriceFromRoi,
    ]);

  const isEmptyValue = useCallback(
    (value: number | string) =>
      value === undefined || value === "" || Number(value) === 0,
    []
  );

  const isPartialOrderSizeExist = useMemo(() => {
    const isTakeProfitTriggerEmpty = isEmptyValue(partialTPTriggerPrice);
    const isStopLossTriggerEmpty = isEmptyValue(partialSLTriggerPrice);
    const isTakeProfitEmpty = isEmptyValue(partialPositionTakeProfitAmount);
    const isStopLossEmpty = isEmptyValue(partialPositionStopLossAmount);
    return (
      (isTakeProfitTriggerEmpty || isTakeProfitEmpty) &&
      (isStopLossTriggerEmpty || isStopLossEmpty) &&
      modalType === ModalTypeEnum.Partial
    );
  }, [
    partialPositionTakeProfitAmount,
    partialPositionStopLossAmount,
    partialTPTriggerPrice,
    partialSLTriggerPrice,
    isEmptyValue,
    modalType,
  ]);

  const { activeOptionMarkets, activePerpMarkets } = useContext(
    MarketInstrumentContext
  );
  const asset = useMemo(
    () => getAssetFromSymbol(position?.instrument_name || ""),
    [position?.instrument_name]
  );

  const instrument = useMemo(() => {
    if (asset && position) {
      const markets =
        position.instrument_type === InstrumentTypeResponse.Option
          ? activeOptionMarkets
          : activePerpMarkets;
      return markets?.find((m) => m.underlying_asset === asset);
    }
    return undefined;
  }, [activeOptionMarkets, activePerpMarkets, asset, position]);

  const contractPriceStep = useMemo(
    () => getContractPriceStep(instrument),
    [instrument]
  );

  const onHideModal = useCallback(() => {
    reset();
    onHide?.();
    setTpType(defaultTpslType);
    setSlType(defaultTpslType);
    setPartialTpType(defaultTpslType);
    setPartialSlType(defaultTpslType);
  }, [onHide, reset, defaultTpslType]);

  const onConfirmTPSLForPosition = useCallback(async () => {
    if (!position) {
      return;
    }

    try {
      setLoading(true);
      const fullClosePositionAmount = "0";

      const tpslSide =
        position.side === SideResponse.Buy
          ? SideResponse.Sell
          : SideResponse.Buy;
      await createTPSLOrders(
        position.closePositionTriggers,
        createTPSLOrder(
          position.instrument_id,
          Stop.TakeProfit,
          tpslSide,
          fullClosePositionAmount,
          OrderTypeEnum.Market,
          actualTpTriggerPrice,
          false // close position should not be partial
        ),
        createTPSLOrder(
          position.instrument_id,
          Stop.StopLoss,
          tpslSide,
          fullClosePositionAmount,
          OrderTypeEnum.Market,
          actualSlTriggerPrice,
          false // close position should not be partial
        ),
        // partial submissions
        createTPSLOrder(
          position.instrument_id,
          Stop.TakeProfit,
          tpslSide,
          partialPositionTakeProfitAmount,
          OrderTypeEnum.Market,
          actualPartialTpTriggerPrice,
          true // partial position should be partial
        ),
        createTPSLOrder(
          position.instrument_id,
          Stop.StopLoss,
          tpslSide,
          partialPositionStopLossAmount,
          OrderTypeEnum.Market,
          actualPartialSlTriggerPrice,
          true // partial position should be partial
        )
      );
      playSound("order_placed");
      mutate();
      onHideModal();
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: t("order_confirmed_header"),
          subheader: t("order_confirmed_subheader"),
          status: ToastStatusEnum.SUCCESS,
        },
        4000
      );
    } catch (err: any) {
      setErrorMessage(apiError(err.message) || "");
    } finally {
      setLoading(false);
    }
  }, [
    actualSlTriggerPrice,
    actualTpTriggerPrice,
    actualPartialTpTriggerPrice,
    actualPartialSlTriggerPrice,
    partialPositionTakeProfitAmount,
    partialPositionStopLossAmount,
    addToast,
    apiError,
    createTPSLOrder,
    createTPSLOrders,
    mutate,
    onHideModal,
    playSound,
    position,
    t,
  ]);

  const stats: IConfirmationModalStat[] = useMemo(() => {
    if (!position) {
      return [];
    }

    const items = [
      {
        title: `${t("position")} (${asset})`,
        data: position ? Number(position.amount).toFixed(2) : "---",
        side: position?.side,
      },
      {
        title: t("avg_entry_price"),
        data: currency(position.avg_entry_price || 0, {
          precision: pricePrecision,
        }).format(),
      },
      {
        title: t("mark_price"),
        data: currency(markPrice || 0, {
          precision: pricePrecision,
        }).format(),
      },
    ];

    return items;
  }, [position, t, asset, pricePrecision, markPrice]);

  const { setShowConnectModal } = useContext(ConnectWalletContext);

  const confirmationButtons: IConfirmationButton[] = useMemo(() => {
    const buttons: IConfirmationButton[] = [];
    if (accountSigningKeyState !== AccountStateEnum.OK) {
      buttons.push({
        title: t("complete_sign_in"),
        onClick: () => setShowConnectModal(true),
        theme: ButtonThemeEnum.HIGHLIGHT,
      });
    } else {
      buttons.push({
        title: t("confirm"),
        disabled:
          isPartialOrderSizeExist ||
          Boolean(errors?.tpTriggerPrice?.type) ||
          Boolean(errors?.slTriggerPrice?.type) ||
          Boolean(errors?.tpRoi?.type) ||
          Boolean(errors?.slRoi?.type) ||
          (Boolean(errors?.partialTPTriggerPrice?.type) &&
            modalType === ModalTypeEnum.Partial &&
            currentTab === NotificationTypeEnum.TP) ||
          (Boolean(errors?.partialSLTriggerPrice?.type) &&
            modalType === ModalTypeEnum.Partial &&
            currentTab === NotificationTypeEnum.SL) ||
          (Boolean(errors?.partialTakeProfitPositionAmount?.type) &&
            modalType === ModalTypeEnum.Partial &&
            currentTab === NotificationTypeEnum.TP) ||
          (Boolean(errors?.partialStopLossPositionAmount?.type) &&
            modalType === ModalTypeEnum.Partial &&
            currentTab === NotificationTypeEnum.SL),
        onClick: onConfirmTPSLForPosition,
      });
    }

    return buttons;
  }, [
    accountSigningKeyState,
    errors?.slRoi?.type,
    errors?.slTriggerPrice?.type,
    errors?.tpRoi?.type,
    errors?.tpTriggerPrice?.type,
    errors?.partialTPTriggerPrice?.type,
    errors?.partialSLTriggerPrice?.type,
    errors?.partialTakeProfitPositionAmount?.type,
    errors?.partialStopLossPositionAmount?.type,
    isPartialOrderSizeExist,
    modalType,
    currentTab,
    onConfirmTPSLForPosition,
    setShowConnectModal,
    t,
  ]);

  const triggerPriceValidateFn = useCallback(
    (isTakeProfit: boolean) => {
      // If BUY STOP LOSS or SELL TAKE PROFIT, trigger price must be higher than mark
      if (
        (position?.side === SideResponse.Buy && isTakeProfit) ||
        (position?.side === SideResponse.Sell && !isTakeProfit)
      ) {
        return {
          [FormValidatorKeysEnum.triggerPriceBelowMark]: () => true,
          [FormValidatorKeysEnum.triggerPriceAboveMark]: (v: string) =>
            !v || parseFloat(v) >= Number(markPrice || 0),
        };
      }
      // If SELL STOP LOSS or BUY TAKE PROFIT, trigger price must be lower than mark
      return {
        [FormValidatorKeysEnum.triggerPriceBelowMark]: (v: string) =>
          !v || parseFloat(v) <= Number(markPrice || 0),
        [FormValidatorKeysEnum.triggerPriceAboveMark]: () => true,
      };
    },
    [markPrice, position?.side]
  );

  // Trigger updates when inputs change
  useEffect(() => {
    clearErrors();
    trigger(tpTriggerPriceName);
    trigger(slTriggerPriceName);
    trigger(partialTPTriggerPriceName);
    trigger(partialSLTriggerPriceName);
  }, [
    trigger,
    tpTriggerPrice,
    slTriggerPrice,
    partialTPTriggerPrice,
    partialSLTriggerPrice,
    clearErrors,
  ]);

  const resetTpInput = useCallback(() => {
    setValue(tpTriggerPriceName, undefined, {
      shouldValidate: true,
    });
  }, [setValue]);

  const resetSlInput = useCallback(() => {
    setValue(slTriggerPriceName, undefined, {
      shouldValidate: true,
    });
  }, [setValue]);

  const resetPartialTpInput = useCallback(() => {
    setValue(partialTPTriggerPriceName, undefined, {
      shouldValidate: true,
    });
  }, [setValue]);

  const resetPartialSlInput = useCallback(() => {
    setValue(partialSLTriggerPriceName, undefined, {
      shouldValidate: true,
    });
  }, [setValue]);

  const calculateRemainingQuantity = useCallback(
    (
      _posAmount: number,
      _partialPositionTriggers: GetAccount200ResponsePositionsInnerPartialPositionTriggers
    ) => {
      if (
        _partialPositionTriggers?.take_profit?.length !== 0 &&
        currentTab === NotificationTypeEnum.TP
      ) {
        const sumTakeProfit = (
          _partialPositionTriggers?.take_profit ?? []
        ).reduce((acc, curr) => acc + parseFloat(curr.amount), 0);
        const remainingTakeProfit = _posAmount - sumTakeProfit;
        return remainingTakeProfit.toFixed(2);
      }

      if (
        _partialPositionTriggers?.stop_loss?.length !== 0 &&
        currentTab === NotificationTypeEnum.SL
      ) {
        const sumStopLoss = (_partialPositionTriggers?.stop_loss ?? []).reduce(
          (acc, curr) => acc + parseFloat(curr.amount),
          0
        );
        const remainingStopLoss = _posAmount - sumStopLoss;
        return remainingStopLoss.toFixed(2);
      }

      return _posAmount;
    },
    [currentTab]
  );

  // Whenever the modal is presented, reset state.
  useEffect(() => {
    if (position?.closePositionTriggers?.take_profit?.trigger) {
      setValue(
        tpTriggerPriceName,
        position?.closePositionTriggers?.take_profit.trigger
      );
    }
  }, [position?.closePositionTriggers?.take_profit?.trigger, setValue]);

  // Whenever the modal is presented, reset state.
  useEffect(() => {
    if (position?.closePositionTriggers?.stop_loss?.trigger) {
      setValue(
        slTriggerPriceName,
        position?.closePositionTriggers?.stop_loss.trigger
      );
    }
  }, [position?.closePositionTriggers?.stop_loss?.trigger, setValue]);

  useEffect(() => {
    if (slType !== Tpsl.Roi) return;

    const value = parseFloat(slTriggerPrice);
    if (!Number.isNaN(value) && value > 0) {
      // If the value is positive, set it to its negative counterpart
      setValue(slTriggerPriceName, -Math.abs(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slTriggerPrice]);
  // actualPartialSlTriggerPrice

  useEffect(() => {
    if (partialSlType !== Tpsl.Roi) return;
    const value = parseFloat(partialSLTriggerPrice);
    if (!Number.isNaN(value) && value > 0) {
      // If the value is positive, set it to its negative counterpart
      setValue(partialSLTriggerPriceName, -Math.abs(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partialSLTriggerPrice]);

  const takeProfitExpectedPnl = useMemo(() => {
    if (!position?.avg_entry_price || !actualTpTriggerPrice) {
      return undefined;
    }
    return (
      calculatePnl(
        Number(position.avg_entry_price) || 0,
        Number(actualTpTriggerPrice) || 0,
        position.side
      ) * Number(position.amount)
    );
  }, [actualTpTriggerPrice, position]);

  const stopLossExpectedPnl = useMemo(() => {
    if (!position?.avg_entry_price || !actualSlTriggerPrice) {
      return undefined;
    }
    return (
      calculatePnl(
        Number(position.avg_entry_price) || 0,
        Number(actualSlTriggerPrice) || 0,
        position.side
      ) * Number(position.amount)
    );
  }, [actualSlTriggerPrice, position]);

  const partialTakeProfitExpectedPnl = useMemo(() => {
    if (!position?.avg_entry_price || !actualPartialTpTriggerPrice) {
      return undefined;
    }
    return (
      calculatePnl(
        Number(position.avg_entry_price) || 0,
        Number(actualPartialTpTriggerPrice) || 0,
        position.side
      ) * Number(position.amount)
    );
  }, [actualPartialTpTriggerPrice, position]);

  const partialStopLossExpectedPnl = useMemo(() => {
    if (!position?.avg_entry_price || !actualPartialSlTriggerPrice) {
      return undefined;
    }
    return (
      calculatePnl(
        Number(position.avg_entry_price) || 0,
        Number(actualPartialSlTriggerPrice) || 0,
        position.side
      ) * Number(position.amount)
    );
  }, [actualPartialSlTriggerPrice, position]);

  const takeProfitRegister = register(tpTriggerPriceName, {
    disabled: loading,
    required: false,
    validate:
      tpType === Tpsl.Roi
        ? {
            [FormValidatorKeysEnum.roiTooHigh]: (v) =>
              position?.side !== SideResponse.Buy || !v || parseFloat(v) < 1000,
          }
        : {
            ...triggerPriceValidateFn(true),
            [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
              !v ||
              roundToStepSize(
                Number(v),
                contractPriceStep.price_step,
                contractPriceStep.price_precision
              ) >=
                1 / 10 ** contractPriceStep.price_precision,
          },
  });

  const stopLossRegister = register(slTriggerPriceName, {
    disabled: loading,
    required: false,
    validate:
      slType === Tpsl.Roi
        ? {
            [FormValidatorKeysEnum.roiTooLow]: (v) =>
              position?.side === SideResponse.Buy ||
              !v ||
              parseFloat(v) > -1000,
          }
        : {
            ...triggerPriceValidateFn(false),
            [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
              !v ||
              roundToStepSize(
                Number(v),
                contractPriceStep.price_step,
                contractPriceStep.price_precision
              ) >=
                1 / 10 ** contractPriceStep.price_precision,
          },
  });

  const partialTakeProfitRegister = register(partialTPTriggerPriceName, {
    disabled: loading,
    required: false,
    validate: {
      ...(partialTpType === Tpsl.Roi
        ? {
            [FormValidatorKeysEnum.roiTooHigh]: (v) =>
              position?.side !== SideResponse.Buy || !v || parseFloat(v) < 1000,
          }
        : {
            ...triggerPriceValidateFn(true),
            [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
              !v ||
              roundToStepSize(
                Number(v),
                contractPriceStep.price_step,
                contractPriceStep.price_precision
              ) >=
                1 / 10 ** contractPriceStep.price_precision,
          }),
    },
  });

  const partialStopLossRegister = register(partialSLTriggerPriceName, {
    disabled: loading,
    required: false,
    validate: {
      ...(partialSlType === Tpsl.Roi
        ? {
            [FormValidatorKeysEnum.roiTooLow]: (v) =>
              position?.side === SideResponse.Buy ||
              !v ||
              parseFloat(v) > -1000,
          }
        : {
            ...triggerPriceValidateFn(false),
            [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
              !v ||
              roundToStepSize(
                Number(v),
                contractPriceStep.price_step,
                contractPriceStep.price_precision
              ) >=
                1 / 10 ** contractPriceStep.price_precision,
          }),
    },
  });

  const partialTakeProfitPositionAmountRegister = register(
    partialTakeProfitPositionAmount,
    {
      disabled: loading,
      required: false,
      validate: {
        [FormValidatorKeysEnum.positionAmountGreaterThanPosition]: (v) =>
          !v ||
          parseFloat(v) <=
            Number(
              calculateRemainingQuantity(
                Number(position?.amount),
                position?.partialPositionTriggers ?? {}
              )
            ),
        [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
          roundToStepSize(
            v,
            contractPriceStep.amount_step,
            contractPriceStep.amount_precision
          ) >=
          1 / 10 ** contractPriceStep.price_precision,
      },
      onBlur(event) {
        setValue(
          partialTakeProfitPositionAmount,
          roundToStepSize(
            parseFloat(event.target.value),
            contractPriceStep.amount_step,
            contractPriceStep.amount_precision
          )
        );
      },
    }
  );

  const partialStopLossPositionAmountRegister = register(
    partialStopLossPositionAmount,
    {
      disabled: loading,
      required: false,
      validate: {
        [FormValidatorKeysEnum.positionAmountGreaterThanPosition]: (v) =>
          !v ||
          parseFloat(v) <=
            Number(
              calculateRemainingQuantity(
                Number(position?.amount),
                position?.partialPositionTriggers ?? {}
              )
            ),
        [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
          roundToStepSize(
            v,
            contractPriceStep.amount_step,
            contractPriceStep.amount_precision
          ) >=
          1 / 10 ** contractPriceStep.price_precision,
      },
      onBlur(event) {
        setValue(
          partialStopLossPositionAmount,
          roundToStepSize(
            parseFloat(event.target.value),
            contractPriceStep.amount_step,
            contractPriceStep.amount_precision
          )
        );
      },
    }
  );

  const onSelectTab = useCallback(
    (value: string) => setCurrentTab(value as NotificationType),
    []
  );

  const setMaxPartialTPValue = useCallback(
    (value: any) => {
      setValue(partialTakeProfitPositionAmount, value, {
        shouldValidate: false,
      });
    },
    [setValue]
  );

  const setMaxPartialSLValue = useCallback(
    (value: any) => {
      setValue(partialStopLossPositionAmount, value, {
        shouldValidate: false,
      });
    },
    [setValue]
  );

  const onChangePartialTakeProfitSliderValueHandler = useCallback(
    (value: number) => {
      setValue(partialTakeProfitPositionAmount, value);
    },
    [setValue]
  );

  const onChangePartialStopLossSliderValueHandler = useCallback(
    (value: number) => {
      setValue(partialStopLossPositionAmount, value);
    },
    [setValue]
  );

  if (!position) {
    return null;
  }

  return (
    <ConfirmationModal
      title={
        modalType === ModalTypeEnum.Close
          ? t("confirmation_modal_title")
          : t("partial_confirmation_modal_title")
      }
      primaryColor={COLORS.blue.one}
      style={{
        backgroundColor: BACKGROUND_COLORS.seven,
        width: 320,
      }}
      stats={stats}
      confirmationButtons={confirmationButtons}
      confirmationWrapperStyle={{ display: "flex" }}
      isLoading={loading}
      onHide={onHideModal}
      show={show}
      {...modalProps}
      extras={
        modalType === ModalTypeEnum.Close ? (
          <TPSLContent
            contractPriceStep={contractPriceStep.price_step}
            errorMessage={errorMessage}
            errors={errors}
            handleSubmit={handleSubmit}
            markPrice={markPrice}
            onConfirmTPSLForPosition={onConfirmTPSLForPosition}
            position={position}
            pricePrecision={pricePrecision}
            actualSlTriggerPrice={actualSlTriggerPrice}
            actualTpTriggerPrice={actualTpTriggerPrice}
            stopLossExpectedPnl={stopLossExpectedPnl || 0}
            takeProfitExpectedPnl={takeProfitExpectedPnl || 0}
            stopLossRegister={stopLossRegister}
            takeProfitRegister={takeProfitRegister}
            slType={slType}
            tpType={tpType}
            resetSlInput={resetSlInput}
            resetTpInput={resetTpInput}
            slDropdownOpen={slDropdownOpen}
            tpDropdownOpen={tpDropdownOpen}
            setTpDropdownOpen={setTpDropdownOpen}
            setTpType={setTpType}
            setSlDropdownOpen={setSlDropdownOpen}
            setSlType={setSlType}
          />
        ) : (
          // Some props of Partial TPSL are passing the differenct props compared to full TPSL
          <TPSLPartialContent
            contractPriceStep={contractPriceStep.price_step}
            minAmountSize={contractPriceStep.amount_step}
            errorMessage={errorMessage}
            errors={errors}
            handleSubmit={handleSubmit}
            markPrice={markPrice}
            onConfirmTPSLForPosition={onConfirmTPSLForPosition}
            position={position}
            pricePrecision={pricePrecision}
            amountPrecision={amountPrecision}
            positionTakeProfitSliderAmount={positionTakeProfitSliderAmount}
            positionStopLossSliderAmount={positionStopLossSliderAmount}
            partialTakeProfitPositionAmountRegister={
              partialTakeProfitPositionAmountRegister
            }
            partialStopLossPositionAmountRegister={
              partialStopLossPositionAmountRegister
            }
            onChangePartialTakeProfitSliderValue={
              onChangePartialTakeProfitSliderValueHandler
            }
            onChangePartialStopLossSliderValue={
              onChangePartialStopLossSliderValueHandler
            }
            actualSlTriggerPrice={actualPartialSlTriggerPrice}
            actualTpTriggerPrice={actualPartialTpTriggerPrice}
            stopLossExpectedPnl={partialStopLossExpectedPnl || 0}
            takeProfitExpectedPnl={partialTakeProfitExpectedPnl || 0}
            stopLossRegister={partialStopLossRegister}
            takeProfitRegister={partialTakeProfitRegister}
            slType={partialSlType}
            tpType={partialTpType}
            resetSlInput={resetPartialSlInput}
            resetTpInput={resetPartialTpInput}
            slDropdownOpen={partialSlDropdownOpen}
            tpDropdownOpen={partialTpDropdownOpen}
            setTpDropdownOpen={setPartialTpDropdownOpen}
            setTpType={setPartialTpType}
            setSlDropdownOpen={setPartialSlDropdownOpen}
            setSlType={setPartialSlType}
            setMaxPartialTPValue={setMaxPartialTPValue}
            setMaxPartialSLValue={setMaxPartialSLValue}
            currentTab={currentTab}
            onSelectTab={onSelectTab}
          />
        )
      }
      statsFirst={false}
    />
  );
}

export default TPSLForPositionModal;
