import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BaseModal } from "../../BaseModal";
import { AboutModalContainer } from "./style";

interface IAboutAevoModalProps {
  show: boolean;
  onHide: () => void;
}

function AboutAevoModal({ show, onHide }: IAboutAevoModalProps) {
  const { t } = useTranslation("app", { keyPrefix: "Header.AboutAevoModal" });

  const vcs = useMemo(
    () => [
      {
        name: t("vc:paradigm"),
        link: "https://www.paradigm.xyz/",
      },
      {
        name: t("vc:dragonfly_capital"),
        link: "https://www.dragonfly.xyz/",
      },
      {
        name: t("vc:ethereal_ventures"),
        link: "http://www.etherealventures.com/",
      },
      {
        name: t("vc:coinbase_ventures"),
        link: "https://www.coinbase.com/ventures",
      },
      {
        name: t("vc:nascent"),
        link: "https://www.nascent.xyz/",
      },
      {
        name: t("vc:robot_ventures"),
        link: "https://robvc.com/",
      },
      {
        name: t("vc:scalar_capital"),
        link: "https://scalar.capital/",
      },
      {
        name: t("vc:alliance"),
        link: "https://alliance.xyz/",
      },
    ],
    [t]
  );

  return (
    <BaseModal show={show} onHide={onHide} title="About Aevo">
      <AboutModalContainer>
        <p>{t("about1")}</p>
        <p>{t("about2")}</p>
        <p>
          {t("about3")}{" "}
          {vcs.map((vc, idx) => (
            <Fragment key={vc.name}>
              <a target="_blank" rel="noreferrer" href={vc.link}>
                {vc.name}
              </a>
              {idx !== vcs.length - 1 ? ", " : "."}
            </Fragment>
          ))}
        </p>
      </AboutModalContainer>
    </BaseModal>
  );
}

export default AboutAevoModal;
