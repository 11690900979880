import styled from "styled-components";
import { TEXT_COLORS } from "../../constants/design/colors";
import { FONT_STYLE } from "../../constants/design/fontSize";
import { SPACING } from "../../constants/design/spacing";
import { Button } from "../Buttons/styles";

export const OFACWrapper = styled.div`
  width: 311px;
  padding: ${SPACING.three}px;
  p,
  li {
    color: ${TEXT_COLORS.two};
    ${FONT_STYLE.body.three};
  }
  li {
    &:not(:last-child) {
      margin-bottom: ${SPACING.two}px;
    }
  }
  > ${Button} {
    margin-top: ${SPACING.three}px;
  }
`;
