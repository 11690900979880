import { AssetResponse } from "../../utils/asset";
import { isDevelopment, isProduction } from "../../utils/env";

// eslint-disable-next-line no-nested-ternary
export const API_URL = isProduction()
  ? "https://api.aevo.xyz"
  : isDevelopment()
  ? "http://localhost:8080"
  : "https://api-testnet.aevo.xyz";

export const API_RIBBON_URL = "https://api-ribbon.vercel.app";
// export const API_RIBBON_URL = "http://localhost:4000";

export const TRADINGVIEW_DATAFEED_LAST_TRADED_URL = `${API_URL}/last-traded-tradingview`;

export const getDatafeedURLForPerpAsset = (asset: AssetResponse) =>
  TRADINGVIEW_DATAFEED_LAST_TRADED_URL;
