import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
import currency from "currency.js";
import { ReactComponent as PlusIcon } from "../../assets/svg/plus.svg";
import { InstrumentTypeResponse } from "../../codegen-api";
import { MarketContext } from "../../contexts/MarketContext";
import { PageEndpointEnum } from "../../enums/endpoint";
import { useGetMarketSummary } from "../../hooks/api/marketSummary/useGetMarketSummary";
import usePersistentState from "../../hooks/usePersistentState";
import useWatchlist from "../../hooks/useWatchlist";
import { getContractPriceStepPrecision } from "../../utils/instruments";
import { ButtonThemeEnum } from "../Buttons/styles";
import {
  AddMarketsToWatchlistContainer,
  MarqueeContainer,
  MarqueeInnerContainer,
  Scrim,
  SmallButton,
  Ticker,
  TickerDropdownContainer,
  TickerTapeContainer,
} from "./style";
import DropdownSimple from "../shared/DropdownSimple";
import { SPACING } from "../../constants/design/spacing";

enum TickerTypeEnum {
  Watchlist = "Watchlist",
  TopGainers = "Top Gainers",
  TopLosers = "Top Losers",
  TopVolume = "Top Volume",
}

interface ITickerData {
  asset: string;
  price: number;
  pricePrecision: number;
  dailyChangePercent: number;
  dailyVolume: number;
}

const MAX_TICKERS = 20;

function FooterTickerTape() {
  const { t } = useTranslation("app", { keyPrefix: "Footer.FooterTickerTape" });
  const navigate = useNavigate();
  const { setMarket, setShowCommandModal } = useContext(MarketContext);
  const { watchlist } = useWatchlist();
  const { data: marketsSummary } = useGetMarketSummary();
  const { preferredTickerType, setPreferredTickerType } = usePersistentState();

  const marqueeContainerRef = useRef<HTMLDivElement>(null);
  const [tickersTypeDropdownOpen, setTickersTypeDropdownOpen] = useState(false);
  const [selectedTickerType, setSelectedTickerType] = useState<TickerTypeEnum>(
    preferredTickerType &&
      Object.values(TickerTypeEnum).some(
        (type) => type === (preferredTickerType as any)
      )
      ? (preferredTickerType as TickerTypeEnum)
      : watchlist.length
      ? TickerTypeEnum.Watchlist
      : TickerTypeEnum.TopGainers
  );

  // Ticker data sorted by dailyChange
  const allTickerDatas: ITickerData[] = useMemo(
    () =>
      marketsSummary?.summaries
        ?.map((s) => {
          const price24hAgo =
            Number(s.perpetual_info?.mark_price) -
            Number(s.perpetual_info?.mark_daily_change);
          const dailyChangePercent =
            Number(s.perpetual_info?.mark_daily_change || 0) / price24hAgo;
          return {
            asset: s.asset,
            dailyVolume: Number(s.perpetual_info?.daily_volume || 0),
            price: Number(s.perpetual_info?.mark_price || 0),
            pricePrecision: s.perpetual_info
              ? getContractPriceStepPrecision(
                  s.perpetual_info.amount_step,
                  s.perpetual_info.price_step
                ).price_precision
              : 2,
            dailyChangePercent,
          };
        })
        .sort((a, b) => b.dailyChangePercent - a.dailyChangePercent) || [],
    [marketsSummary?.summaries]
  );

  const tickerDatas: ITickerData[] = useMemo(() => {
    switch (selectedTickerType) {
      case TickerTypeEnum.Watchlist:
        return allTickerDatas.filter((v) =>
          watchlist.some((w) => w.asset === v.asset)
        );
      case TickerTypeEnum.TopGainers:
        // Filter negatives
        return allTickerDatas.slice(0, MAX_TICKERS);
      case TickerTypeEnum.TopLosers:
        // Filter positives
        return allTickerDatas.slice(-MAX_TICKERS);
      case TickerTypeEnum.TopVolume:
        return allTickerDatas
          .sort((a, b) => b.dailyVolume - a.dailyVolume)
          .slice(0, MAX_TICKERS);
      default:
        return [];
    }
  }, [allTickerDatas, selectedTickerType, watchlist]);

  const getTickerTypeLabel = useCallback(
    (type: TickerTypeEnum) => {
      switch (type) {
        case TickerTypeEnum.TopGainers:
          return t("top_gainers");
        case TickerTypeEnum.TopLosers:
          return t("top_losers");
        case TickerTypeEnum.TopVolume:
          return t("top_volume");
        case TickerTypeEnum.Watchlist:
          return t("watchlist");
      }
      return "";
    },
    [t]
  );
  const onTickerClick = useCallback(
    (d: ITickerData) => {
      // Redirect to trading page
      setMarket({
        asset: d.asset,
        derivative: InstrumentTypeResponse.Perpetual,
      });
      navigate(PageEndpointEnum.TRADING);
    },
    [navigate, setMarket]
  );

  return (
    <TickerTapeContainer>
      <MarqueeContainer ref={marqueeContainerRef}>
        {selectedTickerType === TickerTypeEnum.Watchlist &&
        !tickerDatas.length ? (
          <AddMarketsToWatchlistContainer
            onClick={() => setShowCommandModal(true)}
          >
            <span>{t("add_markets_to_watchlist")}</span>
            <SmallButton buttonTheme={ButtonThemeEnum.NEUTRAL2}>
              <PlusIcon />
            </SmallButton>
          </AddMarketsToWatchlistContainer>
        ) : (
          <>
            <TickerDropdownContainer>
              <DropdownSimple<TickerTypeEnum>
                toggleStyle={{
                  padding: `${SPACING.one}px ${SPACING.two}px`,
                }}
                dropDirection={"up"}
                show={tickersTypeDropdownOpen}
                onToggle={setTickersTypeDropdownOpen}
                title={getTickerTypeLabel(selectedTickerType)}
                items={Object.values(TickerTypeEnum)}
                selectedItem={selectedTickerType}
                onSelectItem={(type) => {
                  setSelectedTickerType(type);
                  setPreferredTickerType(type);
                }}
                getItemLabel={getTickerTypeLabel}
              />
            </TickerDropdownContainer>
            <MarqueeInnerContainer>
              <Marquee
                direction={"left"}
                pauseOnHover
                gradient={false}
                speed={30}
              >
                {tickerDatas?.map((m) => (
                  <Ticker
                    key={m.asset}
                    positiveChange={m.dailyChangePercent >= 0}
                    onClick={() => onTickerClick(m)}
                  >
                    <span>{m.asset}</span>
                    <span>
                      {currency(m.price, {
                        precision: m.pricePrecision,
                      }).format()}
                    </span>
                    <span>
                      {m.dailyChangePercent >= 0 ? "+" : ""}
                      {(m.dailyChangePercent * 100).toFixed(2)}%
                    </span>
                  </Ticker>
                ))}
              </Marquee>
              <Scrim direction="left" />
              <Scrim direction="right" />
            </MarqueeInnerContainer>
          </>
        )}
      </MarqueeContainer>
    </TickerTapeContainer>
  );
}

export default FooterTickerTape;
