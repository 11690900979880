import {
  BareFetcher, Key, SWRConfiguration, SWRHook,
} from "swr";
import { isDevelopment } from "../../../utils/env";

export const useLoggerMiddleware = (useSWRNext: SWRHook) => (
  key: Key,
  fetcher: BareFetcher<any> | null,
  config: SWRConfiguration<any, any, BareFetcher<any>>,
) => {
  // Handle the next middleware, or the `useSWR` hook if this is the last one.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const swr = useSWRNext(key, fetcher, config);

  // If validating or not key, ignore
  if (!swr.isValidating && key) {
    if (swr.error && isDevelopment()) {
      // eslint-disable-next-line no-console
      // console.table({
      //   type: "FETCH ERROR",
      //   baseURL: BASE_URL,
      //   key,
      // });
      // eslint-disable-next-line no-console
      // console.log(swr.error);
    } else {
      // Do nothing on success
    }
  }
  return swr;
};
