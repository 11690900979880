/* eslint-disable no-nested-ternary */
import { AnimationControls } from "framer-motion";
import { PropsWithChildren } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as Alert } from "../../../assets/svg/alert.svg";
import {
  GetAccount200ResponseFeeStructuresInner,
  GetAccount200ResponsePositionsInner,
  GetOrderbook200Response,
  GetOrders200Response,
  OrderTypeResponse,
  SideResponse,
} from "../../../codegen-api";
import { COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";
import { INDEX_PRICE_COLLAR_OPTIONS } from "../../../constants/precision/form";
import { AccountStateEnum } from "../../../contexts/AuthContext";
import { IMarket } from "../../../contexts/MarketContext";
import { IOptionMarket } from "../../../contexts/MarketInstrumentContext/useGetMarkets";
import { IPositionInfo, ITradeInfo } from "../../../interfaces/TradeInfo";
import { IContractPriceStep } from "../../../utils/instruments";
import OptionFilter from "../../OptionFilter";
import TicketDetails from "../../TicketDetails";
import { ReduceOnlyInput } from "../form";
import {
  AbsoluteWrapper,
  CheckboxesWrapper,
  CloseButton,
  DeselectReduceOnlyContainer,
  DetailsWrapper,
  FormContent,
  InfoRow,
  MarketSelectionWrapper,
  OrderbookWrapper,
  Padding,
  PositionChip,
  PositionInfoWrapper,
  Title,
  TradeForm,
  TradeFormContainer,
  TradeFormTopContent,
  TradeInfoWrapper,
  TransactionTypeWrapper,
  Value,
  OrderTypeWrapper,
  StatsWrapper,
} from "../style";
import FeeInfo from "./components/FeeInfo";
import { IOptionsFormFieldValues } from "./components/form";
import {
  GreeksWrapper,
  OrderProtectionTitle,
  OrderProtectionWrapper,
} from "./style";
import MarketSelectionButton from "../../MarketSelectionButton";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import MobileTradingBottomBar from "../../TradingBottomBar/MobileTradingBottomBar";
import { useGetOptionData } from "../../../hooks/api/statistics/useGetOptionData";
import { Greeks } from "../../TicketDetails/Greeks";
import { IWSSTickerGreeks } from "../../../hooks/wss/model/ticker";
import { IWSSIndexResponseData } from "../../../hooks/wss/model";
import OrderSideSegmentedControl from "./components/OrderSideSegmentedControl";
import SubmitContent from "./components/SubmitContent";
import Inputs from "./components/Inputs";
import OrderTypeSegmentedControl from "./components/OrderTypeSegmentedControl";

export interface IMobileComponentProps {
  showTradeForm?: boolean;
  setShowTradeForm: (show: boolean) => void;
  optionsForm: UseFormReturn<IOptionsFormFieldValues, any, undefined>;
  submitOrder: () => Promise<void>;
  market: IMarket;
  selectedInstrument?: IOptionMarket;
  orderSide: SideResponse;
  totalValue: number;
  amount: never;
  orderData?: GetOrders200Response[];
  orderbookData?: GetOrderbook200Response;
  markPrice?: string;
  greeks?: IWSSTickerGreeks;
  onRowClick: (price: string, size: string, side: SideResponse) => void;
  index?: IWSSIndexResponseData;
  setOrderSide: (orderSide: SideResponse) => void;
  orderType: OrderTypeResponse;
  setOrderType: (type: OrderTypeResponse) => void;
  reduceOnlyDisabled: boolean;
  reduceOnlyNotAllowed: boolean;
  reduceOnly: boolean;
  currentPosition?: GetAccount200ResponsePositionsInner;
  contractPriceStep: IContractPriceStep;
  verifyNotEnoughBalance: (contractSize: string) => boolean;
  verifyOrderValueTooSmall: (contractSize: string) => boolean;
  isLoading: boolean;
  maxOrderSizeBeforeOBProtectionTriggered: number;
  insufficientMarketLiquidityWarning?: string;
  setReduceOnly: (reduceOnly: boolean) => void;
  tradeInfo: ITradeInfo[];
  feeStructure?: GetAccount200ResponseFeeStructuresInner;
  positionInfo: IPositionInfo[];
  accountSigningKeyState: AccountStateEnum;
  inactiveSubmitButtonState: JSX.Element;
  animControls: AnimationControls;
}

function MobileComponent({
  showTradeForm,
  setShowTradeForm,
  optionsForm,
  submitOrder,
  market,
  selectedInstrument,
  orderSide,
  totalValue,
  amount,
  orderData,
  orderbookData,
  markPrice,
  greeks,
  onRowClick,
  index,
  setOrderSide,
  orderType,
  setOrderType,
  reduceOnlyDisabled,
  reduceOnlyNotAllowed,
  reduceOnly,
  currentPosition,
  contractPriceStep,
  verifyNotEnoughBalance,
  verifyOrderValueTooSmall,
  isLoading,
  maxOrderSizeBeforeOBProtectionTriggered,
  insufficientMarketLiquidityWarning,
  setReduceOnly,
  tradeInfo,
  feeStructure,
  positionInfo,
  accountSigningKeyState,
  inactiveSubmitButtonState,
  animControls,
}: PropsWithChildren<IMobileComponentProps>) {
  const { data: optionData } = useGetOptionData(
    selectedInstrument?.instrument_name
  );

  const { t: commonFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.Common",
  });
  const { t: optionsTradeFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.OptionsTradeForm.OptionsTradeForm",
  });

  const {
    formState: { errors },
    handleSubmit,
  } = optionsForm;

  return (
    <AbsoluteWrapper>
      <MarketSelectionWrapper>
        <MarketSelectionButton isMobile isTradeForm={showTradeForm} />
        <CloseButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowTradeForm(false);
          }}
        >
          <Close />
        </CloseButton>
      </MarketSelectionWrapper>
      <TradeFormContainer isMobileScreen>
        <TradeForm onSubmit={handleSubmit(submitOrder)} isMobileScreen>
          <TradeFormTopContent>
            <OrderbookWrapper>
              <TicketDetails
                indexPriceCollar={INDEX_PRICE_COLLAR_OPTIONS}
                asset={market.asset}
                selectedInstrument={selectedInstrument}
                orderDirection={orderSide}
                averagePremium={
                  Number.isNaN(totalValue / Number(amount))
                    ? 0
                    : totalValue / Number(amount)
                }
                totalSize={Number(amount)}
                showPlaceholder={!selectedInstrument}
                orderData={orderData}
                orderbookData={orderbookData}
                markPrice={markPrice}
                markIV={greeks?.iv}
                onOrderbookRowClick={onRowClick}
                mobileMode
                indexPrice={Number(index?.price || 0)}
              />
            </OrderbookWrapper>
            <FormContent>
              <Padding isMobileScreen>
                <TransactionTypeWrapper style={{ marginTop: 0 }}>
                  <OrderSideSegmentedControl
                    orderSide={orderSide}
                    setOrderSide={setOrderSide}
                  />
                </TransactionTypeWrapper>
                <OrderTypeWrapper isMobileScreen>
                  <OrderTypeSegmentedControl
                    orderType={orderType}
                    setOrderType={setOrderType}
                  />
                </OrderTypeWrapper>
                <OptionFilter isMobileScreen />
              </Padding>
              <DetailsWrapper isMobileScreen>
                {!reduceOnlyDisabled && reduceOnlyNotAllowed && reduceOnly && (
                  <DeselectReduceOnlyContainer
                    show={reduceOnlyNotAllowed && reduceOnly}
                  >
                    <div>{commonFormTranslations("deselect_reduce_only")}</div>
                    <div>
                      {commonFormTranslations("reduce_only_error_desc_1", {
                        orderDirection: commonFormTranslations(
                          orderSide.toLowerCase()
                        ),
                        orderType: commonFormTranslations(orderType),
                      })}
                      <strong>
                        {currentPosition
                          ? currentPosition?.side === SideResponse.Buy
                            ? commonFormTranslations(
                                "reduce_only_error_desc_2_open_position_long"
                              )
                            : commonFormTranslations(
                                "reduce_only_error_desc_2_open_position_short"
                              )
                          : commonFormTranslations(
                              "reduce_only_error_desc_2_no_position"
                            )}
                      </strong>
                      {commonFormTranslations("reduce_only_error_desc_3_and")}
                      <strong>
                        {commonFormTranslations(
                          "reduce_only_error_desc_4_reduce_only"
                        )}
                      </strong>
                      {commonFormTranslations("reduce_only_error_desc_5")}
                    </div>
                  </DeselectReduceOnlyContainer>
                )}
                <Inputs
                  orderType={orderType}
                  amount={amount}
                  reduceOnlyDisabled={reduceOnlyDisabled}
                  reduceOnly={reduceOnly}
                  isLoading={isLoading}
                  maxOrderSizeBeforeOBProtectionTriggered={
                    maxOrderSizeBeforeOBProtectionTriggered
                  }
                  insufficientMarketLiquidityWarning={
                    insufficientMarketLiquidityWarning
                  }
                  errors={errors}
                  contractPriceStep={contractPriceStep}
                  optionsForm={optionsForm}
                  verifyNotEnoughBalance={verifyNotEnoughBalance}
                  verifyOrderValueTooSmall={verifyOrderValueTooSmall}
                  currentPosition={currentPosition}
                  isMobileScreen
                />
                {!reduceOnlyDisabled && (
                  <CheckboxesWrapper isMobileScreen>
                    <ReduceOnlyInput
                      isOn={reduceOnly}
                      onToggle={() => {
                        setReduceOnly(!reduceOnly);
                      }}
                    />
                  </CheckboxesWrapper>
                )}
              </DetailsWrapper>
              <StatsWrapper isMobileScreen>
                {Number(amount) > maxOrderSizeBeforeOBProtectionTriggered ? (
                  <OrderProtectionWrapper>
                    <div>
                      <OrderProtectionTitle>
                        {optionsTradeFormTranslations(
                          "order_protection_triggered"
                        )}
                        <Alert />
                      </OrderProtectionTitle>
                    </div>
                    <p>
                      {/* Protection has been triggered because this order will fill{" "}
                        {orderSide === SideResponse.Buy ? "an offer" : "a bid"} that is
                        outside the order protection barrier. Please{" "} */}
                      {orderSide === SideResponse.Buy
                        ? optionsTradeFormTranslations(
                            "order_protection_desc_bid"
                          )
                        : optionsTradeFormTranslations(
                            "order_protection_desc_offer"
                          )}
                      {maxOrderSizeBeforeOBProtectionTriggered > 0 ? (
                        <>
                          <span>
                            {optionsTradeFormTranslations(
                              "order_protection_desc_reduce_1"
                            )}
                          </span>
                          {optionsTradeFormTranslations(
                            "order_protection_desc_reduce_2"
                          )}
                          <span>
                            {optionsTradeFormTranslations(
                              "order_protection_desc_reduce_3",
                              {
                                amount:
                                  maxOrderSizeBeforeOBProtectionTriggered.toFixed(
                                    contractPriceStep.amount_precision
                                  ),
                              }
                            )}
                          </span>
                          {optionsTradeFormTranslations(
                            "order_protection_desc_reduce_4"
                          )}
                        </>
                      ) : (
                        <span>
                          {orderSide === SideResponse.Buy
                            ? optionsTradeFormTranslations(
                                "order_protection_desc_try_limit_order_bid"
                              )
                            : optionsTradeFormTranslations(
                                "order_protection_desc_try_limit_order_ask"
                              )}
                        </span>
                      )}
                    </p>
                  </OrderProtectionWrapper>
                ) : (
                  <TradeInfoWrapper isMobileScreen>
                    {tradeInfo.map((info) => (
                      <InfoRow
                        key={`${info.title}-${info.value}}`}
                        warningOrError={info.warningOrError}
                        isMobileScreen
                      >
                        <Title>
                          {info.title}
                          {Boolean(
                            info.warningOrError && info.showErrorIcon
                          ) && (
                            <Alert
                              style={{
                                stroke:
                                  info.warningOrError === "warning"
                                    ? COLORS.system.one
                                    : COLORS.negative.one,
                                marginLeft: `${SPACING.one}px`,
                                marginTop: `-${SPACING.one / 2}px`,
                              }}
                            />
                          )}
                        </Title>
                        <Value>{info.value}</Value>
                      </InfoRow>
                    ))}

                    {/* If market order, show fees */}
                    {orderType === OrderTypeResponse.Market && (
                      <FeeInfo
                        asset={market.asset}
                        size={Number(amount || 0)}
                        feeStructure={Number(feeStructure?.taker_fee || 0)}
                        markPrice={Number(markPrice || 0)}
                      />
                    )}
                  </TradeInfoWrapper>
                )}
                <PositionInfoWrapper isMobileScreen>
                  {positionInfo.map((info) => (
                    <InfoRow
                      key={`${info.title}-${info.value}}`}
                      warningOrError={info.warningOrError}
                      isMobileScreen
                    >
                      <Title>
                        {info.title}
                        {Boolean(info.warningOrError && info.showErrorIcon) && (
                          <Alert
                            style={{
                              stroke:
                                info.warningOrError === "warning"
                                  ? COLORS.system.one
                                  : COLORS.negative.one,
                              marginLeft: `${SPACING.one}px`,
                              marginTop: `-${SPACING.one / 2}px`,
                            }}
                          />
                        )}
                        {info.side && (
                          <PositionChip type={info.side}>
                            {info.side === SideResponse.Buy
                              ? optionsTradeFormTranslations("long")
                              : optionsTradeFormTranslations("short")}
                          </PositionChip>
                        )}
                      </Title>
                      <Value>{info.value}</Value>
                    </InfoRow>
                  ))}
                </PositionInfoWrapper>
              </StatsWrapper>
            </FormContent>
          </TradeFormTopContent>
          <GreeksWrapper>
            <Greeks greeks={optionData?.greeks} isTradeForm />
          </GreeksWrapper>
          <SubmitContent
            selectedInstrument={selectedInstrument}
            orderSide={orderSide}
            amount={amount}
            reduceOnlyDisabled={reduceOnlyDisabled}
            reduceOnlyNotAllowed={reduceOnlyNotAllowed}
            reduceOnly={reduceOnly}
            isLoading={isLoading}
            maxOrderSizeBeforeOBProtectionTriggered={
              maxOrderSizeBeforeOBProtectionTriggered
            }
            accountSigningKeyState={accountSigningKeyState}
            inactiveSubmitButtonState={inactiveSubmitButtonState}
            animControls={animControls}
            errors={errors}
            isMobileScreen
          />
        </TradeForm>
      </TradeFormContainer>
      <MobileTradingBottomBar noAnimation />
    </AbsoluteWrapper>
  );
}

export default MobileComponent;
