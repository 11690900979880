import CryptoJS from "crypto-js";

export function encrypt(
  text: string,
  password: string
): {
  encryptedText: string;
  iv: string;
  salt: string;
} {
  try {
    // Random salt for key derivation
    const salt = CryptoJS.lib.WordArray.random(128 / 8);
    const iv = CryptoJS.lib.WordArray.random(128 / 8);

    // Deriving key using PBKDF2
    const key = CryptoJS.PBKDF2(password, salt, {
      keySize: 256 / 32, // for AES-256
      iterations: 10000,
    });

    // Encrypting the text using the derived key
    const encrypted = CryptoJS.AES.encrypt(text, key, {
      // Random IV
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // const encryptedText = CryptoJS.AES.encrypt(text, password).toString();
    return {
      encryptedText: encrypted.toString(),
      iv: iv.toString(),
      salt: salt.toString(),
    };
  } catch (e) {
    console.log("Error during encryption", e);
    throw e;
  }
}

// Dont need to catch error for decryption because we just assume the password is wrong
export function decrypt(
  encryptedText: string,
  password: string,
  ivString: string,
  saltString: string
) {
  // Assuming these values were saved from the encryption process
  const iv = CryptoJS.enc.Hex.parse(ivString); // The IV as a hex string
  const salt = CryptoJS.enc.Hex.parse(saltString); // The salt as a hex string

  // Deriving the same key using PBKDF2, salt and password must be the same
  const key = CryptoJS.PBKDF2(password, salt, {
    keySize: 256 / 32, // Must be the same as during encryption
    iterations: 10000, // Must be the same as during encryption
  });

  // Decrypting the text using the derived key
  const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const originalText = decrypted.toString(CryptoJS.enc.Utf8);
  return originalText;
}
