import { AxiosError } from "axios";
import { useCallback, useMemo } from "react";
import useSWRImmutable from "swr/immutable";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";
import { publicApi } from "../../../services/api/apiFetcher";
import { GetAirdropIncentivizedAssets200Response } from "../../../codegen-api";
import { nonIncentivizedAirdrops } from "../../../utils/asset/assets";
import { APIEndpointEnum } from "../../../enums/endpoint";

export const useGetAirdropIncentivizedAssets = () => {
  const fetcher = publicApi();
  const swr = useSWRImmutable<
    GetAirdropIncentivizedAssets200Response,
    AxiosError
  >(
    APIEndpointEnum.AIRDROP_INCENTIVIZED_ASSETS,
    async () => (await (await fetcher.getAirdropIncentivizedAssets())()).data,
    {
      use: [useLoggerMiddleware],
    }
  );

  const airdropIncentivizedAssets: string[] = useMemo(
    () =>
      (swr.data?.airdrop_incentivized_assets || [])
        .filter((a) => a.is_incentivized)
        .map((a) => a.asset),
    [swr.data]
  );

  const allAirdrops = useMemo(
    () => [...airdropIncentivizedAssets, ...nonIncentivizedAirdrops],
    [airdropIncentivizedAssets]
  );
  const isPrelaunchWithAirdrop = useCallback(
    (asset: string) => allAirdrops.includes(asset),
    [allAirdrops]
  );

  return {
    ...swr,
    airdropIncentivizedAssets,
    allAirdrops,
    isPrelaunchWithAirdrop,
  };
};
