import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { COLORS, LAYER_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../constants/design/spacing";
import { foregroundComponent } from "../../../style";
import { glow } from "../../Buttons/styles";

export const Container = styled.div`
  ${foregroundComponent};
  width: inherit;
  overflow: scroll;
  display: flex;
  flex-direction: column;
`;

export const ExpandButton = styled.button`
  aspect-ratio: 1;
  width: 40px;
  background-color: ${COLORS.blue.two};
  margin: ${SPACING.two}px 0;
  border-radius: ${BORDER_RADIUS.five}px;
  > svg {
    stroke: ${COLORS.blue.one};
  }
  &:hover {
    > svg {
      opacity: 1;
    }
  }

  ${glow(COLORS.blue.one)}
`;

export const TabContent = styled.div`
  ${FONT_CSS.header.eight}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TabCounter = styled.div`
  ${FONT_CSS.data.five}
  background-color: ${LAYER_COLORS.four};
  border-radius: 20px;
  margin-left: ${SPACING.one}px;
  padding: 0 ${SPACING.one}px;
  min-width: 16px;
`;

export const ContentContainer = styled(motion.div)`
  overflow: hidden;
  flex: 1;
`;

export const BottomBarHeader = styled.div<{ canClick?: boolean }>`
  display: flex;
  justify-content: space-between;
  height: ${COMPONENTS.tradeBottomBar.header}px;
  padding: ${SPACING.two}px;

  ${({ canClick }) =>
    canClick &&
    css`
      cursor: pointer;
    `}
`;
