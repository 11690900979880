/* eslint-disable no-nested-ternary */
import { AnimationControls } from "framer-motion";
import { FieldErrors } from "react-hook-form";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { GetAccount200Response, SideResponse } from "../../../../codegen-api";
import { COLORS } from "../../../../constants/design/colors";
import { AccountStateEnum } from "../../../../contexts/AuthContext";
import { IOptionMarket } from "../../../../contexts/MarketInstrumentContext/useGetMarkets";
import { Spinner } from "../../../shared/Spinner";
import {
  PlaceOrderButton,
  SubmitContentWrapper,
  TradeExecutedBar,
} from "../../style";
import { IOptionsFormFieldValues } from "./form";

export interface IMobileComponentProps {
  selectedInstrument?: IOptionMarket;
  orderSide: SideResponse;
  amount: never;
  reduceOnlyDisabled: boolean;
  reduceOnlyNotAllowed: boolean;
  reduceOnly: boolean;
  isLoading: boolean;
  maxOrderSizeBeforeOBProtectionTriggered: number;
  accountSigningKeyState: AccountStateEnum;
  showOnboarding?: boolean;
  accountData?: GetAccount200Response;
  inactiveSubmitButtonState: JSX.Element;
  animControls: AnimationControls;
  errors: FieldErrors<IOptionsFormFieldValues>;
  isMobileScreen?: boolean;
}

function SubmitContent({
  selectedInstrument,
  orderSide,
  amount,
  reduceOnlyDisabled,
  reduceOnlyNotAllowed,
  reduceOnly,
  isLoading,
  maxOrderSizeBeforeOBProtectionTriggered,
  accountSigningKeyState,
  showOnboarding,
  accountData,
  inactiveSubmitButtonState,
  animControls,
  errors,
  isMobileScreen,
}: PropsWithChildren<IMobileComponentProps>) {
  const { t: commonFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.Common",
  });
  const { t: optionsTradeFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.OptionsTradeForm.OptionsTradeForm",
  });

  return (
    <SubmitContentWrapper isMobileScreen={isMobileScreen}>
      {accountSigningKeyState === AccountStateEnum.OK && !showOnboarding ? (
        <PlaceOrderButton
          disabled={
            Object.values(errors).length > 0 ||
            !amount ||
            !selectedInstrument ||
            accountData?.in_liquidation ||
            Number(amount) > maxOrderSizeBeforeOBProtectionTriggered ||
            isLoading ||
            Boolean(!reduceOnlyDisabled && reduceOnlyNotAllowed && reduceOnly)
          }
          type={"submit"}
          side={orderSide}
        >
          {!isLoading ? (
            optionsTradeFormTranslations("place_order", {
              side: commonFormTranslations(orderSide.toLowerCase()),
            })
          ) : (
            <Spinner
              color={
                orderSide === SideResponse.Buy
                  ? COLORS.positive.one
                  : COLORS.negative.one
              }
            />
          )}
        </PlaceOrderButton>
      ) : (
        inactiveSubmitButtonState
      )}
      <TradeExecutedBar
        type={orderSide}
        animate={animControls}
        initial={{
          opacity: 0,
          scale: 0,
        }}
      />
    </SubmitContentWrapper>
  );
}

export default SubmitContent;
