/* eslint-disable no-continue */
import { useCallback, useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useShallow } from "zustand/react/shallow"
import { WSS_URL } from "../../../constants/api/wss";
import { nanosToMillis } from "../../../utils/date";
import { jsonParse, jsonStringify } from "../../../utils/strings";
import { IWSSOrderbookRequest } from "../model/orderbook";
import { ISubscribeIDMap, IWebsocketRateLimitedResponse, IWebsocketResponse } from "../model/shared";
import { IWSSTickerRequest } from "../model/ticker";
import { getMarkPriceChannel } from "./channels";
import { useMarkPriceStore } from "./store/useMarkPriceStore";
import useTabVisibility from "../../useTabVisibility";


// 15 second interval when tab not visible
const tabInvisibleFlushMessagesIntervalMs = 15000;
const flushMessagesIntervalMs = 250;

export function MarkPriceWebsocket() {
  const isTabVisible = useTabVisibility();

  // MARK PRICE
  const [subToMarkTickers, updateMark] = useMarkPriceStore(useShallow((state) => [state.markTickers, state.updateMark]))
  const subbedMarkTickers = useRef<Set<string>>(new Set());

  const [messages, setMessages] = useState<MessageEvent<any>[] | null>(null);

  const messageEvents = useRef(new Set<MessageEvent<any>>());

  const { sendMessage, readyState } = useWebSocket(WSS_URL, {
    shouldReconnect: () => true,
    reconnectAttempts: 50,
    // Attempt to reconnect every 2 seconds
    reconnectInterval: 2000,
    retryOnError: true,
    share: false,
    onMessage: (msg: any) => {
      messageEvents.current.add(msg);
    },
    // Never update lastMessage, since we're processing onMessage ourselves
    filter: () => false,
  });

  useEffect(() => {
    const flushMessages = () => {
      const msgs = Array.from(messageEvents.current.values());
      if (msgs.length) {
        messageEvents.current.clear();
        setMessages(isTabVisible ? msgs : msgs.slice(-10))
      }
    };
    const timer = setInterval(flushMessages, isTabVisible ? flushMessagesIntervalMs : tabInvisibleFlushMessagesIntervalMs);

    return () => {
      clearInterval(timer);
      flushMessages();
    };
  }, [isTabVisible]);


  // An incremental id will be assigned to each subscribes
  // Using ref because we never want these to trigger any side effects
  const id = useRef(0);
  const subbedIdMap = useRef<ISubscribeIDMap>({});

  // Resub whenever readyState opens
  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const subDatas = [...Object.values(subbedIdMap.current)];
      // Resub all the existing subs
      console.log("WS Reconnected! Resubbing to: ", subDatas);
      subbedIdMap.current = {};

      for (let i = 0; i < subDatas.length; i += 1) {
        const data = jsonParse(subDatas[i]);
        id.current += 1;

        // Subscribe
        const subMessage: IWSSTickerRequest = {
          op: "subscribe",
          data,
          id: id.current,
        };
        sendMessage(jsonStringify(subMessage));

        subbedIdMap.current = {
          ...subbedIdMap.current,
          [id.current]: jsonStringify(data),
        };
      }
    }
  }, [readyState, sendMessage]);

  const triggerUnsubscribe = useCallback((data: string[]) => {
    if (!data.length) {
      return;
    }

    const existingId = Object.keys(subbedIdMap.current).find((key) =>
      subbedIdMap.current[Number(key)].includes(jsonStringify(data))
    );
    const idNum = Number(existingId);
    if (idNum) {
      const unsubMessage: IWSSOrderbookRequest = {
        op: "unsubscribe",
        data: jsonParse(subbedIdMap.current[idNum]),
      };
      sendMessage(jsonStringify(unsubMessage));
      delete subbedIdMap.current[idNum];
    }
  }, [sendMessage])

  const triggerSubscribe = useCallback((data: string[]) => {
    if (!data.length) {
      return;
    }

    // Check if any existing channels exists
    // If not subbed, increment id and SUB
    const exists = Object.values(subbedIdMap.current).some(
      (v) => v === jsonStringify(data)
    );
    if (!exists && readyState === ReadyState.OPEN) {
      id.current += 1;

      // Subscribe
      const subMessage: IWSSTickerRequest = {
        op: "subscribe",
        data,
        id: id.current,
      };
      sendMessage(jsonStringify(subMessage));

      subbedIdMap.current = {
        ...subbedIdMap.current,
        [id.current]: jsonStringify(data),
      };
    }
  },
    [readyState, sendMessage]
  );

  // Automatic subbed to mark prices
  useEffect(() => {
    if (readyState !== ReadyState.OPEN) {
      return;
    }

    // If subToMarkTickers exist, but local subbedMarkTickers don't,
    const subscribeToMarkAssets = Array
      .from(subToMarkTickers)
      .filter((i) => !subbedMarkTickers.current.has(i))

    // If local subbedMarkTickers exist, but subToMarkTickers don't,
    const unsubscribeFromMarkAssets = Array
      .from(subbedMarkTickers.current)
      .filter((i) => !subToMarkTickers.has(i))

    // Sub
    const subData = subscribeToMarkAssets.map((a) => getMarkPriceChannel(a));
    triggerSubscribe(subData);

    for (let i = 0; i < subscribeToMarkAssets.length; i += 1) {
      const asset = subscribeToMarkAssets[i];
      subbedMarkTickers.current.add(asset)
    }

    // Unsub
    const unsubData = unsubscribeFromMarkAssets.map((a) => getMarkPriceChannel(a));
    triggerUnsubscribe(unsubData);

    for (let i = 0; i < unsubscribeFromMarkAssets.length; i += 1) {
      const asset = unsubscribeFromMarkAssets[i];
      subbedMarkTickers.current.delete(asset)
    }
  }, [readyState, subToMarkTickers, triggerSubscribe, triggerUnsubscribe]);

  // If any messages is a rate limit exceeded, retry after x seconds
  useEffect(() => {
    if (!messages) {
      return
    }

    for (let i = 0; i < messages.length; i += 1) {
      const lastMessage = messages[i];

      const {
        data,
        error,
        id: resubId,
      }: IWebsocketRateLimitedResponse = jsonParse(lastMessage.data);
      if (error === "RATE_LIMIT_EXCEEDED" && resubId) {
        const subMsg = subbedIdMap.current[resubId];
        // If existing sub id exists
        if (subMsg) {
          const retryMs = nanosToMillis(data.retry_after);

          // If failed, we delete subbed id map
          delete subbedIdMap.current[resubId];

          // And then resub again after a duration
          setTimeout(() => {
            console.log("RETRYING", subMsg);
            triggerSubscribe(jsonParse(subMsg));
          }, retryMs);
        }
      }
    }
  }, [messages, triggerSubscribe]);

  // Handle messages
  useEffect(() => {
    if (!messages) {
      return
    }

    for (let idx = 0; idx < messages.length; idx += 1) {
      const lastMessage = messages[idx];

      const { data, channel }: IWebsocketResponse = jsonParse(
        lastMessage.data
      );

      // mark should continue to be handled so that tab header mark price updates
      // Handle Mark
      const tickerMatchingMarkChannel = Array
        .from(subbedMarkTickers.current)
        .find((i) => getMarkPriceChannel(i) === channel)
      if (data && tickerMatchingMarkChannel) {
        updateMark(data)
        continue
      }


      console.log("UNHANDLED DATA:", data, channel)

    }
  }, [messages, updateMark, isTabVisible]);


  return null
}