import styled from "styled-components";
import { FONT_CSS } from "../../constants/design/fontSize";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { Button } from "../Buttons/styles";

export const MarketDetailsButton = styled(Button)`
  ${FONT_CSS.body.five}
  color: ${TEXT_COLORS.two};
  padding: ${SPACING.two}px;
  min-height: 0;
  margin-left: ${SPACING.two}px;
  border-radius: 10px;

  svg {
    margin-left: ${SPACING.one}px;
  }
`;
export const MarketDetailsHeader = styled.div`
  img {
    margin-right: ${SPACING.two}px;
  }
`;

export const MarketDetailsDescription = styled.div`
  ${FONT_CSS.body.three}
  color: ${TEXT_COLORS.two};
`;

export const MarketDetailsInfo = styled.div`
  margin-top: ${SPACING.three}px;

  > * {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
`;

export const InfoRow = styled.div<{
  warningOrError?: "warning" | "error";
  marginBottom?: number;
  isMobileScreen?: boolean;
}>`
  &:not(:last-of-type) {
    margin-bottom: ${({ marginBottom, isMobileScreen }) =>
      marginBottom || isMobileScreen
        ? SPACING.two
        : SPACING.twoHalf}px !important;
  }

  > * {
    margin: auto 0 !important;
    color: ${({ warningOrError }) => {
      if (warningOrError === "warning") {
        return `${COLORS.system.one} !important`;
      }
      if (warningOrError === "error") {
        return `${COLORS.negative.one} !important`;
      }
      return undefined;
    }};
  }
`;

export const Title = styled.div`
  ${FONT_CSS.label.two}
  color: ${TEXT_COLORS.three};
  display: flex;
  align-items: center;
  margin: auto 0;
`;

export const Value = styled.div`
  ${FONT_CSS.data.three}
  color: ${TEXT_COLORS.one};
`;
