// Render nested rows

import currency from "currency.js";
import moment from "moment";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "react-table";
import { SideResponse } from "../../codegen-api";
import { COLORS } from "../../constants/design/colors";
import { ITableColumn } from "../../interfaces/Table/TableColumn";
import { getAssetLogo } from "../../utils/asset/assets";
import { nanosToSeconds } from "../../utils/date";
import { formatSizePrecision } from "../../utils/format";
import {
  Separator,
  SettlementInProgress,
} from "../PortfolioSettings/Table/style";
import { Chevron } from "../shared/Chevron/style";
import RowActionButton from "../shared/RowActionButton";
import { Align, SizeFillCell } from "../shared/Table/style";
import { IModifiedPosition } from "./model";
import {
  AnimatedColumn,
  AssetAndLabels,
  RowActionWrapper,
  ExpiryColumn,
  OptionNameTitle,
  PositionTableRow,
  PositionTitleWrapper,
  HeaderColumn,
} from "./style";

interface IOptionPositionTableRowProps {
  inLiquidation?: boolean;
  row: Row<IModifiedPosition>;
  columns: ITableColumn<IModifiedPosition>[];
  actionButtonId?: string;
  onClickPosition?: (pos: IModifiedPosition) => void;
  onClosePosition?: (pos: IModifiedPosition) => void;
  onViewPositionDetails?: (pos: IModifiedPosition) => void;
  onSharePosition?: (pos: IModifiedPosition) => void;
}

const greeksAccessor = [
  "option.delta",
  "option.gamma",
  "option.vega",
  "option.theta",
];

const positionValuePnlAccessor: (keyof IModifiedPosition)[] = [
  "positionValue",
  "unrealized_pnl",
  "maintenance_margin",
];

function OptionPositionTableRow({
  inLiquidation,
  row,
  columns,
  actionButtonId,
  onClickPosition,
  onClosePosition,
  onViewPositionDetails,
  onSharePosition,
}: IOptionPositionTableRowProps) {
  const [expanded, setExpanded] = useState(false);
  const [expandedExpiries, setExpandedExpiries] = useState<string[]>();
  const { t } = useTranslation("app", { keyPrefix: "OptionPositionTableRow" });

  const { nestedOptionsData, asset } = row.original;
  const allPositions = Object.values(nestedOptionsData || {})
    .map((v) => v.positions)
    .flat();

  const onToggleExpandedExpiry = useCallback(
    (expiry: string) => {
      if ((expandedExpiries || []).includes(expiry)) {
        setExpandedExpiries(
          (expandedExpiries || []).filter((exp) => exp !== expiry)
        );
      } else {
        setExpandedExpiries([...(expandedExpiries || []), expiry]);
      }
    },
    [expandedExpiries]
  );

  // Returns the summed greeks
  const greeksSummary = useMemo(() => {
    const defaultGreeks = {
      delta: 0,
      gamma: 0,
      vega: 0,
      theta: 0,
    };

    const optionByExpiries = Object.keys(nestedOptionsData || {}).reduce(
      (acc, exp) => ({
        ...acc,
        [exp]: (nestedOptionsData || {})[exp].positions.reduce(
          (greeks, position) => {
            const d = {
              delta: greeks.delta + Number(position.option?.delta || 0),
              gamma: greeks.gamma + Number(position.option?.gamma || 0),
              vega: greeks.vega + Number(position.option?.vega || 0),
              theta: greeks.theta + Number(position.option?.theta || 0),
            };
            return d;
          },
          defaultGreeks
        ),
      }),
      {} as {
        [expiry: string]: {
          delta: number;
          gamma: number;
          vega: number;
          theta: number;
        };
      }
    );

    return {
      optionByExpiries,
      totalGreeks: Object.values(optionByExpiries).reduce(
        (prev, greeks) => ({
          delta: prev.delta + greeks.delta,
          gamma: prev.gamma + greeks.gamma,
          vega: prev.vega + greeks.vega,
          theta: prev.theta + greeks.theta,
        }),
        defaultGreeks
      ),
    };
  }, [nestedOptionsData]);

  // Returns the summed greeks
  const positionValuePnlSummary = useMemo(() => {
    const defaultSummary = {
      positionValue: 0,
      pnl: 0,
      maintenance_margin: 0,
      pricePrecision: 2,
    };

    const summaryByExpirires = Object.keys(nestedOptionsData || {}).reduce(
      (acc, exp) => ({
        ...acc,
        [exp]: (nestedOptionsData || {})[exp].positions.reduce(
          (summary, position) => {
            const d = {
              positionValue:
                summary.positionValue + Number(position.positionValue || 0),
              pnl: summary.pnl + Number(position.unrealized_pnl || 0),
              maintenance_margin:
                summary.maintenance_margin +
                Number(position.maintenance_margin || 0),
              pricePrecision: position.pricePrecision,
            };
            return d;
          },
          defaultSummary
        ),
      }),
      {} as {
        [expiry: string]: {
          positionValue: number;
          pnl: number;
          maintenance_margin: number;
          pricePrecision: number;
        };
      }
    );

    return {
      optionByExpirires: summaryByExpirires,
      total: Object.values(summaryByExpirires).reduce(
        (prev, summ) => ({
          ...prev,
          positionValue: prev.positionValue + summ.positionValue,
          pnl: prev.pnl + summ.pnl,
          maintenance_margin: prev.maintenance_margin + summ.maintenance_margin,
        }),
        defaultSummary
      ),
    };
  }, [nestedOptionsData]);

  const renderHeaderCellContent = useCallback(
    (column: ITableColumn<IModifiedPosition>, expiry?: string) => {
      const { accessor, align } = column;

      if (accessor === "instrument_name") {
        if (expiry) {
          return (
            <ExpiryColumn expanded={expanded}>
              <div>
                {moment.unix(nanosToSeconds(expiry)).format("DD MMM YY")}
                <Chevron
                  direction={
                    (expandedExpiries || []).includes(expiry) ? "up" : "down"
                  }
                />
              </div>
            </ExpiryColumn>
          );
        }
        return (
          <AssetAndLabels>
            <img src={getAssetLogo(asset)} alt={asset} />
            <div>
              <span>
                {asset} {t("options")}
                <Chevron direction={expanded ? "up" : "down"} />
              </span>
              <span>
                {allPositions.length} {t("positions")}
              </span>
            </div>
          </AssetAndLabels>
        );
      }

      if (positionValuePnlAccessor.includes(accessor as any) && !expiry) {
        let value = "";
        const summary = expiry
          ? positionValuePnlSummary.optionByExpirires[expiry]
          : positionValuePnlSummary.total;
        const pnlColor =
          summary.pnl >= 0 ? COLORS.positive.one : COLORS.negative.one;
        if ((accessor as any) === "unrealized_pnl") {
          value = currency(summary.pnl).format();
          return (
            <Align align={align} style={{ color: pnlColor }}>
              {summary.pnl >= 0 ? "+" : ""}
              <span>{value}</span>
            </Align>
          );
        }
        if ((accessor as any) === "positionValue") {
          value = currency(summary.positionValue, {
            precision: summary.pricePrecision,
          }).format();
        }
        if ((accessor as any) === "maintenance_margin") {
          value = currency(summary.maintenance_margin).format();
        }
        return <Align align={align}>{value}</Align>;
      }

      if (greeksAccessor.includes(accessor as any) && !expiry) {
        let value = "";
        const summary = expiry
          ? greeksSummary.optionByExpiries[expiry]
          : greeksSummary.totalGreeks;
        if ((accessor as any) === "option.delta") {
          value = summary.delta.toFixed(4);
        }
        if ((accessor as any) === "option.gamma") {
          value = summary.gamma.toFixed(2);
        }
        if ((accessor as any) === "option.vega") {
          value = summary.vega.toFixed(2);
        }
        if ((accessor as any) === "option.theta") {
          value = currency(summary.theta).format();
        }
        return <Align align={align}>{value}</Align>;
      }

      return null;
    },
    [
      asset,
      t,
      expanded,
      allPositions.length,
      expandedExpiries,
      positionValuePnlSummary,
      greeksSummary,
    ]
  );

  const renderCellContent = useCallback(
    (pos: IModifiedPosition, column: ITableColumn<IModifiedPosition>) => {
      if (!pos.option) {
        return null;
      }

      const { accessor, align, id } = column;
      const {
        roi,
        unrealized_pnl,
        side,
        option,
        amount,
        amountPrecision,
        maintenance_margin,
      } = pos;

      // First column
      if (accessor === "instrument_name") {
        return (
          <PositionTitleWrapper>
            <Align align={align}>
              <div>
                <OptionNameTitle>
                  {currency(option.strike).format({ symbol: "" })}
                  <Separator />
                  {t(option.option_type)}
                </OptionNameTitle>
                {Boolean(
                  moment
                    .unix(nanosToSeconds(option.expiry))
                    .isSameOrBefore(moment())
                ) && (
                  <SettlementInProgress>
                    {t("settlement_in_progress")}
                  </SettlementInProgress>
                )}
              </div>
            </Align>
          </PositionTitleWrapper>
        );
      }

      // Special case
      if (greeksAccessor.includes(accessor as any)) {
        const a = (accessor as string)?.split(".")[1];
        const val = (pos.option as any)[a as any];

        if (a === "theta") {
          return <Align align={align}>{currency(val || 0).format()}</Align>;
        }

        return (
          <Align align={align}>
            {a === "delta"
              ? Number(val || 0).toFixed(4)
              : Number(val || 0).toFixed(2)}
          </Align>
        );
      }

      if (actionButtonId && id === actionButtonId) {
        return (
          <RowActionWrapper>
            {!inLiquidation && (
              <RowActionButton
                variant={"close"}
                onClick={(e) => {
                  e.stopPropagation();
                  onClosePosition?.(pos);
                }}
              />
            )}
            <RowActionButton
              variant={"details"}
              onClick={(e) => {
                e.stopPropagation();
                onViewPositionDetails?.(pos);
              }}
            />
            <RowActionButton
              variant="share"
              svgColor={
                Number(roi) >= 0 ? COLORS.positive.one : COLORS.negative.one
              }
              onClick={(e) => {
                e.stopPropagation();
                onSharePosition?.(pos);
              }}
            />
          </RowActionWrapper>
        );
      }

      let item: JSX.Element | null = null;
      switch (accessor) {
        case "side": {
          item = (
            <span
              style={{
                color:
                  side === SideResponse.Buy
                    ? COLORS.positive.one
                    : COLORS.negative.one,
              }}
            >
              {t(side)}
            </span>
          );
          break;
        }
        case "amount": {
          item = <span>{formatSizePrecision(amount, amountPrecision)}</span>;
          break;
        }
        case "mark_price":
        case "avg_entry_price":
        case "positionValue": {
          item = (
            <span>
              {currency(pos[accessor]?.toString() || 0, {
                precision: pos.pricePrecision,
              }).format()}
            </span>
          );
          break;
        }
        case "unrealized_pnl": {
          const color =
            Number(unrealized_pnl) >= 0
              ? COLORS.positive.one
              : COLORS.negative.one;
          const roiColor =
            Number(roi) >= 0 ? COLORS.positive.two : COLORS.negative.two;

          item = (
            <SizeFillCell>
              <span style={{ color }}>
                {Number(unrealized_pnl) >= 0 ? "+" : ""}
                {unrealized_pnl ? currency(unrealized_pnl).format() : "-"}
              </span>
              <span style={{ color: roiColor }}>
                {roi >= 0 ? "+" : ""}
                {(roi * 100).toFixed(2)}%
              </span>
            </SizeFillCell>
          );
          break;
        }
        case "maintenance_margin": {
          item = <span>{currency(maintenance_margin).format()}</span>;
          break;
        }
        case "instrument_id": {
          item = <span>-</span>;
          break;
        }
        default: {
          item = (
            <span>
              {(accessor
                ? (pos as any)[accessor as string] || "-"
                : "-"
              ).toString()}
            </span>
          );
          break;
        }
      }

      return <Align align={align}>{item}</Align>;
    },
    [
      actionButtonId,
      inLiquidation,
      onClosePosition,
      onSharePosition,
      onViewPositionDetails,
      t,
    ]
  );

  if (!nestedOptionsData) {
    return null;
  }

  return (
    <>
      <PositionTableRow
        {...row.getRowProps()}
        style={{
          position: "relative",
          zIndex: 2,
        }}
        onClick={() => {
          setExpanded((prev) => !prev);
        }}
      >
        {columns.map((col, i) => (
          <HeaderColumn key={String(col.accessor || col.id || i)}>
            {renderHeaderCellContent(col)}
          </HeaderColumn>
        ))}
      </PositionTableRow>
      {/* Expiries rows + cell */}
      {
        // Loop all expiries
        Object.keys(nestedOptionsData)
          .sort((a, b) => Number(a) - Number(b))
          .map((e) => (
            <Fragment key={e}>
              <PositionTableRow
                onClick={() => onToggleExpandedExpiry(e)}
                style={{
                  position: "relative",
                  zIndex: 1,
                  height: "auto",
                }}
              >
                {columns.map((col, i) =>
                  expanded ? (
                    <HeaderColumn key={String(col.accessor || col.id || i)}>
                      {renderHeaderCellContent(col, e)}
                    </HeaderColumn>
                  ) : null
                )}
              </PositionTableRow>
              {
                // Check if expiry expanded
                nestedOptionsData[e].positions.map((p) => {
                  const { option } = p;
                  if (!option) {
                    return null;
                  }
                  return (
                    <PositionTableRow
                      key={p.instrument_name}
                      style={{
                        position: "relative",
                        zIndex: 0,
                        height: "auto",
                      }}
                      onClick={() => onClickPosition?.(p)}
                    >
                      {columns.map((c, i) =>
                        expanded ? (
                          <AnimatedColumn
                            key={String(c.accessor || c.id || i)}
                            expanded={expanded && expandedExpiries?.includes(e)}
                          >
                            {renderCellContent(p, c)}
                          </AnimatedColumn>
                        ) : null
                      )}
                    </PositionTableRow>
                  );
                })
              }
            </Fragment>
          ))
      }
    </>
  );
}

export default OptionPositionTableRow;
