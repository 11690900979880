import styled from "styled-components";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";

export const SortContainer = styled.div<{
  isSortedDesc?: boolean;
  isSorted?: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding-left: ${SPACING.two}px;

  svg:nth-child(1) {
    fill: ${({ isSortedDesc, isSorted }) => {
    if (isSorted) {
      return !isSortedDesc ? COLORS.blue.one : COLORS.white.four;
    }
    return TEXT_COLORS.three;
  }};
  }
  
  svg:nth-child(2) {
    transform: rotate(180deg);
    fill: ${({ isSortedDesc, isSorted }) => {
    if (isSorted) {
      return isSortedDesc ? COLORS.blue.one : COLORS.white.four;
    }
    return TEXT_COLORS.three;
  }};
  }
`;
