import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import ReactSlider from "react-slider";
import {
  BORDER_COLORS,
  COLORS,
  FIELD_COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import {
  FONT_CSS,
  FONT_SIZE,
  FONT_STYLE,
} from "../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../constants/design/spacing";
import { Button } from "../Buttons/styles";

export const TagContainer = styled.div<{ color?: string; bgColor?: string }>`
  ${FONT_CSS.label.one}
  width: fit-content;
  padding: ${SPACING.one}px ${SPACING.two}px;
  border-radius: 4px;
  background-color: ${({ bgColor }) => bgColor || COLORS.positive.five};
  margin-bottom: ${SPACING.three}px;
  display: flex;
  align-items: center;

  span {
    color: ${({ color }) => color};
    text-transform: capitalize;
  }
`;

export const TagContent = styled.div`
  display: flex;
  align-items: center;
`;

export const StatContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${FONT_SIZE.one};

  &:not(:last-child) {
    margin-bottom: ${SPACING.three}px;
  }

  span:first-child {
    color: ${TEXT_COLORS.three};
  }

  span:last-child {
    color: ${TEXT_COLORS.one};
  }
`;

export const ButtonsContainer = styled.div<{
  reverseConfirmationWrapper?: boolean;
}>`
  margin-top: ${SPACING.three}px;

  ${({ reverseConfirmationWrapper }) =>
    reverseConfirmationWrapper &&
    css`
      flex-direction: row-reverse;
    `}
`;

export const EditOrderInfoTag = styled.div<{
  color?: string;
  background?: string;
}>`
  ${FONT_CSS.label.one}
  width: fit-content;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: ${SPACING.one}px ${SPACING.two}px;
  border-radius: 4px;
  display: flex;
  margin-bottom: ${SPACING.three}px;
  text-transform: capitalize;

  > div {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      &:after {
        display: inline-block;
        content: "";
        width: 2px;
        height: 2px;
        border-radius: 2px;
        background-color: ${({ color }) => color};
        margin: 0 ${SPACING.one}px;
      }
    }
  }
`;

export const FooterContainer = styled.div`
  padding: ${SPACING.two}px;
  margin-top: ${SPACING.three}px;
  background-color: ${LAYER_COLORS.two};
  border-radius: 4px;
`;

export const CancelText = styled.div`
  ${FONT_STYLE.body.three};
  color: ${TEXT_COLORS.two};
`;

export const StepRow = styled.div<{ isChecked?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING.three}px;
  border: 1px solid
    ${({ isChecked }) => (isChecked ? BORDER_COLORS.two : COLORS.highlight.two)};
  transition: 0.2s all ease-in-out;
  border-radius: ${BORDER_RADIUS.two}px;

  &:not(:last-of-type) {
    margin-bottom: ${SPACING.two}px;
  }

  span {
    ${FONT_STYLE.label.two};
    color: ${({ isChecked }) =>
      isChecked ? TEXT_COLORS.three : TEXT_COLORS.one};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const StatWrapper = styled.div`
  margin-top: ${SPACING.three}px;
  padding: 0 ${SPACING.two}px;
`;

export const TextWrapper = styled.div`
  margin-top: ${SPACING.two}px;
  ${FONT_CSS.label.two}
  color: ${TEXT_COLORS.three};
  > p {
    display: inline;
    margin: 0;
  }
`;

export const InputRow = styled.div`
  display: flex;
  gap: ${SPACING.two}px;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const CancelButton = styled.button`
  min-height: 48px;
  min-width: 48px;
  margin-top: ${SPACING.three}px;
`;

export const SubmitButton = styled(Button)`
  margin-top: ${SPACING.three}px;
`;

export const ContentContainer = styled(motion.div)`
  overflow: hidden;
  flex: 1;
`;

export const SegmentedControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: ${SPACING.five}px;
  margin-bottom: ${SPACING.three}px;
`;
