import React, { ReactElement, useState } from "react";

interface INotificationContextType {
  notifications: Notification[];
  showPanel: boolean;

  setNotifications: (notifications: Notification[]) => void;
  setShowPanel: (show: boolean) => void;
}

interface INotificationContextProviderProps {
  children: ReactElement;
}

export const NotificationContext = React.createContext<INotificationContextType>({
  notifications: [],
  showPanel: false,

  setNotifications: () => {},
  setShowPanel: () => {},
});

export function NotificationContextProvider({ children }: INotificationContextProviderProps) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showPanel, setShowPanel] = useState<boolean>(false);

  return <NotificationContext.Provider value={{ notifications, showPanel, setNotifications, setShowPanel }}>{children}</NotificationContext.Provider>;
}
