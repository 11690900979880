import styled from "styled-components";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_STYLE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";
import { Button } from "../../Buttons/styles";

export const Container = styled.div`
  width: 300px;

  padding: ${SPACING.three}px;

  > ${Button} {
    margin-top: ${SPACING.two}px;
    width: 100%;

    &:first-child {
      margin-top: ${SPACING.three}0px;
    }
  }

  > span {
    ${FONT_STYLE.label.three}
    color: ${COLORS.negative.one};
  }
`;

export const Text = styled.div`
  ${FONT_STYLE.body.three}
  color: ${TEXT_COLORS.two};
  margin-bottom: ${SPACING.three}px;

  strong {
    color: ${TEXT_COLORS.one};
  }
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;

  > div {
    width: 100%;

    > ${Button} {
      width: 100%;
      margin-top: ${SPACING.two}px !important;
    }
  }
`;

export const ConfirmationTitle = styled.div`
  ${FONT_STYLE.header.seven}
  margin-bottom: ${SPACING.three}px;
  text-transform: capitalize;
`;
