import { useCallback, useContext, useRef, useState } from "react";
import { ScrollableSection, TradingPageWrapper } from "../style";
import {
  Container,
  Dropdown,
  DropdownWrapper,
  MobileSettingsButton,
  MobileTradingTabsWrapper,
  OptionsChainSection,
} from "./style";

import { ReactComponent as Settings } from "../../../assets/svg/settings.svg";
import { InstrumentTypeResponse } from "../../../codegen-api";
import MarketSelectionButton from "../../../components/MarketSelectionButton";
import { MobileOptionsChain } from "../../../components/OptionsChain/MobileOptionsChain";
import OptionsHistory from "../../../components/OptionsHistory";
import MobilePerpsSection from "../../../components/Perps/MobilePerpsSection";
import { SettingsModal } from "../../../components/SettingsModal";
import LazyOptionsTradingHeader from "../../../components/TradingHeader/Option/LazyOptionsTradingHeader";
import LazyPerpsTradingHeader from "../../../components/TradingHeader/Perp/LazyPerpsTradingHeader";
import { SPACING } from "../../../constants/design/spacing";
import { MarketContext } from "../../../contexts/MarketContext";
import { MarketInstrumentContext } from "../../../contexts/MarketInstrumentContext";
import TradingPageTabs, { TradingPageTabEnum } from "../TradingPageTabs";
import VolatilityWarningBanner from "../VolatilityWarningBanner";

function MobileTradingPage() {
  const { market } = useContext(MarketContext);
  const { selectedOptionInstrument, selectedPerpetualInstrument } = useContext(
    MarketInstrumentContext
  );

  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const [currentTab, setCurrentTab] = useState<TradingPageTabEnum>(
    TradingPageTabEnum.OPTIONS_CHAIN
  );
  const [showTradeForm, setShowTradeForm] = useState<boolean>(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);

  const onSettingsClick = useCallback(() => {
    setShowSettingsModal(true);
  }, []);

  return (
    <TradingPageWrapper isMobile style={{ padding: 0 }}>
      <SettingsModal
        show={showSettingsModal}
        onHide={() => setShowSettingsModal(false)}
      />
      <Container isMobileScreen>
        <DropdownWrapper>
          <Dropdown>
            <MarketSelectionButton isMobile isTradeForm={showTradeForm} />
          </Dropdown>
          <MobileSettingsButton onClick={onSettingsClick}>
            <Settings />
          </MobileSettingsButton>
        </DropdownWrapper>
        {market.derivative === InstrumentTypeResponse.Option && (
          <OptionsChainSection>
            <ScrollableSection
              style={{
                margin: SPACING.two,
                height: `calc(100% - ${SPACING.three}px)`,
              }}
              ref={scrollableContainerRef}
            >
              <LazyOptionsTradingHeader isMobileScreen />
              <MobileTradingTabsWrapper>
                <TradingPageTabs
                  isMobileScreen
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
              </MobileTradingTabsWrapper>
              {currentTab === TradingPageTabEnum.OPTIONS_CHAIN ? (
                <MobileOptionsChain
                  activeInstrumentId={selectedOptionInstrument?.instrument_id}
                  scrollableContainerRef={scrollableContainerRef}
                  showTradeForm={showTradeForm}
                  setShowTradeForm={setShowTradeForm}
                />
              ) : (
                <OptionsHistory isMobileScreen />
              )}
            </ScrollableSection>
          </OptionsChainSection>
        )}
        {market.derivative === InstrumentTypeResponse.Perpetual && (
          <OptionsChainSection>
            <ScrollableSection
              style={{
                margin: SPACING.two,
                height: `calc(100% - ${SPACING.three}px)`,
              }}
            >
              {!!selectedPerpetualInstrument?.pre_launch && !showTradeForm && (
                <VolatilityWarningBanner
                  dismissKey={selectedPerpetualInstrument?.underlying_asset}
                />
              )}
              <div style={{ marginBottom: SPACING.two }}>
                <LazyPerpsTradingHeader
                  isMobileScreen
                  perpInstrument={selectedPerpetualInstrument}
                />
              </div>
              <MobilePerpsSection
                perpInstrument={selectedPerpetualInstrument}
                showTradeForm={showTradeForm}
                setShowTradeForm={setShowTradeForm}
              />
            </ScrollableSection>
          </OptionsChainSection>
        )}
      </Container>
    </TradingPageWrapper>
  );
}
export default MobileTradingPage;
