import { motion } from "framer-motion";
import styled from "styled-components";
import { LAYER_COLORS } from "../../constants/design/colors";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const LoadingContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${LAYER_COLORS.one};

  svg {
    z-index: 1;
  }
`;

export const LoadingBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${LAYER_COLORS.one};
  opacity: 0.8;
`;
