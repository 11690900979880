/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import moment from "moment";
import { useContext, useMemo, useRef } from "react";
import { useSortBy, useTable } from "react-table";
import currency from "currency.js";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../constants/design/colors";
import { ITableColumn } from "../../interfaces/Table/TableColumn";
import { nanosToSeconds } from "../../utils/date";
import { Spinner } from "../shared/Spinner";
import { SpinnerContainerWrapper } from "../shared/Spinner/style";
import { DefaultCellForColumn } from "../shared/Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../shared/Table/DefaultHeaderForColumn";
import { Align } from "../shared/Table/style";
import { PnL } from "./style";
import {
  EmptyContentContainer,
  PortfolioTableWrapper,
} from "../PortfolioSettings/Table/style";
import MarketCell from "../PortfolioSettings/Table/MarketCell";
import { IModifiedFundingHistory } from "../../pages/FundingHistoryTab";
import { FUNDING_RATE_PRECISION } from "../../constants/precision/form";
import { getAssetFromSymbol } from "../../utils/instruments";
import { InstrumentType } from "../../codegen-api";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";

interface IFundingHistoryProps {
  histories?: IModifiedFundingHistory[];
  loading?: boolean;
  isMobile?: boolean;
}

function FundingHistory({
  histories,
  loading,
  isMobile,
}: IFundingHistoryProps) {
  const { t } = useTranslation("app", { keyPrefix: "FundingHistory" });
  const { t: tooltip } = useTranslation("tooltips");
  const { getMarketPrecision } = useContext(MarketInstrumentContext);
  const HIGH_LOAD_MODE = process.env.REACT_APP_HIGH_LOAD_MODE === "true";
  const portfolioTableWrapperRef = useRef<HTMLDivElement>(null);

  const columns: ITableColumn<IModifiedFundingHistory>[] = useMemo(
    () => [
      {
        title: t("market"),
        accessor: "market",
        tooltip: tooltip("market"),
        Cell: ({ value }) => <MarketCell instrumentName={value} />,
      },
      {
        title: t("funding_rate"),
        align: "right",
        accessor: "funding_rate",
        sortType: "basic",
        Cell: ({ value }) => (
          <Align align="right">
            <PnL
              color={
                Number(value) >= 0 ? COLORS.positive.one : COLORS.negative.one
              }
            >
              {currency(Number(value) * 100, {
                precision: FUNDING_RATE_PRECISION,
              }).format({ symbol: "" })}
              %
            </PnL>
          </Align>
        ),
      },
      {
        title: t("mark_price"),
        align: "right",
        accessor: "mark_price",
        tooltip: tooltip("markPrice"),
        Cell: ({ value, row }) => (
          <Align align="right">
            {currency(value, {
              precision: getMarketPrecision(
                getAssetFromSymbol(row.original.market),
                InstrumentType.Perpetual
              ).price_precision,
            }).format()}
          </Align>
        ),
      },
      {
        title: t("time"),
        accessor: "created_timestamp",
        align: "right",
        valueExtractor: (value) =>
          `${moment
            .unix(nanosToSeconds(value))
            .utc()
            .format("hhmm")} UTC - ${moment
            .unix(nanosToSeconds(value))
            .format("DD MMM YY")}`,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, portfolioTableWrapperRef.current?.clientWidth]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: histories,
        defaultColumn: {
          Header: DefaultHeaderForColumn,
          Cell: DefaultCellForColumn,
        },
        autoResetSortBy: false,
      } as any,
      useSortBy
    );

  return (
    <PortfolioTableWrapper
      ref={portfolioTableWrapperRef}
      stickyHeader
      isMobile={isMobile}
    >
      {loading || !histories?.length ? (
        <div>
          {loading ? (
            <SpinnerContainerWrapper>
              <Spinner />
            </SpinnerContainerWrapper>
          ) : (
            <EmptyContentContainer>
              {HIGH_LOAD_MODE ? t("high_load") : t("no_funding_history")}
            </EmptyContentContainer>
          )}
        </div>
      ) : (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  // eslint-disable-next-line react/jsx-key
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { ...rowProps } = row.getRowProps();
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...rowProps}>
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </PortfolioTableWrapper>
  );
}

export default FundingHistory;
