import styled from "styled-components";
import { SPACING } from "../../constants/design/spacing";
import { Button } from "../Buttons/styles";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING.three}px;
`;

export const StepButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: ${SPACING.three}px;
`;

export const StepButton = styled(Button)<{ direction: "left" | "right" }>`
  svg {
    transform: scaleX(${({ direction }) => (direction === "left" ? -1 : 1)});
  }
`;

export const Label = styled.span``;

export const TopStepButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: ${SPACING.two}px;
`;

export const BottomStepButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;
