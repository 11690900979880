import { SFXSettings } from "../../contexts/SettingsContext";
import audioOnIcon from "../../assets/svg/volume-on.svg";
import audioOffIcon from "../../assets/svg/volume-off.svg";
import { useSFX } from "../../hooks/useSFX";

interface IAudioPlayerProps {
  sfx: SFXSettings;
  isOn: boolean;
}

export function SettingsAudioPlayer({ sfx, isOn }: IAudioPlayerProps) {
  const { playSound } = useSFX();

  return (
    <button onClick={() => playSound(sfx, true)} type="button">
      <img src={isOn ? audioOnIcon : audioOffIcon} alt="audio-icon" />
    </button>
  );
}
