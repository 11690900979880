import { create } from "zustand";
import { AssetResponse } from "../../../../utils/asset";
import { mountZustandDevtool } from "../../../../utils/zustand";
import { IWSSIndexResponseData } from "../../model";

/*
 The initial state shapes what values we can have in our store.
 We can order them as we like or use multiple stores.
 For our game, I'll use only one store.

 Our server only sends the game state updates so that's almost all we need.
 We use the 'ready' state to know if we are connected to the server or not.
*/

interface IAssetToIndex {
  [asset: string]: IWSSIndexResponseData;
}

interface IIndexPriceStoreState {
  indexAssets: Set<string>;
  index: IAssetToIndex;
  
  // Mutations
  addIndexAsset: (asset: AssetResponse) => void;
  removeIndexAsset: (asset: AssetResponse) => void;
  updateIndex: (asset: AssetResponse, data: IWSSIndexResponseData) => void;
}

export const useIndexPriceStore = create<IIndexPriceStoreState>()((setState) => ({
  indexAssets: new Set(),
  addIndexAsset: (asset: AssetResponse) => setState((state) => {
    if (!state.indexAssets.has(asset)) {
      // Update if not already included
      const newIndexSet = new Set([
        ...Array.from(state.indexAssets),
        asset
      ])
      return {
        indexAssets: newIndexSet
      }
    }
    return state
  }),
  removeIndexAsset: (asset: string) => setState((state) => {
    if (state.indexAssets.has(asset)) {
      // Update if not already included
      const newIndexSet = new Set([
        ...Array.from(state.indexAssets),
      ].filter((a) => a !== asset))

      return {
        indexAssets: newIndexSet
      }
    }
    return state
  }),

  // DATA
  index: {},
  updateIndex: (asset: string, data: IWSSIndexResponseData) => setState(() => ({
    index: {
      [asset]: data
    }
  }))
}));


mountZustandDevtool("useIndexPriceStore", useIndexPriceStore)