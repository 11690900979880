import styled, { css } from "styled-components";
import {
  BACKGROUND_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { fadeInCSS } from "../../shared/style";

export const DateSelectionWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
  font-family: BananaGrotesk;
  height: ${COMPONENTS.dateSelectionHeight}px;
`;

export const AccordionWrapper = styled.div<{
  mobileMode?: boolean;
  padding?: string;
}>`
  z-index: 1;
  flex: 1;
  overflow-x: scroll;
  height: 100%;
  width: 10px;
  position: relative;

  // Add padding when overflowing
  padding: ${({ padding }) => padding || "0"};

  ${({ mobileMode }) => {
    if (mobileMode) {
      return fadeInCSS;
    }
    return "";
  }}

  > * {
    font-size: ${FONT_SIZE.one};
  }
`;

export const AccordionButton = styled.button<{ side: "left" | "right" }>`
  position: absolute;
  min-width: 48px;
  width: 48px;
  backdrop-filter: blur(1px);
  background-color: ${BACKGROUND_COLORS.thirteen};
  box-shadow: inset 2px 4px 40px rgba(255, 255, 255, 0.04);
  color: ${TEXT_COLORS.one};
  height: 100%;
  z-index: 1;
  transition: 0.2s;

  ${({ side }) => `${side}: 0;`};

  &:hover > svg {
    stroke: ${COLORS.blue.one};
  }
`;

export const MiniScrim = styled.div<{ align: "left" | "right" }>`
  position: absolute;
  top: 0;
  z-index: 1;
  background: linear-gradient(
    270deg,
    ${LAYER_COLORS.one} 0%,
    rgba(18, 18, 26, 0) 100%
  );
  pointer-events: none;

  width: 32px;
  height: 32px;

  ${({ align }) => {
    if (align === "left") {
      return `
      left: 0;
      transform: rotate(-180deg);
    `;
    }
    return css`
      right: calc(32px + 32px + ${SPACING.two + SPACING.two}px);
    `;
  }}
`;

export const PrevNextButton = styled.button`
  background-color: ${COLORS.white.five};
  border-radius: 10px;
  overflow: hidden;
  width: 32px;
  height: 32px;

  &:disabled {
    opacity: 0.64;
  }
`;

export const PaginationButtons = styled.div`
  display: flex;

  > ${PrevNextButton}:first-child {
    margin-left: ${SPACING.two}px;
    margin-right: ${SPACING.two}px;
  }
`;

export const PositionOrderIndicator = styled.div<{
  type: "position" | "order";
}>`
  background-color: ${({ type }) =>
    type === "position" ? COLORS.highlight.one : "transparent"};
  outline: ${({ type }) =>
    type === "order" ? `1px solid ${COLORS.highlight.one}` : "none"};
  width: 4px;
  height: 4px;
  border-radius: 4px;
  margin-right: ${SPACING.one}px;
`;
