import { useTranslation } from "react-i18next";
import {
  Padding,
  ReferralCodeText,
  RememberCheckbox,
  RememberMeTooltipWrapper,
} from "./style";

import { ReactComponent as Info } from "../../assets/svg/info.svg";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { useCheckReferral } from "../../hooks/api/referrals/useCheckReferral";
import { ID_TO_CHAINS } from "../../hooks/wallet/useWallet";
import { supportedChainId } from "../../utils/wallet/connectors";
import Checkbox from "../shared/Checkbox";
import { HeaderSubText } from "../shared/SharedModalStyles/styles";
import TooltipExplanation from "../shared/Tooltip";

interface IEnableTradingProps {
  isRememberMeChecked: boolean;
  setRememberMe: (remember: boolean) => void;
}

function EnableTrading({
  isRememberMeChecked,
  setRememberMe,
}: IEnableTradingProps) {
  const referralCode = window.localStorage.getItem(
    LocalStorageKeyEnum.REFERRAL_CODE
  );
  const { data: checkReferralData } = useCheckReferral();
  const { t } = useTranslation("app", {
    keyPrefix: "ConnectWalletModal.EnableTrading",
  });

  return (
    <>
      <HeaderSubText>
        {t("enable_trading_desc", {
          chain: ID_TO_CHAINS[supportedChainId],
        })}
        {checkReferralData?.is_referrable ? (
          <ReferralCodeText>
            {t("registering_with_refcode")} <span>{referralCode}</span>.
          </ReferralCodeText>
        ) : null}
      </HeaderSubText>
      <Padding>
        <RememberCheckbox>
          <Checkbox
            type="outline"
            title={t("remember_me")}
            checked={isRememberMeChecked}
            onToggle={() => setRememberMe(!isRememberMeChecked)}
          />
          <TooltipExplanation
            title={t("remember_me")}
            explanation={t("remember_me_desc")}
            renderContent={({ ref, ...triggerHandler }) => (
              <RememberMeTooltipWrapper ref={ref} {...triggerHandler}>
                <Info />
              </RememberMeTooltipWrapper>
            )}
          />
        </RememberCheckbox>
      </Padding>
    </>
  );
}
export default EnableTrading;
