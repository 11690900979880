import styled, { css } from "styled-components";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { Button } from "../../Buttons/styles";
import { SPACING } from "../../../constants/design/spacing";

export const Title = styled.div`
  svg {
    width: 32px;
    height: 32px;
    padding: ${SPACING.two}px;
    background-color: ${COLORS.neutral.five};
    border-radius: 100px;
    margin-right: ${SPACING.one}px;
  }
`;

export const Content = styled.div<{ center?: boolean }>`
  ${FONT_CSS.body.three}
  color: ${TEXT_COLORS.two};

  a {
    text-decoration: none;
    cursor: pointer;
    color: ${TEXT_COLORS.one};
  }

  ${Button} {
    margin-top: ${SPACING.three}px;
  }

  ${({ center }) =>
    center &&
    css`
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;

      ${Button} {
        margin-top: ${SPACING.two}px;

        svg {
          stroke: none;
        }
      }
    `}
`;

export const StepNumber = styled.div`
  ${FONT_CSS.data.five}
  margin-bottom: ${SPACING.two}px;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  border: 1px solid ${COLORS.neutral.one};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${TEXT_COLORS.one};
`;

export const StepContent = styled.span`
  ${FONT_CSS.body.five}
  color: ${TEXT_COLORS.two};

  strong {
    color: ${TEXT_COLORS.one};
  }
`;

export const StepConnector = styled.div`
  margin: ${SPACING.two}px;
  border: none;
  border-left: 2px dashed ${COLORS.neutral.three};
  height: 32px;
  width: 0px;
`;

export const ErrorText = styled.div`
  ${FONT_CSS.label.three}
  margin-top: ${SPACING.three}px;
  color: ${COLORS.negative.one};
`;
