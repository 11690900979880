import { useCallback, useState } from "react";
import { TFunction } from "react-i18next";
import AdjustIsolatedMarginModal from "../../../../components/ConfirmationModal/AdjustIsolatedMarginModal";
import PartialTPSLForPositionModal from "../../../../components/ConfirmationModal/PartialTPSLModalContent/PartialTPSLForPositionModal";
import TPSLForPositionModal from "../../../../components/ConfirmationModal/TPSLModalContent/TPSLForPositionModal";
import { PnLCardModal } from "../../../../components/PnLCardModal";
import { IModifiedPosition } from "../../../../components/Positions/model";
import PositionDetailsModal from "../../../../components/shared/ViewDetailsModal/PositionDetailsModal";
import { useGetAccount } from "../../../../hooks/api/account/useGetAccount";
import { useGetAccumulatedFundings } from "../../../../hooks/api/account/useGetAccumulatedFundings";
import { useRFQ } from "../../../../hooks/api/rfq/useRFQ";
import MobilePositionRow, {
  OnModifyPositionTypeEnum,
} from "./MobilePositionRow";

interface IMobilePositionsProps {
  positions: IModifiedPosition[];
  simplePositions: IModifiedPosition[];
  t: TFunction;
  noAnimation?: boolean;
}

enum ModalTypeEnum {
  Close = "close",
  Partial = "partial",
}

function MobilePositions({
  positions,
  simplePositions,
  t,
  noAnimation,
}: IMobilePositionsProps) {
  const { data: accountData } = useGetAccount();
  const { data: accumulatedFundingData } = useGetAccumulatedFundings();
  const { deleteRFQsByInstrumentId } = useRFQ(undefined, false);

  // NOTE: IDs here are referring the position's instrument_id
  const [shareModalPositionId, setShareModalPositionId] = useState<string>();
  const [closingPositionId, setClosingPositionId] = useState<string>();
  const [viewDetailsForPositionId, setViewDetailsForPositionId] =
    useState<string>();
  const [adjustingMarginPositionId, setAdjustingMarginPositionId] =
    useState<string>();
  const [tpslModalPositionId, setTpslModalPositionId] = useState<string>();
  const [partialTPSLModalId, setPartialTPSLModalId] = useState<string>();
  const [detailPartialTPSLModalId, setDetailPartialTPSLModalId] =
    useState<string>();

  const onClosePositionModalHide = useCallback(() => {
    if (closingPositionId) {
      deleteRFQsByInstrumentId(closingPositionId);
    }
    setClosingPositionId(undefined);
  }, [closingPositionId, deleteRFQsByInstrumentId]);

  const positionByInstrumentId = useCallback(
    (instrumentId?: string) => {
      if (!instrumentId) {
        return undefined;
      }

      return simplePositions.find(
        (position) => position.instrument_id === instrumentId
      );
    },
    [simplePositions]
  );

  const onAdjustMarginModalHide = useCallback(() => {
    setAdjustingMarginPositionId(undefined);
  }, []);

  const onPositionDetailsModalHide = useCallback(() => {
    setViewDetailsForPositionId(undefined);
  }, []);

  const onSharePositionModalHide = useCallback(() => {
    setShareModalPositionId(undefined);
  }, []);

  const onTPSLForPositionModalHide = useCallback(() => {
    setTpslModalPositionId(undefined);
  }, []);

  const onTPSLForPartialPositionModalHide = useCallback(() => {
    setPartialTPSLModalId(undefined);
  }, []);

  const onDetailPartialTPSLForPositionModalHide = useCallback(() => {
    setDetailPartialTPSLModalId(undefined);
  }, []);

  const onModifyPosition = useCallback(
    (type: OnModifyPositionTypeEnum, position: IModifiedPosition) => {
      switch (type) {
        case OnModifyPositionTypeEnum.TPSL_EDIT:
          setTpslModalPositionId(position.instrument_id);
          break;
        case OnModifyPositionTypeEnum.PARTIAL_TPSL_DETAIL:
          setDetailPartialTPSLModalId(position.instrument_id);
          break;
        case OnModifyPositionTypeEnum.PARTIAL_TPSL_ADD:
          setPartialTPSLModalId(position.instrument_id);
          break;
        case OnModifyPositionTypeEnum.EDIT:
          setAdjustingMarginPositionId(position.instrument_id);
          break;
        case OnModifyPositionTypeEnum.CLOSE:
          setClosingPositionId(position.instrument_id);
          break;
        case OnModifyPositionTypeEnum.VIEW_DETAIL:
          setViewDetailsForPositionId(position.instrument_id);
          break;
        case OnModifyPositionTypeEnum.SHARE:
          setShareModalPositionId(position.instrument_id);
          break;
      }
    },
    []
  );

  return (
    <>
      <AdjustIsolatedMarginModal
        position={positionByInstrumentId(adjustingMarginPositionId)}
        onHide={onAdjustMarginModalHide}
      />
      <PositionDetailsModal
        position={positionByInstrumentId(closingPositionId)}
        onHide={onClosePositionModalHide}
        accumulatedFundingData={accumulatedFundingData}
        isClosePosition
      />
      <PositionDetailsModal
        position={positionByInstrumentId(viewDetailsForPositionId)}
        onHide={onPositionDetailsModalHide}
        accumulatedFundingData={accumulatedFundingData}
      />
      <PnLCardModal
        pnlType="position"
        pnlData={positionByInstrumentId(shareModalPositionId)}
        onHideModal={onSharePositionModalHide}
        show={!!shareModalPositionId}
        onHidePnLCard={onSharePositionModalHide}
        hideBackButton
      />
      {!!tpslModalPositionId && (
        <TPSLForPositionModal
          position={positionByInstrumentId(tpslModalPositionId)}
          onHide={onTPSLForPositionModalHide}
          show={!!tpslModalPositionId}
          modalType={ModalTypeEnum.Close}
        />
      )}
      {!!partialTPSLModalId && (
        <TPSLForPositionModal
          onHide={onTPSLForPartialPositionModalHide}
          show={!!partialTPSLModalId}
          position={positionByInstrumentId(partialTPSLModalId)}
          modalType={ModalTypeEnum.Partial}
        />
      )}
      {!!detailPartialTPSLModalId && (
        <PartialTPSLForPositionModal
          onHide={onDetailPartialTPSLForPositionModalHide}
          show={!!detailPartialTPSLModalId}
          position={positionByInstrumentId(detailPartialTPSLModalId)}
        />
      )}
      <div>
        {positions.map((position, i) => (
          <MobilePositionRow
            key={`${position.instrument_id}-${position.asset}`}
            position={position}
            t={t}
            noAnimation={noAnimation}
            delay={positions.length > 10 ? 0 : i * 0.1}
            inLiquidation={!!accountData?.in_liquidation}
            onModifyPosition={onModifyPosition}
          />
        ))}
      </div>
    </>
  );
}

export default MobilePositions;
