import { CSSProperties } from "react";
import { DropDirection } from "react-bootstrap/esm/DropdownContext";
import { LAYER_COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";
import { Chevron } from "../Chevron/style";
import Dropdown from "../Dropdown";
import { DropdownItem, DropdownTitle } from "./style";

interface IDropdownSimpleProps<T extends string> {
  show: boolean;
  onToggle: (show: boolean) => void;
  title: string | JSX.Element;
  items: T[];
  getItemLabel?: (item: T) => string | JSX.Element;
  selectedItem?: T;
  onSelectItem: (item: T) => void;
  dropDirection?: DropDirection;
  toggleStyle?: CSSProperties;
}

function DropdownSimple<T extends string>({
  show,
  onToggle,
  title,
  items,
  getItemLabel,
  selectedItem,
  onSelectItem,
  dropDirection,
  toggleStyle,
}: IDropdownSimpleProps<T>) {
  return (
    <Dropdown
      dropDirection={dropDirection}
      onToggle={onToggle}
      toggleStyles={{
        backgroundColor: LAYER_COLORS.two,
        padding: SPACING.two,
        ...toggleStyle,
      }}
      dropdownMenuContainerStyles={{
        overflow: "hidden",
        borderRadius: "8px",
      }}
      title={
        typeof title === "string" ? (
          <DropdownTitle>
            <span>{title}</span>
            <Chevron size="small" direction={show ? "up" : "down"} />
          </DropdownTitle>
        ) : (
          title
        )
      }
      items={Object.values(items).map((v: T) => ({
        label: (
          <DropdownItem key={v} isSelected={v === selectedItem}>
            {getItemLabel ? getItemLabel(v) : v}
          </DropdownItem>
        ),
        onSelect: () => onSelectItem(v),
      }))}
    />
  );
}

export default DropdownSimple;
