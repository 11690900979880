import currency from "currency.js";
import { SPACING } from "../../../constants/design/spacing";
import {
  PartialPositionInput,
  StopLossInput,
  TakeProfitInput,
} from "../../TradeForm/PerpsTradeForm/components/form";
import { Form } from "../../TradeForm/style";
import DropdownSimple from "../../shared/DropdownSimple";
import {
  Tpsl,
  NotificationTypeEnum,
  TpslType,
  IPartialTPSLFormContentProps,
} from "./TPSLContent.types";
import TPSLNotificationText from "./TPSLNotificationText";

function TPSLPartialFormContent({
  actualSlTriggerPrice,
  actualTpTriggerPrice,
  contractPriceStep,
  minAmountSize,
  errors,
  handleSubmit,
  markPrice,
  onConfirmTPSLForPosition,
  position,
  pricePrecision,
  amountPrecision,
  resetSlInput,
  resetTpInput,
  slDropdownOpen,
  slType,
  stopLossExpectedPnl,
  stopLossRegister,
  takeProfitExpectedPnl,
  takeProfitRegister,
  tpDropdownOpen,
  tpType,
  setTpDropdownOpen,
  setTpType,
  setSlDropdownOpen,
  setSlType,
  partialTakeProfitPositionAmountRegister,
  partialStopLossPositionAmountRegister,
  positionTakeProfitSliderAmount,
  positionStopLossSliderAmount,
  setMaxPartialTPValue,
  setMaxPartialSLValue,
  onChangePartialTakeProfitSliderValue,
  onChangePartialStopLossSliderValue,
  currentTab,
}: IPartialTPSLFormContentProps) {
  const { amount, partialPositionTriggers } = position;

  return (
    <Form
      style={{ padding: 0, marginBottom: SPACING.three }}
      onSubmit={handleSubmit(onConfirmTPSLForPosition)}
    >
      {currentTab === NotificationTypeEnum.TP && (
        <>
          <TakeProfitInput
            key={tpType}
            placeholder={"0.00"}
            markPrice={currency(markPrice || 0, {
              precision: pricePrecision,
            }).format()}
            leftAccessory={
              <DropdownSimple<TpslType>
                dropDirection={"up"}
                show={tpDropdownOpen}
                onToggle={setTpDropdownOpen}
                title={tpType}
                items={Object.values(Tpsl)}
                selectedItem={tpType}
                onSelectItem={setTpType}
              />
            }
            minPrice={contractPriceStep}
            register={takeProfitRegister}
            resetInput={resetTpInput}
            errors={errors}
          />
          {partialTakeProfitPositionAmountRegister && (
            <PartialPositionInput
              errors={errors}
              inputRegister={partialTakeProfitPositionAmountRegister}
              placeholder={"0.00"}
              amount={amount}
              partialPositionTriggers={partialPositionTriggers!!}
              partialPositionType={NotificationTypeEnum.TP}
              setMaxPartialTPValue={setMaxPartialTPValue}
              onChangeSliderValue={onChangePartialTakeProfitSliderValue!!}
              positionAmount={positionTakeProfitSliderAmount || 0}
              minAmountSize={minAmountSize}
              amountPrecision={amountPrecision}
            />
          )}
          <TPSLNotificationText
            actualTriggerPrice={actualTpTriggerPrice}
            pricePrecision={pricePrecision}
            currencyExpectedPnl={takeProfitExpectedPnl}
            noticationType={NotificationTypeEnum.TP}
          />
        </>
      )}
      {currentTab === NotificationTypeEnum.SL && (
        <div>
          <StopLossInput
            key={slType}
            placeholder={"0.00"}
            markPrice={currency(markPrice || 0, {
              precision: pricePrecision,
            }).format()}
            leftAccessory={
              <DropdownSimple<TpslType>
                dropDirection={"up"}
                show={slDropdownOpen}
                onToggle={setSlDropdownOpen}
                title={slType}
                items={Object.values(Tpsl)}
                selectedItem={slType}
                onSelectItem={setSlType}
              />
            }
            minPrice={contractPriceStep}
            register={stopLossRegister}
            resetInput={resetSlInput}
            errors={errors}
          />
          {partialStopLossPositionAmountRegister && (
            <PartialPositionInput
              errors={errors}
              inputRegister={partialStopLossPositionAmountRegister}
              placeholder={"0.00"}
              amount={amount}
              partialPositionTriggers={partialPositionTriggers!!}
              partialPositionType={NotificationTypeEnum.SL}
              setMaxPartialSLValue={setMaxPartialSLValue}
              onChangeSliderValue={onChangePartialStopLossSliderValue!!}
              positionAmount={positionStopLossSliderAmount || 0}
              minAmountSize={minAmountSize}
              amountPrecision={amountPrecision}
            />
          )}
          <TPSLNotificationText
            actualTriggerPrice={actualSlTriggerPrice}
            pricePrecision={pricePrecision}
            currencyExpectedPnl={stopLossExpectedPnl}
            noticationType={NotificationTypeEnum.SL}
          />
        </div>
      )}
    </Form>
  );
}

export default TPSLPartialFormContent;
