import styled, { css } from "styled-components";

export const FONT_SIZE = {
  xxs: "9px",
  xs: "11px",
  one: "12px",
  oneHalf: "13px",
  two: "14px",
  three: "16px",
  four: "22px",
  five: "28px",
} as const;

export const FONT_STYLE = {
  header: {
    one: {
      fontSize: "120px",
      lineHeight: "120px",
    },
    two: {
      fontSize: "96px",
      lineHeight: "96px",
    },
    three: {
      fontSize: "48px",
      lineHeight: "56px",
    },
    four: {
      fontSize: "36px",
      lineHeight: "44px",
    },
    five: {
      fontSize: "22px",
      lineHeight: "28px",
    },
    six: {
      fontSize: "18px",
      lineHeight: "20px",
    },
    seven: {
      fontSize: "14px",
      lineHeight: "16px",
    },
    eight: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  body: {
    one: {
      fontSize: "18px",
      lineHeight: "24px",
    },
    two: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    three: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    four: {
      fontSize: "13px",
      lineHeight: "16px",
    },
    five: {
      fontSize: "12px",
      lineHeight: "16px",
    },
    six: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  data: {
    one: {
      fontSize: "28px",
      lineHeight: "32px",
    },
    two: {
      fontSize: "16px",
      lineHeight: "20px",
    },
    three: {
      fontSize: "12px",
      lineHeight: "16px",
    },
    four: {
      fontSize: "11px",
      lineHeight: "16px",
    },
    five: {
      fontSize: "9px",
      lineHeight: "12px",
    },
    six: {
      fontSize: "9px",
      lineHeight: "12px",
    },
  },
  label: {
    one: {
      fontSize: "13px",
      lineHeight: "16px",
    },
    two: {
      fontSize: "12px",
      lineHeight: "16px",
    },
    three: {
      fontSize: "11px",
      lineHeight: "12px",
    },
  },
} as const;

//  CSS
export const FONT_CSS = {
  header: {
    one: css`
      font-size: ${FONT_STYLE.header.one.fontSize};
      line-height: ${FONT_STYLE.header.one.lineHeight};
    `,
    two: css`
      font-size: ${FONT_STYLE.header.two.fontSize};
      line-height: ${FONT_STYLE.header.two.lineHeight};
    `,
    three: css`
      font-size: ${FONT_STYLE.header.three.fontSize};
      line-height: ${FONT_STYLE.header.three.lineHeight};
    `,
    four: css`
      font-size: ${FONT_STYLE.header.four.fontSize};
      line-height: ${FONT_STYLE.header.four.lineHeight};
    `,
    five: css`
      font-size: ${FONT_STYLE.header.five.fontSize};
      line-height: ${FONT_STYLE.header.five.lineHeight};
    `,
    six: css`
      font-size: ${FONT_STYLE.header.six.fontSize};
      line-height: ${FONT_STYLE.header.six.lineHeight};
    `,
    seven: css`
      font-size: ${FONT_STYLE.header.seven.fontSize};
      line-height: ${FONT_STYLE.header.seven.lineHeight};
    `,
    eight: css`
      font-size: ${FONT_STYLE.header.eight.fontSize};
      line-height: ${FONT_STYLE.header.eight.lineHeight};
    `,
  },
  body: {
    one: css`
      font-size: ${FONT_STYLE.body.one.fontSize};
      line-height: ${FONT_STYLE.body.one.lineHeight};
    `,
    two: css`
      font-size: ${FONT_STYLE.body.two.fontSize};
      line-height: ${FONT_STYLE.body.two.lineHeight};
    `,
    three: css`
      font-size: ${FONT_STYLE.body.three.fontSize};
      line-height: ${FONT_STYLE.body.three.lineHeight};
    `,
    four: css`
      font-size: ${FONT_STYLE.body.four.fontSize};
      line-height: ${FONT_STYLE.body.four.lineHeight};
    `,
    five: css`
      font-size: ${FONT_STYLE.body.five.fontSize};
      line-height: ${FONT_STYLE.body.five.lineHeight};
    `,
    six: css`
      font-size: ${FONT_STYLE.body.six.fontSize};
      line-height: ${FONT_STYLE.body.six.lineHeight};
    `,
  },
  data: {
    one: css`
      font-size: ${FONT_STYLE.data.one.fontSize};
      line-height: ${FONT_STYLE.data.one.lineHeight};
    `,
    two: css`
      font-size: ${FONT_STYLE.data.two.fontSize};
      line-height: ${FONT_STYLE.data.two.lineHeight};
    `,
    three: css`
      font-size: ${FONT_STYLE.data.three.fontSize};
      line-height: ${FONT_STYLE.data.three.lineHeight};
    `,
    four: css`
      font-size: ${FONT_STYLE.data.four.fontSize};
      line-height: ${FONT_STYLE.data.four.lineHeight};
    `,
    five: css`
      font-size: ${FONT_STYLE.data.five.fontSize};
      line-height: ${FONT_STYLE.data.five.lineHeight};
    `,
    six: css`
      font-size: ${FONT_STYLE.data.six.fontSize};
      line-height: ${FONT_STYLE.data.six.lineHeight};
    `,
  },
  label: {
    one: css`
      font-size: ${FONT_STYLE.label.one.fontSize};
      line-height: ${FONT_STYLE.label.one.lineHeight};
    `,
    two: css`
      font-size: ${FONT_STYLE.label.two.fontSize};
      line-height: ${FONT_STYLE.label.two.lineHeight};
    `,
    three: css`
      font-size: ${FONT_STYLE.label.three.fontSize};
      line-height: ${FONT_STYLE.label.three.lineHeight};
    `,
  },
} as const;

const getFontStyles = <T extends keyof typeof FONT_CSS>(
  type: T,
  number: keyof (typeof FONT_CSS)[T] // Ensure the `number` corresponds to the correct keys of the given type
) => FONT_CSS[type][number];

// Single generic styled component for headers
export const Header = styled.div<{
  number: keyof (typeof FONT_CSS)["header"];
  color?: string;
}>`
  ${({ number }) => getFontStyles("header", number)}
  ${({ color }) => color && `color: ${color};`}
`;

// Single generic styled component for body
export const Body = styled.div<{
  number: keyof (typeof FONT_CSS)["body"];
  color?: string;
}>`
  ${({ number }) => getFontStyles("body", number)}
  ${({ color }) => color && `color: ${color};`}
`;

// Single generic styled component for data
export const Data = styled.div<{
  number: keyof (typeof FONT_CSS)["data"];
  color?: string;
}>`
  ${({ number }) => getFontStyles("data", number)}
  ${({ color }) => color && `color: ${color};`}
`;

// Single generic styled component for labels
export const Label = styled.div<{
  number: keyof (typeof FONT_CSS)["label"];
  color?: string;
}>`
  ${({ number }) => getFontStyles("label", number)}
  ${({ color }) => color && `color: ${color};`}
`;
