import {
  GetAccount200ResponsePositionsInnerPartialPositionTriggersTakeProfitInner,
  SideResponse,
} from "../../../codegen-api";

export enum PartialTPSLTabEnum {
  All = "All",
  TakeProfit = "TakeProfit",
  StopLoss = "StopLoss",
}

export type PartialTPSLTabType =
  (typeof PartialTPSLTabEnum)[keyof typeof PartialTPSLTabEnum];

export interface IPartialTPSLModalContentProps {
  currentTab: PartialTPSLTabType;
  onSelectTab: (value: string) => void;
  onCancelOrder: (order: any) => void;
  positionTotalAmount: string | undefined;
  loadingOrderId: string;
  side: SideResponse;
  data: GetAccount200ResponsePositionsInnerPartialPositionTriggersTakeProfitInner[];
}

export interface IPartialTPSLModalTableContentProps
  extends GetAccount200ResponsePositionsInnerPartialPositionTriggersTakeProfitInner {
  no: number;
  side: SideResponse;
}

export enum TPSLEnum {
  STOP_LOSS = 0,
  TAKE_PROFIT = 1,
}
