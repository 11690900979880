import styled, { css } from "styled-components";
import { COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { SPACING, BORDER_RADIUS } from "../../../constants/design/spacing";

export const ChipWrapper = styled.span<{ theme: "green" | "blue" | "red" }>`
  font-size: ${FONT_SIZE.xs};
  margin-left: ${SPACING.one}px;
  padding: ${SPACING.one}px ${SPACING.two}px;
  border-radius: ${BORDER_RADIUS.two}px;

  ${({ theme }) => {
    switch (theme) {
      case "green":
        return css`
          background-color: ${COLORS.positive.five} !important;
          color: ${COLORS.positive.one} !important;
        `;
      case "blue":
        return css`
          background-color: ${COLORS.blue.two} !important;
          color: ${COLORS.blue.two} !important;
        `;
      case "red":
        return css`
          background-color: ${COLORS.negative.five} !important;
          color: ${COLORS.negative.one} !important;
        `;
      default:
        return "";
    }
  }}
`;
