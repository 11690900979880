import { httpClientIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import buffer from "buffer";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import App from "./App";
import "./i18n/config";
import { ModalPortal } from "./portal";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { GlobalStyle } from "./style";
import { isProduction } from "./utils/env";
import { initMixpanel } from "./utils/mixpanel";

window.Buffer = window.Buffer || buffer.Buffer;

// Remove console log in production
if (isProduction() && process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

if (
  process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === "production" &&
  process.env.REACT_APP_NODE_ENV !== "test"
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_VERCEL_GIT_COMMIT_REF,

    // The release tag will be the commit sha that is set by vercel automatically
    release: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,

    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      httpClientIntegration({
        // This array can contain tuples of `[begin, end]` (both inclusive),
        // single status codes, or a combination of both.
        // default: [[500, 599]]
        failedRequestStatusCodes: [502, 500],

        // This array can contain Regexes, strings, or a combination of both.
        // default: [/.*/]
        failedRequestTargets: [/.*\.aevo.xyz$/],
      }),
    ],
  });
}

// GA + Mix Panel Tracking
const TRACKING_DEBUG_OVERRIDE = false;
if (
  (process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === "production" ||
    TRACKING_DEBUG_OVERRIDE) &&
  process.env.REACT_APP_NODE_ENV !== "test"
) {
  // Google analytics
  ReactGA.initialize("G-LZQXV4SKMM", {
    // Only for testing
    gaOptions: {
      debug_mode: TRACKING_DEBUG_OVERRIDE,
    },
    gtagOptions: {
      debug_mode: TRACKING_DEBUG_OVERRIDE,
    },
  });
}

// Mixpanel
initMixpanel(TRACKING_DEBUG_OVERRIDE);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
    <ModalPortal />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
