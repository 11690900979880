/* eslint-disable no-nested-ternary */
import styled, { css } from "styled-components";
import {
  TEXT_COLORS,
  ICON_COLORS,
  COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";

// Action button used in a table row for cancelling an order, modifying a position, etc.
export const RowActionButtonWrapper = styled.button<{
  isText?: boolean;
  isPartial?: boolean;
}>`
  ${({ isText, isPartial }) =>
    isText
      ? css`
          ${FONT_CSS.body.four}
          color: ${TEXT_COLORS.one};
          width: 80px;
          height: 32px;

          &:disabled {
            color: ${TEXT_COLORS.two};
          }
        `
      : isPartial
      ? css`
          ${FONT_CSS.body.four}
          color: ${TEXT_COLORS.two};
          width: 80px;
          height: 32px;
          gap: ${SPACING.two}px;

          &:hover {
            color: ${COLORS.blue.one};
          }
          &:disabled {
            color: ${TEXT_COLORS.two};
          }
        `
      : css`
          width: ${SPACING.five}px;
          height: ${SPACING.five}px;
        `}
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${SPACING.two}px;
`;

export const ButtonWithBackground = styled(RowActionButtonWrapper)<{
  fillOnly?: boolean;
  svgColor: string;
  svgBgColor: string;
  disabled?: boolean;
}>`
  background-color: ${({ svgBgColor }) => svgBgColor};
  ${({ disabled }) => !!disabled && "opacity: 0.3;"}
  svg {
    ${({ fillOnly }) =>
      fillOnly
        ? css`
            fill: ${ICON_COLORS.two};
          `
        : css`
            stroke: ${ICON_COLORS.two};
          `}
  }
  &:hover {
    svg {
    ${({ fillOnly, svgColor, disabled }) =>
      !disabled
        ? fillOnly
          ? css`
              fill: ${svgColor};
            `
          : css`
              stroke: ${svgColor};
            `
        : ""}
  }
`;

export const PartialButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING.two}px;
`;
