import styled, { css } from "styled-components";
import { SelectWrapper } from "../../../components/shared/Select/style";
import { ICON_COLORS, LAYER_COLORS } from "../../../constants/design/colors";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../constants/design/spacing";

export const Container = styled.div<{
  isMobileScreen?: boolean;
}>`
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  height: 100%;

  ${({ isMobileScreen }) =>
    isMobileScreen &&
    css`
      display: grid;
      grid-template-rows: ${COMPONENTS.header.sub}px 1fr;
    `}
`;

export const OptionsChainSection = styled.div`
  overflow: hidden;
  height: 100%;
  position: relative;

  > ${SelectWrapper} {
    margin: ${SPACING.three}px;
  }
`;

export const DropdownWrapper = styled.div`
  z-index: 5;
  display: flex;
`;

export const Dropdown = styled.div`
  flex: 1;
  margin: 0 ${SPACING.two}px;
`;

export const MobileSettingsButton = styled.button.attrs({
  type: "button",
})`
  background: ${LAYER_COLORS.one};
  border-radius: ${BORDER_RADIUS.five}px;
  margin-right: ${SPACING.two}px;
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  height: ${COMPONENTS.header.sub}px;
  width: ${COMPONENTS.header.sub}px;

  svg {
    width: 16px;
    height: 16px;
    stroke: ${ICON_COLORS.two};
  }
  &:hover {
    svg {
      stroke: ${ICON_COLORS.one} !important;
    }
  }
`;

export const HeaderWrapper = styled.div`
  flex: 1;
  margin: 0 ${SPACING.two}px;
`;

export const MobileTradingTabsWrapper = styled.div`
  background: ${LAYER_COLORS.one};
  border-top-left-radius: ${BORDER_RADIUS.five}px;
  border-top-right-radius: ${BORDER_RADIUS.five}px;
  padding: ${SPACING.two}px;
`;
