import useSWR from "swr";
import { useContext } from "react";
import { GetAccountStats200Response } from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { authApi } from "../../../services/api/apiFetcher";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";
import { AuthContext } from "../../../contexts/AuthContext";
import { AssetResponse } from "../../../utils/asset";

export interface IGetAccountStatisticsProps {
  startTime?: number;
  endTime?: number;
  asset?: AssetResponse;
  campaignName?: string;
}

export const useGetAccountStatistics = ({
  startTime,
  endTime,
  asset,
  campaignName,
}: IGetAccountStatisticsProps) => {
  const { apiKey, apiSecret } = useContext(AuthContext);
  const fetcher = authApi(apiKey, apiSecret);

  // Check if startTime is valid (less than current time)
  const isStartTimeValid = !startTime || startTime / 1_000_000 < Date.now(); // converting current time to nanoseconds

  const swr = useSWR<GetAccountStats200Response, Error>(
    // If apiKey is not available, the request will not fetch
    apiKey && isStartTimeValid
      ? [
          APIEndpointEnum.ACCOUNT_STATISTICS,
          asset,
          startTime,
          endTime,
          campaignName,
          apiKey,
        ]
      : undefined,
    async () => {
      const accountStatistics = (
        await (
          await fetcher.getAccountStats(startTime, endTime, asset, campaignName)
        )()
      ).data;
      return {
        ...accountStatistics,
      };
    },
    {
      use: [useLoggerMiddleware],
    }
  );

  return swr;
};
