import { useTranslation } from "react-i18next";
import pendingDepositVideo from "../../../assets/videos/pending.mp4";
import { ReactComponent as Arrow } from "../../../assets/svg/arrow-up-right.svg";
import { BaseModal, IBaseModalProps } from "../../BaseModal";
import { Container, PendingVideo, ViewTransactionLabel } from "./style";

interface IDepositPendingModalProps extends IBaseModalProps {
  pendingDepositTxURL?: string;
}

function DepositPendingModal({
  pendingDepositTxURL,
  ...others
}: IDepositPendingModalProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "shared.DepositPendingModal",
  });
  return (
    <BaseModal animateTitle noContentPadding {...others}>
      <Container
        style={{
          width: 300,
          height: 348,
        }}
      >
        <PendingVideo autoPlay loop muted>
          <source src={pendingDepositVideo} type="video/mp4" />
        </PendingVideo>
        <ViewTransactionLabel
          href={pendingDepositTxURL}
          target="_blank"
          rel="noreferrer"
        >
          {t("view_transaction")} <Arrow />
        </ViewTransactionLabel>
      </Container>
    </BaseModal>
  );
}

export default DepositPendingModal;
