import { AxiosError } from "axios";
import useSWR from "swr";
import {
  GenericErrorResponse,
  GetOptionsHistory200Response,
  OptionTypeResponse,
} from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { publicApi } from "../../../services/api/apiFetcher";
import { pollingInterval } from "../../../services/api/pollingInterval";
import { AssetResponse } from "../../../utils/asset";
import { TimePeriodEnum } from "../../../interfaces/TimePeriod";
import { getStartTimeSeconds } from "../../../utils/date";

export const useOptionsHistory = (
  asset: AssetResponse,
  optionType?: OptionTypeResponse,
  offset?: number,
  limit?: number,
  period: TimePeriodEnum = TimePeriodEnum.WEEKLY
) => {
  const fetcher = publicApi();

  const swr = useSWR<
    GetOptionsHistory200Response[],
    AxiosError<GenericErrorResponse>
  >(
    [APIEndpointEnum.OPTIONS_HISTORY, asset, limit, optionType, period],
    async () => {
      const start = getStartTimeSeconds(period);
      return (
        await (
          await fetcher.getOptionsHistory(
            asset,
            start,
            undefined,
            optionType,
            offset,
            limit
          )
        )()
      ).data;
    },
    {
      // TODO: - Fix this by using immutable swr, and update through trade ws instead
      refreshInterval: pollingInterval[APIEndpointEnum.OPTIONS_HISTORY],
    }
  );

  return swr;
};
