import { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { LinkText } from "../Header/style";
import {
  AnnouncementBanner,
  CloseButton,
  LeftContent,
  PWAAnnouncementContent,
  Subtitle,
  Title,
} from "./style";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import png from "../../../assets/png/pwa.png";
import { usePWAFlow } from "../../../hooks/usePWAFlow";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";
import DesktopPWAModal from "../PWAFlowModal/DesktopPWAModal";
import MobilePWAModal from "../PWAFlowModal/MobilePWAModal";

function AnnouncementCloseButton({ onClose }: any) {
  return (
    <CloseButton onClick={onClose}>
      <Close />
    </CloseButton>
  );
}

export function PWAAnnouncement() {
  const announcementKey = "pwa";
  const [showPWAFlow, setShowPWAFlow] = useState<boolean>(false);
  const { isMobileScreen } = useScreenSize();
  const [hideAnnouncement, setHideAnnouncement] = useState<boolean>(false);
  const { t } = useTranslation("app", { keyPrefix: "shared.PWAAnnouncement" });

  const { isPwaPath } = usePWAFlow();

  const setHideCallback = useCallback(() => {
    localStorage.setItem(announcementKey, "true");
    setHideAnnouncement(true);
  }, [announcementKey]);

  useEffect(() => {
    setHideAnnouncement(Boolean(localStorage.getItem(announcementKey)));
  }, [announcementKey]);

  useEffect(() => {
    if (isPwaPath) {
      setHideCallback();
    }
  }, [isPwaPath, setHideCallback]);

  // change this callback when the announcement type changes
  const onAnnouncementActionClick = useCallback(() => {
    setHideCallback();
    setShowPWAFlow(true);
  }, [setHideCallback, setShowPWAFlow]);

  const textColor = TEXT_COLORS.one;

  const banner = useMemo(
    () => (
      <AnnouncementBanner>
        <img src={png} alt={png} />
        <PWAAnnouncementContent>
          <LeftContent>
            <Title>{t("title")}</Title>
            <Subtitle>{t("subtitle")}</Subtitle>
            <LinkText
              style={{
                color: textColor,
                textDecoration: "underline",
                fontSize: FONT_SIZE.one,
              }}
              onClick={onAnnouncementActionClick}
            >
              {t("action_title")}
            </LinkText>
          </LeftContent>
          <AnnouncementCloseButton onClose={() => setHideCallback()} />
        </PWAAnnouncementContent>
      </AnnouncementBanner>
    ),
    [onAnnouncementActionClick, setHideCallback, t, textColor]
  );

  return (
    <>
      <DesktopPWAModal
        show={!isMobileScreen && showPWAFlow}
        onHide={() => setShowPWAFlow(false)}
      />
      <MobilePWAModal
        show={isMobileScreen && showPWAFlow}
        onHide={() => setShowPWAFlow(false)}
      />
      {!hideAnnouncement && banner}
    </>
  );
}
