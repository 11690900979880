import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { CollateralAssetResponse } from "../../../codegen-api";
import Intro from "./Intro";
import { ONCHAIN_ESTIMATES } from "../../../constants/onchainEstimates";
import { useChangeText } from "../../../hooks/useChangeText";
import DepositPendingModal from "../../shared/DepositPendingModal";
import EarnForm from "./EarnForm";
import { BaseModal } from "../../BaseModal";
import { getAssetLogo } from "../../../utils/asset/assets";
import { AssetTitleContainer, PausedText, TitleTextContainer } from "./style";
import { LocalStorageKeyEnum } from "../../../enums/localStorage";
import { useYieldVault } from "../../../hooks/api/yieldVault/useYieldVault";

export enum EarnPageEnum {
  INTRO = 0,
  DEPOSIT = 1,
}

interface IDepositWithdrawModalProps {
  show?: boolean;
  onHide?: () => void;
}

function EarnModal({ show, onHide }: IDepositWithdrawModalProps) {
  const [pageIndex, setPageIndex] = useState(0);
  const { t } = useTranslation("app", {
    keyPrefix: "DepositWithdrawModal.EarnModal",
  });
  const { data: yieldVaultData } = useYieldVault();
  const assetToReceive = CollateralAssetResponse.AeUsd;

  const onHideModal = useCallback(() => {
    onHide?.();
    setPageIndex(0);
  }, [onHide]);

  const [pendingDepositTxURL, setPendingDepositTxURL] = useState<
    string | undefined
  >();

  const pendingDepositTitles = useMemo(
    () => [
      t("initiating_deposit"),
      t("eta", {
        time: `${ONCHAIN_ESTIMATES.deposit.value} ${ONCHAIN_ESTIMATES.deposit.unit}`,
      }),
    ],
    [t]
  );

  const pendingDepositTitle = useChangeText(
    pendingDepositTitles,
    5000,
    !!pendingDepositTxURL
  );

  const content = useMemo(() => {
    const showIntro = window.localStorage.getItem(
      LocalStorageKeyEnum.SHOW_EARN_INTRO
    );
    if (showIntro) {
      return (
        <EarnForm
          onHide={onHideModal}
          setPendingDepositTxURL={setPendingDepositTxURL}
        />
      );
    }
    switch (pageIndex) {
      case EarnPageEnum.INTRO:
        return <Intro setPageIndex={setPageIndex} />;
      case EarnPageEnum.DEPOSIT:
        return (
          <EarnForm
            onHide={onHideModal}
            setPendingDepositTxURL={setPendingDepositTxURL}
          />
        );
    }
    return undefined;
  }, [onHideModal, pageIndex]);

  const modalTitle = useMemo(
    () => (
      <AssetTitleContainer>
        <img src={getAssetLogo(assetToReceive)} alt={assetToReceive} />
        <TitleTextContainer>
          <span style={{ textTransform: "none" }}>
            {pageIndex === EarnPageEnum.INTRO ? assetToReceive : t("deposit")}
          </span>
          {yieldVaultData?.is_paused && <PausedText>Vault Paused</PausedText>}
        </TitleTextContainer>
      </AssetTitleContainer>
    ),
    [assetToReceive, pageIndex, t, yieldVaultData?.is_paused]
  );

  if (pendingDepositTxURL) {
    return (
      <DepositPendingModal
        onHide={onHideModal}
        show={show}
        title={pendingDepositTitle}
        pendingDepositTxURL={pendingDepositTxURL}
      />
    );
  }

  return (
    <BaseModal
      show={show}
      onHide={onHide}
      style={{ width: 311 }}
      title={modalTitle}
    >
      <AnimatePresence>{content}</AnimatePresence>
    </BaseModal>
  );
}

export default EarnModal;
