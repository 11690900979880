import currency from "currency.js";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import useIndexWSS from "../../../../hooks/wss/useIndexWSS";
import { InfoRow, Title, Value } from "../../style";
import { AssetResponse } from "../../../../utils/asset";
import { calculateOrderFee } from "../../../../utils/math";

interface IFeeInfoProps {
  asset?: AssetResponse;
  size: number;
  // Amount % of fee
  feeStructure: number;
  markPrice?: number;
}

function FeeInfo({ size, feeStructure, markPrice, asset }: IFeeInfoProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.OptionsTradeForm.FeeInfo",
  });
  const { index: indexPrice } = useIndexWSS(asset);
  const notional = size * Number(indexPrice?.price || 0);

  const fee = calculateOrderFee(feeStructure, notional, markPrice);

  // eslint-disable-next-line no-nested-ternary
  const display = fee
    ? fee < 0.01
      ? `< ${currency(0.01).format()}`
      : currency(fee).format()
    : "---";

  return (
    <InfoRow>
      <Title>{t("fees")}</Title>
      <Value>{display}</Value>
    </InfoRow>
  );
}

export default memo(FeeInfo);
