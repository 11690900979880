import { SideResponse } from "../../../../codegen-api";
import { COLORS } from "../../../../constants/design/colors";
import { formatSizePrecision } from "../../../../utils/format";
import { Cell } from "../../style";

interface IPositionCellProps {
  position: number;
  side: SideResponse;
  align?: "left" | "center" | "right";
}

function PositionCell({ position, side, align }: IPositionCellProps) {
  return (
    <Cell
      align={align}
      style={{
        color: side === SideResponse.Sell ? COLORS.negative.one : COLORS.positive.one,
      }}
    >
      {position !== 0 && `${side === SideResponse.Sell ? "-" : ""}${formatSizePrecision(position)}`}
    </Cell>
  );
}

export default PositionCell;
