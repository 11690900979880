import styled from "styled-components";
import {
  BORDER_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";
import { FONT_CSS } from "../../../constants/design/fontSize";

export const ToggleContainer = styled.div<{ isOn?: boolean }>`
  ${FONT_CSS.label.three}
  border-radius: 10px;
  background-color: ${({ isOn }) =>
    isOn ? COLORS.blue.six : LAYER_COLORS.three};
  border: 1px solid ${({ isOn }) => (isOn ? COLORS.blue.one : "transparent")};
  color: ${({ isOn }) => (isOn ? COLORS.blue.one : TEXT_COLORS.three)};
  height: 32px;
  width: 64px;
  padding: ${SPACING.twoHalf}px;

  transition: all 0.2s ease-in-out;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

export const SwitchLine = styled.div<{ isOn?: boolean }>`
  transition: all 0.2s ease-in-out;
  position: absolute;
  transform: ${({ isOn }) => (isOn ? "translateX(0)" : "translateX(100%)")};
  height: 8px;
  width: 100%;
  border-left: 1px solid
    ${({ isOn }) => (isOn ? COLORS.blue.one : BORDER_COLORS.three)};
`;

export const SwitchLabel = styled.div<{ isOn?: boolean }>`
  transition: transform 0.2s ease-in-out;
  position: absolute;
  width: 100%;
  text-align: left;
  transform: ${({ isOn }) => (isOn ? "translateX(60%)" : "translateX(0)")};
`;
