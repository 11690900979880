import moment from "moment";
import { BigNumber, ethers } from "ethers";
import Decimal from "decimal.js";
import {
  GetEmissions200Response,
  GetFarmBoost200Response,
  GetFarmBoost200ResponseAevoEarnedPerEpochInner,
  SideResponse,
} from "../codegen-api";
import { IEpochStakeData } from "../hooks/api/subgraph/useSubgraphData";
import stakingRewardsEpoch10Json from "../constants/epoch10Rewards/staking-rewards-epoch-10-only.json";

interface IStakingRewardsEpoch10 {
  [key: string]: string;
}

const stakingRewardsEpoch10 =
  stakingRewardsEpoch10Json as IStakingRewardsEpoch10;

export const getRange = (start: number, stop: number, step: number = 1) => {
  const a = [start];
  let b = start;
  while (b < stop) {
    a.push((b += step));
  }
  return a;
};

// initialMargin in percentage. 100 = 100%, 1 = 1%
export const initialMarginUtilization = (
  equity: number,
  initialMargin: number,
  maintenanceMargin: number
) => {
  if (!equity) {
    return 0;
  }
  const utilization = ((initialMargin + maintenanceMargin) / equity) * 100;
  return utilization;
};

// maintenanceMargin in percentage. 100 = 100%, 1 = 1%
export const maintenanceMarginUtilization = (
  equity: number,
  maintenanceMargin: number
) => {
  if (!equity) {
    return 0;
  }
  const utilization = (maintenanceMargin / equity) * 100;
  return utilization;
};

// Returns the change from num1 -> num2
export const calculateChange = (num1: number, num2: number) => {
  let diff = 0;
  let percentageChange = 0;

  if (num1 && num2) {
    diff = num2 - num1;
    percentageChange = diff / num1;
  }

  return {
    diff,
    percentageChange,
  };
};

export const formatCompactCurrency = (number: number) => {
  const opt: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    notation: "compact",
    compactDisplay: "short",
    currencyDisplay: "narrowSymbol",
    style: "currency",
    currency: "USD",
  };
  return Intl.NumberFormat("en-US", opt).format(number);
};

export const calculatePnl = (
  totalEntryValue: number,
  totalExitValue: number,
  side: SideResponse
) =>
  side === SideResponse.Buy
    ? totalExitValue - totalEntryValue
    : totalEntryValue - totalExitValue;

export const calculatePnlPercent = (
  pnl: number,
  totalEntryValue: number,
  maintenanceMargin: number
) => (maintenanceMargin ? pnl / maintenanceMargin : pnl / totalEntryValue);

export const calculateExitPriceFromRoi = (
  totalEntryValue: number,
  roi: number, // 50% = 50
  maintenanceMargin: number,
  side: SideResponse,
  amount: number
): number => {
  if (amount === 0) {
    return 0;
  }
  const pnl = maintenanceMargin
    ? roi * maintenanceMargin
    : roi * totalEntryValue;

  if (side === SideResponse.Buy) {
    return Number((pnl / amount + totalEntryValue).toFixed(6));
  }
  return Number((totalEntryValue - pnl / amount).toFixed(6));
};

export const calculatePositionLeverage = (
  avgEntryPrice: number,
  amount: number,
  accountEquity: number
) => (accountEquity ? (avgEntryPrice * amount) / accountEquity : 0);

export const getPercent = (num: number, total: number) => {
  const percent = num / total;
  return Math.max(0, Math.min(percent, 1));
};

export const countDecimalPlaces = (number: number) =>
  new Decimal(number).decimalPlaces();

/**
 * Returns the number rounded to the nearest interval.
 * Example:
 *
 *   roundToNearest(1000.5, 1); // 1000
 *   roundToNearest(1000.5, 0.5);  // 1000.5
 */
export const roundToNearest = (
  value: number,
  interval: number,
  roundUp?: boolean
) => {
  let roundingMode = roundUp ? Decimal.ROUND_UP : Decimal.ROUND_DOWN;
  const decVal = new Decimal(value);

  // If round up + negative number, round DOWN.
  // This is because decimals.js rounding ignores the sign (+/-)
  if (decVal.isNegative()) {
    roundingMode = roundUp ? Decimal.ROUND_DOWN : Decimal.ROUND_UP;
  }

  const roundedToNearest = decVal.toNearest(
    new Decimal(interval),
    roundingMode
  );
  return roundedToNearest.toNumber();
};

export const xScalarMultiplier = 500000;

export function calculateFarmBoostMultiplier(volume: number): number {
  // Constants
  const t = 0.936;
  const x0 = 3.2;
  const D = 1.2;
  const z = 0.092123;
  const p = 3.0796;
  const x = volume / xScalarMultiplier;

  if (x <= 0) {
    return 1;
  }
  // Check the range of x and apply the corresponding formula
  if (x > 0 && x < 4.562) {
    // Calculate the value based on the first equation
    const exponent = -D * (x - x0);
    const formulaValue = t + (4 - t) / (1 + Math.exp(exponent));
    return formulaValue;
  }
  if (x >= 4.562 && x < 10) {
    // Apply the second equation directly
    return Math.min(4, z * x + p);
  }

  // x is outside the defined ranges
  return 4;
}

export function getRoundedBoost(farmBoost: number) {
  let roundedFarmBoost = 5;

  if (farmBoost >= 25) {
    roundedFarmBoost = 25;
  } else if (farmBoost >= 10) {
    roundedFarmBoost = 10;
  }
  return roundedFarmBoost;
}

export function getNextEpochEndDate() {
  let nextWednesday = moment
    .utc()
    .startOf("week")
    .add(2, "days")
    .hour(8)
    .minute(0)
    .second(0);

  if (moment.utc().isAfter(nextWednesday)) {
    nextWednesday = nextWednesday.add(1, "week");
  }

  return nextWednesday;
}

export const totalTradingEmissions = 110000000;
export const totalStakingEmissions = 20000000;
export const epoch0BoostedVolume = 3000000000;
export const tradingRewardsStartTimeNano = "1710316800000000000";

export const getCurrentEpoch = (timestampNano?: number): number => {
  // Define the start date (1 March 2024 00:00 UTC) in milliseconds
  const startDate = new Date("2024-03-13T08:00:00Z").getTime();

  const startNano = startDate * 1e6;

  const epochDurationNano = 7 * 24 * 60 * 60 * 1e9;

  const elapsedNano = (timestampNano ?? Date.now() * 1e6) - startNano;

  // Add one to start the count from 1 instead of 0
  const currentEpoch = Math.floor(elapsedNano / epochDurationNano) + 1;

  if (currentEpoch < 1) {
    return 1;
  }

  return currentEpoch;
};

export const getLatestEpochUnstakable = (): number =>
  Math.max(getCurrentEpoch() - 9, 1); // latest unstakable has be be >= 1

export const calculatePercentageOfWeekDone = (): number => {
  // A function to find the start of the current week, assuming weeks start on Wednesday at 08:00 UTC
  const findStartOfCurrentWeek = (): Date => {
    const now = new Date();
    const dayOfWeek = now.getUTCDay(); // Sunday - 0, Monday - 1, ..., Saturday - 6
    const wednesday = 3; // Wednesday is the 3rd day of the week in this context (starting from Sunday)
    let daysToSubtract = (dayOfWeek - wednesday + 7) % 7 || 7; // Calculate the difference in days

    // Adjust if we are past Wednesday 8:00 UTC or exactly at Wednesday
    if (dayOfWeek === wednesday && now.getUTCHours() >= 8) {
      daysToSubtract = 0;
    }

    // Calculate the start of the week by subtracting the days
    const startOfWeek = new Date(now);
    startOfWeek.setUTCDate(now.getUTCDate() - daysToSubtract);
    startOfWeek.setUTCHours(8, 0, 0, 0); // Set to Wednesday at 08:00 UTC

    return startOfWeek;
  };

  // Get the current date and time
  const now = new Date();

  // Get the start of the current week
  const startOfWeek = findStartOfCurrentWeek();

  // Calculate the duration of one week in milliseconds
  const oneWeekMilliseconds = 7 * 24 * 60 * 60 * 1000;

  // Calculate the elapsed time in milliseconds from the start of the week
  const elapsedMilliseconds = now.getTime() - startOfWeek.getTime();

  // If the current time is before the start of the week, return 0%
  if (elapsedMilliseconds < 0) {
    return 0;
  }

  // Calculate the percentage of the week completed
  const percentageOfWeekDone = elapsedMilliseconds / oneWeekMilliseconds;

  // Return the percentage of the week completed, rounded to two decimal places
  return percentageOfWeekDone;
};

export function getTotalStakedAmountAtEpoch(
  epochStakeAmounts: IEpochStakeData[],
  targetEpoch: number
): number {
  // Filter the array to include only the epochs in the range [targetEpoch-8, targetEpoch]
  const filteredEpochs = epochStakeAmounts.filter(
    (item) => item.epoch <= targetEpoch && item.unstakeEpoch > targetEpoch
  );
  // Sum the stakedAmounts of the filtered epochs
  const totalStakedAmount = filteredEpochs.reduce(
    (sum, current) => sum + parseInt(current.stakedAmount, 10),
    0
  );

  return totalStakedAmount;
}

export function getTotalStakedAmountAtEpochForVault(
  epochStakeAmounts: IEpochStakeData[],
  targetEpoch: number
): number {
  // Filter the array to include only the epochs in the range [targetEpoch-8, targetEpoch]
  const filteredEpochs = epochStakeAmounts.filter(
    (item) => item.epoch <= targetEpoch && item.unstakeEpoch > targetEpoch
  );
  // Sum the stakedAmounts and subtract the unstakedAmounts of the filtered epochs,
  const totalStakedAmount = filteredEpochs.reduce(
    (sum, current) =>
      sum +
      parseInt(current.stakedAmount, 10) -
      parseInt(current.unstakedAmount, 10),
    0
  );

  return totalStakedAmount;
}

export const calculateStakingRewardsAndHistory = (
  accountStakingData: IEpochStakeData[],
  vaultStakingData: IEpochStakeData[],
  emissions: GetEmissions200Response[],
  account: string,
  epoch?: number,
  weekComplete?: boolean
): {
  confirmedRewards: number;
  rewardsHistoryArray: GetFarmBoost200ResponseAevoEarnedPerEpochInner[];
} => {
  const currentEpoch = epoch ?? getCurrentEpoch();
  const totalEpochs = currentEpoch;
  let confirmedRewards = 0;
  const rewardsHistoryArray: GetFarmBoost200ResponseAevoEarnedPerEpochInner[] =
    [];

  emissions.forEach((emission, index) => {
    const epochFromIndex = index + 1; // since emissions[0] is epoch 1

    let reward = 0;

    // for epoch 10 we read the rewards from json file
    if (epochFromIndex === 10) {
      reward = stakingRewardsEpoch10[account]
        ? Number(ethers.utils.formatUnits(stakingRewardsEpoch10[account], 18))
        : 0;
    } else {
      const accountEpochStakedAmount = getTotalStakedAmountAtEpoch(
        accountStakingData,
        epochFromIndex
      ); // total compounded staked amount for account
      const vaultEpochStakedAmount = getTotalStakedAmountAtEpochForVault(
        vaultStakingData,
        epochFromIndex
      ); // total compounded staked amount for vault

      if (vaultEpochStakedAmount > 0 && epochFromIndex <= currentEpoch) {
        const stakedPercentage =
          accountEpochStakedAmount / vaultEpochStakedAmount;
        const epochProgressPercentage =
          epochFromIndex === currentEpoch && !weekComplete
            ? calculatePercentageOfWeekDone()
            : 1;
        const epochTotalStakingEmission =
          (Number(emission.staking_emission) / 100) * totalStakingEmissions;
        reward =
          epochProgressPercentage *
          stakedPercentage *
          epochTotalStakingEmission;
      }
    }

    // Add to confirmedRewards for all but the last (current) epoch
    if (index < totalEpochs - 1) {
      confirmedRewards += reward;
    }

    // Add to rewardsHistoryArray if reward is greater than 0
    if (index < totalEpochs - 1 && reward > 0) {
      rewardsHistoryArray.push({
        epoch: String(epochFromIndex),
        aevo_earned: String(reward),
      });
    }
  });

  return { confirmedRewards, rewardsHistoryArray };
};

export const calculateTradingRewardsEstimate = (
  farmData: GetFarmBoost200Response,
  emissions: GetEmissions200Response[]
): number => {
  const accountBoostedVolume = farmData.boosted_volume;
  const latestEpochEmission = emissions[emissions.length - 1];

  const emissionPercentage = latestEpochEmission.farming_emission;
  const totalBoostedVolume = latestEpochEmission.total_boosted_volume;

  if (Number(totalBoostedVolume) === 0) {
    return 0;
  }
  return (
    (Number(accountBoostedVolume) / Number(totalBoostedVolume)) *
    (Number(emissionPercentage) / 100) *
    totalTradingEmissions
  );
};

export const calculateEpochTotalBoostedVolume = (
  epoch: number,
  emissions: GetEmissions200Response[]
) => {
  if (epoch === 1) {
    return epoch0BoostedVolume;
  }
  if (emissions.length <= 1) {
    return 1;
  }

  const percentageOfWeekDone = calculatePercentageOfWeekDone();
  // if less than 1 day of epoch has passed, return previous epoch volume
  if (percentageOfWeekDone < 1 / 7) {
    return Number(emissions[epoch - 2].total_boosted_volume);
  }
  // else, extrapolate current epoch volume by percentage of time left in epoch
  return (
    Number(emissions[epoch - 1].total_boosted_volume) / percentageOfWeekDone
  );
};

export const calculateEstimatedTradeRewards = (
  boostedVolume: number,
  emissions?: GetEmissions200Response[],
  timestampNano?: number,
  takerFee?: number,
  isOptions: boolean = false
) => {
  if (!emissions) {
    return 0;
  }

  const timestampInNanoSeconds = timestampNano ?? Date.now() * 1e6;
  if (timestampInNanoSeconds < Number(tradingRewardsStartTimeNano)) {
    return 0;
  }
  const epoch = getCurrentEpoch(timestampInNanoSeconds);

  const epochTradingEmissions =
    emissions.length >= epoch - 1
      ? Number(emissions[epoch - 1].farming_emission)
      : 1;

  const epochEstimatedVolume = calculateEpochTotalBoostedVolume(
    epoch,
    emissions
  );

  const isBoostedVolumeNerfed = isOptions
    ? takerFee && Number(takerFee) < 0.0005
    : takerFee && Number(takerFee) < 0.0008;

  const boostedVolumeAfterNerf = isBoostedVolumeNerfed
    ? boostedVolume * 0.75
    : boostedVolume;
  return (
    (boostedVolumeAfterNerf *
      totalTradingEmissions *
      (epochTradingEmissions / 100)) /
    epochEstimatedVolume
  );
};

export const calculateStakingApr = (
  emissions: GetEmissions200Response[],
  totalStakedAmount: BigNumber,
  currentEpoch?: number // for testing purposes
): number => {
  const epoch = currentEpoch ?? Math.min(getCurrentEpoch(), 18);
  const epochIndex = epoch - 1;
  const latestEpochEmission =
    emissions.length >= epochIndex
      ? Number(emissions[epochIndex].staking_emission)
      : 0;

  const totalStakedAmountNumber = Number(
    ethers.utils.formatUnits(totalStakedAmount, 18)
  );

  return Math.min(
    (totalStakingEmissions * (Number(latestEpochEmission) / 100) * 52) /
      totalStakedAmountNumber,
    249.6427
  );
};

export const getUnlockDate = (): string => {
  const futureDate = moment().add(9, "weeks");

  // Adjust to next Wednesday
  if (futureDate.day() <= 3) {
    futureDate.day(3); // If it's before Wednesday, set to this week's Wednesday
  } else {
    futureDate.add(1, "weeks").day(3); // Otherwise, set to next week's Wednesday
  }

  // Set time to 00:00 UTC and format
  futureDate.utc().startOf("day");

  return futureDate.format("MMMM Do, YYYY");
};

export const getUnlockDateOfEpoch = (epoch: number): string => {
  // Start date: March 13, 2024, at 09:00 UTC
  const startDate = moment.utc("2024-03-13T09:00:00");

  // Calculate the unlock date by adding (8 + epoch) weeks to the start date
  // Epoch 1 will be 9 weeks from the start date, epoch 2 will be 10 weeks, etc.
  const unlockDate = startDate.add(8 + epoch, "weeks");

  // The time is already set to 09:00 UTC, so we only format the date
  return unlockDate.format("MMMM Do, YYYY");
};

export const formatNumber = (number: number) => {
  if (Math.abs(number) < 0.01) {
    return "0.00";
  }
  if (number < 1e3) return number;
  if (number >= 1e3 && number < 1e6) return `${+(number / 1e3).toFixed(1)}K`;
  if (number >= 1e6 && number < 1e9) return `${+(number / 1e6).toFixed(1)}M`;
  if (number >= 1e9 && number < 1e12) return `${+(number / 1e9).toFixed(1)}B`;
  if (number >= 1e12) return `${+(number / 1e12).toFixed(1)}T`;
  return number;
};

export const isMarketOrder = (price: string) =>
  price === "0" ||
  price ===
    "115792089237316200000000000000000000000000000000000000000000000000000000";

export const calculateOrderFee = (
  feeStructure?: number,
  notional?: number,
  markPrice?: number
) => {
  if (!feeStructure || !notional) {
    return undefined;
  }
  // for perps
  if (!markPrice) {
    return feeStructure * notional;
  }
  // for options
  return Math.min(feeStructure * notional, 0.125 * markPrice);
};

export const calculatePrelaunchBoost = (stakedAmount: number) => {
  if (stakedAmount < 101) {
    return 0;
  }
  if (stakedAmount < 1001) {
    return 1;
  }
  if (stakedAmount < 10001) {
    return 1 + Math.min(0.5, (0.5 * (stakedAmount - 1001)) / (10001 - 1001));
  }
  return 1.5 + Math.min(3.5, (4 * (stakedAmount - 10001)) / (101000 - 10001));
};

export const calculateLuckyBoost = (stakedAmount: number) => {
  if (stakedAmount < 10001) {
    return 13.5;
  }
  return (
    13.5 + Math.min(13.5, (13.5 * (stakedAmount - 10001)) / (101000 - 10001))
  );
};
