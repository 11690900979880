/* eslint-disable no-nested-ternary */
import currency from "currency.js";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Alert } from "../../../assets/svg/alert.svg";
import {
  GetAccount200ResponsePositionsInner,
  GetOrderbook200Response,
  InstrumentTypeResponse,
  SideResponse,
} from "../../../codegen-api";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import {
  INDEX_PRICE_COLLAR_OPTIONS,
  INDEX_PRICE_COLLAR_PERPS,
  MARK_PRICE_COLLAR,
} from "../../../constants/precision/form";
import { AccountStateEnum, AuthContext } from "../../../contexts/AuthContext";
import { ConnectWalletContext } from "../../../contexts/ConnectWalletContext";
import { MarketInstrumentContext } from "../../../contexts/MarketInstrumentContext";
import { useOrderbookWSS } from "../../../hooks/wss/useOrderbookWSS";
import { IPriceSize } from "../../../interfaces/Orderbook";
import { calculatePnl } from "../../../utils/math";
import {
  getValueOfContractsAmount,
  maxOrderSizeForMarkPriceCollar,
} from "../../../utils/orderbook";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import ModalOverlayInfo from "../ModalOverlayInfo";
import { Spinner } from "../Spinner";
import { ModalErrorMessage } from "../style";
import {
  ButtonsContainer,
  ConfirmCloseContent,
  EmptyTextContent,
  Highlight,
  TextContent,
  Title,
} from "./style";

interface IConfirmationOverlayInfoProps {
  show: boolean;
  onClose: () => void;
  loading?: boolean;
  error?: string;

  // If orderbook data is provided, use this instead of connecting to WS
  orderbookData?: GetOrderbook200Response;
  position: GetAccount200ResponsePositionsInner;
  onClosePosition: () => void;
}

enum LiquidityTypeEnum {
  SUFFICIENT = 0,
  BLEND,
  INSUFFICIENT,
  NONE,
}

type IPositionInfo =
  | {
      liquidityType: LiquidityTypeEnum.SUFFICIENT;
      bestLiquidity: {
        iv?: string;
        price: string;
      };
      pnl: number;
    }
  | {
      liquidityType: LiquidityTypeEnum.BLEND | LiquidityTypeEnum.INSUFFICIENT;
      averagePrice: string;
      averageIV?: string;
      pnl: number;
    }
  | {
      liquidityType: LiquidityTypeEnum.NONE;
    };

export function ClosePositionOverlayInfo({
  show,
  onClose,
  loading,
  error,
  position,
  orderbookData,
  onClosePosition,
}: IConfirmationOverlayInfoProps) {
  const { accountSigningKeyState } = useContext(AuthContext);
  const { setShowConnectModal } = useContext(ConnectWalletContext);
  const { getMarketPrecision, activePerpMarkets, activeOptionMarkets } =
    useContext(MarketInstrumentContext);
  const { t } = useTranslation("app", {
    keyPrefix: "shared.ClosePositionOverlayInfo",
  });

  const market = useMemo(
    () =>
      [...(activePerpMarkets || []), ...(activeOptionMarkets || [])].find(
        (v) => v.instrument_name === position.instrument_name
      ),
    [activeOptionMarkets, activePerpMarkets, position.instrument_name]
  );

  const wsOrderbook = useOrderbookWSS(position.instrument_name);
  const orderbook = wsOrderbook || orderbookData;

  // if buy, use bids. because closing pos will sell into the bids
  const liquidity: string[][] | undefined =
    position.side === SideResponse.Buy ? orderbook?.bids : orderbook?.asks;

  const bestLiquidity =
    (position.side === SideResponse.Buy
      ? liquidity?.sort((a, b) => Number(b[0]) - Number(a[0]))
      : liquidity?.sort((a, b) => Number(a[0]) - Number(b[0])))?.[0] || [];
  const bestLiquiditySize = Number(bestLiquidity?.[1] || 0);
  const totalLiquiditySize = Number(
    liquidity?.reduce((prev, liq) => prev + Number(liq[1]), 0)
  );

  const liquidityType: LiquidityTypeEnum =
    bestLiquiditySize === 0
      ? LiquidityTypeEnum.NONE
      : bestLiquiditySize >= Number(position.amount)
      ? LiquidityTypeEnum.SUFFICIENT
      : totalLiquiditySize >= Number(position.amount)
      ? LiquidityTypeEnum.BLEND
      : LiquidityTypeEnum.INSUFFICIENT;

  // If position is an option, detect order protection
  const maxOrderSizeBeforeOBProtectionTriggered =
    position.option &&
    (liquidityType === LiquidityTypeEnum.SUFFICIENT ||
      liquidityType === LiquidityTypeEnum.BLEND)
      ? maxOrderSizeForMarkPriceCollar(
          position.side === SideResponse.Buy
            ? SideResponse.Sell
            : SideResponse.Buy,
          orderbook?.bids || [],
          orderbook?.asks || [],
          Number(market?.mark_price || 0),
          Number(market?.index_price || 0),
          MARK_PRICE_COLLAR,
          position.instrument_type === InstrumentTypeResponse.Option
            ? INDEX_PRICE_COLLAR_OPTIONS
            : INDEX_PRICE_COLLAR_PERPS
        )
      : Number.MAX_VALUE;

  const showOrderProtection =
    Number(position.amount) > maxOrderSizeBeforeOBProtectionTriggered;

  let positionInfo: IPositionInfo | undefined;
  // Array of [price, size, iv]
  // if have a long position, sell into best BIDS
  // if have a short position, buy into best ASKS
  if (liquidity) {
    const positionEntryValue =
      Number(position.avg_entry_price) * Number(position.amount);

    switch (liquidityType) {
      case LiquidityTypeEnum.NONE:
        positionInfo = {
          liquidityType,
        };
        break;
      case LiquidityTypeEnum.SUFFICIENT: {
        const closingValue = Number(bestLiquidity[0]) * Number(position.amount);
        const pnl = calculatePnl(
          positionEntryValue,
          closingValue,
          position.side
        );
        positionInfo = {
          liquidityType,
          pnl,
          bestLiquidity: {
            iv: bestLiquidity[2]
              ? `${(Number(bestLiquidity[2]) * 100).toFixed(2)}%`
              : undefined,
            price: currency(bestLiquidity[0], {
              precision: getMarketPrecision(
                position.asset,
                position.instrument_type
              ).price_precision,
            }).format(),
          },
        };
        break;
      }
      case LiquidityTypeEnum.BLEND: // This is where RFQ should be created
      case LiquidityTypeEnum.INSUFFICIENT: {
        const closingValue = getValueOfContractsAmount(
          position.side === SideResponse.Buy
            ? SideResponse.Sell
            : SideResponse.Buy,
          liquidity as IPriceSize[],
          Number(position.amount)
        );
        // Size depends on blend or insufficient liquidity
        const size = Math.min(Number(position.amount), totalLiquiditySize);
        const entryValue = size * Number(position.avg_entry_price);
        const pnl = calculatePnl(entryValue, closingValue, position.side);
        const avgIV =
          liquidity.reduce((prev, liq) => prev + (Number(liq[2]) || 0), 0) /
          size;
        positionInfo = {
          liquidityType,
          pnl,
          averagePrice: currency(closingValue / size, {
            precision: getMarketPrecision(
              position.asset,
              position.instrument_type
            ).price_precision,
          }).format(),
          averageIV: avgIV ? `${(avgIV * 100).toFixed(2)}%` : undefined,
        };
        break;
      }
      default:
        break;
    }
  }

  const content = useMemo(() => {
    if (!positionInfo) {
      return (
        <EmptyTextContent>
          <Spinner />
        </EmptyTextContent>
      );
    }

    if (showOrderProtection) {
      return (
        <TextContent>
          {t("order_protection_desc_1", {
            side: position.side === SideResponse.Buy ? t("bid") : t("ask"),
            markPercentage: MARK_PRICE_COLLAR * 100,
          })}
          <br />
          <br />
          {t("order_protection_desc_2")}
        </TextContent>
      );
    }

    switch (positionInfo.liquidityType) {
      case LiquidityTypeEnum.SUFFICIENT:
        return (
          <TextContent>
            {t("sufficient_liquidity_desc_1", {
              side: position.side === SideResponse.Buy ? t("bid") : t("offer"),
            })}{" "}
            <Highlight>
              {positionInfo.bestLiquidity.price}{" "}
              {positionInfo.bestLiquidity.iv
                ? `(${t("iv")} ${positionInfo.bestLiquidity.iv})`
                : ""}
            </Highlight>{" "}
            {t("sufficient_liquidity_desc_2")}{" "}
            <Highlight>
              {positionInfo.pnl >= 0 ? t("profit") : t("loss")}
            </Highlight>{" "}
            {t("of")}{" "}
            <span
              style={{
                color:
                  positionInfo.pnl >= 0
                    ? COLORS.positive.one
                    : COLORS.negative.one,
              }}
            >
              {positionInfo.pnl >= 0 && "+"}
              {currency(positionInfo.pnl, {
                precision: getMarketPrecision(
                  position.asset,
                  position.instrument_type
                ).price_precision,
              }).format()}
            </span>
          </TextContent>
        );
      case LiquidityTypeEnum.NONE:
        return <TextContent>{t("no_liquidity_desc")}</TextContent>;
      default:
        return (
          <TextContent>
            {positionInfo.liquidityType === LiquidityTypeEnum.BLEND
              ? t("sufficient_blend_liquidity_desc")
              : t("insufficient_blend_liquidity_desc")}
            <Highlight>
              {positionInfo.averagePrice}{" "}
              {positionInfo.averageIV
                ? `(${t("iv")} ${positionInfo.averageIV})`
                : ""}
            </Highlight>{" "}
            {t("blend_liquidity_desc_2")}{" "}
            <Highlight>
              {positionInfo.pnl >= 0 ? t("profit") : t("loss")}
            </Highlight>{" "}
            {t("of")}{" "}
            <span
              style={{
                color:
                  positionInfo.pnl >= 0
                    ? COLORS.positive.one
                    : COLORS.negative.one,
              }}
            >
              {positionInfo.pnl >= 0 && "+"}
              {currency(positionInfo.pnl, {
                precision: getMarketPrecision(
                  position.asset,
                  position.instrument_type
                ).price_precision,
              }).format()}
            </span>
          </TextContent>
        );
    }
  }, [
    positionInfo,
    showOrderProtection,
    position.side,
    position.asset,
    position.instrument_type,
    getMarketPrecision,
    t,
  ]);

  return (
    <ModalOverlayInfo
      noPadding
      hideCloseButton={!showOrderProtection}
      show={show}
      onClose={onClose}
      height="300px"
    >
      <ConfirmCloseContent>
        <div style={{ flex: 1 }}>
          {showOrderProtection ? (
            <Title color={COLORS.negative.one}>
              {t("order_protection_triggered")} <Alert />
            </Title>
          ) : (
            <Title>{t("close_position")}?</Title>
          )}
          {content}
        </div>
        {!showOrderProtection && (
          <ButtonsContainer>
            {accountSigningKeyState !== AccountStateEnum.OK ? (
              <Button
                fullWidth
                buttonTheme={ButtonThemeEnum.HIGHLIGHT}
                onClick={() => setShowConnectModal(true)}
              >
                {t("complete_sign_in")}
              </Button>
            ) : (
              <Button
                fullWidth
                buttonTheme={ButtonThemeEnum.NEUTRAL3}
                disabled={loading}
                onClick={onClosePosition}
              >
                {loading ? (
                  <Spinner color={TEXT_COLORS.one} />
                ) : (
                  t("close_position")
                )}
              </Button>
            )}
            <Button
              fullWidth
              disabled={loading}
              buttonTheme={ButtonThemeEnum.NEGATIVE}
              onClick={onClose}
            >
              {t("cancel")}
            </Button>
            {error && <ModalErrorMessage>{error}</ModalErrorMessage>}
          </ButtonsContainer>
        )}
      </ConfirmCloseContent>
    </ModalOverlayInfo>
  );
}
