import styled, { css } from "styled-components";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow-down.svg";
import { ReactComponent as Check } from "../../../assets/svg/checkmark.svg";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import {
  COLORS,
  FIELD_COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../../constants/design/spacing";
import { Button } from "../../Buttons/styles";

export const ConfirmTransferContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${SPACING.three}px;
  margin-bottom: ${SPACING.five}px;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${SPACING.two}px;
`;

export const Description = styled.div`
  color: ${TEXT_COLORS.two};
  ${FONT_CSS.body.three}
`;

export const BackButton = styled(Button)`
  svg {
    transform: scaleX(-1);
  }
`;

export const TransferProgressContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  margin: ${SPACING.five}px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const svgStyle = css`
  position: absolute;
  width: 32px;
  height: 32px;
`;

export const TransferArrow = styled(ArrowDown)`
  ${svgStyle}
`;

export const TransferSuccessIcon = styled(Check)`
  ${svgStyle}
`;

export const TransferFailedIcon = styled(Close)`
  ${svgStyle}
  stroke: ${COLORS.negative.one};
`;

export const AddressConfirmationContainer = styled.div`
  ${FONT_CSS.body.three}
  color: ${TEXT_COLORS.two};
  margin-bottom: ${SPACING.three}px;

  > div {
    margin-top: ${SPACING.three}px;
    padding: ${SPACING.two}px;
    background-color: ${FIELD_COLORS.one};
    border-radius: ${BORDER_RADIUS.five}px;

    p {
      ${FONT_CSS.data.three}
      color: ${TEXT_COLORS.one};
      word-break: break-all;
      margin: 0;
    }
  }
`;

export const AddressText = styled.div`
  ${FONT_CSS.body.five}
  padding: ${SPACING.two}px 0;
  color: ${COLORS.highlight.one};
`;

export const VideoContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  background: ${LAYER_COLORS.two};
  width: 100%;
  height: 116px;

  video {
    position: absolute;
    opacity: 0.16;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const HeaderText = styled.div`
  color: ${TEXT_COLORS.three};
  margin-bottom: ${SPACING.two}px;
  ${FONT_CSS.data.four}
`;

export const SubText = styled.div`
  ${FONT_CSS.header.five}
`;

export const IntroContainer = styled.div<{ isOnboard: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.three}px;
  width: 100%;
  ${({ isOnboard }) =>
    isOnboard &&
    css`
      padding: ${SPACING.three}px;
      padding-bottom: ${SPACING.three}px;
    `}
`;

export const USDCSourceContainer = styled.div`
  margin-bottom: ${SPACING.three}px;
`;

export const Spacer = styled.div`
  margin-top: ${SPACING.two}px;
`;

export const IconContainer = styled.div`
  display: flex;
  margin: auto;

  svg {
    margin: auto ${SPACING.two}px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
`;

export const AssetTitleContainer = styled.div`
  display: flex;
  gap: ${SPACING.two}px;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${SPACING.two}px;
`;

export const HelpTitle = styled.div`
  display: flex;
  ${FONT_CSS.header.seven}
  margin-bottom: ${SPACING.three}px;
`;

export const TitleTextContainer = styled.div`
  display: flex;
  gap: ${SPACING.one}px;
  flex-direction: column;
`;

export const PausedText = styled.span`
  color: ${COLORS.blue.one};
  font-size: ${FONT_SIZE.one};
`;
