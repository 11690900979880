import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  LAYER_COLORS,
  BORDER_COLORS,
  COLORS,
  TEXT_COLORS,
  ICON_COLORS,
} from "../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
  WIDTHS,
} from "../constants/design/spacing";
import { ConnectWalletButton } from "../components/Buttons/styles";
import { frostedGlassBg } from "../components/shared/style";

export const Title = styled.div`
  font-size: ${FONT_SIZE.two};
  margin-bottom: ${SPACING.three}px;
`;

export const TabWrapper = styled.div`
  overflow-y: scroll;
  border-radius: 12px;
  padding: ${SPACING.three}px;
  border-right: 1px solid ${BORDER_COLORS.one};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${LAYER_COLORS.one};
`;

export const TabFooterWrapper = styled(motion.div)`
  border-top: 1px solid ${BORDER_COLORS.one};

  button {
    &:not(:first-child) {
      margin-top: ${SPACING.three}px;
    }
  }
`;

export const TabImageWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isActive }) =>
    isActive ? COLORS.highlight.six : LAYER_COLORS.two};
  border-radius: ${BORDER_RADIUS.five}px;
  border: ${({ isActive }) =>
    isActive ? `1px solid ${COLORS.highlight.one}` : "none"};
  margin-right: ${SPACING.two}px;
  height: ${COMPONENTS.pageTabsHeight}px;
  width: ${COMPONENTS.pageTabsHeight}px;

  svg {
    width: 16px;
    height: 16px;
    transition: 0.2s ease-in-out;
  }
`;

export const TabLinkWrapper = styled(Link)<{
  $isActive: boolean;
  $iconHoverStyle?: "fill";
}>`
  ${FONT_CSS.body.three}
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  color: ${({ $isActive }) =>
    $isActive ? COLORS.highlight.one : TEXT_COLORS.two};
  background-color: ${({ $isActive }) => $isActive && COLORS.highlight.six};
  text-decoration: none !important;
  margin: auto 0;
  margin-bottom: ${SPACING.two}px;
  border-radius: ${BORDER_RADIUS.five}px;
  white-space: nowrap;

  ${TabImageWrapper} {
    svg {
      ${({ $iconHoverStyle, $isActive }) => {
        if ($iconHoverStyle === "fill") {
          return css`
            fill: ${$isActive ? COLORS.highlight.one : ICON_COLORS.two};
          `;
        }
        return css`
          stroke: ${$isActive ? COLORS.highlight.one : ICON_COLORS.two};
        `;
      }}
    }
  }

  &:hover {
    color: ${({ $isActive }) =>
      $isActive ? COLORS.highlight.one : TEXT_COLORS.one};
    svg {
      ${({ $iconHoverStyle, $isActive }) => {
        if (!$isActive) {
          if ($iconHoverStyle === "fill") {
            return css`
              fill: ${TEXT_COLORS.one};
            `;
          }
          return css`
            stroke: ${TEXT_COLORS.one};
          `;
        }
        return css``;
      }}
    }
  }
`;

export const ContentsWrapper = styled.div<{
  isMobileScreen?: boolean;
  noWatchList?: boolean;
  noPadding?: boolean;
}>`
  flex: 1;

  ${({ noWatchList, isMobileScreen }) =>
    Boolean(noWatchList && !isMobileScreen) &&
    css`
      grid-column: 2 / span 2;
    `}

  padding: 0 ${({ isMobileScreen, noPadding }) =>
    isMobileScreen || noPadding ? 0 : SPACING.three}px;

  display: flex;
  justify-content: center;
  overflow-y: auto;
  border-radius: 12px;
  background: ${({ isMobileScreen }) => !isMobileScreen && LAYER_COLORS.one};

  ${({ isMobileScreen }) =>
    isMobileScreen &&
    css`
      border-radius: 0;
      max-height: 100%;
    `}
`;

export const InnerContentsWrapper = styled.div<{ isMobileScreen?: boolean }>`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  ${({ isMobileScreen }) => (!isMobileScreen ? "" : "width: 100vw;")}
  display: flex;
  flex-direction: column;
`;

export const ReferralInnerContentsWrapper = styled(InnerContentsWrapper)`
  display: flex;
  flex: 1;
`;

export const MobilePageWrapper = styled.div`
  position: relative;
  flex: 1;
  overflow: scroll;
  scroll-behavior: smooth;
`;

export const MobileTabActionsWrapper = styled.div`
  border: none;
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  overflow-x: scroll;
  ${frostedGlassBg()}

  > div {
    display: flex;
    align-items: center;
    height: ${COMPONENTS.portfolioNavigation}px;
    width: fit-content;

    > div {
      margin: auto 0;
      margin-right: ${SPACING.three}px;

      &:first-child {
        margin-left: ${SPACING.three}px;
      }
      &:last-child {
        margin-right: ${SPACING.three}px;
      }
    }
  }
`;

export const MobileTabLinkWrapper = styled(TabLinkWrapper)`
  font-size: ${FONT_SIZE.two};
  margin: auto;

  color: ${({ $isActive }) =>
    $isActive ? TEXT_COLORS.one : TEXT_COLORS.three};
  background-color: transparent;
  width: fit-content;

  &:hover {
    color: ${TEXT_COLORS.one};
  }

  &:hover {
    background: none;
  }
`;

export const WatchListTabWrapper = styled(TabWrapper)``;

export const SettingsInputContainer = styled.div``;

export const ToggleContainer = styled.div`
  display: flex;
`;

export const SettingRow = styled.div`
  margin-bottom: ${SPACING.five}px;
`;

export const SettingTitle = styled.div`
  ${FONT_CSS.header.seven}
  color: ${TEXT_COLORS.one};
  margin-bottom: ${SPACING.three}px;
`;

export const SettingDescription = styled.div`
  ${FONT_CSS.body.four}
  color: ${TEXT_COLORS.two};
  margin-bottom: ${SPACING.three}px;

  > strong {
    color: ${TEXT_COLORS.one};
  }
`;

export const SettingsContainer = styled.div<{ isMobile?: boolean }>`
  padding: ${({ isMobile }) =>
      isMobile ? `${SPACING.three}px 0` : `0 ${SPACING.three}px`}
    0 0;
  display: flex;
  flex-direction: column;

  ${SettingsInputContainer} {
    ${({ isMobile }) => {
      if (isMobile) {
        return css`
          display: flex;
          flex-direction: column-reverse;
          > *:first-child {
            margin-top: ${SPACING.three}px;
          }
        `;
      }
      return css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: ${SPACING.five}px;
      `;
    }}
  }

  ${ToggleContainer} {
    ${({ isMobile }) => {
      if (isMobile) {
        return css`
          flex-direction: column-reverse;
          > *:first-child {
            margin-top: ${SPACING.three}px;
          }
        `;
      }
      return css`
        flex-direction: row;
        align-items: center;
        > *:first-child {
          margin-right: ${SPACING.two}px;
        }
      `;
    }}
  }
`;

export const ErrorMessage = styled.span<{
  isSmall?: boolean;
  isMobile?: boolean;
}>`
  ${({ isSmall }) => (isSmall ? FONT_CSS.label.three : FONT_CSS.body.three)}
  color: ${COLORS.negative.one};
  display: flex;
  ${({ isMobile }) => isMobile && `margin-top: ${SPACING.one}px;`}
`;

export const Contents = styled.div<{ isMobile?: boolean }>`
  color: ${TEXT_COLORS.two};
  font-size: ${FONT_SIZE.two};
  display: flex;
  padding: ${({ isMobile }) => (isMobile ? 0 : SPACING.five)}px;

  @media (max-width: ${WIDTHS.S}px) {
    flex-direction: column;

    ${ConnectWalletButton} {
      width: 100% !important;
    }
  }

  a {
    color: ${TEXT_COLORS.two};
  }

  > span {
    flex: 1;
  }

  > div {
    flex: 1;
  }
`;
