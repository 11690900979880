import styled from "styled-components";
import { BORDER_COLORS, COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";

export type CheckboxType = "outline" | "default"

export const CheckboxContainer = styled.div<{ isActive?: boolean; type?: CheckboxType }>`
  ${FONT_CSS.body.five}
  display: flex;
  position: relative;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  transition: 0.2s ease-in-out;
  color: ${({ isActive, type }) => {
    if (type === "outline") {
      return TEXT_COLORS.three;
    }
    return isActive ? COLORS.highlight.one : TEXT_COLORS.three;
  }};

  div {
    border-radius: 4px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const Box = styled.div<{ isActive?: boolean; type?: CheckboxType }>`
  height: 16px;
  width: 16px;
  margin: 0;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ type, isActive }) => {
    if (type === "outline") {
      return isActive ? `1px solid ${COLORS.highlight.one}` : `1px solid ${BORDER_COLORS.three}`;
    }
    return (isActive ? "1px solid transparent" : `1px solid ${BORDER_COLORS.three}`);
  }};

  background-color: ${({ type, isActive }) => {
    if (type === "outline") {
      return "transparent";
    }
    return isActive ? COLORS.highlight.five : "transparent";
  }};

  svg {
    transition: 0.2s ease-in-out;
    width: 12px;
    height: 12px;
    stroke: ${COLORS.highlight.one};
  }
`;
