import currency from "currency.js";
import { Col } from "react-bootstrap";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import chartIcon from "../../assets/svg/chart.svg";
import listIcon from "../../assets/svg/list.svg";
import { GetInstrumentInstrumentName200ResponseMarkets, InstrumentTypeResponse } from "../../codegen-api";
import { useGetStatistics } from "../../hooks/api/statistics/useGetStatistics";
import { Label } from "../../pages/PortfolioOverviewTab/style";
import { Data, MarketDataWrapper, MarketWrapper } from "./style";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import { AssetResponse } from "../../utils/asset";

interface IMarketDataProps {
  market?: GetInstrumentInstrumentName200ResponseMarkets;
  asset?: AssetResponse;
}

export function MarketData({ market, asset }: IMarketDataProps) {
  const { data: statsData } = useGetStatistics(asset, InstrumentTypeResponse.Option);
  const { getMarketPrecision } = useContext(MarketInstrumentContext);
  const { t } = useTranslation("app", { keyPrefix: "MarketData" });

  return (
    <MarketWrapper>
      <Col xs={6}>
        <MarketDataWrapper>
          <Data>
            <img src={chartIcon} alt="daily_volume" />
          </Data>
          <div>
            <Label>{t("24h_volume")}</Label>
            <p>{market?.daily_volume ? currency(market.daily_volume).format() : "---"}</p>
          </div>
        </MarketDataWrapper>
      </Col>
      <Col xs={6}>
        <MarketDataWrapper>
          <Data>
            <img src={listIcon} alt="total_oi" />
          </Data>
          <div>
            <Label>{t("total_oi")}</Label>
            <p>
              {
                statsData?.index_price && market?.total_oi
                  ? currency(Number(market.total_oi) * Number(statsData.index_price), { precision: getMarketPrecision(asset, InstrumentTypeResponse.Option).price_precision }).format()
                  : "---"
              }
            </p>
          </div>
        </MarketDataWrapper>
      </Col>
    </MarketWrapper>
  );
}
