import currency from "currency.js";
import moment from "moment";
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Copy } from "../../../assets/svg/copy.svg";
import {
  GetTradeHistory200ResponseTradeHistoryInner,
  InstrumentTypeResponse,
  SideResponse,
  TradeTypeResponse,
} from "../../../codegen-api";
import { COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";
import { MarketInstrumentContext } from "../../../contexts/MarketInstrumentContext";
import { nanosToSeconds } from "../../../utils/date";
import { formatSizePrecision } from "../../../utils/format";
import { copyStringToClipboard, shortenString } from "../../../utils/strings";
import { getTradeSide } from "../../../utils/trade";
import { BaseModal } from "../../BaseModal";
import ShareButton from "../../Buttons/ShareButton";
import { PnLCardModal } from "../../PnLCardModal";
import MarketCell from "../../PortfolioSettings/Table/MarketCell";
import { ViewDetailsContent, ViewDetailsRow } from "./style";

interface ITradeDetailsModalProps {
  trade?: GetTradeHistory200ResponseTradeHistoryInner;
  onHide?: () => void;
  style?: CSSProperties;
}

function TradeDetailsModal({ trade, onHide, style }: ITradeDetailsModalProps) {
  const [showCopied, setShowCopied] = useState(false);
  const [showPnLCard, setShowPnLCard] = useState<boolean>(false);
  const { getMarketPrecision } = useContext(MarketInstrumentContext);
  const { amount_precision, price_precision } = getMarketPrecision(
    trade?.asset,
    trade?.instrument_type
  );
  const { t } = useTranslation("app", {
    keyPrefix: "shared.ViewDetailsModal.TradeDetailsModal",
  });

  // Dismiss copied text after 1 second
  useEffect(() => {
    if (showCopied) {
      const timeout = setTimeout(() => {
        setShowCopied(!showCopied);
      }, 500);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [showCopied]);

  const onCopyTradeId = useCallback(() => {
    if (trade) {
      copyStringToClipboard(trade.trade_id);
      setShowCopied(true);
    }
  }, [trade]);

  const onOpenPnLCard = useCallback(() => {
    setShowPnLCard(true);
  }, []);

  const onHidePnLCard = useCallback(() => {
    setShowPnLCard(false);
  }, []);

  if (!showPnLCard) {
    return (
      <BaseModal
        title={
          trade && (
            <MarketCell
              instrumentName={trade.instrument_name}
              optionType={trade.option_type}
              expiry={trade.expiry}
              strike={trade.strike}
            />
          )
        }
        show={!!trade}
        onHide={onHide}
        style={{
          ...style,
          minWidth: 311,
        }}
      >
        {trade && (
          <ViewDetailsContent>
            <ViewDetailsRow>
              <span>{t("side")}</span>
              <span
                style={{
                  color:
                    getTradeSide(
                      trade.side,
                      Boolean(trade.is_closing),
                      t,
                      trade.trade_type,
                      trade.avg_entry_price,
                      trade.price
                    ).type === SideResponse.Buy
                      ? COLORS.positive.one
                      : COLORS.negative.one,
                }}
              >
                {
                  getTradeSide(
                    trade.side,
                    Boolean(trade.is_closing),
                    t,
                    trade.trade_type,
                    trade.avg_entry_price,
                    trade.price
                  ).text
                }
              </span>
            </ViewDetailsRow>
            <ViewDetailsRow>
              <span>{t("type")}</span>
              <span>
                {trade.instrument_type === InstrumentTypeResponse.Spot &&
                  t("spot")}{" "}
                {trade.trade_type}
              </span>
            </ViewDetailsRow>
            {trade.settlement_price && (
              <ViewDetailsRow>
                <span>{t("settlement_price")}</span>
                <span>{currency(trade.settlement_price).format()}</span>
              </ViewDetailsRow>
            )}
            <ViewDetailsRow>
              <span>{t("size")}</span>
              <span>{formatSizePrecision(trade.amount, amount_precision)}</span>
            </ViewDetailsRow>
            <ViewDetailsRow>
              <span>{t("price")}</span>
              <span>
                {currency(trade.price ?? 0, {
                  precision: price_precision,
                }).format()}
              </span>
            </ViewDetailsRow>
            {trade.order_type && (
              <ViewDetailsRow>
                <span>{t("order_type")}</span>
                <span>{trade.order_type}</span>
              </ViewDetailsRow>
            )}
            <ViewDetailsRow>
              <span>{t("realized_pnl")}</span>
              {Number(trade.pnl) ? (
                <span
                  style={{
                    color:
                      Number(trade.pnl) >= 0
                        ? COLORS.positive.one
                        : COLORS.negative.one,
                  }}
                >
                  {Number(trade.pnl) >= 0 ? "+" : ""}
                  {currency(trade.pnl || 0, {
                    precision: getMarketPrecision(
                      trade.asset,
                      trade.instrument_type
                    ).price_precision,
                  }).format()}
                </span>
              ) : (
                <span>-</span>
              )}
            </ViewDetailsRow>
            {trade.trade_type !== TradeTypeResponse.Settlement && (
              <ViewDetailsRow>
                <span>{t("index_price")}</span>
                <span>
                  {trade.spot_price === undefined
                    ? "-"
                    : currency(trade.spot_price, {
                        precision: getMarketPrecision(
                          trade.asset,
                          trade.instrument_type
                        ).price_precision,
                      }).format()}
                </span>
              </ViewDetailsRow>
            )}
            <ViewDetailsRow
              style={{
                marginBottom: "12px",
              }}
            >
              <span>{t("fees")}</span>
              <span>
                {trade.fees === undefined
                  ? "-"
                  : currency(trade.fees, {
                      precision: getMarketPrecision(
                        trade.asset,
                        trade.instrument_type
                      ).price_precision,
                    }).format()}
              </span>
            </ViewDetailsRow>
            <ViewDetailsRow>
              <span>{t("date_time")}</span>
              <span>
                {moment
                  .unix(nanosToSeconds(trade.created_timestamp))
                  .format("HH:mm:ss - DD MMM YY")}
              </span>
            </ViewDetailsRow>
            {trade.trade_id && (
              <ViewDetailsRow clickable onClick={onCopyTradeId}>
                <span>{t("trade_id")}</span>
                <span>
                  <Copy style={{ marginRight: SPACING.two }} />
                  {showCopied
                    ? t("copied_trade_id")
                    : shortenString(trade.trade_id, 5, 5)}
                </span>
              </ViewDetailsRow>
            )}
            {Number(trade.pnl) && Boolean(trade.is_closing) ? (
              <ShareButton
                fullWidth
                onClick={onOpenPnLCard}
                style={{ marginTop: SPACING.three }}
              />
            ) : undefined}
          </ViewDetailsContent>
        )}
      </BaseModal>
    );
  }

  return (
    <PnLCardModal
      pnlType="trade"
      pnlData={trade}
      onHideModal={onHide}
      show={!!trade}
      onHidePnLCard={onHidePnLCard}
    />
  );
}

export default TradeDetailsModal;
