import { BadgeWrapper } from "./style";

interface IBadgeProps extends React.HTMLAttributes<HTMLElement> {
  backgroundColor?: string;
  color?: string;
  size?: number;
}

export function Badge({ backgroundColor, color, size, ...props }: IBadgeProps) {
  return (
    <BadgeWrapper backgroundColor={backgroundColor} color={color} size={size}>
      {props.children}
    </BadgeWrapper>
  );
}
