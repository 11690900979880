export default {
  short_name: "Aevo",
  name: "Aevo Exchange",
  description:
    "The world's first high-performance decentralized options exchange.",
  orientation: "portrait",
  icons: [
    {
      src: "/assets/logo/favicon.svg",
      type: "image/svg+xml",
      sizes: "512x512",
    },
    {
      src: "/assets/logo/favicon.png",
      type: "image/png",
      sizes: "512x512",
      purpose: "any",
    },
    {
      src: "/assets/logo/favicon-196.png",
      type: "image/png",
      sizes: "196x196",
      purpose: "maskable",
    },
    {
      src: "/assets/logo/favicon-192.png",
      type: "image/png",
      sizes: "192x192",
    },
  ],
  start_url: "./",
  scope: ".",
  display: "standalone",
  theme_color: "#ffffff",
  background_color: "#000000",
  launch_handler: {
    client_mode: ["focus-existing", "auto"],
  },
  handle_links: "preferred",
  display_override: ["window-controls-overlay"],
  categories: ["finance", "business", "productivity"],
  dir: "auto",
  screenshots: [
    {
      src: "public/assets/screenshots/1280x800.png",
      sizes: "1280x800",
      type: "image/png",
    },
    {
      src: "public/assets/screenshots/750x1334.png",
      sizes: "750x1334",
      type: "image/png",
    },
  ],
};
