import { AxiosError } from "axios";
import { useContext } from "react";
import useSWR from "swr";
import { GetCheckReferral200Response } from "../../../codegen-api";
import { AuthContext } from "../../../contexts/AuthContext";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { LocalStorageKeyEnum } from "../../../enums/localStorage";
import { publicApi } from "../../../services/api/apiFetcher";

export enum ReferralErrorCodesEnum {
  ACCOUNT_ALREADY_EXISTS = "ACCOUNT_ALREADY_EXISTS",
  REFERRER_SAME_AS_REFEREE = "REFERRER_SAME_AS_REFEREE",
  ACCOUNT_ALREADY_REFERRED = "ACCOUNT_ALREADY_REFERRED",
  INVALID_REFERRAL_CODE = "INVALID_REFERRAL_CODE",
}

export const useCheckReferral = () => {
  const fetcher = publicApi();
  const { account } = useContext(AuthContext);
  const referralCode = window.localStorage.getItem(
    LocalStorageKeyEnum.REFERRAL_CODE
  );

  return useSWR<GetCheckReferral200Response, AxiosError>(
    account && referralCode
      ? [APIEndpointEnum.CHECK_REFERRAL, account, referralCode]
      : undefined,
    async () =>
      (await (await fetcher.getCheckReferral(account!, referralCode!))()).data
  );
};
