import { Link } from "react-router-dom";
import styled from "styled-components";
import { TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../../constants/design/fontWeight";
import { SPACING } from "../../../constants/design/spacing";

export const BalanceLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${TEXT_COLORS.one};
  cursor: pointer;

  &:hover {
    color: ${TEXT_COLORS.one};
  }
`;

export const ExchangeBalanceContainer = styled.div<{
  isMobileScreen?: boolean;
}>`
  display: flex;
  margin-right: ${SPACING.two}px;

  img {
    ${({ isMobileScreen }) => `
      width: ${isMobileScreen ? "32px" : "28px"};
      height: ${isMobileScreen ? "32px" : "28px"};
    `}
    margin: auto ${SPACING.one}px;
  }
`;

export const MobileUtilizationWrapper = styled.div<{
  isMobileScreen?: boolean;
}>`
  ${({ isMobileScreen }) => `
flex-direction: ${!isMobileScreen ? "row" : "column"};
`}
`;

export const TextValues = styled.div<{ isMobileScreen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: ${SPACING.one}px;
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.one};

  ${({ isMobileScreen }) => `
    flex-direction: ${isMobileScreen ? "row" : "column"};
  `}

  // TITLE
  span:first-child {
    white-space: nowrap;
    color: ${TEXT_COLORS.four};
    font-weight: normal;
    font-size: ${FONT_SIZE.xs};
  }
`;

export const MarginUtilizationContainer = styled.div<{
  isMobileScreen?: boolean;
}>`
  display: flex;

  ${TextValues} {
    margin: 0 ${SPACING.two}px;
    flex-direction: column;

    ${({ isMobileScreen }) =>
      isMobileScreen &&
      `
      margin: 0 0 0 ${SPACING.two}px;
      width: 100%;
      justify-content: space-between;
      flex-direction: row;

      > span {
        margin-right: ${SPACING.two}px;
      }
    `}
  }
`;
