/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/prop-types */
import currency from "currency.js";
import { ethers } from "ethers";
import { AnimatePresence } from "framer-motion";
import moment from "moment";
import { useCallback, useContext, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTable } from "react-table";
import {
  InstrumentTypeResponse,
  OptionTypeResponse,
  PostRfqsPayload,
  PostRfqsPayloadLegsInner,
  SideResponse,
} from "../../../../codegen-api";
import { Button, ButtonThemeEnum } from "../../../../components/Buttons/styles";
import { Divider } from "../../../../components/shared/Divider";
import Dropdown, {
  IDropdownItemsItem,
} from "../../../../components/shared/Dropdown";
import { Input } from "../../../../components/shared/Input";
import RowActionButton from "../../../../components/shared/RowActionButton";
import { DefaultCellForColumn } from "../../../../components/shared/Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../../../components/shared/Table/DefaultHeaderForColumn";
import {
  Align,
  ButtonGroup,
  GroupedCells,
  TableContainerWrapper,
} from "../../../../components/shared/Table/style";
import { COLORS } from "../../../../constants/design/colors";
import { SPACING } from "../../../../constants/design/spacing";
import {
  AccountStateEnum,
  AuthContext,
} from "../../../../contexts/AuthContext";
import { ConnectWalletContext } from "../../../../contexts/ConnectWalletContext";
import { MarketInstrumentContext } from "../../../../contexts/MarketInstrumentContext";
import { ITableColumn } from "../../../../interfaces/Table/TableColumn";
import { getAssetLogo } from "../../../../utils/asset/assets";
import { nanosToSeconds } from "../../../../utils/date";
import { roundToStepSize } from "../../../../utils/format";
import CreateRFQModal from "../modals/CreateRFQModal";
import {
  AmountWrapper,
  AssetTag,
  EditorRow,
  EmptyContent,
  FooterButtonWrapper,
  RFQWrapper,
  StrategyTableWrapper,
  dropdownToggleStyles,
} from "./style";

interface IStrategy {
  asset: string | undefined;
  side: SideResponse | undefined;
  instrument_type: InstrumentTypeResponse | undefined;
  option_type: OptionTypeResponse | undefined;
  expiry: string | undefined;
  strike: string | undefined;
  ratio: string | undefined;
}

interface IFormStrategy extends IStrategy {
  isEdit: boolean;
}

const emptyStrategy: IFormStrategy = {
  asset: undefined,
  side: undefined,
  instrument_type: undefined,
  option_type: undefined,
  expiry: undefined,
  strike: undefined,
  ratio: "1",
  isEdit: true,
};

const optionAssets: string[] = ["ETH", "BTC"];

const defaultValues = {
  full_size: true,
  amount: "",
  strategies: [emptyStrategy] as Array<IFormStrategy>,
};

export function CreateRFQ() {
  const { accountSigningKeyState, account } = useContext(AuthContext);
  const { setShowConnectModal } = useContext(ConnectWalletContext);
  const [showRFQModal, setShowRFQModal] = useState<boolean>(false);
  const [payload, setPayload] = useState<PostRfqsPayload>();
  const { activeOptionMarkets, activePerpMarkets } = useContext(
    MarketInstrumentContext
  );
  const { t } = useTranslation("app", { keyPrefix: "pages.RFQPage.CreateRFQ" });
  const {
    register,
    formState: { isValid },
    handleSubmit,
    setValue,
    control,
    trigger,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const strategies = useWatch({ control, name: "strategies" });
  const amount = useWatch({ control, name: "amount" });

  const onAddLeg = useCallback(() => {
    if (strategies.length < 5) {
      strategies.push(emptyStrategy);
      setValue("strategies", strategies);
    }
  }, [setValue, strategies]);

  const onDeleteLeg = useCallback(
    (index: number) => {
      strategies.splice(index, 1);
      setValue("strategies", strategies);
    },
    [setValue, strategies]
  );

  const columns: ITableColumn<IFormStrategy>[] = useMemo(
    () => [
      {
        title: t("asset"),
        id: "asset",
        accessor: "asset",
        Cell: ({ value }) => <Align align="left">{value}</Align>,
      },
      {
        title: t("instrument_type"),
        id: "instrument_type",
        accessor: "instrument_type",
        Cell: ({ value }) => <Align align="left">{value}</Align>,
      },
      {
        title: t("side"),
        id: "side",
        accessor: "side",
        Cell: ({ value }) => (
          <Align
            align="left"
            style={{
              color:
                value === SideResponse.Buy
                  ? COLORS.positive.one
                  : COLORS.negative.one,
            }}
          >
            {value}
          </Align>
        ),
      },
      {
        title: t("option_type"),
        id: "option_type",
        accessor: "option_type",
        Cell: ({ value }) => <Align align="left">{value}</Align>,
      },
      {
        title: t("expiry"),
        id: "expiry",
        accessor: "expiry",
        Cell: ({ value }) => <Align align="left">{value}</Align>,
      },
      {
        title: t("strike"),
        id: "strike",
        accessor: "strike",
        Cell: ({ value }) => (
          <Align align="left">{currency(value || "").format()}</Align>
        ),
      },
      {
        title: t("ratio"),
        id: "ratio",
        accessor: "ratio",
        Cell: ({ value }) => <Align align="left">{value}</Align>,
      },
      {
        title: t("actions"),
        id: "actions",
        Cell: ({ row }: any) => (
          <Align align="right">
            <GroupedCells>
              <ButtonGroup>
                <RowActionButton
                  variant={"close"}
                  onClick={() => onDeleteLeg(row.index)}
                  style={{ marginRight: `${SPACING.two}px` }}
                />
              </ButtonGroup>
            </GroupedCells>
          </Align>
        ),
      },
    ],
    [onDeleteLeg, t]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: strategies,
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
    });

  const instrumentTypes: InstrumentTypeResponse[] = useMemo(
    () =>
      Object.values(InstrumentTypeResponse)
        .filter((v) => v !== "SPOT")
        .map((instrumentType) => instrumentType),
    []
  );
  const sides: SideResponse[] = useMemo(
    () => Object.values(SideResponse).map((side) => side),
    []
  );
  const optionTypes: OptionTypeResponse[] = useMemo(
    () => Object.values(OptionTypeResponse).map((optionType) => optionType),
    []
  );
  const assets: string[] = useMemo(() => {
    const assetSet = new Set<string>();
    const allMarkets = [
      ...(activeOptionMarkets || []),
      ...(activePerpMarkets || []),
    ];

    allMarkets.forEach((market) => {
      assetSet.add(market.underlying_asset as string);
    });

    return Array.from(assetSet)
      .sort((a, b) => {
        // Ensure option assets are at the top of the list, then sort alphabetically
        if (optionAssets.includes(a) && !optionAssets.includes(b)) {
          return -1;
        }
        if (!optionAssets.includes(a) && optionAssets.includes(b)) {
          return 1;
        }
        return a.localeCompare(b);
      })
      .map((asset) => asset);
  }, [activeOptionMarkets, activePerpMarkets]);

  const prepareInstrumentTypeDropdown = useCallback(
    (index: number) =>
      instrumentTypes
        .filter(
          // Filter based on strategy's asset. If it is an optionAsset, remove Perpetual from list of instrument types
          // Otherwise, allow all instruments
          (instrument) => {
            if (
              strategies[index].asset !== undefined &&
              !optionAssets.includes(strategies[index].asset as string)
            ) {
              return instrument === InstrumentTypeResponse.Perpetual;
            }
            return true;
          }
        )
        .map(
          (instrument) =>
            ({
              label: t(instrument.toLowerCase()),
              active: strategies[index].instrument_type === instrument,
              onSelect: () => {
                // If selected strategy's asset is an optionAsset, remove Perpetual form list of instrument types
                if (
                  strategies[index].instrument_type !== undefined &&
                  !optionAssets.includes(strategies[index].asset as string)
                ) {
                  setValue(
                    `strategies.${index}.instrument_type`,
                    InstrumentTypeResponse.Perpetual
                  );
                  setValue(`strategies.${index}.option_type`, undefined);
                } else {
                  setValue(`strategies.${index}.instrument_type`, instrument);
                }

                if (
                  strategies[index].instrument_type ===
                  InstrumentTypeResponse.Perpetual
                ) {
                  setValue(`strategies.${index}.expiry`, undefined);
                  setValue(`strategies.${index}.strike`, undefined);
                  setValue(`strategies.${index}.option_type`, undefined);

                  trigger(`strategies.${index}.expiry`);
                  trigger(`strategies.${index}.strike`);
                }

                trigger(`strategies.${index}.option_type`);
                trigger(`strategies.${index}.instrument_type`);
              },
            } as IDropdownItemsItem)
        ),
    [instrumentTypes, setValue, strategies, t, trigger]
  );

  const prepareSidesDropdown = useCallback(
    (index: number) =>
      sides.map(
        (side) =>
          ({
            label: (
              <div
                style={{
                  color:
                    side === SideResponse.Buy
                      ? COLORS.positive.one
                      : COLORS.negative.one,
                }}
              >
                {t(side)}
              </div>
            ),
            active: strategies[index].side === side,
            onSelect: () => {
              setValue(`strategies.${index}.side`, side);
              trigger(`strategies.${index}.side`);
            },
          } as IDropdownItemsItem)
      ),
    [sides, setValue, strategies, trigger, t]
  );

  const prepareOptionTypeDropdown = useCallback(
    (index: number) =>
      optionTypes.map(
        (optionType) =>
          ({
            label: t(optionType),
            active: strategies[index].option_type === optionType,
            onSelect: () => {
              setValue(`strategies.${index}.option_type`, optionType);
              trigger(`strategies.${index}.option_type`);
            },
          } as IDropdownItemsItem)
      ),
    [optionTypes, setValue, strategies, trigger, t]
  );

  const prepareAssetDropdown = useCallback(
    (index: number) =>
      assets.map(
        (asset) =>
          ({
            label: (
              <AssetTag>
                <img src={getAssetLogo(asset)} alt={asset} />
                <div>{asset}</div>
              </AssetTag>
            ),
            active: strategies[index].asset === asset,
            onSelect: () => {
              setValue(`strategies.${index}.asset`, asset);

              if (
                strategies[index].asset !== undefined &&
                !optionAssets.includes(strategies[index].asset as string)
              ) {
                // If the asset is not an optionAsset, reset the instrument type to perpetual and option_type to undefined
                setValue(
                  `strategies.${index}.instrument_type`,
                  InstrumentTypeResponse.Perpetual
                );
                setValue(`strategies.${index}.option_type`, undefined);
              }

              trigger(`strategies.${index}.asset`);
              trigger(`strategies.${index}.instrument_type`);
              trigger(`strategies.${index}.option_type`);

              setValue(`strategies.${index}.expiry`, undefined);
              setValue(`strategies.${index}.strike`, undefined);
            },
          } as IDropdownItemsItem)
      ),
    [assets, setValue, strategies, trigger]
  );

  const prepareExpiryDropdown = useCallback(
    (index: number) => {
      if (!activeOptionMarkets) return [];

      const selectedMarkets = activeOptionMarkets?.filter(
        (m) =>
          m.underlying_asset === strategies[index].asset &&
          m.expiry !== undefined
      );
      const marketsSet = new Set<string>();
      selectedMarkets.forEach((m) => marketsSet.add(String(m.expiry)));

      return Array.from(marketsSet).map(
        (m) =>
          ({
            label: moment.unix(nanosToSeconds(m)).format("DD MMM YY"),
            active: strategies[index].expiry === String(m),
            onSelect: () => {
              setValue(`strategies.${index}.expiry`, String(m));
              trigger(`strategies.${index}.expiry`);
            },
          } as IDropdownItemsItem)
      );
    },
    [activeOptionMarkets, setValue, strategies, trigger]
  );

  const prepareStrikeDropdown = useCallback(
    (index: number) => {
      if (!activeOptionMarkets) return [];

      const selectedMarkets = activeOptionMarkets?.filter(
        (m) =>
          m.underlying_asset === strategies[index].asset &&
          m.expiry === strategies[index].expiry
      );
      const marketsSet = new Set<string>();
      selectedMarkets.forEach((m) => marketsSet.add(String(m.strike)));

      return Array.from(marketsSet).map(
        (m) =>
          ({
            label: currency(String(m)).format(),
            active: strategies[index].strike === String(m),
            onSelect: () => {
              setValue(`strategies.${index}.strike`, String(m));
              trigger(`strategies.${index}.strike`);
            },
          } as IDropdownItemsItem)
      );
    },
    [activeOptionMarkets, setValue, strategies, trigger]
  );

  const onReset = useCallback(() => {
    reset(defaultValues);
  }, [reset]);

  const onSubmit = useCallback(() => {
    const formStrategies = getValues();
    const { full_size, amount, strategies } = formStrategies;

    // Map formstrategies based on strike, expiry and asset,
    // find the market and then convert this into PostQuotesPayload
    const legs = strategies.map((strategy: IStrategy) => {
      const markets = [
        ...(activePerpMarkets || []),
        ...(activeOptionMarkets || []),
      ];
      const market = markets?.find(
        (m) =>
          m.underlying_asset === strategy.asset &&
          m.instrument_type === strategy.instrument_type &&
          m.option_type === strategy.option_type &&
          m.expiry === strategy.expiry &&
          m.strike === strategy.strike
      );

      // If either one of the values are undefined, remove
      if (
        market === undefined ||
        market.instrument_id === undefined ||
        strategy.ratio === undefined ||
        strategy.ratio.length === 0 ||
        strategy.side === undefined
      ) {
        return undefined;
      }

      return {
        instrument: Number(market.instrument_id),
        is_buy: Boolean(strategy.side === SideResponse.Buy),
        ratio: Number(strategy.ratio),
      } as PostRfqsPayloadLegsInner;
    });

    const payload: PostRfqsPayload = {
      full_size,
      amount: ethers.utils.parseUnits(amount, 6).toString(),
      legs: legs.filter(
        (leg): leg is PostRfqsPayloadLegsInner => leg !== undefined
      ),
      duration: 1800,
    };

    setPayload(payload);
    setShowRFQModal(true);
  }, [activeOptionMarkets, activePerpMarkets, getValues]);

  const onHideModal = useCallback(
    (resetForm: boolean = false) => {
      setShowRFQModal(false);

      if (resetForm) {
        reset();
      }
    },
    [reset]
  );

  return (
    <>
      <CreateRFQModal
        payload={payload}
        show={showRFQModal}
        onHide={onHideModal}
        activeOptionMarkets={activeOptionMarkets}
        activePerpMarkets={activePerpMarkets}
      />

      <RFQWrapper>
        <TableContainerWrapper>
          <StrategyTableWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <AnimatePresence>
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                          // eslint-disable-next-line react/jsx-key
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      const { ...rowProps } = row.getRowProps();

                      if (!row.original.isEdit) {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <tr {...rowProps}>
                            {row.cells.map((cell) => (
                              // eslint-disable-next-line react/jsx-key
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      }

                      const instrumentTypeOptions =
                        prepareInstrumentTypeDropdown(row.index);
                      const assetOptions = prepareAssetDropdown(row.index);
                      const sideOptions = prepareSidesDropdown(row.index);
                      const optionTypeOptions = prepareOptionTypeDropdown(
                        row.index
                      );
                      const expiryOptions = prepareExpiryDropdown(row.index);
                      const strikeOptions = prepareStrikeDropdown(row.index);
                      const strategy = strategies[row.index];

                      return (
                        <EditorRow
                          key={String(row.id)}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          <td>
                            <Dropdown
                              showArrow
                              toggleStyles={dropdownToggleStyles}
                              width={"140px"}
                              title={
                                strategy.asset ? (
                                  <AssetTag>
                                    <img
                                      src={getAssetLogo(strategy.asset)}
                                      alt={strategy.asset}
                                    />
                                    <div>{strategy.asset}</div>
                                  </AssetTag>
                                ) : (
                                  t("select_asset")
                                )
                              }
                              items={assetOptions}
                              {...register(`strategies.${row.index}.asset`, {
                                required: true,
                              })}
                            />
                          </td>
                          <td>
                            <Dropdown
                              showArrow
                              toggleStyles={dropdownToggleStyles}
                              width={"180px"}
                              title={t(
                                (
                                  strategy.instrument_type ??
                                  "select_instrument_type"
                                ).toLowerCase()
                              )}
                              items={instrumentTypeOptions}
                              {...register(
                                `strategies.${row.index}.instrument_type`,
                                {
                                  required: true,
                                }
                              )}
                            />
                          </td>
                          <td>
                            <Dropdown
                              showArrow
                              toggleStyles={dropdownToggleStyles}
                              width={"120px"}
                              title={
                                strategy.side ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      color:
                                        strategy.side === SideResponse.Buy
                                          ? COLORS.positive.one
                                          : COLORS.negative.one,
                                    }}
                                  >
                                    {t(strategy.side)}
                                  </div>
                                ) : (
                                  t("select_side")
                                )
                              }
                              items={sideOptions}
                              {...register(`strategies.${row.index}.side`, {
                                required: true,
                              })}
                            />
                          </td>
                          <td>
                            <div style={{ width: 160 }}>
                              {strategy.instrument_type ===
                              InstrumentTypeResponse.Option ? (
                                <Dropdown
                                  showArrow
                                  toggleStyles={dropdownToggleStyles}
                                  width={"100%"}
                                  title={t(
                                    strategy.option_type ?? "select_option_type"
                                  )}
                                  items={optionTypeOptions}
                                  {...register(
                                    `strategies.${row.index}.option_type`,
                                    {
                                      required:
                                        strategy.instrument_type ===
                                        InstrumentTypeResponse.Option,
                                    }
                                  )}
                                />
                              ) : (
                                <EmptyContent>-</EmptyContent>
                              )}
                            </div>
                          </td>
                          <td>
                            <div style={{ width: 180 }}>
                              {strategy.asset &&
                              strategy.instrument_type &&
                              strategy.instrument_type ===
                                InstrumentTypeResponse.Option ? (
                                <Dropdown
                                  showArrow
                                  toggleStyles={dropdownToggleStyles}
                                  width={"100%"}
                                  title={
                                    strategy.expiry
                                      ? moment
                                          .unix(nanosToSeconds(strategy.expiry))
                                          .format("DD MMM YY")
                                      : t("select_expiry")
                                  }
                                  items={expiryOptions}
                                  {...register(
                                    `strategies.${row.index}.expiry`,
                                    {
                                      required:
                                        strategy.asset &&
                                        strategy.instrument_type &&
                                        strategy.instrument_type ===
                                          InstrumentTypeResponse.Option,
                                    }
                                  )}
                                />
                              ) : (
                                <EmptyContent>-</EmptyContent>
                              )}
                            </div>
                          </td>
                          <td>
                            <div style={{ width: 120 }}>
                              {strategy.asset &&
                              strategy.instrument_type &&
                              strategy.instrument_type ===
                                InstrumentTypeResponse.Option &&
                              strategy.expiry ? (
                                <Dropdown
                                  showArrow
                                  width={"100%"}
                                  toggleStyles={dropdownToggleStyles}
                                  title={
                                    strategy.strike
                                      ? currency(strategy.strike).format()
                                      : t("select_strike")
                                  }
                                  items={strikeOptions}
                                  {...register(
                                    `strategies.${row.index}.strike`,
                                    {
                                      required:
                                        strategy.asset &&
                                        strategy.instrument_type &&
                                        strategy.instrument_type ===
                                          InstrumentTypeResponse.Option &&
                                        strategy.expiry,
                                    }
                                  )}
                                />
                              ) : (
                                <EmptyContent>-</EmptyContent>
                              )}
                            </div>
                          </td>
                          <td>
                            <Input
                              placeholder="0"
                              {...register(`strategies.${row.index}.ratio`, {
                                onBlur: (e) => {
                                  const roundedValue = roundToStepSize(
                                    parseFloat(e.target.value) || 0,
                                    0.25
                                  ).toString();
                                  setValue(
                                    `strategies.${row.index}.ratio`,
                                    roundedValue
                                  );
                                },
                                required: true,
                                validate: {
                                  moreThanZero: (v) => v && parseFloat(v) > 0,
                                },
                              })}
                            />
                          </td>
                          <td>
                            <Align align="right">
                              <GroupedCells>
                                <ButtonGroup>
                                  {rows.length === 1 ? null : (
                                    <RowActionButton
                                      variant={"close"}
                                      onClick={() => onDeleteLeg(row.index)}
                                      style={{
                                        marginRight: `${SPACING.two}px`,
                                      }}
                                    />
                                  )}
                                </ButtonGroup>
                              </GroupedCells>
                            </Align>
                          </td>
                        </EditorRow>
                      );
                    })}
                  </tbody>
                </table>
              </AnimatePresence>
              <AmountWrapper>
                <span>{t("block_size")}:</span>
                <Input
                  placeholder="0"
                  value={amount}
                  {...register("amount", {
                    onBlur() {
                      setValue("amount", String(roundToStepSize(amount, 0.1)));
                    },
                    required: true,
                    validate: {
                      moreThanZero: (v) => v && parseFloat(v) > 0,
                    },
                  })}
                />
              </AmountWrapper>
              <Divider
                direction="horizontal"
                size="100%"
                style={{ height: "1px" }}
              />
              <FooterButtonWrapper>
                {account && accountSigningKeyState === AccountStateEnum.OK ? (
                  <>
                    <Button
                      buttonTheme={ButtonThemeEnum.NEUTRAL2}
                      onClick={() => onReset()}
                    >
                      {t("reset")}
                    </Button>
                    <Button
                      buttonTheme={ButtonThemeEnum.NEUTRAL2}
                      onClick={() => onAddLeg()}
                      disabled={strategies.length === 5}
                    >
                      {t("add_new_leg")} ({strategies.length} / 5)
                    </Button>
                    <Button
                      buttonTheme={ButtonThemeEnum.PRIMARY}
                      type="submit"
                      disabled={!isValid}
                    >
                      {t("submit")}
                    </Button>
                  </>
                ) : (
                  <Button
                    buttonTheme={ButtonThemeEnum.HIGHLIGHT}
                    onClick={() => setShowConnectModal(true)}
                  >
                    {!account ? t("connect_wallet") : t("complete_sign_in")}
                  </Button>
                )}
              </FooterButtonWrapper>
            </form>
          </StrategyTableWrapper>
          {/* <div>
          {errors.amount?.type === "required" && (
          <InputError>{formError("amount.required")}</InputError>
          )}
          {errors.amount?.type === "moreThanZero" && (
          <InputError>{formError("amount.moreThanZero")}</InputError>
          )}
        </div> */}
        </TableContainerWrapper>
      </RFQWrapper>
    </>
  );
}
