import { ButtonHTMLAttributes, useMemo } from "react";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import { ReactComponent as Details } from "../../../assets/svg/details.svg";
import { ReactComponent as Edit } from "../../../assets/svg/edit.svg";
import { ReactComponent as Share } from "../../../assets/svg/share.svg";
import { ReactComponent as PartialDetail } from "../../../assets/svg/partialdetail.svg";
import { ReactComponent as Plus } from "../../../assets/svg/plus.svg";
import { COLORS, LAYER_COLORS } from "../../../constants/design/colors";
import { ButtonThemeEnum } from "../../Buttons/styles";
import { ButtonWithBackground, PartialButtonWrapper } from "./style";

type IRowActionButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  (
    | {
        variant: "close" | "edit" | "details" | "share" | "plus";
        text?: undefined;
        svgColor?: string;
        svgBgColor?: string;
        disabled?: boolean;
        buttonTheme?: ButtonThemeEnum;
      }
    | {
        variant: "text";
        text: string;
        svgColor?: string;
        svgBgColor?: string;
        disabled?: boolean;
      }
    | {
        variant: "partial";
        text: string;
        svgColor?: string;
        svgBgColor?: string;
        disabled?: boolean;
      }
  );

function RowActionButton({
  variant,
  svgColor = COLORS.blue.one,
  svgBgColor = LAYER_COLORS.three,
  disabled = false,
  onClick,
  ...props
}: IRowActionButtonProps) {
  const icon = useMemo(() => {
    switch (variant) {
      case "close":
        return <Close />;
      case "edit":
        return <Edit />;
      case "details":
        return <Details />;
      case "share":
        return <Share />;
      case "partial":
        return <PartialDetail />;
      case "plus":
        return <Plus />;
      default:
        return null;
    }
  }, [variant]);

  return (
    <ButtonWithBackground
      isText={variant === "text"}
      isPartial={variant === "partial"}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(event);
      }}
      {...props}
      fillOnly={variant === "details"}
      svgColor={svgColor}
      svgBgColor={svgBgColor}
      disabled={disabled}
    >
      {variant === "text" ? (
        props.text
      ) : variant === "partial" ? (
        <PartialButtonWrapper>
          {icon} {props.text}
        </PartialButtonWrapper>
      ) : (
        icon
      )}
    </ButtonWithBackground>
  );
}

export default RowActionButton;
