import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GetOrderbook200Response,
  GetOrders200Response,
  SideResponse,
} from "../../codegen-api";
import { IOptionMarket } from "../../contexts/MarketInstrumentContext/useGetMarkets";
import { useGetOptionData } from "../../hooks/api/statistics/useGetOptionData";
import useTradesWSS from "../../hooks/wss/useTradesWSS";
import { IPriceSize } from "../../interfaces/Orderbook";
import { AssetResponse } from "../../utils/asset";
import { getContractPriceStep } from "../../utils/instruments";
import InstrumentTradeHistory from "../InstrumentTradeHistory";
import { MarketData } from "../MarketData";
import { Tab } from "../OptionFilter/style";
import {
  OrderbookEmptyContent,
  OrderbookTabWrapper,
  PlaceholderContent,
} from "../TradeForm/style";
import { Select } from "../shared/Select";
import Transition from "../shared/Transition";
import { Greeks } from "./Greeks";
import PayoffChart from "./PayoffChart";
import TradeOrderbook from "./TradeOrderBook";
import { MarkRow } from "./TradeOrderBook/MarkRow";
import {
  OrderbookSelect,
  OrderbookWrapper,
  TicketGreeksWrapper,
} from "./style";

export interface ITicketDetailsProps {
  asset: AssetResponse;
  orderDirection: SideResponse;
  averagePremium: number;
  selectedInstrument?: IOptionMarket;
  totalSize: number;
  showPlaceholder?: boolean;
  mobileMode?: boolean;
  // ORDERBOOK
  orderbookData?: GetOrderbook200Response;
  orderData?: GetOrders200Response[];
  markPrice?: string;
  markIV?: string;
  indexPrice: number;
  // % away from index price that a trade is allowed
  indexPriceCollar: number;
  onOrderbookRowClick: (
    price: string,
    size: string,
    side: SideResponse
  ) => void;
}

export enum TicketDetailsPageEnum {
  ORDERBOOK = "orderbook",
  DATA = "data",
  PAYOFF = "payoff",
  TRADES = "trades",
}

function TicketDetails({
  asset,
  orderDirection,
  averagePremium,
  selectedInstrument,
  totalSize,
  showPlaceholder,
  orderbookData,
  orderData,
  markPrice,
  markIV,
  indexPrice,
  indexPriceCollar,
  mobileMode,
  onOrderbookRowClick,
}: ITicketDetailsProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TicketDetails.TicketDetails",
  });

  const { data: optionData } = useGetOptionData(
    selectedInstrument?.instrument_name
  );
  const { data: histories, loading: tradeHistoryValidating } = useTradesWSS(
    selectedInstrument?.instrument_name,
    undefined,
    true
  );

  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const [selectedPage, setPage] = useState<TicketDetailsPageEnum>(
    mobileMode ? TicketDetailsPageEnum.DATA : TicketDetailsPageEnum.ORDERBOOK
  );
  const pageOptions: TicketDetailsPageEnum[] = useMemo(
    () => Object.values(TicketDetailsPageEnum) as TicketDetailsPageEnum[],
    []
  );

  const bidAsks = useMemo(() => {
    let bids: IPriceSize[] = (orderbookData?.bids as IPriceSize[]) || [];
    let asks: IPriceSize[] = (orderbookData?.asks as IPriceSize[]) || [];

    const diff = bids.length - asks.length;
    if (diff > 0) {
      // Positive diff means bids > asks
      asks = [...asks, ...Array(Math.abs(diff)).fill(["0", "0"])];
    } else if (diff < 0) {
      bids = [...bids, ...Array(Math.abs(diff)).fill(["0", "0"])];
    }

    return {
      bids,
      asks,
    };
  }, [orderbookData]);

  useEffect(() => {
    setPage((prevPage) => {
      if (!pageOptions.includes(prevPage)) {
        return pageOptions[0];
      }
      return prevPage;
    });
  }, [pageOptions]);

  // Scroll to center of orderbook whenever new instrument is selected
  useEffect(() => {
    const container = scrollableContainerRef.current;
    if (container) {
      const scrollTop = container.scrollHeight / 2 - container.clientHeight / 2;
      container.scrollTo({
        behavior: "smooth",
        top: scrollTop,
      });
    }
  }, [selectedInstrument]);

  const midRowRef = useRef<HTMLDivElement>(null);
  const orderbookSectionContainer = useRef<HTMLDivElement>(null);

  const containerHeight = orderbookSectionContainer.current?.clientHeight || 0;
  const rowHeight = 40;
  const obHeightWithoutHeaders = containerHeight - rowHeight * 2;
  const maxRows = orderbookSectionContainer.current?.clientHeight
    ? Math.max(Math.floor(obHeightWithoutHeaders / 2 / rowHeight), 0)
    : undefined;
  const newRowHeight = maxRows
    ? obHeightWithoutHeaders / (maxRows * 2)
    : rowHeight;

  if (mobileMode && selectedInstrument) {
    return (
      <TradeOrderbook
        headerRowHeight={`${rowHeight}px`}
        rowHeight={`${newRowHeight}px`}
        indexPriceCollar={indexPriceCollar}
        sizeDecimals={getContractPriceStep(selectedInstrument).amount_precision}
        priceDecimals={getContractPriceStep(selectedInstrument).price_precision}
        accountOrders={orderData || []}
        middleRow={
          <MarkRow
            ref={midRowRef}
            markPrice={markPrice}
            markIV={markIV}
            isTradeForm
          />
        }
        instrumentId={selectedInstrument.instrument_name}
        bids={bidAsks.bids}
        asks={bidAsks.asks}
        showIV={!!selectedInstrument.expiry}
        onRowClick={onOrderbookRowClick}
        markPrice={Number(markPrice || 0)}
        indexPrice={indexPrice}
        isTradeForm
        isOptionsTradeForm
      />
    );
  }
  return (
    <OrderbookWrapper initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
      <OrderbookSelect>
        <Select
          isRound
          options={pageOptions.map((opt) => ({
            label: t(opt),
            isActive: selectedPage === opt,
            onClick: () => setPage(opt),
          }))}
        />
      </OrderbookSelect>
      {showPlaceholder || !selectedInstrument ? (
        <PlaceholderContent>
          <span>{t("select_strike_price")}</span>
        </PlaceholderContent>
      ) : (
        <Transition key={selectedPage}>
          {selectedPage === TicketDetailsPageEnum.ORDERBOOK && (
            <OrderbookTabWrapper ref={scrollableContainerRef}>
              {selectedInstrument ? (
                <TradeOrderbook
                  indexPriceCollar={indexPriceCollar}
                  sizeDecimals={
                    getContractPriceStep(selectedInstrument).amount_precision
                  }
                  priceDecimals={
                    getContractPriceStep(selectedInstrument).price_precision
                  }
                  accountOrders={orderData || []}
                  middleRow={<MarkRow markPrice={markPrice} markIV={markIV} />}
                  instrumentId={selectedInstrument.instrument_name}
                  bids={bidAsks.bids}
                  asks={bidAsks.asks}
                  showIV={!!selectedInstrument.expiry}
                  onRowClick={onOrderbookRowClick}
                  markPrice={Number(markPrice || 0)}
                  indexPrice={indexPrice}
                />
              ) : (
                <OrderbookEmptyContent>
                  <Tab flashing isActive>
                    {t("select_strike_price")}
                  </Tab>
                </OrderbookEmptyContent>
              )}
            </OrderbookTabWrapper>
          )}
          {selectedPage === TicketDetailsPageEnum.DATA && (
            <TicketGreeksWrapper>
              <Greeks greeks={optionData?.greeks} />
              <MarketData market={optionData?.markets} asset={asset} />
            </TicketGreeksWrapper>
          )}
          {selectedPage === TicketDetailsPageEnum.PAYOFF && (
            <PayoffChart
              asset={asset}
              orderDirection={orderDirection}
              averagePremium={averagePremium}
              totalSize={totalSize}
              optionType={selectedInstrument.option_type}
              strike={selectedInstrument.strike}
              expiry={selectedInstrument.expiry}
            />
          )}
          {selectedPage === TicketDetailsPageEnum.TRADES && (
            <InstrumentTradeHistory
              trades={histories}
              loading={tradeHistoryValidating}
              sizeDecimals={
                getContractPriceStep(selectedInstrument).amount_precision
              }
              priceDecimals={
                getContractPriceStep(selectedInstrument).price_precision
              }
            />
          )}
        </Transition>
      )}
    </OrderbookWrapper>
  );
}

export default TicketDetails;
