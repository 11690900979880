import { useCallback } from "react";
import useSWR from "swr";
import { IMarket } from "../../contexts/MarketContext";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { jsonParse } from "../../utils/strings";

const useWatchlist = () => {
  const loadWatchlist = useCallback(() => {
    const localStorageItem = localStorage.getItem(
      LocalStorageKeyEnum.WATCHLIST
    );
    if (localStorageItem) {
      try {
        return jsonParse(localStorageItem) as IMarket[];
      } catch (error) {
        // Do nothing
      }
    }
    return [];
  }, []);

  // Get block (once every 30s)
  const { data: watchlist, mutate } = useSWR(
    ["Watchlist"],
    async () => {
      try {
        return loadWatchlist();
      } catch (error) {
        // Ignore error fetching block
        return [];
      }
    },
    {
      refreshInterval: 30000,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Retry after 30s.
        setTimeout(() => revalidate({ retryCount }), 30000);
      },
    }
  );

  const updateWatchlist = useCallback(
    (list: IMarket[]) => {
      mutate(list, { revalidate: false });
      try {
        localStorage.setItem(
          LocalStorageKeyEnum.WATCHLIST,
          JSON.stringify(list)
        );
      } catch (error) {
        // Do nothing
      }
    },
    [mutate]
  );

  return {
    watchlist: watchlist || [],
    updateWatchlist,
  };
};

export default useWatchlist;
