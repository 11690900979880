import { motion } from "framer-motion";
import styled from "styled-components";
import {
  BORDER_COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../../constants/design/fontWeight";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../constants/design/spacing";
import { SelectButton } from "../Select/style";
import { fadeInCSS } from "../style";

export const DropdownButton = styled(SelectButton)`
  ${FONT_CSS.body.three}
  display: flex;
  border-radius: 8px;
  font-weight: ${FONT_WEIGHT.medium};
  background-color: ${LAYER_COLORS.two};

  height: 100%;
  margin: auto 0;

  > * {
    margin: auto;
    width: 100%;
  }
`;

export const DropdownHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  background-color: ${LAYER_COLORS.one};
`;

export const DropdownMenuWrapper = styled.ul<{ hasAnnouncement?: boolean }>`
  background-color: ${LAYER_COLORS.one};
  outline: ${`1px solid ${BORDER_COLORS.one}`};
  border: none;
  overflow-y: scroll;
  max-height: ${({ hasAnnouncement }) =>
    `calc(100vh - ${
      COMPONENTS.header.main +
      COMPONENTS.header.sub +
      COMPONENTS.footer.desktop +
      SPACING.three +
      (hasAnnouncement ? COMPONENTS.banner : 0)
    }px)`};
  border-radius: ${BORDER_RADIUS.five}px;
  padding: 0;
  z-index: 3;
  ${fadeInCSS}
`;

export const DropdownItemButton = styled(motion.button)`
  ${FONT_CSS.body.three}
  color: ${TEXT_COLORS.one};
  width: 100%;
  text-align: left;
  padding: 0;
`;

export const ChevronWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  margin: 0;
  margin-left: auto;
`;
