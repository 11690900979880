import currency from "currency.js";
import { ethers } from "ethers";
import moment, { DurationInputArg1, DurationInputArg2 } from "moment";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowDown } from "../../../../assets/svg/arrow-down.svg";
import { ReactComponent as ArrowOut } from "../../../../assets/svg/arrow-up-right.svg";
import { ReactComponent as Dollar } from "../../../../assets/svg/dollar.svg";
import { ReactComponent as Etherscan } from "../../../../assets/svg/etherscan.svg";
import { ReactComponent as Globe } from "../../../../assets/svg/globe.svg";
import { ReactComponent as Disconnect } from "../../../../assets/svg/power.svg";
import { ReactComponent as Refresh } from "../../../../assets/svg/refresh.svg";
import { ReactComponent as ArrowRepeat } from "../../../../assets/svg/repeat.svg";
import { ReactComponent as Settings } from "../../../../assets/svg/settings.svg";
import { ReactComponent as StopSync } from "../../../../assets/svg/stop-circle.svg";
import {
  GetTransactionHistory200ResponseTransactionHistoryInner,
  TxTypeResponse,
} from "../../../../codegen-api";
import { COLORS } from "../../../../constants/design/colors";
import { ONCHAIN_ESTIMATES } from "../../../../constants/onchainEstimates";
import {
  AccountStateEnum,
  AuthContext,
} from "../../../../contexts/AuthContext";
import { PageEndpointEnum } from "../../../../enums/endpoint";
import { useGetAccount } from "../../../../hooks/api/account/useGetAccount";
import useWallet from "../../../../hooks/wallet/useWallet";
import {
  collateralAssetDecimals,
  getCollateralAssetByAddress,
} from "../../../../utils/asset/assets";
import { nanosToSeconds } from "../../../../utils/date";
import { formatCollateralDecimals } from "../../../../utils/format";
import { shortenAddress } from "../../../../utils/strings";
import { BaseModal, IBaseModalProps } from "../../../BaseModal";
import { Button, ButtonThemeEnum } from "../../../Buttons/styles";
import { ArrowWrapper } from "../../../TransactionHistory/style";
import ModalOverlayInfo from "../../ModalOverlayInfo";
import { OverlayInfoContent } from "../../ModalOverlayInfo/style";
import { Spinner } from "../../Spinner";
import { SpinnerContainerWrapper } from "../../Spinner/style";
import {
  AccountItemRow,
  AccountItemsContainer,
  BalanceValue,
  Content,
  DepositItemsContainer,
  EnableTradingButton,
  ExchangeBalanceWrapper,
  GlobeContainer,
  OngoingTransfer,
  OngoingTransferList,
  Title,
  TitleWrapper,
  Username,
} from "./style";
import useScreenSize from "../../../../hooks/screenSize/useScreenSize";
import { isPWA } from "../../../../utils/pwa";
import StakingBadgeText from "../../StakingBadgeText";
import { SPACING } from "../../../../constants/design/spacing";
import { BadgeTier } from "../../../../hooks/api/rewards/useRewards";
import StakingBadge from "../../StakingBadge";

interface IAccountModalProps extends IBaseModalProps {
  txHistory?: GetTransactionHistory200ResponseTransactionHistoryInner[];
  txHistoryValidating?: boolean;
  onSyncPWA?: () => void;
  onDepositClick?: () => void;
  onWithdrawClick?: () => void;
  onTransferClick?: () => void;
  onContinueOnboarding?: () => void;
  stakingBadge?: BadgeTier;
}

function AccountModal({
  txHistory = [],
  txHistoryValidating,
  onDepositClick,
  onWithdrawClick,
  onTransferClick,
  onContinueOnboarding,
  onHide,
  onSyncPWA,
  stakingBadge,
  ...others
}: IAccountModalProps) {
  const { t } = useTranslation("app", { keyPrefix: "Header.AccountModal" });
  const stakingBadgeRef = useRef<HTMLDivElement>(null);
  const { explorerURL } = useWallet();
  const { data: accountData, showOnboarding } = useGetAccount();
  const navigate = useNavigate();
  const { accountApiKeyState, account, disconnectAccount, isAccountImported } =
    useContext(AuthContext);
  const { isGeoblocked } = useContext(AuthContext);
  const [showTransfersList, setShowTransfersList] = useState<boolean>(false);

  const { isMobileScreen } = useScreenSize();

  const openBlockExplorer = useCallback(() => {
    if (explorerURL) {
      window.open(`${explorerURL}/address/${account}`);
    }
  }, [account, explorerURL]);

  const openSettings = useCallback(() => {
    navigate(PageEndpointEnum.SETTINGS);
    onHide?.();
  }, [navigate, onHide]);

  const onDisconnect = useCallback(() => {
    disconnectAccount();
    onHide?.();
  }, [disconnectAccount, onHide]);

  const onWithdraw = useCallback(() => {
    onWithdrawClick?.();
  }, [onWithdrawClick]);

  const onDeposit = useCallback(() => {
    onDepositClick?.();
  }, [onDepositClick]);

  const onTransfer = useCallback(() => {
    onTransferClick?.();
  }, [onTransferClick]);

  const txEtaText = useCallback(
    (txType: TxTypeResponse) => {
      switch (txType) {
        case "deposit":
          return t("estimated_deposit_time");
        case "withdraw":
          return t("estimated_withdraw_time");
        case "send":
          return t("estimated_send_time");
        case "receive":
          return t("estimated_receive_time");
        default:
          return undefined;
      }
    },
    [t]
  );

  const txContent = useMemo(() => {
    if (txHistoryValidating) {
      return (
        <SpinnerContainerWrapper height={480}>
          <Spinner />
        </SpinnerContainerWrapper>
      );
    }

    if (txHistory.length === 0) {
      return <div>{t("no_ongoing_transactions_desc")}</div>;
    }

    return (
      <OngoingTransferList>
        {txHistory?.map((tx) => {
          const initiatedTime = moment.unix(
            nanosToSeconds(tx.initiated_timestamp)
          );
          const txEstimatedValue = (
            [
              TxTypeResponse.Deposit,
              TxTypeResponse.YvDeposit,
              TxTypeResponse.YvWithdraw,
              TxTypeResponse.Send,
              TxTypeResponse.Swap,
            ] as TxTypeResponse[]
          ).includes(tx.tx_type)
            ? ONCHAIN_ESTIMATES.deposit.value
            : ONCHAIN_ESTIMATES.withdrawal.value;

          const txEstimatedUnit = (
            [
              TxTypeResponse.Deposit,
              TxTypeResponse.YvDeposit,
              TxTypeResponse.YvWithdraw,
              TxTypeResponse.Send,
              TxTypeResponse.Swap,
            ] as TxTypeResponse[]
          ).includes(tx.tx_type)
            ? ONCHAIN_ESTIMATES.deposit.unit
            : ONCHAIN_ESTIMATES.withdrawal.unit;

          const eta = initiatedTime.add(
            txEstimatedValue as DurationInputArg1,
            txEstimatedUnit as DurationInputArg2
          );
          const diff = moment.duration(eta.diff(moment()));
          const totalDuration = moment.duration(
            txEstimatedValue as DurationInputArg1,
            txEstimatedUnit as DurationInputArg2
          );

          const percentage = Math.round(
            (diff.asMinutes() / totalDuration.asMinutes()) * 100
          );
          const etaText =
            diff.asHours() > 1
              ? `${t("n_hours", { hours: Math.round(diff.asHours()) })}`
              : `${t("n_minutes", { minutes: Math.round(diff.asMinutes()) })}`;

          const collateral = getCollateralAssetByAddress(tx.collateral);
          const decimals = collateral
            ? collateralAssetDecimals[collateral]
            : undefined;
          const val = decimals
            ? ethers.utils.formatUnits(tx.amount, decimals)
            : tx.amount;

          return (
            <OngoingTransfer
              key={tx.l1_tx_hash || tx.l2_tx_hash}
              percentage={percentage}
            >
              <ArrowWrapper type={tx.tx_type}>
                <ArrowDown />
              </ArrowWrapper>
              <div>
                <span>
                  {formatCollateralDecimals(val)} {collateral}
                </span>
                <span>
                  {txEtaText(tx.tx_type)} -{" "}
                  {diff.asMinutes() > 1 ? etaText : t("less_than_1_minute")}
                </span>
              </div>
            </OngoingTransfer>
          );
        })}
      </OngoingTransferList>
    );
  }, [txHistory, txHistoryValidating, txEtaText, t]);

  return (
    <BaseModal
      {...others}
      onHide={() => {
        onHide?.();
        setShowTransfersList(false);
      }}
      noContentPadding
      excludeRefs={[stakingBadgeRef]}
      title={
        <Title showLiquidationIndicator={Boolean(accountData?.in_liquidation)}>
          <div>
            <TitleWrapper>
              {shortenAddress(account || "")}
              <StakingBadgeText
                ref={stakingBadgeRef}
                stakingBadge={stakingBadge}
              />
            </TitleWrapper>
            {accountApiKeyState === AccountStateEnum.OK ? (
              <Username>{accountData?.username}</Username>
            ) : null}
          </div>
        </Title>
      }
    >
      {isGeoblocked ? (
        <Content>
          <AccountItemsContainer>
            <AccountItemRow onClick={onDisconnect}>
              <Disconnect />
              <span>{t("disconnect")}</span>
            </AccountItemRow>
          </AccountItemsContainer>
        </Content>
      ) : (
        <>
          <Content>
            <ExchangeBalanceWrapper
              to={PageEndpointEnum.PORTFOLIO}
              onClick={() => onHide?.()}
            >
              {stakingBadge ? (
                <StakingBadge stakingBadge={stakingBadge} size={64} />
              ) : (
                <GlobeContainer>
                  <Globe />
                </GlobeContainer>
              )}
              <span style={{ marginTop: SPACING.three }}>
                {t("equity_balance")}
              </span>
              <BalanceValue>
                {accountData ? currency(accountData.equity).format() : "---"}
              </BalanceValue>
            </ExchangeBalanceWrapper>
            <DepositItemsContainer>
              {accountData?.in_liquidation ? (
                <Button disabled buttonTheme={ButtonThemeEnum.NEGATIVE}>
                  {t("liquidations_in_progress")}
                </Button>
              ) : isAccountImported ? (
                <Button disabled buttonTheme={ButtonThemeEnum.NEUTRAL2}>
                  {t("import_account_deposits_withdrawals_not_allowed")}
                </Button>
              ) : (
                <>
                  <Button
                    buttonTheme={ButtonThemeEnum.NEUTRAL1}
                    onClick={onDeposit}
                  >
                    <ArrowDown />
                    {t("deposit")}
                  </Button>
                  <div />
                  <Button
                    buttonTheme={ButtonThemeEnum.NEUTRAL1}
                    onClick={onWithdraw}
                  >
                    <ArrowDown style={{ rotate: "180deg" }} />
                    {t("withdraw")}
                  </Button>
                  <div />
                  <Button
                    buttonTheme={ButtonThemeEnum.NEUTRAL1}
                    onClick={onTransfer}
                  >
                    <ArrowRepeat />
                    {t("transfer")}
                  </Button>
                </>
              )}
            </DepositItemsContainer>

            <AccountItemsContainer>
              <AccountItemRow onClick={openBlockExplorer}>
                <Etherscan />
                <span>{t("open_block_explorer")}</span>
                <ArrowOut />
              </AccountItemRow>
              {txHistory.length > 0 ? (
                <AccountItemRow
                  isHighlighted
                  onClick={() => setShowTransfersList(true)}
                >
                  <Dollar />
                  <span>{t("transfers_in_progress")}</span>
                </AccountItemRow>
              ) : null}

              {(accountApiKeyState !== AccountStateEnum.OK ||
                !isMobileScreen) && (
                <AccountItemRow onClick={onSyncPWA}>
                  <Refresh stroke={COLORS.neutral.one} />
                  <span>{t("sync_with_pwa")}</span>
                </AccountItemRow>
              )}
              <AccountItemRow onClick={openSettings}>
                <Settings />
                <span>{t("user_settings")}</span>
              </AccountItemRow>
              {isPWA && isAccountImported ? (
                <AccountItemRow onClick={onDisconnect}>
                  <StopSync />
                  <span>{t("stop_syncing")}</span>
                </AccountItemRow>
              ) : (
                <AccountItemRow onClick={onDisconnect}>
                  <Disconnect />
                  <span>{t("disconnect")}</span>
                </AccountItemRow>
              )}
              {(accountApiKeyState !== AccountStateEnum.OK || showOnboarding) &&
                !isAccountImported && (
                  <EnableTradingButton
                    onClick={() => {
                      onHide?.();
                      onContinueOnboarding?.();
                    }}
                  >
                    {
                      // eslint-disable-next-line no-nested-ternary
                      accountApiKeyState === AccountStateEnum.REQUIRE_PASSWORD
                        ? t("unlock_trading")
                        : accountApiKeyState ===
                          AccountStateEnum.REQUIRE_REGISTER_SIGNING
                        ? t("complete_sign_in")
                        : t("continue_onboarding")
                    }
                  </EnableTradingButton>
                )}
            </AccountItemsContainer>
          </Content>
          <ModalOverlayInfo
            show={showTransfersList}
            onClose={() => setShowTransfersList(false)}
            height={"480px"}
          >
            <OverlayInfoContent>
              <span>{t("transfers_in_progress")}</span>
              {txContent}
            </OverlayInfoContent>
          </ModalOverlayInfo>
        </>
      )}
    </BaseModal>
  );
}

export default AccountModal;
