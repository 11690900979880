import styled, { css } from "styled-components";
import {
  COLORS,
  FIELD_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../constants/design/spacing";

export const InputLabel = styled.div`
  ${FONT_CSS.label.three}
  color: ${TEXT_COLORS.three};
  margin-bottom: ${SPACING.one}px;
`;

export const InputContainer = styled.div`
  padding-bottom: ${SPACING.three}px;
`;

export const InputError = styled.div`
  ${FONT_CSS.label.three}
  color: ${COLORS.negative.one};
  padding-top: ${SPACING.one}px;
`;

export const MaxButton = styled.button`
  ${FONT_CSS.label.three}
  background-color: ${FIELD_COLORS.two};
  color: ${TEXT_COLORS.two};
  border-radius: ${BORDER_RADIUS.five}px;
  height: 100%;
  padding: ${SPACING.two}px ${SPACING.twoHalf}px;
`;

export const ErrorMessage = styled.p`
  ${FONT_CSS.label.three}
  color: ${COLORS.negative.one};
  margin: 0;
  margin-top: ${SPACING.three}px;
  margin-bottom: ${SPACING.two}px;
`;

export const ApprovalSuccessMessage = styled.div`
  ${FONT_CSS.label.three}
  color: ${COLORS.highlight.one};
  margin-bottom: ${SPACING.three}px;
`;

export const Spacer = styled.div`
  margin-top: ${SPACING.three}px;
`;

export const ButtonsContainer = styled.div`
  display: flex;

  > * {
    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const Footer = styled.div`
  ${FONT_CSS.body.five}
  margin-top: ${SPACING.three}px;
  padding: ${SPACING.two}px;
  border-radius: ${BORDER_RADIUS.five}px;
  background-color: ${COLORS.highlight.six};
  color: ${COLORS.highlight.one};
`;

export const DepositAssetDropdownTitle = styled.div`
  ${FONT_CSS.data.three}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 42px;
  color: ${TEXT_COLORS.two};
  > span {
    margin-left: ${SPACING.one}px;
  }
`;

export const DepositAssetDropdownItem = styled(DepositAssetDropdownTitle)<{
  selected: boolean;
}>`
  justify-content: flex-start;
  width: 112px;
  &:hover {
    color: ${TEXT_COLORS.one};
  }

  ${({ selected }) =>
    selected &&
    css`
      color: ${TEXT_COLORS.one};
    `}
`;

export const WithdrawalFeesTooltipWrapper = styled.div`
  display: flex;

  > svg {
    stroke: ${TEXT_COLORS.three};
    margin-left: ${SPACING.one}px;
  }
`;
