import {
  FieldErrors,
  FieldValues,
  UseFormRegisterReturn,
} from "react-hook-form";
import { GetAccount200ResponsePositionsInner } from "../../../codegen-api";
import { IBaseModalProps } from "../../BaseModal";

enum TPSLTabEnum {
  Full = "Full",
  Partial = "Partial",
}

enum NotificationTypeEnum {
  TP = "TP",
  SL = "SL",
}

const Tpsl = {
  Price: "USD",
  Roi: "%ROI",
} as const;

type TpslType = (typeof Tpsl)[keyof typeof Tpsl];

type TPSLTabType = (typeof TPSLTabEnum)[keyof typeof TPSLTabEnum];

type NotificationType =
  (typeof NotificationTypeEnum)[keyof typeof NotificationTypeEnum];

interface ITPSLContentProps {
  actualSlTriggerPrice: number;
  actualTpTriggerPrice: number;
  contractPriceStep: number;
  minAmountSize: number;
  errorMessage?: string;
  errors: FieldErrors<FieldValues>;
  handleSubmit: Function;
  markPrice?: string;
  onConfirmTPSLForPosition: () => void;
  position: GetAccount200ResponsePositionsInner;
  pricePrecision: number;
  amountPrecision: number;
  resetSlInput: () => void;
  resetTpInput: () => void;
  slDropdownOpen: boolean;
  slType: TpslType;
  stopLossExpectedPnl: number;
  stopLossRegister:
    | UseFormRegisterReturn<"slTriggerPrice">
    | UseFormRegisterReturn<"partialSLTriggerPrice">;
  takeProfitExpectedPnl: number;
  takeProfitRegister:
    | UseFormRegisterReturn<"tpTriggerPrice">
    | UseFormRegisterReturn<"partialTPTriggerPrice">;
  tpDropdownOpen: boolean;
  tpType: TpslType;
  setTpDropdownOpen: (value: boolean) => void;
  setTpType: (value: TpslType) => void;
  setSlDropdownOpen: (value: boolean) => void;
  setSlType: (value: TpslType) => void;

  // implement partial position changes
  partialTakeProfitPositionAmountRegister?: UseFormRegisterReturn<"partialTakeProfitPositionAmount">;
  partialStopLossPositionAmountRegister?: UseFormRegisterReturn<"partialStopLossPositionAmount">;

  setMaxPartialTPValue?: (value: string | number) => void;
  setMaxPartialSLValue?: (value: string | number) => void;

  currentTab: NotificationType;
  onSelectTab: (value: string) => void;
}

interface IPartialTPSLContentProps extends ITPSLContentProps {
  onChangePartialTakeProfitSliderValue: (value: number) => void;
  onChangePartialStopLossSliderValue: (value: number) => void;
  positionTakeProfitSliderAmount: number;
  positionStopLossSliderAmount: number;
}

interface ITPSLFormContentProps
  extends Omit<
    ITPSLContentProps,
    "currentTab" | "onSelectTab" | "amountPrecision" | "minAmountSize"
  > {}

interface IPartialTPSLFormContentProps
  extends Omit<IPartialTPSLContentProps, "onSelectTab"> {}

interface ITPSLNotificationTextProps {
  actualTriggerPrice: number;
  pricePrecision: number;
  currencyExpectedPnl: number;
  noticationType: "SL" | "TP";
}

enum OrderTypeEnum {
  Market = "market",
  Limit = "limit",
}

enum ModalTypeEnum {
  Close = "close",
  Partial = "partial",
}

type ModalType = (typeof ModalTypeEnum)[keyof typeof ModalTypeEnum];

type OrderType = (typeof OrderTypeEnum)[keyof typeof OrderTypeEnum];

interface IOrderTypes {
  takeProfit: OrderType;
  stopLoss: OrderType;
}

interface IClosePositionModal extends IBaseModalProps {
  position?: GetAccount200ResponsePositionsInner;
  modalType?: ModalType;
}

export type {
  TpslType,
  TPSLTabType,
  ITPSLContentProps,
  ITPSLFormContentProps,
  ITPSLNotificationTextProps,
  OrderType,
  IOrderTypes,
  IClosePositionModal,
  ModalType,
  NotificationType,
  IPartialTPSLFormContentProps,
  IPartialTPSLContentProps,
};

export {
  Tpsl,
  TPSLTabEnum,
  NotificationTypeEnum,
  OrderTypeEnum,
  ModalTypeEnum,
};
