import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SideResponse } from "../../../../codegen-api";
import SegmentedControl, {
  ISegmentedControlConfig,
} from "../../../shared/SegmentedControl";
import {
  COLORS,
  BACKGROUND_COLORS,
  TEXT_COLORS,
} from "../../../../constants/design/colors";
import { FONT_SIZE } from "../../../../constants/design/fontSize";

interface IOrderSideSegmentedControlProps {
  orderSide: SideResponse;
  setOrderSide: (orderSide: SideResponse) => void;
}

function OrderSideSegmentedControl({
  orderSide,
  setOrderSide,
}: IOrderSideSegmentedControlProps) {
  const { t: commonFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.Common",
  });

  const buySellSegmentProps = useMemo(() => {
    const config: ISegmentedControlConfig = {
      theme: "outline",
      color:
        orderSide === SideResponse.Sell
          ? COLORS.negative.one
          : COLORS.positive.one,
      widthType: "fullWidth",
      backgroundColor: BACKGROUND_COLORS.eight,
      activeBackgroundColor:
        orderSide === SideResponse.Sell
          ? COLORS.negative.five
          : COLORS.positive.five,
      borderRadius: "10px",
      button: {
        height: 40,
        fontSize: FONT_SIZE.two,
      },
    };
    return {
      config,
      segments: [
        {
          value: String(SideResponse.Buy),
          display: commonFormTranslations("buy"),
          textColor:
            orderSide === SideResponse.Buy
              ? COLORS.positive.one
              : TEXT_COLORS.three,
        },
        {
          value: String(SideResponse.Sell),
          display: commonFormTranslations("sell"),
          textColor:
            orderSide === SideResponse.Sell
              ? COLORS.negative.one
              : TEXT_COLORS.three,
        },
      ],
    };
  }, [orderSide, commonFormTranslations]);

  return (
    <SegmentedControl
      segments={buySellSegmentProps.segments}
      value={String(orderSide)}
      onSelect={(v) => setOrderSide(v as SideResponse)}
      config={buySellSegmentProps.config}
    />
  );
}

export default OrderSideSegmentedControl;
