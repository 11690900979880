import { Row } from "react-bootstrap";
import styled from "styled-components";
import { BACKGROUND_COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../constants/design/spacing";

export const MarketWrapper = styled(Row)`
  margin: 0;

  height: 100%;

  > div {
    display: flex;
  }

  > label {
    margin: 0;
    padding: 0;
  }

  > * {
    margin: auto 0;
  }
`;

export const Data = styled.div`
  background-color: ${BACKGROUND_COLORS.four};
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: ${BORDER_RADIUS.five}px;
  display: flex;
  vertical-align: middle;
  color: ${TEXT_COLORS.two};
  justify-content: center;
  margin: auto 0;
  margin-right: ${SPACING.three}px;

  img {
    margin: auto;
    width: 16px;
    height: 16px;
  }
`;

export const MarketDataWrapper = styled.div`
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: ${SPACING.three}px;
  }

  p {
    margin-bottom: 0;
    font-size: ${FONT_SIZE.oneHalf};
  }
`;
