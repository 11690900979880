import { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import useWallet from "../../wallet/useWallet";
import contractAddresses from "../../../constants/addresses/addresses.json";
import { AuthContext } from "../../../contexts/AuthContext";

export type IEpochStakeData = {
  epoch: number;
  unstakeEpoch: number;
  stakedAmount: string;
  unstakedAmount: string;
};

type IStakedAccount = {
  id: string;
  totalStakedAmount: string;
  epochStakeAmounts?: IEpochStakeData[];
};

const useSubgraphData = () => {
  const { account } = useContext(AuthContext);
  const vaultAddress = contractAddresses.mainnet.aevoStaking;
  const [accountStakingData, setAccountStakingData] =
    useState<IStakedAccount>();
  const [vaultStakingData, setVaultStakingData] = useState<IStakedAccount>();
  const SUBGRAPH_URI = process.env.REACT_APP_AEVO_SUBGRAPH_URI || "";

  const fetchSubgraphData = useCallback(async () => {
    try {
      const accountsToQuery = account
        ? `["${account.toLowerCase()}", "${vaultAddress.toLowerCase()}"]`
        : `["${vaultAddress.toLowerCase()}"]`;
      const query = `
        {
            stakedAccounts(where: {id_in: ${accountsToQuery}}) {
                id,
                totalStakedAmount,
                epochStakeAmounts {
                    stakedAmount,
                    unstakedAmount,
                    unstakeEpoch,
                    epoch
                }
            }
        }
    `;
      const response = await axios.post(SUBGRAPH_URI, {
        query,
      });

      const stakedAccounts = response.data.data
        .stakedAccounts as IStakedAccount[];

      const responseAccountData = account
        ? stakedAccounts.find(
            (stakedAccount: IStakedAccount) =>
              stakedAccount.id === account.toLowerCase()
          )
        : undefined;

      const responseVaultData = stakedAccounts.find(
        (stakedAccount: IStakedAccount) =>
          stakedAccount.id === vaultAddress.toLowerCase()
      );
      setAccountStakingData(responseAccountData);
      setVaultStakingData(responseVaultData);
    } catch (error: any) {
      // do nothing
    }
  }, [SUBGRAPH_URI, account, vaultAddress]);

  useEffect(() => {
    fetchSubgraphData();
  }, [fetchSubgraphData]);

  return {
    accountStakingData,
    vaultStakingData,
    fetchSubgraphData,
  };
};

export default useSubgraphData;
