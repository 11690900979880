import { useTranslation } from "react-i18next";
import { SideResponse } from "../../../../codegen-api";
import SegmentedControl from "../../../shared/SegmentedControl";
import {
  TEXT_COLORS,
  LAYER_COLORS,
  COLORS,
} from "../../../../constants/design/colors";
import { FONT_SIZE } from "../../../../constants/design/fontSize";

interface IOrderSideSegmentedControlProps {
  orderDirection: SideResponse;
  setOrderDirection: (orderDirection: SideResponse) => void;
}

function OrderSideSegmentedControl({
  orderDirection,
  setOrderDirection,
}: IOrderSideSegmentedControlProps) {
  const { t: commonFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.Common",
  });

  return (
    <SegmentedControl
      segments={[
        {
          value: String(SideResponse.Buy),
          display: commonFormTranslations("buy"),
          textColor:
            orderDirection === SideResponse.Buy
              ? COLORS.positive.one
              : TEXT_COLORS.three,
        },
        {
          value: String(SideResponse.Sell),
          display: commonFormTranslations("sell"),
          textColor:
            orderDirection === SideResponse.Sell
              ? COLORS.negative.one
              : TEXT_COLORS.three,
        },
      ]}
      value={String(orderDirection)}
      onSelect={(value) => setOrderDirection(value as SideResponse)}
      config={{
        theme: "outline",
        color:
          orderDirection === SideResponse.Sell
            ? COLORS.negative.one
            : COLORS.positive.one,
        widthType: "fullWidth",
        backgroundColor: LAYER_COLORS.two,
        activeBackgroundColor:
          orderDirection === SideResponse.Sell
            ? COLORS.negative.five
            : COLORS.positive.five,
        borderRadius: "10px",
        button: {
          height: 40,
          fontSize: FONT_SIZE.two,
        },
      }}
    />
  );
}

export default OrderSideSegmentedControl;
