import moment from "moment";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { useGeoblock } from "../../hooks/api/geoblock/useGeoblock";
import useWallet from "../../hooks/wallet/useWallet";
import {
  AuthInfoList,
  IAuthInfo,
  IDecryptedAuthInfo,
  IEncryptedAuthInfo,
} from "../../interfaces/AuthInfo";
import {
  DecryptAccountStatusEnum,
  decryptEncryptedAccountInfo,
} from "../../utils/encryption/accountPasswordEncryption";
import { encrypt } from "../../utils/encryption/encryption";
import { idUser, stopIdUser } from "../../utils/mixpanel";
// import { decrypt, encrypt } from "../../utils/encryption/encryption";

export enum AccountStateEnum {
  REQUIRE_CONNECT = "REQUIRE_CONNECT",
  REQUIRE_REGISTER_SIGNING = "REQUIRE_REGISTER_SIGNING",
  REQUIRE_PASSWORD = "REQUIRE_PASSWORD",
  OK = "OK",
}

export type AuthInfoType = "api" | "signing" | "all";

interface IAuthContextType {
  authInfoList: AuthInfoList;
  accountSigningKeyState: AccountStateEnum;
  accountApiKeyState: AccountStateEnum;
  manualImportAccount: (authInfo: IEncryptedAuthInfo, password: string) => void;
  manualSyncAccount: (authInfo: IDecryptedAuthInfo) => void;
  isGeoblocked?: boolean;
  getRawAccountAuthInfo: () => IAuthInfo | undefined;
  getImportedAccountAddress: () => string | null;
  account?: string | null;
  isAccountImported: boolean;
  apiKey?: string;
  apiSecret?: string;
  signingKey?: string;
  expiry?: string;
  isEncryptionEnabled: boolean;
  showInvalidRefModal?: boolean;

  setAuthInfoList: (info: AuthInfoList) => void;
  encryptAccount: (password: string) => boolean;
  reconnectImportedAccount: () => void;
  unlockAccount: (password: string) => boolean;
  disconnectAccount: () => void;
  // If password is provided, encrypt the entire auth info before storing it locally
  enableTrading: (info: IAuthInfo, isRememberMeChecked: boolean) => void;
  deleteAuthInfo: (authInfo: AuthInfoType) => void;
  setShowInvalidRefModal: (show: boolean) => void;
}

interface IAuthContextProviderProps {
  children: ReactElement;
}

export const AuthContext = React.createContext<IAuthContextType>({
  authInfoList: {},
  accountSigningKeyState: AccountStateEnum.REQUIRE_CONNECT,
  accountApiKeyState: AccountStateEnum.REQUIRE_CONNECT,
  manualImportAccount: () => undefined,
  manualSyncAccount: () => undefined,
  isGeoblocked: undefined,
  getRawAccountAuthInfo: () => undefined,
  getImportedAccountAddress: () => null,
  account: undefined,
  isAccountImported: false,
  apiKey: undefined,
  apiSecret: undefined,
  signingKey: undefined,
  expiry: undefined,
  showInvalidRefModal: false,
  isEncryptionEnabled: false,

  setAuthInfoList: () => null,
  encryptAccount: () => false,
  reconnectImportedAccount: () => undefined,
  unlockAccount: () => false,
  disconnectAccount: () => false,
  enableTrading: () => null,
  deleteAuthInfo: () => null,
  setShowInvalidRefModal: () => null,
});

export function AuthContextProvider({ children }: IAuthContextProviderProps) {
  const [authInfoList, setAuthInfoList] = useState<AuthInfoList>({});
  // TODO: - Use imported account if available instead of from wallet
  const [importedAccountAddress, setImportedAccount] = useState<string>();
  const { account: walletAccount, deactivate } = useWallet();
  const { data: geoblockData } = useGeoblock();

  // password to unlock the CURRENT account
  const [localPassword, setLocalPassword] = useState<string>();
  const [showInvalidRefModal, setShowInvalidRefModal] =
    useState<boolean>(false);

  const isGeoblocked = useMemo(
    () => geoblockData?.restricted,
    [geoblockData?.restricted]
  );

  const storeAuthInfoToLocalStorage = useCallback((info: AuthInfoList) => {
    window.localStorage.setItem(
      LocalStorageKeyEnum.AUTH_INFO,
      JSON.stringify(info)
    );
  }, []);

  const getRawAccountAuthInfo = useCallback(() => {
    const acc = importedAccountAddress || walletAccount;
    if (!authInfoList || !acc) {
      return undefined;
    }

    // Auto set account
    const authInfo = authInfoList[acc];
    if (!authInfo) {
      return undefined;
    }

    if (!authInfo.account) {
      authInfo.account = acc;

      // Resave with the account. This is for legacy auth info
      const newAuthInfo: AuthInfoList = {
        ...authInfoList,
        [authInfo.account]: {
          ...authInfo,
          account: authInfo.account,
        },
      };
      storeAuthInfoToLocalStorage(newAuthInfo);
    }

    return authInfo;
  }, [
    authInfoList,
    importedAccountAddress,
    storeAuthInfoToLocalStorage,
    walletAccount,
  ]);

  const decryptedAccountAuthInfo = useMemo(() => {
    const authInfo = getRawAccountAuthInfo();
    if (!authInfo) {
      return undefined;
    }

    // Return decrypted auth info if it is encrypted
    if (authInfo.encrypted && localPassword) {
      const { decryptedInfo } = decryptEncryptedAccountInfo(
        authInfo,
        localPassword
      );
      // Spread the properties into a new object to ensure a new reference is created
      return decryptedInfo
        ? ({ ...decryptedInfo } as IDecryptedAuthInfo)
        : undefined;
    }

    // Return a new object with the properties of authInfo
    return { ...authInfo } as IDecryptedAuthInfo | IEncryptedAuthInfo;
  }, [getRawAccountAuthInfo, localPassword]);

  const isEncrypted = useMemo(() => {
    const acc = importedAccountAddress || walletAccount;
    const localAuthInfo = window.localStorage.getItem(
      LocalStorageKeyEnum.AUTH_INFO
    );
    // Merge both local and session auth info
    if (localAuthInfo && acc) {
      const info = JSON.parse(localAuthInfo) as AuthInfoList;
      const accountInfo = info[acc];
      if (accountInfo) {
        return Boolean(accountInfo.encrypted && accountInfo.passwordProtected);
      }
    }
    return false;
    // update when authInfoList updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedAccountAddress, walletAccount, authInfoList]);

  const accountApiKeyState = useMemo(() => {
    // TODO: Load from wallet if required
    if (!walletAccount && !importedAccountAddress) {
      return AccountStateEnum.REQUIRE_CONNECT;
    }

    if (!decryptedAccountAuthInfo) {
      return AccountStateEnum.REQUIRE_REGISTER_SIGNING;
    }

    // If password protected and no password
    if (!!decryptedAccountAuthInfo?.passwordProtected && !localPassword) {
      return AccountStateEnum.REQUIRE_PASSWORD;
    }

    const authInfo = decryptedAccountAuthInfo as IDecryptedAuthInfo;
    if (!authInfo.apiKey || !authInfo.apiSecret) {
      return AccountStateEnum.REQUIRE_REGISTER_SIGNING;
    }

    return AccountStateEnum.OK;
  }, [
    decryptedAccountAuthInfo,
    importedAccountAddress,
    localPassword,
    walletAccount,
  ]);

  const accountSigningKeyState = useMemo(() => {
    // TODO: Load from wallet if required
    if (!walletAccount && !importedAccountAddress) {
      return AccountStateEnum.REQUIRE_CONNECT;
    }

    if (!decryptedAccountAuthInfo) {
      return AccountStateEnum.REQUIRE_REGISTER_SIGNING;
    }

    const authInfo = decryptedAccountAuthInfo as IDecryptedAuthInfo;

    // If password protected and no password
    if (!!decryptedAccountAuthInfo?.passwordProtected && !localPassword) {
      return AccountStateEnum.REQUIRE_PASSWORD;
    }

    // you cannot perform /POST actions without api or signing key
    if (!authInfo.signingKey || !authInfo.apiKey || !authInfo.apiSecret) {
      return AccountStateEnum.REQUIRE_REGISTER_SIGNING;
    }

    return AccountStateEnum.OK;
  }, [
    decryptedAccountAuthInfo,
    importedAccountAddress,
    localPassword,
    walletAccount,
  ]);

  // ID a user on mix panel
  useEffect(() => {
    const acc = decryptedAccountAuthInfo?.account || walletAccount;
    if (
      (accountApiKeyState === AccountStateEnum.OK ||
        accountSigningKeyState === AccountStateEnum.OK) &&
      acc
    ) {
      idUser(acc);
    } else {
      stopIdUser();
    }
  }, [
    accountApiKeyState,
    accountSigningKeyState,
    decryptedAccountAuthInfo?.account,
    walletAccount,
  ]);

  const getImportedAccountAddress = useCallback(() => {
    const importedAcc = window.localStorage.getItem(
      LocalStorageKeyEnum.IMPORTED_ACCOUNT_ADDRESS
    );
    return importedAcc;
  }, []);

  // Whenever local password is set, update session storage with the decrypted info
  useEffect(() => {
    if (decryptedAccountAuthInfo && localPassword) {
      let newInfo: AuthInfoList = {};
      // If local password, update session storage with the decrypted info
      newInfo = {
        [decryptedAccountAuthInfo.account]: {
          ...decryptedAccountAuthInfo,
          encrypted: false,
          passwordProtected: false,
        } as IDecryptedAuthInfo,
      };
      sessionStorage.setItem(
        LocalStorageKeyEnum.AUTH_INFO,
        JSON.stringify(newInfo)
      );
    }
  }, [decryptedAccountAuthInfo, localPassword]);

  // When context first initializes, check if there is authInfo in localStorage
  // This is the only part when localStorage is read
  useEffect(() => {
    const localAuthInfo = window.localStorage.getItem(
      LocalStorageKeyEnum.AUTH_INFO
    );

    // Session auth info that is meant to be persisted across sessions
    const sessionAuthInfo = sessionStorage.getItem(
      LocalStorageKeyEnum.AUTH_INFO
    );

    const importedAcc = getImportedAccountAddress();

    let authInfo: AuthInfoList = {};

    // Merge both local and session auth info
    if (localAuthInfo) {
      authInfo = JSON.parse(localAuthInfo) as AuthInfoList;
    }
    if (sessionAuthInfo) {
      authInfo = {
        ...authInfo,
        ...(JSON.parse(sessionAuthInfo) as AuthInfoList),
      };
    }

    if (authInfo) {
      setAuthInfoList(authInfo);
    }

    if (importedAcc) {
      setImportedAccount(importedAcc);
    }
  }, [getImportedAccountAddress]);

  // Set password and store to local storage
  const encryptAccount = useCallback(
    (password: string) => {
      if (!decryptedAccountAuthInfo) {
        return false;
      }

      const updatedAuthInfoList: AuthInfoList = { ...(authInfoList || {}) };

      // Make sure cannot encrypt encrypted account
      if (decryptedAccountAuthInfo.encrypted) {
        return false;
      }

      try {
        // Set password protected
        decryptedAccountAuthInfo.passwordProtected = true;
        const { encryptedText, salt, iv } = encrypt(
          JSON.stringify(decryptedAccountAuthInfo),
          password
        );

        const encryptedInfo: IEncryptedAuthInfo = {
          encrypted: true,
          passwordProtected: true,
          encryptedBundle: encryptedText,
          salt,
          iv,
          account: decryptedAccountAuthInfo.account,
        };

        updatedAuthInfoList[decryptedAccountAuthInfo.account] = {
          ...encryptedInfo,
        };

        setAuthInfoList(updatedAuthInfoList);
        setLocalPassword(password);
        storeAuthInfoToLocalStorage(updatedAuthInfoList);
        return true;
      } catch (error) {
        return false;
      }
    },
    [decryptedAccountAuthInfo, authInfoList, storeAuthInfoToLocalStorage]
  );

  const unlockAccount = useCallback(
    (password: string) => {
      if (!decryptedAccountAuthInfo || !decryptedAccountAuthInfo.encrypted) {
        return false;
      }

      // Check password valid before storing password
      const { status } = decryptEncryptedAccountInfo(
        decryptedAccountAuthInfo,
        password
      );

      if (status === DecryptAccountStatusEnum.OK) {
        setLocalPassword(password);
        return true;
      }
      return false;
    },
    [decryptedAccountAuthInfo]
  );

  // TODO: - This is only for metamask
  const enableTrading = useCallback(
    (info: IAuthInfo, isRememberMeChecked: boolean) => {
      const updatedAuthInfoList: AuthInfoList = { ...authInfoList } || {};
      updatedAuthInfoList[info.account] = info;
      setAuthInfoList(updatedAuthInfoList);

      if (isRememberMeChecked) {
        // Just save
        storeAuthInfoToLocalStorage(updatedAuthInfoList);
      }
    },
    [authInfoList, storeAuthInfoToLocalStorage]
  );

  const reconnectImportedAccount = useCallback(() => {
    const acc = getImportedAccountAddress();
    if (acc) {
      setImportedAccount(acc);
    }
  }, [getImportedAccountAddress]);

  const manualImportAccount = useCallback(
    (encryptedAuthInfo: IEncryptedAuthInfo, password: string) => {
      const updatedAuthInfoList: AuthInfoList = { ...(authInfoList || {}) };
      updatedAuthInfoList[encryptedAuthInfo.account] = {
        ...encryptedAuthInfo,
      };

      setAuthInfoList(updatedAuthInfoList);
      setLocalPassword(password);
      storeAuthInfoToLocalStorage(updatedAuthInfoList);

      // Set imported account
      setImportedAccount(encryptedAuthInfo.account);
      window.localStorage.setItem(
        LocalStorageKeyEnum.IMPORTED_ACCOUNT_ADDRESS,
        encryptedAuthInfo.account
      );
    },
    [authInfoList, storeAuthInfoToLocalStorage]
  );

  const manualSyncAccount = useCallback(
    (decryptedAuthInfo: IDecryptedAuthInfo) => {
      const updatedAuthInfoList: AuthInfoList = { ...(authInfoList || {}) };
      updatedAuthInfoList[decryptedAuthInfo.account] = {
        ...decryptedAuthInfo,
      };

      setAuthInfoList(updatedAuthInfoList);
      storeAuthInfoToLocalStorage(updatedAuthInfoList);

      // Set imported account
      setImportedAccount(decryptedAuthInfo.account);
      window.localStorage.setItem(
        LocalStorageKeyEnum.IMPORTED_ACCOUNT_ADDRESS,
        decryptedAuthInfo.account
      );
    },
    [authInfoList, storeAuthInfoToLocalStorage]
  );

  const disconnectAccount = useCallback(async () => {
    await deactivate();
    setImportedAccount(undefined);
    setLocalPassword(undefined);
  }, [deactivate]);

  const deleteAuthInfo = useCallback(
    (type: AuthInfoType) => {
      if (decryptedAccountAuthInfo) {
        const updatedAuthInfoList: AuthInfoList = { ...authInfoList } || {};
        const accountInfo = updatedAuthInfoList[
          decryptedAccountAuthInfo.account
        ] as IDecryptedAuthInfo;

        switch (type) {
          case "api":
            if (accountInfo.apiKey && accountInfo.apiSecret) {
              delete accountInfo.apiKey;
              delete accountInfo.apiSecret;
              updatedAuthInfoList[decryptedAccountAuthInfo.account] =
                accountInfo;
            }
            break;
          case "signing":
            if (accountInfo.signingKey || accountInfo.expiry) {
              delete accountInfo.signingKey;
              delete accountInfo.expiry;
              updatedAuthInfoList[decryptedAccountAuthInfo.account] =
                accountInfo;
            }
            break;
          case "all":
            delete updatedAuthInfoList[decryptedAccountAuthInfo.account];
            break;
          default:
            // handle default case if necessary
            break;
        }

        setAuthInfoList({ ...updatedAuthInfoList });
        storeAuthInfoToLocalStorage(updatedAuthInfoList);
      }

      if (importedAccountAddress) {
        setImportedAccount(undefined);
        window.localStorage.removeItem(
          LocalStorageKeyEnum.IMPORTED_ACCOUNT_ADDRESS
        );
      }
    },
    [
      decryptedAccountAuthInfo,
      importedAccountAddress,
      authInfoList,
      storeAuthInfoToLocalStorage,
    ]
  );

  // Checks for signing key expiry in 1 minute intervals, or when geoblocked
  useEffect(() => {
    const exp = decryptedAccountAuthInfo?.encrypted
      ? undefined
      : decryptedAccountAuthInfo?.expiry;
    if (exp) {
      const checkExpiry = () => {
        const hasExpired = moment().unix() >= Number(exp);
        if (hasExpired || isGeoblocked) deleteAuthInfo("signing");
      };
      // Check Expiry once, then repeat every minute
      checkExpiry();
      const authChecker = setInterval(checkExpiry, 1000 * 60);

      return () => clearInterval(authChecker);
    }

    return () => {};
  }, [decryptedAccountAuthInfo, deleteAuthInfo, isGeoblocked]);

  return (
    <AuthContext.Provider
      value={{
        authInfoList,
        isGeoblocked,
        account: decryptedAccountAuthInfo?.account || walletAccount,
        isAccountImported: !walletAccount,
        getRawAccountAuthInfo,
        getImportedAccountAddress,
        apiKey: decryptedAccountAuthInfo?.encrypted
          ? undefined
          : decryptedAccountAuthInfo?.apiKey,
        apiSecret: decryptedAccountAuthInfo?.encrypted
          ? undefined
          : decryptedAccountAuthInfo?.apiSecret === undefined ||
            decryptedAccountAuthInfo?.apiSecret === ""
          ? decryptedAccountAuthInfo?.apiKey
          : decryptedAccountAuthInfo?.apiSecret,
        signingKey: decryptedAccountAuthInfo?.encrypted
          ? undefined
          : decryptedAccountAuthInfo?.signingKey,
        expiry: decryptedAccountAuthInfo?.encrypted
          ? undefined
          : decryptedAccountAuthInfo?.expiry,
        isEncryptionEnabled: isEncrypted,
        accountSigningKeyState,
        accountApiKeyState,
        manualImportAccount,
        manualSyncAccount,
        encryptAccount,
        unlockAccount,
        reconnectImportedAccount,
        disconnectAccount,
        showInvalidRefModal,
        setAuthInfoList,
        enableTrading,
        deleteAuthInfo,
        setShowInvalidRefModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
