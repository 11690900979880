import useSWR from "swr";
import { GetLeaderboard200Response } from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { publicApi } from "../../../services/api/apiFetcher";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";
import { AssetResponse } from "../../../utils/asset";

export interface IGetLeaderboardProps {
  account?: string;
  asset?: AssetResponse;
  limit: number;
  startTime?: number;
  endTime?: number;
  sort?: string;
  campaignName?: string;
}

export const useGetLeaderboard = ({
  account,
  asset,
  limit,
  startTime,
  endTime,
  sort,
  campaignName,
}: IGetLeaderboardProps) => {
  // Get API Key
  const fetcher = publicApi();

  // Check if startTime is valid (less than current time)
  const isStartTimeValid = !startTime || startTime / 1_000_000 < Date.now(); // converting current time to nanoseconds

  const swr = useSWR<GetLeaderboard200Response, Error>(
    isStartTimeValid
      ? [
          account,
          asset,
          startTime,
          endTime,
          limit,
          sort,
          campaignName,
          APIEndpointEnum.LEADERBOARD,
        ]
      : undefined,
    async () => {
      const d = (
        await (
          await fetcher.getLeaderboard(
            limit,
            asset,
            startTime,
            endTime,
            sort,
            campaignName
          )
        )()
      ).data;
      return d;
    },
    {
      use: [useLoggerMiddleware],
    }
  );

  return swr;
};
