import styled from "styled-components";
import { TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_STYLE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";

export const HeaderSubText = styled.p`
  ${FONT_STYLE.body.three}
  color: ${TEXT_COLORS.two};

  margin-bottom: 0;
  padding: ${SPACING.three}px;
  padding-bottom: 0;

  a {
    text-decoration: none;
    color: ${TEXT_COLORS.one};
  }
`;
