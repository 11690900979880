import { useEffect, useMemo } from "react";
import { TimePeriodEnum } from "../../interfaces/TimePeriod";
import { useInstrumentTradeHistory } from "../api/tradeHistory/useInstrumentTradeHistory";
import { useTradesStore } from "./public/store/useTradesStore";

const useTradesWSS = (
  instrument?: string,
  period: TimePeriodEnum = TimePeriodEnum.WEEKLY,
  populatePastData: boolean = false,
  maxTrades: number = 50
) => {
  const tradeHistories = useTradesStore((state) =>
    instrument ? state.trades[instrument] || [] : []
  );

  const addTradeInstruments = useTradesStore(
    (state) => state.addTradeInstruments
  );
  const removeTradeInstruments = useTradesStore(
    (state) => state.removeTradeInstruments
  );

  useEffect(() => {
    if (instrument) {
      addTradeInstruments(instrument);
      return () => {
        removeTradeInstruments(instrument);
      };
    }
    return () => {};
  }, [addTradeInstruments, instrument, removeTradeInstruments]);

  const { data: instrumentTradeHistoryData, isValidating } =
    useInstrumentTradeHistory(
      populatePastData ? instrument : undefined,
      period
    );

  const aggregatedTradeHistories = useMemo(() => {
    const histories = [...(instrumentTradeHistoryData || [])];

    tradeHistories.forEach((h) => {
      const exists = histories.some((hist) => hist.trade_id === h.trade_id);
      if (!exists) {
        histories.push(h);
      }
    });

    return histories
      .sort((a, b) => Number(b.created_timestamp) - Number(a.created_timestamp))
      .slice(0, maxTrades);
  }, [instrumentTradeHistoryData, maxTrades, tradeHistories]);

  return {
    data: aggregatedTradeHistories,
    loading: !instrumentTradeHistoryData && isValidating,
  };
};

export default useTradesWSS;
