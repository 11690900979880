import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  ICON_COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../../constants/design/colors";
import { FONT_CSS } from "../../../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../../constants/design/spacing";
import { ArrowWrapper } from "../../../TransactionHistory/style";

export const Title = styled.div<{
  showLiquidationIndicator?: boolean;
}>`
  ${FONT_CSS.header.seven}
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${SPACING.one}px;
  ${({ showLiquidationIndicator }) =>
    showLiquidationIndicator &&
    `&:before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: ${BORDER_RADIUS.five}px;
      background-color: ${COLORS.negative.one};
      margin-right: ${SPACING.three}px;
    }`}
`;

export const Content = styled.div`
  width: 312px;
`;

export const ExchangeBalanceWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${SPACING.four}px;
  color: ${TEXT_COLORS.three};
  cursor: pointer;
  text-decoration: none;

  ${FONT_CSS.label.three}

  &:hover {
    color: ${TEXT_COLORS.three};
  }

  img {
    width: ${SPACING.five}px;
    height: ${SPACING.five}px;
  }
`;

export const GlobeContainer = styled.div`
  width: 64px;
  height: 64px;
  background-color: ${COLORS.highlight.five};
  border-radius: 100px;
  border: 1px solid ${COLORS.highlight.one};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    stroke: ${COLORS.highlight.one};
  }
`;

export const BalanceValue = styled.span`
  margin-top: ${SPACING.two}px;
  ${FONT_CSS.data.one}
  color: ${TEXT_COLORS.one};
`;

export const DepositItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${SPACING.three}px;

  > div {
    width: ${SPACING.two}px;
  }

  button {
    ${FONT_CSS.label.three}
    flex: 1;
    color: ${TEXT_COLORS.two};
    display: flex;
    flex-direction: column;
    padding: ${SPACING.two}px;

    svg {
      width: 16px;
      height: 16px;
      margin-bottom: ${SPACING.one}px;
    }
  }
`;

export const AccountItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccountItemRow = styled.button<{ isHighlighted?: boolean }>`
  display: flex;
  align-items: center;
  height: ${COMPONENTS.accountModalRowHeight}px;
  padding: 0 ${SPACING.three}px;
  margin: ${SPACING.three}px;
  margin-bottom: 0;

  &:last-child {
    margin-bottom: ${SPACING.three}px;
  }

  border-radius: ${BORDER_RADIUS.five}px;
  background-color: ${LAYER_COLORS.one};

  ${FONT_CSS.body.three}
  color: ${TEXT_COLORS.two};
  transition: all 0.2s ease-in-out;

  span {
    padding: 0 ${SPACING.two}px;
  }

  svg {
    opacity: 0.48;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    color: ${TEXT_COLORS.one};
    svg {
      opacity: 1;
    }
  }

  svg:first-child {
    width: 32px;
    height: 32px;
    padding: ${SPACING.two}px;
    border-radius: 10px;
    background-color: ${BORDER_COLORS.one};
  }

  ${({ isHighlighted }) =>
    isHighlighted &&
    `
    opacity: 1;
    color: ${COLORS.blue.one};

    svg:first-child {
      background-color: ${COLORS.blue.two};
      
      > path {
        stroke: ${COLORS.blue.one};
      }
    }
  `}
`;

export const EnableTradingButton = styled.div`
  cursor: pointer;
  color: ${COLORS.highlight.one};
  background-color: ${COLORS.highlight.six};
  margin: ${SPACING.three}px;
  padding: ${SPACING.three}px;
  text-align: center;
  border-radius: ${BORDER_RADIUS.five}px;
  ${FONT_CSS.body.three}

  transition: opacity 0.2s ease-in-out;
  &:hover {
    outline: 1px solid ${COLORS.highlight.one};
  }
`;

export const OngoingTransferList = styled.div`
  width: 100%;
`;

export const OngoingTransfer = styled.div<{ percentage?: number }>`
  display: inline-flex;
  padding: ${SPACING.two}px;
  background-color: ${LAYER_COLORS.three};
  border-radius: ${BORDER_RADIUS.two}px;
  width: 100%;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    width: ${({ percentage }) => percentage}%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: ${BACKGROUND_COLORS.four};
  }

  > * {
    margin: auto 0;
  }

  > ${ArrowWrapper} {
    margin-right: ${SPACING.two}px;
    background-color: ${COLORS.blue.two};

    svg > path {
      stroke: ${COLORS.blue.one};
    }
  }

  > div:not(${ArrowWrapper}) {
    ${FONT_CSS.body.five}
    display: flex;
    flex-direction: column;

    span:first-of-type {
      color: ${TEXT_COLORS.one};
      margin-bottom: ${SPACING.one}px;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: ${SPACING.two}px;
  }
`;

export const Username = styled.div`
  ${FONT_CSS.body.five}
  color: ${TEXT_COLORS.three};
  display: flex;
`;

export const TooltipWrapper = styled.div<{ stroke?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: ${SPACING.one}px;
  svg {
    stroke: ${({ stroke }) => stroke ?? ICON_COLORS.three};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: ${SPACING.one}px;
`;
