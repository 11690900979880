export enum PaginationPerPageOptionsEnum {
  TEN = 10,
  TWENTY_FIVE = 25,
  FIFTY = 50,
}

export interface ICommonPagination {
  totalPages: number;
  totalItems: number;
}
