import styled, { css } from "styled-components";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../../constants/design/fontWeight";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../constants/design/spacing";
import { foregroundComponent } from "../../../style";
import { AccountDropdownStateEnum } from "./type";

export const ButtonContent = styled.button<{
  state?: "transfersInProgress" | "mmpTriggered" | "connectWallet";
  tradingEnabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${TEXT_COLORS.one};
  line-height: 1;
  padding: ${SPACING.three}px ${SPACING.twoHalf}px;
  font-size: ${FONT_SIZE.one} !important;
  font-weight: ${FONT_WEIGHT.medium};
  border-radius: ${BORDER_RADIUS.five}px;

  ${({ tradingEnabled }) =>
    !tradingEnabled &&
    css`
      border: 1px solid transparent;
    `}
`;

export const SignInContent = styled(ButtonContent)<{ inLiquidation?: boolean }>`
  background: ${({ inLiquidation }) =>
    inLiquidation ? COLORS.negative.five : COLORS.highlight.five};
  color: ${({ inLiquidation }) =>
    inLiquidation ? COLORS.negative.one : COLORS.highlight.one};
  white-space: nowrap;
`;

export const SignInContentDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.one}px;
`;

export const SignInTitle = styled.div`
  ${FONT_CSS.label.three}
`;

export const SignInAddress = styled.div<{ inLiquidation?: boolean }>`
  ${FONT_CSS.data.six}
  color: ${({ inLiquidation }) =>
    inLiquidation ? COLORS.negative.two : COLORS.highlight.two};
`;

export const AddressWrapper = styled.div<{
  accountDropdownState: AccountDropdownStateEnum;
  tradingEnabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  margin: 0 ${SPACING.two}px;
  height: ${COMPONENTS.header.buttons}px;

  outline: 1px solid transparent;
  box-shadow: 2px 4px 40px transparent;

  ${foregroundComponent}
  border: none;
  border-radius: ${BORDER_RADIUS.five}px;
  color: ${TEXT_COLORS.one};

  button,
  .dropdown {
    transition: 0.2s ease-in-out;
  }
  .dropdown-menu {
    margin-top: ${SPACING.three}px;
  }

  ${({ accountDropdownState }) => {
    switch (accountDropdownState) {
      case AccountDropdownStateEnum.MMP_TRIGGERED:
        return css`
          background-color: ${COLORS.negative.five};
          &:hover {
            outline: 1px solid ${COLORS.negative.one};
            background-color: ${COLORS.negative.five};
          }
          > ${ButtonContent} {
            color: ${COLORS.negative.one};
          }
        `;
      case AccountDropdownStateEnum.WALLET_DISCONNECTED:
      case AccountDropdownStateEnum.WALLET_CONNECTED:
        return css`
          &:hover {
            outline: 1px solid ${COLORS.highlight.one};
            background-color: ${LAYER_COLORS.one};
          }
        `;
      default:
        return "";
    }
  }}
`;

export const Indicator = styled.div<{ color?: string }>`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: ${({ color }) => color ?? COLORS.highlight.one};
  margin-right: ${SPACING.two}px;
`;

export const MarginButton = styled.div<{ isActive?: boolean }>`
  ${FONT_CSS.label.three};
  display: grid;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: auto;
  cursor: pointer;
  transition: 0.2s all ease-in-out;

  background-color: ${COLORS.highlight.six};
  color: ${TEXT_COLORS.three};

  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid ${COLORS.highlight.one};
      color: ${COLORS.highlight.one};
    `}

  &:hover {
    color: ${COLORS.highlight.one};
  }
`;

export const MarginButtonWrapper = styled.div`
  display: flex;
  margin: auto;
  margin-left: ${SPACING.two}px;
`;
