import { useTranslation } from "react-i18next";
import { CommandFooterContainer, FooterItem } from "./style";
import { ReactComponent as ArrowDown } from "../../assets/svg/arrow-down.svg";

function CommandFooter() {
  const { t } = useTranslation("app", {
    keyPrefix: "CommandModal.CommandFooter",
  });
  return (
    <CommandFooterContainer>
      <FooterItem>
        <div>
          <ArrowDown style={{ rotate: "180deg" }} />
        </div>
        <div>
          <ArrowDown />
        </div>
        <span>{t("navigate")}</span>
      </FooterItem>
      <FooterItem>
        <div>Enter</div>
        <span>{t("open_market")}</span>
      </FooterItem>
      <FooterItem>
        <div>Esc</div>
        <span>{t("close_modal")}</span>
      </FooterItem>
    </CommandFooterContainer>
  );
}

export default CommandFooter;
