import { useEffect, useState } from "react";

/**
 * Toggle between an array of string
 * @param texts array of string
 * @param interval interval (ms) between each toggle
 */
export const useChangeText = (
  texts: string[],
  interval: number,
  start: boolean,
) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (start && texts.length) {
      const int = setInterval(() => {
        setCurrentIndex((idx) => (idx + 1 >= texts.length ? 0 : idx + 1));
      }, interval);
      return () => {
        clearInterval(int);
      };
    }
    return () => {};
  }, [interval, start, texts.length]);

  return texts[currentIndex] || "";
};
