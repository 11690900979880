import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmationModal from ".";
import { InstrumentTypeResponse } from "../../codegen-api";
import { COLORS } from "../../constants/design/colors";
import { useOrder } from "../../hooks/api/order/useOrder";
import { useToast } from "../../hooks/toast";
import { ToastEnum, ToastStatusEnum } from "../../utils/toast";
import { IBaseModalProps } from "../BaseModal";
import { CancelText } from "./style";
import { AssetResponse } from "../../utils/asset";
import { ButtonThemeEnum } from "../Buttons/styles";
import { SPACING } from "../../constants/design/spacing";

type ICancelAllOrdersModalProps = IBaseModalProps & {
  instrumentType?: InstrumentTypeResponse;
  asset?: AssetResponse;
};

export function CancelAllOrdersModal({
  instrumentType,
  asset,
  onHide,
  ...others
}: ICancelAllOrdersModalProps) {
  const { removeAllOrders } = useOrder();
  const { addToast, addErrorToast } = useToast();
  const { t } = useTranslation("app", {
    keyPrefix: "ConfirmationModal.CancelAllOrdersModal",
  });
  const { t: apiErrors } = useTranslation("apiErrors");
  const [isLoading, setLoading] = useState<boolean>(false);

  const cancelAllOrders = useCallback(async () => {
    try {
      setLoading(true);
      const response = await removeAllOrders(instrumentType, asset);

      if (response.success) {
        addToast({
          type: ToastEnum.SIMPLE,
          header: t("cancel_success"),
          status: ToastStatusEnum.SUCCESS,
        });
      }
    } catch (error: any) {
      addErrorToast(
        t("cancel_failed"),
        apiErrors(error.message) || t("place_order_again")
      );
    } finally {
      setLoading(false);
    }
    onHide?.();
  }, [
    addErrorToast,
    addToast,
    apiErrors,
    asset,
    instrumentType,
    onHide,
    removeAllOrders,
    t,
  ]);

  return (
    <ConfirmationModal
      {...others}
      onHide={onHide}
      primaryColor={COLORS.system.one}
      title={t("cancel_all_orders_title")}
      extras={<CancelText>{t("cancel_all_orders_text")}</CancelText>}
      isLoading={isLoading}
      confirmationButtons={[
        {
          title: t("confirm"),
          onClick: cancelAllOrders,
          disabled: false,
          style: {
            marginBottom: SPACING.three,
          },
        },
        {
          title: t("cancel"),
          onClick: onHide,
          disabled: false,
          theme: ButtonThemeEnum.SYSTEM,
        },
      ]}
    />
  );
}
