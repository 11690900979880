import { useCallback, useEffect, useState } from "react";
import { TEXT_COLORS } from "../../constants/design/colors";
import usePrevious from "../usePrevious";

// Returns a value whenever theres a change in the given parameter inbetween renders
export const usePriceChangeColor = (
  negativeChangeColor: string,
  positiveChangeColor: string,
  price?: number,
  defaultColor: string = TEXT_COLORS.one
) => {
  const prevPrice = usePrevious(price);
  const [colors, setColor] = useState<string>(defaultColor);

  const updatePriceColor = useCallback(
    (isNegative?: boolean) => {
      setColor(isNegative ? negativeChangeColor : positiveChangeColor);
    },
    [negativeChangeColor, positiveChangeColor]
  );

  useEffect(() => {
    if (price && prevPrice) {
      const diff = price - prevPrice;
      if (diff < 0) {
        updatePriceColor(true);
      }
      if (diff > 0) {
        updatePriceColor(false);
      }
    }
  }, [prevPrice, price, updatePriceColor]);

  return colors;
};
