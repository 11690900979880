import styled from "styled-components";
import { CSSProperties } from "react";
import {
  BORDER_COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { frostedGlassBg } from "../style";

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  border-top: 1px solid ${BORDER_COLORS.one};
  height: ${COMPONENTS.paginationHeight}px;
  width: 100%;
  ${frostedGlassBg("transparent", LAYER_COLORS.one, 12)};
`;

export const GroupContainer = styled.div`
  ${FONT_CSS.body.five}
  display: flex;
  align-items: center;
  flex: 1;

  color: ${TEXT_COLORS.two};
  strong {
    color: ${TEXT_COLORS.one};
  }
`;

export const ControlButtonContainer = styled(GroupContainer)`
  ${FONT_CSS.data.four}
  color: ${TEXT_COLORS.one};
  justify-content: center;
  > span {
    transition: 0.2s all ease-out;
    margin-left: ${SPACING.three}px;
    margin-right: ${SPACING.three}px;
  }
`;

export const ControlButton = styled.button<{
  direction: "left" | "right";
}>`
  background-color: ${LAYER_COLORS.two};
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all ease-out;
  transform: ${({ direction }) => {
    if (direction === "left") {
      return "rotate(180deg);";
    }
    return "rotate(0deg);";
  }};

  &:hover {
    border: 1px solid ${TEXT_COLORS.one};
  }

  &:disabled {
    opacity: 0.5;
    &:hover {
      border: none;
    }
  }
`;

export const PortfolioPaginationInlineStyle: CSSProperties = {
  position: "sticky",
  bottom: "0",
  padding: `0 ${SPACING.five}px`,
  overflow: "visible",
};
