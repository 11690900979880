import styled from "styled-components";
import { COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";

export const BadgeWrapper = styled.span<{ backgroundColor?: string; color?: string; size?: number }>`
  ${FONT_CSS.data.four}
  border-radius: 50%;
  line-height: 1.5;
  color: ${({ color }) => color ?? COLORS.negative.one};
  background-color: ${({ backgroundColor }) => backgroundColor ?? COLORS.negative.four};
  display: flex;
  justify-content: center;
  ${({ size }) => `
    width: ${size ?? 16}px;
    height: ${size ?? 16}px; 
  `}
`;
