import currency from "currency.js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import infoIcon, { ReactComponent as Info } from "../../assets/svg/info.svg";
import {
  CollateralAssetResponse,
  GetAccount200ResponseCollateralsInner,
} from "../../codegen-api";
import {
  AllCollaterals,
  DepositWithdrawCollaterals,
  IsUSDC,
  getAsset,
} from "../../constants/addresses";
import { COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { ONCHAIN_ESTIMATES } from "../../constants/onchainEstimates";
import { ChainIdEnum } from "../../enums/chain";
import { ChainCodeErrorEnum } from "../../enums/rpcErrorCodes";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { useWithdrawalTransfer } from "../../hooks/api/withdrawTransfer/useWithdrawalTransfer";
import { useToast } from "../../hooks/toast";
import useWallet, { ID_TO_CHAINS } from "../../hooks/wallet/useWallet";
import {
  getAssetLogo,
  getAssetShortName,
  getCollateralAssets,
} from "../../utils/asset/assets";
import { formatCollateralDecimals } from "../../utils/format";
import { ToastEnum, ToastStatusEnum } from "../../utils/toast";
import {
  supportedChainId,
  supportedSigningChainIds,
} from "../../utils/wallet/connectors";
import { BaseModal } from "../BaseModal";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import { InfoRow } from "../ConnectWalletModal/Deposit/style";
import SelectMultichainNetwork from "../SelectMultichainNetwork";
import { Chevron } from "../shared/Chevron/style";
import DropdownSimple from "../shared/DropdownSimple";
import { Input } from "../shared/Input";
import ModalOverlayInfo from "../shared/ModalOverlayInfo";
import { OverlayInfoContent } from "../shared/ModalOverlayInfo/style";
import { Spinner } from "../shared/Spinner";
import TooltipExplanation from "../shared/Tooltip";
import {
  ButtonsContainer,
  DepositAssetDropdownItem,
  DepositAssetDropdownTitle,
  ErrorMessage,
  InputContainer,
  InputError,
  InputLabel,
  MaxButton,
  Spacer,
  WithdrawalFeesTooltipWrapper,
} from "./style";
import { isSocketChain } from "../../utils/chain";

interface IDepositWithdrawModalProps {
  show?: boolean;
  onHide?: () => void;
  defaultAsset?: DepositWithdrawCollaterals;
}

function WithdrawModal({
  show,
  onHide,
  defaultAsset,
}: IDepositWithdrawModalProps) {
  const wallet = useWallet();
  const { chainId, setChainToSwitch } = wallet;
  const { addToast } = useToast();
  const { data: accountData } = useGetAccount();

  const { t } = useTranslation("app", {
    keyPrefix: "DepositWithdrawModal.WithdrawModal",
  });
  const { t: formError } = useTranslation("formErrors");
  const { t: apiError } = useTranslation("apiErrors");
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
    clearErrors,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  const amount: string | undefined = useWatch({ control, name: "amount" });

  const [selectedWithdrawalChain, setSelectedWithrawalChain] = useState(
    supportedSigningChainIds[0]
  );

  const [assetDropdownOpen, setAssetDropdownOpen] = useState(false);
  const [asset, setAsset] = useState<AllCollaterals>(
    DepositWithdrawCollaterals.Usdc
  );
  const { createWithdrawal, withdrawalFees } = useWithdrawalTransfer(
    wallet,
    asset,
    selectedWithdrawalChain
  );

  const withdrawalFeeForAssetChain = useMemo(
    () =>
      withdrawalFees?.find(
        (v) =>
          v.collateral_asset === asset &&
          String(v.chain_id) === String(selectedWithdrawalChain)
      ),
    [asset, selectedWithdrawalChain, withdrawalFees]
  );

  const [showHelpInfo, setShowHelpInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notEnoughBridgeCapacityError, setNotEnoughBridgeCapacityError] =
    useState<string>();
  const [depositWithdrawError, setDepositWithdrawError] = useState<
    string | undefined
  >();

  const collateralInfo: GetAccount200ResponseCollateralsInner | undefined =
    accountData?.collaterals?.find(
      (c) => c.collateral_asset === getAsset(asset)
    );

  // When asset is not allowed, switch network and reset asset
  useEffect(() => {
    setSelectedWithrawalChain((chain) => {
      const allowedAssets = getCollateralAssets(chain, "withdraw");
      if (!allowedAssets.includes(asset)) {
        return supportedChainId;
      }
      return chain;
    });
  }, [asset]);

  // When chain is switched, reset asset
  useEffect(() => {
    const allowedAssets = getCollateralAssets(
      selectedWithdrawalChain,
      "withdraw"
    );
    if (defaultAsset && allowedAssets.includes(defaultAsset)) {
      setAsset(defaultAsset);
    } else {
      setAsset(allowedAssets[0]);
    }
  }, [defaultAsset, selectedWithdrawalChain]);

  // When chain is switched, set selected withdrawal chain
  useEffect(() => {
    if (chainId && supportedSigningChainIds.includes(chainId)) {
      setSelectedWithrawalChain(chainId);
    }
  }, [chainId]);

  // When selected withdrawal chain is changed, reset errors
  useEffect(() => {
    setNotEnoughBridgeCapacityError(undefined);
    setDepositWithdrawError(undefined);
  }, [selectedWithdrawalChain]);

  // Reset errors
  useEffect(() => {
    clearErrors();
    setDepositWithdrawError(undefined);
    setNotEnoughBridgeCapacityError(undefined);
    setShowHelpInfo(false);
  }, [clearErrors, show]);

  const withdrawableBalance = useMemo(() => {
    if (collateralInfo) {
      const { balance } = collateralInfo;
      const creditBalance =
        IsUSDC(collateralInfo.collateral_asset) && accountData?.credit
          ? Number(accountData.credit)
          : 0;
      const availableBalance = collateralInfo.available_balance;
      return (
        Math.min(Number(balance), Number(availableBalance)) - creditBalance
      );
    }
    return undefined;
  }, [accountData?.credit, collateralInfo]);

  // Withdrawable balance - fees
  const maxWithdrawableBalance = useMemo(() => {
    if (withdrawableBalance) {
      return (
        withdrawableBalance - Number(withdrawalFeeForAssetChain?.fees || 0)
      );
    }
    return withdrawableBalance;
  }, [withdrawableBalance, withdrawalFeeForAssetChain?.fees]);

  const submitButtonContent = useMemo(() => {
    if (chainId !== selectedWithdrawalChain) {
      return (
        <>
          <Button
            fullWidth
            buttonTheme={ButtonThemeEnum.HIGHLIGHT}
            type="button"
            onClick={() => setChainToSwitch(selectedWithdrawalChain)}
          >
            {t("switch_to", {
              chain: ID_TO_CHAINS[selectedWithdrawalChain as ChainIdEnum],
            })}
          </Button>
          <Button
            buttonTheme={ButtonThemeEnum.NEUTRAL2}
            type={"button"}
            onClick={() => setShowHelpInfo(true)}
          >
            <img src={infoIcon} alt="info" />
          </Button>
        </>
      );
    }
    return (
      <>
        <Button
          fullWidth
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          type={"submit"}
          disabled={loading || !isValid}
        >
          {loading ? <Spinner /> : t("withdraw")}
        </Button>
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          type={"button"}
          onClick={() => setShowHelpInfo(true)}
        >
          <img src={infoIcon} alt="info" />
        </Button>
      </>
    );
  }, [chainId, selectedWithdrawalChain, loading, isValid, setChainToSwitch, t]);

  const onMaxClick = useCallback(() => {
    clearErrors("amount");
    setValue("amount", String(maxWithdrawableBalance || 0));
    trigger("amount");
  }, [clearErrors, setValue, trigger, maxWithdrawableBalance]);

  const handleWithdraw = useCallback(async () => {
    setDepositWithdrawError(undefined);
    setNotEnoughBridgeCapacityError(undefined);

    try {
      setLoading(true);
      const { response, insufficientLimit } = await createWithdrawal(
        amount || "0"
      );
      if (response?.success) {
        // Dismiss modal and show success
        onHide?.();
        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: t("withdrawal_pending"),
            subheader: t("withdrawal_pending_desc", {
              amount: `${currency(amount || "0").format({
                symbol: "",
              })} ${asset}`,
              time: isSocketChain(selectedWithdrawalChain)
                ? `${ONCHAIN_ESTIMATES.socketWithdrawal.value} ${ONCHAIN_ESTIMATES.socketWithdrawal.unit}`
                : `${ONCHAIN_ESTIMATES.withdrawal.value} ${ONCHAIN_ESTIMATES.withdrawal.unit}`,
            }),
            status: ToastStatusEnum.SUCCESS,
          },
          4000
        );
      } else if (insufficientLimit) {
        const chainEnum = chainId as ChainIdEnum;
        const otherNetworks = supportedSigningChainIds
          .filter((c) => c !== chainEnum)
          .map((c) => ID_TO_CHAINS[c]);
        const otherNetworksText =
          otherNetworks.length === 2
            ? otherNetworks.join(` ${t("or")} `)
            : otherNetworks.join(", ");
        const currentNetworkName = ID_TO_CHAINS[chainEnum];

        // No limit
        if (Number(insufficientLimit) === 0) {
          setNotEnoughBridgeCapacityError(
            t("no_bridge_capacity", {
              currentChain: currentNetworkName,
              otherChains: otherNetworksText,
            })
          );
        } else {
          let text = t("insufficient_bridge_capacity", {
            chain: currentNetworkName,
          });
          text += t("reduce_withdrawal_size", {
            size: `${currency(insufficientLimit).format({
              symbol: "",
            })} ${asset}`,
            otherChains: otherNetworksText,
          });
          setNotEnoughBridgeCapacityError(text);
        }
      }
    } catch (error: any) {
      if (error.code === ChainCodeErrorEnum.CANCELLED) {
        setDepositWithdrawError(t("withdraw_cancelled"));
      } else {
        setDepositWithdrawError(
          apiError(error.message) ?? t("withdrawal_failed")
        );
        // eslint-disable-next-line no-console
        console.log({ error });
      }
    } finally {
      setLoading(false);
    }
  }, [
    createWithdrawal,
    amount,
    onHide,
    addToast,
    t,
    asset,
    selectedWithdrawalChain,
    chainId,
    apiError,
  ]);

  return (
    <BaseModal
      onHide={onHide}
      show={show}
      style={{
        width: 310,
      }}
      title={t("withdraw")}
    >
      <ModalOverlayInfo
        show={showHelpInfo}
        onClose={() => setShowHelpInfo(false)}
        height="280px"
      >
        <OverlayInfoContent>
          <span>{t("withdrawals")}</span>
          <span>
            {t("withdrawal_availability_desc_1", {
              asset: getAssetShortName(asset),
            })}
            {asset === CollateralAssetResponse.Weth
              ? t("eth_to_weth_desc")
              : " "}
            {t("withdrawal_availability_desc_2")}
          </span>
        </OverlayInfoContent>
      </ModalOverlayInfo>
      <form onSubmit={handleSubmit(handleWithdraw)}>
        <InputContainer>
          <InputLabel>{t("withdraw_to")}</InputLabel>
          <SelectMultichainNetwork
            selectedChain={selectedWithdrawalChain}
            onSelectChain={setSelectedWithrawalChain}
          />
          <InputLabel>{t("amount")}</InputLabel>
          <Input
            placeholder="0.00"
            leftAccessory={
              <DropdownSimple<AllCollaterals>
                dropDirection={"down"}
                show={assetDropdownOpen}
                onToggle={setAssetDropdownOpen}
                toggleStyle={{
                  padding: 0,
                  paddingLeft: SPACING.two,
                  paddingRight: SPACING.two,
                }}
                title={
                  <DepositAssetDropdownTitle>
                    <img
                      src={getAssetLogo(asset)}
                      width={24}
                      height={24}
                      alt={asset}
                    />
                    <Chevron
                      size="small"
                      direction={assetDropdownOpen ? "up" : "down"}
                    />
                  </DepositAssetDropdownTitle>
                }
                items={getCollateralAssets(selectedWithdrawalChain, "withdraw")}
                getItemLabel={(v) => (
                  <DepositAssetDropdownItem selected={v === asset}>
                    <img src={getAssetLogo(v)} width={24} height={24} alt={v} />
                    <span>{getAssetShortName(v, selectedWithdrawalChain)}</span>
                  </DepositAssetDropdownItem>
                )}
                selectedItem={asset}
                onSelectItem={(v) => {
                  setAsset(v as DepositWithdrawCollaterals);
                }}
              />
            }
            rightAccessory={
              <MaxButton onClick={onMaxClick} type="button">
                {t("max")}
              </MaxButton>
            }
            type="number"
            disabled={loading}
            {...register("amount", {
              required: true,
              validate: {
                moreThanZero: (v) => parseFloat(v) > 0,
                lessThanEqualToExchangeBalance: (v) =>
                  parseFloat(v) <= (withdrawableBalance || 0),
                enoughForFees: (v) =>
                  parseFloat(v) <= (maxWithdrawableBalance || 0),
              },
            })}
            error={
              Boolean(Object.keys(errors).length) ||
              !!notEnoughBridgeCapacityError
            }
          />
          {errors.amount?.type === "required" && (
            <InputError>{formError("amount.required")}</InputError>
          )}
          {errors.amount?.type === "moreThanZero" && (
            <InputError>{formError("amount.moreThanZero")}</InputError>
          )}
          {errors.amount?.type === "lessThanEqualToExchangeBalance" && (
            <InputError>
              {formError("amount.lessThanEqualToExchangeBalance")}
            </InputError>
          )}
          {errors.amount?.type === "enoughForFees" && (
            <InputError>
              {formError("amount.enoughForFees", {
                amount: formatCollateralDecimals(
                  maxWithdrawableBalance || 0,
                  asset
                ),
                asset,
                feeAmount: formatCollateralDecimals(
                  withdrawalFeeForAssetChain?.fees || 0,
                  asset
                ),
              })}
            </InputError>
          )}
          {!errors.amount?.type && !!notEnoughBridgeCapacityError && (
            <InputError>{notEnoughBridgeCapacityError}</InputError>
          )}
        </InputContainer>
        <InfoRow>
          <span>
            {t("exchange_balance")} ({getAssetShortName(asset)})
          </span>
          <span>
            {collateralInfo
              ? formatCollateralDecimals(collateralInfo.balance, asset)
              : "---"}
          </span>
        </InfoRow>
        {IsUSDC(asset) && accountData?.credit && (
          <InfoRow>
            <span>
              {t("credit_balance")} ({getAssetShortName(asset)})
            </span>
            <span>{formatCollateralDecimals(accountData.credit, asset)}</span>
          </InfoRow>
        )}
        <InfoRow>
          <span
            style={{
              color:
                errors.amount?.type === "lessThanEqualToExchangeBalance"
                  ? COLORS.negative.one
                  : undefined,
            }}
          >
            {t("withdrawable_balance")} (
            {getAssetShortName(asset, selectedWithdrawalChain)})
          </span>
          <span
            style={{
              color:
                errors.amount?.type === "lessThanEqualToExchangeBalance"
                  ? COLORS.negative.one
                  : undefined,
            }}
          >
            {withdrawableBalance
              ? formatCollateralDecimals(withdrawableBalance, asset)
              : "---"}
          </span>
        </InfoRow>
        <InfoRow>
          <span>{t("total_exchange_equity")}</span>
          <span>{currency(Number(accountData?.equity)).format()}</span>
        </InfoRow>
        <InfoRow>
          <span>{t("eta")}</span>
          <span>
            {isSocketChain(selectedWithdrawalChain) ? (
              <>
                {ONCHAIN_ESTIMATES.socketWithdrawal.value}{" "}
                {ONCHAIN_ESTIMATES.socketWithdrawal.unit}
              </>
            ) : (
              <>
                {ONCHAIN_ESTIMATES.withdrawal.value}{" "}
                {ONCHAIN_ESTIMATES.withdrawal.unit}
              </>
            )}
          </span>
        </InfoRow>
        {!!Number(withdrawalFeeForAssetChain?.fees || 0) && (
          <InfoRow>
            <span
              style={{
                color:
                  errors.amount?.type === "enoughForFees"
                    ? COLORS.negative.one
                    : undefined,
              }}
            >
              <TooltipExplanation
                title={`${t("withdrawal_fees")}`}
                explanation={t("withdrawal_fees_desc", {
                  chain: ID_TO_CHAINS[selectedWithdrawalChain],
                })}
                renderContent={({ ref, ...triggerHandler }) => (
                  <WithdrawalFeesTooltipWrapper ref={ref} {...triggerHandler}>
                    {t("withdrawal_fees")} {asset}
                    <Info />
                  </WithdrawalFeesTooltipWrapper>
                )}
              />
            </span>
            <span
              style={{
                color:
                  errors.amount?.type === "enoughForFees"
                    ? COLORS.negative.one
                    : undefined,
              }}
            >
              {formatCollateralDecimals(
                withdrawalFeeForAssetChain?.fees || 0,
                asset
              )}
            </span>
          </InfoRow>
        )}
        <Spacer />
        {depositWithdrawError && (
          <ErrorMessage>{depositWithdrawError}</ErrorMessage>
        )}
        <ButtonsContainer>{submitButtonContent}</ButtonsContainer>
      </form>
    </BaseModal>
  );
}

export default WithdrawModal;
