import currency from "currency.js";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { OptionTypeResponse, SideResponse } from "../../codegen-api";
import { COLORS } from "../../constants/design/colors";
import useIndexWSS from "../../hooks/wss/useIndexWSS";
import { longhandTimeLeftToExpiry, nanosToSeconds } from "../../utils/date";
import { calculateProfitPerOption } from "../../utils/instruments";
import { ChartContainer, PayoffDataContainer, PayoffDataRow } from "./style";
import ProfitChart from "../ProfitChart";
import { AssetResponse } from "../../utils/asset";

interface IPayoffChartProps {
  asset: AssetResponse;
  orderDirection: SideResponse;
  averagePremium: number;
  totalSize: number;
  optionType?: OptionTypeResponse;
  strike?: string;
  expiry?: string;
}

function PayoffChart({
  asset,
  orderDirection,
  averagePremium,
  totalSize,
  optionType,
  strike,
  expiry,
}: IPayoffChartProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TicketDetails.PayoffChart",
  });
  const { t: translateAll } = useTranslation();
  const { index } = useIndexWSS(asset);

  // CURRENT ASSET PRICE
  const [hoveredPrice, setHoveredPrice] = useState<number>();
  const assetPrice = hoveredPrice ?? Number(index?.price || 0);

  let pnl = 0;
  if (strike && optionType) {
    const profit = calculateProfitPerOption(
      optionType === OptionTypeResponse.Put,
      assetPrice,
      Number(strike),
      averagePremium,
      orderDirection
    );
    pnl = profit * totalSize;
  }

  const onChartHover = useCallback((price: number | undefined) => {
    setHoveredPrice(price);
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <ChartContainer>
        <ProfitChart
          key={`${averagePremium}-${orderDirection}`}
          price={assetPrice || 0}
          strike={Number(strike)}
          premium={averagePremium}
          isPut={optionType === OptionTypeResponse.Put}
          orderDirection={orderDirection}
          asset={asset}
          onHover={onChartHover}
        />
      </ChartContainer>
      <PayoffDataContainer>
        <PayoffDataRow
          valueColor={
            // eslint-disable-next-line no-nested-ternary
            !pnl
              ? COLORS.white.one
              : pnl > 0
              ? COLORS.positive.one
              : COLORS.negative.one
          }
        >
          <span>{t("payoff")}</span>
          <span>{currency(pnl || 0).format()}</span>
        </PayoffDataRow>
        <PayoffDataRow>
          <span>{t("time_to_expiry")}</span>
          <span>
            {expiry
              ? longhandTimeLeftToExpiry(
                  translateAll,
                  nanosToSeconds(expiry)
                ) || "Expired"
              : "-"}
          </span>
        </PayoffDataRow>
      </PayoffDataContainer>
    </div>
  );
}

export default PayoffChart;
