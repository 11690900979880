import styled from "styled-components";
import { BORDER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { SPACING, COMPONENTS } from "../../../constants/design/spacing";
import { Button } from "../../Buttons/styles";

export const Divider = styled.div<{
  height?: string;
  horizontalMargin?: string;
}>`
  background-color: ${BORDER_COLORS.one};
  width: 1px;
  height: 100%;

  margin-left: ${({ horizontalMargin }) =>
    horizontalMargin || `${SPACING.twoHalf}px`};
  margin-right: ${({ horizontalMargin }) =>
    horizontalMargin || `${SPACING.twoHalf}px`};
`;

export const LeftHeaderWrapper = styled.div`
  font-size: ${FONT_SIZE.two};
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    cursor: pointer;
    color: ${TEXT_COLORS.two};
  }
`;

export const RightHeaderWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  > ${Button} {
    padding: ${SPACING.twoHalf}px ${SPACING.three}px;
    min-height: ${COMPONENTS.header.buttons}px;
    margin-left: ${SPACING.two}px;
  }
`;

export const MainHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING.two}px;
  height: ${COMPONENTS.header.main}px;
`;
