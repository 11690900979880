import styled, { css } from "styled-components";
import {
  BACKGROUND_COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../constants/design/spacing";
import { TableWrapper } from "../shared/Table/style";

export const TableContainer = styled.div`
  position: relative;
  flex: 1;
  overflow: auto;
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const OptionsHistoryTableWrapper = styled(TableWrapper)`
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  thead {
    position: sticky;
    top: 0;
  }
  td {
    padding: 0 !important;
    height: inherit;
    > div {
      padding: 0 ${SPACING.three}px;
    }
    background-color: transparent !important;
  }

  tr {
    &:hover {
      background-color: ${BACKGROUND_COLORS.four};
    }
  }
`;

export const OptionsHistoryWrapper = styled.div<{ isMobileScreen?: boolean }>`
  ${({ isMobileScreen }) =>
    !isMobileScreen
      ? css`
          position: absolute;
          top: 0;
          left: ${SPACING.two}px;
          right: ${SPACING.two}px;
          bottom: 0;
          overflow: scroll;
          ${OptionsHistoryTableWrapper} {
            overflow: scroll;
            height: 100%;
          }
        `
      : css`
          background: ${LAYER_COLORS.one};
          border-bottom-right-radius: ${BORDER_RADIUS.five}px;
          border-bottom-left-radius: ${BORDER_RADIUS.five}px;
          overflow: hidden;
        `}

  display: flex;
  flex-direction: column;

  > *:not(${TableContainer}) {
    margin: ${SPACING.two}px;
  }
  > ${FiltersContainer} {
    margin: ${SPACING.two}px 0;
    margin-top: 0;
  }
`;

export const ScrollableSection = styled.div`
  overflow: scroll;
  height: 100%;
`;

export const EmptyContent = styled.div`
  height: 240px;
  color: ${TEXT_COLORS.two};
  padding-top: ${SPACING.two}px;
  font-size: ${FONT_SIZE.two};
`;

export const LoadingContainer = styled.div`
  text-align: center;
`;

export const DualColRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  width: 200px;
`;
