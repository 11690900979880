import { useTranslation } from "react-i18next";
import { useState } from "react";
import { OrderTypeResponse } from "../../../../codegen-api";
import { LAYER_COLORS } from "../../../../constants/design/colors";
import { SPACING } from "../../../../constants/design/spacing";
import { getStopOrderName } from "../../../../utils/order";
import Dropdown from "../../../shared/Dropdown";
import { DropdownTitle } from "../style";
import { Chevron } from "../../../shared/Chevron/style";
import { DropdownItem } from "../../style";

interface IMobileOrderTypeDropdownProps {
  orderType: OrderTypeResponse;
  setOrderType: (type: OrderTypeResponse) => void;
  isStop: boolean;
  setIsStop: (isStop: boolean) => void;
}

function MobileOrderTypeDropdown({
  isStop,
  setIsStop,
  orderType,
  setOrderType,
}: IMobileOrderTypeDropdownProps) {
  const { t } = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  return (
    <Dropdown
      width={"100%"}
      isToggled={dropdownOpen}
      onToggle={(open) => setDropdownOpen(open)}
      toggleStyles={{
        padding: 0,
        borderRadius: 10,
        backgroundColor: LAYER_COLORS.two,
      }}
      title={
        <DropdownTitle style={{ justifyContent: "center" }}>
          {getStopOrderName(t, orderType, isStop, true, true)}
          <Chevron
            style={{ marginLeft: SPACING.one }}
            direction={dropdownOpen ? "up" : "down"}
          />
        </DropdownTitle>
      }
      items={[
        {
          label: (
            <DropdownItem
              key={"market"}
              isSelected={orderType === OrderTypeResponse.Market && !isStop}
            >
              {getStopOrderName(t, OrderTypeResponse.Market, false)}
            </DropdownItem>
          ),
          onSelect: () => {
            setOrderType(OrderTypeResponse.Market);
            setIsStop(false);
          },
        },
        {
          label: (
            <DropdownItem
              key={"limit"}
              isSelected={orderType === OrderTypeResponse.Limit && !isStop}
            >
              {getStopOrderName(t, OrderTypeResponse.Limit, false)}
            </DropdownItem>
          ),
          onSelect: () => {
            setOrderType(OrderTypeResponse.Limit);
            setIsStop(false);
          },
        },
        {
          label: (
            <DropdownItem
              key={"stop-market"}
              isSelected={orderType === OrderTypeResponse.Market && isStop}
            >
              {getStopOrderName(t, OrderTypeResponse.Market, true)}
            </DropdownItem>
          ),
          onSelect: () => {
            setOrderType(OrderTypeResponse.Market);
            setIsStop(true);
          },
        },
        {
          label: (
            <DropdownItem
              key={"stop-limit"}
              isSelected={orderType === OrderTypeResponse.Limit && isStop}
            >
              {getStopOrderName(t, OrderTypeResponse.Limit, true)}
            </DropdownItem>
          ),
          onSelect: () => {
            setOrderType(OrderTypeResponse.Limit);
            setIsStop(true);
          },
        },
      ]}
    />
  );
}

export default MobileOrderTypeDropdown;
