import { create } from "zustand";
import { GetOrderbook200Response } from "../../../../codegen-api";
import { mountZustandDevtool } from "../../../../utils/zustand";

/*
 The initial state shapes what values we can have in our store.
 We can order them as we like or use multiple stores.
 For our game, I'll use only one store.

 Our server only sends the game state updates so that's almost all we need.
 We use the 'ready' state to know if we are connected to the server or not.
*/

interface IInstrumentToOrderbook {
  [instrument: string]: GetOrderbook200Response;
}

type IOrderbookInstrumentSubscribeCount = {
  [instrumentName: string]: number;
}
interface IPublicWebsocketState {
  // Keeps track of how many subs to a specific instrument is ongoing
  // Eg. if component A sub to ETH-PERP, and component B sub to ETH-PERP, count will be 2.
  // When count reaches 0, we can safely unsub from this websocket. Otherwise, keep it subscribed
  orderbookInstrumentSubscribeCount: IOrderbookInstrumentSubscribeCount;
  orderbookInstruments: Set<string>;
  orderbook: IInstrumentToOrderbook;
  
  // Mutations
  addOrderbookInstruments: (instrument: string) => void;
  removeOrderbookInstruments: (instrument: string) => void;
  updateOrderbookData: (instrument: string, data?: GetOrderbook200Response) => void;
}

const MAX_ORDERBOOK_SIZE = 100;

export const useOrderbookStore = create<IPublicWebsocketState>()((setState) => ({
  orderbookInstrumentSubscribeCount: {},
  orderbookInstruments: new Set(),
  addOrderbookInstruments: (instrument: string) => setState((state) => {
    const subCount = {
      ...state.orderbookInstrumentSubscribeCount,
      [instrument]: state.orderbookInstrumentSubscribeCount[instrument]
        ? state.orderbookInstrumentSubscribeCount[instrument] + 1
        : 1
    }

    if (!state.orderbookInstruments.has(instrument)) {
      // Update if not already included
      const newOrderbookSet = new Set([
        ...Array.from(state.orderbookInstruments),
        instrument
      ])
      return {
        orderbookInstrumentSubscribeCount: subCount,
        orderbookInstruments: newOrderbookSet
      }
    }
    return {
      orderbookInstrumentSubscribeCount: subCount,
    }
  }),
  removeOrderbookInstruments: (instrument: string) => setState((state) => {
    const subCount = {
      ...state.orderbookInstrumentSubscribeCount,
      [instrument]: state.orderbookInstrumentSubscribeCount[instrument]
        ? state.orderbookInstrumentSubscribeCount[instrument] - 1
        : 0
    }
    if (state.orderbookInstruments.has(instrument)) {
      // Update if not already included
      const newOrderbookSet = new Set([
        ...Array.from(state.orderbookInstruments),
      ].filter((i) => i !== instrument))

      return {
        orderbookInstrumentSubscribeCount:  subCount,
        orderbookInstruments: newOrderbookSet
      }
    }
    return {
      orderbookInstrumentSubscribeCount: subCount
    }
  }),

  // DATA
  orderbook: {},
  updateOrderbookData: (instrument: string, data?: GetOrderbook200Response) => setState((state) => {
    const prevObData = state.orderbook[instrument]

    if (!data) {
      const newOb = {
        ...state.orderbook
      }
      delete newOb[instrument]
      
      return {
        orderbook: newOb
      };
    }

    // If update, update the prev state
    if (data.type === "snapshot") {
      const newData: GetOrderbook200Response = {
        ...data,
        bids: (
          data.bids?.filter(([, size]) => Number(size) > 0) || []
        ).sort((a, b) => Number(b[0]) - Number(a[0])),
        asks: (
          data.asks?.filter(([, size]) => Number(size) > 0) || []
        ).sort((a, b) => Number(a[0]) - Number(b[0])),
      };
      return {
        orderbook: {
          ...state.orderbook,
          [instrument]: newData
        }
      };
    }

    // Process asks and bids, and update the diff
    if (data.type === "update" || data.type === "update_iv") {
      const newAsks: string[][] =
        prevObData?.instrument_name === instrument
          ? prevObData?.asks || []
          : [];
      const newBids: string[][] =
        prevObData?.instrument_name === instrument
          ? prevObData?.bids || []
          : [];

      data.asks?.forEach(([price, size, iv]) => {
        const priceIndex = newAsks.findIndex(([p]) => p === price);
        if (priceIndex >= 0) {
          newAsks[priceIndex] = [price, size, iv];
        } else {
          newAsks.push([price, size, iv]);
        }
      });

      data.bids?.forEach(([price, size, iv]) => {
        const priceIndex = newBids.findIndex(([p]) => p === price);
        if (priceIndex >= 0) {
          newBids[priceIndex] = [price, size, iv];
        } else {
          newBids.push([price, size, iv]);
        }
      });

      // Mutate OB
      const bids = (
        newBids.filter(([, size]) => Number(size) > 0) || []
      ).sort((a, b) => Number(b[0]) - Number(a[0])).slice(0, MAX_ORDERBOOK_SIZE);
      const asks = (
        newAsks.filter(([, size]) => Number(size) > 0) || []
      ).sort((a, b) => Number(a[0]) - Number(b[0])).slice(0, MAX_ORDERBOOK_SIZE);

      return {
        orderbook: {
          ...state.orderbook,
          [instrument]: {
            ...state.orderbook[instrument],
            // Filters to only bids/asks with size
            bids,
            asks,
          }
        }
      };
    }

    return state
  })
}));

mountZustandDevtool("useOrderbookStore", useOrderbookStore)
