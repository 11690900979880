/* eslint-disable no-continue */
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useShallow } from "zustand/react/shallow";
import { WSS_URL } from "../../../constants/api/wss";
import { AuthContext } from "../../../contexts/AuthContext";
import { nanosToMillis } from "../../../utils/date";
import { jsonParse, jsonStringify } from "../../../utils/strings";
import { useGetAccount } from "../../api/account/useGetAccount";
import { IWSSOrderbookRequest } from "../model/orderbook";
import {
  ISubscribeIDMap,
  IWebsocketRateLimitedResponse,
  IWebsocketResponse,
} from "../model/shared";
import { IWSSTickerRequest } from "../model/ticker";
import { fillsChannel, positionsChannel } from "./channels";
import { useFillsStore } from "./store/useFillsStore";
import { usePositionsStore } from "./store/usePositionsStore";
import { GetAccount200ResponsePositionsInner } from "../../../codegen-api/api";
import useTabVisibility from "../../useTabVisibility";

/**
 * 2 different zustand states
 * 1 for tracking subscribes
 * 1 for tracking data
 *
 * to sub to a channel, we simply call the action "subscribe" which updates zustand state,
 * which then triggers this component to do the actual websocket subscribe
 *
 * to receive data, just retrieve from zustand data states.
 */

const flushMessagesIntervalMs = 250;

interface ICheckInstrumentsProps {
  array: GetAccount200ResponsePositionsInner[];
  obj: { [instrumentName: string]: GetAccount200ResponsePositionsInner };
}

export function AuthenticatedWebsocket() {
  const isTabVisible = useTabVisibility();
  const { data: accountData } = useGetAccount();
  const { apiKey, apiSecret, account } = useContext(AuthContext);

  // An incremental id will be assigned to each subscribes
  // Using ref because we never want these to trigger any side effects
  const id = useRef(0);
  const subbedIdMap = useRef<ISubscribeIDMap>({});
  const [authenticated, setAuthenticated] = useState(false);

  // Fills
  const [updateFill, resetFills] = useFillsStore((state) => [
    state.updateFill,
    state.resetFills,
  ]);
  const [positions, resetPositions, updatePosition, updateFallbackPosition] =
    usePositionsStore(
      useShallow((state) => [
        state.positions,
        state.resetPositions,
        state.updatePosition,
        state.updateFallbackPosition,
      ])
    );

  const [messages, setMessages] = useState<MessageEvent<any>[] | null>(null);
  const messageEvents = useRef(new Set<MessageEvent<any>>());

  const { sendMessage, readyState } = useWebSocket(WSS_URL, {
    shouldReconnect: () => true,
    reconnectAttempts: 50,
    // Attempt to reconnect every 2 seconds
    reconnectInterval: 2000,
    retryOnError: true,
    share: false,
    onMessage: (msg: any) => {
      try {
        const { channel }: IWebsocketResponse = jsonParse(msg?.data);
        if (isTabVisible || channel !== positionsChannel) {
          messageEvents.current.add(msg);
        }
      } catch (e) {
        console.log(e);
      }
    },
    // Never update lastMessage, since we're processing onMessage ourselves
    filter: () => false,
  });

  const sendAuth = useCallback(() => {
    if (readyState !== ReadyState.OPEN) {
      return;
    }

    if (!apiKey || !apiSecret) {
      setAuthenticated(false);
      return;
    }

    // eslint-disable-next-line no-console
    console.log("WS Opened, and apikey available. Attempting auth...");

    const op = "auth";
    const data = {
      key: apiKey,
      secret: apiSecret,
    };

    const authMessage = {
      op,
      data,
    };
    sendMessage(JSON.stringify(authMessage));
  }, [apiKey, apiSecret, readyState, sendMessage]);

  // Automatic auth whenever api key is available
  useEffect(() => {
    sendAuth();
  }, [sendAuth]);

  useEffect(() => {
    const flushMessages = () => {
      const msgs = Array.from(messageEvents.current.values());
      if (msgs.length) {
        messageEvents.current.clear();
        setMessages(msgs);
      }
    };
    const timer = setInterval(flushMessages, flushMessagesIntervalMs);

    return () => {
      clearInterval(timer);
      flushMessages();
    };
  }, []);

  useEffect(() => {
    if (readyState === ReadyState.OPEN && authenticated && isTabVisible) {
      const subDatas = [...Object.values(subbedIdMap.current)];
      // Resub all the existing subs
      console.log("WS Reconnected! Resubbing to: ", subDatas);
      subbedIdMap.current = {};

      for (let i = 0; i < subDatas.length; i += 1) {
        const data = jsonParse(subDatas[i]);
        id.current += 1;

        // Subscribe
        const subMessage: IWSSTickerRequest = {
          op: "subscribe",
          data,
          id: id.current,
        };
        sendMessage(jsonStringify(subMessage));

        subbedIdMap.current = {
          ...subbedIdMap.current,
          [id.current]: jsonStringify(data),
        };
      }
    }
  }, [authenticated, isTabVisible, readyState, sendMessage]);

  const checkInstruments = useCallback(
    ({ array, obj }: ICheckInstrumentsProps) =>
      array.every((item) =>
        Object.prototype.hasOwnProperty.call(obj, item.instrument_name)
      ),
    []
  );

  const triggerUnsubscribe = useCallback(
    (data: string[]) => {
      const existingId = Object.keys(subbedIdMap.current).find((key) =>
        subbedIdMap.current[Number(key)].includes(jsonStringify(data))
      );

      const idNum = Number(existingId);
      if (idNum) {
        const unsubMessage: IWSSOrderbookRequest = {
          op: "unsubscribe",
          data: jsonParse(subbedIdMap.current[idNum]),
        };
        sendMessage(jsonStringify(unsubMessage));
        delete subbedIdMap.current[idNum];
      }
    },
    [sendMessage]
  );

  const triggerSubscribe = useCallback(
    (data: string[]) => {
      // Check if any existing channels exists
      // If not subbed, increment id and SUB
      const exists = Object.values(subbedIdMap.current).some(
        (v) => v === jsonStringify(data)
      );
      if (!exists && readyState === ReadyState.OPEN) {
        id.current += 1;

        // Subscribe
        const subMessage: IWSSTickerRequest = {
          op: "subscribe",
          data,
          id: id.current,
        };
        sendMessage(jsonStringify(subMessage));

        subbedIdMap.current = {
          ...subbedIdMap.current,
          [id.current]: jsonStringify(data),
        };
      }
    },
    [readyState, sendMessage]
  );

  // Handle position
  // In case websocket is slow,
  // If account data positions is newer, update positionsMap
  useEffect(() => {
    if (accountData) {
      updateFallbackPosition(accountData?.positions);
    }
  }, [accountData, updateFallbackPosition]);

  // Automatic subbed to all auth ws
  useEffect(() => {
    const fillsData = [fillsChannel];
    const positionsData = [positionsChannel];

    if (!authenticated) {
      triggerUnsubscribe(fillsData);
      triggerUnsubscribe(positionsData);
      return;
    }

    // Check if positions are valid
    if (
      accountData?.account !== account &&
      checkInstruments({
        array: accountData?.positions ?? [],
        obj: positions,
      }) === false
    ) {
      // Reset
      resetFills();
      resetPositions();
    }

    // Subscribe
    triggerSubscribe(fillsData);
    triggerSubscribe(positionsData);
  }, [
    authenticated,
    account,
    accountData,
    resetFills,
    resetPositions,
    triggerSubscribe,
    triggerUnsubscribe,
    checkInstruments,
    positions,
  ]);

  // If any messages is a rate limit exceeded, retry after x seconds
  useEffect(() => {
    if (!messages || !authenticated) {
      return;
    }

    for (let i = 0; i < messages.length; i += 1) {
      const lastMessage = messages[i];

      const {
        data,
        error,
        id: resubId,
      }: IWebsocketRateLimitedResponse = jsonParse(lastMessage.data);

      if (error === "RATE_LIMIT_EXCEEDED" && resubId) {
        const subMsg = subbedIdMap.current[resubId];
        // If existing sub id exists
        if (subMsg) {
          const retryMs = nanosToMillis(data.retry_after);

          // If failed, we delete subbed id map
          delete subbedIdMap.current[resubId];

          // And then resub again after a duration
          setTimeout(() => {
            console.log("RETRYING", subMsg);
            triggerSubscribe(jsonParse(subMsg));
          }, retryMs);
        }
      } else if (error === "RATE_LIMIT_EXCEEDED") {
        // Must be auth rate limited
        const retryMs = nanosToMillis(data.retry_after);
        setTimeout(() => {
          sendAuth();
        }, retryMs);
      } else if (error) {
        console.log("UNKNOWN ERROR:", data, error, resubId);
      }
    }
  }, [authenticated, messages, sendAuth, triggerSubscribe]);

  // Handle messages
  useEffect(() => {
    if (!messages) {
      return;
    }

    for (let idx = 0; idx < messages.length; idx += 1) {
      const lastMessage = messages[idx];

      const { data, channel, error }: IWebsocketResponse = jsonParse(
        lastMessage.data
      );

      // Handle Auth state
      if (data && data.success && data.account) {
        setAuthenticated(true);
        continue;
      }

      // Handle Fills
      if (data && channel === fillsChannel) {
        updateFill(data);
        continue;
      }

      if (data && channel === positionsChannel && account) {
        updatePosition(data.positions);
        continue;
      }

      console.log("UNHANDLED AUTH DATA:", data, channel, error);
    }
  }, [account, messages, updateFill, updatePosition]);

  return null;
}
