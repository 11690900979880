import { ReactComponent as Share } from "../../assets/svg/share.svg";
import { Button, ButtonThemeEnum, IButtonComponentProps } from "./styles";

type IShareButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>
  & Omit<IButtonComponentProps, "buttonTheme">

function ShareButton(props: IShareButtonProps) {
  return (
    <Button
      buttonTheme={ButtonThemeEnum.NEUTRAL2}
      style={{
        height: "48px",
      }}
      {...props}
    >
      <Share />
    </Button>
  );
}
export default ShareButton;
