import { QRCodeSVG } from "qrcode.react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Eye } from "../../../../assets/svg/eye.svg";
import { ReactComponent as EyeOff } from "../../../../assets/svg/eye-off.svg";
import { Container, HiddenOverlay, QRContainer, ShowHideButton } from "./style";

interface IGenerateQRProps {
  qrCode?: string;
}

function GenerateQR({ qrCode }: IGenerateQRProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "Header.PWAFlowModal.GenerateQR",
  });
  const [isHidden, setIsHidden] = useState(true);

  const toggleIsHidden = useCallback(() => {
    setIsHidden((h) => !h);
  }, []);

  return (
    <Container>
      <QRContainer>
        <QRCodeSVG value={qrCode || ""} />
        <HiddenOverlay isHidden={isHidden}>{t("never_share_qr")}</HiddenOverlay>
      </QRContainer>
      <ShowHideButton onClick={toggleIsHidden}>
        {isHidden ? t("show") : t("hide")}
        {isHidden ? <Eye /> : <EyeOff />}
      </ShowHideButton>
    </Container>
  );
}

export default GenerateQR;
