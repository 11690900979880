import styled from "styled-components";
import { BORDER_RADIUS, SPACING } from "../../constants/design/spacing";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";

export const NetworkButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  grid-gap: ${SPACING.two}px;
  margin-top: ${SPACING.two}px;
  margin-bottom: ${SPACING.three}px;

  & > *:last-child {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;

    & > *:last-child {
      grid-column: auto;
      grid-row: auto;
    }
  }
`;

export const NetworkButton = styled.button.attrs({
  type: "button",
})<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${LAYER_COLORS.two};
  border-radius: ${BORDER_RADIUS.five}px;
  transition: 0.2s all ease-in-out;
  height: 64px;
  ${FONT_CSS.body.five}
  border: 1px solid ${({ isSelected }) =>
    isSelected ? COLORS.highlight.one : "transparent"};
  color: ${({ isSelected }) =>
    isSelected ? TEXT_COLORS.one : TEXT_COLORS.two};

  svg {
    width: 32px;
    height: 32px;
    margin-bottom: ${SPACING.one}px;
  }
`;
