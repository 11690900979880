import currency from "currency.js";
import { BigNumber, ethers } from "ethers";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FieldValues, UseFormReturn, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input } from "../../shared/Input";
import { HeaderSubText } from "../../shared/SharedModalStyles/styles";
import { ExtrasText, Padding, SuccessMessage } from "../style";
import {
  HeaderText,
  InfoRow,
  InputContainer,
  InputLabel,
  MaxButton,
} from "./style";

import { SPACING } from "../../../constants/design/spacing";
import { ONCHAIN_ESTIMATES } from "../../../constants/onchainEstimates";
import { ConnectWalletContext } from "../../../contexts/ConnectWalletContext";
import { ChainIdEnum } from "../../../enums/chain";
import { useGetAccount } from "../../../hooks/api/account/useGetAccount";
import {
  getAssetLogo,
  getAssetShortName,
  getCollateralAssets,
} from "../../../utils/asset/assets";
import {
  DepositAssetDropdownItem,
  DepositAssetDropdownTitle,
} from "../../DepositWithdrawModal/style";
import SelectMultichainNetwork from "../../SelectMultichainNetwork";
import { Chevron } from "../../shared/Chevron/style";
import ModalOverlayInfo from "../../shared/ModalOverlayInfo";
import YieldVaultToggle from "../../shared/YieldVaultToggle";
import { useYieldVault } from "../../../hooks/api/yieldVault/useYieldVault";
import {
  AllCollaterals,
  DepositWithdrawCollaterals,
  IsUSDC,
} from "../../../constants/addresses";
import DropdownSimple from "../../shared/DropdownSimple";

interface IDepositProps {
  form: UseFormReturn<FieldValues, any>;
  isDepositing: boolean;
  asset: DepositWithdrawCollaterals;
  onChangeAsset: (asset: DepositWithdrawCollaterals) => void;
  balance?: string;
  showHelpInfo?: boolean;
  showApprovalSuccessMessage?: boolean;
  onHideHelpInfo?: () => void;
  customError?: string;
  yieldVaultToggle: boolean;
  setYieldVaultToggle: (yieldVaultToggle: boolean) => void;

  selectedDepositNetwork: ChainIdEnum;
  onChangeSelectedDepositNetwork: (chain: ChainIdEnum) => void;

  socketDepositInfo?: {
    ethBalance: BigNumber;
    depositFee?: BigNumber;
  };
}

function Deposit({
  form,
  isDepositing,
  asset,
  onChangeAsset,
  balance,
  socketDepositInfo,
  showHelpInfo,
  showApprovalSuccessMessage,
  onHideHelpInfo,
  selectedDepositNetwork,
  onChangeSelectedDepositNetwork,
  customError,
  yieldVaultToggle,
  setYieldVaultToggle,
}: IDepositProps) {
  const { showConnectModal } = useContext(ConnectWalletContext);
  const { data: accountData } = useGetAccount();
  const { data: yieldVaultData } = useYieldVault();
  const {
    register,
    formState: { errors },
    setValue,
    control,
    clearErrors,
  } = form;
  const amount: string | undefined = useWatch({ control, name: "amount" });
  const { t } = useTranslation("app", {
    keyPrefix: "ConnectWalletModal.Deposit",
  });
  const { t: formError } = useTranslation("formErrors");
  const [assetDropdownOpen, setAssetDropdownOpen] = useState(false);
  const exchangeBalance = useMemo(() => {
    const collateralBalance = accountData?.collaterals?.find(
      (c) => c.collateral_asset === asset
    );
    if (collateralBalance) {
      return currency(collateralBalance.balance).format({ symbol: "" });
    }
    return "-";
  }, [accountData?.collaterals, asset]);

  const estDepositTime = useMemo(() => {
    if (socketDepositInfo) {
      return `${ONCHAIN_ESTIMATES.socketDeposit.value} ${ONCHAIN_ESTIMATES.socketDeposit.unit}`;
    }
    return `${ONCHAIN_ESTIMATES.deposit.value} ${ONCHAIN_ESTIMATES.deposit.unit}`;
  }, [socketDepositInfo]);

  const notEnoughEthForFees =
    socketDepositInfo && amount
      ? socketDepositInfo.ethBalance.lt(socketDepositInfo.depositFee || 0)
      : false;

  const messageContent = useMemo(() => {
    const hasErrors =
      notEnoughEthForFees || Boolean(Object.keys(errors).length);
    if (socketDepositInfo && notEnoughEthForFees) {
      return <ExtrasText type="error">{t("insufficient_balance")}</ExtrasText>;
    }
    if (showApprovalSuccessMessage && !hasErrors) {
      return <SuccessMessage>{t("ready_to_deposit")}</SuccessMessage>;
    }
    return undefined;
  }, [
    errors,
    notEnoughEthForFees,
    showApprovalSuccessMessage,
    socketDepositInfo,
    t,
  ]);

  const onMaxClick = useCallback(() => {
    setValue("amount", balance, { shouldValidate: true });
  }, [balance, setValue]);

  useEffect(() => {
    if (!showConnectModal) {
      setTimeout(() => {
        clearErrors();
        setValue("amount", undefined);
      }, 200);
    }
  }, [showConnectModal, clearErrors, setValue]);

  return (
    <>
      <ModalOverlayInfo show={showHelpInfo} onClose={onHideHelpInfo}>
        <HeaderText>{t("how_deposits_work_header")}</HeaderText>
        <HeaderSubText style={{ padding: 0 }}>
          {t("how_deposits_work_subheader", {
            asset: getAssetShortName(asset),
          })}
        </HeaderSubText>
      </ModalOverlayInfo>
      <Padding>
        <InputContainer>
          <InputLabel>{t("deposit_from")}</InputLabel>
          <SelectMultichainNetwork
            selectedChain={selectedDepositNetwork}
            onSelectChain={onChangeSelectedDepositNetwork}
          />
          <InputLabel>{t("amount")}</InputLabel>
          <Input
            placeholder="0.00"
            leftAccessory={
              <DropdownSimple<AllCollaterals>
                dropDirection={"down"}
                show={assetDropdownOpen}
                onToggle={setAssetDropdownOpen}
                toggleStyle={{
                  padding: 0,
                  paddingLeft: SPACING.two,
                  paddingRight: SPACING.two,
                }}
                title={
                  <DepositAssetDropdownTitle>
                    <img
                      src={getAssetLogo(asset)}
                      width={24}
                      height={24}
                      alt={asset}
                    />
                    <Chevron
                      size="small"
                      direction={assetDropdownOpen ? "up" : "down"}
                    />
                  </DepositAssetDropdownTitle>
                }
                items={getCollateralAssets(selectedDepositNetwork, "deposit")}
                getItemLabel={(v) => (
                  <DepositAssetDropdownItem selected={v === asset}>
                    <img src={getAssetLogo(v)} width={24} height={24} alt={v} />
                    <span>{getAssetShortName(v, selectedDepositNetwork)}</span>
                  </DepositAssetDropdownItem>
                )}
                selectedItem={asset}
                onSelectItem={(v) => {
                  onChangeAsset(v as DepositWithdrawCollaterals);
                }}
              />
            }
            rightAccessory={
              <MaxButton onClick={onMaxClick} type="button">
                {t("max")}
              </MaxButton>
            }
            type="number"
            disabled={isDepositing}
            {...register("amount", {
              required: true,
              validate: {
                moreThanZero: (v) => parseFloat(v) > 0,
                lessThanEqualToBalance: (v) =>
                  parseFloat(v) <= parseFloat(balance || "0"),
              },
            })}
            error={Boolean(Object.keys(errors).length)}
          />
          {errors.amount?.type === "required" && (
            <ExtrasText type="error">{formError("amount.required")}</ExtrasText>
          )}
          {errors.amount?.type === "moreThanZero" && (
            <ExtrasText type="error">
              {formError("amount.moreThanZero")}
            </ExtrasText>
          )}
          {errors.amount?.type === "lessThanEqualToBalance" && (
            <ExtrasText type="error">
              {formError("amount.lessThanEqualToBalance")}
            </ExtrasText>
          )}
          {Boolean(!errors.amount?.type && customError) && (
            <ExtrasText type="error">{customError}</ExtrasText>
          )}
        </InputContainer>
        {socketDepositInfo && (
          <InfoRow error={notEnoughEthForFees}>
            <span>{t("wallet_balance")} (ETH)</span>
            <span>
              {Number(
                ethers.utils.formatEther(socketDepositInfo.ethBalance)
              ).toFixed(4)}
            </span>
          </InfoRow>
        )}
        <InfoRow>
          <span>
            {t("wallet_balance")} (
            {getAssetShortName(asset, selectedDepositNetwork)})
          </span>
          <span>{balance || "---"}</span>
        </InfoRow>
        <InfoRow>
          <span>
            {t("exchange_balance")} ({asset})
          </span>
          <span>{exchangeBalance}</span>
        </InfoRow>
        <InfoRow>
          <span>{t("estimated_deposit_time")}</span>
          <span>{estDepositTime}</span>
        </InfoRow>
        {socketDepositInfo && (
          <InfoRow error={notEnoughEthForFees}>
            <span>{t("bridge_fee")}</span>
            <span>
              {socketDepositInfo.depositFee
                ? `${Number(
                    ethers.utils.formatEther(socketDepositInfo.depositFee)
                  ).toFixed(6)} ETH`
                : "---"}
            </span>
          </InfoRow>
        )}
        {IsUSDC(asset) && !yieldVaultData?.is_paused && (
          <YieldVaultToggle
            isOn={yieldVaultToggle}
            onToggle={() => {
              setYieldVaultToggle(!yieldVaultToggle);
            }}
          />
        )}
        {messageContent}
      </Padding>
    </>
  );
}
export default Deposit;
