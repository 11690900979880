import styled from "styled-components";
import { COLORS, LAYER_COLORS } from "../../constants/design/colors";

export const FillMeterWrapper = styled.div<{textColor?: string; layoutReversed?: boolean}>`
  display: flex;
  flex-direction: ${({ layoutReversed }) => (layoutReversed ? "row-reverse" : "row")};
  align-items: center;
  color: ${({ textColor }) => textColor || COLORS.blue.one};
`;

// percent is a number between 0-100
export const Bar = styled.div`
  background-color: ${LAYER_COLORS.three};
  width: 24px;
  height: 2px;
  border-radius: 100px;
  overflow: hidden;
`;

export const FillPercentage = styled.div`

`;

export const BarInner = styled.div.attrs<{
  percent: number;
  fillColor?: string;
}>(({ percent, fillColor }) => ({
  style: {
    background: fillColor || COLORS.blue.one,
    width: `${percent}%`
  }
}))<{
  percent: number;
  fillColor?: string;
}>`
  height: 100%;
  transition: all 0.2s ease-in-out;
`;
