import { AxiosError } from "axios";
import useSWR from "swr";
import { useEffect, useState } from "react";
import {
  GetStatistics200Response,
  InstrumentTypeResponse,
} from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { publicApi } from "../../../services/api/apiFetcher";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";
import {
  pollingDelay,
  pollingInterval,
} from "../../../services/api/pollingInterval";
import { AssetResponse } from "../../../utils/asset";

export const useGetStatistics = (
  asset?: AssetResponse,
  instrumentType?: InstrumentTypeResponse
) => {
  const fetcher = publicApi();
  const [startsQuery, setStartsQuery] = useState(false);

  useEffect(() => {
    // Wait before querying
    const t = setTimeout(() => {
      setStartsQuery(true);
    }, pollingDelay[APIEndpointEnum.STATISTICS]);
    return () => clearTimeout(t);
  }, []);

  return useSWR<GetStatistics200Response, AxiosError>(
    asset && instrumentType && startsQuery
      ? [APIEndpointEnum.STATISTICS, asset, instrumentType]
      : undefined,
    async () =>
      (await (await fetcher.getStatistics(asset!, instrumentType!))()).data,
    {
      use: [useLoggerMiddleware],
      refreshInterval: pollingInterval[APIEndpointEnum.STATISTICS],
    }
  );
};
