import currency from "currency.js";
import { useCallback, useContext, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  GetTradeHistory200ResponseTradeHistoryInner,
  InstrumentTypeResponse,
  SideResponse,
  TradeTypeResponse,
} from "../../../../codegen-api";
import { PortfolioMarketFilter } from "../../../../components/PortfolioSettings/PortfolioMarketFilter";
import MarketCell from "../../../../components/PortfolioSettings/Table/MarketCell";
import { Content } from "../../../../components/PortfolioSettings/style";
import RowActionButton from "../../../../components/shared/RowActionButton";
import { Spinner } from "../../../../components/shared/Spinner";
import { SpinnerContainerWrapper } from "../../../../components/shared/Spinner/style";
import TradeDetailsModal from "../../../../components/shared/ViewDetailsModal/TradeDetailsModal";
import { COLORS, TEXT_COLORS } from "../../../../constants/design/colors";
import { MarketInstrumentContext } from "../../../../contexts/MarketInstrumentContext";
import { PageEndpointEnum } from "../../../../enums/endpoint";
import { useTradeHistory } from "../../../../hooks/api/tradeHistory/useTradeHistory";
import { Label } from "../../../PortfolioOverviewTab/style";
import {
  Actions,
  Footer,
  Header,
  MobilePortfolioRow,
  MobileWrapper,
  Pill,
  PortfolioFilterContainer,
  Stat,
  StatValue,
  Stats,
} from "../style";

interface IMobileTradeHistoryProps {
  tradeHistories?: GetTradeHistory200ResponseTradeHistoryInner[];
  t: TFunction;
}

const pnlColor = (pnl: string) => {
  if (pnl && Number(pnl) > 0) {
    return COLORS.positive.one;
  }
  if (pnl && Number(pnl) < 0) {
    return COLORS.negative.one;
  }
  return TEXT_COLORS.one;
};

function MobileTradeHistory({ tradeHistories, t }: IMobileTradeHistoryProps) {
  const { getMarketPrecision } = useContext(MarketInstrumentContext);
  const navigate = useNavigate();

  const [viewDetailsForTrade, setViewDetailsForTrade] =
    useState<GetTradeHistory200ResponseTradeHistoryInner>();

  const viewAllTradeHistory = useCallback(() => {
    navigate(`${PageEndpointEnum.PORTFOLIO}/trade-history`);
  }, [navigate]);

  return (
    <div>
      <TradeDetailsModal
        trade={viewDetailsForTrade}
        onHide={() => setViewDetailsForTrade(undefined)}
      />
      {tradeHistories?.map((trade, i) => {
        const { price_precision, amount_precision } = getMarketPrecision(
          trade.asset,
          trade.instrument_type
        );
        return (
          <MobilePortfolioRow
            key={`${trade.trade_id}-${trade.agg_order_id}`}
            onClick={() => setViewDetailsForTrade(trade)}
            transition={{
              delay: tradeHistories.length > 10 ? 0 : i * 0.1,
              duration: 0.5,
              ease: "easeInOut",
            }}
            initial={{
              transform: "translateY(-8px)",
              opacity: 0,
            }}
            animate={{
              transform: "translateY(0px)",
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <Header>
              <MarketCell
                instrumentName={trade.instrument_name}
                optionType={trade.option_type}
                expiry={trade.expiry}
                strike={trade.strike}
              />
              <Pill isPositive={trade.side === SideResponse.Buy}>
                {trade.side === SideResponse.Buy ? t("long") : t("short")}
              </Pill>
            </Header>
            <Footer>
              <Stats>
                <Stat>
                  <Label>{t("price")}</Label>
                  <StatValue>
                    {currency(trade.price || 0, {
                      precision: price_precision,
                    }).format()}
                  </StatValue>
                </Stat>
                <Stat>
                  <Label>{t("size")}</Label>
                  <StatValue>
                    {Number(trade.amount).toFixed(amount_precision)}
                  </StatValue>
                </Stat>
              </Stats>
              <Stats>
                <Stat>
                  <Label>{t("type")}</Label>
                  <StatValue>{trade.order_type}</StatValue>
                </Stat>
                <Stat>
                  <Label>{t("realized_pnl")}</Label>
                  <StatValue color={pnlColor(trade.pnl || "0")}>
                    {currency(trade.pnl || 0, {
                      precision: price_precision,
                    }).format()}
                  </StatValue>
                </Stat>
              </Stats>
            </Footer>
            <Actions>
              <RowActionButton
                variant={"details"}
                onClick={() => setViewDetailsForTrade(trade)}
              />
            </Actions>
          </MobilePortfolioRow>
        );
      })}
      <MobilePortfolioRow onClick={viewAllTradeHistory}>
        <StatValue>{t("view_all_trade_history")}</StatValue>
      </MobilePortfolioRow>
    </div>
  );
}

interface IMobileTradeHistoryTabProps {
  showFilter?: boolean;
  noPadding?: boolean;
}

export function MobileTradeHistoryTab({
  showFilter = true,
  noPadding = false,
}: IMobileTradeHistoryTabProps) {
  const [filter, setFilter] = useState<InstrumentTypeResponse>();
  const { data, isValidating: tradeHistoryDataValidating } = useTradeHistory(
    0,
    undefined,
    [
      TradeTypeResponse.Trade,
      TradeTypeResponse.Settlement,
      TradeTypeResponse.Liquidation,
    ],
    false,
    undefined,
    undefined,
    undefined,
    filter
  );
  const tradeHistoryLoading = !data && tradeHistoryDataValidating;
  const { t } = useTranslation("app", {
    keyPrefix: "pages.PortfolioPage.mobile.MobileTradeHistoryTab",
  });

  const tradeHistoryData = useMemo(() => data?.trade_history, [data]);

  if (tradeHistoryLoading) {
    return (
      <SpinnerContainerWrapper>
        <Spinner />
      </SpinnerContainerWrapper>
    );
  }

  return (
    <MobileWrapper noPadding={noPadding}>
      {showFilter ? (
        <PortfolioFilterContainer>
          <PortfolioMarketFilter
            title={t("trade_history")}
            instrumentType={filter}
            onSetFilter={setFilter}
          />
        </PortfolioFilterContainer>
      ) : null}
      {!tradeHistoryData || tradeHistoryData?.length === 0 ? (
        <Content>{t("no_history_desc")}</Content>
      ) : (
        <MobileTradeHistory tradeHistories={tradeHistoryData} t={t} />
      )}
    </MobileWrapper>
  );
}
